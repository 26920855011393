import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { parseToInteger } from 'utils/helper';
import { PILOT_MARK_COMPLETED } from 'pages/Pilot/PilotPortalGraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';

const MarkComplete = ({
  projectId,
  folderId,
  isMarkCompleted,
  isProjectAccepted,
  refetchAssets,
  pilotFlagStatusRefetch,
}) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [isMarkCompleteModalOpen, setMarkCompleteModal] = useState(false);
  const [isMarkCompleteStatus, setMarkCompleteStatus] = useState(false);

  const toggleMarkModal = (status) => {
    setMarkCompleteStatus(status);
    setMarkCompleteModal(!isMarkCompleteModalOpen);
  };

  const [pilotMarkCompleted] = useMutation(PILOT_MARK_COMPLETED, {
    onCompleted: async ({ pilotMarkCompleted }) => {
      await refetchAssets();
      await pilotFlagStatusRefetch();
      toggleMarkModal(false);
      toast.success(pilotMarkCompleted.message);
    },
    onError: ({ message }) => {
      toggleMarkModal(false);
      toast.error(message);
    },
  });

  const handleOnChange = async (status) => {
    try {
      await pilotMarkCompleted({
        variables: {
          folderId: parseToInteger(folderId),
          projectId: parseToInteger(projectId),
          isMarkedCompleted: status,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderMarkCheckbox = () => {
    if (
      role === ROLES.VIDEOMS_PM ||
      role === ROLES.ADMIN ||
      role === ROLES.VIDEOMS_QC
    ) {
      return (
        isMarkCompleted && (
          <Label check className="ml-4">
            <Input
              type="checkbox"
              id="topping"
              name="topping"
              disabled={!isMarkCompleted}
              onChange={(e) => toggleMarkModal(e.target.checked)}
              checked={isMarkCompleted}
            />{' '}
            <span className="align-middle d-inline-block line-height-23">
              Mark Complete
            </span>
          </Label>
        )
      );
    }
    return (
      <Label check className="ml-4">
        <Input
          type="checkbox"
          id="topping"
          name="topping"
          disabled={isMarkCompleted}
          onChange={(e) => toggleMarkModal(e.target.checked)}
          checked={isMarkCompleted}
        />{' '}
        <span className="align-middle d-inline-block line-height-23">
          Mark Complete
        </span>
      </Label>
    );
  };
  return (
    <>
      {isProjectAccepted && <>{renderMarkCheckbox()}</>}
      {isMarkCompleteModalOpen && (
        <Modal
          isOpen={isMarkCompleteModalOpen}
          toggle={() => toggleMarkModal(false)}
          className="modal-dialog-centered"
        >
          <ModalBody className="text-center py-5">
            <h5>
              <FontAwesomeIcon
                className="mr-2"
                icon={faCheckCircle}
                color="#3AB42B"
              />
              Are you sure?
            </h5>
            <p className="mb-0">
              Do you really want to change the mark complete status.
            </p>
          </ModalBody>
          <ModalFooter className="bg-grey-upload">
            <Button
              outline
              color="primary"
              className="mr-2 btn-sm px-4 font-size-13"
              onClick={() => toggleMarkModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn-sm px-4 font-size-13"
              onClick={() => handleOnChange(isMarkCompleteStatus)}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default MarkComplete;
