import React, { useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Badge, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { COMMENT_SOURCE } from 'constants/appConstants';
import { authContext } from 'contexts/AuthContext';
import AnnotationModal from 'common/AnnotationModal';
import { dateTimeFormatter } from 'utils/helper';

const EditComment = ({
  commentId,
  commentText,
  setShowComment,
  refechAssetComments,
  handleAcceptSingleComment,
  projectEditor,
}) => {
  const { auth } = useContext(authContext);
  const [editedCommentText, setEditedComment] = useState(commentText);

  const handleEditComment = (e) => {
    setEditedComment(e.target.value);
  };

  const handleCancel = () => {
    setEditedComment(commentText);
    setShowComment(1);
  };
  const handleSubmit = async () => {
    if (auth && auth.data) {
      const { role, token } = auth.data;
      const {
        data: { userId },
      } = jwtDecode(token);
      await handleAcceptSingleComment({
        commentTo: projectEditor,
        commentId,
        commentFrom: userId,
        isEdited: true,
        commentText: editedCommentText,
      });
    }
    setShowComment(1);
    await refechAssetComments();
  };
  return (
    <div>
      <FormGroup>
        <Input
          value={editedCommentText}
          type="text"
          className="form-control-sm"
          onChange={(e) => handleEditComment(e)}
        />
      </FormGroup>
      <div className="text-right mt-2">
        <Button
          outline
          type="button"
          color="primary"
          onClick={handleCancel}
          className="px-4 font-size-14 font-weight-500 mr-2 btn-sm"
        >
          Cancel
        </Button>
        <Button
          className="font-size-14 font-weight-light px-4  btn btn-primary btn-sm"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const Screenshot = ({
  comments,
  commentId,
  commentTime,
  commentScreenShot,
  parentCommentCreatedAt,
  commentCompleted,
  annotationCords,
  projectEditor,
  acceptAllCommentStatus,
  ...rest
}) => {
  const { VIDEO_PLAYER } = COMMENT_SOURCE;

  const [showComment, setShowComment] = useState(1);
  const [assetModalData, setAssetModalData] = useState({
    assetURL: null,
    comments: null,
    assetModal: false,
    annotationCords: null,
  });

  useEffect(() => {
    if (acceptAllCommentStatus) setShowComment(1);
  }, [acceptAllCommentStatus]);

  const toggleAssetModal = (commentScreenShot, annotationCords, comments) =>
    setAssetModalData({
      comments,
      annotationCords,
      assetURL: commentScreenShot,
      assetModal: !assetModalData.assetModal,
    });
  return (
    <>
      <Row className="comment-row">
        <Col md="2">
          <div>
            <img
              src={commentScreenShot}
              alt="SCREENSHOTS"
              className="border-radius-8px"
              height="100px"
              width="135px"
              onClick={() =>
                toggleAssetModal(commentScreenShot, annotationCords, comments)
              }
              onKeyDown={() =>
                toggleAssetModal(commentScreenShot, annotationCords, comments)
              }
              role="presentation"
            />
          </div>
        </Col>
        <Col md="10">
          <div className="row">
            <div className="col-md-8">
              <Badge
                color="light"
                className="badge badge-light border-radius-8px font-size-12 font-weight-bold mb-2 px-3 py-1 text-danger"
              >
                {commentTime}
              </Badge>
              {comments &&
                comments.map(
                  ({
                    id,
                    commentText,
                    commentUser,
                    commentSource,
                    commentAccepted,
                    isEditedComment,
                    commentCreatedAt,
                  }) => {
                    return (
                      <div key={id}>
                        <div
                          className={
                            commentSource !== VIDEO_PLAYER
                              ? 'edited-comment-card mb-3 col-md-8'
                              : 'comment-card mb-3'
                          }
                        >
                          <div className="flex-fill ">
                            <Row>
                              <Col md={6}>
                                <span>{commentUser}</span>
                              </Col>
                              {commentSource !== VIDEO_PLAYER && (
                                <Col md={6}>
                                  <div className="text-right">
                                    <h6 className="mb-0 text-muted font-size-12 font-weight-normal">
                                      {dateTimeFormatter(
                                        commentCreatedAt,
                                        'MM/DD/YYYY h:mm:ss a'
                                      )}
                                    </h6>
                                  </div>
                                </Col>
                              )}
                            </Row>

                            <p className="font-size-14 font-italic mb-0">
                              {commentText}
                              {!commentCompleted &&
                                !isEditedComment &&
                                !commentAccepted && (
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    color="#7870B5"
                                    onClick={() => setShowComment(id)}
                                    className="ml-2 cursor-pointer"
                                  />
                                )}
                            </p>
                          </div>
                        </div>
                        {!acceptAllCommentStatus && showComment === id && (
                          <EditComment
                            commentId={commentId}
                            commentText={commentText}
                            setShowComment={setShowComment}
                            projectEditor={projectEditor}
                            {...rest}
                          />
                        )}
                      </div>
                    );
                  }
                )}
            </div>
            <div className=" col-md-4 text-right">
              <h6 className="mb-0 text-muted font-size-14">
                {dateTimeFormatter(
                  parentCommentCreatedAt,
                  'MM/DD/YYYY h:mm:ss a'
                )}
              </h6>
              {commentCompleted ? (
                <div className="d-flex">
                  <Badge className="badge bg-success border-radius-8px mt-3 font-size-12 font-weight-normal mb-2 px-3 py-1 text-white">
                    Completed
                  </Badge>
                </div>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      {assetModalData.assetModal && (
        <AnnotationModal
          toggleAssetModal={toggleAssetModal}
          {...assetModalData}
        />
      )}
    </>
  );
};
export default Screenshot;
