import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { faCheckCircle, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const REGEX_PHONE = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_DASH_REGEX = /\d{3}(?=\d{2,3})|\d+/g;

const BrokerItem = ({ brokerInfo, toggleFlagModal, saveBrokersData }) => {
  const [brokerData, setBrokerData] = useState(brokerInfo);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    firstName,
    lastName,
    company,
    phone,
    email,
    id,
    totalActiveAssetsCount,
  } = brokerData;

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    const brokerObj = { ...brokerData };

    if (fieldName === 'phone') {
      if (!value.match(PHONE_DASH_REGEX)) {
        setBrokerData({ ...brokerObj, phone: [] });
        return true;
      }
      if (value) {
        let temp = value.match(PHONE_DASH_REGEX);
        temp = temp.join('-');
        brokerObj[fieldName] = [temp];
      } else {
        brokerObj[fieldName] = [value];
      }
    } else {
      brokerObj[fieldName] = value;
    }

    setBrokerData(brokerObj);
    return true;
  };

  const handleSubmit = async () => {
    await setButtonDisabled(true);
    await saveBrokersData(brokerData);
  };

  const handleDisable = () => {
    if (email && phone.length > 0) {
      if (phone[0].match(REGEX_PHONE) && email.match(REGEX_EMAIL)) {
        return false;
      }
      return true;
    }
    return true;
  };

  const checkValidClass = (brokerData, fieldName) => {
    const { email, phone } = brokerData;
    if (email || phone.length > 0) {
      if (email && fieldName === 'email') {
        if (email.match(REGEX_EMAIL)) {
          return true;
        }
        return false;
      }

      if (phone.length > 0 && fieldName === 'phone') {
        if (phone[0].match(REGEX_PHONE)) {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
    return true;
  };

  const renderURL = (val) => {
    if (!val) return 'Not specified';
    return (
      <a
        href={`https://www.google.com/search?q=${firstName}+${lastName}+${company}`}
        target="_blank"
        rel="noreferrer"
        title={val}
        className="w-75 text-truncate d-inline-block mr-2"
      >
        {val}
      </a>
    );
  };

  const renderPhoneField = () => {
    const html = (
      <input
        id={`phone-${id}`}
        name="phone"
        className={`form-control w-75 d-inline-flex mr-2 ${
          checkValidClass(brokerData, 'phone') ? '' : 'border-danger'
        } `}
        value={phone || ''}
        onChange={(e) => {
          handleInputChange(e, 'phone');
        }}
      />
    );

    if (!brokerInfo.phone.length || !phone[0].match(REGEX_PHONE)) {
      return html;
    }
    return (
      <a
        href={`https://www.google.com/search?q=${firstName}+${lastName}+${company}`}
        target="_blank"
        rel="noreferrer"
        title={phone}
        className="w-75 text-truncate d-inline-block mr-2"
      >
        {phone}
      </a>
    );
  };

  return (
    <>
      <tr
        style={{ backgroundColor: 'rgb(248, 231, 233)' }}
        className="update-missing-record-row"
      >
        <td>{renderURL(firstName)}</td>
        <td>{renderURL(lastName)}</td>
        <td>{renderURL(company)}</td>
        <td>{totalActiveAssetsCount}</td>
        <td>{renderPhoneField()}</td>
        <td className="d-flex">
          <input
            id={`email-${id}`}
            name="email"
            className={`form-control w-70 d-inline-flex mr-2 ${
              checkValidClass(brokerData, 'email') ? '' : 'border-danger'
            } `}
            value={email || ''}
            onChange={(e) => {
              handleInputChange(e, 'email');
            }}
          />
          <Button
            onClick={handleSubmit}
            className="check-btn px-1"
            disabled={handleDisable() || buttonDisabled}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          </Button>
          <Button
            onClick={() => toggleFlagModal(true, brokerData)}
            className="check-btn update-missing-flag px-1"
          >
            <FontAwesomeIcon icon={faFlag} color="#7a696c" />
          </Button>
        </td>
      </tr>
    </>
  );
};
export default BrokerItem;
