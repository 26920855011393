import React from 'react';
//
import NoData from 'common/NoData';
import { VIDPITCH_VIDEO_PRICE } from 'pages/Vidpitch/constant';

const VideoDetails = ({ data }) => {
  if (data?.getVideoData?.brokerDetails) {
    const {
      getVideoData: { brokerDetails },
    } = data;

    const fetchPropertyImage = () => {
      if (brokerDetails?.assetImageUrls?.length) {
        return brokerDetails.assetImageUrls[0];
      }
      return 'https://via.placeholder.com/40';
    };

    return (
      <div>
        <p className="mb-2">Video</p>
        <h3>{VIDPITCH_VIDEO_PRICE}</h3>
        <img
          src={fetchPropertyImage()}
          alt="propertyImage"
          height={130}
          width={250}
        />
        <h6 className="pt-2">{brokerDetails?.assetName || 'Not Specified'}</h6>
        <p>{brokerDetails?.assetAddress || 'Not Specified'}</p>
        <hr />
        <div className="d-flex justify-content-between">
          <span>1 X Video</span>
          <span>{VIDPITCH_VIDEO_PRICE}</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <b>Sub Total</b>
          <b>{VIDPITCH_VIDEO_PRICE}</b>
        </div>
      </div>
    );
  }

  return (
    <NoData customMessage="Something bad happened, please try after some time." />
  );
};

export default VideoDetails;
