import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';

import { TAB_NAMES } from 'pages/EditorPortal/constant';
import ErrorBoundary from 'common/ErrorBoundary';
import Header from 'pages/PMPortal/common/Header';
import FilterProjectPlanType from 'pages/EditorPortal/common/FilterProjectPlanType';
import ProjectTableProvider from './components/ProjectTableProvider';

const FILTER = {
  status: 'NEW INVITE',
  limit: 10,
  offset: 0,
  page: 1,
  isEditorPortal: true,
  filter: {
    projectPlan: 'ALL',
    searchString: '',
  },
};

const PMsActiveProjects = () => {
  let searchFilterTimeOut;
  const {
    activeProjectInfo: {
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.editorPortal);

  const [isActive, setActive] = useState(0);
  const [filters, setFilters] = useState(FILTER);
  const [searchText, setSearchText] = useState(filters?.searchString);
  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    setFilters({ ...filters, offset, page });
  }, [page, offset]);

  const handleFilter = (filterStatus, i) => {
    setActive(i);
    setFilters({
      ...filters,
      offset: 0,
      page: 1,
      status: TAB_NAMES[filterStatus],
    });
  };

  const handleFilters = (filterStatus) => {
    setFilters({
      ...filters,
      offset: 0,
      page: 1,
      filter: { ...filters.filter, projectPlan: filterStatus },
    });
  };

  const handleSearch = (searchParam) => {
    setSearchText(searchParam);
    clearTimeout(searchFilterTimeOut);

    searchFilterTimeOut = setTimeout(() => {
      setFilters({
        ...filters,
        offset: 0,
        page: 1,
        filter: { ...filters?.filter, searchString: searchParam?.trim() },
      });
    }, [300]);
  };

  const renderTabButtons = () => {
    return Object.keys(TAB_NAMES).map((tabName, i) => {
      return (
        <div key={tabName} className="position-relative">
          <Button
            outline
            onClick={() => handleFilter(tabName, i)}
            className={`tab-button-editor ${i === isActive ? 'isactive' : ''}`}
          >
            {tabName}
            {tabName === 'PROJECT INVITATION' && hasNotification && (
              <span className="notification-dot" />
            )}
          </Button>
        </div>
      );
    });
  };

  return (
    <>
      <Header heading="Projects" />
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">{renderTabButtons()}</div>
          <div className="d-flex">
            <FilterProjectPlanType
              handleFilter={handleFilters}
              sortByData={filters?.filter?.projectPlan || 'ALL'}
            />
            <Input
              type="search"
              name="search"
              placeholder="Search"
              value={searchText || ''}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '350px' }}
            />
          </div>
        </div>

        <div>
          <ErrorBoundary>
            <ProjectTableProvider
              filters={filters}
              setFilters={setFilters}
              setHasNotification={setHasNotification}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default PMsActiveProjects;
