export const ROLES_ALIAS = {
  'videoms-admin': 'VMS Admin',
  'videoms-project-manager': 'VMS PM',
  crexi: 'VMS Crexi',
  'videoms-operator': 'VMS Operator',
  'videoms-test': 'VMS QA',
  'videoms-editor': 'VMS Editor',
  'videoms-pilot': 'VMS Pilot',
  'videoms-sales-person': 'VMS Sales Person',
  'vidpitch-admin': 'Vidpitch Admin',
  broker: 'Vidpitch Broker',
  'videoms-quality-controller': 'VMS QC',
};
