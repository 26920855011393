import React from 'react';
import VideoMSLogo from 'common/VideoMSLogo';
import PMNavigation from './components/PMNavigation';

const Sidebar = () => {
  return (
    <div className="sidebar-quad px-3">
      <div className="d-flex justify-content-between align-items-center logo-height">
        <VideoMSLogo />
      </div>
      <div className="portal-name">
        <span>PM PORTAL</span>
      </div>
      <div>
        <PMNavigation />
      </div>
    </div>
  );
};
export default Sidebar;
