import React from 'react';
import { Layer, Rect } from 'react-konva';

const RectShape = ({ color, x, y, w, h, radius }) => {
  return (
    <Layer>
      <Rect
        x={(640 * (x * 100)) / 100}
        y={(360 * (y * 100)) / 100}
        width={(640 * (w * 100)) / 100}
        height={(360 * (h * 100)) / 100}
        stroke={color}
        cornerRadius={radius}
      />
    </Layer>
  );
};
export default RectShape;
