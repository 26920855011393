import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_PROJECT_BY_NAME } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import ProjectList from './components/ProjectList';

const ProjectListProvider = ({
  filters,
  setFilters,
  changeInSearchString,
  setChangeInSearchString,
}) => {
  let totalCount;
  const [items, setItems] = useState([]);

  const { data, loading, error } = useQuery(SEARCH_PROJECT_BY_NAME, {
    variables: filters,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && data?.searchProjectByName?.projects) {
      if (changeInSearchString) {
        setItems([...data.searchProjectByName.projects]);
        setChangeInSearchString(false);
      } else {
        setItems([...items, ...data.searchProjectByName.projects]);
      }
    }
  }, [data, loading]);

  if (error) return <div>{error.message}</div>;

  if (data) {
    totalCount = data.searchProjectByName.totalCount;
  }
  if (items.length) {
    return (
      <ProjectList
        filters={filters}
        setFilters={setFilters}
        totalCount={totalCount}
        PROJECT_LIST={items}
      />
    );
  }
  return (
    <li className="mt-4 text-center">
      <span className="text-dark">
        {loading ? 'Loading...' : 'No Projects found!'}
      </span>
    </li>
  );
};

export default ProjectListProvider;
