import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Card, CardBody } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
//
import MailIcon from 'assets/mail-icon.png';
import Logo from 'assets/videoms-logo.png';
import MailTo from './components/MailTo';
import RegistrationForm from './components/RegistrationForm';

const VidpitchSignup = () => {
  const { uuid } = useParams();
  const history = useHistory();

  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasLinkExpired, setHasLinkExpired] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}api/verify-user?link_id=${uuid}`,
      {
        method: 'GET',
        headers: new Headers({
          'ngrok-skip-browser-warning': '69420',
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          const { isAlreadyRegistered, linkExpired } = data;
          if (!linkExpired && isAlreadyRegistered) {
            return history.push(`/${uuid}/video-draft-purchase`);
          }
          if (!linkExpired && !isAlreadyRegistered) {
            setEmailAddress(data.email);
          }
          setHasLinkExpired(linkExpired);
          setIsAlreadyRegistered(isAlreadyRegistered);
        } else {
          toast.error(data.error);
          setErrorMessage(data.error);
        }
        return null;
      })
      .catch((err) => {
        toast.error(err.message);
        setErrorMessage(err.message);
      });
  }, []);

  return (
    <div className="no-auth-main-container">
      <Helmet defaultTitle="VidTech" />
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <div className="mb-4">
              <img src={Logo} alt="Logo" width="100" />
            </div>
            {hasLinkExpired && (
              <div className="text-primary">
                This link is already expired. Please contact vidTech team to
                provide a new registration link.
                <br />
                <div className="mt-2">
                  <img
                    height="20"
                    src={MailIcon}
                    alt="MailIcon"
                    className="mr-2"
                  />
                  <MailTo email="team@videoms.com">team@videoms.com</MailTo>
                </div>
              </div>
            )}
            {!hasLinkExpired && !isAlreadyRegistered && (
              <RegistrationForm uuid={uuid} emailAddress={emailAddress} />
            )}
            {errorMessage && <div className="text-primary">{errorMessage}</div>}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default VidpitchSignup;
