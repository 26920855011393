import * as actions from './LoginActionTypes';

export const userInitialState = {
  user: {},
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actions.LOG_OUT:
      return { ...userInitialState };
    default:
      return state;
  }
};

export default userReducer;
