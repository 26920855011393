import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const SaveDetailWarningModal = ({
  isVisibleModal,
  toggleUnfinishedModal,
  handleDiscardChanges,
  handleSaveChanges,
  propertyName,
  isCompleteIVC,
  pendingOrder,
  isIVCDisabled,
}) => {
  return (
    <>
      <Modal
        isOpen={isVisibleModal}
        toggle={toggleUnfinishedModal}
        className="unfinished-orders-modal modal-dialog-centered overflow-hidden"
      >
        <ModalHeader
          toggle={toggleUnfinishedModal}
          className="justify-content-center"
        >
          {pendingOrder
            ? 'The action will open the unfinished orders'
            : 'Confirmation'}
        </ModalHeader>
        <ModalBody className="px-3 py-4">
          <h6 className="text-center mb-4">
            Do you want to save this order &quot;{propertyName}&quot;?
          </h6>
          {isIVCDisabled && (
            <p className="text-center text-danger mt-n2">
              All fields of interior video contact are mandatory and Please
              enter valid data.
            </p>
          )}
          <div className="text-center ">
            <Button
              color="secondary"
              className="previous-btn font-size-14 py-2 px-3"
              onClick={handleDiscardChanges}
            >
              Discard changes
            </Button>
            <Button
              color="primary"
              className="ml-2 font-size-14 py-2 px-3"
              onClick={handleSaveChanges}
              disabled={isIVCDisabled}
            >
              Yes, Save for Later
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default SaveDetailWarningModal;
