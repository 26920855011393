import React, { useEffect } from 'react';
import { Spinner, Button } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  useTasSubscription,
  useTaskActionSubscription,
} from 'pages/ManagementPortal/hooks';
import { GET_NOTIFICATIONS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import flashIcon from 'assets/flash-icon.svg';

const spinnerStyle = {
  top: '6px',
  width: '8px',
  height: '8px',
  right: '14px',
  position: 'absolute',
  background: '#f0850e',
};

const RenderCount = () => {
  const taskSubscription = useTasSubscription();
  const taskActionSubscription = useTaskActionSubscription();

  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: { limit: 1, offset: 0 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [taskSubscription, taskActionSubscription]);

  if (error) {
    toast.error(error.message);
    return null;
  }

  if (loading) {
    return <Spinner style={spinnerStyle} type="grow" color="primary" />;
  }

  if (data && data.getNotifications) {
    const {
      getNotifications: { totalCount },
    } = data;
    return (
      <span className={totalCount > 20 ? 'small-circle' : ''}>
        {totalCount > 20 ? ' ' : totalCount}
      </span>
    );
  }

  return null;
};

const AttentionIcon = ({ handleOpenAttentionDrawer }) => (
  <Button
    color="link"
    onClick={handleOpenAttentionDrawer}
    className="attention-span d-inline-block cursor-pointer"
  >
    <img src={flashIcon} alt="flashIcon" />
    <RenderCount />
  </Button>
);

export default AttentionIcon;
