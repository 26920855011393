import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';

const PopupForm = ({ isOpen, toggle, handleSubmit, setCancel }) => {
  const onCancel = () => {
    setCancel(true);
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Project Instructions</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ instructions: '', doNotCapture: '', otherNotes: '' }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label for="instructions">
                  Are there any special instructions for the ground photo or
                  video shots you require?
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="instructions"
                  id="instructions"
                  component="textarea"
                />
              </FormGroup>
              <FormGroup>
                <Label for="doNotCapture">
                  Are there any video or photo shots that you specifically do
                  NOT want the pilot to capture?
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="doNotCapture"
                  id="doNotCapture"
                  component="textarea"
                />
              </FormGroup>
              <FormGroup>
                <Label for="otherNotes">
                  Do you have any other notes you would like to pass to the
                  drone pilot or photographer about this project?
                </Label>
                <Field
                  className="form-control"
                  type="textarea"
                  name="otherNotes"
                  id="otherNotes"
                  component="textarea"
                />
              </FormGroup>
              <ModalFooter className="px-0 pb-0">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  size="sm"
                >
                  Add Instructions
                </Button>
                <Button color="secondary" onClick={() => onCancel()} size="sm">
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default PopupForm;
