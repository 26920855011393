import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep, isArray, isEmpty, isObject, transform } from 'lodash';

import BDImg from 'assets/BD.svg';
import PMImg from 'assets/PM.svg';
import SPPImg from 'assets/SPP.svg';
import SPImg from 'assets/sales-portal.svg';
import EDImg from 'assets/EditorPortal.svg';
import PPImg from 'assets/Pilot.svg';
import MPImg from 'assets/mp.svg';

import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import { PORTALS } from 'constants/appNavigations';
import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import { getCurrentTab } from 'pages/Search/constant';
import { useMutation } from '@apollo/client';
import { ADD_ASSET_DETAILS } from 'pages/Search/CrexiSearchGraphQL';
import SaveDetailWarningModal from 'pages/Search/common/SaveDetailWarningModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import Scrollbars from 'react-custom-scrollbars-2';
import { dateTimeFormatter } from 'utils/helper';

const PortalDropdown = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;

  const currentTab = getCurrentTab(pathname);

  const { tabId } = currentTab || { tabId: 0, label: '' };

  const assetContext = useContext(assetDetailContext);
  const isIVCDisabled = assetContext && assetContext.isIVCDisabled;
  const handleEditAsset =
    (assetContext && assetContext.handleEditAsset) || null;
  const assetDetail = (assetContext && assetContext.assetDetail) || null;
  const editStatus =
    assetContext &&
    assetContext.assetToEdit &&
    assetContext.assetToEdit.editStatus;

  const getAssetDetails =
    (assetContext &&
      assetContext.assetToEdit &&
      assetContext.assetToEdit.assetData) ||
    null;

  let isCompleteIVC = true;
  if (getAssetDetails && getAssetDetails.interiorVideoContact && tabId === 5) {
    isCompleteIVC = Object.values(getAssetDetails.interiorVideoContact).every(
      (row) => row
    );
  }

  const propertyName = getAssetDetails ? getAssetDetails.asset.name : '';
  const { searchedBy, plan } = useSelector((state) => state.salesPortal);

  const [modalProp, setModalProp] = useState({
    isVisibleModal: false,
    path: null,
  });

  const { isVisibleModal } = modalProp;

  const toggleUnfinishedModal = () => setModalProp(!isVisibleModal);

  const {
    SALES,
    MANAGEMENT,
    BROKERS_DIRECTORY,
    PM_PORTAL,
    EDITOR_PORTAL,
    PILOT_PORTAL,
    SALES_PERSON_PORTAL,
  } = PORTALS;

  const portalKeys = Object.keys(PORTALS);
  const [portalName, setPortalName] = useState(SALES.name);
  const [selectedPortal, setSelectedPortal] = useState();
  const [isOpenPortal, togglePortal] = useState(false);
  const [addAssetDetails] = useMutation(ADD_ASSET_DETAILS);

  useEffect(() => {
    portalKeys.forEach((row) => {
      const portalObj = PORTALS[row];
      if (pathname.includes(portalObj.slug)) setPortalName(portalObj.name);
    });
  }, [portalName, pathname]);

  const handleChange = async (portal) => {
    if (editStatus) {
      setSelectedPortal(portal);
      setModalProp({
        isVisibleModal: true,
        path: searchedBy,
      });
      return;
    }
    await setPortalName(portal.name);
    history.push(portal.link);
    dispatch(
      toggleAttentionDrawer({
        isOpen: false,
        heading: '',
        drawerName: '',
      })
    );
  };

  const handleOpenDropdown = () => togglePortal((prevState) => !prevState);

  const handleDiscardChanges = async () => {
    await handleEditAsset(false, null);
    if (selectedPortal) {
      setPortalName(selectedPortal.name);
      history.push(selectedPortal.link);
    }
  };

  const removeProperties = (updatedAssetDetails) => {
    const properties = [
      'createdAt',
      'updatedAt',
      'status',
      'userId',
      'assetId',
      'isEditing',
    ];
    const details = cloneDeep(updatedAssetDetails);
    const changes = (details) => {
      const temp = details;
      transform(details, (result, value, key) => {
        if (properties.indexOf(key) >= 0) delete temp[key];
        if (isObject(value) || isArray(value)) changes(value);
        return details;
      });
      return temp;
    };
    return changes(details);
  };

  const handleSaveChanges = async () => {
    let updatedAssetDetails = {};
    updatedAssetDetails = cloneDeep(getAssetDetails);
    updatedAssetDetails.interiorVideoContact = {
      ...assetDetail.interiorVideoContact,
      ...updatedAssetDetails.interiorVideoContact,
    };
    const modifiedData = removeProperties(updatedAssetDetails);
    modifiedData.assetId = updatedAssetDetails.asset.id;
    modifiedData.status = 'Inprogress';
    if (tabId === 6) modifiedData.status = 'Complete';
    if (modifiedData.interiorVideoContact) {
      const { shootTime } = modifiedData.interiorVideoContact;
      if (isObject(shootTime)) {
        modifiedData.interiorVideoContact.shootTime = dateTimeFormatter(
          shootTime,
          'hh:mm:ss'
        );
      }
    }
    if (isEmpty(modifiedData.interiorVideoContact)) {
      modifiedData.interiorVideoContact = null;
    }
    delete modifiedData.asset;
    try {
      await addAssetDetails({
        variables: { projectPlan: plan?.type ?? 'PLATINUM', ...modifiedData },
        update: (cache, { data: { addAssetDetails } }) => {
          toast.success('Asset details added successfully');
          if (selectedPortal) {
            setPortalName(selectedPortal.name);
            history.push(selectedPortal.link);
          }
        },
        error: (e) => {
          toast.error(e.message);
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
    setSelectedPortal(null);
    toggleUnfinishedModal();
    await handleEditAsset(false, null);
  };

  return (
    <>
      <RBAC
        allowedRoles={[
          ROLES.ADMIN,
          ROLES.VIDEOMS_PM,
          ROLES.USER,
          ROLES.VIDEOMS_QA,
          ROLES.VIDEOMS_QC
        ]}
      >
        <div className="portal-dropdown-new">
          <Dropdown isOpen={isOpenPortal} toggle={handleOpenDropdown}>
            <DropdownToggle
              outline
              className="border-0 menu-main-button"
              color="primary"
              title="Switch to..."
            >
              <FontAwesomeIcon icon={faTh} />
            </DropdownToggle>
            <DropdownMenu right className="p-2">
              <Scrollbars
                autoHide
                className="row"
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{
                  height: `274px`,
                  width: 'calc(100% + 22px)',
                }}
              >
                <Col md={12}>
                  <div className="d-flex flex-wrap">
                    <RBAC
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.USER,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem onClick={() => handleChange(SALES)}>
                        <span className="logo-name">
                          <img src={SPImg} alt="ssd" />
                          {SALES.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.USER,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem onClick={() => handleChange(MANAGEMENT)}>
                        <span className="logo-name">
                          <img src={MPImg} alt="ssd" />
                          {MANAGEMENT.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.OPERATOR,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem
                        onClick={() => handleChange(BROKERS_DIRECTORY)}
                      >
                        <span className="logo-name">
                          <img src={BDImg} alt="ssd" />
                          {BROKERS_DIRECTORY.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem onClick={() => handleChange(PM_PORTAL)}>
                        <span className="logo-name">
                          <img src={PMImg} alt="ssd" />
                          {PM_PORTAL.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.VIDEOMS_EDITOR,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem onClick={() => handleChange(EDITOR_PORTAL)}>
                        <span className="logo-name">
                          <img src={EDImg} alt="ssd" />
                          {EDITOR_PORTAL.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.VIDEOMS_PM,
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_QA,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem onClick={() => handleChange(PILOT_PORTAL)}>
                        <span className="logo-name">
                          <img src={PPImg} alt="ssd" />
                          {PILOT_PORTAL.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                    <RBAC
                      allowedRoles={[
                        ROLES.VIDEOMS_PM,
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_QA,
                        ROLES.VIDEOMS_QC
                      ]}
                    >
                      <DropdownItem
                        onClick={() => handleChange(SALES_PERSON_PORTAL)}
                      >
                        <span className="logo-name">
                          <img src={SPPImg} alt="ssd" />
                          {SALES_PERSON_PORTAL.name}
                        </span>
                      </DropdownItem>
                    </RBAC>
                  </div>
                </Col>
              </Scrollbars>
            </DropdownMenu>
          </Dropdown>
        </div>
      </RBAC>
      {isVisibleModal && (
        <SaveDetailWarningModal
          isVisibleModal={isVisibleModal}
          propertyName={propertyName}
          toggleUnfinishedModal={toggleUnfinishedModal}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveChanges={handleSaveChanges}
          isCompleteIVC={isCompleteIVC}
          isIVCDisabled={isIVCDisabled}
        />
      )}
    </>
  );
};
export default PortalDropdown;
