import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//
import Paginator from 'common/Paginator';
import { dealsDetailsInfo } from 'pages/SalesPersonPortal/SalesPersonPortalActions';
import DealsTable from './components/DealsTable';

const DealTableRenderer = ({
  refetch,
  filters,
  pageCount,
  totalCount,
  dealsData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const limit = 10;

  const {
    location: { pathname },
  } = history;

  const goToTop = async (filters) => {
    const { page, offset, limit, globalSearchText, ...rest } = filters;
    await dispatch(
      dealsDetailsInfo({
        dealsDetailsInfo: {
          filters: { ...rest },
          globalSearchText,
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  return (
    <div>
      <div className="update-record-scroll">
        <DealsTable tableData={dealsData} />
      </div>
      <div className="pb-3">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={dealsData.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </div>
  );
};

export default DealTableRenderer;
