import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
//
import NoData from 'common/NoData';
import { ROLES } from 'constants/role';
import {
  CREXI_BRONZE,
  FORMIK_FIELDS,
  characterLimitValidation,
  validateCustomField,
  verifyAttrType,
  CHAPTER_FIELD_CONSTANT,
  DISABLED_CHECKBOX_FIELDS,
} from 'pages/CrexiBronze/constant';
import PageLoader from 'common/PageLoader';
import CharacterCounter from 'pages/CrexiBronze/common/CharacterCounter';
import { crexiBronzeCurrentPreviewURL } from 'pages/CrexiBronze/CrexiBronzeAction';
import UploadImages from 'pages/CrexiBronze/common/UploadImages';
import FormFooter from './components/FormFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const ChapterForms = ({
  formDataValues = {},
  projectUUID,
  isLoading,
  refetchBronzeData,
  userRole,
}) => {
  const dispatch = useDispatch();
  const [textInitialValues, setTextInitialValues] = useState();
  const [propertyBoundaryImg, setPropertyBoundaryImg] = useState(null);
  const { currentChapter } = useSelector((state) => state.crexiBronze);
  const {
    ISMARKED_FOR_TEMPLATE,
    CHAPTER_FIVE,
    CUSTOM,
    TENANCY,
    INSTRUCTION_FOR_EDITOR,
  } = CHAPTER_FIELD_CONSTANT;

  const createMultiFieldValues = (childValues) => {
    const temp = [];
    childValues.forEach((item) => {
      const { attributeValue, value } = item;
      if (isArray(value)) {
        let obj = {};
        value.forEach((itemValue, index) => {
          obj = { ...obj, [`${attributeValue}_${index}`]: itemValue };
        });
        temp.push(obj);
      } else {
        temp.push({ [attributeValue]: value });
      }
    });
    return temp;
  };

  useEffect(() => {
    let temp = {};
    if (formDataValues?.formData) {
      const { formData } = formDataValues;
      formData.forEach((item) => {
        const { attributeValue } = item;
        if (verifyAttrType(attributeValue)) {
          temp = {
            ...temp,
            ...('value' in item && { [attributeValue]: item.value }),
            ...(item.children?.length && {
              [attributeValue]: createMultiFieldValues(item.children),
            }),
            ...(ISMARKED_FOR_TEMPLATE in item && {
              [`CHECKBOX_${attributeValue}`]: item.isMarkedForTemplate,
            }),
            ...('label' in item &&
              'isEditable' in item &&
              item.isEditable && {
                [`LABEL_${attributeValue}`]: item.label,
              }),
          };
        } else if (attributeValue.includes('IMAGE_')) {
          dispatch(
            crexiBronzeCurrentPreviewURL({ currentPreviewURL: item.value })
          );
        }
      });
    }

    setTextInitialValues(temp);
  }, [formDataValues?.formData]);

  const renderCheckBoxField = (
    attributeValue,
    label = '',
    disabledField = false
  ) => (
    <>
      <Field
        type="checkbox"
        className="crexi-bronze-checkbox"
        key={attributeValue}
        name={attributeValue}
        disabled={
          (userRole === ROLES.VIDEOMS_EDITOR &&
            attributeValue === 'CHECKBOX_INSTRUCTION_FOR_EDITOR') ||
          disabledField
        }
      />
      {label && <Label className="label-color">{label}</Label>}
    </>
  );

  const renderChildField = (attributeValue, index, childItem, values) => {
    const {
      attributeValue: childAttribute,
      fieldType,
      label,
      placeholder,
    } = childItem;
    const formikValue = `${attributeValue}.${[index]}.${[childAttribute]}`;
    if (fieldType !== 'checkbox') {
      return (
        <Field
          label={label}
          key={formikValue}
          name={formikValue}
          placeholder={placeholder}
          component={FORMIK_FIELDS[fieldType]}
          className="form-control crexi-bronze-input"
          validate={
            attributeValue.includes(CUSTOM)
              ? () => validateCustomField(values)
              : ''
          }
        />
      );
    }
    return renderCheckBoxField(formikValue, label);
  };

  const renderMultiChildFields = (
    childItem,
    childIndex,
    parentAttributeValue
  ) => {
    return childItem.value.map((_, subChildIndex) => {
      const { attributeValue, fieldType, placeholder } = childItem;
      const customKey = `${attributeValue}_${subChildIndex}`;
      return (
        <Field
          key={`${parentAttributeValue}.${[childIndex]}.${[customKey]}`}
          name={`${parentAttributeValue}.${[childIndex]}.${[customKey]}`}
          component={FORMIK_FIELDS[fieldType]}
          className="form-control crexi-bronze-input"
          placeholder={placeholder[subChildIndex]}
        />
      );
    });
  };

  const getInputfieldStyle = (childItem, currentChapter) => {
    if (
      childItem?.value?.length === 4 &&
      childItem?.attributeValue !== 'CUSTOM_HEADING_1' &&
      childItem?.attributeValue !== 'CUSTOM_HEADING_0'
    ) {
      return 'pl-0 cb-multiple-fields p-0';
    }
    if (currentChapter === 5) {
      return 'pl-0 p-0 mb-3';
    }

    return 'pl-0';
  };

  const getInputfieldSize = (childItem, currentChapter, attributeValue) => {
    if (currentChapter === 5) {
      return childItem.value.length === 4 ? 12 : 6;
    }

    if (
      childItem?.attributeValue === 'CUSTOM_HEADING_0' ||
      childItem?.attributeValue === 'CUSTOM_HEADING_1'
    ) {
      return 4;
    }

    if (
      attributeValue === 'CALLOUTS_IN_100_MILE_RADIUS' ||
      attributeValue === 'CALLOUTS_IN_10_MILE_RADIUS'
    ) {
      return 12;
    }

    return childItem.value.length === 4 ? 12 : 4;
  };

  const renderChildFields = (multiFieldData, values) => {
    const { attributeValue, children, label } = multiFieldData;
    return (
      <>
        <div className="mb-1">
          {attributeValue === CHAPTER_FIVE ? null : (
            <Label
              check
              style={{
                left: ISMARKED_FOR_TEMPLATE in multiFieldData ? '' : '0px',
              }}
              className="crexi-bronze-label mb-1"
            >
              {label}
            </Label>
          )}
        </div>
        <Row className={`m-0 ${currentChapter === 5 ? 'd-block' : ''}`}>
          <FieldArray
            name={attributeValue}
            render={() => (
              <>
                {children &&
                  children.map((childItem, index) => {
                    return (
                      <Col
                        md={getInputfieldSize(
                          childItem,
                          currentChapter,
                          attributeValue
                        )}
                        key={childItem.attributeValue}
                        className={getInputfieldStyle(
                          childItem,
                          currentChapter
                        )}
                      >
                        {!isArray(childItem.value)
                          ? renderChildField(
                              attributeValue,
                              index,
                              childItem,
                              values
                            )
                          : renderMultiChildFields(
                              childItem,
                              index,
                              attributeValue
                            )}
                      </Col>
                    );
                  })}
              </>
            )}
          />
        </Row>
      </>
    );
  };

  const customLabelFocus = (attributeValue) => {
    document.getElementById(`LABEL_${attributeValue}`).focus();
  };

  const renderLabel = (attributeValue, item) => {
    return item?.isEditable ? (
      <div className="crexi-bronze-custom-input d-flex justify-content-between">
        <div className="d-flex">
          <Field
            type="label"
            id={`LABEL_${attributeValue}`}
            key={`LABEL_${attributeValue}`}
            name={`LABEL_${attributeValue}`}
            className="crexi-bronze-custom-label mb-1"
          />
          <div className="pl-5">
            <ErrorMessage
              name={`LABEL_${attributeValue}`}
              render={(msg) => {
                return <div className="text-danger font-size-12">{msg}</div>;
              }}
            />
          </div>
        </div>
        <span className="ml-4 pr-2">
          <FontAwesomeIcon
            icon={faEdit}
            title="Edit Field"
            onClick={() => customLabelFocus(attributeValue)}
            className="text-primary f-10 cursor-pointer"
          />
        </span>
      </div>
    ) : (
      <Label check className="crexi-bronze-label mb-1">
        {item.label ?? ''}
      </Label>
    );
  };

  const renderFormikFields = (values) => {
    if (!formDataValues?.formData?.length)
      return <NoData customMessage="No fields available in this chapter." />;
    return formDataValues?.formData.map((item) => {
      const { attributeValue, fieldType } = item;
      if (verifyAttrType(attributeValue))
        return (
          <Col
            key={attributeValue}
            md={12}
            className={`mb-2 crexi-bronze-fields ${
              attributeValue === TENANCY ? 'p-0' : 'pl-0'
            }`}
          >
            <FormGroup check className="pl-0">
              {ISMARKED_FOR_TEMPLATE in item &&
                renderCheckBoxField(
                  `CHECKBOX_${attributeValue}`,
                  '',
                  item.fieldDisable
                )}
              {fieldType !== 'multiple' && (
                <>
                  {renderLabel(attributeValue, item)}
                  <Field
                    key={attributeValue}
                    name={attributeValue}
                    component={FORMIK_FIELDS[fieldType]}
                    className="form-control crexi-bronze-input"
                    options={
                      fieldType === 'select' && isArray(item.options)
                        ? item.options
                        : []
                    }
                    disabled={
                      (userRole === ROLES.VIDEOMS_EDITOR &&
                        attributeValue === INSTRUCTION_FOR_EDITOR) ||
                      item.fieldDisable
                    }
                    defaultoption="select"
                  />
                  {attributeValue === INSTRUCTION_FOR_EDITOR && (
                    <CharacterCounter fieldValue={values} />
                  )}
                </>
              )}
              {fieldType === 'multiple' && renderChildFields(item, values)}
            </FormGroup>
          </Col>
        );

      return null;
    });
  };

  const getImageSpacing = (currentChapter) => {
    if (currentChapter === 5) {
      return 'upload-chapter-logo';
    }
    if (currentChapter === 6) {
      return 'upload-chapter-image';
    }
    return '';
  };

  return (
    <Formik
      enableReinitialize
      initialValues={textInitialValues}
      validationSchema={characterLimitValidation(formDataValues)}
      children={({ values, isSubmitting, isValid, setSubmitting }) => (
        <Form className="w-100">
          <div className="pl-3 pb-4 pt-0 crexi-bronze-content">
            <p className="font-size-16 my-3">
              {currentChapter === 1 && CREXI_BRONZE.CHAPTER_ONE_HEADING}
            </p>
            {!isLoading ? (
              <>
                <Row className="m-0">{renderFormikFields(values)}</Row>
                <div className={getImageSpacing(currentChapter)}>
                  {projectUUID && (
                    <UploadImages
                      projectUuid={projectUUID}
                      currentChapter={currentChapter}
                      refetchBronzeData={refetchBronzeData}
                      setPropertyBoundaryImg={setPropertyBoundaryImg}
                      propertyBoundaryImg={propertyBoundaryImg}
                    />
                  )}
                </div>
              </>
            ) : (
              <PageLoader />
            )}
          </div>
          <hr className="mt-0" />
          <FormFooter
            formValues={values}
            formDataValues={formDataValues}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            isValid={isValid}
            isLoading={isLoading}
            projectUUID={projectUUID}
            propertyBoundaryImg={propertyBoundaryImg}
          />
        </Form>
      )}
    />
  );
};

export default ChapterForms;
