import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
//
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { CREATE_PAYMENT_INTENT } from 'pages/Vidpitch/VidPitchGraphQL';
import VideoCheckOut from './components/VideoCheckOut';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const VideoForm = ({ uuid, email }) => {
  const appearance = {
    theme: 'stripe',
  };
  const [clientSecret, setClientSecret] = useState('');

  const [createPaymentIntent, { loading, error, data }] = useLazyQuery(
    CREATE_PAYMENT_INTENT
  );

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (clientSecret) {
      setClientSecret(clientSecret);
      return;
    }

    createPaymentIntent({
      variables: {
        uuid,
        brokerEmail: email,
      },
      fetchPolicy: 'network-only',
    });
  }, []);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  if (data?.createPaymentIntent?.clientSecret) {
    const {
      createPaymentIntent: { clientSecret },
    } = data;

    return (
      <>
        {clientSecret ? (
          <Elements
            options={{ clientSecret, appearance }}
            stripe={stripePromise}
          >
            <VideoCheckOut uuid={uuid} email={email} />
          </Elements>
        ) : (
          <NoData customMessage="Something bad happened, please try after some time." />
        )}
      </>
    );
  }

  if (!data) {
    return (
      <>
        {clientSecret && (
          <Elements
            options={{ clientSecret, appearance }}
            stripe={stripePromise}
          >
            <VideoCheckOut uuid={uuid} email={email} />
          </Elements>
        )}
      </>
    );
  }

  return (
    <NoData customMessage="Something bad happened, please try after some time." />
  );
};

export default VideoForm;
