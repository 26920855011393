import React, { useContext } from 'react';
import { Spinner } from 'reactstrap';
import { recordsToBeUpdatedContext } from 'contexts/RecordsToBeUpdated';

const RecordsToBeUpdated = () => {
  const { recordsToBeUpdated, loading, error } = useContext(
    recordsToBeUpdatedContext
  );

  if (error) return `Error! ${error.message}`;

  const renderCount = () => {
    if (loading) return <Spinner size="sm" color="primary" />;

    return (
      <strong>
        {' '}
        {recordsToBeUpdated && recordsToBeUpdated > 0
          ? recordsToBeUpdated
          : 0}{' '}
      </strong>
    );
  };

  return <span>Records to be Updated: {renderCount()}</span>;
};

export default RecordsToBeUpdated;
