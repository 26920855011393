import React from 'react';
import { Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';
import { DRAWER_HEADER_ICONS } from 'constants/appConstants';
import AttentionNotifications from 'pages/ManagementPortal/common/Header/components/AttentionNotifications';
import CloseButton from './components/CloseButton';

const DrawerRight = () => {
  const dispatch = useDispatch();
  const {
    attentionDrawerInfo: { isOpen, heading, drawerName },
  } = useSelector((state) => state.app);

  const handleDrawerClose = () => {
    dispatch(
      toggleAttentionDrawer({
        isOpen: false,
        heading: '',
        drawerName: '',
      })
    );
  };

  const renderDrawerContent = () => {
    let html = null;
    switch (drawerName) {
      case 'ATTENTION_DRAWER':
        html = <AttentionNotifications handleDrawerClose={handleDrawerClose} />;
        break;
      default:
        html = null;
    }
    return html;
  };

  const slideDrawerClass = isOpen ? 'slide drawer' : 'drawer';

  if (isOpen) {
    return (
      <div className={slideDrawerClass}>
        <header>
          <img
            className="d-inline-blockf mr-2"
            src={DRAWER_HEADER_ICONS[drawerName]}
            alt={drawerName}
          />
          <h6 className="d-inline-block mb-0">{heading}</h6>
          <CloseButton handleDrawerClose={handleDrawerClose} />
        </header>
        <div className="drawer-body py-3">
          <Scrollbars
            autoHide
            className="row"
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ width: `calc(100% + 15px)`, height: `calc(100vh - 60px)` }}
          >
            <Col sm="12">{renderDrawerContent()}</Col>
          </Scrollbars>
        </div>
      </div>
    );
  }
  return null;
};

export default DrawerRight;
