import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Col,
  Row,
  Card,
  CardBody,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { ACTIVE_PROJECT_COLUMN } from 'pages/ManagementPortal/constant';
import { dateTimeFormatter, numberWithCommas } from 'utils/helper';

const AssetDetails = ({ detail }) => {
  const {
    broker,
    crexiUrl,
    askingPrice,
    listingDate,
    propertyType,
    thumbnailUrl,
  } = detail;

  const [dropdownOpen, setOpen] = useState(false);

  const toggleBrokerDropdown = () => setOpen(!dropdownOpen);

  const renderBrokerCount = () => {
    return (
      <div className="d-flex align-items-center border-left-3">
        <ButtonDropdown
          className="bg-white"
          isOpen={dropdownOpen}
          toggle={toggleBrokerDropdown}
        >
          <DropdownToggle className="bg-white border-0 text-dark count-btn">
            + {broker.length - 1}
          </DropdownToggle>
          <DropdownMenu className="border-radius-8px border-0 shadow-lg">
            {broker.map((row, index) => {
              if (index > 0) {
                return (
                  <DropdownItem
                    key={`${row.brokerName}_${index + 1}`}
                    className="py-0 px-1"
                  >
                    <div className="d-flex align-items-center flex-fill p-2">
                      <img
                        src={row.imageLink || 'https://via.placeholder.com/40'}
                        alt="Broker"
                        className="rounded-circle h-40"
                      />
                      <div className="ml-2 ">
                        <h6 className="mb-1 font-size-14">{row.brokerName}</h6>
                        <p className="m-0 font-size-12">BROKER</p>
                      </div>
                    </div>
                  </DropdownItem>
                );
              }
              return null;
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  };

  const renderBrokerNames = () => {
    return (
      <>
        <CardBody className="d-flex p-0">
          <div className="d-flex align-items-center flex-fill p-2">
            <img
              src={broker[0].imageLink || 'https://via.placeholder.com/40'}
              alt="Broker"
              className="rounded-circle h-40"
            />
            <div className="ml-2 ">
              <h6 className="mb-1 font-size-14">{broker[0].brokerName}</h6>
              <p className="m-0 font-size-12">BROKER</p>
            </div>
          </div>
          {broker.length > 1 ? renderBrokerCount() : ''}
        </CardBody>
      </>
    );
  };

  const activeProjectExpandRow = {
    parentClassName: 'border-none-tr',
    renderer: (row) => {
      return (
        <div className="py-3 px-4 project-collapse-detail">
          <div className="d-flex align-items-center">
            <div className="">
              <img
                src={thumbnailUrl}
                alt="broker-img"
                className="border-radius-8px project-image"
              />
            </div>
            <div className="flex-fill ">
              <Row className="m-0">
                <Col md="auto">
                  <Card className="border-radius-8px">
                    {renderBrokerNames()}
                  </Card>
                </Col>
                <Col md="auto" className="ml-auto">
                  <Card className="border-radius-8px p-1">
                    <CardBody className="p-2">
                      <Row>
                        <Col md="auto">
                          <div className="ml-2">
                            <p className="m-0 font-size-12">PROPERTY TYPE</p>
                            <h6 className="mb-1 font-size-14">
                              {!propertyType ? 'Not Specified' : propertyType}
                            </h6>
                          </div>
                        </Col>
                        <Col md="auto" className="border-left border-right">
                          <div className="ml-2">
                            <p className="m-0 font-size-12">LISTING DATE</p>
                            <h6 className="mb-1 font-size-14">
                              {!listingDate
                                ? 'Not Specified'
                                : dateTimeFormatter(listingDate)}
                            </h6>
                          </div>
                        </Col>
                        <Col md="auto">
                          <div className="ml-2">
                            <p className="m-0 font-size-12">ASKING PRICE</p>
                            <h6 className="mb-1 font-size-14">
                              {askingPrice
                                ? `$${numberWithCommas(askingPrice)}`
                                : 'Not Specified'}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="px-3 mt-2">
                <a href={crexiUrl}>{crexiUrl}</a>
              </div>
            </div>
          </div>
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon icon={faCaretDown} />;
      }
      return <FontAwesomeIcon icon={faCaretRight} />;
    },
  };

  return (
    <BootstrapTable
      keyField="id"
      data={[detail]}
      columns={ACTIVE_PROJECT_COLUMN}
      expandRow={activeProjectExpandRow}
      bordered={false}
    />
  );
};

export default AssetDetails;
