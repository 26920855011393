import React from 'react';

import { Card, CardBody } from 'reactstrap';
import SignupForm from 'components/SignupForm';
import Logo from 'assets/videoms-logo.png';

const Signup = () => {
  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100">
          <CardBody>
            <div className="text-center">
              <img src={Logo} alt="Logo" width="100"/>
            </div>
            <SignupForm />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Signup;
