import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';

import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import CityAndCalloutForm from 'pages/Search/common/CityAndCalloutForm';
import PageLoader from '../../../../common/PageLoader';

const CalloutsIn10Miles = () => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);
  if (loading) return <PageLoader />;
  if (getAssetDetails) {
    const { nearbyCallouts } = getAssetDetails;

    return (
      <Formik
        initialValues={{ nearbyCallouts }}
        enableReinitialize
        children={(props) => (
          <>
            <PropertyDetailHeader
              fieldKey="nearbyCallouts"
              values={props.values}
              getAssetDetails={getAssetDetails}
              isValid
              refetch={refetchAssetDetail}
            />
            <div className="themed-container property-detail-page">
              <div className="search-result-header ">
                <Card className="shadow border-radius-8px">
                  <CardBody className="px-3">
                    <CityAndCalloutForm
                      {...props}
                      fieldName="nearbyCallouts"
                      type="10miles"
                      distanceFromAsset={10}
                      fieldKey="nearbyCallouts"
                      getAssetDetails={getAssetDetails}
                      refetch={refetchAssetDetail}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </>
        )}
      />
    );
  }
  return null;
};

export default CalloutsIn10Miles;
