import React from 'react';
import { Field } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import FormikSelectField from 'components/Fields/FormikSelectField';
import { cameraOptions, dronePlatformOptions } from 'pages/Pilot/constant';

const Step3 = ({ values, setFieldValue }) => {
  return (
    <div>
      <div className="bg-white card-header mb-4 mt-n3 mx-n4 text-center pilot-progress">
        <h5 className="font-size-14 mb-1">
          <span className="progress-span">
            <CircularProgressbar
              value={66}
              text="3/3"
              styles={buildStyles({
                pathColor: `rgb(58, 180, 43)`,
                textSize: '28px',
              })}
            />
          </span>{' '}
          Camera and Other Details
        </h5>
      </div>

      <FormGroup className="mb-2" size="medium">
        <Field
          id="dronePlatform"
          name="dronePlatform"
          component={FormikSelectField}
          label="Drone Platform"
          className="font-size-13"
          options={dronePlatformOptions}
          defaultoption="select a drone platform"
        />
      </FormGroup>

      <FormGroup className="mb-2" size="medium">
        <Field
          id="camera"
          name="camera"
          component={FormikSelectField}
          label="Cameras"
          className="font-size-13"
          options={cameraOptions}
          defaultoption="select a camera"
        />
      </FormGroup>

      <div>
        <h6 className="my-3 font-size-13 font-weight-normal">
          Do you offer interior drone photography?
        </h6>
        <Row>
          <Col>
            <Label>
              <Field
                type="radio"
                name="offerInteriorDronePhotos"
                value="yes"
                onChange={() => setFieldValue('offerInteriorDronePhotos', true)}
                checked={values.offerInteriorDronePhotos}
              />{' '}
              Yes
            </Label>
          </Col>

          <Col>
            <Label>
              <Field
                type="radio"
                name="offerInteriorDronePhotos"
                value="no"
                onChange={() =>
                  setFieldValue('offerInteriorDronePhotos', false)
                }
                checked={!values.offerInteriorDronePhotos}
              />{' '}
              No
            </Label>
          </Col>
        </Row>
      </div>

      <div>
        <h6 className="my-3 font-size-13 font-weight-normal">
          Do you offer professional interior
          <br /> camera-based photography?
        </h6>
        <Row>
          <Col>
            <Label>
              <Field
                type="radio"
                name="offerProfessionalInteriorDronePhotos"
                value="yes"
                onChange={() =>
                  setFieldValue('offerProfessionalInteriorDronePhotos', true)
                }
                checked={values.offerProfessionalInteriorDronePhotos}
              />{' '}
              Yes
            </Label>
          </Col>

          <Col>
            <Label>
              <Field
                type="radio"
                name="offerProfessionalInteriorDronePhotos"
                onChange={() =>
                  setFieldValue('offerProfessionalInteriorDronePhotos', false)
                }
                value="no"
                checked={!values.offerProfessionalInteriorDronePhotos}
              />{' '}
              No
            </Label>
          </Col>
        </Row>
      </div>

      <div>
        <h6 className="my-3 font-size-13 font-weight-normal">
          Do you offer Matterport or other virtual tour services?
        </h6>
        <Row>
          <Col>
            <Label>
              <Field
                type="radio"
                name="offerMatterPortOrOtherTours"
                value="yes"
                onChange={() =>
                  setFieldValue('offerMatterPortOrOtherTours', true)
                }
                checked={values.offerMatterPortOrOtherTours}
              />{' '}
              Yes
            </Label>
          </Col>

          <Col>
            <Label>
              <Field
                type="radio"
                name="offerMatterPortOrOtherTours"
                value="no"
                onChange={() =>
                  setFieldValue('offerMatterPortOrOtherTours', false)
                }
                checked={!values.offerMatterPortOrOtherTours}
              />{' '}
              No
            </Label>
          </Col>
        </Row>
      </div>

      <div>
        <h6 className="my-3 font-size-13 font-weight-normal">
          Do you offer thermal drone imagery?
        </h6>
        <Row>
          <Col>
            <Label>
              <Field
                type="radio"
                name="offerThermalDrone"
                value="yes"
                onChange={() => setFieldValue('offerThermalDrone', true)}
                checked={values.offerThermalDrone}
              />{' '}
              Yes
            </Label>
          </Col>

          <Col>
            <Label>
              <Field
                type="radio"
                name="offerThermalDrone"
                value="no"
                onChange={() => setFieldValue('offerThermalDrone', false)}
                checked={!values.offerThermalDrone}
              />{' '}
              No
            </Label>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Step3;
