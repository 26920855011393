import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const PilotAlreadyInvitedModal = ({
  invitePilotPromptModal,
  togglePilotInviteModal,
  togglePilotInvitePromptModal,
}) => {
  const toggleModal = () => {
    togglePilotInviteModal(true);
    togglePilotInvitePromptModal(false);
  };
  return (
    <Modal
      isOpen={invitePilotPromptModal}
      toggle={() => togglePilotInvitePromptModal(false)}
      className="modal-dialog-centered editor-invite-prompt-modal"
    >
      <ModalHeader>Pilot Invite Exist</ModalHeader>
      <ModalBody>
        <span>
          A pilot is already invited on this project. <br />
          <b>Do you want to invite new pilot ?</b>
        </span>
        <div className="text-right pt-4">
          <Button
            outline
            color="primary"
            className=" btn-sm px-2 font-size-13"
            onClick={() => togglePilotInvitePromptModal(false)}
          >
            No
          </Button>
          <Button
            type="submit"
            color="primary"
            className="btn-sm px-3 font-size-13 ml-2"
            onClick={() => toggleModal()}
          >
            Yes
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PilotAlreadyInvitedModal;
