import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Row } from 'reactstrap';
import FolderComponent from 'common/FolderComponent';
import { PILOT_ASSET_FOLDERS_LISTING } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import { parseToInteger } from 'utils/helper';
import PageLoader from 'common/PageLoader';
import CloseIcon from 'assets/close-icon.svg';
import NoData from 'common/NoData';

const Folders = ({
  projectId,
  setToggle,
  setUploadPath,
  toggleProjectFolderModal,
  setFolderName,
}) => {
  const { loading, error, data, refetch } = useQuery(
    PILOT_ASSET_FOLDERS_LISTING,
    {
      variables: {
        projectId: parseToInteger(projectId),
        folderTypes: 'Sales',
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const handleFilePath = (path, status, title) => {
    setUploadPath(path);
    setToggle(status);
    setFolderName(title);
  };

  return (
    <>
      <div className="video-review-folder">
        <div className="mb-3">
          <Row>
            <Col md={8}>
              <h5 className="font-size-16">Project Documents</h5>
            </Col>
            <Col md={4}>
              <div className="text-right">
                <Button
                  color="link"
                  onClick={() => {
                    setToggle(true);
                    toggleProjectFolderModal(false);
                  }}
                  className=" py-0 back-btn color-inherit px-0 folder-back-btn"
                >
                  <img src={CloseIcon} alt="close" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          {data &&
            data.pilotAssetFoldersListing.map(
              ({ id, title, uploadingPath }) => {
                return (
                  <Col md={3} className="mb-3" key={id}>
                    <div
                      className="text-decoration-none text-body"
                      onClick={() =>
                        handleFilePath(uploadingPath, false, title)
                      }
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setToggle(false)}
                    >
                      <FolderComponent folderName={title} />
                    </div>
                  </Col>
                );
              }
            )}
        </Row>
      </div>
    </>
  );
};

export default Folders;
