import { gql } from '@apollo/client';

export const PILOT_PROJECT_DETAILS = gql`
  query pilotProjectDetails($projectId: Int!, $dataSource: String!) {
    pilotProjectDetails(projectId: $projectId, dataSource: $dataSource)
  }
`;

export const PILOT_PROJECT_LISTING = gql`
  query pilotProjectListing($limit: Int, $offset: Int) {
    pilotProjectListing(limit: $limit, offset: $offset)
  }
`;

export const PILOT_ASSET_FOLDERS_LISTING = gql`
  query pilotAssetFoldersListing(
    $projectId: Int!
    $folderTypes: String!
    $isMarkedCompleted: Boolean
    $status: String
  ) {
    pilotAssetFoldersListing(
      projectId: $projectId
      folderTypes: $folderTypes
      isMarkedCompleted: $isMarkedCompleted
      status: $status
    )
  }
`;

export const PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT = gql`
  mutation projectAcceptedByEditorOrPilot(
    $projectId: Int!
    $status: String!
    $flag: String!
  ) {
    projectAcceptedByEditorOrPilot(
      projectId: $projectId
      status: $status
      flag: $flag
    )
  }
`;

export const PILOT_ASSET_UPLOADED = gql`
  mutation pilotAssetUploaded($id: Int!, $isAssetUploaded: Boolean!) {
    pilotAssetUploaded(id: $id, isAssetUploaded: $isAssetUploaded)
  }
`;

export const PILOT_MARK_COMPLETED = gql`
  mutation pilotMarkCompleted(
    $folderId: Int!
    $projectId: Int!
    $isMarkedCompleted: Boolean!
  ) {
    pilotMarkCompleted(
      folderId: $folderId
      projectId: $projectId
      isMarkedCompleted: $isMarkedCompleted
    )
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $role: String!
    $email: String!
    $input: UpdateDetailsInput!
  ) {
    updateProfile(role: $role, email: $email, input: $input)
  }
`;

export const ADD_NOTES = gql`
  mutation addNotes(
    $notesFrom: Int!
    $defaultFolderFlag: Boolean
    $notesTo: Int!
    $folderId: Int!
    $notesUserRole: String!
    $noteText: String!
    $projectId: Int
  ) {
    addNotes(
      notesFrom: $notesFrom
      notesTo: $notesTo
      defaultFolderFlag: $defaultFolderFlag
      folderId: $folderId
      notesUserRole: $notesUserRole
      noteText: $noteText
      projectId: $projectId
    )
  }
`;

export const LIST_NOTES = gql`
  query listNotes($input: ListNotesInput) {
    listNotes(input: $input)
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails($userId: Int!) {
    getUserDetails(userId: $userId)
  }
`;

export const PILOT_FLIGHT_RESCHEDULE = gql`
  mutation pilotFlightRescheduled(
    $dueDate: String!
    $projectId: Int!
    $isScheduled: Boolean!
  ) {
    pilotFlightRescheduled(
      dueDate: $dueDate
      projectId: $projectId
      isScheduled: $isScheduled
    )
  }
`;

export const PILOT_FOLDER_FLAGS = gql`
  query pilotFolderFlags($projectId: Int!) {
    pilotFolderFlags(projectId: $projectId)
  }
`;

export const PILOT_CUSTOM_FOLDERS = gql`
  mutation pilotCustomFolders(
    $title: String!
    $description: String!
    $folderTypes: String!
    $projectId: Int!
  ) {
    pilotCustomFolders(
      title: $title
      description: $description
      folderTypes: $folderTypes
      projectId: $projectId
    )
  }
`;

export const GET_FLIGHT_DETAILS = gql`
  query getFlightDetails($projectId: Int!) {
    getFlightDetails(projectId: $projectId)
  }
`;

export const ADDITIONAL_NOTES_ADDED = gql`
  subscription addedAdditionalNotes {
    addedAdditionalNotes
  }
`;
export const PILOT_MARK_COMPLETED_FOLDER = gql`
  subscription pilotMarkCompletedFolder {
    pilotMarkCompletedFolder
  }
`;
export const PILOT_ASSET_UPDATED = gql`
  subscription pilotAssetUpdated {
    pilotAssetUpdated
  }
`;

export const PILOT_EDITOR_INVITATION = gql`
  subscription userInvited {
    userInvited
  }
`;

export const PILOT_EDITOR_INVITED_ACCEPTED = gql`
  subscription pilotOrEditorInviteAccepted {
    pilotOrEditorInviteAccepted
  }
`;
