import * as Yup from 'yup';

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const profileValidationSchema = Yup.object({
  address: Yup.object({
    address: Yup.string().trim().required('Address is required'),
    state: Yup.string().trim().required('State is required'),
    city: Yup.string().trim().required('City is required'),
    zip: Yup.string()
      .required('Zip is required')
      .matches(zipRegExp, 'ZIP Code is not valid'),
  }),
  phone: Yup.string()
    .required('Phone is Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  officePhone: Yup.string()
    .required('Office Phone is Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});
