import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ProjectDetail from './components/ProjectDetail';

const ProjectDetailModal = ({
  isProjectDetailModalOpen,
  toggleProjectDetailModal,
  name,
}) => {
  return (
    <Modal
      isOpen={isProjectDetailModalOpen}
      toggle={() => toggleProjectDetailModal(false)}
      className="project-details-spp modal-xl mr-0 mt-0 mb-0"
    >
      <ModalBody className="px-4 py-0">
        <ProjectDetail
          toggleProjectDetailModal={toggleProjectDetailModal}
          name={name}
        />
      </ModalBody>
    </Modal>
  );
};

export default ProjectDetailModal;
