import React, { useMemo, useState } from 'react';
import { Table } from 'reactstrap';
import EditorItem from './components/EditorItem';
import EditorProfileDetails from '../../../EditorProfileDetails';
import EditorEditModal from '../../../EditorEditModal';

const EditorsTable = ({
  tableData,
  dataSource,
  projectId,
  editorSearchFilters,
}) => {
  const [isEditorProfileModalOpen, setEditorProfileModal] = useState(false);
  const [isEditProfileModalOpen, setEditProfileModal] = useState(false);
  const [currentEditorDetails, setCurrentEditorDetails] = useState({});
  const [editorDetails, setEditorDetails] = useState({});

  const toggleEditorProfileModal = (status, editorData) => {
    setEditorProfileModal(status);
    setCurrentEditorDetails(editorData);
  };

  const toggleEditProfileModal = (status, editorData) => {
    setEditProfileModal(status);
    setEditorDetails(editorData);
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
      const sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(tableData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return '';
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table responsive className="table record-update">
        <thead className="editorListTable">
          <tr className=" bg-purple-dark text-white">
            <th colSpan="2">NAME </th>
            <th>LOCATION</th>
            <th
              onClick={() => {
                requestSort('projectCompleted');
              }}
              className={getClassNamesFor('projectCompleted') || 'descending'}
            >
              PROJECTS COMPLETED
            </th>
            <th
              onClick={() => {
                requestSort('availability');
              }}
              className={getClassNamesFor('availability') || 'descending'}
            >
              AVAILABILITY
            </th>
            <th> </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            items.map((row) => {
              return (
                <EditorItem
                  key={Math.random()}
                  editorInfo={row}
                  toggleEditorProfileModal={toggleEditorProfileModal}
                  toggleEditProfileModal={toggleEditProfileModal}
                />
              );
            })}
        </tbody>
      </Table>
      {isEditorProfileModalOpen && (
        <EditorProfileDetails
          isEditorProfileModalOpen={isEditorProfileModalOpen}
          toggleEditorProfileModal={toggleEditorProfileModal}
          editorHeaderInfo={currentEditorDetails}
          dataSource={dataSource}
          projectId={projectId}
          editorSearchFilters={editorSearchFilters}
        />
      )}
      {isEditProfileModalOpen && (
        <EditorEditModal
          isEditProfileModalOpen={isEditProfileModalOpen}
          toggleEditProfileModal={toggleEditProfileModal}
          editorInfo={editorDetails}
        />
      )}
    </>
  );
};

export default EditorsTable;
