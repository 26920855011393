import React from 'react';
import { useQuery } from '@apollo/client';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoData from 'common/NoData';
import AssetFiles from 'components/ProjectAssetProvider/components/AssetFiles';
import { GET_AWS_FILES } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import { Button, Col, Row } from 'reactstrap';
import PageLoader from 'common/PageLoader';
import CloseIcon from 'assets/close-icon.svg';

const Files = ({
  setToggle,
  uploadPath,
  toggleProjectFolderModal,
  folderName,
}) => {
  const { data, loading, error } = useQuery(GET_AWS_FILES, {
    variables: {
      filePath: uploadPath,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  return (
    <div className="video-review-folder">
      <div className="mb-2">
        <Row>
          <Col md={8}>
            <Button
              color="link"
              className="back-btn color-inherit px-0 folder-back-btn"
              onClick={() => setToggle(true)}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
              {folderName}
            </Button>
          </Col>
          <Col md={4}>
            <div className="text-right">
              <Button
                color="link"
                onClick={() => {
                  setToggle(true);
                  toggleProjectFolderModal(false);
                }}
                className="py-0 back-btn color-inherit px-0 folder-back-btn"
              >
                <img src={CloseIcon} alt="close" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {data && data.getAwsFiles.length > 0 ? (
        <Row>
          <AssetFiles getAwsFiles={data.getAwsFiles} hideCheckbox />
        </Row>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default Files;
