import React, { useContext } from 'react';
import moment from 'moment';
import { Table } from 'reactstrap';

import { BRONZE, SLACK_LINK } from 'constants/appConstants';
import moviePlatinum from 'assets/movie.svg';
import slackIMG from 'assets/slack.png';
import asanaIMG from 'assets/asana.png';
import hubspotIcon from 'assets/hubspot-icon.png';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import playIcon from 'assets/play-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import VersionDropdown from './components/VersionDropdown';

const VideoReviewHeader = ({
  forPMPortal,
  projectName,
  elaspedTime,
  frameIoLink,
  asanaLink,
  hubspotLink,
  selectedVersion: { version, updatedAt },
  startDate,
  versionData,
  slackLink,
  handleSelectVersion,
  planType,
}) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const renderSelectedVersion = () => {
    if (version) {
      const versionData = version.split('-');
      return versionData[versionData.length - 1];
    }
    return null;
  };

  function secondsToDhms(second) {
    const sec = moment.duration(second).asSeconds();
    const seconds = Number(sec);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);

    const dDisplay =
      d > 0 ? (
        <span key="day" className="elasped-time-duration-span">
          {d} {d === 1 ? ' day ' : ' days '}
        </span>
      ) : (
        ''
      );
    const hDisplay =
      h > 0 ? (
        <span key="hours" className="elasped-time-duration-span">
          {h} {h === 1 ? ' hour ' : ' hours '}
        </span>
      ) : (
        ''
      );
    const mDisplay =
      m > 0 ? (
        <span key="minutes" className="elasped-time-duration-span">
          {m} {m === 1 ? ' minute ' : ' minutes '}
        </span>
      ) : (
        ''
      );
    return [dDisplay, hDisplay, mDisplay];
  }

  return (
    <div className="video-review-header mt-3">
      <div className="table-1">
        <div className="video-review-head px-2 mx-n3 border-bottom">
          <Table borderless className="mb-0">
            <tbody>
              <tr>
                <td className="font-size-14 py-1 font-weight-bold align-middle">
                  <img
                    className="mr-2"
                    src={
                      planType ? PLAN_TYPE_ICONS[planType].icon : moviePlatinum
                    }
                    alt="planType"
                  />
                  {projectName}
                </td>
                <td className="font-size-14 py-1 align-middle">
                  Start Date:<strong> {dateTimeFormatter(startDate)}</strong>
                </td>
                <td className="font-size-14 py-1 align-middle elapsed-times">
                  Elapsed time:<strong> {secondsToDhms(elaspedTime)}</strong>
                </td>
                <td
                  className="align-middle py-1"
                  style={{ borderLeft: '1px solid #e4e3e8' }}
                >
                  <span className="d-block">
                    Draft Version: {renderSelectedVersion()}
                  </span>
                  <strong>{dateTimeFormatter(updatedAt)}</strong>
                </td>
                <td className="align-middle py-1">
                  <VersionDropdown
                    versionData={versionData}
                    handleSelectVersion={handleSelectVersion}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="table-2">
        <div className="video-review-head px-2 py-2 mx-n3">
          <Table
            borderless
            className="mb-0 pt-3"
            style={{ maxWidth: '1200px' }}
          >
            <tbody>
              <tr>
                <td>
                  <a
                    href={frameIoLink}
                    rel="noreferrer"
                    target="_blank"
                    className="mb-0"
                  >
                    <img src={playIcon} alt={playIcon} className="mr-2" /> Video
                    Review
                  </a>
                </td>
                {planType !== BRONZE && (
                  <td>
                    <a
                      href={asanaLink}
                      rel="noreferrer"
                      target="_blank"
                      onClick={
                        role === ROLES.VIDEOMS_EDITOR
                          ? (e) => e.preventDefault()
                          : ''
                      }
                      className={`mb-0 text-info ${
                        role === ROLES.VIDEOMS_EDITOR ? 'disableStar' : ''
                      }`}
                    >
                      <img src={asanaIMG} alt={asanaIMG} className="mr-2" />{' '}
                      View project in Asana
                    </a>
                    <FontAwesomeIcon
                      icon={faExternalLinkSquareAlt}
                      color="#565082"
                      className="ml-2 align-middle"
                    />
                  </td>
                )}
                <td>
                  <a
                    href={slackLink || SLACK_LINK}
                    rel="noreferrer"
                    target="_blank"
                    className="mb-0"
                  >
                    <img src={slackIMG} alt={slackIMG} className="mr-2" /> Chat
                    on slack
                  </a>
                </td>
                {forPMPortal && planType !== BRONZE && (
                  <td>
                    <a
                      href={hubspotLink}
                      rel="noreferrer"
                      target="_blank"
                      className="mb-0"
                    >
                      <img
                        src={hubspotIcon}
                        alt={hubspotIcon}
                        className="mr-2"
                      />{' '}
                      Hubspot link
                    </a>
                  </td>
                )}
                {/* {forPMPortal && (
                  <td>
                    <a
                      href={hubspotLink}
                      rel="noreferrer"
                      target="_blank"
                      className="mb-0"
                    >
                      <img src={timeline} alt={timeline} className="mr-2" />{' '}
                      Project Detail & Timeline
                    </a>
                  </td>
                )} */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default VideoReviewHeader;
