import React from 'react';
import { dateTimeFormatter } from 'utils/helper';

const BrokerItem = ({ brokerInfo }) => {
  const {
    firstName,
    lastName,
    company,
    phone,
    email,
    totalActiveAssetsCount,
    brokerUpdatedAt,
  } = brokerInfo;

  const renderCellContent = () => {
    return (
      <>
        <td title={firstName}>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {firstName}
          </span>
        </td>
        <td title={lastName}>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {lastName}
          </span>
        </td>
        <td title={company}>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {company}
          </span>
        </td>
        <td>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {totalActiveAssetsCount}
          </span>
        </td>
        <td>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {phone}
          </span>
        </td>
        <td title={email}>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {email}
          </span>
        </td>
        <td>
          <span className="w-75 text-truncate d-inline-block mr-2 align-middle">
            {dateTimeFormatter(brokerUpdatedAt)}
          </span>
        </td>
      </>
    );
  };
  return <tr className="update-record-row">{renderCellContent()}</tr>;
};
export default BrokerItem;
