import React, { useEffect, useContext } from 'react';
import { isEqual } from 'lodash';
import { Form, Field } from 'formik';
import { InputGroup, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';

import { assetDetailContext } from 'contexts/AssetDetailContext';
import FormikInputField from 'components/Fields/FormikInputField';
import FormikDatePicker from 'components/Fields/FormikDatePicker';
import DetailViewNextButton from 'pages/Search/common/DetailViewNextButton';

const InteriorVideoContactFrom = ({
  values,
  fieldKey,
  getAssetDetails,
  interiorVideoContactData,
  refetch,
  isDisabled,
}) => {
  const { handleEditAsset } = useContext(assetDetailContext);

  const { isEditing } = getAssetDetails;
  const getReadAssetDetails = async () => {
    if (
      Object.values(values[fieldKey]).some((row) => row) &&
      !isEqual(interiorVideoContactData, values[fieldKey])
    ) {
      const assetData = { ...getAssetDetails };
      assetData[fieldKey] = values[fieldKey];
      handleEditAsset(true, assetData);
    }
  };

  useEffect(() => {
    getReadAssetDetails();
  }, [values, isEditing]);

  return (
    <Form>
      <Row className="search-result-header ">
        <Col sm="12" xl="6">
          <InputGroup size="medium">
            <Field
              id="interiorVideoContact.name"
              name="interiorVideoContact.name"
              type="text"
              component={FormikInputField}
              label="Name of contact"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="search-result-header ">
        <Col sm="12" xl="4">
          <InputGroup size="medium">
            <Field
              id="email"
              name="interiorVideoContact.email"
              type="email"
              component={FormikInputField}
              label="Email"
            />
          </InputGroup>
        </Col>
        <Col sm="12" xl="2">
          <InputGroup size="medium">
            <Field
              id="phoneNumber"
              name="interiorVideoContact.phone"
              type="number"
              component={FormikInputField}
              label="Phone number"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="search-result-header ">
        <Col sm="auto">
          <InputGroup size="medium">
            <Field
              id="shootDate"
              name="interiorVideoContact.shootDate"
              type="text"
              component={FormikDatePicker}
              label="Shoot timing"
              minDate={new Date()}
            />
            <FontAwesomeIcon icon={faCalendar} className="calender-icon" />
          </InputGroup>
        </Col>
        <Col sm="auto">
          <InputGroup size="medium">
            <Field
              id="shootTime"
              name="interiorVideoContact.shootTime"
              component={FormikDatePicker}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              label="&nbsp;"
            />
            <FontAwesomeIcon icon={faClock} className="calender-icon" />
          </InputGroup>
        </Col>
      </Row>
      <Row className="search-result-header ">
        <Col sm="12" xl="6">
          <InputGroup size="medium">
            <Field
              id="additionalNotes"
              name="interiorVideoContact.additionalNotes"
              type="textarea"
              component={FormikInputField}
              label="Additional notes"
              onChange
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3 px-2">
          <DetailViewNextButton
            values={values}
            fieldKey={fieldKey}
            isValid
            isDisabled={isDisabled}
            getAssetDetails={getAssetDetails}
            refetch={refetch}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default InteriorVideoContactFrom;
