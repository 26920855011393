import React from 'react';
import { Row, Col } from 'reactstrap';
import Home from 'assets/home-1.png';

const SearchResultPlaceholder = ({ message }) => {
  const messageText = message || 'Search results will appear here';
  return (
    <Col>
      <Row style={{ height: 'calc(100vh - 61px)' }}>
        <Col className="align-self-center" align="center">
          <img src={Home} alt="home" />
          <h4 className="no-result-message">{messageText}</h4>
        </Col>
      </Row>
    </Col>
  );
};
export default SearchResultPlaceholder;
