import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import RBFP from 'hoc/RBFP';
import { VIEW } from 'constants/permissions';
import FolderComponent from 'common/FolderComponent';
import { filesAndFoldersTogether, hasSubFolders } from 'utils/helper';
import { FOLDER_NAMES_TO_EXCLUDE } from 'constants/appConstants';

const Folders = ({
  parentName,
  directoryName,
  children,
  projectId,
  portalSlug,
}) => {
  if (!children || !children.length) return null;

  return children.map(({ parent, name, children }) => {
    const commonLinkProps = {
      to: {
        pathname: `/${portalSlug}/projects/${projectId}/folders/${parentName}/${parent}/subFolders`,
        state: {
          directoryName,
          subDirectoryName: name,
          subFolders: children,
          hasFilesInFolderListing: filesAndFoldersTogether(parent),
        },
      },
      className: 'text-decoration-none text-body',
    };

    return (
      <RBFP
        key={name}
        permissionType={VIEW}
        subDirectoryName={name}
        directoryName={directoryName}
      >
        <Col md={3} className="mb-3" key={parent}>
          {hasSubFolders(parent) && (
            <Link
              to={{
                pathname: `/${portalSlug}/projects/${projectId}/folders/${parentName}/${parent}`,
                state: {
                  directoryName,
                  subDirectoryName: name,
                },
              }}
              className="text-decoration-none text-body"
            >
              <FolderComponent folderName={parent} />
            </Link>
          )}

          {FOLDER_NAMES_TO_EXCLUDE.includes(parent) && (
            <Link {...commonLinkProps}>
              <FolderComponent folderName={parent} />
            </Link>
          )}
        </Col>
      </RBFP>
    );
  });
};

export default Folders;
