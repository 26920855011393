import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import RBAC from 'hoc/RBAC';
import NoData from 'common/NoData';
import { ROLES } from 'constants/role';
import PageLoader from 'common/PageLoader';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import { EDITOR_PROJECT_DETAILS } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';
import { BRONZE, NOT_SPECIFIED } from 'constants/appConstants';
import BronzeAssetActions from 'pages/EditorPortal/common/BronzeAssetActions';
import { editorProjectDetails } from 'pages/EditorPortal/EditorPortalActions';
import RequirementSidebar from '../RequirementSidebar';
import ProjectAcceptBox from '../ProjectAcceptBox';

const ProjectDetailDrawer = ({
  projectId,
  isDetailOpen,
  toggleDetails,
  refetchProjects,
  isProjectInvitationDrawer,
}) => {
  const dispatch = useDispatch();
  const { loading, data, error } = useQuery(EDITOR_PROJECT_DETAILS, {
    variables: {
      projectId: parseInt(projectId, 10),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!isProjectInvitationDrawer && !loading && data?.editorProjectDetails) {
      const {
        editorProjectDetails: { crexiUrl, assetName },
      } = data;

      dispatch(
        editorProjectDetails({
          propertyDetail: {
            name: assetName,
            crexiUrl,
          },
        })
      );
    }
  }, [loading, data]);

  const renderDetails = () => {
    const {
      editorProjectDetails: {
        plan,
        projectName,
        projectCompleteDate,
        projectEditorBudget,
        trackStatus,
        dataSource,
      },
    } = data;

    return (
      <div className="d-flex">
        <div className="w-50">
          <h6>{projectName}</h6>
          <div className="mb-4">
            <>
              {plan !== BRONZE ? (
                <RequirementSidebar
                  projectId={projectId}
                  requirementData={data}
                />
              ) : (
                <div className="mt-3">
                  <span>
                    This is a project called CREXI Bronze and consists of
                    building satellite maps, calling out cities or other
                    elements on maps at a general altitude of 200 miles, 100
                    miles and 10 miles.
                    {isProjectInvitationDrawer ? (
                      <span>
                        The data to be included on each map is noted in the CSV
                        file available through the “Download Project Data”
                        button available under PROJECT/TASK DETAILS drawer once
                        you accept the project.
                      </span>
                    ) : (
                      <span>
                        The data to be included on each map is noted in the CSV
                        file available through the “Download Project Data”
                        button on this page.
                      </span>
                    )}
                  </span>
                  <br />
                  <br />
                  <span>
                    This project consists of the following four steps:
                  </span>
                  <ul className="mt-2">
                    <li>Accept the project and project delivery timeline.</li>
                    <li>
                      Build three maps and insert call outs that are listed in
                      the CSV file you download for the project.
                    </li>
                    <li>
                      Upload the three completed maps into the video builder
                      interface, click “Render Video”.
                    </li>
                    <li>
                      If the customer wants map changes, review customer
                      comments noted in the video player and quality control
                      notes and modify maps if needed, then click “Render Video”
                      again.
                    </li>
                  </ul>
                  <span className="mt-2">
                    If you have any questions, please contact your VidTech PM
                    for additional details.
                  </span>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-end">
          <div>
            <div className="bronze-confirmation shadow">
              <div>
                <div className="d-flex justify-content-between px-4">
                  <span className="font-size-12">TYPE</span>
                  <span>
                    <img
                      src={PLAN_TYPE_ICONS[plan].icon}
                      alt={PLAN_TYPE_ICONS[plan].altText}
                      width={20}
                      height={20}
                      className="mr-1"
                    />
                    <b>{plan || NOT_SPECIFIED}</b>
                  </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between px-4">
                  <span className="font-size-12">DUE DATE</span>
                  <span>
                    {dateTimeFormatter(projectCompleteDate) || NOT_SPECIFIED}
                  </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between px-4">
                  <span className="font-size-12">ESTIMATED PRICE</span>
                  <span className="text-success">
                    {plan === BRONZE ? `$30` : projectEditorBudget}
                  </span>
                </div>
                <RBAC allowedRoles={[ROLES.VIDEOMS_EDITOR]}>
                  <>
                    <hr />
                    <div className="px-4">
                      {isProjectInvitationDrawer ? (
                        <ProjectAcceptBox
                          isEditorPortal
                          className="px-4"
                          projectId={projectId}
                          isProjectInvitationDrawer
                          toggleDetails={toggleDetails}
                          refetchProjects={refetchProjects}
                        />
                      ) : (
                        <BronzeAssetActions
                          projectId={projectId}
                          projectName={projectName}
                          toggleDetails={toggleDetails}
                          trackStatus={trackStatus}
                          dataSource={dataSource}
                        />
                      )}
                    </div>
                  </>
                </RBAC>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isDetailOpen}
      toggle={toggleDetails}
      className="project-Folders-spp mr-0 mt-0 mb-0 project-details-spp project-details-bronze"
    >
      <ModalHeader toggle={toggleDetails}>
        <span>Project Details</span>
      </ModalHeader>
      <ModalBody>
        {loading && <PageLoader />}
        {error && <NoData customMessage={error.message} />}
        {data?.editorProjectDetails && renderDetails()}
      </ModalBody>
    </Modal>
  );
};

export default ProjectDetailDrawer;
