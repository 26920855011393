import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import {
  isImage,
  timeFormator,
  removeScreenShots,
  convertTimecodeToSeconds,
  calculatePreviewTimeWithPercentage,
} from 'common/VideoPlayer/util';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { videoPlayerContext } from 'contexts/VideoPlayerContext';
import { UPDATE_REVIEW_ASSET_STATUS } from 'common/VideoPlayer/videoPlayerGraphQL';
import VideoPlayerControllers from '../VideoPlayerControllers';
import DrawAnnotations from '../DrawAnnotations';
import CreateComment from '../CreateComment';
import ListComments from '../ListComments';
import VideoVersionListing from '../VideoVersionListing';
import LeaveCommentModal from '../CreateComment/components/LeaveCommentModal';
import RedirectConfirmationModal from './components/RedirectConfirmationModal';
import loadingGIF from '../../../../assets/loading.gif';

const encodeURL = (url) => {
  try {
    const encordedURL = url?.split('.com/');
    if (encordedURL?.length !== 2) throw new Error('Invalid URL format');

    return `${encordedURL?.[0]}.com/${encodeURIComponent(encordedURL?.[1])}`;
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

const VideoPlayerScreen = ({
  isModal,
  playerData,
  videoSrc,
  refetch,
  isVidPitchPlayer,
  trackVideo,
}) => {
  let assetId;
  let assetURL = videoSrc;
  let assetFileName = '';
  let version = '';
  let reviewStatus = false;
  let fileParentId = null;
  const videoElement = useRef(null);
  const screenshotDivElement = useRef(null);
  const commentScreenshotElement = useRef(null);
  const eleWithScreenshotAndAnnotation = useRef(null);
  const progressbarRef = useRef(null);
  const currentDurationRef = useRef(null);

  const [showAnnotationBoard, setShowAnnotationBoard] = useState(false);
  const [annotationsList, setAnnotationsList] = useState([]);
  const [commentLoading, setCommentLoading] = useState([]);
  const [annotationDetails, setAnnotationDetails] = useState({
    annotationStrockWidth: 5,
    annotationStrockColor: '#C92020',
    type: '',
  });
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const [reviewCompleted, setReviewCompleted] = useState(false);
  const [hasCheckboxCheked, setHasCheckboxCheked] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { playerState, setVideoPlayerDetails } = useContext(videoPlayerContext);
  const [updateReviewAssetStatus] = useMutation(UPDATE_REVIEW_ASSET_STATUS, {
    onCompleted: () => {
      setReviewCompleted(true);
      setShowConfirmationModal(true);
    },
    onError: ({ message }) => {
      toast.error(message);
      setReviewCompleted(false);
    },
  });

  if (playerData?.getReviewLinkDetails?.assetCommentDetails) {
    const {
      id,
      parentId,
      fileName,
      assetLink,
      brokerReviewStatus,
      version: versionName,
    } = playerData.getReviewLinkDetails.assetCommentDetails;
    assetId = id;
    assetFileName = fileName;
    reviewStatus = brokerReviewStatus;
    version = versionName;
    fileParentId = parentId;
    // assetURL = assetLink;
    assetURL = encodeURL(assetLink.split('?').shift());
  }

  const tempVideoEle = document.createElement('video');
  tempVideoEle.crossOrigin = 'anonymous';
  tempVideoEle.src = assetURL;

  const handleOnTimeUpdate = () => {
    const { currentTime, duration } = videoElement.current;
    const progress = currentTime ? (currentTime / duration) * 100 : 0;
    if (progressbarRef?.current) {
      progressbarRef.current.value = progress;
    }

    if (currentDurationRef?.current) {
      currentDurationRef.current.textContent = `${timeFormator(
        currentTime || 0,
        playerState.formatorType
      )}/${timeFormator(duration || 0, playerState.formatorType)}`;
    }
  };

  const handleOnMouseMoveOverProgress = async (e) => {
    const tempPreviewVideoTime = calculatePreviewTimeWithPercentage(
      e,
      videoElement.current.duration,
      playerState.videoPreviewImages,
      progressbarRef.current
    );

    setVideoPlayerDetails({
      ...playerState,
      previewVideoTime: { ...tempPreviewVideoTime },
    });
  };

  const handleMouseOut = () => {
    setVideoPlayerDetails({
      ...playerState,
      previewVideoTime: {
        previewTime: null,
        divPosition: 0,
        // url: null,
      },
    });
  };

  const handleManualVideoProgressUpdate = (event) => {
    const { value } = event.target;
    const manualChange = Number(value);
    videoElement.current.currentTime =
      (videoElement.current.duration / 100) * manualChange;
    setVideoPlayerDetails({
      ...playerState,
      isPlaying: false,
      imageBase64: '',
    });
    if (progressbarRef.current) {
      progressbarRef.current.value = manualChange;
    }

    if (showAnnotationBoard) {
      setShowAnnotationBoard(false);
      removeScreenShots();
    }
  };

  const handleTimeMoveByComment = async (commentsData) => {
    const { commentTime, commentScreenShot } = commentsData;
    setVideoPlayerDetails({
      ...playerState,
      videoLoading: true,
    });
    const timeInSeconds = await convertTimecodeToSeconds(
      commentTime || '00:00:00:00',
      20
    );
    videoElement.current.currentTime = timeInSeconds || 0;
    const progress = (timeInSeconds / videoElement.current.duration) * 100 || 0;
    setTimeout(() => {
      setVideoPlayerDetails({
        ...playerState,
        isPlaying: false,
        imageBase64: commentScreenShot,
      });

      if (progressbarRef.current) {
        progressbarRef.current.value = progress;
      }
    }, 300);
    if (showAnnotationBoard) {
      setShowAnnotationBoard(false);
    }
  };

  const handleAddCommentAtPosition = async () => {
    if (playerData && playerData.getReviewLinkDetails) {
      const { asset_comments: assetComments } =
        playerData.getReviewLinkDetails.assetCommentDetails;

      let tempArray = [];
      await assetComments.map(async (itm) => {
        const { commentTime } = itm;
        const timeInSeconds = await convertTimecodeToSeconds(
          commentTime || '00:00:00:00',
          20
        );
        const progress = (timeInSeconds / videoElement.current.duration) * 100;
        tempArray = [...tempArray, { commentProgressTime: progress, ...itm }];
        return itm;
      });

      setVideoPlayerDetails({
        ...playerState,
        commentPosition: tempArray,
      });
    }
  };

  useEffect(() => {
    setCommentLoading(false);
    if (playerState.isVideoLoaded) handleAddCommentAtPosition();
  }, [playerData, playerState.isVideoLoaded]);

  useEffect(() => {
    if (playerState.isPlaying) {
      setVideoPlayerDetails({
        ...playerState,
        videoLoading: false,
      });
      setShowAnnotationBoard(false);
    }
  }, [playerState.isPlaying]);

  const handleOnVideoMetaLoad = () => {
    if (playerState.isPlaying) {
      videoElement.current.play();
    } else {
      videoElement.current.pause();
    }

    setVideoPlayerDetails({
      ...playerState,
      isVideoLoaded: true,
      videoLoading: false,
    });
  };

  const handleReviewedCheckboxAction = (userData) => {
    if (userData || playerState.userDetails) {
      updateReviewAssetStatus({
        variables: {
          parentId: fileParentId,
          assetId,
          folderName: version,
        },
      });
    }
  };

  const toggleHasCheckboxChecked = (status) => {
    if (playerState.showAddCommentModal) {
      setHasCheckboxCheked(status);
      if (playerState.isPlaying) {
        videoElement.current.pause();
        setVideoPlayerDetails({
          ...playerState,
          isPlaying: false,
        });
      }
    } else {
      handleReviewedCheckboxAction();
    }
  };

  const handleReivewChecked = () => {
    if (!playerState?.isBronze) {
      toggleHasCheckboxChecked(true);
    }
  };

  const toggleReviewedCheckboxModal = (status, userDetails = null) => {
    setVideoPlayerDetails({
      ...playerState,
      showAddCommentModal: status,
      userDetails,
    });
  };

  const isAssetUrlImage = isImage(assetURL);

  return (
    <>
      <Row>
        <Col
          md={isVidPitchPlayer ? 12 : 9}
          className={!isVidPitchPlayer ? 'px-5' : 'p-0'}
        >
          <div
            className={`d-flex justify-content-between align-items-center ${
              !isVidPitchPlayer ? 'py-2' : ''
            }`}
          >
            {!isModal && (
              <VideoVersionListing
                rootId={
                  playerData.getReviewLinkDetails.assetCommentDetails.rootId
                }
                assetFileName={assetFileName}
                assetId={assetId}
                videoElement={videoElement}
              />
            )}
          </div>

          <div className="video-container">
            <div
              className="video-relative"
              id="videoWithAnnotation"
              ref={eleWithScreenshotAndAnnotation}
            >
              {isAssetUrlImage ? (
                <img
                  className="video-p-size"
                  ref={videoElement}
                  src={assetURL}
                  alt="draft_image"
                  crossOrigin="anonymous"
                />
              ) : null}

              {!isAssetUrlImage && (
                <video
                  id="videotag"
                  className="video-p-size"
                  style={{ objectFit: `${playerState.aspectRatio}` }}
                  controlsList="nodownload"
                  playsInline
                  disablePictureInPicture
                  onEnded={() => {
                    setVideoPlayerDetails({
                      ...playerState,
                      isPlaying: false,
                    });
                  }}
                  preload="auto"
                  crossOrigin="anonymous"
                  ref={videoElement}
                  onCanPlayThrough={handleOnVideoMetaLoad}
                  onTimeUpdate={handleOnTimeUpdate}
                  onWaiting={() => {
                    if (isVidPitchPlayer) {
                      setVideoPlayerDetails({
                        ...playerState,
                        videoLoading: !playerState.imageBase64,
                      });
                    }
                  }}
                  onPlaying={() =>
                    setVideoPlayerDetails({
                      ...playerState,
                      videoLoading: false,
                    })
                  }
                >
                  <source src={`${assetURL}#t=0.002`} type="video/mp4" />
                  <track src="" default kind="captions" srcLang="en" />
                </video>
              )}

              {!isModal && (
                <div id="videoPlayerScreenDiv" ref={screenshotDivElement}>
                  {playerState.imageBase64 ? (
                    <img
                      ref={commentScreenshotElement}
                      id="screenshotImg"
                      onError={() => {
                        setVideoPlayerDetails({
                          ...playerState,
                          videoLoading: false,
                          imageBase64: '',
                        });
                        toast.error('Unable to load annotation');
                      }}
                      onLoad={() => {
                        setVideoPlayerDetails({
                          ...playerState,
                          videoLoading: false,
                        });
                      }}
                      src={playerState.imageBase64}
                      style={{ width: '100%' }}
                      alt="video_screenshot"
                    />
                  ) : null}

                  {/* render annotations canvas*/}
                  {showAnnotationBoard ? (
                    <div id="screenWithAnnotation">
                      <DrawAnnotations
                        annotationsList={annotationsList}
                        setAnnotationsList={setAnnotationsList}
                        videoElement={videoElement}
                        annotationDetails={annotationDetails}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {!isAssetUrlImage && playerState.videoLoading ? (
              <img
                src={loadingGIF}
                alt="loading"
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: 'auto',
                }}
              />
            ) : null}
          </div>
          {!isAssetUrlImage && (
            <div className={!isVidPitchPlayer ? 'timeline' : 'timeline mb-0'}>
              <input
                id="progressbar"
                ref={progressbarRef}
                type="range"
                min="0"
                max="100"
                value={progressbarRef?.current?.value ?? 0}
                onMouseMove={handleOnMouseMoveOverProgress}
                onMouseLeave={handleMouseOut}
                onChange={(e) => handleManualVideoProgressUpdate(e)}
                style={{ height: '3px' }}
                className="w-100"
              />
              <div
                style={{ top: '3px', height: '10px' }}
                className="position-relative"
              >
                {!isModal && (
                  <>
                    {playerState.commentPosition
                      ? playerState.commentPosition.map((commentPos) => {
                          const timeInSeconds = convertTimecodeToSeconds(
                            commentPos.commentTime || '00:00:00:00',
                            20
                          );
                          return (
                            <span
                              id={`Tooltip-${commentPos.id}`}
                              role="presentation"
                              key={commentPos.id}
                              onKeyDown={() =>
                                handleTimeMoveByComment(commentPos)
                              }
                              onClick={() =>
                                handleTimeMoveByComment(commentPos)
                              }
                              style={{
                                left: `${commentPos.commentProgressTime}%`,
                              }}
                              className="screenshot-dot"
                            >
                              <span className="text-capitalize">
                                {commentPos.commentUser
                                  ? commentPos.commentUser.charAt(0)
                                  : 'G'}
                              </span>
                              <Tooltip
                                placement="top"
                                isOpen={
                                  toolTipOpen && commentPos.id === tooltipId
                                }
                                target={`Tooltip-${commentPos.id}`}
                                toggle={() => {
                                  setTooltipId(commentPos.id);
                                  setToolTipOpen(!toolTipOpen);
                                }}
                              >
                                <span>
                                  <span className="user-points-name text-capitalize">
                                    {commentPos.commentUser
                                      ? commentPos.commentUser.charAt(0)
                                      : 'G'}
                                  </span>

                                  <span className="pl-1 font-size-12 text-capitalize">
                                    {commentPos.commentUser
                                      ? commentPos.commentUser
                                      : 'Guest'}
                                  </span>
                                </span>

                                <br />
                                <span className="font-size-12 text-info font-weight-bold">
                                  {timeFormator(
                                    timeInSeconds || 0,
                                    playerState.formatorType
                                  )}
                                </span>
                                <span className="font-size-12 pl-1">
                                  {commentPos.commentText}
                                </span>
                              </Tooltip>
                            </span>
                          );
                        })
                      : null}
                    <span
                      style={{
                        paddingLeft: `${
                          playerState.previewVideoTime
                            ? playerState.previewVideoTime.divPosition
                            : null
                        }%`,
                        display: 'inline-block',
                        position: 'relative',
                        bottom: '35px',
                      }}
                    >
                      {playerState.previewVideoTime &&
                      playerState.previewVideoTime.previewTime ? (
                        <>
                          {timeFormator(
                            playerState.previewVideoTime.previewTime,
                            playerState.formatorType
                          )}
                        </>
                      ) : null}
                    </span>
                  </>
                )}
              </div>
              <VideoPlayerControllers
                assetURL={assetURL}
                trackVideo={trackVideo}
                videoElement={videoElement}
                showAnnotationBoard={showAnnotationBoard}
                setShowAnnotationBoard={setShowAnnotationBoard}
                isVidPitchPlayer={isVidPitchPlayer}
                playerData={playerData}
                currentDurationRef={currentDurationRef}
              />
            </div>
          )}
          {!isModal && (
            <CreateComment
              videoElement={videoElement}
              eleWithScreenshotAndAnnotation={eleWithScreenshotAndAnnotation}
              showAnnotationBoard={showAnnotationBoard}
              setShowAnnotationBoard={setShowAnnotationBoard}
              playerState={playerState}
              setVideoPlayerDetails={setVideoPlayerDetails}
              setAnnotationDetails={setAnnotationDetails}
              annotationDetails={annotationDetails}
              screenshotDivElement={screenshotDivElement}
              commentScreenshotElement={commentScreenshotElement}
              playerData={playerData}
              refetch={refetch}
              setAnnotationsList={setAnnotationsList}
              handleAddCommentAtPosition={handleAddCommentAtPosition}
              annotationsList={annotationsList}
              setCommentLoading={setCommentLoading}
              isImage={isAssetUrlImage}
              assetId={assetId}
              reviewCompleted={reviewCompleted}
            />
          )}
        </Col>
        {!isModal && (
          <Col className="comments-section p-0" md={3}>
            <ListComments
              playerState={playerState}
              playerData={playerData}
              handleTimeMoveByComment={handleTimeMoveByComment}
              commentLoading={commentLoading}
              reviewCompleted={reviewCompleted}
              setReviewCompleted={setReviewCompleted}
              reviewStatus={reviewStatus}
              handleReivewChecked={handleReivewChecked}
              setShowConfirmationModal={setShowConfirmationModal}
            />
          </Col>
        )}
      </Row>
      {hasCheckboxCheked && (
        <LeaveCommentModal
          title="Want to review complete ?"
          description="Add your info here to know who marked review completed."
          assetId={assetId}
          showAddCommentModal={playerState.showAddCommentModal}
          toggleAddCommentModal={toggleReviewedCheckboxModal}
          toggleHasCommentClicked={toggleHasCheckboxChecked}
          handleSendCommentActions={handleReviewedCheckboxAction}
        />
      )}
      <RedirectConfirmationModal
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
      />
    </>
  );
};

export default VideoPlayerScreen;
