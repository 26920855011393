import React from 'react';
import Select from 'react-select';
import { find } from 'lodash';
import { parseToInteger } from 'utils/helper';

const PilotProjectListing = ({ handleSort, selectProject, projects }) => {
  const handleProjectChange = (pid) => {
    const selectedProject = find(projects, (o) => {
      return o.id === parseToInteger(pid);
    });
    const { id, acceptedByPilot } = selectedProject;
    handleSort(id, acceptedByPilot, selectedProject);
  };

  const CustomizeOptionLabel = ({ value, label }) => {
    return (
      <div className="d-flex justify-content-between">
        <span className="option-text">{value}</span>
        <span
          className={
            label === 'Active'
              ? 'float-right mr-3'
              : 'float-right mr-3 text-danger'
          }
        >
          {label}
        </span>
      </div>
    );
  };

  const renderProjects = () => {
    return (
      <Select
        key={Math.random()}
        className="basic-single"
        classNamePrefix="select"
        defaultValue={{ ...selectProject }}
        isSearchable
        name="projectName"
        width="200px"
        getOptionLabel={(option) => CustomizeOptionLabel(option)}
        getOptionValue={(option) => `${option.label} ${option.value}`}
        options={projects}
        onChange={(e) => handleProjectChange(e.id)}
      />
    );
  };

  if (projects.length) {
    return <div className="pilot-project-select">{renderProjects()}</div>;
  }
  return null;
};

export default PilotProjectListing;
