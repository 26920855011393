import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

const FormikSelectField = ({
  field,
  form: { isSubmitting, touched, errors },
  disabled = false,
  ...props
}) => (
  <FormGroup className="flex-grow-1">
    {props.label && (
      <Label for={props.id} className="label-color">
        {props.label}
      </Label>
    )}
    <Input
      id={props.id}
      {...field}
      {...props}
      type="select"
      invalid={Boolean(touched[field.name] && errors[field.name])}
      disabled={disabled}
    >
      <option value="">{props.defaultoption}</option>
      {props.options.map((option, index) => {
        const { keys } = props;
        if (keys)
          return (
            <option value={option[keys.id]} key={option}>
              {option[keys.label]}
            </option>
          );
        return (
          <option value={option} key={option}>
            {props.flag ? `<img src={googleImage}` : option}
          </option>
        );
      })}
    </Input>
    <ErrorMessage
      name={field.name}
      render={(msg) => <div className="text-danger font-size-12">{msg}</div>}
    />
  </FormGroup>
);

export default FormikSelectField;
