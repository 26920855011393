import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

const FormikTextArea = ({
  field: { ...fields },
  required,
  form: { touched, errors, setFieldValue, handleBlur },
  ...props
}) => {
  const { label, id } = props;
  const { name } = fields;

  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(name, value, true);
  };

  return (
    <FormGroup className="flex-grow-1 mb-0">
      {label && (
        <Label for={id} className="label-color">
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Input
        {...props}
        {...fields}
        type='textarea'
        onChange={handleChange}
        invalid={Boolean(touched[name] && errors[name])}
        onBlur={handleBlur}
        rows="3"
      />

      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="text-danger font-size-12">{msg}</div>;
        }}
      />
    </FormGroup>
  );
};
export default FormikTextArea;
