/* eslint-disable no-param-reassign */
import React, { useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { videoPlayerContext } from 'contexts/VideoPlayerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faExpand,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  isImage,
  removeScreenShots,
  convertSecondsToHHMMSSFormat,
} from 'common/VideoPlayer/util';
import { extractSubDomain } from 'utils/helper';
import { UPDATE_VIDEO_WATCH_TIME } from 'pages/Vidpitch/VidPitchGraphQL';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import { BRONZE } from 'constants/appConstants';
import VideoSpeedSelector from '../VideoSpeedSelector';

const VideoPlayerControllers = ({
  assetURL,
  trackVideo,
  videoElement,
  showAnnotationBoard,
  setShowAnnotationBoard,
  isVidPitchPlayer,
  playerData,
  currentDurationRef,
}) => {
  const params = useParams();
  const history = useHistory();

  const currentSubDomain = extractSubDomain();
  const { playerState, setVideoPlayerDetails } = useContext(videoPlayerContext);
  const { auth } = useContext(authContext);

  const [modifyVideoRequest] = useMutation(UPDATE_VIDEO_WATCH_TIME, {
    onCompleted: () => {
      console.log('success');
    },
    onError: ({ message }) => {
      console.log('error', message);
    },
  });

  useEffect(() => {
    const planType =
      playerData?.getReviewLinkDetails?.assetCommentDetails?.project_folder
        ?.project?.projectPlan ?? null;

    if (!auth?.data && planType === BRONZE) {
      history.push('/login');
    }
    setVideoPlayerDetails({
      ...playerState,
      userDetails: planType === BRONZE ? auth?.data : null,
      isBronze: planType === BRONZE,
    });
  }, [auth]);

  const requestModifyVideo = async (currentTime) => {
    try {
      await modifyVideoRequest({
        variables: {
          input: {
            uuid: params.uuid,
            watchTime: currentTime,
            isModifyVideoRequested: false,
          },
        },
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const verifyWatchTimeUpdate = () => {
    if (
      currentSubDomain === process.env.REACT_APP_VIDPITCH_KEYWORD &&
      videoElement?.current &&
      trackVideo &&
      auth?.data?.role !== ROLES.ADMIN
    ) {
      const { current } = videoElement;
      const currentTime = convertSecondsToHHMMSSFormat(
        current.currentTime || 0
      );
      requestModifyVideo(currentTime);
    }
  };

  useEffect(() => {
    const routeName = history.location.pathname;
    const unListen = history.listen(() => {
      if (routeName.includes('/video-draft')) verifyWatchTimeUpdate();
    });

    return () => {
      unListen();
    };
  }, [history]);

  useEffect(() => {
    if (playerState.isPlaying) {
      videoElement.current.play();
    } else {
      videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  useEffect(() => {
    if (playerState.isMuted) {
      videoElement.current.muted = true;
    } else {
      videoElement.current.muted = false;
    }
  }, [playerState.isMuted, videoElement]);

  useEffect(() => {
    if (
      videoElement?.current?.ended &&
      history.location.pathname.includes('/video-draft')
    ) {
      verifyWatchTimeUpdate();
    }
  }, [videoElement?.current?.ended]);

  const togglePlay = () => {
    const { isPlaying, annotationScreenShot, imageBase64 } = playerState;
    setVideoPlayerDetails({
      ...playerState,
      isPlaying: !isPlaying,
      annotationScreenShot: isPlaying ? annotationScreenShot : '',
      imageBase64: isPlaying ? imageBase64 : '',
    });

    if (showAnnotationBoard) {
      setShowAnnotationBoard(false);
      removeScreenShots();
    }

    if (isPlaying && history.location.pathname.includes('/video-draft')) {
      verifyWatchTimeUpdate();
    }
  };

  const toggleFullscreen = () => {
    const { current } = videoElement;
    if (current.requestFullScreen) {
      current.requestFullScreen();
    } else if (current.webkitRequestFullScreen) {
      current.webkitRequestFullScreen();
    } else if (current.mozRequestFullScreen) {
      current.mozRequestFullScreen();
    }
  };

  const toggleMute = () => {
    setVideoPlayerDetails({
      ...playerState,
      isMuted: !playerState.isMuted,
      volume: !playerState.isMuted ? 0 : 0.2,
    });
  };

  const handleVolumeChange = (event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.volume = manualChange;

    setVideoPlayerDetails({
      ...playerState,
      volume: manualChange,
    });
  };

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setVideoPlayerDetails({
      ...playerState,
      speed,
    });
  };

  return (
    <>
      {!isImage(assetURL) && (
        <div className="controls">
          <div className="actions d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Button
                type="button"
                onClick={togglePlay}
                size="sm"
                className="mr-3 p-1"
                color="link"
              >
                {!playerState.isPlaying ? (
                  <FontAwesomeIcon icon={faPlay} className="video-icons" />
                ) : (
                  <FontAwesomeIcon icon={faPause} className="video-icons" />
                )}
              </Button>
              <Button
                type="button"
                className="mr-3 p-1"
                onClick={toggleMute}
                size="sm"
                color="link"
                style={{ width: '30px' }}
              >
                {playerState.isMuted ? (
                  <FontAwesomeIcon
                    icon={faVolumeMute}
                    className="video-icons"
                  />
                ) : (
                  <FontAwesomeIcon icon={faVolumeUp} className="video-icons" />
                )}
              </Button>
              {/* volume range */}
              <input
                type="range"
                id="volumeBar"
                title="volume"
                min="0"
                max="1"
                step="0.1"
                value={playerState.volume}
                onChange={(e) => handleVolumeChange(e)}
                style={{ height: '3px' }}
                color="danger"
              />
              {/* Show current video time */}
              <span className="text-white ml-3" ref={currentDurationRef}>
                00:00/00:00
              </span>
              {/* render time formator dropdown */}
            </div>

            <div>
              {!isVidPitchPlayer && (
                <>
                  <select
                    className="velocity mr-2"
                    value={playerState.aspectRatio}
                    onChange={(e) =>
                      setVideoPlayerDetails({
                        ...playerState,
                        aspectRatio: e.target.value,
                      })
                    }
                  >
                    <option value="contain">Contain</option>
                    <option value="cover">Cover</option>
                    <option value="fill">Fill</option>
                  </select>
                  <select
                    className="velocity mr-2"
                    value={playerState.formatorType}
                    onChange={(e) =>
                      setVideoPlayerDetails({
                        ...playerState,
                        formatorType: e.target.value,
                      })
                    }
                  >
                    <option value="standard">Standard</option>
                    <option value="timecode">Timecode</option>
                  </select>
                </>
              )}
              <VideoSpeedSelector handleVideoSpeed={handleVideoSpeed} />
              <Button
                type="button"
                onClick={toggleFullscreen}
                size="sm"
                className="mr-1"
                color="link"
              >
                <FontAwesomeIcon icon={faExpand} className="video-icons" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayerControllers;
