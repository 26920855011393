import { gql } from '@apollo/client';

export const GET_USER_PERMISSION = gql`
  query getUserPermission($email: String!) {
    getUserPermission(email: $email)
  }
`;

export const LIST_REVIEWED_PROJECTS = gql`
  query listReviewedProjects($limit: Int, $offset: Int) {
    listReviewedProjects(limit: $limit, offset: $offset)
  }
`;

export const GET_REVIEWED_PROJECT_HEADER_DETAILS = gql`
  query getReviewedProjectHeaderDetails($folderId: Int!, $version: String) {
    getReviewedProjectHeaderDetails(folderId: $folderId, version: $version)
  }
`;

export const LIST_ASSET_VERSIONS = gql`
  query listAssetVersions($folderId: Int!) {
    listAssetVersions(folderId: $folderId)
  }
`;
