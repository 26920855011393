import React from 'react';
import { Row, Col } from 'reactstrap';

const PageNotFound = () => {
  return (
    <Col>
      <Row style={{ height: 'calc(100vh - 61px)' }}>
        <Col className="align-self-center" align="center">
          <h4 className="no-result-message">Page Not Found</h4>
        </Col>
      </Row>
    </Col>
  );
};

export default PageNotFound;
