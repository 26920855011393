import React from 'react';
import ReactPlayer from 'react-player';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FILE_TYPES } from 'constants/fileFormatAndTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const AssetPreviewModal = ({
  assetModal,
  toggleAssetModal,
  assetURL,
  fileType,
  fileName,
}) => {
  const { IMAGE, VIDEO, OTHER } = FILE_TYPES;
  return (
    <>
      <Modal
        isOpen={assetModal}
        toggle={toggleAssetModal}
        className="modal-dialog-centered modal-xl"
      >
        <ModalHeader className=" " toggle={toggleAssetModal}>
          {fileName}
        </ModalHeader>
        <ModalBody className="text-center ">
          {fileType === IMAGE && (
            <img src={assetURL} alt="SCREENSHOTS" className="img-fluid" />
          )}
          {fileType === VIDEO && (
            <ReactPlayer
              url={assetURL}
              playing
              controls
              width="640px"
              height="360px"
              className="m-auto"
            />
          )}
          {fileType === OTHER && (
            <>
              <div className="d-flex align-items-center justify-content-center h-400px">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="fa-2x mr-2 "
                />
                <h4>This file format is not supported for viewing</h4>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
export default AssetPreviewModal;
