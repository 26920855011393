import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

const Error404 = () => {
  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} className="pt-3">
          <Card className="mt-5 pt-2 bg-white">
            <CardBody>
              <h1>Error 404 = Page not found</h1>
              <p>Sorry, this page not found!</p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Error404;
