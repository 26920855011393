import React from 'react';
import { useHistory } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import activeFill from 'assets/refresh-fill.svg';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import { Badge } from 'reactstrap';

const ProjectDetail = (dealType) => {
  let icon = null;
  let altText = null;
  if (dealType !== 'Not Specified') {
    const iconObj = PLAN_TYPE_ICONS[dealType];
    icon = iconObj?.icon;
    altText = iconObj?.altText;
  }
  return (
    <div className="d-flex">
      {icon && altText ? (
        <img className="mr-2" src={icon} alt={altText} />
      ) : (
        <span className="plan-image-placeholder mr-3" />
      )}
    </div>
  );
};

const DealItem = ({ dealInfo }) => {
  const history = useHistory();

  const {
    dealName,
    dealStage,
    projectManager,
    projectEditor,
    pif,
    status,
    startDate,
    dealId,
    projectId,
    dealType,
  } = dealInfo;

  const handleAnchor = () => {
    history.push(`/sales-portal/deals/${projectId}`);
  };

  return (
    <tr
      key={dealId}
      className="bg-white shadow-sm cursor-pointer"
      onClick={() => handleAnchor()}
    >
      <td>
        <div
          className="d-flex max-width-500px text-truncate align-items-center"
          title={dealName}
        >
          {ProjectDetail(dealType)}
          {dealName}
        </div>
      </td>
      <td>{dealStage === 'closedwon' && 'Closed / won'}</td>
      <td>{projectManager || 'N/A'} (PM)</td>
      <td>{projectEditor || 'N/A'} (Editor)</td>
      <td>PIF: {pif}</td>
      <td>
        Status:
        <span className="active-td border border-radius-8px badge badge-secondary d-inline-block ml-1 p-1">
          <img src={activeFill} alt="active td" className="align-middle mr-1" />
          {status}
        </span>
      </td>
      <td>{dateTimeFormatter(startDate)}</td>
    </tr>
  );
};

export default DealItem;
