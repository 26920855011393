import React, { useContext, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { useQuery } from '@apollo/client';

import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { dateTimeFormatter } from 'utils/helper';
import {
  ADDITIONAL_NOTES_ADDED,
  LIST_NOTES,
} from 'pages/Pilot/PilotPortalGraphQL';
import { FOLDER_TYPES } from 'pages/Pilot/constant';
import AddNotes from 'pages/PMPortal/components/Projects/components/VideoDevelopmentDetail/components/Timeline/components/AdditionalNotes/components/AddNotes';
import { GET_PROJECT_DETAILS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const AdditionalNotes = ({ selectProject }) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const {
    subscribeToMore,
    loading: notesLoading,
    error: notesError,
    data: notesData,
  } = useQuery(LIST_NOTES, {
    variables: {
      input: {
        projectId: parseInt(selectProject, 10),
        orderBy: 'DESC',
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: ADDITIONAL_NOTES_ADDED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.addedAdditionalNotes.listNotes) {
          const {
            addedAdditionalNotes: { listNotes },
          } = subscriptionData.data;
          if (listNotes?.length > 0) {
            const { pilot_task: pilotTask } = listNotes[0];
            if (pilotTask.projectId === parseInt(selectProject, 10)) {
              return {
                listNotes: [...listNotes, ...prev.listNotes],
              };
            }
          }
          return prev;
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  const { data } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId: parseInt(selectProject, 10),
    },
    fetchPolicy: 'cache-and-network',
  });

  const { projectPilotId } = data?.getProjectDetails || {};

  if (notesLoading) return <PageLoader />;
  if (notesError) return <NoData customMessage={notesError.message} />;

  if (notesData && notesData.listNotes.length) {
    const { listNotes } = notesData;

    const renderNotes = () => {
      return listNotes?.map(
        ({
          id,
          notesUserRole,
          noteText,
          createdAt,
          notesUsername,
          pilot_task: { title, folderTypes },
        }) => {
          return (
            <div key={id}>
              <span>
                <b className="text-capitalize">
                  {`(${notesUserRole}) ${notesUsername || ''}`} ,
                </b>{' '}
                {dateTimeFormatter(createdAt, 'MM/DD/YYYY hh:mm a')}
              </span>

              <div>
                {folderTypes !== FOLDER_TYPES.DEFAULT && (
                  <Badge
                    color={
                      folderTypes === FOLDER_TYPES.CUSTOM
                        ? `success`
                        : 'warning'
                    }
                    className="border-radius-8px font-weight-500 px-2 py-1 mb-2"
                  >
                    {`${title}`}
                  </Badge>
                )}
              </div>

              <p>{noteText}</p>
            </div>
          );
        }
      );
    };

    return (
      <div>
        <div className="rendred-notes">{renderNotes()}</div>
        {role === ROLES.VIDEOMS_PILOT ? (
          <AddNotes projectId={selectProject} projectPilotId={projectPilotId} />
        ) : null}
      </div>
    );
  }

  return (
    <>
      <div className="mb-3">
        <NoData customMessage="No additional notes available" />
      </div>
      {role === ROLES.VIDEOMS_PILOT ? (
        <AddNotes projectId={selectProject} projectPilotId={projectPilotId} />
      ) : null}
    </>
  );
};

export default AdditionalNotes;
