import React, { useContext, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
//
import { authContext } from 'contexts/AuthContext';
import {
  UPDATE_PROFILE,
  GET_USER_DETAILS,
} from 'pages/Pilot/PilotPortalGraphQL';
import PageLoader from 'common/PageLoader';
import Header from 'common/Header';
import { ROLES } from 'constants/role';
import { pilotRegistrationInitialValues } from 'pages/Pilot/constant';
import Step1 from './components/GeneralDetails';
import Step4 from './components/ProfileComplete';

import Step3 from './components/CoverageCamera';
import Step2 from './components/InsuranceDetails';
import validationSchema from './validationSchema';
import registrationSchema from './registrationSchema';

const steps = ['Step 1', 'Step 2', 'Step 3'];

function renderStepContent(step, properties, isEditProfile) {
  switch (step) {
    case 0:
      return <Step1 {...properties} isEditProfile={isEditProfile} />;
    case 1:
      return <Step2 {...properties} />;
    case 2:
      return <Step3 {...properties} />;
    default:
      return <div>Not Found</div>;
  }
}

const PilotRegistration = () => {
  let registrationInitialValues = {};
  let isEditProfile = false;
  const { auth, setAuthPhoto } = useContext(authContext);
  const { data: authData } = auth;
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  if (pathname.includes('edit-profile')) {
    isEditProfile = true;
  }

  const [activeStep, setActiveStep] = useState(0);
  const { email, name, company } = useParams();

  registrationInitialValues = {
    firstName: name,
    email,
    companyName: company,
    ...pilotRegistrationInitialValues,
  };

  const [updatePilotProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: async ({ updateProfile }) => {
      setActiveStep(activeStep + 1);
      toast.success(updateProfile.message);
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const { loading, error, data } = useQuery(GET_USER_DETAILS, {
    variables: {
      userId: isEditProfile ? authData?.userId : null,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (data && data.getUserDetails) {
    const {
      createdAt,
      emailVerified,
      fullName,
      has_password_reset: hasPwdReset,
      id,
      isProfileUpdated,
      reset_token: resetToken,
      rolePermissionId,
      token,
      updatedAt,
      userSlackId,
      role,
      password,
      country,
      editorSkills,
      editorVendorId,
      isEditorProfileUpdated,
      officePhone,
      paymentVendorName,
      timeZone,
      userUuid,
      ...rest
    } = data.getUserDetails;
    registrationInitialValues = { ...rest };
  }

  const currentValidationSchema = isEditProfile
    ? registrationSchema[activeStep]
    : validationSchema[activeStep];

  const isLastStep = activeStep === steps.length - 1;

  const submitForm = async (values, actions) => {
    const { confirmPassword, email, ...signUpDetails } = values;
    setAuthPhoto(signUpDetails.photo);
    try {
      await updatePilotProfile({
        variables: {
          role: ROLES.VIDEOMS_PILOT,
          email,
          input: { isProfileUpdated: true, ...signUpDetails },
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Helmet defaultTitle="Pilot Regstration - VidTech" />
      <Header pilotRegistration={!isEditProfile} />
      {isEditProfile && (
        <div className="container-fluid ml-3 py-3">
          <Link
            className="border-bottom d-inline-block font-size-14 font-weight-500 pb-1"
            to="/pilot-dashboard/0"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
            Back to Projects
          </Link>
        </div>
      )}
      <div className="m-auto max-w-400px py-5">
        {activeStep === steps.length ? (
          <Card className="border-radius-8px shadow border-0">
            <CardBody className="border-0 px-4">
              <Step4 isEditProfile={isEditProfile} handleBack={handleBack} />
            </CardBody>
          </Card>
        ) : (
          <Card className="border-radius-8px shadow border-0">
            <CardBody className="border-0 px-4">
              <Formik
                initialValues={registrationInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form>
                    {renderStepContent(activeStep, props, isEditProfile)}
                    <div className="mt-2">
                      <div className="text-right">
                        {activeStep !== 0 && (
                          <Button
                            color="primary"
                            outline
                            onClick={handleBack}
                            className="mr-3 btn btn-sm font-size-13 px-4"
                          >
                            Previous
                          </Button>
                        )}

                        <Button
                          color="primary"
                          //disabled={isSubmitting}
                          type="submit"
                          className="btn btn-sm font-size-13 px-4"
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  );
};

export default PilotRegistration;
