import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  BRONZE_FILE_TYPES,
  CUSTOM_IMAGE_UPLOAD_ENUM,
} from 'pages/CrexiBronze/constant';
import { GET_CUSTOM_ASSET_IMAGES } from 'pages/Vidpitch/VidPitchGraphQL';
import { bronzeContext } from 'pages/CrexiBronze/BronzeContext';
import FileUploaderVidPitch from 'common/FileUploaderVidPitch';
import AssetImages from './components/AssetImages';
import CustomImage from './components/CustomImage';

const ChangeImageModal = ({
  uuid,
  assetCrexiId,
  currentChapter,
  changeImageModal,
  chapterImageURL,
  setChapterImageURL,
  toggleChangeImageModal,
  isBronze = false,
  fileType,
  setPropertyBoundaryImg,
}) => {
  const isPropertyBoundary = fileType === BRONZE_FILE_TYPES.PROPERTY_BOUNDARY;
  const { dispatchBronzeData } = useContext(bronzeContext);

  const {
    data: customImages,
    loading: customImageLoader,
    error: customImageError,
    refetch: refetchAssets,
  } = useQuery(GET_CUSTOM_ASSET_IMAGES, {
    variables: {
      fileType: fileType ?? BRONZE_FILE_TYPES.IMAGE,
      offset: 0,
      limit: 150,
      uuid,
      ...(isBronze ? { packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE } : {}),
      ...(isPropertyBoundary ? { chapter: `Chapter_${currentChapter}` } : {}),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (fileType === BRONZE_FILE_TYPES.IMAGE) {
      const s3ImageLinkArray =
        customImages?.getCustomAssetImages?.map((item) => item.s3ImageLink) ??
        [];
      dispatchBronzeData({
        type: 'IMAGE_LINKS',
        s3ImageLinkArray,
      });
    }
  }, [customImages]);

  return (
    <Modal
      isOpen={changeImageModal}
      toggle={() => toggleChangeImageModal(false)}
      className="modal-dialog-centered modal-xl"
    >
      <ModalHeader toggle={() => toggleChangeImageModal(false)}>
        Change Image : CHAPTER {currentChapter}
      </ModalHeader>
      <ModalBody className="text-center ">
        <Row>
          <Col md={7} className="border-right">
            <div className="mb-2">
              {!isPropertyBoundary && (
                <span className="d-flex align-left font-size-14">
                  * Select one of the below images or upload a new image.
                </span>
              )}
            </div>
            {!isBronze && (
              <AssetImages
                uuid={uuid}
                assetCrexiId={assetCrexiId}
                chapterImageURL={chapterImageURL}
                setChapterImageURL={setChapterImageURL}
              />
            )}
            <CustomImage
              type={fileType ?? BRONZE_FILE_TYPES.IMAGE}
              error={customImageError}
              loader={customImageLoader}
              chapterAssetURL={chapterImageURL}
              setChapterAssetURL={setChapterImageURL}
              customImages={customImages?.getCustomAssetImages}
              isBronze={isBronze}
              isPropertyBoundary={isPropertyBoundary}
            />
          </Col>
          <Col md={5}>
            <div className="image-req">
              <FileUploaderVidPitch
                uuid={uuid}
                fileType={fileType ?? BRONZE_FILE_TYPES.IMAGE}
                versionSuffix={0}
                refetchAssets={refetchAssets}
                setPropertyBoundaryImg={setPropertyBoundaryImg}
                isBronze={isBronze}
                isPropertyBoundary={isPropertyBoundary}
                currentChapter={currentChapter}
                isBoundaryImage
              />
              <div className="text-left">
                <b className="pl-3 mb-3 font-size-18">Image Requirements</b>
                {/* <p className="pl-4 d-block">Image side should be 00 X 00</p> */}
                <p className="pl-3 d-block mt-2">
                  Please upload the highest quality images available, the image
                  will appear the same as it is uploaded. All images must be
                  licensed or approved for commercial marketing.
                </p>
                <p className="pl-3 d-block">
                  VidTech retains the right to remove any inappropriate or
                  unlicensed images.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ChangeImageModal;
