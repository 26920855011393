/*eslint no-param-reassign: "error"*/
import * as Yup from 'yup';
import FormikInputField from 'components/Fields/FormikInputField';
import FormikCheckboxField from 'components/Fields/FormikCheckbox';
import FormikSelectField from 'components/Fields/FormikSelectField';
import FormikTextArea from 'components/Fields/FormikTextArea';

export const CREXI_BRONZE = {
  TOTAL_CHAPTERS: 6,
  CHAPTER_ONE_HEADING: 'Property Details',
  MAX_CHARACTER_LIMIT: 15,
};

export const VIDEO_RENDER_COUNT_DOWN = 3;

export const FORMIK_FIELDS = {
  text: FormikInputField,
  checkbox: FormikCheckboxField,
  select: FormikSelectField,
  textarea: FormikTextArea,
};

export const attributeArray = [
  'BUILDING_SIZE',
  'LOT_SIZE',
  'YEAR_BUILT',
  'PARKING_SPACES',
];

export const verifyAttrType = (attributeValue) => {
  if (
    ['LOGO', 'IMAGE', 'QR_CODE'].some((excludedValue) =>
      attributeValue.includes(excludedValue)
    )
  ) {
    return false;
  }

  return true;
};

export const mapFormDataToJson = (formJson, formData, imageLogoUrl) => {
  const temp = formJson ? JSON.parse(JSON.stringify(formJson)) : {};
  temp.formData.forEach((item) => {
    const itemValue = formData[item.attributeValue];

    if ('isMarkedForTemplate' in item && verifyAttrType(item.attributeValue)) {
      item.isMarkedForTemplate = formData[`CHECKBOX_${item.attributeValue}`];
    }

    if (item.children.length) {
      const flattenedChildObj = Object.assign({}, ...itemValue);

      item.children.forEach((child) => {
        if (Array.isArray(child.value)) {
          child.value = child.value.map(
            (_, ind) => flattenedChildObj[`${child.attributeValue}_${ind}`]
          );
        } else {
          child.value = flattenedChildObj[child.attributeValue];
        }
      });
    }
    if (!verifyAttrType(item.attributeValue)) {
      if (
        item.attributeValue.includes('LOGO') ||
        item.attributeValue.includes('QR_CODE')
      ) {
        item.isVisible = !item.isVisible
          ? !!imageLogoUrl?.logoAndQrUrl
          : item.isVisible;
        item.value = imageLogoUrl?.logoAndQrUrl || item.value;
      } else if (item.attributeValue.includes('IMAGE')) {
        item.value = imageLogoUrl?.currentPreviewURL || item.value;
      }
    } else {
      item.value = itemValue;
      if (formData[`LABEL_${item.attributeValue}`]) {
        item.label = formData[`LABEL_${item.attributeValue}`];
      }
      if (!verifyAttrType(item.attributeValue)) {
        item.value =
          item.attributeValue.includes('LOGO') ||
          item.attributeValue.includes('QR_CODE')
            ? imageLogoUrl?.logoAndQrUrl
            : imageLogoUrl?.currentPreviewURL;
      }
    }
  });

  return temp;
};

export const characterLimitValidation = (formDataValues = {}) => {
  const schema = {};
  return Yup.object().shape(
    formDataValues?.formData?.reduce((parentSchema, fieldName) => {
      if (fieldName?.fieldType === 'multiple' && fieldName?.attributeValue && !fieldName?.attributeValue.includes('CUSTOM_HEADING')) {
        schema[fieldName.attributeValue] = Yup.array().of(
          Yup.object().shape(
            fieldName?.children?.reduce(
              (childSchema, item, currentIndex, arr) => {
                if (Array.isArray(item?.value)) {
                  item?.value.forEach((childArr, ind) => {
                    schema[`${item?.attributeValue}_${ind}`] = Yup.string()
                      .trim()
                      .max(
                        CREXI_BRONZE.MAX_CHARACTER_LIMIT,
                        `Maximum ${CREXI_BRONZE.MAX_CHARACTER_LIMIT} characters allowed`
                      );
                  });
                } else {
                  schema[`${item?.attributeValue}`] = Yup.string()
                    .trim()
                    .max(
                      CREXI_BRONZE.MAX_CHARACTER_LIMIT,
                      `Maximum ${CREXI_BRONZE.MAX_CHARACTER_LIMIT} characters allowed`
                    );
                }
                return schema;
              },
              {}
            )
          )
        );
      } else if (
        fieldName?.attributeValue &&
        fieldName?.fieldType === 'text' &&
        !['PROPERTY_NAME', 'PROPERTY_ADDRESS', 'HEADING_1'].includes(
          fieldName.attributeValue
        )
      ) {
        schema[fieldName.attributeValue] = Yup.string()
          .trim()
          .max(
            CREXI_BRONZE.MAX_CHARACTER_LIMIT,
            `Maximum ${CREXI_BRONZE.MAX_CHARACTER_LIMIT} characters allowed`
          );
      }

      if (fieldName?.isEditable) {
        schema[`LABEL_${fieldName?.attributeValue}`] = Yup.string()
          .trim()
          .required('Label is required')
          .max(
            CREXI_BRONZE.MAX_CHARACTER_LIMIT,
            `Maximum ${CREXI_BRONZE.MAX_CHARACTER_LIMIT} characters allowed`
          );
      }
      return schema;
    }, {})
  );
};

export const CUSTOM_IMAGE_UPLOAD_ENUM = {
  BRONZE: 'BRONZE',
};

export const validateCustomField = (values) => {
  let error = '';
  const { CUSTOM_HEADING } = values;
  if (CUSTOM_HEADING) {
    if (
      CUSTOM_HEADING[0].CUSTOM_HEADING_0.trim() &&
      !CUSTOM_HEADING[1].CUSTOM_HEADING_1.trim()
    ) {
      error = 'Custom Value is required, if Custom Heading is provided';
    }
    if (
      !CUSTOM_HEADING[0].CUSTOM_HEADING_0.trim() &&
      CUSTOM_HEADING[1].CUSTOM_HEADING_1.trim()
    ) {
      error = 'Custom Heading is required, if Custom Value is provided';
    }
  }
  return error;
};

export const CHAPTER_FIELD_CONSTANT = {
  ISMARKED_FOR_TEMPLATE: 'isMarkedForTemplate',
  CHAPTER_FIVE: 'SEGMENT_5',
  CUSTOM: 'CUSTOM',
  TENANCY: 'TENANCY',
  INSTRUCTION_FOR_EDITOR: 'INSTRUCTION_FOR_EDITOR',
};

export const BRONZE_FILE_TYPES = {
  IMAGE: 'IMAGE',
  LOGO: 'LOGO',
  QR_CODE: 'QR_CODE',
  PROPERTY_BOUNDARY: 'PROPERTY_BOUNDARY',
};

export const renderPropertyBoundaryImage = (chapter) => {
  const chaptersNumber = [2, 3, 4];
  return chaptersNumber.includes(chapter);
};

export const DISABLED_CHECKBOX_FIELDS = [
  'CHECKBOX_HEADING_1',
  'CHECKBOX_PROPERTY_ADDRESS',
  'CHECKBOX_PROPERTY_NAME',
];
