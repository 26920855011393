import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Label, InputGroup, Input } from 'reactstrap';

import client from 'config/apollo';
import { GET_ASSESTS_DETAILS } from 'pages/Search/CrexiSearchGraphQL';
import { changeEliteAuction } from 'pages/Search/SalesActions';
import { toast } from 'react-toastify';
import { AUCTION, ELITE } from 'constants/appConstants';

const AuctionCard = ({ eliteAuctionStatus }) => {
  const dispatch = useDispatch();
  const { assetId } = useParams();

  const handleChange = async (e) => {
    if (assetId) {
      try {
        const { getAssetDetails } = await client.readQuery({
          query: GET_ASSESTS_DETAILS,
          variables: {
            assetId: parseInt(assetId, 10),
          },
        });
        await client.writeQuery({
          query: GET_ASSESTS_DETAILS,
          variables: {
            assetId: parseInt(assetId, 10),
          },
          data: {
            getAssetDetails: {
              ...getAssetDetails,
              eliteAuction: e.target.value,
            },
          },
        });
      } catch (error) {
        toast.error(e.message);
      }
    }

    dispatch(
      changeEliteAuction({
        eliteAuction: e.target.value,
      })
    );
  };

  return (
    <Row>
      <Col sm="5">
        <Label check>
          <InputGroup>
            <div className="custom-radio">
              <Input
                addon
                type="radio"
                name="eliteAuction"
                value={AUCTION}
                checked={eliteAuctionStatus === AUCTION}
                onChange={(e) => handleChange(e)}
                id="auction"
              />
              <label htmlFor="auction">Auction</label>
            </div>
          </InputGroup>
        </Label>
      </Col>
      <Col sm="6">
        <Label check>
          <InputGroup>
            <div className="custom-radio">
              <Input
                addon
                type="radio"
                name="eliteAuction"
                value={ELITE}
                checked={eliteAuctionStatus === ELITE}
                onChange={(e) => handleChange(e)}
                id="elite"
              />
              <label htmlFor="elite">Elite</label>
            </div>
          </InputGroup>
        </Label>
      </Col>
    </Row>
  );
};
export default AuctionCard;
