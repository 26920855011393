import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_ASSESTS_DETAILS } from 'pages/Search/CrexiSearchGraphQL';

const useAssestDetails = () => {
  const { assetId } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_ASSESTS_DETAILS, {
    variables: {
      assetId: parseInt(assetId, 10),
    },
    fetchPolicy: 'network-only',
  });
  return { loading, data, error, refetch };
};

export default useAssestDetails;
