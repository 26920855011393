import { brokersDirectoryInitialState } from 'constants/reduxInitialStates/brokersDirectyState';
import * as actions from './BrokersDirectoryActionTypes';

export const BrokersDirectoryReducer = (
  state = brokersDirectoryInitialState,
  action
) => {
  switch (action.type) {
    case actions.ADD_UPDATED_BROKER_PAGE_INFO:
      return {
        ...state,
        updatedBrokersInfo: {
          ...action.payload.updatedBrokersInfo,
        },
      };
    case actions.ADD_MISSING_BROKER_PAGE_INFO:
      return {
        ...state,
        missingBrokersInfo: {
          ...action.payload.missingBrokersInfo,
        },
      };
    case actions.CHANGE_FLAGGED_FILTERS:
      return {
        ...state,
        flaggedPageInfo: {
          ...action.payload.flaggedPageInfo,
        },
      };
    case actions.DEALS_DETAIL_INFO:
      return {
        ...state,
        dealsDetailsInfo: {
          ...action.payload.dealsDetailsInfo,
        },
      };

    case actions.HS_OUT_OF_SYNC_FILTER:
      return {
        ...state,
        HSoutOfSyncData: {
          ...action.payload.HSoutOfSyncData,
        },
      };
    default:
      return state;
  }
};
