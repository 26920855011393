import { editorPortalInitialState } from 'constants/reduxInitialStates/editorPortalState';
import * as actions from './EditorPortalActionTypes';

export const editorPortalReducer = (
  state = editorPortalInitialState,
  action
) => {
  // console.log('action', action);
  switch (action.type) {
    case actions.EDITOR_ACTIVE_PROJECT_PAGE_INFO:
      return {
        ...state,
        activeProjectInfo: {
          previousPageInfo: action.payload.previousPageInfo,
        },
      };

    case actions.EDITOR_FOLDER_ASSETS_PAGE_INFO:
      return {
        ...state,
        folderAssetsPageInfo: {
          previousPageInfo: action.payload.previousPageInfo,
          folderAssets: action.payload.folderAssets,
        },
      };
    case actions.RESET_EDITOR_PORTAL_REDUCER:
      return {
        ...state,
        ...editorPortalInitialState,
      };
    case actions.EDITOR_FILES_TO_DOWNLOAD:
      return {
        ...state,
        filesToDownload: action.payload.filesToDownload,
      };
    case actions.EDITOR_PROJECT_DETAILS:
      return {
        ...state,
        propertyDetail: action.payload.propertyDetail,
      };

    case actions.EDITOR_ACTIVE_TAB_STATUS:
      return {
        ...state,
        editorActiveTabStatus: action.payload,
      };

    case actions.NEW_PROJECT_INVITE:
      return {
        ...state,
        isNewProjectInvite: action.payload.isNewProjectInvite,
      };

    default:
      return state;
  }
};
