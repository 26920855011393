import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const ProfileComplete = ({ isEditProfile, isModal }) => {
  const history = useHistory();
  const {
    auth: {
      data: { photo },
    },
  } = useContext(authContext);

  const handleContinue = async () => {
    history.push('/editor-portal/projects/project-list');
  };

  const renderAvatar = () => {
    if (photo)
      return (
        <img
          src={photo}
          alt="editor avatar"
          width="80"
          height="80"
          style={{ borderRadius: '50%' }}
        />
      );

    return (
      <FontAwesomeIcon
        size="5x"
        className="mr-2"
        icon={faUserCircle}
        color="#3AB42B"
      />
    );
  };

  return (
    <div>
      <div className="bg-white card-header mb-4 mt-n3 mx-n4 text-left font-weight">
        <h5 className="font-size-14 mb-1">Completed Successfully</h5>
      </div>
      <div className="text-center p-5">
        {renderAvatar()}
        {isEditProfile && (
          <h4 className="pt-4">
            Profile Details are updated <br /> Successfully!
          </h4>
        )}
        {!isEditProfile && (
          <h4 className="font-size-24 pt-4">
            Profile Details are completed <br /> Successfully!
          </h4>
        )}
        {!isModal || (
          <div className="py-4">
            <Button
              color="primary"
              type="submit"
              className="btn btn-sm font-size-14 px-4 "
              onClick={handleContinue}
            >
              Proceed to Projects
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileComplete;
