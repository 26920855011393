import * as actions from './SalesActionTypes';

export const changeSalesFilters = (payload) => ({
  type: actions.CHANGE_SALES_FILTERS,
  payload,
});

export const changePlan = (payload) => ({
  type: actions.CHANGE_PLAN,
  payload,
});

export const changeEliteAuction = (payload) => ({
  type: actions.CHANGE_ELITE_AUCTION,
  payload,
});

export const changeSearchedBy = (payload) => ({
  type: actions.CHANGE_SEARCHED_BY,
  payload,
});

export const resetSalesFilters = (payload) => ({
  type: actions.RESET,
  payload,
});
