import { pmPortalInitialState } from 'constants/reduxInitialStates/pmPortalState';
import * as actions from './PMPortalActionTypes';

export const pmPortalReducer = (state = pmPortalInitialState, action) => {
  switch (action.type) {
    case actions.PM_ACTIVE_PROJECT_PAGE_INFO:
      return {
        ...state,
        activeProjectInfo: {
          previousPageInfo: action.payload.previousPageInfo,
        },
      };

    case actions.PM_FOLDER_ASSETS_PAGE_INFO:
      return {
        ...state,
        folderAssetsPageInfo: {
          previousPageInfo: action.payload.previousPageInfo,
          folderAssets: action.payload.folderAssets,
        },
      };
    case actions.PM_FILES_TO_DOWNLOAD:
      return {
        ...state,
        filesToDownload: action.payload.filesToDownload,
      };
    case actions.RESET_PM_PORTAL_REDUCER:
      return {
        ...state,
        ...pmPortalInitialState,
      };
    case actions.PM_EDITOR_INVITE:
      return {
        ...state,
        editorInviteListing: {
          previousPageInfo: action.payload.editorInviteListing.previousPageInfo,
        },
      };
    case actions.ACTIVE_TAB_STATUS:
      return {
        ...state,
        activeTabStatus: action.payload,
      };
    default:
      return state;
  }
};
