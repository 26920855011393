import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';

const PageLoader = () => (
  <Col>
    <Row style={{ height: 'calc(100vh - 61px)' }}>
      <Col className="align-self-center" align="center">
        <Spinner color="secondary" />
      </Col>
    </Row>
  </Col>
);

export default PageLoader;
