import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import checkImg from 'assets/checkbox-circle-fill.svg';

const UploadFileRows = ({ filesToUpload, progress, verifyFormat }) => {
  const renderUploadProgress = () => {
    if (progress < 100) {
      return (
        <Col xs={4}>
          {' '}
          <Spinner style={{ width: '2rem', height: '2rem' }} type="grow" />
        </Col>
      );
    }
    return (
      <Col xs={4}>
        {' '}
        <img src={checkImg} alt="checkImg" className="mr-1" /> Upload Complete
      </Col>
    );
  };

  const renderFileBar = (row, index) => {
    const isAllowedType = verifyFormat(row);

    return (
      isAllowedType.length > 0 && (
        <div className="mb-3" key={`${row.name}_${index + 1}`}>
          <div className="bg-grey-upload p-4 border-radius-8px">
            <Row>
              <Col xs={8}>{row.name}</Col>

              {renderUploadProgress()}
            </Row>
          </div>
        </div>
      )
    );
  };

  return filesToUpload.map((row, index) => {
    return renderFileBar(row, index);
  });
};

export default UploadFileRows;
