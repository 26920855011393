import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
//
import { GET_BROKERS } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import PageLoader from 'common/PageLoader';
import { addMissingBrokerPageInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import NoData from 'common/NoData';
import SearchBroker from 'pages/BrokersDirectory/common/SearchBroker';
import SortBroker from 'pages/BrokersDirectory/common/SortBroker';
import BrokerTableRenderer from './components/BrokerTableRenderer';

const GetUpdateMissingDetails = ({
  searchParams,
  searchString,
  sortByData,
  filters,
  page,
  offset,
}) => {
  const limit = 10;

  const { loading, data, error, refetch } = useQuery(GET_BROKERS, {
    variables: {
      ...filters,
      limit,
      offset,
      page,
      searchParams,
      searchString,
      sortParams: sortByData,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (error) return <span>{error.message}</span>;

  if (data) {
    const {
      getBrokersDirectory: { data: brokersData, totalCount },
    } = data;

    if (!brokersData.length) return <NoData />;

    const pageCount = Math.ceil(totalCount / limit);

    return (
      <BrokerTableRenderer
        refetch={refetch}
        offset={offset}
        page={page}
        limit={limit}
        filters={filters}
        pageCount={pageCount}
        totalCount={totalCount}
        brokersData={brokersData}
      />
    );
  }
  return null;
};

const UpdateMissingDetailsWrapper = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState('first_name');
  const [searchString, setSearchString] = useState(null);
  const [sortByData, setSortByData] = useState('');

  const {
    missingBrokersInfo: {
      filters,
      previousPageInfo: { offset, page },
    },
  } = useSelector((state) => state.brokersDirectory);

  useEffect(() => {
    if (
      filters.missingEmail &&
      !searchString &&
      (searchParams === 'last_name' ||
        searchParams === 'first_name' ||
        searchParams === 'email')
    ) {
      setSearchString(null);
    } else if (
      !filters.missingEmail &&
      searchParams === 'email' &&
      !searchString
    ) {
      setSearchString('');
    }
  }, [filters.missingEmail]);

  const handleSearchParams = (value) => {
    if ((value === 'last_name' || value === 'first_name') && !searchString) {
      setSearchString(null);
    }
    if (value === 'email' && filters.missingEmail && !searchString) {
      setSearchString(null);
    }
    if (value === 'email' && !filters.missingEmail && !searchString) {
      setSearchString('');
    }
    if (value === 'company' && !searchString) {
      setSearchString('');
    }

    setSearchParams(value);
    dispatch(
      addMissingBrokerPageInfo({
        missingBrokersInfo: {
          filters,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const handleSort = (value) => {
    setSortByData(value);
    dispatch(
      addMissingBrokerPageInfo({
        missingBrokersInfo: {
          filters,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const handleSearchString = (value) => {
    setSearchString(value);
    dispatch(
      addMissingBrokerPageInfo({
        missingBrokersInfo: {
          filters,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <>
      <div className="broker-top-space">
        <Row>
          <Col lg={4} className="ml-auto mt-3">
            <SortBroker handleSort={handleSort} sortByData={sortByData} />
          </Col>
          <Col lg={4} className="mt-3">
            <div>
              <SearchBroker
                searchParams={searchParams}
                searchString={searchString}
                handleSearchParams={handleSearchParams}
                handleSearchString={handleSearchString}
              />
            </div>
          </Col>
        </Row>
        <GetUpdateMissingDetails
          searchParams={searchParams}
          searchString={searchString}
          sortByData={sortByData}
          filters={filters}
          offset={offset}
          page={page}
        />
      </div>
    </>
  );
};

export default UpdateMissingDetailsWrapper;
