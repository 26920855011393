import { gql } from '@apollo/client';

export const GET_CUSTOM_IMAGE_PRE_SIGNED_URL = gql`
  mutation getCustomImagePreSignedUrl(
    $packageType: packageType
    $customImageInput: [CustomImageInput!]!
  ) {
    getCustomImagePreSignedUrl(
      customImageInput: $customImageInput
      packageType: $packageType
    )
  }
`;

export const GET_S3_IMAGE_PRE_SIGNED_URL = gql`
  query getS3ImagePreSignedUrl(
    $packageType: packageType
    $customImageInput: [CustomImageInput!]!
  ) {
    getS3ImagePreSignedUrl(
      customImageInput: $customImageInput
      packageType: $packageType
    )
  }
`;

export const UPDATE_CUSTOM_IMAGE_S3_URL = gql`
  mutation updateCustomImageS3Url(
    $input: [UpdateS3CustomImageInput!]!
    $uuid: String!
    $packageType: packageType
    $userUuid: UUID
  ) {
    updateCustomImageS3Url(
      input: $input
      uuid: $uuid
      packageType: $packageType
      userUuid: $userUuid
    )
  }
`;
