import React, { lazy } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCommentAlt } from '@fortawesome/free-solid-svg-icons';

import ActiveProjects from 'pages/ManagementPortal/components/Projects/components/ActiveProjects';
import Completed from 'pages/ManagementPortal/components/Projects/components/Completed';
import NeedMyAttention from 'pages/ManagementPortal/components/Projects/components/NeedMyAttention';
import activeFill from 'assets/refresh-fill.svg';
import AttentionIcon from 'pages/ManagementPortal/components/Projects/components/ProjectDetail/components/ProjectDetailContent/components/TaskItem/components/AttentionIcon';
import { PLAN_TYPE_ICONS } from 'utils/helper';

const routeProjects = import('pages/ManagementPortal/components/Projects');
const Projects = lazy(() => routeProjects);

export const ProjectDetail = (rowContent, { plan }) => {
  let icon = null;
  let altText = null;
  if (plan !== 'Not Specified') {
    const iconObj = PLAN_TYPE_ICONS[plan];
    icon = iconObj.icon;
    altText = iconObj.altText;
  }
  return (
    <div className="d-flex">
      {icon && altText ? (
        <img className="mr-2" src={icon} alt={altText} />
      ) : (
        <span className="plan-image-placeholder mr-3" />
      )}
      <strong
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px', fontWeight: '500' }}
        title={rowContent}
      >
        {rowContent}
      </strong>
    </div>
  );
};

export const ProjectDetailCheckIcon = (rowContent, { status }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faCheckCircle}
        size="lg"
        className="mr-3"
        color={status === 'COMPLETED' ? '#64BC26' : '#ccc'}
      />
      <span
        className="text-truncate d-inline-block align-middle maxWidth90Per"
        title={rowContent}
      >
        {rowContent}
      </span>
    </>
  );
};

export const ProjectDetailCompleteLabel = (rowContent, { lastStatus }) => {
  const classString = `${lastStatus
    .replace(/[^\w]/g, '-')
    .toLowerCase()}-badge`;

  return (
    <span title={lastStatus} className={`badge text-truncate ${classString}`}>
      {lastStatus}
    </span>
  );
};

export const ProjectDetailChatIcon = (rowContent, { id, alertDetails }) => {
  return (
    <>
      {alertDetails.length ? (
        <AttentionIcon id={id} alertDetails={alertDetails} />
      ) : (
        ''
      )}
    </>
  );
};

export const TruncatedDetail = (rowContent, row) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={rowContent}
      >
        {rowContent}
      </span>
    </>
  );
};

export const TruncatedBrokerName = (rowContent, { broker }) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={rowContent}
      >
        {broker[0].brokerName}
      </span>
    </>
  );
};

export const ProjectStatus = (rowContent, { trackStatus, hasMessage }) => {
  return (
    <>
      <div style={{ width: '120px', display: 'inline-block' }}>
        {trackStatus === 'NOT STARTED' ? (
          <span className="not-started">{trackStatus}</span>
        ) : (
          <span className="active-td">
            <img
              src={activeFill}
              alt="active td"
              className="align-middle mr-1"
            />
            {trackStatus}
          </span>
        )}
      </div>
      {hasMessage && (
        <span className="chat-span">
          <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#ccc" />
          <span>1</span>
        </span>
      )}
    </>
  );
};

export const OrderedBy = (rowContent, row) => (
  <span className="text-capitalized">{rowContent}</span>
);

export const TABS = [
  {
    tabId: 0,
    label: 'Projects',
    link: '/projects',
    type: `projects`,
    path: '/management-portal/projects',
    component: Projects,
    subNavs: [
      {
        tabId: 1,
        label: 'Active Projects',
        link: `/projects/active-projects`,
        type: `active-projects`,
        path: 'management-portal/projects/active-projects',
        component: ActiveProjects,
      },
      {
        tabId: 1,
        label: 'Need My Attention',
        link: `/projects/need-my-attention`,
        type: `need-my-attention`,
        path: 'management-portal/projects/need-my-attention',
        component: NeedMyAttention,
      },
      {
        tabId: 1,
        label: 'Completed',
        link: `/projects/completed`,
        type: `completed`,
        path: 'management-portal/projects/completed',
        component: Completed,
      },
    ],
  },
];

export const getTab = (pathname) => {
  const params = pathname.split('/');
  return params[params.length - 1];
};

export const getCurrentTab = (pathname) => {
  return TABS.filter((TAB) => TAB.type === getTab(pathname))[0];
};

export const PROJECT_DETAIL_COLUMN = [
  {
    dataField: 'task',
    text: 'Task Name',
    formatter: ProjectDetailCheckIcon,
  },
  {
    dataField: 'completedAt',
    text: 'Product complete',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: ProjectDetailCompleteLabel,
  },
  {
    dataField: 'isMessage',
    text: 'Product message',
    formatter: ProjectDetailChatIcon,
  },
];

export const ACTIVE_PROJECT_COLUMN = [
  {
    dataField: 'projectName',
    text: 'Project',
    sort: true,
    formatter: ProjectDetail,
  },
  {
    dataField: 'broker',
    text: 'Broker',
    sort: true,
    formatter: TruncatedBrokerName,
  },
  {
    dataField: 'company',
    text: 'Company',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'eliteAuction',
    text: 'Elite / Auction',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'startDate',
    text: 'Start date',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'trackStatus',
    text: 'Status',
    sort: true,
    formatter: ProjectStatus,
  },
  {
    dataField: 'orderCreatedBy',
    text: 'Ordered By',
    sort: true,
    formatter: OrderedBy,
  },
];

export const CREXI_STATUS = {
  NOT_STARTED: 'Not Started',
};

export const convertDateWithTimezone = (date) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = moment(moment.utc(date));
  return moment.tz(newDate, tz).format('MM/DD/YYYY HH:mm:ss A');
};

export const PROJECT_LIST = [
  {
    id: 1,
    projectName: 'Mansion Height 99',
  },
  {
    id: 2,
    projectName: 'Treasure Apollo',
  },
  {
    id: 3,
    projectName: '72 Height Hills',
  },
];

export const EDITOR_REGISTRATION_VALUES = {
  photo: '',
  phone: '',
  officePhone: '',
  address: {
    state: '',
    zip: '',
    address: '',
    city: '',
  },
  country: '',
  timeZone: '',
  paymentVendorName: '',
  editorVendorId: '',
  editorSkills: {
    'Basic Models': 0,
    'Photorealistic Models': 0,
    'Premium Plus': 0,
    Premium: 0,
    Standard: 0,
    Vidspection: 0,
  },
  editorSkillsRatings: {
    videoCreation: 0,
    animation: 0,
    '3DModelBuilding': 0,
    motionGraphics: 0,
    drawingPhotoShopTools: 0,
  },
};

export const RATINGS = ['No Skills', 'Start', 'Good', 'Strong', 'Expert'];

export const EDITOR_SKILLS = {
  videoCreation: 'Video Creation',
  '3DModelBuilding': '3D Model Building',
  motionGraphics: 'Motion Graphics',
  animation: 'Animation',
  drawingPhotoShopTools: 'Drawing/Photoshop Tools',
};

export const TAB_NAMES = {
  'PROJECT INVITATION': 'NEW INVITE',
  'ACTIVE PROJECT': 'ACTIVE',
  'COMPLETED PROJECT': 'COMPLETED',
};
export const ASSET_TAB_NAMES = ['OVERVIEW', 'ASSETS'];

export const STATUS_COLUMN = { headerName: 'Status', width: 100 };
export const NEW_INVITE_STATUS_COLUMN = { headerName: '', width: 350 };
export const DELETE_ACTION = 'Delete';
export const EDITOR_PROJECT_ACTIONS = {
  Accept: 'accepted',
  Decline: 'declined',
  Delete: 'deleted',
};
export const BRONZE_DOWNLOAD_EXCEL_PATH = '/Project Inputs/Project Data';
