import React, { useContext } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import PageLoader from 'pages/Search/common/PageLoader';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import ProperyCard from './components/ProperyCard';
import ProperyDetails from './components/ProperyDetails';

const PropertyDetail = (props) => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
    setIsIVCDisabled,
    setGalleryLoading,
    galleryImage,
    setGalleryImage,
  } = useContext(assetDetailContext);

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;

  if (getAssetDetails) {
    // if (!getAssetDetails.interiorVideoContact) {
    //   setIsIVCDisabled(true);
    // }

    const { asset } = getAssetDetails;
    return (
      <>
        <PropertyDetailHeader
          fieldKey="propertyCardDetails"
          values={{ asset }}
          getAssetDetails={getAssetDetails}
          isValid
          refetch={refetchAssetDetail}
          galleryImage={galleryImage}
          {...props}
        />
        <div className="themed-container property-detail-page">
          <div className="search-result-header ">
            <Card className="shadow border-radius-8px">
              <CardBody className="px-3">
                <Row>
                  <ProperyCard
                    {...asset}
                    setGalleryLoading={setGalleryLoading}
                    galleryImage={galleryImage}
                    setGalleryImage={setGalleryImage}
                  />
                  <ProperyDetails {...asset} />
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
  return null;
};
export default PropertyDetail;
