import React from 'react';
import { CREXI_BRONZE } from 'pages/CrexiBronze/constant';

const ChapterRibbon = ({ currentChapter }) => {
  return (
    <div className="temp-settings p-2 ml-3 mr-3 mt-3">
      <h6 className='font-size-14'>
        Showing settings for - Chapter {currentChapter} of{' '}
        {CREXI_BRONZE.TOTAL_CHAPTERS}
      </h6>
    </div>
  );
};

export default ChapterRibbon;
