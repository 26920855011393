import React, { useContext } from 'react';
import { Nav } from 'reactstrap';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { assetDetailContext } from 'contexts/AssetDetailContext';
import { TABS } from 'pages/Search/constant';
import { useGetAssestPlan } from 'pages/Search/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SILVER } from 'constants/appConstants';
import { checkIVCComplete } from 'utils/helper';

const DetailNavigations = () => {
  const { url } = useRouteMatch();
  const { plan } = useSelector((state) => state.salesPortal);

  const {
    assetToEdit: { editStatus, assetData },
    assetDetail,
    isIVCDisabled,
  } = useContext(assetDetailContext);

  const { data, loading } = useGetAssestPlan();
  const getAssetPlan = data && data.getAssetPlan ? data.getAssetPlan : null;

  const getSelectedPlan = () => {
    if (getAssetPlan) {
      const { type } = getAssetPlan;
      if (type === SILVER) {
        return true;
      }
      if (type === SILVER && plan.type === SILVER) {
        return true;
      }
    }
    if (plan.type === SILVER) {
      return true;
    }
    return false;
  };

  const checkIVCData = () => {
    let status = false;
    if (editStatus && assetData && assetData.interiorVideoContact) {
      status = checkIVCComplete(assetData.interiorVideoContact);
    }
    if (assetDetail && assetDetail.interiorVideoContact) {
      status = checkIVCComplete(assetDetail.interiorVideoContact);
    }
    return status;
  };

  const isIVCompleted = checkIVCData();

  const hideInteriorVideoContact = getSelectedPlan();

  return (
    <div className="property-detail-tabs">
      <Nav vertical>
        {TABS.map((TAB) => {
          const { label } = TAB;
          // if (label === 'Interior Video Contact') {
          //   return null;
          // }
          return (
            <NavLink
              key={TAB.label}
              className={
                label === 'Order Summary' &&
                isIVCDisabled 
                // &&
                // plan.type !== 'SILVER'
                  ? 'nav-link row disabled'
                  : 'nav-link row'
              }
              isActive={(match) => match}
              to={`${url}${TAB.link}`}
            >
              {label}{' '}
              {label === 'Interior Video Contact' &&
              !isIVCDisabled &&
              isIVCompleted ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : (
                ''
              )}
            </NavLink>
          );
        })}
      </Nav>
    </div>
  );
};

export default DetailNavigations;
