import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
//
import { VidPitchContext } from 'contexts/VidPitchContext';
import { RENDER_EDITED_VIDEO_TEMPLATE } from 'pages/Vidpitch/VidPitchGraphQL';

const RenderVideoConfirmationModal = ({
  videoId,
  confirmationModal,
  toggleConfirmationModal,
}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const { modifyVideoDetails } = useContext(VidPitchContext);

  const [renderEditedVideoTemplate] = useMutation(
    RENDER_EDITED_VIDEO_TEMPLATE,
    {
      onCompleted: (data) => {
        const {
          renderEditedVideoTemplate: { uuid },
        } = data;
        setLoader(false);
        toggleConfirmationModal(false);
        history.push(`/${uuid}/video-draft`);
        toast.success(
          'Edited data is processing for video rendering. Video will be rendered in 2 - 4 mins.'
        );
      },
      onError: (err) => {
        setLoader(false);
        toggleConfirmationModal(false);
        toast.error(
          'An error occurred, while processing video rendering. Please try again.'
        );
      },
    }
  );

  const handleFormSubmit = async () => {
    try {
      setLoader(true);
      const { previewURL, ...modifyVideoData } = modifyVideoDetails;
      await renderEditedVideoTemplate({
        variables: {
          videoId,
          templateId: modifyVideoDetails.selectedTemplateId,
          formData: { ...modifyVideoData },
          isEdited: true,
        },
      });
    } catch (err) {
      toast.error(
        'An error occurred, while processing video rendering. Please try again.'
      );
    }
  };

  return (
    <Modal isOpen={confirmationModal} className="modal-dialog-centered">
      <ModalHeader>Ready to see your new video?</ModalHeader>
      <ModalBody>Video render time is 2 - 4 mins.</ModalBody>
      <ModalFooter>
        <Button
          className="font-size-13"
          disabled={loader}
          onClick={() => toggleConfirmationModal(false)}
        >
          Cancel - Make More Edits
        </Button>
        <Button
          type="submit"
          disabled={loader}
          onClick={handleFormSubmit}
          className="font-size-13 ml-2"
        >
          Make My New Video!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RenderVideoConfirmationModal;
