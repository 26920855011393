import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
//
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import FileUploaderVidPitch from 'common/FileUploaderVidPitch';
import {
  crexiBronzeCurrentPreviewURL,
  crexiBronzeLogoAndQrUrl,
} from 'pages/CrexiBronze/CrexiBronzeAction';
import {
  BRONZE_FILE_TYPES,
  CUSTOM_IMAGE_UPLOAD_ENUM,
  renderPropertyBoundaryImage,
} from 'pages/CrexiBronze/constant';
import { GET_CUSTOM_ASSET_IMAGES } from 'pages/Vidpitch/VidPitchGraphQL';
import ChangeImageModal from 'pages/Vidpitch/components/VidpitchVideo/components/VidPitchVideoDetails/components/MultiStepModifyVideo/components/EditTextImages/components/ChangeImage';

const UploadImages = ({
  currentChapter,
  projectUuid,
  refetchBronzeData,
  setPropertyBoundaryImg,
  propertyBoundaryImg,
}) => {
  const dispatch = useDispatch();
  const { id: assetId } = useParams();

  const [chapterImageURL, setChapterImageURL] = useState('');
  const [changeImageModal, toggleChangeImageModal] = useState(false);
  const [fileType, setFileType] = useState(null);

  const { currentPreviewURL, logoAndQrUrl, isVideoTextChange } = useSelector(
    (state) => state.crexiBronze
  );

  const { data: propertyBoundaryImages, loading: customImageLoader } = useQuery(
    GET_CUSTOM_ASSET_IMAGES,
    {
      variables: {
        fileType: BRONZE_FILE_TYPES.PROPERTY_BOUNDARY,
        offset: 0,
        limit: 1,
        uuid: projectUuid,
        ...(currentChapter ? { chapter: `Chapter_${currentChapter}` } : {}),
        packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!customImageLoader && propertyBoundaryImages?.getCustomAssetImages) {
      setPropertyBoundaryImg(propertyBoundaryImages?.getCustomAssetImages);
    }
  }, [customImageLoader, propertyBoundaryImages]);

  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  useEffect(() => {
    if (chapterImageURL) {
      dispatch(
        crexiBronzeCurrentPreviewURL({ currentPreviewURL: chapterImageURL })
      );
    }
  }, [chapterImageURL]);

  useEffect(() => {
    if (!chapterImageURL) {
      setChapterImageURL(currentPreviewURL);
    }
  }, [currentPreviewURL]);

  const handleFileUploadModel = (type) => {
    setFileType(type);
    toggleChangeImageModal(true);
  };

  const renderBoundaryImage = () => {
    return (
      <Col md="5">
        <p className="font-size-14 mb-1">Property Boundary Image</p>
        <div className="px-0">
          <Row className="m-0">
            {/* <Col md={5}>
         <img
           src={
             (haveUploadedAssets && currentPreviewURL) ||
             chapterImageURL
               ? currentPreviewURL
               : uploadImg
           }
           width={120}
           height={120}
           alt="chapter-thumbnail"
           className="object-fit-cover rounded"
         />
       </Col> */}
            <Col className="p-0 d-flex align-items-end">
              <div>
                <Button
                  outline
                  className="font-size-13 px-2 upload-image-btn upload-section"
                  onClick={() =>
                    handleFileUploadModel(BRONZE_FILE_TYPES.PROPERTY_BOUNDARY)
                  }
                >
                  Select Boundary Image
                  {propertyBoundaryImg?.length > 0 && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success ml-1"
                    />
                  )}
                </Button>
                <p className="mb-2 font-size-12 text-muted">
                  Supporting files png, jpeg
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  return (
    <>
      <Row className="mb-4">
        {!isVideoTextChange && currentChapter === 6 && (
          <Col md={5}>
            <div style={{ width: '268px' }}>
              <p className="font-size-14 mb-1">QR Code Image</p>
              <FileUploaderVidPitch
                currentChapter={currentChapter}
                refetchBronzeData={refetchBronzeData}
                logoAndQrUrl={logoAndQrUrl}
                uuid={projectUuid}
                fileType={BRONZE_FILE_TYPES.QR_CODE}
                versionSuffix={0}
                refetchAssets={() => {}}
                isBronze
                updateBronzeUrl={(qrImageObj) => {
                  dispatch(
                    crexiBronzeLogoAndQrUrl({
                      logoAndQrUrl: qrImageObj?.s3ImageLink ?? logoAndQrUrl,
                    })
                  );
                }}
              />
            </div>
          </Col>
        )}
      </Row>
      {!isVideoTextChange && (
        <Row>
          {currentChapter === 1 && role !== ROLES.USER && (
            <Col md="5">
              <div>
                <p className="font-size-14 mb-1">Logo</p>
                <FileUploaderVidPitch
                  currentChapter={currentChapter}
                  refetchBronzeData={refetchBronzeData}
                  logoAndQrUrl={logoAndQrUrl}
                  uuid={projectUuid}
                  fileType={BRONZE_FILE_TYPES.LOGO}
                  versionSuffix={0}
                  refetchAssets={() => {}}
                  isBronze
                  updateBronzeUrl={(logoObj) =>
                    dispatch(
                      crexiBronzeLogoAndQrUrl({
                        logoAndQrUrl: logoObj?.s3ImageLink ?? logoAndQrUrl,
                      })
                    )
                  }
                />
              </div>
            </Col>
          )}
          {role !== ROLES.USER && (
            <Col md={5}>
              <p className="font-size-14 mb-1">Image</p>
              <div className="px-0">
                <Row className="m-0">
                  {/* <Col md={5}>
                  <img
                    src={
                      (haveUploadedAssets && currentPreviewURL) ||
                      chapterImageURL
                        ? currentPreviewURL
                        : uploadImg
                    }
                    width={120}
                    height={120}
                    alt="chapter-thumbnail"
                    className="object-fit-cover rounded"
                  />
                </Col> */}
                  <Col className="p-0 d-flex align-items-end">
                    <div>
                      <Button
                        outline
                        className="font-size-13 px-4 upload-image-btn upload-section"
                        onClick={() =>
                          handleFileUploadModel(BRONZE_FILE_TYPES.IMAGE)
                        }
                      >
                        Select Image
                        {!chapterImageURL.includes(
                          'default_bronze_image.png'
                        ) && (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success ml-1"
                          />
                        )}
                      </Button>
                      <p className="mb-2 font-size-12 text-muted">
                        Supporting files png, jpeg
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
          {[
            ROLES.USER,
            ROLES.VIDEOMS_PM,
            ROLES.ADMIN,
            ROLES.VIDEOMS_QC,
          ].includes(role) &&
            renderPropertyBoundaryImage(currentChapter) &&
            renderBoundaryImage()}
        </Row>
      )}
      {changeImageModal && (
        <ChangeImageModal
          uuid={projectUuid}
          assetCrexiId={assetId}
          currentChapter={currentChapter}
          chapterImageURL={chapterImageURL}
          changeImageModal={changeImageModal}
          setChapterImageURL={setChapterImageURL}
          toggleChangeImageModal={toggleChangeImageModal}
          isBronze
          fileType={fileType}
          setPropertyBoundaryImg={setPropertyBoundaryImg}
        />
      )}
    </>
  );
};

export default UploadImages;
