import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
//
import Loader from 'common/Loader';
import {
  ADDITIONAL_NOTES_ADDED,
  LIST_NOTES,
} from 'pages/Pilot/PilotPortalGraphQL';
import NoteMessaging from './components/NoteMessaging';
import NoteListing from './components/NotesListing';

const AddNote = ({
  title,
  folderId,
  project: { projectManagerId, projectPilotId },
  refetchAssets,
}) => {
  const [isAddNoteModalOpen, setAddNoteModal] = useState(false);

  const toggleNoteModal = (status) => {
    setAddNoteModal(status);
  };

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    LIST_NOTES,
    {
      variables: {
        input: {
          folderId,
          orderBy: 'ASC',
        },
      },
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: ADDITIONAL_NOTES_ADDED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.addedAdditionalNotes.listNotes) {
          const {
            addedAdditionalNotes: { listNotes },
          } = subscriptionData.data;
          if (listNotes?.length > 0) {
            if (listNotes[0].folderId === folderId) {
              return {
                listNotes: [...prev.listNotes, ...listNotes],
              };
            }
          }
          return prev;
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const { listNotes } = data;

  return (
    <>
      <Button
        size="sm"
        className="pl-4 pr-0 mt-1 border-0 outline-none"
        color="link"
        onClick={() => toggleNoteModal(true)}
      >
        Add/View Notes
      </Button>
      {isAddNoteModalOpen && (
        <Modal
          isOpen={isAddNoteModalOpen}
          toggle={() => toggleNoteModal(false)}
          className="modal-dialog-centered add-notes-modal"
        >
          <ModalHeader toggle={() => toggleNoteModal(false)}>
            Add Note - {title}
          </ModalHeader>
          <ModalBody>
            <NoteListing listNotes={listNotes} />
          </ModalBody>
          <ModalFooter className="">
            <NoteMessaging
              folderId={folderId}
              refetchNotes={refetch}
              projectPilotId={projectPilotId}
              projectManagerId={projectManagerId}
              refetchAssets={refetchAssets}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default AddNote;
