import { BRONZE_FILE_TYPES } from 'pages/CrexiBronze/constant';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Button, Col, Row, Spinner } from 'reactstrap';

const CustomImage = ({
  type,
  error,
  loader,
  customImages,
  chapterAssetURL,
  setChapterAssetURL,
  isPropertyBoundary,
}) => {
  if (loader) return <Spinner color="secondary" />;
  if (error) return <div>{error.message}</div>;

  const handleChange = (imageURL) => {
    if (!isPropertyBoundary) {
      setChapterAssetURL(imageURL);
    }
  };

  const availableAssetCount = customImages ? customImages.length : 0;

  const renderAssetCount = () => {
    switch (type) {
      case BRONZE_FILE_TYPES.IMAGE:
        return `Custom Uploaded Images (${availableAssetCount})`;
      case BRONZE_FILE_TYPES.PROPERTY_BOUNDARY:
        return `Property Boundary Images (${availableAssetCount})`;
      default:
        return null;
    }
  };

  return (
    <div className="my-4">
      <div className="mb-2">
        <b className="d-flex align-left font-size-16">{renderAssetCount()}</b>
      </div>
      {availableAssetCount ? (
        <div className="pt-2">
          <Scrollbars
            style={{
              width: '100%',
              height: `calc(100vh - 525px)`,
              margin: '0px',
            }}
          >
            <Row className="p-1 m-1">
              {customImages.map((item) => {
                const { s3ImageLink: URL } = item;
                return (
                  <Col key={URL} md={3} className="p-0 pr-2 pb-2">
                    {/* <FormGroup check inline className="template-checkbox">
                      <Input
                        type="checkbox"
                        checked={chapterAssetURL === URL}
                        onChange={() => handleChange(URL)}
                      />
                    </FormGroup> */}
                    <Button
                      onClick={() => handleChange(URL)}
                      color="link"
                      className="p-0"
                    >
                      <img
                        src={URL}
                        alt="custom"
                        className={
                          chapterAssetURL === URL
                            ? 'template-img-2 v-active-image'
                            : 'template-img-2'
                        }
                      />
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </Scrollbars>
        </div>
      ) : (
        <span>
          {isPropertyBoundary
            ? 'No property Boundary images uploaded yet.'
            : 'No custom images uploaded yet.'}
        </span>
      )}
    </div>
  );
};

export default CustomImage;
