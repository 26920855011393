import React from 'react';
import {
  convertDateWithTimezone,
  CREXI_STATUS,
} from 'pages/ManagementPortal/constant';
import { useQuery } from '@apollo/client';
import { LIST_ASANA_TASK_ACTIVITIES } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import Loader from 'common/Loader';
import NoData from 'common/NoData';

const ActivityItems = ({ asanaTaskId, planName }) => {
  const { NOT_STARTED } = CREXI_STATUS;

  const { loading, data, error } = useQuery(LIST_ASANA_TASK_ACTIVITIES, {
    variables: {
      taskId: asanaTaskId,
      planName,
    },
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <div className="text-center">
        <Loader />
      </div>
    );
  if (error) return <span> {error.message} </span>;

  if (data) {
    if (data.listAsanaTaskActivities) {
      const {
        listAsanaTaskActivities: { activities },
      } = data;

      if (activities.length) {
        return activities.map((activityData, index) => {
          const { lastUpdatedBy, completedOn, crexiTaskStatus } = activityData;

          if (index === 0 && crexiTaskStatus === NOT_STARTED) {
            return (
              <tr key={`${index + 1}`} className="activity-log">
                <td />
                <td>
                  <p className="textIndent45 mb-0 d-block text-truncate">
                    Task not started yet
                  </p>
                </td>
                <td>-</td>
                <td />
                <td />
              </tr>
            );
          }

          return (
            <tr key={`${index + 1}`} className="activity-log">
              <td />
              <td>
                <p className="textIndent45 mb-0 d-block text-truncate">
                  {lastUpdatedBy} updated to{' '}
                  <strong className="status" title={crexiTaskStatus}>
                    {crexiTaskStatus}{' '}
                  </strong>
                </p>
              </td>
              <td>
                <span className="date-time">
                  {completedOn
                    ? convertDateWithTimezone(completedOn)
                    : 'Task In Progress'}
                </span>
              </td>
              <td />
              <td />
            </tr>
          );
        });
      }
      return <NoData customMessage="No activities found!!" />;
    }
  }

  return <NoData customMessage="No activities found!!" />;
};

export default ActivityItems;
