import React from 'react';
// import moment from 'moment';
import * as moment from 'moment-timezone';
import {
  cloneDeep,
  isArray,
  filter,
  isObject,
  transform,
  omit,
  pick,
} from 'lodash';
import moviePlatinum from 'assets/movie.svg';
import movieGold from 'assets/movie-gold.svg';
import movieSilver from 'assets/movie-silver.svg';
import movieBronze from 'assets/movie-bronze.svg';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FOLDERS_FILES_TOGETHER,
  FOLDER_NAMES_TO_EXCLUDE,
  NOT_SPECIFIED,
} from 'constants/appConstants';
import { FILE_FORMATS } from 'constants/fileFormatAndTypes';

export const IVC_FIELDS = [
  'email',
  'name',
  'phone',
  'shootDate',
  'shootTime',
  'additionalNotes',
];

export const PLAN_TYPE_ICONS = {
  PLATINUM: {
    icon: moviePlatinum,
    altText: 'moviePlatinum',
  },
  GOLD: {
    icon: movieGold,
    altText: 'movieGold',
  },
  SILVER: {
    icon: movieSilver,
    altText: 'movieSilver',
  },
  BRONZE: {
    icon: movieBronze,
    altText: 'movieBronze',
  },
};

export const numberWithCommas = (numbers) => {
  const str = numbers.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
};

export const removeProperties = (updatedAssetDetails) => {
  const properties = ['createdAt', 'updatedAt', 'status', 'userId', 'assetId'];
  const details = cloneDeep(updatedAssetDetails);
  const changes = (details) => {
    const temp = details;
    transform(details, (result, value, key) => {
      if (properties.indexOf(key) >= 0) delete temp[key];
      if (isObject(value) || isArray(value)) changes(value);
      return details;
    });
    return temp;
  };
  return changes(details);
};

export const removeBlankCityCallouts = (dataArray) => {
  const properties = ['nearbyCities', 'nearbyCallouts'];
  const details = cloneDeep(dataArray);
  properties.forEach((row) => {
    if (isArray(details[row]) && details[row].length) {
      details[row] = filter(details[row], (o) => o.name !== '');
    }
  });
  return details;
};

export const ProjectStatus = (rowContent, row) => {
  const { trackStatus, hasMessage } = row;
  return (
    <>
      <div style={{ width: '120px', display: 'inline-block' }}>
        <span
          className={
            trackStatus === 'Not started' ? 'not-started' : 'active-td'
          }
        >
          {trackStatus}
        </span>
      </div>
      {hasMessage && (
        <span className="chat-span">
          <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#ccc" />
          <span>1</span>
        </span>
      )}
    </>
  );
};

export const allowedUserChecker = (roles, allowedRoles) =>
  allowedRoles.includes(roles);

export const checkIVCComplete = (ivcData) => {
  const tempIVC = pick(ivcData, IVC_FIELDS);
  const ivcObj = omit(tempIVC, ['additionalNotes']);
  const status = Object.values(ivcObj).some((row) => row);
  return status;
};

export const getFileName = (filename) => {
  const name = filename.split('/');
  return name[name.length - 1];
};

export const parseToInteger = (value) => {
  return parseInt(value, 10);
};

export const updateFileNameWithVersion = (version, fileName) => {
  if (version === 0) {
    return fileName.replaceAll(' ', '');
  }
  const splittedFileName = fileName.split('.');
  const fileFormat = `.${splittedFileName[splittedFileName.length - 1]}`;
  const fileNameWithVersion = fileName.replace(
    fileFormat,
    `(${version})${fileFormat}`
  );
  return fileNameWithVersion.replaceAll(' ', '_');
};

export const dateTimeFormatter = (dateTimeStamp, format = 'MM/DD/YYYY') => {
  if (dateTimeStamp !== NOT_SPECIFIED) {
    return moment(dateTimeStamp).format(format);
  }
  return NOT_SPECIFIED;
};

export const getExtensionFromFileName = (filename) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

export const convertDateWithTimezone = (date) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = moment(moment.utc(date));
  return moment.tz(newDate, tz).format('MM/DD/YYYY HH:mm:ss A');
};

export const extractSubDomain = () => {
  const {
    location: { host },
  } = window;
  return host.split('.')[0];
};

export const generateKey = (prefix) => {
  return `${prefix}_${new Date().getTime()}`;
};

export const blobToDownloadLink = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const hasSubFolders = (parent) => {
  return !FOLDER_NAMES_TO_EXCLUDE.includes(parent);
};

export const filesAndFoldersTogether = (parent) => {
  return Object.keys(FOLDERS_FILES_TOGETHER).includes(parent);
};

export const polygonStyle = [
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#48cae4',
      'line-width': 4,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': '#48cae4',
      'fill-outline-color': '#48cae4',
      'fill-opacity': 0.1,
    },
  },
  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#48cae4',
      'line-width': 4,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#FFF',
    },
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 4,
      'circle-color': '#48cae4',
    },
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-width': 3,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'fill-color': '#000',
      'fill-outline-color': '#000',
      'fill-opacity': 0.1,
    },
  },
  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-width': 3,
    },
  },
];
