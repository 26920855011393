import React, { useState } from 'react';
import { Table, Tooltip } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'lodash';
import {
  faCaretRight,
  faCaretDown,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams } from 'react-router-dom';
import { PROJECT_DETAIL_COLUMN } from 'pages/ManagementPortal/constant';
import ActivityItems from './components/ActivityItems';

const TaskItem = ({
  sectionName = 'NA',
  missingTasks = [],
  sectionTasks,
  sectionId,
  planName,
}) => {
  const { projectId } = useParams();
  const location = useLocation();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const idFromLocation = location.hash.split('#')[1];
  const idFromParam = projectId.split('#')[1];

  const renderSectionTasks = () => {
    let expandedTableId = [];
    if (!sectionTasks.length)
      return <div className="no-activity-message">No task found!</div>;
    return sectionTasks.map((row, index) => {
      const { task, activities } = row;
      const lastObject = activities[activities.length - 1];
      const taskID = task.split(' ').join('_');

      const taskData = [
        {
          ...row,
          ...lastObject,
          sectionName,
          planName,
        },
      ];
      if ((idFromParam || idFromLocation) === taskID) {
        expandedTableId = task;
      }

      return (
        <div key={`${task}_${index + 1}`} className="expand-table-container">
          <CustomTable
            keyField="task"
            data={taskData}
            columns={PROJECT_DETAIL_COLUMN}
            classes="mb-0  project-detail-inner-table "
            id={taskID}
            planName={planName}
            expandedTableId={expandedTableId}
          />
        </div>
      );
    });
  };

  const renderSectionNameWithMissingTasks = () => {
    if (isArray(missingTasks) && missingTasks.length) {
      const missingTasksText = `${missingTasks.join(
        ', '
      )} tasks are missing from template.`;

      return (
        <>
          {sectionName}
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="ml-2"
            id={`tooltip-${sectionId}`}
          />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`tooltip-${sectionId}`}
            toggle={toggleTooltip}
          >
            {missingTasksText}
          </Tooltip>
        </>
      );
    }

    return sectionName;
  };

  return (
    <>
      <h6 className="mt-4 mb-2 font-size-14 font-weight-bold">
        {renderSectionNameWithMissingTasks()}
      </h6>
      <div className="collapse-section">{renderSectionTasks()}</div>
    </>
  );
};

const CustomTable = ({ expandedTableId, planName, ...rest }) => {
  const expandRow = {
    parentClassName: (isExpanded) => {
      return 'parent-expand-bar';
    },
    renderer: ({ asanaTaskId }) => {
      return (
        <div className="">
          <Table className="project-detail-inner-table activity-table">
            <tbody>
              <ActivityItems asanaTaskId={asanaTaskId} planName={planName} />
            </tbody>
          </Table>
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon icon={faCaretDown} />;
      }
      return <FontAwesomeIcon icon={faCaretRight} />;
    },
    expanded: [expandedTableId],
  };

  return <BootstrapTable {...rest} expandRow={expandRow} />;
};

export default TaskItem;
