import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Card, CardBody, Container } from 'reactstrap';
//
import NoData from 'common/NoData';
import MailIcon from 'assets/mail-icon.png';
import Logo from 'assets/videoms-logo.png';
import PageLoader from 'common/PageLoader';
import { authContext } from 'contexts/AuthContext';
import VidTechHeader from 'pages/Vidpitch/common/VidtechHeader';
import { GET_VIDEO_DATA } from 'pages/Vidpitch/VidPitchGraphQL';
import VideoForm from './components/VideoForm';
import VideoDetails from './components/VideoDetails';
import MailTo from '../Signup/components/MailTo';

const VidPitchPurchase = () => {
  const { uuid } = useParams();
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  const { auth } = useContext(authContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasLinkExpired, setHasLinkExpired] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(true);

  const [getVideoData, { loading, data, error }] = useLazyQuery(GET_VIDEO_DATA);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}api/verify-user?link_id=${uuid}`,
      {
        method: 'GET',
        headers: new Headers({
          pragma: 'no-cache',
          'cache-control': 'no-store',
          'ngrok-skip-browser-warning': '69420',
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          const { isAlreadyRegistered, linkExpired } = data;
          setHasLinkExpired(linkExpired);
          setIsAlreadyRegistered(isAlreadyRegistered);
          if (!linkExpired && isAlreadyRegistered) {
            getVideoData({
              variables: {
                input: {
                  email: auth?.data?.email,
                  uuid,
                  videoViewed: null,
                },
              },
            });
          }
        } else {
          toast.error(data.error);
          setErrorMessage(data.error);
        }
        return null;
      })
      .catch((err) => {
        toast.error(err.message);
        setErrorMessage(err.message);
      });
  }, []);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const renderPurchasePage = () => {
    if (data?.getVideoData?.videoDetails) {
      const {
        getVideoData: { videoDetails, brokerDetails },
      } = data;

      const { isVideoPurchased } = videoDetails;

      return (
        <div className="vidTech">
          <Helmet defaultTitle="VidTech" />
          <VidTechHeader />
          <Container>
            <div className="pt-3">
              <p className="mb-2 font-size-18">
                <Link to={`/${uuid}/video-draft`}>
                  <i className="fa fa-arrow-left pr-2" aria-hidden="true" />
                  <span className="font-size-18">Back To Video</span>
                </Link>
              </p>
              <p className="pl-4">Video / Payment</p>
            </div>

            {isVideoPurchased && !clientSecret ? (
              <NoData
                className="mt-2"
                customMessage="This video is already purchased."
              />
            ) : (
              <Card className="border-0 shadow-lg">
                <CardBody className="p-0">
                  <Row>
                    <Col className="p-5 video-section">
                      <VideoDetails data={data} />
                    </Col>
                    <Col className="p-5">
                      <VideoForm
                        uuid={uuid}
                        email={brokerDetails?.brokerEmail}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Container>
        </div>
      );
    }
    return null;

    // return (
    //   <NoData customMessage="We are unable to process your payment request, please try after some time." />
    // );
  };

  return (
    <>
      {(hasLinkExpired || !isAlreadyRegistered) && (
        <div className="no-auth-main-container ">
          <div className="form-signin">
            <Card className="w-100 border-radius-8px">
              <CardBody className="p-4">
                <div className="mb-4">
                  <img src={Logo} alt="Logo" width="100" />
                </div>
                {hasLinkExpired && (
                  <div className="text-primary">
                    This link is already expired. Please contact vidTech team to
                    provide a new registration link.
                    <br />
                    <div className="mt-2">
                      <img
                        height="20"
                        src={MailIcon}
                        alt="MailIcon"
                        className="mr-2"
                      />
                      <MailTo email="team@videoms.com">team@videoms.com</MailTo>
                    </div>
                  </div>
                )}
                {!isAlreadyRegistered && !hasLinkExpired && (
                  <div className="text-primary">
                    Please get registered on vidPitch portal to begin with
                    payment process. Click to Register below.
                    <div className="mt-2">
                      <Link to={`/${uuid}/register`}>Register</Link>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessage && (
                  <div className="text-primary">{errorMessage}</div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {isAlreadyRegistered && !hasLinkExpired && renderPurchasePage()}
    </>
  );
};

export default VidPitchPurchase;
