import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
//
import { parseToInteger } from 'utils/helper';
import { authContext } from 'contexts/AuthContext';
import { EDITOR_PROJECT_ACTIONS } from 'pages/EditorPortal/constant';
import { newInviteProject } from 'pages/EditorPortal/EditorPortalActions';
import { PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';

const ProjectAcceptBox = ({
  projectId,
  refetchProjects,
  toggleDetails,
  isEditorPortal,
  isProjectInvitationDrawer,
  className = '',
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);

  const [projectAcceptedByEditor] = useMutation(
    PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT,
    {
      onCompleted: async ({ projectAcceptedByEditorOrPilot }) => {
        if (isProjectInvitationDrawer) toggleDetails();
        if (
          isEditorPortal &&
          projectAcceptedByEditorOrPilot?.status !==
            EDITOR_PROJECT_ACTIONS.Decline
        ) {
          dispatch(newInviteProject({ isNewProjectInvite: 'ACTIVE' }));
          return history.push(`/editor-portal/projects/${projectId}/folders`);
        }
        refetchProjects();
        setIsLoading(false);
        return toast.success(projectAcceptedByEditorOrPilot?.message);
      },
      onError: ({ message }) => {
        setIsLoading(false);
        toast.error(message);
        if (isProjectInvitationDrawer) toggleDetails();
      },
    }
  );

  const handleProjectStatus = async (status) => {
    try {
      setIsLoading(true);
      await projectAcceptedByEditor({
        variables: {
          projectId: parseToInteger(projectId),
          status,
          flag: 'EDITOR',
          editorId: auth?.data?.userId,
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div
      className={`d-flex text-center justify-content-between${
        className ? 'justify-content-center w-100' : ''
      }`}
    >
      <Button
        outline
        size="sm"
        key="decline"
        color="primary"
        className={`${className} mr-2`}
        disabled={isLoading}
        onClick={() => handleProjectStatus(EDITOR_PROJECT_ACTIONS.Decline)}
      >
        Decline
      </Button>
      <Button
        size="sm"
        key="accept"
        color="success"
        disabled={isLoading}
        onClick={() => handleProjectStatus(EDITOR_PROJECT_ACTIONS.Accept)}
        className={`${className}`}
      >
        <span className="text-white">Accept</span>
      </Button>
      <br />
    </div>
  );
};

export default ProjectAcceptBox;
