import {
  faCheckCircle,
  faPencilAlt,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

const REGEX_PHONE = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_DASH_REGEX = /\d{3}(?=\d{2,3})|\d+/g;

const BrokerItem = ({ brokerInfo, saveBrokersData }) => {
  const [brokerData, setBrokerData] = useState(brokerInfo);
  const [editRow, setEditRow] = useState(false);

  const { firstName, lastName, company, phone, email, totalActiveAssetsCount } =
    brokerData;

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    const brokerObj = {
      ...brokerData,
    };

    if (fieldName === 'phone') {
      if (!value.match(PHONE_DASH_REGEX)) {
        setBrokerData({ ...brokerObj, phone: [] });
        return true;
      }
      if (value) {
        let temp = value.match(PHONE_DASH_REGEX);
        temp = temp.join('-');
        brokerObj[fieldName] = [temp];
      } else brokerObj[fieldName] = [value];
    } else {
      brokerObj[fieldName] = value;
    }

    setBrokerData(brokerObj);
    return true;
  };

  const handleDisable = () => {
    if (email && phone.length > 0) {
      if (phone[0].match(REGEX_PHONE) && email.match(REGEX_EMAIL)) {
        return false;
      }
      return true;
    }
    return true;
  };

  const checkValidClass = (brokerData, fieldName) => {
    const { email, phone } = brokerData;
    let isValidClass = true;
    if (email || phone.length > 0) {
      if (email && fieldName === 'email') {
        if (email.match(REGEX_EMAIL)) {
          isValidClass = true;
        } else {
          isValidClass = false;
        }
      }

      if (phone.length > 0 && fieldName === 'phone') {
        if (phone[0].match(REGEX_PHONE)) {
          isValidClass = true;
        } else {
          isValidClass = false;
        }
      }
    } else {
      isValidClass = true;
    }
    return isValidClass;
  };

  const editBrokerData = () => setEditRow(true);

  const handleCancel = () => {
    setEditRow(false);
    setBrokerData(brokerInfo);
  };

  const handleSubmit = () => {
    setEditRow(false);
    saveBrokersData(brokerData);
  };

  const generateGoogleLink = (firstName, lastName, company) =>
    `https://www.google.com/search?q=${firstName}+${lastName}+${company}`;

  const renderPhoneInput = () => {
    return editRow ? (
      <input
        id="phone"
        name="phone"
        className={`form-control w-75 d-inline-flex mr-2 ${
          checkValidClass(brokerData, 'phone') ? '' : 'border-danger'
        } `}
        value={phone || ''}
        onChange={(e) => {
          handleInputChange(e, 'phone');
        }}
      />
    ) : (
      <a
        href={generateGoogleLink(firstName, lastName, company)}
        target="_blank"
        rel="noreferrer"
        className="w-75 d-inline-block mr-2"
      >
        <ul className="list-inline mb-0">
          {phone &&
            phone.map((phoneNumber) => (
              <li key={phoneNumber}>{phoneNumber}</li>
            ))}
        </ul>
      </a>
    );
  };

  const renderEmailInput = () => {
    return editRow ? (
      <input
        id="email"
        name="email"
        className={`form-control w-75 d-inline-flex mr-2 ${
          checkValidClass(brokerData, 'email') ? '' : 'border-danger'
        } `}
        value={email || ''}
        onChange={(e) => {
          handleInputChange(e, 'email');
        }}
      />
    ) : (
      <a
        href={generateGoogleLink(firstName, lastName, company)}
        target="_blank"
        rel="noreferrer"
        className="w-75 d-inline-block mr-2 text-truncate"
        title={email}
      >
        {email}
      </a>
    );
  };

  const renderButton = () => {
    return editRow ? (
      <>
        <Button
          onClick={() => handleSubmit()}
          className="check-btn"
          disabled={handleDisable()}
        >
          <FontAwesomeIcon icon={faCheckCircle} color="green" />
        </Button>

        <Button onClick={() => handleCancel()} className="check-btn">
          <FontAwesomeIcon icon={faTimesCircle} color="#f44336" />
        </Button>
      </>
    ) : (
      <Button
        onClick={() => editBrokerData(brokerData)}
        className="check-btn record-pencil"
      >
        <FontAwesomeIcon icon={faPencilAlt} color="#554f7f" />
      </Button>
    );
  };

  const renderCellContent = () => {
    return (
      <>
        <td>
          <a
            href={generateGoogleLink(firstName, lastName, company)}
            target="_blank"
            rel="noreferrer"
            className="w-75 d-inline-block mr-2"
          >
            {firstName}
          </a>
        </td>
        <td>
          <a
            href={generateGoogleLink(firstName, lastName, company)}
            target="_blank"
            rel="noreferrer"
            className="w-75 d-inline-block mr-2"
          >
            {lastName}
          </a>
        </td>
        <td>
          <a
            href={generateGoogleLink(firstName, lastName, company)}
            target="_blank"
            rel="noreferrer"
            title={company}
            className="w-75  text-truncate d-inline-block mr-2"
          >
            {!company ? 'N/A' : company}
          </a>
        </td>
        {totalActiveAssetsCount ? (
          <td>{totalActiveAssetsCount}</td>
        ) : (
          <td>--</td>
        )}
        <td>{renderPhoneInput()}</td>
        <td>{renderEmailInput()}</td>
        <td>{renderButton()}</td>
      </>
    );
  };
  return <tr className="update-record-row">{renderCellContent()}</tr>;
};
export default BrokerItem;
