import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Logo from 'assets/videoms-logo.png';
import { Link } from 'react-router-dom';

const SuccessMessage = () => {
  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <div className="mb-4">
              <img src={Logo} alt="Logo" width="100"/>
            </div>
            <p className="font-size-14 font-weight-bold">Reset Password</p>
            <div className="bright-yellow">
              <p className="font-size-14 mb-1">
                Your password has been successfully changed.
              </p>
              <Link to="/login">Click here to Login.</Link>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default SuccessMessage;
