import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ErrorMessage, Field, Formik } from 'formik';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  FormGroup,
} from 'reactstrap';
//
import FormikDatePicker from 'components/Fields/FormikDatePicker';
import { dateTimeFormatter } from 'utils/helper';
import { SKILLS } from 'constants/appConstants';

const editorInviteSchema = Yup.object().shape({
  customStartDate: Yup.string().when('availability', {
    is: (availability) => availability === 'custom',
    then: Yup.string().required('Start date is required').nullable(),
  }),
  customEndDate: Yup.string().when('availability', {
    is: (availability) => availability === 'custom',
    then: Yup.string().required('End date is required').nullable(),
  }),
  skillSet: Yup.array().min(1).required('Select at least one skill'),
  // clientName: Yup.string().when('previousWorkedEditors', {
  //   is: (previousWorkedEditors) => !!previousWorkedEditors,
  //   then: Yup.string().required('Client/Company Name is required'),
  // }),
});

const EditorInviteModal = ({
  inviteEditorModal,
  toggleEditorInviteModal,
  projectName,
  projectId,
  dataSource,
  searchFilter,
}) => {
  const history = useHistory();
  let editorInviteInitialValues = {};

  const SkillSetForm = () => (
    <Row>
      {SKILLS.map((skill) => (
        <Col xs={6} key={skill.id}>
          <FormGroup>
            <Field
              id={skill.id}
              name="skillSet"
              value={skill.label}
              label={skill.label}
              type="checkbox"
              className="mr-2 align-middle"
            />
            <Label htmlFor={skill.id}>{skill.label}</Label>
          </FormGroup>
        </Col>
      ))}
      <ErrorMessage
        name="skillSet"
        render={() => (
          <div className="text-danger font-size-12">
            Select at least one skill
          </div>
        )}
      />
    </Row>
  );

  const handleSubmit = async (values) => {
    const { skillSet, customEndDate, customStartDate, availability } = values;
    let availabilityDates = {};

    if (availability === 'Immediate') {
      availabilityDates = {
        startDate: moment().format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
      };
    } else if (availability === 'nextThreeDays') {
      availabilityDates = {
        startDate: moment().format('MM/DD/YYYY'),
        endDate: moment().add(3, 'days').format('MM/DD/YYYY'),
      };
    } else {
      availabilityDates = {
        startDate: dateTimeFormatter(customStartDate),
        endDate: dateTimeFormatter(customEndDate),
      };
    }

    const searchFilter = {
      skills: skillSet,
      flag: availability,
      availability: { ...availabilityDates },
    };
    toggleEditorInviteModal(false);
    history.push({
      pathname: `/pm-portal/projects/${projectId}/folders/editor-invite`,
      state: {
        dataSource,
        projectId,
        projectName,
        searchFilters: searchFilter,
        editorFilter: values,
      },
    });
  };

  if (searchFilter) {
    editorInviteInitialValues = { ...searchFilter };
  } else {
    editorInviteInitialValues = {
      availability: 'Immediate',
      customStartDate: '',
      customEndDate: '',
      skillSet: [],
    };
  }

  return (
    <>
      <Modal
        isOpen={inviteEditorModal}
        toggle={() => toggleEditorInviteModal(false)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader>Editor Search</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={editorInviteInitialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={editorInviteSchema}
            children={({ handleSubmit, values }) => {
              return (
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardBody className="bg-about-this-deal">
                          <CardTitle tag="h6">Availability</CardTitle>
                          <FormGroup>
                            <Field
                              type="radio"
                              name="availability"
                              value="Immediate"
                              className="mr-2 align-middle"
                            />
                            Immediate
                          </FormGroup>
                          <FormGroup>
                            <Field
                              type="radio"
                              name="availability"
                              value="nextThreeDays"
                              className="mr-2 align-middle"
                            />
                            Next 3 days
                          </FormGroup>
                          <FormGroup>
                            <Field
                              type="radio"
                              name="availability"
                              value="custom"
                              className="mr-2 align-middle"
                            />
                            Custom
                          </FormGroup>

                          {values.availability === 'custom' && (
                            <>
                              <Field
                                id="customStartDate"
                                name="customStartDate"
                                component={FormikDatePicker}
                                placeholder="Start Date"
                                className="font-size-13 form-control"
                                maxDate={values.customEndDate}
                                minDate={new Date()}
                              />
                              <Field
                                id="customEndDate"
                                name="customEndDate"
                                component={FormikDatePicker}
                                placeholder="End Date"
                                className="font-size-13 form-control"
                                minDate={values.customStartDate || new Date()}
                              />
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <p className="border-bottom pb-3">
                        Please select the skills as per project requirements
                      </p>

                      {SkillSetForm()}
                      {/* <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <Field
                              id="videoCreation"
                              name="skillSet"
                              value="Video Creation"
                              label="Video Creation"
                              type="checkbox"
                              className="mr-2 align-middle"
                            />
                            <Label>Video Creation</Label>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              id="DrawingPhotoshopTools"
                              name="skillSet"
                              value="Drawing/Photoshop Tools"
                              label="Drawing/Photoshop Tools"
                              type="checkbox"
                              className="mr-2 align-middle"
                            />
                            <Label>Drawing/Photoshop Tools</Label>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              id="MotionGraphics"
                              name="skillSet"
                              value="Motion Graphics"
                              label="Motion Graphics"
                              type="checkbox"
                              className="mr-2 align-middle"
                            />
                            <Label>Motion Graphics</Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Field
                              id="Animation"
                              name="skillSet"
                              value="Animation"
                              label="Animation"
                              type="checkbox"
                              className="mr-2 align-middle"
                            />
                            <Label>Animation</Label>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              id="3DModelBuilding"
                              name="skillSet"
                              value="3D Model Building"
                              label="3D Model Building"
                              type="checkbox"
                              className="mr-2 align-middle"
                            />
                            <Label>3D Model Building</Label>
                          </FormGroup>
                        </Col>
                        <ErrorMessage
                          name="skillSet"
                          render={() => (
                            <div className="text-danger font-size-12">
                              Select at least one skill
                            </div>
                          )}
                        />
                      </Row> */}
                      <div className="text-right pt-4">
                        <Button
                          outline
                          color="primary"
                          className=" btn-sm px-2 font-size-13"
                          onClick={() => toggleEditorInviteModal(false)}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          color="primary"
                          className="btn-sm px-3 font-size-13 ml-2"
                        >
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditorInviteModal;
