import React, { useContext, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Form, Field, FieldArray } from 'formik';
import { InputGroup, Row, Col, Button } from 'reactstrap';

import { assetDetailContext } from 'contexts/AssetDetailContext';
import FormikInputField from 'components/Fields/FormikInputField';
import DetailViewNextButton from 'pages/Search/common/DetailViewNextButton';

const CityAndCalloutForm = ({
  values,
  refetch,
  fieldKey,
  fieldName,
  getAssetDetails,
  distanceFromAsset,
}) => {
  const { handleEditAsset } = useContext(assetDetailContext);

  const handleAddRow = (arrayHelpers) => {
    if (distanceFromAsset) {
      arrayHelpers.push({ name: '', distanceFromAsset });
    } else {
      arrayHelpers.push({ name: '' });
    }
  };

  useEffect(() => {
    if (!isEqual(getAssetDetails[fieldKey], values[fieldKey])) {
      const assetData = { ...getAssetDetails };
      assetData[fieldKey] = values[fieldKey];
      handleEditAsset(true, assetData);
    }
  }, [values]);

  return (
    <div>
      <Form>
        <FieldArray
          name={fieldName}
          render={(arrayHelpers) => (
            <div>
              {values[fieldName] &&
                values[fieldName].length > 0 &&
                values[fieldName].map((row, index) => {
                  if (row.delete) return null;
                  if (fieldName === 'nearbyCities') {
                    return (
                      <Row key={`${fieldName}_${index + 1}`}>
                        <Col sm="12" xl="4">
                          <InputGroup size="medium">
                            <Field
                              name={`${fieldName}.${index}.name`}
                              component={FormikInputField}
                              placeholder="Enter city here"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    );
                  }
                  if (row.distanceFromAsset === distanceFromAsset) {
                    return (
                      <Row key={`${fieldName}_${index + 1}`}>
                        <Col sm="12" xl="4">
                          <InputGroup size="medium">
                            <Field
                              name={`${fieldName}.${index}.name`}
                              component={FormikInputField}
                              placeholder="Enter callout here"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    );
                  }
                  return null;
                })}
              <Row>
                <Col sm="12">
                  <Button
                    className="add-more-btn mr-3"
                    color="link"
                    onClick={() => handleAddRow(arrayHelpers)}
                  >
                    + Add More
                  </Button>
                  <DetailViewNextButton
                    values={values}
                    fieldKey={fieldKey}
                    isValid
                    getAssetDetails={getAssetDetails}
                    refetch={refetch}
                  />
                </Col>
              </Row>
            </div>
          )}
        />
      </Form>
    </div>
  );
};

export default CityAndCalloutForm;
