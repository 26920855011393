import React from 'react';
import { Card, CardBody, Spinner } from 'reactstrap';

const NoData = ({ customMessage, animation }) => {
  return (
    <Card className="mt-5 mt-lg-2">
      <CardBody className="d-flex align-items-center">
        {animation && (
          <div className="my-3 mr-3">
            <Spinner animation="grow" variant="primary" />
          </div>
        )}
        <strong>{customMessage || 'No data found!'}</strong>
      </CardBody>
    </Card>
  );
};

export default NoData;
