import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { faCheckCircle, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRowClass } from 'pages/BrokersDirectory/helper';
import { FLAG_TYPES, PRIORITIES } from 'pages/BrokersDirectory/constant';

const REGEX_PHONE = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_DASH_REGEX = /\d{3}(?=\d{2,3})|\d+/g;

const BrokerItem = ({ brokerInfo, toggleConfirmationModal }) => {
  const { EMAIL_NOT_FOUND } = FLAG_TYPES;
  const { PRIORITY1, PRIORITY2 } = PRIORITIES;

  const [brokerData, setBrokerData] = useState(brokerInfo);
  const [buttonDisable, setButtonDisable] = useState(false);

  const {
    firstName,
    lastName,
    company,
    phone,
    email,
    priority,
    note,
    flagType,
    id,
  } = brokerData;

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    const brokerObj = { ...brokerData };

    if (fieldName === 'phone') {
      if (!value.match(PHONE_DASH_REGEX)) {
        setBrokerData({ ...brokerObj, phone: [] });
        return true;
      }
      if (value) {
        let temp = value.match(PHONE_DASH_REGEX);
        temp = temp.join('-');
        brokerObj[fieldName] = [temp];
      } else {
        brokerObj[fieldName] = [value];
      }
    } else {
      brokerObj[fieldName] = value;
    }

    setBrokerData(brokerObj);
    return true;
  };

  const handleDisable = () => {
    let isValidated = true;
    let isValidatedEmail = true;
    let isValidatedPhone = true;
    const { email, phone } = brokerData;
    if (email && email.match(REGEX_EMAIL)) {
      isValidatedEmail = true;
    } else {
      isValidatedEmail = false;
    }
    if (phone && typeof phone === 'string') {
      if (phone && phone.length > 0 && phone.match(REGEX_PHONE)) {
        isValidatedPhone = true;
      } else {
        isValidatedPhone = false;
      }
    } else if (phone && typeof phone === 'object') {
      if (phone && phone.length > 0 && phone[0].match(REGEX_PHONE)) {
        isValidatedPhone = true;
      } else {
        isValidatedPhone = false;
      }
    } else {
      isValidatedPhone = false;
    }

    if (isValidatedEmail && isValidatedPhone) {
      isValidated = false;
    } else {
      isValidated = true;
    }
    return isValidated;
  };

  const checkValidClass = ({ email, phone }, fieldName) => {
    if (email || phone) {
      if (email && fieldName === 'email') {
        if (email.match(REGEX_EMAIL)) {
          return true;
        }
        return false;
      }

      if (phone && phone.length > 0 && fieldName === 'phone') {
        if (phone[0].match(REGEX_PHONE)) {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
    return true;
  };

  const renderURL = (val) => {
    if (!val) return 'Not specified';
    return (
      <a
        href={`https://www.google.com/search?q=${firstName}+${lastName}+${company}`}
        target="_blank"
        rel="noreferrer"
        title={val}
        className="w-75 text-truncate d-inline-block mr-2"
      >
        {val}
      </a>
    );
  };

  const renderPhoneField = () => {
    const html = (
      <input
        id={`phone-${id}`}
        name="phone"
        className={`form-control w-75 d-inline-flex mr-2 ${
          checkValidClass(brokerData, 'phone') ? '' : 'border-danger'
        } `}
        value={phone || ''}
        onChange={(e) => {
          handleInputChange(e, 'phone');
        }}
      />
    );

    if (
      !brokerInfo.phone ||
      !brokerInfo.phone.length ||
      !brokerInfo.phone.match(REGEX_PHONE)
    ) {
      return html;
    }
    return (
      <a
        title={phone}
        target="_blank"
        rel="noreferrer"
        className="w-75 text-truncate d-inline-block mr-2"
        href={`https://www.google.com/search?q=${firstName}+${lastName}+${company}`}
      >
        {phone}
      </a>
    );
  };

  const getPriorityColor = () => {
    if (priority === PRIORITY1) return '#E94853';
    if (priority === PRIORITY2) return '#EA8918';
    return '#F4BE09';
  };
  const getEmailValue = () => {
    if (flagType === EMAIL_NOT_FOUND) return EMAIL_NOT_FOUND;
    return email;
  };

  const renderNote = () => {
    return note ? (
      <tr className={`note-tr ${getRowClass(priority, flagType)}`}>
        <td colSpan="5">
          <p>Notes: {note}</p>
        </td>
      </tr>
    ) : null;
  };

  return (
    <>
      <tr
        className={`update-missing-record-row ${getRowClass(
          priority,
          flagType
        )}`}
      >
        <td>{renderURL(firstName)}</td>
        <td>{renderURL(lastName)}</td>
        <td>{renderURL(company)}</td>
        <td>{renderPhoneField()}</td>
        <td className="d-flex align-items-center">
          <input
            id={`email-${id}`}
            name="email"
            className={`form-control w-70 d-inline-flex mr-2 ${
              checkValidClass(brokerData, 'email') ? '' : 'border-danger'
            }
            
            ${
              flagType === EMAIL_NOT_FOUND
                ? 'email-not-found border-danger'
                : ''
            }
            `}
            value={email || ''}
            placeholder={getEmailValue()}
            onChange={(e) => {
              handleInputChange(e, 'email');
            }}
          />
          <Button
            onClick={async () => {
              await setButtonDisable(true);
              toggleConfirmationModal(true, brokerData);
            }}
            className="check-btn"
            disabled={handleDisable(brokerData) || buttonDisable}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          </Button>
          <FontAwesomeIcon icon={faFlag} color={getPriorityColor()} />
        </td>
      </tr>
      {renderNote()}
    </>
  );
};
export default BrokerItem;
