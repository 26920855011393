import { gql } from '@apollo/client';

export const GET_BRONZE_VIDEO_SEGMENT_DETAILS = gql`
  query getBronzeVideoSegmentDetails(
    $segmentNumber: Int!
    $assetId: Int
    $projectId: Int
    $isHubspotProject: Boolean
  ) {
    getBronzeVideoSegmentDetails(
      segmentNumber: $segmentNumber
      assetId: $assetId
      projectId: $projectId
      isHubspotProject: $isHubspotProject
    )
  }
`;

export const DRAFT_BRONZE_SEGMENTS = gql`
  mutation draftBronzeSegments(
    $segmentNumber: Int!
    $assetId: Int
    $formData: JSON!
    $projectId: Int
    $eliteAuction: String!
    $projectUuid: String!
    $isTextChange: Boolean
  ) {
    draftBronzeSegments(
      segmentNumber: $segmentNumber
      assetId: $assetId
      formData: $formData
      projectId: $projectId
      eliteAuction: $eliteAuction
      projectUuid: $projectUuid
      isTextChange: $isTextChange
    )
  }
`;

export const DELETE_CUSTOM_IMAGE_S3_URL = gql`
  mutation deleteCustomImageS3Url(
    $s3ImageLinkArray: [String]!
    $fileType: fileType
    $projectUuid: String
    $userUuid: UUID
  ) {
    deleteCustomImageS3Url(
      s3ImageLinkArray: $s3ImageLinkArray
      fileType: $fileType
      projectUuid: $projectUuid
      userUuid: $userUuid
    )
  }
`;

export const BRONZE_VIDEO_RENDER = gql`
  mutation bronzeVideoRender($projectId: Int!) {
    bronzeVideoRender(projectId: $projectId)
  }
`;
