import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import FormikInputField from 'components/Fields/FormikInputField';
import { useMutation } from '@apollo/client';
import { ADD_NOTES } from 'pages/Pilot/PilotPortalGraphQL';
import { authContext } from 'contexts/AuthContext';

const messagingSchema = Yup.object({
  noteText: Yup.string()
    .required('Required')
    .test(
      'remove-white-spaces',
      'White space not allowed as a character',
      (noteText) => {
        if (noteText) return noteText.trim().length > 0;
        return false;
      }
    ),
});

const AddNotes = ({ projectPilotId, projectId, refetch, inputTextType }) => {
  const {
    auth: {
      data: { role, userId },
    },
  } = useContext(authContext);
  const [addNotes] = useMutation(ADD_NOTES, {
    onCompleted: async () => {
      if (refetch) {
        refetch();
      }
      toast.success('Note added successfully.');
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSubmit = async ({ noteText }, { resetForm }) => {
    try {
      resetForm({});
      addNotes({
        variables: {
          notesFrom: parseInt(userId, 10),
          folderId: 0,
          notesTo: parseInt(projectPilotId, 10),
          notesUserRole: role,
          noteText: noteText.trim(),
          defaultFolderFlag: true,
          projectId: parseInt(projectId, 10),
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={{ noteText: '' }}
      validationSchema={messagingSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="d-flex w-100">
          <Field
            id="noteText"
            type={inputTextType ?? 'text'}
            name="noteText"
            placeholder="Type your message here.."
            component={FormikInputField}
            className="form-control form-control-sm mb-0"
          />
          <div className="ml-2 mt-0">
            <div className="text-right">
              <Button
                color="primary"
                type="submit"
                className="btn btn-sm font-size-13 px-4"
              >
                Send
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNotes;
