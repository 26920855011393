import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
//
import NoData from 'common/NoData';
import { ROLES } from 'constants/role';
import { parseToInteger } from 'utils/helper';
import { bronzeContext } from 'pages/CrexiBronze/BronzeContext';
import ChapterRibbon from 'pages/CrexiBronze/common/ChapterRibbon';
import { GET_BRONZE_VIDEO_SEGMENT_DETAILS } from 'pages/CrexiBronze/CrexiBronzeGraphQL';
import ChapterForms from './components/ChapterForms';

const StepFormWrapper = ({ userRole }) => {
  const { id } = useParams();
  const location = useLocation();
  const { dispatchBronzeData } = useContext(bronzeContext);

  const [chapterValues, setChapterValues] = useState([]);
  const { currentChapter, isVideoTextChange } = useSelector(
    (state) => state.crexiBronze
  );

  const [
    getBronzeVideoSegmentDetails,
    { loading, error, data, refetch: refetchBronzeData },
  ] = useLazyQuery(GET_BRONZE_VIDEO_SEGMENT_DETAILS);

  useEffect(() => {
    getBronzeVideoSegmentDetails({
      variables: {
        segmentNumber: currentChapter,
        assetId: userRole !== ROLES.VIDEOMS_EDITOR ? parseToInteger(id) : null,
        projectId:
          userRole === ROLES.VIDEOMS_EDITOR || isVideoTextChange
            ? parseToInteger(id)
            : null,
        isHubspotProject:
          location?.state?.dataSource === 'HUBSPOT' ? true : false,
      },
      fetchPolicy: 'network-only',
    });
  }, [currentChapter, isVideoTextChange]);

  useEffect(() => {
    if (!loading && data?.getBronzeVideoSegmentDetails) {
      setChapterValues(data.getBronzeVideoSegmentDetails);
      dispatchBronzeData({
        type: 'PROJECT_UUID',
        projectUuid: data.getBronzeVideoSegmentDetails?.projectId,
      });
    }
  }, [data, loading]);

  if (error)
    return <NoData customMessage="No chapters exist for provided asset." />;

  return (
    <>
      <ChapterRibbon currentChapter={currentChapter} />
      <ChapterForms
        formDataValues={chapterValues?.segmentDetails}
        projectUUID={chapterValues?.projectId}
        isLoading={loading}
        refetchBronzeData={refetchBronzeData}
        userRole={userRole}
      />
    </>
  );
};

export default memo(StepFormWrapper);
