import React from 'react';
import { Table } from 'reactstrap';
import asanaIMG from 'assets/asana.png';
import moviePlatinum from 'assets/movie.svg';
import hubspotIcon from 'assets/hubspot-icon.png';
import Header from 'pages/PMPortal/common/Header';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { PLAN_TYPE_ICONS, convertDateWithTimezone, dateTimeFormatter } from 'utils/helper';
import { BRONZE, NOT_SPECIFIED } from 'constants/appConstants';
import { Link } from 'react-router-dom';
import { renderCountDownTimer } from 'pages/PMPortal/constant';

const ProjectDetailHeader = ({
  projectId,
  projectName,
  projectAsanaLink,
  startDate,
  videoStatus,
  timeElapsed,
  hubspotLink,
  deliveryDate,
  timeTillDue,
  planType,
  createdAt,
}) => {
  const fullProjectName = projectName.replace(/,/g, ', ');

  function secondsToDhms(second) {
    const sec = moment.duration(second).asSeconds();
    const seconds = Number(sec);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);

    const dDisplay =
      d > 0 ? (
        <span key="day" className="elasped-time-duration-span">
          {d} {d === 1 ? ' day ' : ' days '}
        </span>
      ) : (
        ''
      );
    const hDisplay =
      h > 0 ? (
        <span key="hours" className="elasped-time-duration-span">
          {h} {h === 1 ? ' hour ' : ' hours '}
        </span>
      ) : (
        ''
      );
    const mDisplay =
      m > 0 ? (
        <span key="minutes" className="elasped-time-duration-span">
          {m} {m === 1 ? ' minute ' : ' minutes '}
        </span>
      ) : (
        ''
      );
    return [dDisplay, hDisplay, mDisplay];
  }

  return (
    <>
      <Link to={`/pm-portal/projects/${projectId}/folders`}>
        <Header heading={fullProjectName} />
      </Link>
      <div className="table-1">
        <div className="video-review-head px-2 py-1 border-bottom">
          <Table borderless className="mb-0">
            <tbody>
              <tr>
                <td className="font-size-14 font-weight-bold align-middle">
                  <img
                    className="mr-2"
                    src={
                      planType ? PLAN_TYPE_ICONS[planType].icon : moviePlatinum
                    }
                    alt="planType"
                  />
                  <Link
                    className="color-inherit text-decoration-none"
                    to={`/pm-portal/projects/${projectId}/folders`}
                  >
                    {fullProjectName}
                  </Link>
                </td>
                {planType !== BRONZE && (
                  <td className="pt-2">
                    <a href={projectAsanaLink} rel="noreferrer" target="_blank">
                      <img src={asanaIMG} alt={asanaIMG} className="mr-2" />
                      View project in Asana
                    </a>
                    <FontAwesomeIcon
                      icon={faExternalLinkSquareAlt}
                      color="#565082"
                      className="ml-2 align-middle"
                    />
                  </td>
                )}
                <td className="text-right pr-4">
                  {hubspotLink && planType !== BRONZE ? (
                    <a
                      href={hubspotLink}
                      rel="noreferrer"
                      target="_blank"
                      className="mb-0"
                    >
                      <img
                        src={hubspotIcon}
                        alt={hubspotIcon}
                        className="mr-2"
                      />{' '}
                      Hubspot link
                    </a>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="table-2">
        <div className="video-review-head px-2 py-2">
          <Table borderless className="mb-0 pt-3">
            <tbody>
              <tr>
                <td className="font-size-13 align-middle">
                  Start Date :{' '}
                  <strong> {dateTimeFormatter(startDate) || 'N/A'}</strong>
                </td>
                <td className="font-size-13 align-middle">
                  Elapsed time : <strong>{secondsToDhms(timeElapsed)}</strong>
                </td>
                <td className="font-size-13 align-middle">
                  Delivery Date :{' '}
                  <strong>
                    {' '}
                    {deliveryDate !== NOT_SPECIFIED ? convertDateWithTimezone(deliveryDate) : 'N/A'}
                  </strong>
                </td>
                <td className="font-size-13 align-middle">
                  Time till due :{' '}
                  <strong>
                    {' '}
                    {planType === BRONZE
                      ? renderCountDownTimer(createdAt)
                      : 'N/A'}
                  </strong>
                </td>
                <td className="font-size-13 align-middle">
                  Video Status:{' '}
                  <span className="active-td border border-radius-8px badge badge-secondary">
                    {videoStatus || 'N/A'}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default ProjectDetailHeader;
