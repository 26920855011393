import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCirclePlay,
} from '@fortawesome/free-regular-svg-icons';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
//
import NoData from 'common/NoData';
import { ROLES } from 'constants/role';
import Paginator from 'common/Paginator';
// import VideoPlayer from 'common/VideoPlayer';
import { convertDateWithUTC } from 'pages/Vidpitch/constant';
import { authContext } from 'contexts/AuthContext';
import { assetListInfo } from 'pages/Vidpitch/VidPitchActions';
import ModifyVideo from 'pages/Vidpitch/components/VidpitchVideo/components/VidPitchVideoDetails/components/ModifyVideo';

const AssetTable = ({ filters, pageCount, totalCount, videosData }) => {
  const limit = 10;
  const history = useHistory();
  const dispatch = useDispatch();

  const [assetModelData, setAssetModelData] = useState();
  const [isAssetModelOpen, setAssetModel] = useState(false);

  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const goToTop = async (filters) => {
    const { page, offset, startDate, endDate, globalSearchText } = filters;
    await dispatch(
      assetListInfo({
        assetListInfo: {
          startDate,
          endDate,
          globalSearchText,
          previousPageInfo: {
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const rowEvents = async (row) => {
    const { video_data_status: pipelineStatus } = row;
    if (pipelineStatus === 'inprogress') {
      toast.error(
        'Video rendering is under-progress, Once completed you will be notified.'
      );
    } else {
      history.push(`/${row.uuid}/video-draft`);
    }
  };

  const getUrlExtension = (url) => {
    if (url === null) return null;
    if (url) return typeof url !== 'object' && url.includes('gif');
    return false;
  };

  const renderTitle = (pipelineStatus) => {
    return pipelineStatus === 'inprogress'
      ? 'Asset GIF is under processing'
      : 'View Asset GIF';
  };

  const openAssetModel = (cell) => {
    setAssetModel(!isAssetModelOpen);
    setAssetModelData(cell);
  };

  const columns = [
    {
      dataField: 'assetname',
      text: 'PROPERTY',
      sort: true,
      headerStyle: () => ({ width: '10%' }),
      formatter: (cell, row) => {
        return (
          <div title={cell} className="d-flex justify-content-between">
            <div className="v-trancate" style={{ width: '200px' }}>
              {cell}
            </div>
            <Button className="text-center py-0" color="link">
              <FontAwesomeIcon
                icon={faCirclePlay}
                className="font-size-18"
                title="Play Video"
              />
            </Button>
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row) => {
          rowEvents(row);
        },
      },
    },
    {
      dataField: 'brokername',
      text: 'BROKER',
      sort: true,
      headerStyle: () => ({ width: '10%' }),
      formatter: (cell, row) => {
        return (
          <div className="v-trancate" style={{ width: '150px' }} title={cell}>
            {cell}
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row) => {
          rowEvents(row);
        },
      },
    },
    {
      dataField: 'assetaddress',
      text: 'ADDRESS',
      sort: true,
      headerStyle: () => ({ width: '25%' }),
      formatter: (cell, row) => {
        return (
          <div className="v-trancate" title={cell} style={{ width: '300px' }}>
            {cell}
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row) => {
          rowEvents(row);
        },
      },
    },
    {
      dataField: 's3_link',
      text: 'URL',
      headerStyle: () => ({ width: '5%' }),
      formatter: (cell, row) => {
        const { video_data_status: pipelineStatus } = row;
        return (
          <span title={renderTitle(pipelineStatus)}>
            <Button
              color="link"
              disabled={pipelineStatus === 'inprogress'}
              className="font-size-13 py-0 pl-0"
              onClick={() => openAssetModel(row.s3_link_gif)}
            >
              <b>GIF</b>
            </Button>
          </span>
        );
      },
    },
    {
      dataField: 'status',
      text: 'STATUS',
      headerStyle: () => ({ width: '10%' }),
      formatter: (cell, row) => {
        return (
          <span
            className="font-size-14"
            style={{
              background: cell === 'Email Sent' ? '#DCE9F2' : '#D4EDC9',
              padding: '2px 10px',
              borderRadius: '20px',
            }}
          >
            {cell}
          </span>
        );
      },
      sort: true,
    },
    {
      dataField: 'created_at',
      text: 'MODIFIED DATE',
      sort: true,
      headerStyle: () => ({ width: '10%' }),
      formatter: (cell, row) => {
        return <span>{convertDateWithUTC(cell)}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'ACTION',
      hidden: role === ROLES.BROKER,
      headerStyle: () => ({ width: '6%' }),
      formatter: (cell, row) => {
        const {
          uuid,
          asset_crexi_id: assetCrexiId,
          video_data_status: pipelineStatus,
          video_id: videoId,
          status,
        } = row;
        return status === 'Email Sent' ? (
          <ModifyVideo
            uuid={uuid}
            assetTable
            videoId={videoId}
            assetCrexiId={assetCrexiId}
            pipelineStatus={pipelineStatus}
          />
        ) : (
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="font-size-18"
              title="Video Purchased"
            />
          </div>
        );
      },
    },
  ];

  const renderAsset = (assetModelData) => {
    if (getUrlExtension(assetModelData)) {
      return (
        <img
          src={assetModelData}
          alt={assetModelData}
          className="p-4"
          style={{ width: '90%' }}
        />
      );
    }
    return <NoData />;
  };

  return (
    <div>
      <div className="vidtech container-fluid">
        <BootstrapTable
          keyField="uuid"
          columns={columns}
          data={videosData}
          classes="vidtech-table"
        />
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={videosData.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
      <Modal
        isOpen={isAssetModelOpen}
        toggle={openAssetModel}
        centered
        size="xl"
      >
        <ModalHeader toggle={openAssetModel}>Asset GIF</ModalHeader>
        <ModalBody className="text-center p-0">
          {renderAsset(assetModelData)}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AssetTable;
