import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PortalDropdown from 'common/PortalDropdown';
import HeaderUserDropdown from 'common/HeaderUserDropdown';
import { PROJECT_DETAIL_PAGE_HEADINGS } from 'constants/appConstants';
import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';
import AttentionIcon from './components/AttentionIcon';

const Header = ({
  heading,
  showBackButton,
  salesPerson,
  toggleProjectDetailModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { activeProjectInfo, needMyAttentionInfo, completedProjectInfo } =
    useSelector((state) => state.managementPortal);

  const { ACTIVE, NEED_MY_ATTENTION, COMPLETED } = PROJECT_DETAIL_PAGE_HEADINGS;

  const handleOpenAttentionDrawer = () => {
    dispatch(
      toggleAttentionDrawer({
        isOpen: true,
        heading: 'Need Attention',
        drawerName: 'ATTENTION_DRAWER',
      })
    );
  };

  const goBackToList = () => {
    if (salesPerson) {
      toggleProjectDetailModal();
    } else {
      let previousPath = null;
      if (heading === ACTIVE.label) {
        const {
          previousPageInfo: {
            previousRoutes: { path },
          },
        } = activeProjectInfo;
        previousPath = path;
      }
      if (heading === NEED_MY_ATTENTION.label) {
        const {
          previousPageInfo: {
            previousRoutes: { path },
          },
        } = needMyAttentionInfo;
        previousPath = path;
      }
      if (heading === COMPLETED.label) {
        const {
          previousPageInfo: {
            previousRoutes: { path },
          },
        } = completedProjectInfo;
        previousPath = path;
      }
      dispatch(
        toggleAttentionDrawer({
          isOpen: false,
          heading: '',
          drawerName: '',
        })
      );
      history.push(previousPath);
    }
  };

  return (
    <>
      <Row className="content-header-row border-bottom py-2 align-items-center mx-n4">
        <Col sm="8" className='d-flex align-items-center'>
          {showBackButton ? (
            <Button
              color="link"
              className="back-btn color-inherit px-0"
              onClick={goBackToList}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
              {heading}
            </Button>
          ) : (
            <h6 className="px-2 mb-0 show-elipsis w-100">{heading}</h6>
          )}
        </Col>
        {!salesPerson && (
          <Col sm="auto" className="ml-auto position-relative">
            <div className="d-flex align-items-center">
              <AttentionIcon
                handleOpenAttentionDrawer={handleOpenAttentionDrawer}
              />
              <PortalDropdown />
              <HeaderUserDropdown />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
export default Header;
