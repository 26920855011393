import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PageLoader from 'common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Scrollbars from 'react-custom-scrollbars-2';
//
import { LIST_DEALS } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import { dealsDetailsInfo } from 'pages/SalesPersonPortal/SalesPersonPortalActions';
import NoData from 'common/NoData';
import { dateTimeFormatter } from 'utils/helper';
import SearchDeal from 'pages/SalesPersonPortal/common/SearchDeal';
import DealTableRenderer from './components/DealTableRenderer';
import DealCount from './components/DealCount';

const GetDealsInfo = ({ globalSearchText, filters, page, offset }) => {
  const limit = 10;

  const { loading, data, error, refetch } = useQuery(LIST_DEALS, {
    variables: {
      input: { limit, offset, filter: filters, globalSearchText },
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  if (data) {
    const {
      deals: { dealData, totalCount },
    } = data;

    if (!dealData.length) return <NoData />;
    const pageCount = Math.ceil(totalCount / limit);

    return (
      <DealTableRenderer
        refetch={refetch}
        filters={{
          ...filters,
          globalSearchText,
          page,
          offset,
        }}
        limit={limit}
        pageCount={pageCount}
        totalCount={totalCount}
        dealsData={dealData}
      />
    );
  }
  return <NoData customMessage="No deals found" />;
};

const DealsWrapper = () => {
  const dispatch = useDispatch();
  const {
    dealsDetailsInfo: {
      filters,
      globalSearchText,
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.salesPersonPortal);

  const [searchParams, setSearchParams] = useState('');
  const [searchString, setSearchString] = useState('');

  const handleSearchParams = (value) => {
    setSearchParams(value);
    setSearchString('');
    dispatch(
      dealsDetailsInfo({
        dealsDetailsInfo: {
          filters: {
            dealName: '',
            status: '',
            startDate: '',
          },
          globalSearchText: '',
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const handleSearchString = (value) => {
    setSearchString(value);
    let filtersParam = { ...filters };
    let searchText = '';
    if (searchParams === 'startDate') {
      filtersParam = {
        ...filters,
        [searchParams]: dateTimeFormatter(value),
      };
    } else if (searchParams && searchParams !== 'startDate') {
      filtersParam = {
        ...filters,
        [searchParams]: value,
      };
    } else {
      filtersParam = null;
      searchText = value;
    }

    dispatch(
      dealsDetailsInfo({
        dealsDetailsInfo: {
          filters: filtersParam,
          globalSearchText: searchText,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <div>
      <Scrollbars
        autoHide
        className="row"
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{
          width: '100%',
          height: `calc(100vh - 60px)`,
          margin: '0px',
        }}
      >
        <Container fluid className="broker-top-space bg-grey-upload sales-full">
          <div>
            <Row className="align-items-end">
              <Col lg={8} className="ml-auto mt-3">
                <div>
                  <h5 className="m-0">
                    Deals <DealCount />
                  </h5>
                </div>
              </Col>
              <Col lg={4} className="ml-auto mt-3">
                <div>
                  <SearchDeal
                    searchParams={searchParams}
                    searchString={searchString}
                    handleSearchParams={handleSearchParams}
                    handleSearchString={handleSearchString}
                  />
                </div>
              </Col>
            </Row>
            <GetDealsInfo
              filters={filters}
              globalSearchText={globalSearchText}
              page={page}
              offset={offset}
            />
          </div>
        </Container>
      </Scrollbars>
    </div>
  );
};

export default DealsWrapper;
