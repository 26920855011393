import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import FileUploader from 'common/FileUploader';

const UploadAvatar = ({
  upload,
  uploadLocation,
  forPilotPortal,
  forPilotRegistration,
  setAvatar,
}) => {
  const [uploadFileModal, setUploadFileModal] = useState(false);

  const toggleModal = () => setUploadFileModal(!uploadFileModal);
  return (
    <>
      <Button
        color="primary"
        onClick={toggleModal}
        className="btn-sm px-4 mr-0 mt-2"
      >
        {upload}
      </Button>
      <Modal
        isOpen={uploadFileModal}
        toggle={toggleModal}
        className="modal-lg modal-dialog-centered"
      >
        <ModalBody>
          <div className="">
            <FileUploader
              uploadLocation={uploadLocation}
              forPilotPortal={forPilotPortal}
              forPilotRegistration={forPilotRegistration}
              setAvatar={setAvatar}
              toggleModal={toggleModal}
              versionSuffix={0}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadAvatar;
