import { ROLES } from 'constants/role';

export const BRONZE_CHAPTER_VIDEO_TIMESTAMP = [
  { chapter: 6, timeStamp: 35 },
  { chapter: 5, timeStamp: 29 },
  { chapter: 4, timeStamp: 24 },
  { chapter: 3, timeStamp: 18 },
  { chapter: 2, timeStamp: 12 },
  { chapter: 1, timeStamp: 0 },
];
export const BRONZE_TEXT_COMMENT_ERROR_MESSAGE =
  'We are currently unable to process your request for change text.';
export const BRONZE_REVIEW_COMPLETE_ROLES = {
  'qc/pm comment review': [ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC, ROLES.ADMIN],
  'user comment review': [ROLES.USER],
};
export const UNALLOWED_ROLES_FOR_VIDEO_REVIEW_COMPLETE = [
  ROLES.OPERATOR,
  ROLES.VIDEOMS_EDITOR,
  ROLES.VIDEOMS_PILOT,
  ROLES.VIDEOMS_SALES_PERSON,
  ROLES.VIDEOMS_PM
];
export const SECTION_TASKS = {
  userCommentReview: 'user comment review',
};
