import React from 'react';
import { ROLES } from 'constants/role';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
//
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Error401 from 'pages/Error401';
import DrawerRight from 'components/DrawerRight';
import ProtectedRoute from 'components/ProtectedRoute';
import VidpitchSignup from 'pages/Vidpitch/components/Signup';
import EmailSent from 'pages/ResetPassword/components/EmailSent';
import VidpitchVideo from 'pages/Vidpitch/components/VidpitchVideo';
import VidPitchPurchase from 'pages/Vidpitch/components/VidpitchPurchase';
import SuccessMessage from 'pages/ResetPassword/components/SuccessMessage';
import ChangePassword from 'pages/ResetPassword/components/ChangePassword';
import RequestChangePassword from 'pages/ResetPassword/components/RequestChangePassword';
import PaymentSuccess from 'pages/Vidpitch/components/VidpitchPurchase/components/VideoForm/components/PaymentSuccess';
import MultiStepModifyVideo from 'pages/Vidpitch/components/VidpitchVideo/components/VidPitchVideoDetails/components/MultiStepModifyVideo';
import AssetsListing from 'pages/Vidpitch/components/AssetsListing';
import VidpitchVideoDownload from 'pages/Vidpitch/components/VideoDownload';

const AppVidPitchView = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback="">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/:uuid/register" component={VidpitchSignup} />
          <Route
            exact
            path="/:uuid/video-download"
            component={VidpitchVideoDownload}
          />
          <Route
            exact
            path="/forgot-password"
            component={RequestChangePassword}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ChangePassword}
          />
          <Route exact path="/reset-email-sent" component={EmailSent} />
          <Route exact path="/success" component={SuccessMessage} />

          <Route exact path="/:uuid/video-draft" component={VidpitchVideo} />
          <Route
            exact
            path="/:uuid/video-draft-purchase"
            component={VidPitchPurchase}
          />
          <Route exact path="/payment-success" component={PaymentSuccess} />
          <Route
            exact
            path="/:uuid/modify-video"
            component={MultiStepModifyVideo}
          />
          <ProtectedRoute
            exact
            path="/assets-listing"
            allowedRoles={[ROLES.VIDPITCH_ADMIN, ROLES.BROKER]}
            component={AssetsListing}
          />
          <ProtectedRoute
            allowedRoles={[
              ROLES.USER,
              ROLES.ADMIN,
              ROLES.BROKER,
              ROLES.OPERATOR,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_PILOT,
              ROLES.VIDEOMS_EDITOR,
              ROLES.VIDEOMS_SALES_PERSON,
              ROLES.VIDPITCH_ADMIN,
              ROLES.VIDEOMS_QC
            ]}
            exact
            path="/logout"
            component={Logout}
          />
          <Route component={Error401} />
        </Switch>
        <DrawerRight />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppVidPitchView;
