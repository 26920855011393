import React from 'react';
import { Layer, Line } from 'react-konva';
import {range} from 'lodash'

const CustomShapes = (props) => {
  const { color, size, tool, xs, ys } = props;

  const getPoints = () => {
      const points = []
      range(0, xs.length).forEach((current, index) => {
          const currentX = (640 * (xs[current] * 100)) / 100
          const currentY = (360 * (ys[current] * 100)) / 100
          points.push(currentX)
          points.push(currentY)
      })
      return points
  }
  
  return (
    <Layer>
      <Line
        points={getPoints()}
        tension={0.5}
        closed
        stroke={color}
      />
    </Layer>
  );
};
export default CustomShapes;
