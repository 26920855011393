import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//
import { ROLES } from 'constants/role';
import { BRONZE, AUCTION } from 'constants/appConstants';
import { authContext } from 'contexts/AuthContext';
import {
  CREXI_BRONZE,
  mapFormDataToJson,
  renderPropertyBoundaryImage,
} from 'pages/CrexiBronze/constant';
import {
  crexiBronzeChapterStatus,
  crexiBronzeCombinedActions,
  videoRenderTimer,
} from 'pages/CrexiBronze/CrexiBronzeAction';
import {
  BRONZE_VIDEO_RENDER,
  DRAFT_BRONZE_SEGMENTS,
} from 'pages/CrexiBronze/CrexiBronzeGraphQL';
import { parseToInteger } from 'utils/helper';
import LoadingButton from 'common/LoadingButton';
import SaveToDraftModal from 'pages/CrexiBronze/common/SaveToDraftModal';
import { CHECKOUT } from 'pages/Search/CrexiSearchGraphQL';
import CheckOutConfirmationModal from '../CheckOutConfirmationModal';
import BoundaryAlert from '../BoundaryImageAlert';

const FormFooter = ({
  formValues,
  formDataValues,
  isSubmitting,
  isValid,
  isLoading,
  projectUUID,
  setSubmitting,
  propertyBoundaryImg,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useContext(authContext);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  const {
    currentChapter,
    logoAndQrUrl,
    currentPreviewURL,
    isChapterOneVisited,
    isVideoTextChange,
  } = useSelector((state) => state.crexiBronze);

  const { searchedBy, eliteAuction: selectedEliteAuction } = useSelector(
    (state) => state.salesPortal
  );

  useEffect(() => {
    if (isChapterOneVisited === 'SUCCESS') setModalConfirmation(true);
  }, [isChapterOneVisited]);

  const handleGotIt = () => {
    setModalConfirmation(false);
    dispatch(
      crexiBronzeCombinedActions({
        currentChapter: 1,
        currentPreviewURL: '',
        isChapterOneVisited: false,
        logoAndQrUrl: '',
        isVideoTextChange: false,
      })
    );

    if (auth?.data?.role === ROLES.VIDEOMS_EDITOR) {
      history.push(`/editor-portal/projects/${id}/folders`);
    } else if (
      [ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC].includes(auth?.data?.role) &&
      isVideoTextChange
    ) {
      history.push(`/pm-portal/projects/${id}/folders`);
    } else if (auth?.data?.role === ROLES.USER && isVideoTextChange) {
      history.push(
        `/management-portal/projects/active-projects/project-detail/${id}/bronze`
      );
    } else {
      history.push(searchedBy);
    }
  };

  const [checkoutMutation] = useMutation(CHECKOUT, {
    onCompleted: async () => {
      dispatch(
        crexiBronzeChapterStatus({
          isChapterOneVisited: 'SUCCESS',
        })
      );
      setModalConfirmation(true);
      setSubmitting(false);
    },
    onError: (e) => {
      setSubmitting(false);
      toast.error(e.message);
    },
  });

  const [bronzeVideoRender] = useMutation(BRONZE_VIDEO_RENDER, {
    onCompleted: async () => {
      dispatch(
        crexiBronzeChapterStatus({
          isChapterOneVisited: 'SUCCESS',
        })
      );

      if (auth?.data?.role === ROLES.VIDEOMS_EDITOR || isVideoTextChange) {
        dispatch(
          videoRenderTimer({
            videoRenderState: {
              showVideoRenderTime: true,
              videoRenderTimeElapsed: new Date(),
            },
          })
        );
      }

      setModalConfirmation(true);
      setSubmitting(false);
    },
    onError: (e) => {
      setSubmitting(false);
      toast.error(e.message);
    },
  });

  const [draftBronzeSegment] = useMutation(DRAFT_BRONZE_SEGMENTS, {
    onCompleted: async () => {
      if (currentChapter !== CREXI_BRONZE.TOTAL_CHAPTERS) {
        dispatch(
          crexiBronzeCombinedActions({
            currentChapter: currentChapter + 1,
            currentPreviewURL: '',
            isChapterOneVisited: true,
            logoAndQrUrl: '',
          })
        );
        setSubmitting(false);
      } else if (currentChapter === CREXI_BRONZE.TOTAL_CHAPTERS) {
        if (auth?.data?.role === ROLES.VIDEOMS_EDITOR || isVideoTextChange) {
          await bronzeVideoRender({
            variables: {
              projectId: parseToInteger(id),
            },
          });
        } else {
          await checkoutMutation({
            variables: {
              assetId: parseToInteger(id),
              eliteAuction: selectedEliteAuction || AUCTION,
              planName: BRONZE,
            },
          });
        }
      }
    },
    onError: ({ message }) => {
      setSubmitting(false);
      toast.error(message);
    },
  });

  const saveDetails = async () => {
    setSubmitting(true);
    const draftJSON = mapFormDataToJson(formDataValues, formValues, {
      logoAndQrUrl,
      currentPreviewURL,
    });
    try {
      await draftBronzeSegment({
        variables: {
          assetId:
            auth?.data?.role !== ROLES.VIDEOMS_EDITOR
              ? parseToInteger(id)
              : null,
          projectId:
            auth?.data?.role === ROLES.VIDEOMS_EDITOR || isVideoTextChange
              ? parseToInteger(id)
              : null,
          eliteAuction: selectedEliteAuction || AUCTION,
          segmentNumber: currentChapter,
          formData: draftJSON?.formData ?? {},
          projectUuid: projectUUID,
          isTextChange: isVideoTextChange,
        },
      });
    } catch (err) {
      setSubmitting(false);
      toast.error(err.message);
    }
  };

  const handleBoundaryAlert = (status) => {
    if (status === 'yes') {
      saveDetails();
    }
    setModalAlert(!modalAlert);
  };

  const saveChapterDetails = () => {
    const shouldHandleBoundaryAlert =
      propertyBoundaryImg?.length < 1 &&
      renderPropertyBoundaryImage(currentChapter);

    if (shouldHandleBoundaryAlert) {
      if (
        [ROLES.USER, ROLES.VIDEOMS_PM, ROLES.ADMIN, ROLES.VIDEOMS_QC].includes(
          auth?.data?.role
        ) &&
        !isVideoTextChange
      ) {
        return handleBoundaryAlert();
      }
    }

    return saveDetails();
  };

  const renderButtonText = () =>
    CREXI_BRONZE.TOTAL_CHAPTERS > currentChapter ? (
      <>Save & Next Chapter</>
    ) : (
      <span className="font-size-12">{`${
        auth?.data?.role === ROLES.VIDEOMS_EDITOR || isVideoTextChange
          ? 'Render Video'
          : 'Checkout'
      }`}</span>
    );

  const handleBack = () => {
    dispatch(
      crexiBronzeCombinedActions({
        currentChapter: currentChapter - 1,
        currentPreviewURL: '',
        logoAndQrUrl: '',
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-4">
        <SaveToDraftModal userRole={auth?.data?.role} />
        <div>
          {currentChapter > 1 && (
            <Button
              color="primary"
              outline
              disabled={isSubmitting || isLoading}
              onClick={handleBack}
              className="mr-2 crexi-previous-button"
            >
              Previous Chapter
            </Button>
          )}

          <LoadingButton
            color="primary"
            loading={isSubmitting}
            onClick={() => saveChapterDetails()}
            className={`${
              CREXI_BRONZE.TOTAL_CHAPTERS > currentChapter
                ? 'crexi-save-button'
                : 'crexi-cancel-button'
            }`}
            disabled={
              !isValid || isSubmitting || isLoading || modalConfirmation
            }
          >
            {/* {isVideoTextChange ? 'Done' : renderButtonText()} */}
            {renderButtonText()}
          </LoadingButton>
        </div>
      </div>
      <CheckOutConfirmationModal
        modalConfirmation={modalConfirmation}
        setModalConfirmation={setModalConfirmation}
        handleGotIt={handleGotIt}
        userRole={auth?.data?.role}
        isVideoTextChange={isVideoTextChange}
      />
      <BoundaryAlert
        setModalAlert={setModalAlert}
        handleBoundaryAlert={handleBoundaryAlert}
        modalAlert={modalAlert}
      />
    </>
  );
};

export default FormFooter;
