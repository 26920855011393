import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const ReviewCompleteModal = ({
  isLoading,
  setReviewComplete,
  reviewCompleteStatus,
  handleReivewComplete,
}) => {
  return (
    <Modal isOpen={reviewCompleteStatus} className="modal-dialog-centered">
      <ModalBody>
        <div className="text-center p-1">
          <span className="py-3 font-weight-500">
            Once you perform review complete, you will not be able to make
            either text changes or map comment. Do you want to continue ?
          </span>
          <div className="text-center mt-3">
            <Button
              color="primary"
              disabled={isLoading}
              className="btn-sm"
              onClick={handleReivewComplete}
            >
              Continue
            </Button>
            <Button
              color="secondary"
              disabled={isLoading}
              className="ml-2 btn-sm"
              onClick={() => setReviewComplete(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewCompleteModal;
