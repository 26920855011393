import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
//
import { parseToInteger } from 'utils/helper';
import { authContext } from 'contexts/AuthContext';
import { PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';
import { EDITOR_PROJECT_ACTIONS } from 'pages/EditorPortal/constant';

const DeleteInvitation = ({ projectId, refetchProjects }) => {
  const { auth } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);

  const [projectAcceptedByEditor] = useMutation(
    PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT,
    {
      onCompleted: async ({ projectAcceptedByEditorOrPilot }) => {
        refetchProjects();
        setIsLoading(false);
        toast.success(projectAcceptedByEditorOrPilot.message);
      },
      onError: ({ message }) => {
        toast.error(message);
        setIsLoading(false);
      },
    }
  );

  const onProjectInvitationDelete = async (status) => {
    try {
      setIsLoading(true);
      await projectAcceptedByEditor({
        variables: {
          status,
          flag: 'EDITOR',
          editorId: auth?.data?.userId,
          projectId: parseToInteger(projectId),
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div className="d-flex text-center">
      <Button
        outline
        size="sm"
        key="delete"
        color="primary"
        className="mr-2"
        disabled={isLoading}
        onClick={() => onProjectInvitationDelete(EDITOR_PROJECT_ACTIONS.Delete)}
      >
        Delete
      </Button>
    </div>
  );
};

export default DeleteInvitation;
