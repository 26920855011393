import { managementInitialState } from 'constants/reduxInitialStates/managemenState';
import * as actions from './ManagementPortalActionTypes';

export const managementPortalReducer = (
  state = managementInitialState,
  action
) => {
  switch (action.type) {
    case actions.ADD_ACTIVE_PROJECT_PAGE_INFO:
      return {
        ...state,
        activeProjectInfo: {
          previousPageInfo: action.payload.previousPageInfo,
        },
      };

    case actions.ADD_NEED_ATTENTION_PAGE_INFO:
      return {
        ...state,
        needMyAttentionInfo: {
          previousPageInfo: action.payload.previousPageInfo,
        },
      };
    case actions.ADD_COMPLETED_PAGE_INFO:
      return {
        ...state,
        completedProjectInfo: {
          previousPageInfo: action.payload.previousPageInfo,
        },
      };
    case actions.RESET_MANAGEMENT_REDUCER:
      return {
        ...state,
        ...managementInitialState,
      };
    default:
      return state;
  }
};
