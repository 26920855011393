import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Helmet } from 'react-helmet';

import Sidebar from './common/Sidebar';
import Projects from './components/Projects';

const ManagementPortal = ({ component, ...rest }) => {
  const { path } = rest.match;

  return (
    <Container className="themed-container" fluid>
      <Helmet defaultTitle="Management Portal - VidTech" />
      <Row>
        <Sidebar />
        <Col className="content-quad px-4 bg-white">
          <Scrollbars
            autoHide
            className="row"
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ width: `calc(100% + 30px)`, height: `calc(100vh - 60px)` }}
          >
            <Col sm="12">
              <Switch>
                <Route path={`${path}/projects`} component={Projects} />
              </Switch>
            </Col>
            <div style={{ height: '50px' }} />
          </Scrollbars>
        </Col>
      </Row>
    </Container>
  );
};
export default ManagementPortal;
