import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import HeaderUserDropdown from 'common/HeaderUserDropdown';
import PendingOrderButton from 'pages/Search/common/PendingOrderButton';
import { useQueryParams } from 'pages/Search/hooks';
import { resetSalesFilters } from 'pages/Search/SalesActions';
import PortalDropdown from 'common/PortalDropdown';
import VideoMSLogo from 'common/VideoMSLogo';

const Header = ({ isPendingOrder }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query] = useQueryParams();

  const pendingOrder = query.get('pendingOrder');
  const title = pendingOrder ? 'Pending Search Result' : 'Search Result';

  const goToPendingOrders = async () => {
    await dispatch(resetSalesFilters());
    history.push({
      pathname: '/crexi-search/results',
      search: '?pendingOrder=true',
    });
  };

  return (
    <Row className="content-header-row border-bottom py-2 align-items-center mx-n4">
      <Col sm="auto" className="d-flex align-items-center">
        {isPendingOrder && <VideoMSLogo portalName="" />}
        <h6 className="px-3 mb-0">{title}</h6>
      </Col>

      <Col sm="auto" className="ml-auto">
        <div className="d-flex">
          {!pendingOrder && (
            <PendingOrderButton goToPendingOrders={goToPendingOrders} />
          )}
          <PortalDropdown />
          <HeaderUserDropdown />
        </div>
      </Col>
    </Row>
  );
};
export default Header;
