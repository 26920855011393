export const salesPersonPortalInitialState = {
  dealsDetailsInfo: {
    filters: {
      dealName: '',
      status: '',
      startDate: '',
    },
    globalSearchText: '',
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
};
