import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PageLoader from 'common/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
//
import { UPDATED_RECORDS_BROKER_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import { addUpdatedBrokerPageInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import NoData from 'common/NoData';
import SearchBroker from 'pages/BrokersDirectory/common/SearchBroker';
import BrokerTableRenderer from './components/BrokerTableRenderer';

const GetUpdatedRecords = ({ filters, limit }) => {
  const { loading, data, error, refetch } = useQuery(
    UPDATED_RECORDS_BROKER_DIRECTORY,
    {
      variables: {
        ...filters,
        limit,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) return <PageLoader />;

  if (error) return <span>{error.message}</span>;

  if (data) {
    const {
      getBrokersDirectory: { data: brokersData, totalCount },
    } = data;

    if (!brokersData.length) return <NoData />;

    const pageCount = Math.ceil(totalCount / limit);

    return (
      <BrokerTableRenderer
        refetch={refetch}
        filters={filters}
        pageCount={pageCount}
        totalCount={totalCount}
        brokersData={brokersData}
      />
    );
  }
  return null;
};

const UpdatedRecordsWrapper = () => {
  const dispatch = useDispatch();
  const {
    updatedBrokersInfo: {
      filters,
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.brokersDirectory);

  const [searchParams, setSearchParams] = useState('first_name');
  const [searchString, setSearchString] = useState('');

  const limit = 10;

  const handleSearchParams = (value) => {
    setSearchParams(value);
    dispatch(
      addUpdatedBrokerPageInfo({
        updatedBrokersInfo: {
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const handleSearchString = (value) => {
    setSearchString(value);
    dispatch(
      addUpdatedBrokerPageInfo({
        updatedBrokersInfo: {
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <>
      <div className="broker-top-space">
        <Row>
          <Col lg={4} className="ml-auto mt-3">
            <div>
              <SearchBroker
                handleSearchParams={handleSearchParams}
                handleSearchString={handleSearchString}
              />
            </div>
          </Col>
        </Row>
        <GetUpdatedRecords
          filters={{
            ...filters,
            searchParams,
            searchString,
            page,
            offset,
            updatedRecords: true,
          }}
          limit={limit}
        />
      </div>
    </>
  );
};

export default UpdatedRecordsWrapper;
