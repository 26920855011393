import { gql } from '@apollo/client';

export const GET_BROKERS = gql`
  query getBrokersDirectory(
    $missingEmail: Boolean
    $missingPhone: Boolean
    $newBroker: Boolean
    $limit: Int
    $offset: Int
    $searchParams: String
    $searchString: String
    $sortParams: String
  ) {
    getBrokersDirectory(
      missingEmail: $missingEmail
      missingPhone: $missingPhone
      newBroker: $newBroker
      limit: $limit
      offset: $offset
      searchParams: $searchParams
      searchString: $searchString
      sortParams: $sortParams
    )
  }
`;

export const UPDATE_BROKER_DIRECTORY = gql`
  mutation updateBrokerDirectory(
    $phone: [String]
    $email: String
    $brokerId: Int!
    $isFlaggedRecord: Boolean
  ) {
    updateBrokerDirectory(
      phone: $phone
      email: $email
      brokerId: $brokerId
      isFlaggedRecord: $isFlaggedRecord
    )
  }
`;

export const UPDATED_RECORDS_BROKER_DIRECTORY = gql`
  query getBrokersDirectory(
    $limit: Int
    $offset: Int
    $updatedRecords: Boolean
    $searchParams: String
    $searchString: String
  ) {
    getBrokersDirectory(
      limit: $limit
      offset: $offset
      updatedRecords: $updatedRecords
      searchParams: $searchParams
      searchString: $searchString
    )
  }
`;

export const FLAGGED_RECORDS_BROKER_DIRECTORY = gql`
  query getFlaggedBrokers(
    $emailNotFound: Boolean
    $needsEvaluation: Boolean
    $withNotesOnly: Boolean
    $priority: [String]
    $limit: Int
    $offset: Int
    $searchParams: String
    $searchString: String
  ) {
    getFlaggedBrokers(
      emailNotFound: $emailNotFound
      needsEvaluation: $needsEvaluation
      withNotesOnly: $withNotesOnly
      priority: $priority
      limit: $limit
      offset: $offset
      searchParams: $searchParams
      searchString: $searchString
    )
  }
`;

export const FLAG_BROKER_BROKERS_DIRECTORY = gql`
  mutation flagBroker(
    $flagType: String!
    $priority: String!
    $note: String
    $brokerId: Int!
  ) {
    flagBroker(
      flagType: $flagType
      priority: $priority
      note: $note
      brokerId: $brokerId
    )
  }
`;

export const RECORDS_TO_BE_UPDATED = gql`
  query recordsToBeUpdated {
    recordsToBeUpdated
  }
`;

export const HIGH_PRIORITY_UPDATES = gql`
  query highPriorityUpdates {
    highPriorityUpdates
  }
`;

export const RECORDS_TO_BE_UPDATED_SUBSCRIPTION = gql`
  subscription updateBrokerDirectoryCountDetails {
    updateBrokerDirectoryCountDetails
  }
`;

export const HIGH_PRIORITY_COUNT_SUBSCRIPTION = gql`
  subscription highPriorityCountDetails {
    highPriorityCountDetails
  }
`;

export const HS_OUT_OF_SYNC = gql`
  query getHsOutOfSyncData($input: HsOutOfSyncDataInput) {
    getHsOutOfSyncData(input: $input)
  }
`;
