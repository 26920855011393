import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { authContext } from 'contexts/AuthContext';
import { NAVS } from 'constants/appNavigations';
import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';
import { resetManagementReducer } from 'pages/ManagementPortal/ManagementPortalActions';

const ManagementNavigation = () => {
  const dispatch = useDispatch();
  const { auth } = useContext(authContext);
  const {
    data: { role },
  } = auth;

  const resetReducerStore = () => {
    dispatch(
      toggleAttentionDrawer({
        isOpen: false,
        heading: '',
        drawerName: '',
      })
    );
    dispatch(resetManagementReducer());
  };

  const renderSubNav = (subNavs) => {
    return (
      <Nav vertical>
        {subNavs.map((row) => {
          return (
            <NavLink
              key={row.label}
              className="nav-link"
              isActive={(match) => match}
              to={row.link}
              onClick={resetReducerStore}
            >
              {row.label}
            </NavLink>
          );
        })}
      </Nav>
    );
  };

  return (
    <div className="management-tabs">
      <Nav vertical className="tab-ul row">
        {NAVS.map((TAB) => {
          const { rightTo, link, label, subNavs } = TAB;
          let linkPath = link;
          if (label === 'Projects' && subNavs) {
            linkPath = subNavs[0].link;
          }
          if (rightTo.includes(role)) {
            return (
              <NavItem key={label}>
                <NavLink className="nav-link font-weight-600 font-size-14" to={linkPath}>
                  {label}
                </NavLink>
                {subNavs ? renderSubNav(subNavs) : null}
              </NavItem>
            );
          }
          return null;
        })}
      </Nav>
    </div>
  );
};

export default ManagementNavigation;
