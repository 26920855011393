import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
//
import checkImg from 'assets/checkbox-circle-fill.svg';

const PaymentSuccess = ({ uuid, email, message, isCompleteMarked }) => {
  const history = useHistory();

  return (
    <>
      <div className="text-center">
        {isCompleteMarked && (
          <img
            alt="checkImg"
            src={checkImg}
            height="60"
            className="mr-1 mb-1"
          />
        )}
      </div>
      <div className="text-center">
        <div className="mb-4 font-weight-600"> {message}</div>
        {isCompleteMarked && (
          <>
            <div className="mb-2">
              You will get the information regarding the video status and other
              on your email address.
            </div>
            <div className="font-weight-600">{email}</div>
          </>
        )}
        {!isCompleteMarked && (
          <Button
            type="button"
            onClick={() => history.push(`/${uuid}/video-draft-purchase`)}
          >
            Go back to payment page
          </Button>
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
