import { vidPitchInitialState } from 'constants/reduxInitialStates/vidPitchState';
import * as actions from './VidPitchActionTypes';

export const VidPitchReducer = (state = vidPitchInitialState, action) => {
  switch (action.type) {
    case actions.ASSET_LIST_INFO:
      return {
        ...state,
        assetListInfo: {
          ...action.payload.assetListInfo,
        },
      };
    default:
      return state;
  }
};
