import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Avatar = ({ userName }) => {
  return (
    <div>
      <span className="first-letter-badge bg-secondary">
        {userName ? (
          userName.charAt(0)
        ) : (
          <FontAwesomeIcon icon={faUser} className="text-white" />
        )}
      </span>
    </div>
  );
};

export default Avatar;
