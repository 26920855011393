import React, { useContext, useState } from 'react';
import { Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import NoData from 'common/NoData';
import { awsFolderAssetsContext } from 'contexts/GetAWSFolderAssets';
import AssetFiles from './components/AssetFiles';

const ProjectAssetProvider = ({
  handleSelectFile,
  handleSelectAll,
  isPilotPortal,
  pilotID,
  refetchPilotVideoImages,
  isMarkedCompleted,
  isSalesPortal,
  hideCheckbox,
  setFilesCount,
  portalSlug,
  hasFilesInFolderListing,
  parent,
}) => {
  const { awsfiles, loading, error, assetLocation, refetchAssets } = useContext(
    awsFolderAssetsContext
  );
  const [allSelected, setAllSelected] = useState(false);

  if (loading) return <Spinner color="secondary" />;

  if (error) return <div>{error.message}</div>;
  if (awsfiles && !awsfiles.length && !isPilotPortal) return <NoData />;

  if (awsfiles) {
    if (setFilesCount) {
      if (isPilotPortal) {
        setFilesCount(assetLocation, awsfiles.length);
      } else {
        setFilesCount(awsfiles.length);
      }
    }
    const onSelectAll = (e) => {
      setAllSelected(e.target.checked);
      handleSelectAll(e.target.checked, awsfiles);
    };

    return (
      <>
        {!hasFilesInFolderListing && (
          <Row>
            {!isPilotPortal && (
              <Col md="12">
                <div className="select-all mb-3 w-100 mt-3">
                  <FormGroup check>
                    <Label check className="font-weight-500">
                      <Input
                        type="checkbox"
                        value={allSelected}
                        onChange={(e) => onSelectAll(e)}
                      />{' '}
                      Select All
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            )}
          </Row>
        )}

        <Row>
          <AssetFiles
            allSelected={allSelected}
            getAwsFiles={awsfiles}
            handleSelectAll={handleSelectAll}
            handleSelectFile={handleSelectFile}
            isPilotPortal={isPilotPortal}
            refetch={refetchAssets}
            pilotID={pilotID}
            refetchPilotVideoImages={refetchPilotVideoImages}
            isMarkedCompleted={isMarkedCompleted}
            isSalesPortal={isSalesPortal}
            hideCheckbox={hideCheckbox}
            portalSlug={portalSlug}
            hasFilesInFolderListing={hasFilesInFolderListing}
            parent={parent}
          />
        </Row>
      </>
    );
  }
  return null;
};
export default ProjectAssetProvider;
