import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import MailIcon from 'assets/mail-icon.png';
import { ROLES } from 'constants/role';

const CheckOutConfirmationModal = ({
  modalConfirmation,
  handleGotIt,
  userRole,
  isVideoTextChange,
}) => {
  return (
    <div>
      <Modal isOpen={modalConfirmation} className="modal-dialog-centered">
        <ModalBody>
          <div className="text-center px-3 py-3">
            {userRole !== ROLES.VIDEOMS_EDITOR && !isVideoTextChange ? (
              <>
                <img src={MailIcon} alt="MailIcon" />
                <h4 className="py-3">
                  Your order summary has been sent to your email.
                </h4>
                <p className="font-size-16 ">
                  Please check your junk folder to assure email receipt.
                </p>
              </>
            ) : (
              <>
                <h4 className="py-3">This video is rendering.</h4>
                <p className="font-size-16 ">
                  You will be able to access the video via URL under
                  <strong> Production/ Approvals</strong> task section.
                </p>
              </>
            )}
            <div className="text-center">
              <Button
                color="primary"
                onClick={handleGotIt}
                className="px-4 font-weight-500"
                size="sm"
                outline
              >
                Okay, Got it
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CheckOutConfirmationModal;
