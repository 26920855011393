import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';

import { authContext } from 'contexts/AuthContext';
import { allowedUserChecker } from 'utils/helper';
import { logOut } from 'pages/Login/LoginActions';
import PageLoader from 'common/PageLoader';
import { APP_TOKEN } from 'constants/appConstants';

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth, setAuthData } = useContext(authContext);
  const { loading } = auth;
  let role = '';

  const forceLogout = async () => {
    await dispatch(logOut());
    await setAuthData(null);
  };

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <PageLoader />;
        }}
      />
    );
  }
  // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const {
          location: { pathname },
        } = routeProps;
        if (auth && !auth.data)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );

        const {
          data: { token },
        } = auth;

        if (JSON.parse(window.localStorage.getItem(APP_TOKEN))) {
          role = JSON.parse(window.localStorage.getItem(APP_TOKEN)).role;
        }

        const { exp } = jwtDecode(token);

        let expirationTime = exp * 1000;
        expirationTime -= 60000;

        const hasValidToken = Date.now() < expirationTime;
        const hasAccess = allowedUserChecker(role, allowedRoles);

        if (!hasValidToken) {
          forceLogout();
        } else if (hasValidToken && !hasAccess) {
          return <Redirect to="/error/401" />;
        }

        return auth.data && hasValidToken ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
