import React from 'react';
import { Input, InputGroupAddon } from 'reactstrap';

const SearchBroker = ({
  searchParams,
  searchString,
  hideEmailParam,
  handleSearchParams,
  handleSearchString,
}) => {
  return (
    <>
      <div className="input-group updated-record-search">
        <InputGroupAddon addonType="prepend">
          <Input
            type="select"
            name="select"
            value={searchParams}
            onChange={(e) => handleSearchParams(e.target.value)}
          >
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
            <option value="company">Company</option>
            <option value="email">Email</option>
          </Input>
        </InputGroupAddon>
        <Input
          type="search"
          name="searchText"
          id="searchText"
          placeholder="Search"
          value={searchString}
          onChange={(e) => handleSearchString(e.target.value)}
        />
      </div>
    </>
  );
};
export default SearchBroker;
