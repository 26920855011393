import { appInitialState } from 'constants/reduxInitialStates/appState';
import * as actions from './AppActionTypes';

export const AppReducer = (state = appInitialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_PORTAL:
      return {
        ...state,
        selectedPortal: action.payload.portalName,
      };

    case actions.ADD_ROUTE_PAGE_NUM:
      return {
        ...state,
        previousPageInfo: action.payload.previousPageInfo,
      };
    case actions.TOGGLE_ATTENTION_DRAWER:
      return {
        ...state,
        attentionDrawerInfo: action.payload,
      };

    default:
      return state;
  }
};
