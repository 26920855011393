import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, set } from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, CardBody, CardTitle } from 'reactstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import client from 'config/apollo';
import { numberWithCommas } from 'utils/helper';
import { useGetAssestPlan } from 'pages/Search/hooks';
import { GET_ASSEST_PLAN } from 'pages/Search/CrexiSearchGraphQL';
import { changePlan } from 'pages/Search/SalesActions';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import PlansModal from '../../../PlansModal';

const PlansCardContent = ({ defaultPlan }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { plan } = useSelector((state) => state.salesPortal);
  const { assetDetail, isIVCDisabled, setIsIVCDisabled, handleEditAsset } =
    useContext(assetDetailContext);

  const { assetId } = useParams();

  const {
    location: { pathname, search },
  } = history;

  useEffect(() => {}, [defaultPlan]);

  const {
    id: defaultPlanId,
    type: defaultType,
    price: defaultPrice,
  } = defaultPlan;

  const [showPlansModal, setPlansModalStatus] = useState(false);

  const { data, loading } = useGetAssestPlan();
  if (loading) {
    return (
      <span className="text-center my-auto ">Plan for asset is loading...</span>
    );
  }
  const { getAssetPlan } = data;

  const getSelectedPlan = () => {
    const selctedPlan = !isEmpty(plan)
      ? plan
      : { id: defaultPlanId, type: defaultType, price: defaultPrice };

    return selctedPlan;
  };

  const { planId, price, type, isActive } = !getAssetPlan
    ? getSelectedPlan()
    : getAssetPlan;

  if (getAssetPlan && isEmpty(plan)) {
    dispatch(
      changePlan({
        plan: {
          id: planId,
          type,
          price,
        },
      })
    );
  }

  const handleSelectPlan = async ({ type, price, id }) => {
    setPlansModalStatus(!showPlansModal);

    if (type === 'SILVER' && assetDetail && assetDetail.interiorVideoContact) {
      handleEditAsset(false, null);
      // setIsIVCDisabled(false);
    }
    // if (type !== 'SILVER' && assetDetail && !assetDetail.interiorVideoContact) {
    //   setIsIVCDisabled(true);
    // }
    // if (
    //   type !== 'SILVER' &&
    //   isIVCDisabled &&
    //   assetDetail &&
    //   assetDetail.interiorVideoContact
    // ) {
    //   setIsIVCDisabled(false);
    // }
    if (id) {
      if (assetId) {
        try {
          await client.writeQuery({
            query: GET_ASSEST_PLAN,
            variables: {
              assetId: parseInt(assetId, 10),
            },
            data: {
              getAssetPlan: {
                planId: id,
                type,
                price,
              },
            },
          });
        } catch (e) {
          toast.error(e.message);
        }
      }
      history.push({
        pathname,
        search,
      });
    }
    if (!plan || isEmpty(plan)) {
      dispatch(
        changePlan({
          plan: {
            id: planId,
            type,
            price,
          },
        })
      );
    }
    dispatch(
      changePlan({
        plan: {
          id,
          type,
          price,
        },
      })
    );
  };

  const togglePlansModal = () => {
    setPlansModalStatus(!showPlansModal);
  };

  return (
    <>
      <CardBody className="p-3">
        <CardTitle tag="h6" className="font-weight-bold">
          {type} &nbsp;
          <FontAwesomeIcon icon={faCheckCircle} color="#565082" />
        </CardTitle>
        <span className="form-control price-tag">
          ${`${numberWithCommas(price || defaultPrice)}`}
        </span>
        <Button
          color="link"
          onClick={togglePlansModal}
          className="p-0 font-size-12 font-weight-bold view-detail-btn"
        >
          View Detail/Change
        </Button>
      </CardBody>
      <PlansModal
        slectedPlanType={type}
        planId={planId}
        showPlansModal={showPlansModal}
        handleSelectPlan={handleSelectPlan}
        togglePlansModal={togglePlansModal}
      />
    </>
  );
};

export default PlansCardContent;
