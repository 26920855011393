import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import PasswordChecklist from 'react-password-checklist';
import { InputGroup, Card, CardBody, Container, Button } from 'reactstrap';
import * as Yup from 'yup';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'common/LoadingButton';
import Logo from 'assets/videoms-logo.png';
import {
  CHANGE_PASSWORD,
  CHECK_RESET_LINK_EXPIRATION,
} from 'pages/ResetPassword/ResetPasswordGraphQL';
import { Link, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';
import eyeVisible from 'assets/eye-line.svg';
import eyeInvisible from 'assets/eye-off-line.svg';
import Loader from 'common/Loader';

const initialValues = {
  password: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/, 'Incorrect format'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('This field is required'),
});

const ChangePassword = ({ email, token }) => {
  const history = useHistory();
  const { auth } = useContext(authContext);
  const [isEyeVisible, setIsEyeVisible] = useState(false);

  const redirectBasedOnRole = (role) => {
    if (role === ROLES.OPERATOR) {
      history.push('/brokers-directory/update-missing-details');
    } else {
      history.push('/crexi-search/results');
    }
  };

  useEffect(() => {
    if (auth.data) {
      redirectBasedOnRole(auth.data.role);
    }
  }, [auth]);

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: async ({ resetPassword }) => {
      const { message } = resetPassword;
      toast.success(message);
      history.push('/success');
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleSubmit = async (values, actions) => {
    const { password } = values;
    try {
      actions.setSubmitting(true);
      await changePassword({
        variables: { email, password, token },
      });
      actions.setSubmitting(false);
    } catch (e) {
      actions.setFieldError('general', e.message);
      actions.setSubmitting(false);
      toast(e.message, { appearance: 'error' });
    }
  };

  const RenderResetPassword = () => {
    return (
      <div className="no-auth-main-container">
        <div className="form-signin">
          <Card className="w-100 border-radius-8px">
            <CardBody className="p-4">
              <div className="mb-4">
                <img src={Logo} alt="Logo" width="100" />
              </div>
              <p className="font-size-14 font-weight-bold">Reset Password</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, values }) => (
                  <Form>
                    <InputGroup className="mt-2" size="medium">
                      <Field
                        id="password"
                        name="password"
                        type={isEyeVisible ? 'text' : 'password'}
                        component={FormikInputField}
                        label="New Password"
                      />
                    </InputGroup>
                    <InputGroup size="medium">
                      <Field
                        id="confirmPassword"
                        name="confirmPassword"
                        type={isEyeVisible ? 'text' : 'password'}
                        component={FormikInputField}
                        label="Confirm Password"
                      />
                      <Button
                        type="button"
                        className="eye-button"
                        onClick={() => setIsEyeVisible(!isEyeVisible)}
                      >
                        {isEyeVisible ? (
                          <img src={eyeVisible} alt="eye Visible" />
                        ) : (
                          <img src={eyeInvisible} alt="eye Invisible" />
                        )}
                      </Button>
                      <PasswordChecklist
                        className="p-2"
                        rules={[
                          'number',
                          'capital',
                          'lowercase',
                          'minLength',
                          'match',
                        ]}
                        minLength={6}
                        value={values.password}
                        valueAgain={values.confirmPassword}
                      />
                    </InputGroup>
                    <LoadingButton
                      className="btn btn-primary btn-block font-weight-500 mt-2"
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                      color="primary"
                      block
                      type="submit"
                    >
                      Submit
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  return <>{RenderResetPassword()}</>;
};

const ChangePasswordRenderer = () => {
  const history = useHistory();
  const params = useParams();

  const { token } = params;
  const decodeUserInfo = jwtDecode(token);
  const { data, exp } = decodeUserInfo;
  const { email } = data;

  const {
    loading,
    error,
    data: linkData,
  } = useQuery(CHECK_RESET_LINK_EXPIRATION, {
    variables: {
      email,
      token,
    },
    fetchPolicy: 'network-only',
  });

  const currentTime = Date.now().valueOf() / 1000;
  const invalidToken = exp < currentTime;

  if (loading) return <Loader />;

  if (error) return <div>{error.message}</div>;

  if (linkData) {
    const {
      checkResetLinkExpiration: { status },
    } = linkData;
    if (status === 404) {
      history.push('/error/404');
    }
  }

  if (invalidToken) {
    return (
      <div className="pt-5 ">
        <Container>
          <div className="p-4 card-body bg-white border-radius-8px shadow-sm">
            <h3 className=" border-0 font-weight-bold">
              Sorry, Your link is expired,
              <br /> Please click the below link to generate new password:
            </h3>
            <Link to="/forgot-password">Click here</Link>
          </div>
        </Container>
      </div>
    );
  }

  return <ChangePassword email={email} token={token} />;
};
export default ChangePasswordRenderer;
