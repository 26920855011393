import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { useAssestDetails } from 'pages/Search/hooks';
import AuctionCard from 'pages/Search/common/AuctionCard';

const AuctionCardWrapper = () => {
  const { eliteAuction: selectedEliteAuction } = useSelector(
    (state) => state.salesPortal
  );

  const { loading, data } = useAssestDetails();
  if (loading) {
    return (
      <Card className="border-radius-8px border-0 grey-card card-block auction-selector mb-4 mt-2">
        <CardBody className="p-3">Loading ...</CardBody>
      </Card>
    );
  }

  if (data) {
    const {
      getAssetDetails: { eliteAuction },
    } = data;

    return (
      <Card className="border-radius-8px border-0 grey-card card-block auction-selector mb-4 mt-2">
        <CardBody className="p-3">
          <AuctionCard
            eliteAuctionStatus={
              !eliteAuction ? selectedEliteAuction : eliteAuction
            }
          />
        </CardBody>
      </Card>
    );
  }
  return null;
};

export default AuctionCardWrapper;
