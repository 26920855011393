import React, { useContext, useEffect, useState } from 'react';
import VideoPlayer from 'common/VideoPlayer';
import ImageLoader from 'react-load-image';
import { getExtensionFromFileName } from 'utils/helper';
import { VidPitchContext } from 'contexts/VidPitchContext';
import { VIDEO_FORMATS } from 'constants/fileFormatAndTypes';
import loadingImage from '../../../../../../../../assets/loading.gif';

const Preview = () => {
  const [isVideo, setIsVideoType] = useState(false);
  const {
    modifyVideoDetails: {
      previewURL,
      totalChapter,
      currentChapter,
      isVideoType,
    },
  } = useContext(VidPitchContext);

  const verifyIsVideoMedia = () => {
    const ext = getExtensionFromFileName(previewURL);
    return Object.values(VIDEO_FORMATS).filter((item) => {
      if (item === ext) {
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (isVideoType) setIsVideoType(verifyIsVideoMedia());
  }, [previewURL]);

  const renderLoadingPreview = () => {
    return (
      <div className="v-loading-container">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <img
            height="46"
            alt="loading..."
            className="mr-3"
            src={loadingImage}
          />
        </div>
      </div>
    );
  };

  const renderPreviewSegment = () => {
    if (isVideo.length) {
      return <VideoPlayer isModal isVidPitchPlayer videoSrc={previewURL} />;
    }
    return (
      <ImageLoader src={previewURL}>
        <img alt="preview" className="v-loading-image-container w-100 mr-3" />
        <span className="text-danger">Unable to load image.</span>
        {renderLoadingPreview()}
      </ImageLoader>
    );
  };

  return (
    <div>
      <div className="mt-2 mb-2 p-2">
        <b className="font-size-18">
          Text and images changes will be visible in your rendered video.
        </b>
      </div>
      <div className="ml-2 mr-2">
        {previewURL ? renderPreviewSegment() : renderLoadingPreview()}
      </div>

      {!isVideo.length && previewURL && (
        <div className="mt-3 d-flex justify-content-center">
          <b className="font-size-18">
            Showing - Chapter {currentChapter} of {totalChapter}
          </b>
        </div>
      )}
    </div>
  );
};

export default Preview;
