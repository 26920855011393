import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const GET_QUOTE_DATA = gql`
  query getDealQuotes($dealId: String!) {
    getDealQuotes(dealId: $dealId)
  }
`;

const QuoteURL = ({ dealId }) => {
  const [modal, setModal] = useState(false);

  const dealDialog = () => setModal(!modal);

  const { loading, error, data } = useQuery(GET_QUOTE_DATA, {
    variables: { dealId },
  });

  const { getDealQuotes } = data || {};

  if (loading) return 'Loading...';

  if (error)
    return (
      <div className="text-danger">
        <b>{error.message}</b>
      </div>
    );

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success('Linked copied to clipboard');
      })
      .catch((err) => {
        toast.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className="d-flex align-items-center border-top">
      <Button
        color="primary"
        size="sm"
        className="mt-4"
        block
        onClick={dealDialog}
      >
        Quote URL Link
      </Button>

      <Modal isOpen={modal} toggle={dealDialog} centered>
        <ModalHeader toggle={dealDialog}>Quote URL Link</ModalHeader>
        <ModalBody className="my-2">
          {getDealQuotes.map((quote) => (
            <div key={quote.id} className="mb-2 d-flex">
              <a
                href={quote.quoteLink}
                target="_blank"
                rel="noreferrer"
                className="font-size-16 mr-2 text-decoration-underline"
              >
                {quote.quoteLink}
              </a>
              <Button
                color="link"
                onClick={() => copyToClipboard(quote.quoteLink)}
                className="p-0"
              >
                <i
                  className="fa fa-clipboard px-0 font-size-18"
                  aria-hidden="true"
                  title="Copy link"
                />
              </Button>

              {new Date(quote.quoteExpirationDate) < new Date() ? (
                <Badge color="danger">Quote expired</Badge>
              ) : null}
            </div>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default QuoteURL;
