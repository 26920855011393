import * as actions from './BrokersDirectoryActionTypes';

export const addUpdatedBrokerPageInfo = (payload) => ({
  type: actions.ADD_UPDATED_BROKER_PAGE_INFO,
  payload,
});

export const addMissingBrokerPageInfo = (payload) => ({
  type: actions.ADD_MISSING_BROKER_PAGE_INFO,
  payload,
});

export const changeFlaggedFilters = (payload) => ({
  type: actions.CHANGE_FLAGGED_FILTERS,
  payload,
});

export const dealsDetailsInfo = (payload) => ({
  type: actions.DEALS_DETAIL_INFO,
  payload,
});

export const HSoutOfSyncFilterInfo = (payload) => ({
  type: actions.HS_OUT_OF_SYNC_FILTER,
  payload,
});
