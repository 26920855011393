import { gql } from '@apollo/client';

export const REQUEST_CHANGE_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation resetPassword($email: String!, $password: String!, $token: String!) {
    resetPassword(email: $email, password: $password, token: $token)
  }
`;

export const CHECK_RESET_LINK_EXPIRATION = gql`
  query checkResetLinkExpiration($email: String!, $token: String!) {
    checkResetLinkExpiration(email: $email, token: $token)
  }
`;
