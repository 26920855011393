import { useSubscription } from '@apollo/client';
import { TASK_NOTIFICATION_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';

function useTasSubscription() {
  const { data, loading, error } = useSubscription(
    TASK_NOTIFICATION_SUBSCRIPTION,
    {
      shouldResubscribe: true,
    }
  );
  if (loading) return null;
  if (error) return error;
  if (data) {
    const {
      taskNotification: { data: taskSubscription },
    } = data;
    return taskSubscription;
  }
  return null;
}

export default useTasSubscription;
