import React, { useContext } from 'react';
import { Table } from 'reactstrap';
import asanaIMG from 'assets/asana.png';
import moviePlatinum from 'assets/movie.svg';
import hubspotIcon from 'assets/hubspot-icon.png';
import Header from 'pages/PMPortal/common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import { NOT_SPECIFIED } from 'constants/appConstants';

const ProjectDetailHeader = ({
  projectName,
  projectAsanaLink,
  startDate,
  videoStatus,
  timeElapsed,
  hubspotLink,
  deliveryDate,
  timeTillDue,
  planType
}) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);
  
  return (
    <>
      <Header heading={projectName} />
      <div className="table-1">
        <div className="video-review-head px-2 py-1 mx-n3 border-bottom">
          <Table borderless className="mb-0">
            <tbody>
              <tr>
                <td className="font-size-14 font-weight-bold align-middle">
                  <img
                    className="mr-2"
                    src={planType ? PLAN_TYPE_ICONS[planType].icon : moviePlatinum}
                    alt="planType"
                  />
                  {projectName}
                </td>
                <td>
                  <a
                    href={projectAsanaLink}
                    rel="noreferrer"
                    target="_blank"
                    onClick={
                      role === ROLES.VIDEOMS_EDITOR
                        ? (e) => e.preventDefault()
                        : ''
                    }
                    className={`mb-0 text-info ${
                      role === ROLES.VIDEOMS_EDITOR ? 'disableStar' : ''
                    }`}
                  >
                    <img src={asanaIMG} alt={asanaIMG} className="mr-2" /> View
                    project in Asana
                  </a>
                  <FontAwesomeIcon
                    icon={faExternalLinkSquareAlt}
                    color="#565082"
                    className="ml-2 align-middle"
                  />
                </td>
                <td className="text-right pr-4">
                  {hubspotLink ? <a
                    href={hubspotLink}
                    rel="noreferrer"
                    target="_blank"
                    className="mb-0"
                  >
                    <img src={hubspotIcon} alt={hubspotIcon} className="mr-2" />{' '}
                    Hubspot link
                  </a> : <></>}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="table-2">
        <div className="video-review-head px-2 py-2 mx-n3">
          <Table borderless className="mb-0 pt-3">
            <tbody>
              <tr>
                <td className="font-size-13 align-middle">
                  Start Date :{' '}
                  <strong>
                    {' '}
                    {startDate !== NOT_SPECIFIED
                      ? dateTimeFormatter(startDate)
                      : 'N/A'}
                  </strong>
                </td>
                <td className="font-size-13 align-middle">
                  Elapsed time : <strong>{timeElapsed || 'N/A'}</strong>
                </td>
                <td className="font-size-13 align-middle">
                  Delivery Date :{' '}
                  <strong>
                    {deliveryDate !== NOT_SPECIFIED
                      ? dateTimeFormatter(deliveryDate)
                      : 'N/A'}
                  </strong>
                </td>
                <td className="font-size-13 align-middle">
                  Time till due : <strong> {timeTillDue || 'N/A'}</strong>
                </td>
                <td className="font-size-13 align-middle">
                  Video Status:{' '}
                  <span className="active-td border border-radius-8px badge badge-secondary">
                    {videoStatus || 'N/A'}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default ProjectDetailHeader;
