/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import {
  Stage,
  Layer,
  Rect,
  Circle,
  Line,
  Arrow,
  RegularPolygon,
} from 'react-konva';

const DrawAnnotations = ({
  annotationsList,
  setAnnotationsList,
  videoElement,
  annotationDetails,
}) => {
  const [newAnnotation, setNewAnnotation] = useState([]);
  const { annotationStrockWidth, annotationStrockColor, type } =
    annotationDetails;

  const handleMouseDown = (event) => {
    if (newAnnotation.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();

      setAnnotationsList([
        ...annotationsList,
        {
          type,
          key: Date.now(),
          x1: x,
          y1: y,
          x2: x,
          y2: y,
          x,
          y,
          width: 0,
          height: 0,
          points: [x, y],
          annotationStrockColor,
          annotationStrockWidth,
        },
      ]);

      setNewAnnotation([{ x, y, width: 0, height: 0, key: 0 }]);
    }
  };

  const handleMouseMove = (event) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;

      const { x, y } = event.target.getStage().getPointerPosition();
      const lastLineArrIndex = annotationsList.length - 1;

      const ele = annotationsList[lastLineArrIndex];
      const newData = {
        key: ele.key,
        type,
        x1: ele.x1,
        y1: ele.y1,
        x2: x,
        y2: y,
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        points: ele.points ? ele.points.concat([x, y]) : [],
        annotationStrockColor,
        annotationStrockWidth,
      };
      annotationsList[lastLineArrIndex] = newData;
      setAnnotationsList(annotationsList);

      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: Date.now(),
          type,
          annotationStrockColor,
          annotationStrockWidth,
        },
      ]);
    }
  };

  const handleMouseUp = (event) => {
    if (newAnnotation.length === 1) {
      setNewAnnotation([]);
    }
  };

  const renderAnnotations = (value) => {
    switch (value.type) {
      case 'rect':
        return (
          <Rect
            key={value.key}
            x={value.x}
            y={value.y}
            width={value.width}
            height={value.height}
            fill="transparent"
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
          />
        );
      case 'circle':
        return (
          <Circle
            key={value.key}
            x={value.x}
            y={value.y}
            fill="transparent"
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
            radius={
              value.width > 1 && value.height > 1
                ? value.width + value.height
                : 0
            }
          />
        );
      case 'line':
        return (
          <Line
            points={[value.x1, value.y1, value.x2, value.y2]}
            key={value.key}
            lineCap="round"
            lineJoin="round"
            strokeWidth={value.annotationStrockWidth}
            stroke={value.annotationStrockColor}
          />
        );
      case 'arrow':
        return (
          <Arrow
            key={value.key}
            points={[value.x1, value.y1, value.x2, value.y2]}
            tension={value.annotationStrockWidth + 3}
            stroke={value.annotationStrockColor}
            lineCap="round"
            fill={value.annotationStrockColor}
            lineJoin="round"
            strokeWidth={value.annotationStrockWidth}
          />
        );
      case 'freeDrawing':
        return (
          <Line
            key={value.key}
            points={value.points}
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
            tension={0.5}
            lineCap="round"
            globalCompositeOperation="source-over"
          />
        );
      case 'hexagon':
        return (
          <RegularPolygon
            key={value.key}
            sides={6}
            x={value.x}
            y={value.y}
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
            radius={
              value.width > 1 && value.height > 1
                ? value.width + value.height
                : 0
            }
          />
        );
      case 'pentagon':
        return (
          <RegularPolygon
            key={value.key}
            sides={5}
            x={value.x}
            y={value.y}
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
            radius={
              value.width > 1 && value.height > 1
                ? value.width + value.height
                : 0
            }
          />
        );
      case 'triangle':
        return (
          <RegularPolygon
            key={value.key}
            sides={3}
            x={value.x}
            y={value.y}
            stroke={value.annotationStrockColor}
            strokeWidth={value.annotationStrockWidth}
            radius={
              value.width > 1 && value.height > 1
                ? value.width + value.height
                : 0
            }
          />
        );
      default:
        return null;
    }
  };

  const videoWidth = videoElement.current
    ? videoElement.current.offsetWidth
    : 0;
  const videoHeight = videoElement.current
    ? videoElement.current.offsetHeight
    : 0;

  return (
    <Stage
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      width={videoWidth}
      height={videoHeight}
      style={{ zIndex: 999 }}
    >
      <Layer>
        {annotationsList.map((value) => {
          return renderAnnotations(value);
        })}
      </Layer>
    </Stage>
  );
};

export default DrawAnnotations;
