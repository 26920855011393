import React from 'react';

const PropertyFeatureBlock = ({ heading, value, bottomSpace }) => {
  const showPropertyDetail = () => {
    if (value?.length > 1) {
      return value?.join(',');
    }
    if (value) {
      return value;
    }
    return 'Not Specified';
  };

  return (
    <p className={bottomSpace ? 'mb-3' : 'mb-0'}>
      <small className="text-muted d-block font-size-12 text-uppercase">
        {heading}
      </small>
      <strong className="font-weight-500">
        {heading === 'SUB TYPE'
          ? showPropertyDetail()
          : value || 'Not Specified'}
      </strong>
    </p>
  );
};
export default PropertyFeatureBlock;
