import React, { useContext } from 'react';
import * as Yup from 'yup';
import PasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
//
import { ROLES } from 'constants/role';
import Logo from 'assets/videoms-logo.png';
import FormikInputField from 'components/Fields/FormikInputField';
import { UPDATE_PROFILE } from 'pages/Pilot/PilotPortalGraphQL';
import { useDispatch } from 'react-redux';
import { logOut } from 'pages/Login/LoginActions';
import { authContext } from 'contexts/AuthContext';

const editorRegistrationSchema = Yup.object({
  firstName: Yup.string().trim().required('Name is required.'),
  emailAddress: Yup.string()
    .trim()
    .email('Invalid Email Address')
    .required('Email Address is required.'),
  password: Yup.string()
    .required('Password is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/, 'Incorrect format'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const EditorRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, name } = useParams();
  const { setAuthData } = useContext(authContext);

  const [editorRegistration] = useMutation(UPDATE_PROFILE, {
    onCompleted: async ({ updateProfile: { message } }) => {
      await dispatch(logOut());
      await setAuthData(null);
      toast.success(message);
      history.push('/login');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const handleRegistration = async (values) => {
    const { emailAddress, firstName, confirmPassword, ...registrationDetails } =
      values;
    try {
      await editorRegistration({
        variables: {
          role: ROLES.VIDEOMS_EDITOR,
          email: emailAddress.trim().toLowerCase(),
          input: { firstName: firstName.trim(), ...registrationDetails },
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card width={100} className="p-2">
          <CardBody>
            <Row>
              <Col md={5}>
                <div className="text-left">
                  <img src={Logo} alt="Logo" width="100"/>
                </div>
              </Col>
              <Col md={7}>
                <div className="mt-2">
                  <span className="font-size-16 font-weight-bold text-primary">
                    Editor Registration
                  </span>
                </div>
              </Col>
            </Row>
            <Formik
              enableReinitialize
              initialValues={{
                emailAddress: email,
                firstName: name,
                password: '',
                confirmPassword: '',
              }}
              onSubmit={(values) => {
                handleRegistration(values);
              }}
              validationSchema={editorRegistrationSchema}
              children={({ handleSubmit, values }) => {
                return (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <p className="mt-4">
                      <Field
                        required
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="Enter Name"
                        label="Full Name"
                        component={FormikInputField}
                        className="form-control form-control-sm mb-0"
                      />
                    </p>

                    <Field
                      required
                      id="emailAddress"
                      type="email"
                      name="emailAddress"
                      placeholder="Enter Email"
                      label="Email"
                      component={FormikInputField}
                      className="form-control form-control-sm mb-0"
                    />
                    <Field
                      required
                      id="password"
                      type="password"
                      name="password"
                      label="Password"
                      component={FormikInputField}
                      className="form-control form-control-sm mb-0"
                    />
                    <Field
                      required
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      component={FormikInputField}
                      className="form-control form-control-sm mb-0"
                    />
                    <PasswordChecklist
                      className="p-2"
                      rules={[
                        'number',
                        'capital',
                        'lowercase',
                        'minLength',
                        'match',
                      ]}
                      minLength={6}
                      value={values.password}
                      valueAgain={values.confirmPassword}
                    />
                    <div className="font-size-12">
                      <p className="text-left py-2">
                        By clicking the register button you are agreeing to
                        videos
                        <br />
                        <span className="font-weight-bold">
                          Terms and Condition
                        </span>
                      </p>
                    </div>

                    <Button
                      outline
                      type="submit"
                      className="btn-lg px-3 font-size-13 bg-secondary text-white w-100"
                    >
                      Register
                    </Button>
                  </Form>
                );
              }}
            />
          </CardBody>
          <p className="forgot-password text-center border-top pt-4">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </Card>
      </div>
    </div>
  );
};

export default EditorRegistration;
