/*eslint-disable no-duplicate-imports*/
/*eslint-disable import/no-duplicates*/

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthProvider from 'contexts/AuthContext';
import store from 'config/redux';
import AppContainer from 'containers/AppContainer';
import client from './config/apollo';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import './index.scss';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <AuthProvider>
        <AppContainer />
      </AuthProvider>
      <ToastContainer autoClose={2000} />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
