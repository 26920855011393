import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NoData from 'common/NoData';
import Paginator from 'common/Paginator';
import PageLoader from 'common/PageLoader';
import { editorActiveProjectPageInfo } from 'pages/EditorPortal/EditorPortalActions';
import { GET_ACTIVE_PROJECT_LIST } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import { VIDEO_STATUS_UPDATED } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';
import { PILOT_EDITOR_INVITATION } from 'pages/Pilot/PilotPortalGraphQL';
import EditorPortalTable from 'pages/EditorPortal/common/EditorPortalTable';

const ProjectTableProvider = ({
  refetchProjects,
  filters,
  pageCount,
  projects,
  totalCount,
  searchText,
  setFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    location: { pathname },
  } = history;

  const { limit, status } = filters;

  const goToTop = async (filters) => {
    const { page, offset } = filters;
    setFilters(filters);
    await dispatch(
      editorActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: '',
            searchText,
          },
          page,
          offset,
        },
      })
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    goToTop(filters);
  }, []);

  const handleCardClick = async (projectDetails) => {
    const { id } = projectDetails;
    const { page, offset, status } = filters;
    window.scrollTo(0, 0);
    let path = '';
    await dispatch(
      editorActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: pathname,
            searchText,
          },
          page,
          offset,
        },
      })
    );
    if (status === 'NEW INVITE') {
      path = `/editor-portal/projects/project-invitation/${id}`;
      history.push({
        pathname: path,
        from: pathname,
      });
    } else {
      path = `/editor-portal/projects/project-detail/${id}`;
      history.push({ pathname: path, from: pathname });
    }
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page, limit } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const { limit } = filters;
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page, limit } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  return (
    <>
      <div className="editor-table">
        <EditorPortalTable
          rowData={projects}
          listingStatus={status}
          refetchProjects={refetchProjects}
        />
      </div>
      <div className="mb-4 ">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={projects.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </>
  );
};

const ProjectTableWrapper = ({
  filters,
  searchText,
  setFilters,
  setHasNotification,
}) => {
  const queryApi = GET_ACTIVE_PROJECT_LIST;
  const queryVariables = filters;

  const { subscribeToMore, data, loading, error, refetch } = useQuery(
    queryApi,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: VIDEO_STATUS_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.videoStatusUpdated?.projects.length) {
          const { projects: prevProjects } = prev.getActiveProjectList;
          const { videoStatusUpdated } = subscriptionData.data;
          const prevProjectCopy = [...prevProjects];
          const indexOfObj = prevProjectCopy.findIndex(
            (item) => item.id === videoStatusUpdated?.projects[0]?.id
          );
          if (indexOfObj !== -1) {
            prevProjectCopy[indexOfObj] = {
              ...prevProjectCopy[indexOfObj],
              videoStatus:
                videoStatusUpdated?.projects[0]?.videoStatus ||
                prevProjectCopy[indexOfObj].videoStatus,
            };
          }
          return {
            getActiveProjectList: {
              ...prev.getActiveProjectList,
              projects: [...prevProjectCopy],
            },
          };
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_EDITOR_INVITATION,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.userInvited) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;

  if (data) {
    const { getActiveProjectList } = data;

    let projects = null;
    let totalCount = 0;
    if (getActiveProjectList) {
      projects = getActiveProjectList.projects;
      totalCount = getActiveProjectList.totalCount;
    }

    if (!projects.length) {
      const { status } = filters;
      if (filters.status === 'NEW INVITE') setHasNotification(false);
      return (
        <NoData
          customMessage={`You don't have any ${status.toLowerCase()} projects at this moment.`}
        />
      );
    }

    if (filters.status === 'NEW INVITE') setHasNotification(true);

    const pageCount = Math.ceil(totalCount / filters.limit);

    return (
      <ProjectTableProvider
        refetchProjects={refetch}
        filters={filters}
        searchText={searchText}
        setFilters={setFilters}
        pageCount={pageCount}
        projects={projects}
        totalCount={totalCount}
      />
    );
  }
  return null;
};
export default ProjectTableWrapper;
