import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
//
import { reduxStorage } from 'config/storage';
import { AppReducer } from 'containers/AppContainer/AppReducers';
import { managementPortalReducer } from 'pages/ManagementPortal/ManagementPortalReducers';
import { userReducer } from 'pages/Login/LoginReducers';
import { BrokersDirectoryReducer } from 'pages/BrokersDirectory/BrokersDirectoryReducers';
import { reinitializeReduxStore } from 'utils/reinitializeReduxStore';
import { salesReducer } from 'pages/Search/SalesReducers';
import { pmPortalReducer } from 'pages/PMPortal/PMPortalReducers';
import { editorPortalReducer } from 'pages/EditorPortal/EditorPortalReducers';
import { SalesPersonPortalReducer } from 'pages/SalesPersonPortal/SalesPersonPortalReducers';
import { VidPitchReducer } from 'pages/Vidpitch/VidPitchReducers';
import { CrexiBronzeReducer } from 'pages/CrexiBronze/CrexiBronzeReducer';

const editorPortalPreferencesPersistConfig = {
  key: 'editorPortal',
  storage: reduxStorage,
  whitelist: ['activeProjectInfo', 'videoReviewInfo', 'propertyDetail'],
};

const pilotPortalPreferencesPersistConfig = {
  key: 'pilotPortalState',
  storage: reduxStorage,
  whitelist: ['activeProjectInfo', 'videoReviewInfo'],
};

const pmPortalPreferencesPersistConfig = {
  key: 'pmPortal',
  storage: reduxStorage,
  whitelist: ['activeProjectInfo', 'videoReviewInfo'],
};

const managementPreferencesPersistConfig = {
  key: 'managementPortal',
  storage: reduxStorage,
  whitelist: [
    'activeProjectInfo',
    'needMyAttentionInfo',
    'completedProjectInfo',
  ],
};

const appPreferencesPersistConfig = {
  key: 'app',
  storage: reduxStorage,
  whitelist: ['previousPageInfo', 'selectedPortal'],
};

const brokersPreferencesPersistConfig = {
  key: 'brokersDirectory',
  storage: reduxStorage,
  whitelist: ['filters', 'flaggedPageInfo'],
};

const salesPreferencesPersistConfig = {
  key: 'salesPortal',
  storage: reduxStorage,
  whitelist: [
    'searchFilters',
    'plan',
    'eliteAuction',
    'searchedBy',
    'propertyDetail',
  ],
};

const salesPersonPreferencesPersistConfig = {
  key: 'salesPersonPortal',
  storage: reduxStorage,
  whitelist: ['filters', 'flaggedPageInfo'],
};

const vidPitchPreferencesPersistConfig = {
  key: 'vidPitch',
  storage: reduxStorage,
  whitelist: ['globalSearchText', 'flaggedPageInfo'],
};

const crexiBronzePreferencesPersistConfig = {
  key: 'crexiBronze',
  storage: reduxStorage,
  whitelist: [
    'formData',
    'currentPreviewURL',
    'logoAndQrUrl',
    'currentChapter',
    'isChapterOneVisited',
    'isVideoTextChange',
    'videoRenderState',
  ],
};

const applicationReducer = combineReducers({
  app: persistReducer(appPreferencesPersistConfig, AppReducer),
  managementPortal: persistReducer(
    managementPreferencesPersistConfig,
    managementPortalReducer
  ),
  brokersDirectory: persistReducer(
    brokersPreferencesPersistConfig,
    BrokersDirectoryReducer
  ),
  salesPortal: persistReducer(salesPreferencesPersistConfig, salesReducer),
  user: userReducer,
  pmPortal: persistReducer(pmPortalPreferencesPersistConfig, pmPortalReducer),
  editorPortal: persistReducer(
    editorPortalPreferencesPersistConfig,
    editorPortalReducer
  ),
  salesPersonPortal: persistReducer(
    salesPersonPreferencesPersistConfig,
    SalesPersonPortalReducer
  ),
  vidPitch: persistReducer(vidPitchPreferencesPersistConfig, VidPitchReducer),
  crexiBronze: persistReducer(
    crexiBronzePreferencesPersistConfig,
    CrexiBronzeReducer
  ),
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    const reinitializedState = reinitializeReduxStore();

    return applicationReducer(reinitializedState, action);
  }

  return applicationReducer(state, action);
};

export default rootReducer;
