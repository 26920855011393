import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  PROJECT_STATUS,
  PROJECT_TASK_HEADER,
} from 'pages/ManagementPortal/constant';
import { UPDATE_BRONZE_PROJECT_TASK_STATUS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const TaskStatusColumnHandler = ({
  lastStatus,
  sectionName,
  planName,
  asanaTaskId,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const { auth } = useContext(authContext);

  const setInitialStatus = () => {
    setSelectedValue(
      ...Object.values(PROJECT_STATUS).filter((val) => val === lastStatus)
    );
  };

  const [updateBronzeProjectTaskStatus] = useMutation(
    UPDATE_BRONZE_PROJECT_TASK_STATUS,
    {
      onCompleted: async () => {
        toast.success('Status updated successfully');
      },
      onError: (error) => {
        toast.error('Status update failed. Please try again.');
        setInitialStatus();
      },
    }
  );

  useEffect(() => {
    setInitialStatus();
  }, []);

  const handleDropdownChange = (event) => {
    const { value } = event.target;
    updateBronzeProjectTaskStatus({
      variables: {
        crexiTaskStatus: value,
        portalTaskId: asanaTaskId,
      },
    });
    setSelectedValue(value);
  };

  const renderDropdownOptions = () => {
    return Object.keys(PROJECT_STATUS).map((key) => (
      <option key={key} value={PROJECT_STATUS[key]}>
        {PROJECT_STATUS[key]}
      </option>
    ));
  };

  if (
    sectionName === PROJECT_TASK_HEADER.PRODUCTION_APPROVALS &&
    planName === 'false' && // Add BRONZE here
    auth?.data?.role === ROLES.VIDEOMS_EDITOR
  ) {
    return (
      <select
        className="status-select"
        value={selectedValue}
        onChange={handleDropdownChange}
      >
        {renderDropdownOptions()}
      </select>
    );
  }

  const classString = `${lastStatus
    .replace(/[^\w]/g, '-')
    .toLowerCase()}-badge`;
  return (
    <span title={lastStatus} className={`badge text-truncate ${classString}`}>
      {lastStatus}
    </span>
  );
};

const StatusFormatter = (rowContent, { ...rest }) => {
  return <TaskStatusColumnHandler {...rest} />;
};

export default StatusFormatter;
