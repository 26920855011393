import React from 'react';
import { find } from 'lodash';
import { Badge, Col, Row } from 'reactstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoData from 'common/NoData';
import { dateTimeFormatter } from 'utils/helper';
import CurrencyFormatter from 'pages/SalesPersonPortal/common/CurrencyFormater';

const Packages = ({
  deal: { packages, dealPayment, subTotal, discount, total },
}) => {
  const AddOns = 'add-ons';
  const BasePackage = 'base-package';

  let dealName = '';
  let timeValue = 0;

  if (find(dealPayment, { name: 'Pulled From Bundle', checked: true })) {
    const time = find(dealPayment, {
      name: 'Pulled From Bundle',
      checked: true,
    });
    dealName = 'Pulled From Bundle';
    timeValue = time?.timestamp;
  } else if (find(dealPayment, { name: 'Paid', checked: true })) {
    const time = find(dealPayment, {
      name: 'Paid',
      checked: true,
    });
    timeValue = time?.timestamp;
    dealName = 'paid';
  } else if (find(dealPayment, { name: 'Not Paid', checked: true })) {
    const time = find(dealPayment, {
      name: 'Not Paid',
      checked: true,
    });
    timeValue = time?.timestamp;
    dealName = 'Not Paid';
  } else {
    const time = find(dealPayment, {
      name: 'Invoiced',
    });
    timeValue = time?.timestamp;
    dealName = 'Invoiced';
  }

  const renderPaymentStatus = () => {
    return (
      <Row className="font-size-12 m-0">
        <Col md={6} className="p-0">
          Status : {dealName}
        </Col>
        <Col md={6} className="p-0 text-right">
          {dateTimeFormatter(timeValue, 'MM/DD/YYYY hh:mm A')}
        </Col>
      </Row>
    );
  };

  const renderSubTotalAndDiscount = () => {
    return (
      <>
        <Row className="p-1">
          <Col className="font-size-14">Subtotal</Col>
          {subTotal !== 0 ? (
            <Col className="font-size-12 text-right">
              <CurrencyFormatter price={subTotal} />
            </Col>
          ) : (
            <Col className="font-size-12 text-right">0</Col>
          )}
        </Row>
        <Row className="p-1">
          <Col className="font-size-12">
            <Badge
              color="warning"
              className="border-radius-8px font-weight-500 px-2 py-1"
            >
              Discount Applied
            </Badge>
          </Col>
          {discount !== 0 ? (
            <Col className="font-size-12 text-right">
              - <CurrencyFormatter price={discount} />
            </Col>
          ) : (
            <Col className="font-size-12 text-right">0</Col>
          )}
        </Row>
        <hr />
        <Row className="p-1">
          <Col className="font-size-16">Total Amount</Col>
          <Col className="font-size-16 text-right text-success font-weight-bold">
            <CurrencyFormatter price={total} />
          </Col>
        </Row>
      </>
    );
  };

  const renderBasePackages = () => {
    if (
      packages &&
      packages.length &&
      find(packages, { lineitemType: BasePackage })
    ) {
      return packages.map((packageItem) => {
        const {
          id,
          lineitemName,
          lineitemType,
          lineitemQuantity,
          lineitemDiscount,
          lineitemPrice,
        } = packageItem;
        return (
          lineitemType === BasePackage &&
          parseFloat(lineitemQuantity, 10) > 0 && (
            <div
              className="base-package-color px-3 py-1 font-size-14 font-weight-500 sales-summery-price"
              key={id}
            >
              <Row>
                <Col className="text-truncate" title={lineitemName}>
                  {lineitemName}
                </Col>
                <Col className="text-right">
                  {lineitemQuantity} X{' '}
                  <CurrencyFormatter price={lineitemPrice} />
                </Col>
              </Row>
              <div className="discount-line-item font-size-10px p-0 d-flex justify-content-between">
                <span>Discount (per unit)</span>
                {lineitemDiscount && (
                  <CurrencyFormatter price={lineitemDiscount} />
                )}
                {!lineitemDiscount && <span> ${0}</span>}
              </div>
            </div>
          )
        );
      });
    }
    return <NoData customMessage="No Base Package Available" />;
  };

  const renderAddOnItems = () => {
    if (
      packages &&
      packages.length &&
      find(packages, { lineitemType: AddOns })
    ) {
      return packages.map((packageItem) => {
        const {
          id,
          lineitemName,
          lineitemQuantity,
          lineitemType,
          lineitemDiscount,
          lineitemPrice,
        } = packageItem;
        return (
          lineitemType === AddOns &&
          parseFloat(lineitemQuantity, 10) > 0 && (
            <li key={id} className="border-bottom py-3 font-size-12">
              <div>
                <FontAwesomeIcon
                  icon={faCircle}
                  color="#BBB8D4"
                  className="mr-2 mb-1"
                />
                <span className="line-item-name" title={lineitemName}>
                  {lineitemName}
                </span>
                <span className="float-right font-weight-bold">
                  {lineitemQuantity} X{' '}
                  <CurrencyFormatter price={lineitemPrice} />
                </span>
              </div>
              <div className="discount-line-item font-size-10px d-flex justify-content-between">
                <span>Discount (per unit)</span>
                {lineitemDiscount && (
                  <CurrencyFormatter price={lineitemDiscount} />
                )}
                {!lineitemDiscount && <span> ${0}</span>}
              </div>
            </li>
          )
        );
      });
    }

    return <NoData customMessage="No Add Ons Available" />;
  };

  return (
    <div className="side-summery">
      {renderBasePackages()}

      <div>
        <div className="border-bottom py-3">
          <span className="font-size-14 font-weight-500">Add-ons</span>
        </div>
        <ul className="list-unstyled side-summery-height">
          {renderAddOnItems()}
        </ul>
      </div>

      <div>
        <div>{renderSubTotalAndDiscount()}</div>
        {dealPayment && dealPayment.length > 0 && (
          <div className="payment-status p-2">{renderPaymentStatus()}</div>
        )}
      </div>
    </div>
  );
};

export default Packages;
