import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const BoundaryAlert = ({ handleBoundaryAlert, modalAlert }) => {
  return (
    <div>
      <Modal isOpen={modalAlert} className="modal-dialog-centered">
        <ModalBody>
          <div className="text-center p-2">
            <p className="font-size-16 ">
              Do you want to proceed without property boundary image ?
            </p>
            <div className="text-right">
              <Button
                outline
                size="sm"
                color="secondary"
                onClick={() => handleBoundaryAlert('no')}
                className="px-4 font-weight-500 mr-2"
              >
                No
              </Button>
              <Button
                outline
                size="sm"
                color="primary"
                onClick={() => handleBoundaryAlert('yes')}
                className="px-4 font-weight-500"
              >
                Yes
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BoundaryAlert;
