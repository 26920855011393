import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage, Field } from 'formik';

const FormikCheckboxField = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  onChange,
  ...props
}) => {
  const { id, label } = props;
  const { name } = fields;

  const handleChange = (e) => {
    onChange(e, name);
  };
  return (
    <FormGroup className="flex-grow-1 clearfix">
      <Input
        {...fields}
        {...props}
        onChange={handleChange}
        invalid={Boolean(touched[name] && errors[name])}
        style={{
          position: 'relative',
          marginLeft: 0,
          marginRight: '15px',
        }}
      />
      <Label for={id} className="label-color">
        {label}
      </Label>
      <ErrorMessage
        name={fields.name}
        render={(msg) => <div className="text-danger font-size-12">{msg}</div>}
      />
    </FormGroup>
  );
};
export default FormikCheckboxField;
