export const appInitialState = {
  selectedPortal: '',
  previousPageInfo: {
    previousRoutes: {
      path: '',
      param: '',
    },
    page: 1,
    offset: 0,
  },
  attentionDrawerInfo: {
    isOpen: false,
    heading: '',
    drawerName: '',
  },
};
