import { salesPersonPortalInitialState } from 'constants/reduxInitialStates/salesPersonPortalState';
import * as actions from './SalesPersonPortalActionTypes';

export const SalesPersonPortalReducer = (
  state = salesPersonPortalInitialState,
  action
) => {
  switch (action.type) {
    case actions.DEALS_DETAIL_INFO:
      return {
        ...state,
        dealsDetailsInfo: {
          ...action.payload.dealsDetailsInfo,
        },
      };
    default:
      return state;
  }
};
