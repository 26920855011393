import moment from 'moment';
import * as Yup from 'yup';

export const VIDPITCH_VIDEO_PRICE = '$49';
export const COUNTDOWN_TIMER_IN_MINUTES = 4;
export const PIPELINE_STATUS = {
  COMPLETE: 'complete',
  INPROGRESS: 'inprogress',
};
export const MAX_CHARACTER_LIMIT = 27;
const verifyField = (customValue) => {
  if (customValue && customValue.trim()) {
    return true;
  }
  return false;
};

export const convertFormValueToFormData = (
  formValues,
  chapterNumb,
  chapterURL,
  logoURL
) => {
  const tempFormData = [
    {
      label: `IMAGE_${chapterNumb - 1}`,
      attributeValue: `IMAGE_${chapterNumb - 1}`,
      value: chapterURL,
    },
  ];

  if (chapterNumb === 1) {
    tempFormData.push({
      label: 'BROKER_COMPANY_LOGO_URL',
      attributeValue: 'BROKER_COMPANY_LOGO_URL',
      value: logoURL,
    });
  }

  Object.keys(formValues).forEach((formItem) => {
    tempFormData.push({
      label: formItem.replaceAll('_', ' '),
      attributeValue: formItem,
      value: formValues[formItem],
    });
  });

  return tempFormData;
};

export const VALIDATION_SCHEMA_CHAPTER_THREE = Yup.object().shape(
  {
    CUSTOM_HEADING_1: Yup.string().when(['CUSTOM_VALUE_1'], {
      is: (CUSTOM_VALUE_1) => verifyField(CUSTOM_VALUE_1),
      then: Yup.string()
        .trim()
        .required('custom heading is required, if custom value is provided.')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    CUSTOM_VALUE_1: Yup.string().when(['CUSTOM_HEADING_1'], {
      is: (CUSTOM_HEADING_1) => verifyField(CUSTOM_HEADING_1),
      then: Yup.string()
        .trim()
        .required('custom value is required, if custom heading is provided')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    ASSET_YEAR_BUILT: Yup.string()
      .trim()
      .required('Built year is required')
      .max(12, 'Maximum 12 character allowed'),
  },
  [['CUSTOM_HEADING_1', 'CUSTOM_VALUE_1']]
);

export const VALIDATION_SCHEMA_CHAPTER_FOUR = Yup.object().shape(
  {
    CUSTOM_HEADING_2: Yup.string().when(['CUSTOM_VALUE_2'], {
      is: (CUSTOM_VALUE_2) => verifyField(CUSTOM_VALUE_2),
      then: Yup.string()
        .trim()
        .required('custom heading is required, if custom value is provided.')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    CUSTOM_VALUE_2: Yup.string().when(['CUSTOM_HEADING_2'], {
      is: (CUSTOM_HEADING_2) => verifyField(CUSTOM_HEADING_2),
      then: Yup.string()
        .trim()
        .required('custom value is required, if custom heading is provided')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    CUSTOM_HEADING_3: Yup.string().when(['CUSTOM_VALUE_3'], {
      is: (CUSTOM_VALUE_3) => verifyField(CUSTOM_VALUE_3),
      then: Yup.string()
        .trim()
        .required('custom heading is required, if custom value is provided.')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    CUSTOM_VALUE_3: Yup.string().when(['CUSTOM_HEADING_3'], {
      is: (CUSTOM_HEADING_3) => verifyField(CUSTOM_HEADING_3),
      then: Yup.string()
        .trim()
        .required('custom value is required, if custom heading is provided')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
  },
  [
    ['CUSTOM_VALUE_2', 'CUSTOM_HEADING_2'],
    ['CUSTOM_VALUE_3', 'CUSTOM_HEADING_3'],
  ]
);

export const VALIDATION_SCHEMA_CHAPTER_FIVE = Yup.object().shape(
  {
    CUSTOM_HEADING_4: Yup.string().when(['CUSTOM_VALUE_4'], {
      is: (CUSTOM_VALUE_4) => verifyField(CUSTOM_VALUE_4),
      then: Yup.string()
        .trim()
        .required('custom heading is required, if custom value is provided.')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
    CUSTOM_VALUE_4: Yup.string().when(['CUSTOM_HEADING_4'], {
      is: (CUSTOM_HEADING_4) => verifyField(CUSTOM_HEADING_4),
      then: Yup.string()
        .trim()
        .required('custom value is required, if custom heading is provided')
        .max(MAX_CHARACTER_LIMIT, `Maximum ${MAX_CHARACTER_LIMIT} character allowed`),
      otherwise: Yup.string().trim(),
    }),
  },
  [['CUSTOM_VALUE_4', 'CUSTOM_HEADING_4']]
);

export const phoneRegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

export const convertDateWithUTC = (date) => {
  return moment.utc(date).format('MM/DD/YYYY');
};
