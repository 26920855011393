import React, { useState } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { BRONZE_DOWNLOAD_EXCEL_PATH } from 'pages/EditorPortal/constant';
import { START_BRONZE_TASKS } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';
import LoadingButton from 'common/LoadingButton';
import { useSelector } from 'react-redux';

const BronzeAssetActions = ({
  projectId,
  projectName,
  trackStatus,
  dataSource,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const { videoRenderState } = useSelector((state) => state.crexiBronze);

  const [startBronzeTasks] = useMutation(START_BRONZE_TASKS, {
    onCompleted: () => {
      setIsLoading(false);
      history.push(`/crexi-bronze/${projectId}`, {
        dataSource,
      });
    },
    onError: ({ message }) => {
      setIsLoading(false);
      toast.error(message);
    },
  });

  const navigateToChapters = async () => {
    try {
      setIsLoading(true);
      await startBronzeTasks({
        variables: {
          projectId,
        },
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const downloadFile = (fileKey) => {
    setIsLoading(true);
    setHasDownloaded(true);
    const data = {
      folder: fileKey,
    };
    const config = {
      responseType: 'blob',
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-zip-download?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileKey}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsLoading(false);
        setHasDownloaded(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasDownloaded(false);
        toast.error('Failed to download the asset.');
      });
  };

  return (
    <div>
      <LoadingButton
        outline
        size="sm"
        color="primary"
        key="downloadProjectData"
        className="mr-2 mb-2 btn-block"
        disabled={isLoading}
        loading={hasDownloaded}
        onClick={() =>
          downloadFile(`${projectName}${BRONZE_DOWNLOAD_EXCEL_PATH}`)
        }
      >
        Download Project Data
      </LoadingButton>
      <Button
        size="sm"
        className="btn-block"
        key="goToProject"
        color="primary"
        disabled={
          isLoading ||
          trackStatus === 'COMPLETED' ||
          videoRenderState?.showVideoRenderTime
        }
        onClick={() => navigateToChapters()}
      >
        Go To Project
      </Button>
      <br />
    </div>
  );
};

export default BronzeAssetActions;
