import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';

import { GET_NOTIFICATIONS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import NoData from 'common/NoData';
import {
  useTasSubscription,
  useTaskActionSubscription,
} from 'pages/ManagementPortal/hooks';
import PageLoader from 'common/PageLoader';
import NotificationItem from './components/NotificationItem';

const AttentionNotifications = ({
  refetch,
  notifications,
  setFilters,
  filters,
  totalCount,
  setItems,
  loading,
}) => {
  const taskSubscription = useTasSubscription();
  const taskActionSubscription = useTaskActionSubscription();
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const fetchMoreData = () => {
    if (notifications.length !== totalCount) {
      setHasMoreItems(true);
      setFilters({ ...filters, offset: filters.offset + filters.limit });
    } else {
      setHasMoreItems(false);
    }
  };
  useEffect(() => {
    refetch();
  }, [taskSubscription, taskActionSubscription]);

  return (
    <InfiniteScroll
      height={800}
      next={fetchMoreData}
      hasMore={hasMoreItems}
      dataLength={notifications.length}
      loader={
        loading && (
          <li className="mt-4 text-center">
            <span className="text-dark">Loading...</span>
          </li>
        )
      }
      endMessage={
        <p className="text-center">{!loading && 'No more data to load.'}</p>
      }
    >
      <div>
        {notifications.map((row, index) => {
          return (
            <NotificationItem
              refetch={refetch}
              key={`${index + 1}`}
              {...row}
              setFilters={setFilters}
              filters={filters}
              setItems={setItems}
            />
          );
        })}
        <div style={{ width: '100%', height: '70px' }} />
      </div>
    </InfiniteScroll>
  );
};

const FILTER = {
  limit: 10,
  offset: 0,
};
const AttentionNotificationWrapper = () => {
  const [filters, setFilters] = useState(FILTER);
  const [items, setItems] = useState([]);
  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: filters,
    fetchPolicy: 'cache-first',
  });

  if (error) return <NoData customMessage={error.message} />;

  useEffect(() => {
    if (!loading && data?.getNotifications?.notifications) {
      setItems([...items, ...data.getNotifications.notifications]);
    }
  }, [data, loading]);

  if (items.length) {
    return (
      <AttentionNotifications
        refetch={refetch}
        setItems={setItems}
        notifications={items}
        totalCount={data?.getNotifications?.totalCount}
        setFilters={setFilters}
        filters={filters}
        loading={loading}
      />
    );
  }

  return (
    <div className="mx-3 my-3">
      {loading ? <PageLoader /> : 'No new notifications'}
    </div>
  );
};

export default AttentionNotificationWrapper;
