import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PortalDropdown from 'common/PortalDropdown';
import HeaderUserDropdown from 'common/HeaderUserDropdown';

const Header = ({ heading, showBackButton }) => {
  const history = useHistory();

  const goBackToList = () => {
    history.go(-1);
  };

  return (
    <>
      <Row className="content-header-row py-2 align-items-center mx-n4">
        <Col sm="8" className="d-flex align-items-center">
          {showBackButton ? (
            <Button
              color="link"
              className="back-btn color-inherit px-0"
              onClick={goBackToList}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
              {heading}
            </Button>
          ) : (
            <h6 className="px-2 mb-0 show-elipsis w-100">{heading}</h6>
          )}
        </Col>
        <Col sm="auto" className="ml-auto position-relative">
          <div className="d-flex align-items-center">
            <PortalDropdown />
            <HeaderUserDropdown />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Header;
