import React, { useEffect, useState } from 'react';
import { find, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  HIGH_PRIORITY_UPDATES,
  HIGH_PRIORITY_COUNT_SUBSCRIPTION,
} from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';

export const highPriorityUpdatesContext = React.createContext();

const HighPriorityUpdatesProvider = ({ children }) => {
  const [highPriorityUpdates, setCount] = useState(0);

  const { loading, error, data, subscribeToMore } = useQuery(
    HIGH_PRIORITY_UPDATES,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  useEffect(() => {
    if (data && data.highPriorityUpdates) {
      setCount(data.highPriorityUpdates);
    }
  }, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: HIGH_PRIORITY_COUNT_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || isEmpty(prev)) return '';
        if (!subscriptionData.data) return prev;
        const { highPriorityUpdates } =
          subscriptionData.data.highPriorityCountDetails.data;
        return { highPriorityUpdates };
      },
    });
    return () => unsubscribe();
  }, []);

  return (
    <highPriorityUpdatesContext.Provider
      value={{
        error,
        loading,
        highPriorityUpdates,
      }}
    >
      {children}
    </highPriorityUpdatesContext.Provider>
  );
};

export default HighPriorityUpdatesProvider;
