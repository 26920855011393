import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const CodeSnippet = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    toast.success('Embedded Code Copied!!');
  };

  return (
    <CopyToClipboard text={code} onCopy={handleCopy} className="d-inline-block">
      <span
        type="button"
        color="link"
        className="d-inline-block"
        title="Copy Embed Code"
      >
        Copy embed code
      </span>
    </CopyToClipboard>
  );
};

export default CodeSnippet;
