import { appInitialState } from 'constants/reduxInitialStates/appState';
import { salesInitialState } from 'constants/reduxInitialStates/salesState';
import { managementInitialState } from 'constants/reduxInitialStates/managemenState';
import { brokersDirectoryInitialState } from 'constants/reduxInitialStates/brokersDirectyState';
import { pmPortalInitialState } from 'constants/reduxInitialStates/pmPortalState';
import { editorPortalInitialState } from 'constants/reduxInitialStates/editorPortalState';
import { salesPersonPortalInitialState } from 'constants/reduxInitialStates/salesPersonPortalState';
import { vidPitchInitialState } from 'constants/reduxInitialStates/vidPitchState';
import { crexiBronzeInitialState } from 'constants/reduxInitialStates/crexiBronzeState';

export const reinitializeReduxStore = () => {
  return {
    app: appInitialState,
    salesPortal: salesInitialState,
    managementPortal: managementInitialState,
    brokersDirectory: brokersDirectoryInitialState,
    pmPortal: pmPortalInitialState,
    editorPortal: editorPortalInitialState,
    salesPersonPortal: salesPersonPortalInitialState,
    vidPitch: vidPitchInitialState,
    crexiBronze: crexiBronzeInitialState,
  };
};
