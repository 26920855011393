import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
//
import { ROLES } from 'constants/role';
import { USER_INVITE } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import FormikInputField from 'components/Fields/FormikInputField';
import { validationSchemaPilotInvite } from '../../validationSchema';

const InviteModal = ({
  sendEmailModal,
  toggleSendEmailModal,
  projectId,
  refetchProjectDetails,
}) => {
  const InvitePilotInitialValue = {
    firstName: '',
    companyName: null,
    projectId,
    email: '',
  };
  const [invitePilot] = useMutation(USER_INVITE, {
    onCompleted: async ({ userInvite: { message } }) => {
      refetchProjectDetails();
      toggleSendEmailModal(false);
      toast.success(message);
    },
    onError: (e) => {
      toggleSendEmailModal(false);
      toast.error(e.message);
    },
  });

  const saveFlaggedData = async ({
    firstName,
    companyName,
    projectId,
    email,
  }) => {
    const modifiedData = {
      firstName: firstName.trim(),
      companyName: companyName ? companyName.trim() : '',
      projectId,
      email: email.trim().toLowerCase(),
      role: ROLES.VIDEOMS_PILOT,
    };
    try {
      await invitePilot({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <>
      <Modal
        isOpen={sendEmailModal}
        toggle={() => toggleSendEmailModal(false)}
        className="modal-dialog-centered"
      >
        <ModalHeader>Invite Pilot</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={InvitePilotInitialValue}
            validationSchema={validationSchemaPilotInvite}
            onSubmit={(values) => {
              saveFlaggedData(values);
            }}
            children={({ values }) => {
              return (
                <Form>
                  <Field
                    component={FormikInputField}
                    required
                    label="Pilot Name"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter Pilot Name"
                    className="form-control form-control-sm f-12"
                  />
                  <Field
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Enter Company Name"
                    className="form-control form-control-sm f-12"
                    component={FormikInputField}
                    label="Company Name"
                  />
                  <Field
                    required
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Enter Pilot Email"
                    className="form-control form-control-sm f-12"
                    component={FormikInputField}
                    label="Email"
                  />

                  <ModalFooter className="bg-grey-upload">
                    <Button
                      outline
                      color="primary"
                      className="mr-2 btn-sm px-4 font-size-13"
                      onClick={() => toggleSendEmailModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-sm px-4 font-size-13"
                    >
                      Send Invite
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
export default InviteModal;
