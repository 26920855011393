import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import { Helmet } from 'react-helmet';
import SearchResults from './components/SearchResults';

const Search = () => {
  const history = useHistory();

  const { auth } = useContext(authContext);
  const {
    data: { role },
  } = auth;

  useEffect(() => {
    if (role === ROLES.OPERATOR) {
      history.push('/brokers-directory');
    }
  }, [role]);

  return (
    <Container className="themed-container" fluid>
      <Helmet  defaultTitle='Sales Portal - VidTech' />
      <Row>
        <SearchResults />
      </Row>
    </Container>
  );
};

export default Search;
