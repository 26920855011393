import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjects($trackStatus: String!, $limit: Int!, $offset: Int!) {
    getProjects(trackStatus: $trackStatus, limit: $limit, offset: $offset)
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query getTaskDetails($projectId: Int!) {
    getTaskDetails(projectId: $projectId)
  }
`;

export const GET_ATTENTION_PROJECT = gql`
  query getAttentionProjects($limit: Int!, $offset: Int!) {
    getAttentionProjects(limit: $limit, offset: $offset)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications($limit: Int, $offset: Int) {
    getNotifications(limit: $limit, offset: $offset)
  }
`;
export const PROJECT_SEARCH = gql`
  query projectSearch(
    $searchText: String!
    $status: String!
    $limit: Int!
    $offset: Int!
  ) {
    projectSearch(
      searchText: $searchText
      status: $status
      limit: $limit
      offset: $offset
    )
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus(
    $actionValue: Boolean!
    $status: String!
    $id: Int!
  ) {
    updateNotificationStatus(
      actionValue: $actionValue
      status: $status
      id: $id
    )
  }
`;

export const TASK_NOTIFICATION_SUBSCRIPTION = gql`
  subscription taskNotification {
    taskNotification
  }
`;

export const TASK_UPDATE_SUBSCRIPTION = gql`
  subscription taskUpdated {
    taskUpdated
  }
`;

export const NOTIFICATION_ACTION_SUBSCRIPTION = gql`
  subscription notificationAction {
    notificationAction
  }
`;

export const PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT = gql`
  mutation projectAcceptedByEditorOrPilot(
    $projectId: Int!
    $status: String!
    $flag: String!
    $editorId: Int
  ) {
    projectAcceptedByEditorOrPilot(
      projectId: $projectId
      status: $status
      flag: $flag
      editorId: $editorId
    )
  }
`;

export const EDITOR_PROJECT_DETAILS = gql`
  query editorProjectDetails($projectId: Int!) {
    editorProjectDetails(projectId: $projectId)
  }
`;

export const VIDEO_STATUS_UPDATED = gql`
  subscription videoStatusUpdated {
    videoStatusUpdated
  }
`;

export const START_BRONZE_TASKS = gql`
  mutation startBronzeTasks($projectId: Int!) {
    startBronzeTasks(projectId: $projectId)
  }
`;

// ========================= PM portal =============
export const GET_ACTIVE_PROJECT_LIST = gql`
  query getActiveProjectList($status: String!, $limit: Int!, $offset: Int!) {
    getActiveProjectList(status: $status, limit: $limit, offset: $offset)
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query getProjectDetails($projectId: Int!) {
    getProjectDetails(projectId: $projectId)
  }
`;

export const GET_AWS_FOLDERS = gql`
  query getAwsFolders($projectId: Int!) {
    getAwsFolders(projectId: $projectId)
  }
`;

export const LIST_REVIEW_ASSET_COMMENTS = gql`
  query listReviewAssetComments($folderId: Int!, $version: String) {
    listReviewAssetComments(folderId: $folderId, version: $version)
  }
`;

export const UPDATE_REVIEW_ASSET_COMMENT_STATUS = gql`
  mutation updateReviewAssetCommentStatus(
    $isMarkAll: Boolean!
    $projectId: Int!
    $folderId: Int!
    $commentIds: [String!]!
  ) {
    updateReviewAssetCommentStatus(
      isMarkAll: $isMarkAll
      projectId: $projectId
      folderId: $folderId
      commentIds: $commentIds
    )
  }
`;

export const ACCEPTED_BROKER_COMMENT = gql`
  subscription acceptedBrokerComment {
    acceptedBrokerComment
  }
`;

export const REVIEW_ASSET_COMMENT_STATUS = gql`
  subscription reviewAssetCommentStatus {
    reviewAssetCommentStatus
  }
`;
