import { useSubscription } from '@apollo/client';
import { TASK_UPDATE_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';

function useTaskUpdateSubscription() {
  const { data, loading, error } = useSubscription(TASK_UPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
  });
  if (loading) return null;
  if (error) return error;
  if (data) {
    const {
      taskUpdated: { data: updatedTaskData },
    } = data;
    return updatedTaskData;
  }
  return null;
}

export default useTaskUpdateSubscription;
