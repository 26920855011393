import React from 'react';
import { Navbar } from 'reactstrap';
import { ROLES } from 'constants/role';
import { useSelector } from 'react-redux';
import HeaderUserDropdown from 'common/HeaderUserDropdown';
import buildingImage from 'assets/building-2-line.svg';
// import PortalDropdown from 'common/PortalDropdown';
import SaveToDraftModal from '../SaveToDraftModal';

const CrexiBronzeHeader = ({ userRole }) => {
  const { isVideoTextChange } = useSelector((state) => state.crexiBronze);
  const { propertyDetail } = useSelector((state) =>
    userRole === ROLES.VIDEOMS_EDITOR || isVideoTextChange
      ? state?.editorPortal
      : state?.salesPortal
  );

  return (
    <header className="shadow-sm position-relative z-index-99">
      <Navbar className="py-2 pr-0 bg-white" expand="md">
        <div className="d-flex align-items-center">
          <SaveToDraftModal source="Header" userRole={userRole} />
          <img
            src={buildingImage}
            alt="buildingLogo"
            width={18}
            className="ml-4"
          />
          <span className="font-size-14  px-3 font-weight-bold">
            {propertyDetail?.name || 'NA'}
          </span>
          {!isVideoTextChange && (
            <>
              <div className="seprator" />
              <div className="d-flex align-items-center px-3">
                <span className="font-size-12" style={{ color: '#4D4965' }}>
                  Crexi URL :
                </span>
                <a
                  href={
                    propertyDetail?.crexiUrl ??
                    'https://www.crexi.com/properties/'
                  }
                  className="ml-1 font-size-12 crexi-link"
                  rel="noreferrer"
                  target="_blank"
                >
                  {propertyDetail?.crexiUrl || 'NA'}
                </a>
              </div>
            </>
          )}
        </div>
        <div className="d-flex ml-auto position-relative">
          {/* <PortalDropdown /> */}
          {userRole && <HeaderUserDropdown />}
        </div>
      </Navbar>
    </header>
  );
};

export default CrexiBronzeHeader;
