import { gql } from '@apollo/client';

export const LIST_DEALS = gql`
  query deals($input: DealsInput!) {
    deals(input: $input)
  }
`;

export const DEAL_COUNT = gql`
  query Query {
    dealCount
  }
`;

export const DEAL_DETAILS = gql`
  query deal($projectId: Int!) {
    deal(projectId: $projectId)
  }
`;

export const SAVE_DEAL_NOTES = gql`
  mutation SaveDealNotes(
    $projectId: Int!
    $customerNotes: String
    $pmNotes: String
  ) {
    saveDealNotes(
      projectId: $projectId
      customerNotes: $customerNotes
      pmNotes: $pmNotes
    )
  }
`;

export const DEAL_PAYMENT = gql`
  mutation UpdateDealPayment(
    $updateDealPaymentProjectId3: Int!
    $updateDealPaymentPayment2: [DealPaymentInput]!
  ) {
    updateDealPayment(
      projectId: $updateDealPaymentProjectId3
      payment: $updateDealPaymentPayment2
    )
  }
`;

export const PILOT_ASSET_FOLDERS_LISTING = gql`
  query pilotAssetFoldersListing(
    $projectId: Int!
    $folderTypes: String!
    $isMarkedCompleted: Boolean
    $status: String
  ) {
    pilotAssetFoldersListing(
      projectId: $projectId
      folderTypes: $folderTypes
      isMarkedCompleted: $isMarkedCompleted
      status: $status
    )
  }
`;

export const GET_AWS_FILES = gql`
  query getAwsFiles($filePath: String!) {
    getAwsFiles(filePath: $filePath)
  }
`;

export const SYNC_DEAL_ASSOCIATION = gql`
  mutation syncDealAssociations($dealId: Int!) {
    syncDealAssociations(dealId: $dealId)
  }
`;

export const SYNC_DEAL_ASSOCIATION_SUBSCRIPTION = gql`
  subscription syncDealAssociation {
    syncDealAssociation
  }
`;
