import * as actions from './ManagementPortalActionTypes';

export const addActiveProjectPageInfo = (payload) => ({
  type: actions.ADD_ACTIVE_PROJECT_PAGE_INFO,
  payload,
});

export const addNeedAttentionPageInfo = (payload) => ({
  type: actions.ADD_NEED_ATTENTION_PAGE_INFO,
  payload,
});
export const addCompletedPageInfo = (payload) => ({
  type: actions.ADD_COMPLETED_PAGE_INFO,
  payload,
});
export const resetManagementReducer = (payload) => ({
  type: actions.RESET_MANAGEMENT_REDUCER,
  payload,
});
