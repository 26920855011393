import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PlansTable from './components/PlansTable';

const PlansModal = ({ showPlansModal, togglePlansModal, ...rest }) => {
  return (
    <div>
      <Modal
        isOpen={showPlansModal}
        toggle={togglePlansModal}
        centered
        className="plans-modal"
      >
        <ModalBody className="mb-0 px-4">
          <ModalHeader
            toggle={togglePlansModal}
            className="close-btn-detail-plan p-0"
          >
            <h5 className="mb-0 font-size-18">Packages</h5>
          </ModalHeader>

          <PlansTable {...rest} togglePlansModal={togglePlansModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default PlansModal;
