import React, { useContext } from 'react';
import { Card } from 'reactstrap';

import { getPlanContext } from 'contexts/GetPlanContext';
import PlansCardContent from './components/PlansCardContent';

const PlansCard = () => {
  const contextData = useContext(getPlanContext);
  const { loading, error, planData } = contextData;
  if (loading) return <span className="my-auto text-center ">Loading ...</span>;

  if (error)
    return <span className="my-auto text-center ">{error.message}</span>;

  if (planData && planData.defaultPlan) {
    const { defaultPlan } = planData;
    return <PlansCardContent defaultPlan={defaultPlan} />;
  }
  return null;
};

const PlansCardWrapper = () => {
  return (
    <Card className="border-radius-8px border-0 grey-card card-block plan-selector">
      <PlansCard />
    </Card>
  );
};

export default PlansCardWrapper;
