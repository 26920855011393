import React, { useEffect, useState } from 'react';
import { Button, Collapse, Input, Nav } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ProjectListProvider from './components/ProjectListProvider';

const FILTER = {
  searchString: '',
  limit: 10,
  offset: 0,
  isEditorPortal: true,
};
const ProjectAssetsNav = ({ onClickActive, isActive }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [filters, setFilters] = useState(FILTER);
  const [changeInSearchString, setChangeInSearchString] = useState(false);

  const toggleSidebarList = () => setSidebarIsOpen(!sidebarIsOpen);
  const handleChange = async (e) => {
    const searchStr = e?.target?.value?.trim();
    setFilters({
      ...FILTER,
      searchString: searchStr,
    });
    setChangeInSearchString(searchStr !== filters.searchString);
  };

  useEffect(() => {
    if (!sidebarIsOpen) {
      setFilters(FILTER);
    }
  }, [sidebarIsOpen]);

  return (
    <div className="frame-io-tabs">
      <Nav vertical className="tab-ul row" onClick={() => onClickActive(1)}>
        <Button
          onClick={toggleSidebarList}
          id="project-asset-toggler"
          className={`nav-link ${isActive === 1 ? 'active-state' : ''}`}
          style={{ background: '#e8e8e8' }}
        >
          Project Assets
          {sidebarIsOpen ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              color="#000"
              className="float-right"
              size="lg"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              color="#000"
              className="float-right mt-1"
              size="lg"
            />
          )}
        </Button>

        {sidebarIsOpen ? (
          <Collapse isOpen={sidebarIsOpen}>
            <div>
              <div className="asset-search">
                <Input
                  placeholder="Search"
                  type="text"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="project-asset-list">
                <ProjectListProvider
                  filters={filters}
                  setFilters={setFilters}
                  changeInSearchString={changeInSearchString}
                  setChangeInSearchString={setChangeInSearchString}
                />
              </div>
            </div>
          </Collapse>
        ) : null}
      </Nav>
    </div>
  );
};

export default ProjectAssetsNav;
