import { authContext } from 'contexts/AuthContext';
import { logOut } from 'pages/Login/LoginActions';
import React, { useContext } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const Step4 = ({ isEditProfile }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setAuthData } = useContext(authContext);

  const handleContinue = async () => {
    if (!isEditProfile) {
      await dispatch(logOut());
      await setAuthData(null);
      history.push('/login');
    } else {
      history.push('/pilot-dashboard/0');
    }
  };

  return (
    <div>
      <div className="bg-white card-header mb-4 mt-n3 mx-n4 text-center">
        <h5 className="font-size-14 mb-1">
          <span className="progress-span">
            <CircularProgressbar
              value={100}
              text="3/3"
              styles={buildStyles({
                pathColor: `rgb(58, 180, 43)`,
                textSize: '28px',
              })}
            />
          </span>{' '}
          Completed!
        </h5>
      </div>
      <div className="text-center">
        {isEditProfile && <p>Profile updated successfully!</p>}
        {!isEditProfile && <p>Profile created successfully!</p>}

        <Button
          color="primary"
          type="submit"
          className="btn btn-sm font-size-13 px-4"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Step4;
