import React, { useState, useContext } from 'react';
import { Nav, NavItem, NavLink, Navbar } from 'reactstrap';
import Header from 'common/Header';
import { authContext } from 'contexts/AuthContext';

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useContext(authContext);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="main-wrapper">
      <Header />
      <aside className="left-sidebar">
        <div className="d-flex flex-column p-3 text-white">
          <Navbar color="dark" dark expand="md" className="p-0">
            <Nav vertical navbar>
              <NavItem>
                <NavLink href="#" className="active">
                  <i className="fa fa-home pr-3" /> Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                  <i className="fa fa-file-text pr-3" /> Pages
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                  <i className="fa fa-link pr-3" /> Components
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        </div>
      </aside>
    </div>
  );
};

export default Dashboard;
