import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="text-center">
          <h1 className="font-weight-bold text-uppercase">Oops...</h1>
          <h6 className="f-weight-6 text-uppercase text-info">
            something went wrong Here
            {error} {info}
          </h6>
        </div>
      );
    }
    return children;
  }
}
export default ErrorBoundary;
