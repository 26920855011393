import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const ViewSelectorModal = (props) => {
  const { modal, onClose, onContinue } = props;
  const [selectedOption, setOption] = useState('manualDataEntry');

  useEffect(() => {
    setTimeout(() => {
      setOption('manualDataEntry');
    }, 100);
  }, [modal]);

  const checkOptions = (event) => {
    const { value } = event.target;
    setOption(value);
  };

  return (
    <Modal isOpen={modal} toggle={onClose} centered>
      <ModalHeader className="border-0 modal-heading-check p-4">
        Please choose the preferred method to fill the rollout details.
      </ModalHeader>
      <ModalBody className="modal-dialog-centered px-4 py-0">
        <div className="w-100">
          <FormGroup check className="modal-select-option pl-0">
            <Label
              check
              className="border border-radius-8px font-size-14 font-weight-500 form-check modal-select-option p-3 pl-5 w-100 mb-3"
            >
              <Input
                type="radio"
                name="radio1"
                value="advanceMapView"
                disabled
                checked={selectedOption === 'advanceMapView'}
                onClick={checkOptions}
              />
              Advance Map View
            </Label>
          </FormGroup>
          <FormGroup check className="modal-select-option pl-0">
            <Label
              check
              className="border border-radius-8px font-size-14 font-weight-500 form-check modal-select-option p-3 pl-5 w-100"
            >
              <Input
                type="radio"
                name="radio1"
                value="manualDataEntry"
                checked={selectedOption === 'manualDataEntry'}
                onClick={checkOptions}
              />
              Manual Data Entry
            </Label>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-start p-4">
        <Button
          color="primary"
          onClick={() => onContinue(selectedOption)}
          className="px-4"
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ViewSelectorModal;
