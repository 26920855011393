import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//
import VidTechHeader from 'pages/Vidpitch/common/VidtechHeader';
import { authContext } from 'contexts/AuthContext';
import Sidebar from './components/SideBar';
import VidPitchVideoDetails from './components/VidPitchVideoDetails';

const VidPitchVideo = () => {
  const { auth, setAuthData } = useContext(authContext);

  useEffect(() => {
    const removeUserEmail = async () => {
      if (!auth?.data?.role) {
        await setAuthData(null);
      }
    };

    removeUserEmail();
  }, []);

  return (
    <div className="vidTech">
      <Helmet defaultTitle="VidTech" />
      <VidTechHeader userRole={auth?.data?.role} />
      <Container
        fluid
        style={{ height: 'calc(100vh - 54px)', overflow: 'auto' }}
      >
        {auth?.data?.role && (
          <div className="mt-3">
            <h6 className="mb-0">
              <Link to="/assets-listing">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Back To Home
              </Link>
            </h6>
          </div>
        )}

        <Row>
          <Col md="9">
            <VidPitchVideoDetails />
          </Col>
          <Col md="3">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VidPitchVideo;
