import React from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { pmFilesToDownload } from 'pages/PMPortal/PMPortalActions';
import { editorFilesToDownload } from 'pages/EditorPortal/EditorPortalActions';

const AssetDownload = ({ reducerName }) => {
  const dispatch = useDispatch();
  const { filesToDownload } = useSelector((state) => state[reducerName]);

  const downloadFile = (fileKey) => {
    const data = {
      file: fileKey,
    };
    const config = {
      responseType: 'blob',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-file-download?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileKey}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        toast.error('Failed to download the asset.');
      });
  };

  const handleDownload = async () => {
    filesToDownload.forEach((row) => {
      downloadFile(row.Key);
    });

    const initialFilesToDownload = { filesToDownload: [] };
    dispatch(
      reducerName === 'editorPortal'
        ? editorFilesToDownload(initialFilesToDownload)
        : pmFilesToDownload(initialFilesToDownload)
    );
  };

  return (
    <Button
      outline
      color="primary"
      onClick={handleDownload}
      className="btn-sm px-4"
    >
      Download
    </Button>
  );
};

export default AssetDownload;
