import { salesInitialState } from 'constants/reduxInitialStates/salesState';
import * as actions from './SalesActionTypes';

export const salesReducer = (state = salesInitialState, action) => {
  const { searchFilters, plan, eliteAuction, searchedBy } = state;
  switch (action.type) {
    case actions.CHANGE_SALES_FILTERS:
      return {
        ...state,
        searchFilters: { ...searchFilters, ...action.payload.searchFilters },
      };

    case actions.CHANGE_PLAN:
      return {
        ...state,
        plan: action.payload.plan,
      };

    case actions.CHANGE_ELITE_AUCTION:
      return {
        ...state,
        eliteAuction: action.payload.eliteAuction,
      };

    case actions.CHANGE_SEARCHED_BY:
      return {
        ...state,
        searchedBy: action.payload.searchedBy,
        propertyDetail: action.payload.propertyDetail,
      };

    case actions.RESET:
      return {
        ...state,
        ...salesInitialState,
      };

    default:
      return state;
  }
};
