import axios from 'axios';

export const convertTimecodeToSeconds = (duration, fps, dropFrame) => {
  const time = duration.split(':');
  if (time.length !== 4) {
    // we could make assumptions and fill in FF or whatever, but for now just return false
    return false;
  }
  // combine hours and minutes into minutes
  const minutes = time[0] * 60 + Number(time[1]);
  // now that we have the total number of minutes, subtract how many minutes are divisible by ten
  const minutesCounted = minutes - Math.floor(minutes / 10);
  // combine mintues and seconds to seconds
  const seconds = minutes * 60 + Number(time[2]);
  let frames = Number(time[3]);
  // fractions of frames? no. round up though.
  frames += Math.ceil(seconds * fps);
  // only run if we're dealig with dropFrame timecode
  if (dropFrame) {
    // we don't subtract 2 if we're on frame 0 or 1 of the first minute so let's add one or two frames back in
    if (time[3] < 2 && minutesCounted > 0 && time[2] === '00') {
      // 1 frame if we're on '01' and 2 if we're on '00'
      frames += 2 - Number(time[3]);
    }
    // from the minutes counted above, we'll subtract 2 frames per minute
    frames -= minutesCounted * 2;
  }
  // convert frames back into seconds and multiply by (1001 / 1000)
  return parseFloat(parseFloat(frames / fps) * 1.001);
};

export const getImageBase64Url = async (
  assetURL,
  playerState,
  setVideoPlayerDetails,
  videoElement
) => {
  const options = {
    method: 'GET',
    url: `http://b17c-111-118-241-68.ngrok.io/api/get-blob?url=${assetURL}`,
    // url: `${process.env.REACT_APP_BASE_ENDPOINT}api/get-blob?url=${assetURL}`,
    headers: {},
  };

  const response = await axios.get(options.url, {
    headers: options.headers,
  });

  if (response && response.data && response.data.data) {
    return `data:image/jpeg;base64,${response.data.data}`;
  }
  return null;
};

export const removeScreenShots = () => {
  const screen1 = document.getElementById('videoPlayerScreenCanvas');
  if (screen1) {
    screen1.remove();
  }
};

export const isImage = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const convertSecondsToTimeCode = (input, fps) => {
  const pad = (input) => {
    if (input !== 0) {
      return input < 10 ? `0${input}` : input;
    }
    return '00';
  };
  const editedFPS = typeof fps !== 'undefined' ? fps : 24;
  const timeUnits = [
    pad(Math.floor(input / 3600)),
    pad(Math.floor((input % 3600) / 60)),
    pad(Math.floor(input % 60)),
    pad(Math.floor((input * editedFPS) % editedFPS)),
  ];

  // const fiteredTime = timeUnits.filter((item, index) => {
  //   if (index <= 1) {
  //     return item !== '00';
  //   }
  //   if (index > 1) {
  //     return item;
  //   }
  // });
  return timeUnits.join(':');
};

export const calculatePreviewTimeWithPercentage = (
  e,
  currentVideoDuration,
  videoPreviewImages,
  progressbarRef
) => {
  const rect = progressbarRef.getBoundingClientRect();

  const position = e.pageX - rect.left;
  let percentage = (100 * position) / rect.width;
  if (percentage > 100) {
    percentage = 100;
  }
  if (percentage < 0) {
    percentage = 0;
  }

  const previewTime = (currentVideoDuration / 100) * Math.floor(percentage);

  const previewImage =
    videoPreviewImages &&
    videoPreviewImages.filter((videoItem) => {
      return videoItem.frame <= Math.floor(previewTime);
    });

  let imageDt = {};
  if (previewImage && previewImage.length) {
    imageDt = previewImage[previewImage.length - 1];
  }

  return {
    previewTime,
    divPosition: percentage,
    url: imageDt ? imageDt.imageUrl : null,
  };
};

export const convertSecondsToStandardFormat = (seconds) => {
  let result;
  if (seconds < 3600) {
    result = new Date(seconds * 1000).toISOString().slice(14, 19);
  } else {
    result = new Date(seconds * 1000).toISOString().slice(11, 19);
  }
  return result;
};

export const convertSecondsToHHMMSSFormat = (paramTotalSeconds) => {
  let totalSeconds = paramTotalSeconds;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);

  minutes = String(minutes).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  seconds = String(seconds).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const timeFormator = (seconds, type) => {
  let result;
  switch (type) {
    case 'standard':
      if (seconds < 3600) {
        result = new Date(seconds * 1000).toISOString().slice(14, 19);
      } else {
        result = new Date(seconds * 1000).toISOString().slice(11, 19);
      }
      return result;
    case 'timecode':
      return convertSecondsToTimeCode(seconds, 20);
    default:
      return convertSecondsToTimeCode(seconds, 20);
  }
};