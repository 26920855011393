import React, { useContext } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { authContext } from 'contexts/AuthContext';
import { logOut } from 'pages/Login/LoginActions';
import { Helmet } from 'react-helmet';

const Logout = () => {
  const dispatch = useDispatch();
  const {
    setAuthData,
  } = useContext(authContext);

  const handleLogout = async () => {
    await dispatch(logOut());
    await setAuthData(null);
  };

  return (
    <div className="no-auth-main-container">
      <Helmet  defaultTitle='Logout - VidTech' />
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <p className="mb-0 text-center">
              To do a fresh login please perform
              <Button size="sm" className="ml-3" onClick={handleLogout}>
                Logout
              </Button>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Logout;
