import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';

const BrokerCard = ({ thumbnailUrl, firstName, lastName, brokerages }) => {
  return (
    <>
      <Col lg="auto" className="mb-2 pr-lg-0">
        <Card className="border-radius-8px p-1">
          <CardBody className="p-2">
            <div className="d-flex align-items-center">
              <img
                src={thumbnailUrl || 'https://via.placeholder.com/40'}
                alt="Broker"
                className="rounded-circle h-40"
              />
              <div className="ml-2">
                <h6 className="mb-1">
                  {firstName} {lastName}
                </h6>
                <p className="broker-company text-truncate mb-0">
                  {brokerages && brokerages.length
                    ? brokerages.map((col, index) => {
                        if (index > 0)
                          return (
                            <span className="m-0" key={col.id}>
                              , {col.name}
                            </span>
                          );
                        return (
                          <span className="m-0" key={col.id}>
                            {col.name}
                          </span>
                        );
                      })
                    : null}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default BrokerCard;
