import React, { useContext } from 'react';
import { Button } from 'reactstrap';

import { ATTENTION_POPOVER } from 'constants/appConstants';
import needMyAttentionIcon from 'assets/need-my-attention-icon.svg';
import { attentionContext } from 'contexts/AttentionContext';

const AttentionIcon = ({ id, alertDetails, planName }) => {
  const popoverID = `attention_${id}`;

  const { attentionData, setAttentionData, hidePopoverButton } =
    useContext(attentionContext);

  const toggleAttentionPopover = (e) => {
    e.stopPropagation();
    if (attentionData && attentionData.popoverID !== popoverID) {
      setAttentionData(ATTENTION_POPOVER);
    }
    setAttentionData({
      status: true,
      popoverID,
      alertDetails,
      planName,
    });
  };

  return (
    <div className="attention-box">
      <Button
        id={popoverID}
        type="button"
        className="attention-button cursor-pointer d-inline-block"
        onClick={(e) => toggleAttentionPopover(e)}
      >
        <img src={needMyAttentionIcon} alt="needMyAttentionIcon" />
      </Button>
    </div>
  );
};
export default AttentionIcon;
