import React from 'react';
import { Button } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { GET_PENDING_ASSETS } from 'pages/Search/CrexiSearchGraphQL';

const PendingOrderButton = ({ goToPendingOrders }) => {
  const { data, loading, error } = useQuery(GET_PENDING_ASSETS, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <div>Loading ...</div>;
  if (error) return <span>{error.message}</span>;

  if (data) {
    const {
      getPendingAssets: { totalCount },
    } = data;
    return (
      <Button
        onClick={goToPendingOrders}
        className="unfinished-alert border-0 mr-2"
        color="danger"
      >
        {totalCount} unfinished order pending{' '}
      </Button>
    );
  }
  return null;
};

export default PendingOrderButton;
