import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import EditorProfile from 'pages/EditorPortal/components/Projects/components/EditorProfile';

const EditorEditModal = ({
  isEditProfileModalOpen,
  toggleEditProfileModal,
  editorInfo,
}) => {
  return (
    <>
      <Modal
        isOpen={isEditProfileModalOpen}
        toggle={() => toggleEditProfileModal(false)}
        className="project-details-spp modal-xl mr-0 mt-0 mb-0"
      >
        <ModalBody className="p-0">
          <div className="d-flex justify-content-between align-items-center px-4 py-2">
            <span className="font-size-16">{'Edit Profile'}</span>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className="cursor-pointer"
              onClick={() => {
                toggleEditProfileModal(false);
              }}
            />
          </div>
          <hr className="m-0" />
          <EditorProfile isModal={false} editorID={editorInfo.id} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditorEditModal;
