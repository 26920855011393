import React, { useState } from 'react';
import { ATTENTION_POPOVER } from 'constants/appConstants';

export const attentionContext = React.createContext();

const AttentionProvider = ({ children }) => {
  const [attentionData, setAttentionData] = useState(ATTENTION_POPOVER);
  const [hidePopoverButton, togglePopoverButton] = useState(false);

  return (
    <attentionContext.Provider
      value={{
        attentionData,
        setAttentionData,
        hidePopoverButton,
        togglePopoverButton,
      }}
    >
      {children}
    </attentionContext.Provider>
  );
};

export default AttentionProvider;
