import * as Yup from 'yup';

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export default [
  Yup.object({
    address: Yup.object({
      address: Yup.string().trim().required('Address is required'),
      state: Yup.string().trim().required('State is required'),
      zip: Yup.string()
        .trim()
        .required('Zip is required')
        .matches(zipRegExp, 'ZIP Code is not valid'),
    }),
    phone: Yup.string()
      .trim()
      .required('Phone is Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    coverageArea: Yup.string().required('Required'),
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/, 'Incorrect format'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
  Yup.object({
    faaLicenseNumber: Yup.string()
      .trim()
      .required('FAA license number required'),
    faaExpireDate: Yup.date('Date is not valid.')
      .required('Date is required')
      .nullable(),
    insurancePolicyNumber: Yup.string()
      .trim()
      .required('Insurance Policy number required'),
    insuranceExpireDate: Yup.date('Date is not valid.')
      .required('Date is required')
      .nullable(),
  }),
  Yup.object({
    dronePlatform: Yup.string().required('Required'),
    camera: Yup.string().required('Required'),
  }),
];
