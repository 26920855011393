import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { InputGroup, Card, CardBody } from 'reactstrap';
import * as Yup from 'yup';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'common/LoadingButton';
import Logo from 'assets/videoms-logo.png';
import { REQUEST_CHANGE_PASSWORD } from 'pages/ResetPassword/ResetPasswordGraphQL';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const RequestChangePassword = (props) => {
  const [requestForgotPassword] = useMutation(REQUEST_CHANGE_PASSWORD);
  const history = useHistory();
  const { auth } = useContext(authContext);
  const [errorEncountered, setErrorEncountered] = useState(false);

  const redirectBasedOnRole = (role) => {
    if (role === ROLES.OPERATOR) {
      history.push('/brokers-directory/update-missing-details');
    } else {
      history.push('/crexi-search/results');
    }
  };

  useEffect(() => {
    if (auth.data) {
      redirectBasedOnRole(auth.data.role);
    }
  }, [auth]);

  const handleSubmit = async (values, actions) => {
    const { email } = values;
    try {
      actions.setSubmitting(true);
      const { data } = await requestForgotPassword({
        variables: { email },
      });
      const { status, message } = data.forgotPassword;
      if (status === 200) {
        toast.success(message);
        history.push('/reset-email-sent');
      }
      setErrorEncountered(false);
      actions.setSubmitting(false);
    } catch (e) {
      actions.setFieldError('general', e.message);
      actions.setSubmitting(false);
      toast.error(e.message);
      setErrorEncountered(true);
    }
  };

  const renderInvalidUserMessage = () => {
    if (errorEncountered)
      return (
        <div className="custom-error-msg">
          Unknown user ID. Please contact videoms at <br />
          <strong> 805-413-1032</strong> to change your user ID.
        </div>
      );
    return null;
  };

  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <div className="mb-4">
              <img src={Logo} alt="Logo" width="100"/>
            </div>
            <div>
              <h2 className="font-size-16">Oops, you forgot your password!</h2>
              <p className="font-size-14">
                Enter the email address and we’ll send you instructions to reset
                your password
              </p>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <div className="mt-3">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      component={FormikInputField}
                      label="Email"
                    />
                    {renderInvalidUserMessage()}
                  </div>
                  <LoadingButton
                    className="btn btn-primary btn-block font-weight-500 mt-3"
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                    color="primary"
                    block
                    type="submit"
                  >
                    Send reset instructions
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default RequestChangePassword;
