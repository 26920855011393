import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ActiveProjects from './components/ActiveProjects';
import Completed from './components/Completed';
import NeedMyAttention from './components/NeedMyAttention';
import ProjectDetail from './components/ProjectDetail';

const Projects = ({ component, ...rest }) => {
  const { path } = rest.match;

  return (
    <Row>
      <Col>
        <Switch>
          <Route
            exact
            path={`${path}/active-projects`}
            component={ActiveProjects}
          />

          <Route
            path={`${path}/active-projects/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            path={`${path}/need-my-attention/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            path={`${path}/completed/project-detail/:projectId/:planName`}
            component={ProjectDetail}
          />

          <Route
            exact
            path={`${path}/need-my-attention`}
            component={NeedMyAttention}
          />

          <Route exact path={`${path}/completed`} component={Completed} />
        </Switch>
      </Col>
    </Row>
  );
};
export default Projects;
