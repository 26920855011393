import React, { memo, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row } from 'reactstrap';
import StarFillIcon from 'remixicon-react/StarFillIcon';
import { useParams } from 'react-router-dom';
//
import { GET_VIDEO_SEGMENT } from 'pages/Vidpitch/VidPitchGraphQL';
import { convertFormValueToFormData } from 'pages/Vidpitch/constant';
import PageLoader from 'common/PageLoader';
import NoData from 'common/NoData';
import { VidPitchContext } from 'contexts/VidPitchContext';
import ChangeImageModal from './components/ChangeImage';
import UploadLogoModal from './components/UploadLogo';
import TextSetting from './components/TextSetting';
import RenderVideoConfirmationModal from './components/RenderVideoConfirmationModal';
import { BronzeProvider } from 'pages/CrexiBronze/BronzeContext';

const EditTextImages = ({ videoId, videoURL, assetCrexiId }) => {
  const { uuid } = useParams();
  const video = document.createElement('video');
  const canvas = window.document.createElement('canvas');

  const [chapterValues, setChapterValues] = useState({});
  const [chapterImageURL, setChapterImageURL] = useState('');
  const [logoImageURL, setLogoImageURL] = useState('');
  const [changeImageModal, toggleChangeImageModal] = useState(false);
  const [confirmationModal, toggleConfirmationModal] = useState(false);
  const [uploadLogoModal, toggleUploadLogoModal] = useState(false);
  const [textSettingsFormData, setTextSettingsFormData] = useState({});

  const { modifyVideoDetails, updateModifyVideoDetail } =
    useContext(VidPitchContext);
  const { currentChapter, totalChapter, selectedTemplateId } =
    modifyVideoDetails;

  const [getVideoSegment, { data, loading, error }] =
    useLazyQuery(GET_VIDEO_SEGMENT);

  const handleOnVideoMetaLoad = (currentTime) => {
    if (video && videoURL) {
      video.src = videoURL;
      video.height = 600;
      video.width = 600;
      video.crossOrigin = 'anonymous';

      video.addEventListener('loadeddata', () => {
        video.currentTime = currentTime;
      });

      video.ontimeupdate = () => {
        canvas.width = 600;
        canvas.height = 600;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          video,
          0,
          0,
          parseInt(canvas.width, 10),
          parseInt(canvas.height, 10)
        );

        const src = canvas.toDataURL();
        updateModifyVideoDetail({
          ...modifyVideoDetails,
          previewURL: src,
          isVideoType: false,
        });
      };
    }
    return null;
  };

  useEffect(() => {
    updateModifyVideoDetail({
      ...modifyVideoDetails,
      previewURL: '',
      isVideoType: false,
    });
    const chapterAlias = `chapter_${currentChapter}`;
    if (modifyVideoDetails[chapterAlias]) {
      const currentChapterDetails = modifyVideoDetails[chapterAlias];
      setChapterValues(currentChapterDetails);
      handleOnVideoMetaLoad(currentChapterDetails.segmentTime);
    } else {
      getVideoSegment({
        variables: {
          templateId: selectedTemplateId,
          segmentNumber: currentChapter,
          videoId,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [currentChapter]);

  useEffect(() => {
    if (data && !loading) {
      handleOnVideoMetaLoad(data.getVideoSegment.segmentTime);
      setChapterValues(data.getVideoSegment);
      if (!Object.keys(textSettingsFormData).length) {
        let formAttr = {};
        data.getVideoSegment?.formData?.forEach((item) => {
          const { attributeValue, value } = item;
          formAttr = {
            ...formAttr,
            [attributeValue]: value,
          };
        });

        setTextSettingsFormData({
          ...textSettingsFormData,
          ...formAttr,
        });
      }
    }
  }, [data, loading]);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const handleSubmit = (values) => {
    const formData = convertFormValueToFormData(
      values,
      currentChapter,
      chapterImageURL,
      logoImageURL
    );

    setTextSettingsFormData({
      ...textSettingsFormData,
      ...values,
    });

    updateModifyVideoDetail({
      ...modifyVideoDetails,
      [`chapter_${currentChapter}`]: {
        ...chapterValues,
        formData,
      },
      currentChapter:
        currentChapter < totalChapter ? currentChapter + 1 : currentChapter,
    });

    if (currentChapter === totalChapter) {
      toggleConfirmationModal(true);
    }
  };

  const handleBack = () => {
    updateModifyVideoDetail({
      ...modifyVideoDetails,
      currentChapter: currentChapter - 1,
    });
  };

  return (
    <BronzeProvider>
      <div>
        <div className="vm-suggession mt-3 p-2">
          * The video has {totalChapter} Chapters. Make changes to images and
          text for each chapter. <br />* When your changes are complete, render
          the video to ensure the changes are what you want. Changes to pictures
          and text can be made as many times as you require.
        </div>
        <hr />
        <div className="temp-settings p-2">
          <h6>
            <b>
              Showing settings for - Chapter {currentChapter} of {totalChapter}
            </b>
          </h6>
        </div>
        <Row>
          <Col md="7">
            <div className="px-0 mt-4 border-right">
              <h6>
                <b>
                  <StarFillIcon
                    size={16}
                    className="text-warning mr-1 temp-star"
                  />
                  Image Settings
                </b>
              </h6>
              <Row>
                <Col md={5}>
                  <img
                    src={chapterImageURL || 'https://via.placeholder.com/40'}
                    alt="chapter-thumbnail"
                    height={120}
                    width={120}
                    className="object-fit-cover"
                  />
                </Col>
                <Col>
                  <p className="mb-2">
                    Chapter {currentChapter} / {totalChapter} - Image
                  </p>
                  <Button
                    outline
                    size="sm"
                    className="temp-button"
                    onClick={(e) => toggleChangeImageModal(true)}
                  >
                    Change Image
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          {currentChapter === 1 && (
            <Col className="pl-0">
              <div className="px-0 pt-4 py-2">
                <h6>
                  <b>
                    <StarFillIcon
                      size={16}
                      className="text-warning mr-1 temp-star"
                    />
                    Upload Logo
                  </b>
                </h6>
                <Row>
                  <Col md={4} className="mr-4">
                    <img
                      src={logoImageURL || 'https://via.placeholder.com/40'}
                      alt="chapter-thumbnail"
                      height={120}
                      width={120}
                      className="object-fit-contain"
                    />
                  </Col>
                  <Col>
                    <Button
                      outline
                      size="sm"
                      className="temp-button mt-4 ml-3"
                      onClick={(e) => toggleUploadLogoModal(true)}
                    >
                      Change Logo
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
        <div className="px-0 pt-4 py-2">
          <h6>
            <b>
              <StarFillIcon size={16} className="text-warning mr-1 temp-star" />{' '}
              Text Settings
            </b>
          </h6>
        </div>
      </div>

      {chapterValues?.formData && (
        <TextSetting
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          totalChapter={totalChapter}
          currentChapter={currentChapter}
          setChapterImageURL={setChapterImageURL}
          setChapterLogoURL={setLogoImageURL}
          formDataValues={chapterValues.formData}
          textSettingsFormData={textSettingsFormData}
          previewURL={modifyVideoDetails.previewURL}
        />
      )}

      {uploadLogoModal && (
        <UploadLogoModal
          uuid={uuid}
          logoImageURL={logoImageURL}
          uploadLogoModal={uploadLogoModal}
          setLogoImageURL={setLogoImageURL}
          toggleUploadLogoModal={toggleUploadLogoModal}
          currentChapter={currentChapter}
        />
      )}

      {changeImageModal && (
        <ChangeImageModal
          uuid={uuid}
          assetCrexiId={assetCrexiId}
          currentChapter={currentChapter}
          chapterImageURL={chapterImageURL}
          changeImageModal={changeImageModal}
          setChapterImageURL={setChapterImageURL}
          toggleChangeImageModal={toggleChangeImageModal}
        />
      )}

      <RenderVideoConfirmationModal
        videoId={videoId}
        confirmationModal={confirmationModal}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </BronzeProvider>
  );
};

export default memo(EditTextImages);
