import React from 'react';
import { Button } from 'reactstrap';

const AddMoreButton = ({ onClick }) => {
  return (
    <Button className="add-more-btn" color="link" onClick={onClick}>
      + Add more
    </Button>
  );
};

export default AddMoreButton;
