import React, { useContext, useState } from 'react';
import { find } from 'lodash';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { FormGroup, Input, Label } from 'reactstrap';
//
import { DEAL_PAYMENT } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const Payment = ({ deal: { projectId, dealPayment }, refetchDealDetails }) => {
  const [loading, setLoading] = useState(false);
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [updateDealPayment] = useMutation(DEAL_PAYMENT, {
    onCompleted: async () => {
      await refetchDealDetails();
      setLoading(false);
      toast.success('Deal Payment Updated.');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const verifyPaymentMode = (paymentMode) => {
    if (dealPayment.length) {
      return find(dealPayment, { name: paymentMode });
    }
    return false;
  };

  const handlePaymentChange = (e) => {
    try {
      setLoading(true);
      const { value, checked } = e.target;

      const isPulledChecked =
        value === 'Pulled From Bundle'
          ? checked
          : verifyPaymentMode('Pulled From Bundle').checked;

      let isNotPaidChecked;
      let isPaidChecked;

      if (value === 'Not Paid') {
        isNotPaidChecked = checked;
        isPaidChecked = checked ? false : verifyPaymentMode('Paid').checked;
      } else if (value === 'Paid') {
        isPaidChecked = checked;
        isNotPaidChecked = checked
          ? false
          : verifyPaymentMode('Not Paid').checked;
      } else {
        isPaidChecked = verifyPaymentMode('Paid').checked;
        isNotPaidChecked = verifyPaymentMode('Not Paid').checked;
      }

      const dealPaymentPayload = [
        {
          name: 'Invoiced',
          checked: true,
          timestamp:
            value === 'Invoiced'
              ? new Date()
              : verifyPaymentMode('Invoiced').timestamp,
          disabled: false,
        },
        {
          name: 'Pulled From Bundle',
          checked: isPulledChecked,
          timestamp:
            value === 'Pulled From Bundle'
              ? new Date()
              : verifyPaymentMode('Pulled From Bundle').timestamp,
          disabled: false,
        },
        {
          name: 'Not Paid',
          checked: isNotPaidChecked,
          timestamp:
            value === 'Not Paid'
              ? new Date()
              : verifyPaymentMode('Not Paid').timestamp,
          disabled: !!(value === 'Paid' && checked),
        },
        {
          name: 'Paid',
          checked: isPaidChecked,
          timestamp:
            value === 'Paid' ? new Date() : verifyPaymentMode('Paid').timestamp,
          disabled: !!(value === 'Not Paid' && checked),
        },
      ];

      updateDealPayment({
        variables: {
          updateDealPaymentProjectId3: projectId,
          updateDealPaymentPayment2: dealPaymentPayload,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <ul className="list-inline font-size-14">
        <li className="mt-2 font-weight-600">PAYMENT:</li>
        <li className="mt-2 font-size-12">
          <FormGroup check className="d-flex ml-auto">
            <Label check>
              <Input
                disabled={
                  role === ROLES.ADMIN ||
                  role === ROLES.VIDEOMS_PM ||
                  role === ROLES.VIDEOMS_QA
                }
                type="checkbox"
                name="payment"
                value="Not Paid"
                onChange={handlePaymentChange}
                checked={
                  verifyPaymentMode('Not Paid') &&
                  verifyPaymentMode('Not Paid').checked
                }
              />
              <span className="align-middle d-inline-block line-height-23">
                Not Paid
              </span>
            </Label>
          </FormGroup>
        </li>
        <li className="mt-2 font-size-12">
          <FormGroup check className="d-flex ml-auto">
            <Label check>
              <Input
                disabled={
                  role === ROLES.ADMIN ||
                  role === ROLES.VIDEOMS_PM ||
                  role === ROLES.VIDEOMS_QA
                }
                name="payment"
                type="checkbox"
                value="Paid"
                onChange={handlePaymentChange}
                checked={
                  verifyPaymentMode('Paid') && verifyPaymentMode('Paid').checked
                }
              />
              <span className="align-middle d-inline-block line-height-23">
                Paid
              </span>
            </Label>
          </FormGroup>
        </li>
        <li className="mt-2 font-size-12">
          <FormGroup check className="d-flex ml-auto">
            <Label check>
              <Input
                disabled
                type="checkbox"
                name="payment"
                value="Invoiced"
                onChange={handlePaymentChange}
                checked={
                  verifyPaymentMode('Invoiced') &&
                  verifyPaymentMode('Invoiced').checked
                }
              />
              <span className="align-middle d-inline-block line-height-23">
                Invoiced
              </span>
            </Label>
          </FormGroup>
        </li>
        <li className="mt-2 font-size-12">
          <FormGroup check className="d-flex ml-auto">
            <Label check>
              <Input
                disabled={
                  role === ROLES.ADMIN ||
                  role === ROLES.VIDEOMS_PM ||
                  role === ROLES.VIDEOMS_QA
                }
                type="checkbox"
                name="payment"
                value="Pulled From Bundle"
                onChange={handlePaymentChange}
                checked={
                  verifyPaymentMode('Pulled From Bundle') &&
                  verifyPaymentMode('Pulled From Bundle').checked
                }
              />
              <span className="align-middle d-inline-block line-height-23">
                Pulled From Bundle
              </span>
            </Label>
          </FormGroup>
        </li>
      </ul>
    </div>
  );
};

export default Payment;
