import React from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import {
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BetweenButton = () => <Button style={{ cursor: 'default' }}>... </Button>;

const Paginator = ({
  offset,
  recordLength,
  currentPage,
  onPageChange,
  range = 3,
  pageCount,
  handleGoToFirst,
  handleGoToNext,
  handleGoToLast,
  handleGoToPrevious,
  totalCount,
}) => {
  const renderedPages = [...Array(range * 2 + 1).keys()]
    .map((i) => currentPage - range + i)
    .filter((i) => i > 0 && i <= pageCount);

  const showStart = currentPage - range > 1;
  const showEnd = currentPage + range < pageCount;

  const startPage = offset + 1;
  return (
    <Row className="align-items-center">
      <Col lg="auto" className="mb-2 pagination-records ">
        <h6 className="text-black-50 mb-0">
          Showing {`${startPage} - ${offset + recordLength}`} of {totalCount}{' '}
          records
        </h6>
      </Col>
      <Col lg="auto" className="text-lg-right pagination-dots">
        <ButtonGroup className="paginator">
          <Button
            className="prev-next-btn"
            title="First"
            onClick={handleGoToFirst}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>
          <Button
            className="prev-next-btn"
            title="Previous"
            onClick={handleGoToPrevious}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>

          {showStart && [
            <Button key="FirstButton" onClick={() => onPageChange(1)}>
              1
            </Button>,
            <BetweenButton key="BetweenButton" />,
          ]}
          {renderedPages.map((page) => (
            <Button
              key={page}
              onClick={() => onPageChange(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </Button>
          ))}
          {showEnd && (
            <>
              <BetweenButton />
              <Button onClick={() => onPageChange(pageCount)}>
                {pageCount}
              </Button>
            </>
          )}
          <Button
            className="prev-next-btn"
            title="Next"
            onClick={handleGoToNext}
            disabled={currentPage === pageCount}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
          <Button
            className="prev-next-btn"
            title="Last"
            onClick={handleGoToLast}
            disabled={currentPage === pageCount}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default Paginator;
