import { gql } from '@apollo/client';

export const GET_PLANS = gql`
  query getPlans {
    getPlans
  }
`;

export const GET_PLAN_BY_ASSET = gql`
  query getAssetPlan($assetId: Int!) {
    getAssetPlan(assetId: $assetId)
  }
`;

export const ADD_ASSET_PLAN = gql`
  mutation addAssetPlan($quantity: Int!, $assetId: Int!, $planId: Int!) {
    addAssetPlan(quantity: $quantity, assetId: $assetId, planId: $planId)
  }
`;
