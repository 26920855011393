import React, { useContext } from 'react';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { Card, CardBody } from 'reactstrap';

import Loader from 'common/Loader';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import InteriorVideoContactFrom from './components/InteriorVideoContactFrom';
import ValidationSchema from './ValidationSchema';

const defaultValue = {
  name: '',
  email: null,
  phone: '',
  shootDate: '',
  shootTime: '',
  additionalNotes: null,
};

const FIELD_KEY = 'interiorVideoContact';

const InteriorVideoContact = () => {
  let interiorVideoContact = defaultValue;

  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  if (getAssetDetails) {
    if (getAssetDetails.interiorVideoContact) {
      interiorVideoContact = getAssetDetails.interiorVideoContact;
      const { name, email, phone, shootDate, shootTime, additionalNotes } =
        interiorVideoContact;
      if (shootDate && shootTime) {
        interiorVideoContact = {
          name,
          email,
          phone,
          shootDate,
          shootTime: new Date(`${shootDate} ${shootTime}`),
          additionalNotes,
        };
      }
    }

    const renderForm = (props, values, isDisabled) => {
      return (
        <div className="themed-container property-detail-page">
          <div className="search-result-header ">
            <Card className="shadow border-radius-8px">
              <CardBody className="px-3 pb-0">
                <InteriorVideoContactFrom
                  {...props}
                  fieldKey={FIELD_KEY}
                  getAssetDetails={getAssetDetails}
                  interiorVideoContactData={cloneDeep(interiorVideoContact)}
                  refetch={refetchAssetDetail}
                  isDisabled={isDisabled}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      );
    };

    return (
      <Formik
        initialValues={{ interiorVideoContact }}
        enableReinitialize
        validationSchema={ValidationSchema}
        children={(props) => {
          const { isValid, dirty, values } = props;
          const isDisabled = !isValid;
          return (
            <>
              <PropertyDetailHeader
                dirty={dirty}
                fieldKey={FIELD_KEY}
                values={values}
                getAssetDetails={getAssetDetails}
                isValid
                isDisabled={isDisabled}
                refetch={refetchAssetDetail}
              />
              {renderForm(props, values, isDisabled)}
            </>
          );
        }}
      />
    );
  }
  return null;
};

export default InteriorVideoContact;
