import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  split,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { APP_TOKEN } from '../constants/appConstants';

const ENABLE_WEBSOCKET = true;
let wsLink = null;
let link = null;

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
});
if (ENABLE_WEBSOCKET) {
  wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT,
    options: {
      reconnect: true,
    },
  });
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes('Auth token is expired')) {
        //console.warn('Token has expired, please login again to continue.');
      }
    });
});

const authMiddleware = new ApolloLink((operation, forward) => {
  /*
  NOTE: we have an object stored in the local storage thats why parsing it 
  and then passing the token in header. If your application not storing object then
  change the code to match your logic, also check the userContext and make changes accordingly
  */
  const userData = JSON.parse(localStorage.getItem(APP_TOKEN));
  operation.setContext({
    headers: {
      authorization: (userData && userData.token) || null,
    },
  });
  return forward(operation);
});
if (ENABLE_WEBSOCKET) {
  link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    ApolloLink.from([errorLink, authMiddleware, httpLink])
  );
} else {
  link = ApolloLink.from([errorLink, authMiddleware, httpLink]);
}

const client = new ApolloClient({
  link,
  cache,
});

export default client;
