import React, { memo, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { startCase, toLower } from 'lodash';
//
import NoData from 'common/NoData';
import FormikInputField from 'components/Fields/FormikInputField';
import {
  phoneRegExp,
  VALIDATION_SCHEMA_CHAPTER_FIVE,
  VALIDATION_SCHEMA_CHAPTER_FOUR,
  VALIDATION_SCHEMA_CHAPTER_THREE,
  MAX_CHARACTER_LIMIT,
} from 'pages/Vidpitch/constant';

const TextSetting = ({
  setChapterImageURL,
  setChapterLogoURL,
  formDataValues,
  handleBack,
  handleSubmit,
  totalChapter,
  currentChapter,
  textSettingsFormData,
  previewURL,
}) => {
  const [textInitialValues, setTextInitialValues] = useState();

  const tempValidationObj = {
    3: VALIDATION_SCHEMA_CHAPTER_THREE,
    4: VALIDATION_SCHEMA_CHAPTER_FOUR,
    5: VALIDATION_SCHEMA_CHAPTER_FIVE,
  };

  const verifyAttrType = (attributeValue) =>
    !attributeValue.includes('IMAGE_') &&
    !attributeValue.includes('BROKER_COMPANY_LOGO_URL');

  useEffect(() => {
    let temp = {};
    formDataValues.forEach((item) => {
      const { attributeValue, value } = item;
      if (verifyAttrType(attributeValue)) {
        const textUpdatedValue = textSettingsFormData[attributeValue];
        temp = { ...temp, [attributeValue]: textUpdatedValue ?? value };
      } else if (attributeValue.includes('IMAGE_')) {
        setChapterImageURL(value);
      } else if (attributeValue.includes('BROKER_COMPANY_LOGO_URL')) {
        setChapterLogoURL(value);
      }
    });
    setTextInitialValues(temp);
  }, [formDataValues]);

  const validateField = (value, attributeValue, label) => {
    let error = '';
    if (
      [
        'ASSET_NAME',
        'BROKER_NAME',
        'ASSET_ADDRESS',
        'BROKER_OFFICE_FULL_ADDRESS',
      ].includes(attributeValue)
    ) {
      if (!value.trim()) {
        error = `${startCase(toLower(label))} is required`;
      }
    } else if (attributeValue.includes('PHONE')) {
      if (!value.trim()) {
        error = 'Phone number is required';
      }
      if (value.trim() && !value.match(phoneRegExp)) {
        error = 'Phone number is invalid';
      }
    }

    return error;
  };

  const renderFormikFields = () => {
    if (!formDataValues.length)
      return <NoData customMessage="No text fields to edit." />;

    return formDataValues.map((item) => {
      const { attributeValue, label } = item;

      if (verifyAttrType(attributeValue))
        return (
          <span key={attributeValue}>
            <Field
              key={attributeValue}
              name={attributeValue}
              component={FormikInputField}
              label={startCase(toLower(label))}
              disabled={attributeValue.includes('EMAIL')}
              className="form-control form-control-sm mb-0"
              validate={(value) => validateField(value, attributeValue, label)}
            />
            {attributeValue.includes('CUSTOM') ? (
              <span className="font-size-11 float-right vidTech-note">
                Note: Character limit is {MAX_CHARACTER_LIMIT}.
              </span>
            ) : null}
          </span>
        );

      return null;
    });
  };

  const renderButtonText = () =>
    totalChapter > currentChapter ? (
      <>Save & Next Chapter</>
    ) : (
      <>Done! Render New Video</>
    );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={textInitialValues}
      validationSchema={tempValidationObj[currentChapter]}
      children={() => (
        <Form className="w-100">
          <div>{renderFormikFields()}</div>
          <hr className="mt-4" />
          <div className="d-flex justify-content-end align-items-center">
            <Button
              color="primary"
              onClick={handleBack}
              size="sm"
              className="mr-2 temp-button"
              disabled={currentChapter <= 1}
            >
              Previous Chapter
            </Button>
            <Button
              color="secondary"
              type="submit"
              className="temp-button"
              disabled={!previewURL}
            >
              {renderButtonText()}
            </Button>
          </div>
        </Form>
      )}
    />
  );
};

export default memo(TextSetting);
