import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import Logo from 'assets/videoms-logo.png';
import HeaderUserDropdown from 'common/HeaderUserDropdown';
import { ROLES } from 'constants/role';
import RBAC from 'hoc/RBAC';
import PortalDropdown from 'common/PortalDropdown';
import { VIDTECH_LINK } from 'constants/appConstants';

const Header = ({ salesHeader, portalName }) => {
  return (
    <header className="border-bottom">
      <Navbar expand="md" className="py-1 pr-0">
        <div>
          <NavbarBrand
            href={VIDTECH_LINK}
            target="_blank"
            tag="a"
            className="d-inline-flex flex-column"
          >
            <img src={Logo} alt="Logo" width="80" />
            {portalName && (
              <span className="font-size-12 text-right text-secondary font-weight-normal">
                {portalName}
              </span>
            )}
          </NavbarBrand>
        </div>

        <div className="align-items-center d-flex ml-auto position-relative">
          {salesHeader && (
            <div className="align-items-center d-flex ml-auto position-relative">
              <RBAC
                allowedRoles={[
                  ROLES.VIDEOMS_PM,
                  ROLES.ADMIN,
                  ROLES.VIDEOMS_QA,
                  ROLES.VIDEOMS_QC,
                ]}
              >
                <PortalDropdown />
              </RBAC>
            </div>
          )}

          <div className="d-flex align-items-center">
            <HeaderUserDropdown />
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
