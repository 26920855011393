import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Col,
  Row,
  Card,
  CardBody,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight,
  faCaretDown,
  faHome,
  faCalendar,
  faTag,
} from '@fortawesome/free-solid-svg-icons';

import { ACTIVE_PROJECT_COLUMN } from 'pages/ManagementPortal/constant';
import { NOT_SPECIFIED, SLACK_LINK } from 'constants/appConstants';
import { dateTimeFormatter, numberWithCommas } from 'utils/helper';
import slackIMG from 'assets/slack.png';
import asanaIMG from 'assets/asana.png';
import hubspotIcon from 'assets/hubspot-icon.png';
import linkImage from 'assets/link.svg';

const AssetDetails = ({ detail }) => {
  const {
    broker,
    crexiUrl,
    askingPrice,
    listingDate,
    propertyType,
    thumbnailUrl,
    projectAddress,
    projectName,
    projectAsanaLink,
    slackLink,
    hubspotLink,
  } = detail;

  const [dropdownOpen, setOpen] = useState(false);

  const toggleBrokerDropdown = () => setOpen(!dropdownOpen);

  const renderBrokerCount = () => {
    return (
      <div className="d-flex align-items-center border-left-3">
        {broker !== NOT_SPECIFIED && (
          <ButtonDropdown
            className="bg-white"
            isOpen={dropdownOpen}
            toggle={toggleBrokerDropdown}
          >
            <DropdownToggle className="bg-white border-0 text-dark count-btn">
              + {broker.length - 1}
            </DropdownToggle>
            <DropdownMenu className="border-radius-8px border-0 shadow-lg">
              {broker.map((row, index) => {
                if (index > 0) {
                  return (
                    <DropdownItem
                      key={`${row.brokerName}_${index + 1}`}
                      className="py-0 px-1"
                    >
                      <div className="d-flex align-items-center flex-fill p-2">
                        <img
                          src={
                            row.imageLink || 'https://via.placeholder.com/40'
                          }
                          alt="Broker"
                          className="rounded-circle h-40"
                        />
                        <div className="ml-2 ">
                          <h6 className="mb-1 font-size-14">
                            {row.brokerName}
                          </h6>
                          <p className="m-0 font-size-12">BROKER</p>
                        </div>
                      </div>
                    </DropdownItem>
                  );
                }
                return null;
              })}
            </DropdownMenu>
          </ButtonDropdown>
        )}
      </div>
    );
  };

  const renderBrokerNames = () => {
    return (
      <>
        <CardBody className="d-flex p-1">
          <div className="d-flex align-items-center flex-fill p-2">
            <img
              src={broker[0]?.imageLink || 'https://via.placeholder.com/40'}
              alt="Broker"
              className="rounded-circle h-40"
            />
            <div className="ml-2 ">
              <h6 className="mb-1 font-size-14">
                {broker[0]?.brokerName || 'Not Specified'}
              </h6>
              <p className="m-0 font-size-12">BROKER</p>
            </div>
          </div>
          {broker.length > 1 ? renderBrokerCount() : ''}
        </CardBody>
      </>
    );
  };

  const activeProjectExpandRow = {
    parentClassName: 'border-none-tr',
    renderer: (row) => {
      return (
        <div
          className="project-collapse-detail"
          style={{
            backgroundImage:
              'linear-gradient(to right, rgba(255, 0, 0, 0), rgb(86 83 116))',
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              {broker !== NOT_SPECIFIED && thumbnailUrl !== null && (
                <img
                  src={thumbnailUrl}
                  alt="broker-img"
                  style={{
                    width: 'calc(100vw - 70rem)',
                    height: '200px',
                    objectFit: 'cover',
                  }}
                />
              )}
            </div>
            <div>
              <Row className="m-0 py-4">
                <Col md="auto" className="px-0">
                  <Card className="border-radius-8px">
                    {broker.length > 0 && renderBrokerNames()}
                  </Card>
                  <ul className="list-inline mb-1 mt-2">
                    <li className="list-inline-item">
                      <a
                        href={projectAsanaLink || 'https://asana.com/'}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src={asanaIMG}
                          alt={asanaIMG}
                          className="mr-1"
                          height="25"
                          width="25"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={hubspotLink || 'https://hubSpot.com/'}
                        rel="noreferrer"
                        target="_blank"
                        className="mb-0"
                      >
                        <img
                          src={hubspotIcon}
                          alt={hubspotIcon}
                          className="mr-1"
                          height="25"
                          width="25"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={slackLink || SLACK_LINK}
                        rel="noreferrer"
                        target="_blank"
                        className="mb-0"
                      >
                        <img
                          src={slackIMG}
                          alt={slackIMG}
                          className="mr-2"
                          height="25"
                          width="25"
                        />
                      </a>
                    </li>
                  </ul>
                </Col>
                {/* <Col md="auto" className="my-auto"></Col> */}
                <Col md="auto" className="ml-auto">
                  <Card className="border-radius-8px p-1">
                    <CardBody className="p-2">
                      <Row>
                        <Col md="auto">
                          <div className="ml-2 d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faHome}
                              className="active-project-icon"
                              style={{ padding: '5px' }}
                            />
                            <div>
                              <p className="m-0 font-size-12">PROPERTY TYPE</p>
                              <h6
                                className="mb-1 font-size-14 property-type"
                                title={propertyType}
                              >
                                {!propertyType ? 'Not Specified' : propertyType}
                              </h6>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="border-left border-right">
                          <div className="ml-2 d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="active-project-icon"
                            />
                            <div>
                              <p className="m-0 font-size-12">LISTING DATE</p>
                              <h6 className="mb-1 font-size-14">
                                {!listingDate
                                  ? 'Not Specified'
                                  : dateTimeFormatter(listingDate)}
                              </h6>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto">
                          <div className="ml-2 d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faTag}
                              className="active-project-icon"
                            />
                            <div>
                              <p className="m-0 font-size-12">ASKING PRICE</p>
                              <h6 className="mb-1 font-size-14">
                                {askingPrice !== NOT_SPECIFIED
                                  ? `$${numberWithCommas(askingPrice)}`
                                  : 'Not Specified'}
                              </h6>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={7}>
                  <div className="px-3">
                    {crexiUrl !== null && (
                      <>
                        <img src={linkImage} alt="link" />{' '}
                        <a href={crexiUrl}>{crexiUrl}</a>
                      </>
                    )}
                  </div>
                </Col>
                <Col md={5}>
                  <div className="pr-3 text-right">
                    <span>{projectAddress}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon icon={faCaretDown} />;
      }
      return <FontAwesomeIcon icon={faCaretRight} />;
    },
    expanded: [projectName],
  };

  return (
    <BootstrapTable
      keyField="projectName"
      data={[detail]}
      columns={ACTIVE_PROJECT_COLUMN}
      expandRow={activeProjectExpandRow}
      bordered={false}
    />
  );
};

export default AssetDetails;
