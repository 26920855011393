import React from 'react';
import { NavbarBrand } from 'reactstrap';
import CrexiLogo from 'assets/CREXi-logo-black.svg';
import { VIDTECH_LINK } from 'constants/appConstants';

const CrexiBrandLogo = () => (
  <NavbarBrand
    href={`${VIDTECH_LINK}/crexi-portal/`}
    className="p-0 m-0"
    target="_blank"
    tag="a"
  >
    <img src={CrexiLogo} alt="CrexiLogo" />
  </NavbarBrand>
);
export default CrexiBrandLogo;
