import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import moment from 'moment';
import PropertyFeatureBlock from 'pages/Search/common/PropertyFeatureBlock';
import { dateTimeFormatter } from 'utils/helper';

const ProperyDetails = ({
  locations,
  details,
  activatedOn,
  updatedOn,
  status,
  isPrivate,
}) => {
  const activatedDate = dateTimeFormatter(activatedOn);
  const updatedDate = dateTimeFormatter(updatedOn);

  const current = moment.utc().format('MM/DD/YYYY');

  const activatedSince = moment
    .duration(moment(current).diff(activatedDate))
    .asDays();
  const updatedSince = moment
    .duration(moment(current).diff(updatedDate))
    .asDays();

  const getPropertyAddress = () => {
    if (locations && locations.length) {
      const { address, city, county, state, zip } = locations[0];
      const propertyAddress = `${address}, ${city}, ${
        state ? `${state.name}, ` : ''
      } ${county},  ${zip}`;
      return propertyAddress;
    }
    return null;
  };

  const addSpaceToString = (text) => {
    const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return text.replace(rex, '$1$4 $2$3$5');
  };
  const renderPropertyDetails = () => {
    const fieldNames = Object.keys(details);
    return fieldNames.map((row) => {
      if (row !== 'askingPrice') {
        return (
          <Col md="5" key={row}>
            <PropertyFeatureBlock
              heading={addSpaceToString(row)}
              bottomSpace
              value={details[row]}
            />
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <Col sm="12" lg="7" className="property-card-detail">
      <Row>
        <Col sm="12">
          <div className="dotted-border mb-3 mt-2 mt-lg-0">
            <div className="d-flex justify-content-between">
              <PropertyFeatureBlock
                heading="ADDRESS"
                bottomSpace
                value={getPropertyAddress()}
              />
              <div>
                {isPrivate && (
                  <Badge className="font-size-13 mr-2" color="danger">
                    Private
                  </Badge>
                )}
                <Badge className="font-size-13">{status}</Badge>
              </div>
            </div>
            <Row>
              <Col md="4">
                <PropertyFeatureBlock
                  heading="Activated On"
                  bottomSpace
                  value={activatedDate}
                />
              </Col>
              <Col md="4">
                <PropertyFeatureBlock
                  heading="Days on Market"
                  bottomSpace
                  value={`${activatedSince} days`}
                />
              </Col>
              <Col md="4">
                <PropertyFeatureBlock
                  heading="Time Since Last Update"
                  bottomSpace
                  value={`${updatedSince} days`}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <h5 className="mt-1 mb-3">PROPERTY DETAILS</h5>
        </Col>
        <Col md="12">
          <Row>
            {details ? (
              renderPropertyDetails()
            ) : (
              <Col md="5">
                <PropertyFeatureBlock
                  heading=""
                  value="Details not available."
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ProperyDetails;
