import React from 'react';
import { DropdownItem } from 'reactstrap';


const VersionOptions = ({versionData, handleSelectVersion}) => {
    return versionData.map((versionElem) => {
        const {id, version} = versionElem
        return <DropdownItem onClick={(e) => handleSelectVersion(versionElem)}  key={id}>{version}</DropdownItem>
    }) 
}
export default VersionOptions;