import { useSubscription } from '@apollo/client';
import { VIDEO_PLAYER_REVIEW_COMPLETE_SUBSCRIPTION } from '../../../common/VideoPlayer/videoPlayerGraphQL';

function useVideoReviewCompleteSubscription() {
  const { data, loading, error } = useSubscription(
    VIDEO_PLAYER_REVIEW_COMPLETE_SUBSCRIPTION,
    {
      shouldResubscribe: true,
    }
  );
  if (loading) return null;
  if (error) return null;
  if (data?.videoPlayerReviewComplete?.data?.status) {
    const { videoPlayerReviewComplete } = data;
    return videoPlayerReviewComplete;
  }
  return null;
}

export default useVideoReviewCompleteSubscription;
