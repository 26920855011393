import React, { useEffect } from 'react';
import { Table } from 'reactstrap';

import {
  useTasSubscription,
  useTaskUpdateSubscription,
  useTaskActionSubscription,
} from 'pages/ManagementPortal/hooks';
import AttentionProvider from 'contexts/AttentionContext';
import AssetDetails from './components/AssetDetails';
import TaskItem from './components/TaskItem';
import AttentionPopover from './components/AttentionPopover';

const ProjectDetailContent = ({
  parsedProjectId,
  projectData: { id, sections, projects, broker, projectName, ...rest },
  refetch,
}) => {
  const taskSubscription = useTasSubscription();

  const taskActionSubscription = useTaskActionSubscription();

  const taskUpdateSubscription = useTaskUpdateSubscription();

  useEffect(() => {
    if (
      (taskUpdateSubscription &&
        parsedProjectId === taskUpdateSubscription.projectId) ||
      taskSubscription ||
      taskActionSubscription
    ) {
      refetch();
    }
  }, [taskUpdateSubscription, taskSubscription, taskActionSubscription]);

  return (
    <>
      <div className="collapse-selected-table mb-4">
        <AssetDetails detail={{ ...rest, broker, projectName }} />
      </div>

      <Table
        bordered
        className="project-detail-heading-table mt-2 d-inline-table"
      >
        <thead>
          <tr>
            <th>Task Name &nbsp; &nbsp;&nbsp;</th>
            <th>Completed on</th>
            <th>Status</th>
            <th>Alert</th>
          </tr>
        </thead>
      </Table>
      <AttentionProvider>
        <>
          {sections.map((section, index) => {
            if (!section) return null;
            const { sectionName } = section;
            return (
              <TaskItem
                key={`${sectionName}_${index + 1}`}
                {...section}
                refetch={refetch}
              />
            );
          })}
          <AttentionPopover />
        </>
      </AttentionProvider>
    </>
  );
};

export default ProjectDetailContent;
