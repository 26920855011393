import React from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { Button, Modal, ModalBody } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import { APPROVE_REVIEW_ASSET_COMMENT_USER } from 'common/VideoPlayer/videoPlayerGraphQL';
import PageLoader from 'common/PageLoader';

const addCommentSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
});

const LeaveCommentModal = ({
  assetId,
  showAddCommentModal,
  toggleAddCommentModal,
  toggleHasCommentClicked,
  handleSendCommentActions,
  title = 'Want to leave a comment ?',
  description = 'Add your info here so knows who left the comment.',
}) => {
  const [approveCommentReviewUser, { data, error, loading }] = useLazyQuery(
    APPROVE_REVIEW_ASSET_COMMENT_USER
  );

  const closeCommentModal = () => {
    toggleHasCommentClicked(false);
  };

  const submitEmail = ({ email }) => {
    try {
      approveCommentReviewUser({
        variables: {
          assetId,
          email,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (loading) return <PageLoader />;

  if (data && data.approveReviewAssetCommentUser) {
    toast.success('User verified successfully!!');
    toggleAddCommentModal(
      false,
      data.approveReviewAssetCommentUser.userDetails
    );
    handleSendCommentActions(data.approveReviewAssetCommentUser.userDetails);
    toggleHasCommentClicked(false);
  }

  if (error) {
    toast.error(error.message);
  }

  return (
    <div>
      <Modal
        isOpen={showAddCommentModal}
        toggle={closeCommentModal}
        centered
        className="login-model"
      >
        <ModalBody className="login-model">
          <h5>{title}</h5>
          <p className="font-size-14">{description}</p>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={submitEmail}
            validationSchema={addCommentSchema}
            children={() => (
              <Form className="w-100" autoComplete="off">
                <Field
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Enter email.."
                  component={FormikInputField}
                  className="form-control form-control-sm mb-0"
                />
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="primary"
                    onClick={closeCommentModal}
                    size="sm"
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <Button color="secondary" type="submit" size="sm">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeaveCommentModal;
