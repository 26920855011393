import React, { useState } from 'react';
import { Button, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ViewSelectorModal from 'pages/Search/common/ViewSelectorModal';
import { changeSearchedBy } from 'pages/Search/SalesActions';
import AssetDetailProvider from 'contexts/AssetDetailContext';
import GetPlanProvider from 'contexts/GetPlanContext';
import { BRONZE } from 'constants/appConstants';
import { crexiBronzeCombinedActions } from 'pages/CrexiBronze/CrexiBronzeAction';
import Header from './components/Header';
import PropertyList from './components/PropertyList';
import Sidebar from './components/Sidebar';

const SearchResults = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { plan } = useSelector((state) => state.salesPortal);

  const {
    location: { pathname, search },
  } = history;

  const handleClose = () => {
    setOpen(false);
  };

  const isPendingOrder = search.includes('pendingOrder');

  const handleContinue = async (id, propertyDetail = {}) => {
    const bronzePath = `/crexi-bronze/${id}`;
    const otherPackagePath = `/crexi-search/property-detail/${id}/details`;
    let path =
      propertyDetail?.projectPlan === BRONZE || plan?.type === BRONZE
        ? bronzePath
        : otherPackagePath;

    if (propertyDetail?.projectPlan !== BRONZE && isPendingOrder) {
      path = otherPackagePath;
    }
    if (plan?.type === BRONZE || propertyDetail?.projectPlan === BRONZE) {
      dispatch(
        crexiBronzeCombinedActions({
          currentChapter: 1,
          currentPreviewURL: '',
          isChapterOneVisited: false,
          logoAndQrUrl: '',
          isVideoTextChange: false,
        })
      );
    }
    dispatch(
      changeSearchedBy({
        searchedBy: `${pathname}${search}`,
        propertyDetail,
      })
    );

    history.push({
      pathname: path,
      from: `${pathname}${search}`,
    });
  };

  return (
    <>
      <GetPlanProvider>
        <AssetDetailProvider>
          {!isPendingOrder && <Sidebar />}
          <Col
            className={`${
              !isPendingOrder ? 'content-quad px-4 bg-white' : 'px-4 bg-white'
            }`}
          >
            <Header isPendingOrder={isPendingOrder} />
            <PropertyList
              onSelect={handleContinue}
              isPendingOrder={isPendingOrder}
            />
            <ViewSelectorModal
              modal={open}
              onContinue={handleContinue}
              onClose={handleClose}
            />
          </Col>
        </AssetDetailProvider>
      </GetPlanProvider>
    </>
  );
};

export default SearchResults;
