import React, { useEffect, useState } from 'react';

const CharacterCounter = ({ fieldValue }) => {
  const [showWarning, setWarning] = useState(false);
  const charCount = fieldValue?.INSTRUCTION_FOR_EDITOR?.length;

  useEffect(() => {
    if (charCount > 300) {
      return setWarning(true);
    }
    return setWarning(false);
  }, [charCount]);

  return (
    <p className="d-flex justify-content-between mb-0">
      <span
        className={`text-danger font-size-10 ${
          showWarning ? 'visible' : 'invisible'
        }`}
      >
        *character limit is exceeded.
      </span>
      <span className="font-size-10">{`${charCount} / 300`}</span>
    </p>
  );
};

export default CharacterCounter;
