import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SaveConfirmationModal = ({
  confirmationModal,
  toggleConfirmationModal,
  saveBrokersData,
  confirmationData,
  setButtonDisable,
  buttonDisable,
}) => {
  return (
    <div>
      <Modal
        isOpen={confirmationModal}
        toggle={toggleConfirmationModal}
        className="save-confirmation-modal modal-dialog-centered"
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>Do you really want to save this flagged record?</ModalBody>
        <ModalFooter className="p-1">
          <Button
            color="primary"
            className="btn-sm"
            onClick={() => {
              saveBrokersData(confirmationData);
            }}
            disabled={buttonDisable}
          >
            Save
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => toggleConfirmationModal(false, null)}
            className="btn-sm"
            outline
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SaveConfirmationModal;
