export const brokersDirectoryInitialState = {
  updatedBrokersInfo: {
    filters: {
      newBroker: false,
      updatedRecords: false,
      searchParams: '',
      searchString: '',
    },
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
  missingBrokersInfo: {
    filters: {
      missingEmail: true,
      missingPhone: true,
      newBroker: false,
      updatedRecords: false,
      searchParams: '',
      searchString: '',
    },
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
  flaggedPageInfo: {
    filters: {
      emailNotFound: true,
      needsEvaluation: true,
      withNotesOnly: true,
      priority1: true,
      priority2: true,
      priority3: true,
    },
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
  dealsDetailsInfo: {
    filters: {
      dealName: '',
      projectManager: '',
      projectEditor: '',
      pif: '',
      status: '',
      startDate: '',
    },
    globalSearchText: '',
    totalCount: 0,
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
  HSoutOfSyncData: {
    filters: {
      filter: {
        startDate: null,
        endDate: null,
      },
    },
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
};
