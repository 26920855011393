import React from 'react';
import { Field } from 'formik';
import { Col, FormGroup, Row } from 'reactstrap';
import PasswordChecklist from 'react-password-checklist';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import FormikInputField from 'components/Fields/FormikInputField';
import FormikSelectField from 'components/Fields/FormikSelectField';
import { coverageAreaOptions } from 'pages/Pilot/constant';
import Avatar from '../Avatar';

const Step1 = ({ values, isEditProfile, ...rest }) => {
  const { firstName, email } = values;

  return (
    <>
      <div className="bg-white card-header mt-n3 mx-n4 text-center pilot-progress">
        <h5 className="font-size-14 mb-1">
          <span className="progress-span">
            <CircularProgressbar
              value={0}
              text="1/3"
              styles={buildStyles({
                pathColor: `rgb(58, 180, 43)`,
                textSize: '28px',
              })}
            />
          </span>
          General Details{' '}
        </h5>
      </div>
      <div className="bg-grey-pilot mb-3 mx-n4 px-4 py-3">
        <Row>
          <Col md={4}>
            <Avatar
              id="avatar"
              values={values}
              uploadLocation="pilot-profile"
              {...rest}
            />
          </Col>
          <Col md={8}>
            <h6 className="font-size-14 mb-1">{firstName}</h6>
            <p className="mb-1">
              <small>PILOT</small>
            </p>
            <p className="font-size-13 mb-0">{email}</p>
          </Col>
        </Row>
      </div>
      <FormGroup className="form-group">
        <Field
          id="phone"
          type="text"
          name="phone"
          component={FormikInputField}
          label="Phone"
          className="form-control form-control-sm"
        />
      </FormGroup>
      <FormGroup className="form-group">
        <Field
          id="address.address"
          type="text"
          name="address.address"
          component={FormikInputField}
          label="Address"
          className="form-control form-control-sm"
        />
      </FormGroup>
      <FormGroup className="form-group">
        <Field
          id="address.state"
          name="address.state"
          type="text"
          component={FormikInputField}
          className="form-control form-control-sm"
          placeholder="State"
        />
      </FormGroup>
      <FormGroup className="form-group">
        <Field
          id="address.zip"
          name="address.zip"
          type="text"
          component={FormikInputField}
          className="form-control form-control-sm"
          placeholder="Zip"
        />
      </FormGroup>

      <FormGroup className="mb-2" size="medium">
        <Field
          id="coverageArea"
          name="coverageArea"
          component={FormikSelectField}
          label="Pilot area of coverage (radius)"
          className="font-size-13"
          options={coverageAreaOptions}
          defaultoption="Select area of coverage"
        />
      </FormGroup>

      {!isEditProfile && (
        <FormGroup className="form-group">
          <Field
            id="password"
            type="password"
            name="password"
            label="Password"
            component={FormikInputField}
            className="form-control form-control-sm"
          />
        </FormGroup>
      )}

      {!isEditProfile && (
        <FormGroup className="form-group">
          <Field
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            component={FormikInputField}
            label="Confirm Password"
            className="form-control form-control-sm"
          />
        </FormGroup>
      )}

      {!isEditProfile && (
        <PasswordChecklist
          className="p-2"
          rules={['number', 'capital', 'lowercase', 'minLength', 'match']}
          minLength={6}
          value={values.password}
          valueAgain={values.confirmPassword}
        />
      )}
    </>
  );
};

export default Step1;
