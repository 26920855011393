import React, { useContext } from 'react';
import { videoPlayerContext } from 'contexts/VideoPlayerContext';

const VideoSpeedSelector = ({ handleVideoSpeed }) => {
  const { playerState } = useContext(videoPlayerContext);

  return (
    <select
      className="velocity"
      value={playerState.speed}
      onChange={(e) => handleVideoSpeed(e)}
    >
      <option value="0.50">0.50x</option>
      <option value="1">Normal</option>
      <option value="1.25">1.25x</option>
      <option value="2">2x</option>
      <option value="3">3x</option>
    </select>
  );
};

export default VideoSpeedSelector;
