import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Signup from 'pages/Signup';
// import DataTable from 'pages/DataTable';
import Dashboard from 'pages/Dashboard';
import PropertyDetail from 'pages/Search/components/PropertyDetails';
import Search from 'pages/Search';
import ManagementPortal from 'pages/ManagementPortal';
import BrokersDirectory from 'pages/BrokersDirectory';
import Error401 from 'pages/Error401';
import Error404 from 'pages/Error404';
import { ROLES } from 'constants/role';
import DrawerRight from 'components/DrawerRight';
import ProtectedRoute from 'components/ProtectedRoute';
import RequestChangePassword from 'pages/ResetPassword/components/RequestChangePassword';
import ChangePassword from 'pages/ResetPassword/components/ChangePassword';
import EmailSent from 'pages/ResetPassword/components/EmailSent';
import SuccessMessage from 'pages/ResetPassword/components/SuccessMessage';
import PMPortal from 'pages/PMPortal';
import EditorPortal from 'pages/EditorPortal';
import PilotDashboard from 'pages/Pilot';
import PilotRegistration from 'pages/Pilot/components/PilotRegistration/Index';
import SalesPersonPortal from 'pages/SalesPersonPortal';
import VideoPlayer from 'common/VideoPlayer';
import EditorRegistration from 'pages/EditorPortal/components/Projects/components/EditorRegistration';
import EditorProfile from 'pages/EditorPortal/components/Projects/components/EditorProfile';
import CrexiBronze from 'pages/CrexiBronze';

const AppVideomsView = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback="">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/pilot-registration/email=:email/name=:name/company=:company"
            component={PilotRegistration}
          />
          <Route
            exact
            path="/editor-registration/email=:email/name=:name"
            component={EditorRegistration}
          />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/error/401" component={Error401} />
          <Route exact path="/error/404" component={Error404} />

          <Route
            exact
            path="/forgot-password"
            component={RequestChangePassword}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ChangePassword}
          />
          <Route exact path="/reset-email-sent" component={EmailSent} />
          <Route exact path="/success" component={SuccessMessage} />
          <Route exact path="/watch/:videoId" component={VideoPlayer} />

          <ProtectedRoute
            exact
            path="/crexi-search/results"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QA,
              ROLES.USER,
              ROLES.VIDEOMS_QC,
            ]}
            component={Search}
          />
          <ProtectedRoute
            path="/crexi-search/property-detail/:assetId"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QA,
              ROLES.USER,
              ROLES.VIDEOMS_QC,
            ]}
            component={PropertyDetail}
          />
          <ProtectedRoute
            path="/management-portal"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QA,
              ROLES.USER,
              ROLES.VIDEOMS_QC,
            ]}
            component={ManagementPortal}
          />
          <ProtectedRoute
            path="/brokers-directory"
            allowedRoles={[
              ROLES.OPERATOR,
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_QC,
            ]}
            component={BrokersDirectory}
          />

          <ProtectedRoute
            path="/pm-portal"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QC,
            ]}
            component={PMPortal}
          />

          <ProtectedRoute
            path="/sales-portal"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QC,
              ROLES.VIDEOMS_SALES_PERSON,
            ]}
            component={SalesPersonPortal}
          />

          <ProtectedRoute
            path="/editor-portal"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_EDITOR,
              ROLES.VIDEOMS_QC,
            ]}
            component={EditorPortal}
          />
          <ProtectedRoute
            path="/editor-create-profile"
            allowedRoles={[ROLES.VIDEOMS_EDITOR, ROLES.VIDEOMS_QA]}
            component={EditorProfile}
          />
          <ProtectedRoute
            path="/edit-profile"
            allowedRoles={[ROLES.VIDEOMS_EDITOR, ROLES.VIDEOMS_QA]}
            component={EditorProfile}
          />
          <ProtectedRoute
            exact
            path="/pilot-portal/edit-profile"
            allowedRoles={[ROLES.VIDEOMS_PILOT, ROLES.VIDEOMS_QA]}
            component={PilotRegistration}
          />
          <ProtectedRoute
            exact
            path="/pilot-dashboard/:projectID"
            component={PilotDashboard}
            allowedRoles={[
              ROLES.VIDEOMS_PILOT,
              ROLES.VIDEOMS_PM,
              ROLES.ADMIN,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_QC,
            ]}
          />

          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          {/* <ProtectedRoute exact path="/datatable" component={DataTable} /> */}

          <ProtectedRoute
            path="/crexi-bronze/:id"
            allowedRoles={[
              ROLES.ADMIN,
              ROLES.VIDEOMS_PM,
              ROLES.USER,
              ROLES.VIDEOMS_EDITOR,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_QC,
            ]}
            component={CrexiBronze}
          />

          <ProtectedRoute
            allowedRoles={[
              ROLES.USER,
              ROLES.ADMIN,
              ROLES.OPERATOR,
              ROLES.VIDEOMS_QA,
              ROLES.VIDEOMS_PM,
              ROLES.VIDEOMS_PILOT,
              ROLES.VIDEOMS_EDITOR,
              ROLES.VIDEOMS_SALES_PERSON,
              ROLES.VIDEOMS_QC,
            ]}
            exact
            path="/logout"
            component={Logout}
          />
          <Route component={Error401} />
          <Redirect from="/crexi-search" to="/crexi-search/results" />
        </Switch>
        <DrawerRight />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppVideomsView;
