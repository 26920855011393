import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
//
import { FLAGGED_RECORDS_BROKER_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import PageLoader from 'common/PageLoader';
import { changeFlaggedFilters } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import NoData from 'common/NoData';
import SearchBroker from 'pages/BrokersDirectory/common/SearchBroker';
import BrokerTableRenderer from './components/BrokerTableRenderer';

const GetFlaggedRecords = ({ filters, searchParams, searchString }) => {
  const limit = 10;
  const { priority1, priority2, priority3, page, offset, ...rest } = filters;

  const priority = [];
  if (priority1) priority.push('Priority 1');
  if (priority2) priority.push('Priority 2');
  if (priority3) priority.push('Priority 3');
  const filterObj = {
    page,
    offset,
    limit,
    priority: priority.length ? priority : null,
    priority1,
    priority2,
    priority3,
    ...rest,
  };

  const { loading, data, error, refetch } = useQuery(
    FLAGGED_RECORDS_BROKER_DIRECTORY,
    {
      variables: {
        ...filterObj,
        searchParams,
        searchString,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) return <PageLoader />;

  if (error) return <span>{error.message}</span>;

  if (data) {
    const {
      getFlaggedBrokers: { data: brokersData, totalCount },
    } = data;

    if (!brokersData.length) return <NoData />;

    const pageCount = Math.ceil(totalCount / limit);
    return (
      <BrokerTableRenderer
        refetch={refetch}
        filters={filterObj}
        pageCount={pageCount}
        totalCount={totalCount}
        brokersData={brokersData}
      />
    );
  }
  return null;
};

const FlaggedRecordsWrapper = () => {
  const dispatch = useDispatch();
  const {
    flaggedPageInfo: {
      previousPageInfo: { page, offset },
      filters,
    },
  } = useSelector((state) => state.brokersDirectory);

  const [searchParams, setSearchParams] = useState('first_name');
  const [searchString, setSearchString] = useState('');

  const handleSearchParams = (value) => {
    setSearchParams(value);
    dispatch(
      changeFlaggedFilters({
        flaggedPageInfo: {
          filters,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const handleSearchString = (value) => {
    setSearchString(value);
    dispatch(
      changeFlaggedFilters({
        flaggedPageInfo: {
          filters,
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <>
      <div className="broker-top-space">
        <Row>
          <Col lg={4} className="ml-auto mt-3">
            <div>
              <SearchBroker
                handleSearchParams={handleSearchParams}
                handleSearchString={handleSearchString}
              />
            </div>
          </Col>
        </Row>
        <GetFlaggedRecords
          filters={{ ...filters, page, offset }}
          searchParams={searchParams}
          searchString={searchString}
        />
      </div>
    </>
  );
};

export default FlaggedRecordsWrapper;
