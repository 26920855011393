import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
//
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import VideoPlayer from 'common/VideoPlayer';
import { authContext } from 'contexts/AuthContext';
import {
  PIPELINE_STATUS,
  VIDPITCH_VIDEO_PRICE,
  COUNTDOWN_TIMER_IN_MINUTES,
} from 'pages/Vidpitch/constant';
import {
  GET_VIDEO_DATA,
  RENDERED_VIDEO_SUBSCRIPTION,
} from 'pages/Vidpitch/VidPitchGraphQL';
import ModifyVideo from './components/ModifyVideo';
import loadingImage from '../../../../../../assets/loading.gif';

const VidPitchVideoDetails = () => {
  const [purchaseTooltip, setPurchaseTooltip] = useState(false);
  const togglePurchaseTooltip = () => setPurchaseTooltip(!purchaseTooltip);
  const history = useHistory();
  const { uuid } = useParams();
  const { auth } = useContext(authContext);

  const updateVideoView = () => {
    if (!auth?.data?.role) return true;
    return false;
  };

  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    GET_VIDEO_DATA,
    {
      variables: {
        input: {
          email: auth?.data?.email,
          uuid,
          videoViewed: updateVideoView(),
        },
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribeRenderedVideo = subscribeToMore({
      document: RENDERED_VIDEO_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData?.data?.renderedVideo?.data?.renderId ===
          prev?.getVideoData?.videoDetails?.s3LinkRenderId
        ) {
          if (subscriptionData?.data?.renderedVideo?.data?.error) {
            toast.error(subscriptionData?.data?.renderedVideo?.data?.error);
            history.push(`/${uuid}/modify-video`);
          } else {
            refetch();
          }
        }
        return prev;
      },
    });

    return () => subscribeRenderedVideo();
  }, []);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const onPurchaseVideo = () => {
    return history.push(`/${uuid}/register`);
  };

  const countDownRenderer = ({ minutes, seconds }) => {
    return (
      <span>
        {`${minutes < 10 ? 0 : ''}${minutes} : ${
          seconds < 10 ? 0 : ''
        }${seconds}`}
      </span>
    );
  };

  if (data?.getVideoData?.videoDetails) {
    const {
      getVideoData: {
        brokerDetails,
        videoDetails: {
          s3Link,
          videoId,
          modifiedDate,
          assetCrexiId,
          pipelineStatus,
          isVideoPurchased,
        },
      },
    } = data;

    const renderCountDownRenderer = () => {
      const modifiedTimeStamp = new Date(Date.parse(modifiedDate));
      modifiedTimeStamp.setMinutes(
        modifiedTimeStamp.getMinutes() + COUNTDOWN_TIMER_IN_MINUTES
      );

      return (
        <Countdown date={modifiedTimeStamp} renderer={countDownRenderer} />
      );
    };

    const renderModifyVideo = () => {
      if (!isVideoPurchased) {
        return (
          <ModifyVideo
            uuid={uuid}
            videoId={videoId}
            assetCrexiId={assetCrexiId}
            pipelineStatus=""
          />
        );
      }
      return null;
    };

    return (
      <div className="mt-3">
        <div>
          {pipelineStatus === PIPELINE_STATUS.COMPLETE && (
            <VideoPlayer
              trackVideo
              isModal
              isVidPitchPlayer
              videoSrc={s3Link}
            />
          )}
          {pipelineStatus === PIPELINE_STATUS.INPROGRESS && (
            <div className="v-loading-container">
              <div className="d-flex justify-content-center h-100 align-items-center">
                <img
                  height="46"
                  alt="loading..."
                  className="mr-3"
                  src={loadingImage}
                />
                <span className="text-white font-size-16">
                  Please, wait… rendering is in process
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="w-50">
            <h5 className="mb-0">
              {brokerDetails?.assetName || 'Not Specified'}
            </h5>
            <p className="font-size-16 mb-2">
              {brokerDetails?.assetAddress || 'Not Specified'}
            </p>
          </div>
          {pipelineStatus === PIPELINE_STATUS.COMPLETE && (
            <div className="d-flex">
              {renderModifyVideo()}
              <>
                {!auth?.data?.role && !isVideoPurchased && (
                  <div>
                    <Button
                      color="success"
                      className="text-white temp-button px-4"
                      onClick={() => onPurchaseVideo()}
                      id="PurchaseVideo"
                    >
                      PURCHASE VIDEO {VIDPITCH_VIDEO_PRICE}
                    </Button>
                    <Tooltip
                      isOpen={purchaseTooltip}
                      target="PurchaseVideo"
                      toggle={togglePurchaseTooltip}
                      className="text-left"
                    >
                      <p className="mb-1 text-left">Confirmation</p>
                      <div className="font-size-12 text-left">
                        Video cannot be edited after purchase. Click “Modify
                        Video” if you have additional edits, before purchasing.
                      </div>
                    </Tooltip>
                  </div>
                )}

                <div>
                  {isVideoPurchased && (
                    <div className="bg-secondary temp-button px-4 py-2 text-white">
                      Video Purchased !!
                    </div>
                  )}
                </div>
              </>
            </div>
          )}
        </div>
        <div className="d-flex mt-2">
          <Row className="w-75">
            <Col md="6">
              <Card className="border-0 v-address-card">
                <CardBody className="p-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="v-map-icon rounded-circle">
                        <FontAwesomeIcon icon={faMapMarker} />
                      </span>
                    </div>
                    <div className="ml-3">
                      <p className="mb-0">
                        <b>Address</b>
                      </p>
                      <p className="mb-0">
                        {brokerDetails?.brokerOfficeFullAddress ||
                          'Not Specified'}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="border-0 v-address-card">
                <CardBody className="p-2">
                  <div className="d-flex">
                    <div>
                      <img
                        src={
                          brokerDetails?.photoUrl ||
                          'https://via.placeholder.com/40'
                        }
                        alt="broker-avatar"
                        className="rounded-circle h-40 mr-3"
                      />
                    </div>
                    <div>
                      <p className="mb-0">
                        <b>{brokerDetails?.brokerName || 'Not Specified'}</b>
                      </p>
                      <p className="m-0">
                        License: {brokerDetails?.licenses.join(', ') || 'NA'}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {pipelineStatus === PIPELINE_STATUS.INPROGRESS && (
            <div className="ml-4">
              <Card className="border-0 v-address-card">
                <CardBody className="p-2 text-center">
                  <div>
                    <b>Time Elapsed To Render Modified Video</b>
                  </div>
                  <b className="font-size-14">{renderCountDownRenderer()}</b>
                </CardBody>
              </Card>
            </div>
          )}
        </div>
      </div>
    );
  }

  return <NoData customMessage="Requested video asset does not exist." />;
};

export default VidPitchVideoDetails;
