import React from 'react';
import { Col, Row } from 'reactstrap';
//
import NoData from 'common/NoData';
import { dateTimeFormatter } from 'utils/helper';

const EditorProjects = ({ projectsData }) => {
  const renderProjectDate = (projectDate) => {
    return projectDate ? dateTimeFormatter(projectDate) : 'NA';
  };

  const renderRatingStars = (ratings = 0) => {
    return [...Array(5)].map((item, index) => {
      return (
        <span
          key={Math.random()}
          className={`${ratings > index ? 'text-warning' : 'grey-star'}`}
        >
          &#9733;{' '}
        </span>
      );
    });
  };

  const renderProjectReview = () => {
    const { editorProjectsDetails } = projectsData;
    if (editorProjectsDetails.length) {
      return editorProjectsDetails.map((projectItem) => {
        const {
          projectAlias,
          projectManager,
          projectStartDate,
          projectCompleteDate,
          commentsOnEditor,
          projectReviews,
        } = projectItem;

        return (
          <div className="border-bottom p-2" key={projectAlias}>
            <Row>
              <Col md={8}>
                <p className="font-weight-500 font-size-18">
                  {projectAlias || 'NA'}
                </p>
                <p className="font-size-14 pt-1">
                  <span className="font-weight-500 "> PM </span>
                  <span className="font-size-14 d-inline-block ml-2">
                    {projectManager || 'Not Assigned'}
                  </span>
                </p>
                <p className="font-size-14">
                  <span className="font-weight-500 mr-2"> Date Assigned</span>
                  <span>{renderProjectDate(projectStartDate)}</span>
                  <span className="font-weight-500 ml-4 mr-2">
                    {' '}
                    Date Completed
                  </span>
                  <span>{renderProjectDate(projectCompleteDate)}</span>
                </p>
                <p className="font-size-14">
                  <span className="font-weight-500"> Comments</span> <br />
                  <span> {commentsOnEditor || 'No comments'}</span>
                </p>
              </Col>
              <Col md={4}>
                <p className="font-weight-500 font-size-14 mt-5">
                  Project Quality <br />
                  {renderRatingStars(projectReviews?.projectQualityReview)}
                </p>
                <p className="font-weight-500 font-size-14">
                  Project Finished on Time <br />
                  {renderRatingStars(projectReviews?.projectFinishOnTimeReview)}
                </p>
                <p className="font-weight-500 font-size-14">
                  Ease of Collaboration <br />
                  {renderRatingStars(projectReviews?.easeOfCollabrationReview)}
                </p>
              </Col>
            </Row>
          </div>
        );
      });
    }
    return <NoData customMessage="No Projects Assigned" />;
  };

  if (projectsData && projectsData.editorProjectsDetails) {
    return (
      <div className="px-4">
        <p className="font-weight-500 py-2 mt-2  border-bottom font-size-16">
          Projects & Reviews
        </p>
        <p className="projects-review-scroll"> {renderProjectReview()}</p>
      </div>
    );
  }
  return <NoData customMessage="No Projects Assigned" />;
};

export default EditorProjects;
