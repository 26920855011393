import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { every } from 'lodash-es';
import { useParams } from 'react-router';
import {
  ACCEPTED_BROKER_COMMENT,
  LIST_REVIEW_ASSET_COMMENTS,
  REVIEW_ASSET_COMMENT_STATUS,
  UPDATE_REVIEW_ASSET_COMMENT_STATUS,
} from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';
import { parseToInteger } from 'utils/helper';
import ScreenShot from '../ScreenShot';

const EditorComments = ({ version, projectId }) => {
  const [modal, setModal] = useState(false);
  const params = useParams();
  const { folderId } = params;

  const markCheckArray = [];

  const toggle = () => setModal(!modal);

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    LIST_REVIEW_ASSET_COMMENTS,
    {
      variables: {
        folderId: parseInt(folderId, 10),
        version: version || '',
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribeBrokerComment = subscribeToMore({
      document: ACCEPTED_BROKER_COMMENT,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.acceptedBrokerComment
        ) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribeBrokerComment();
  }, []);

  useEffect(() => {
    const subscribeAssetCommentStatus = subscribeToMore({
      document: REVIEW_ASSET_COMMENT_STATUS,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.reviewAssetCommentStatus
        ) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribeAssetCommentStatus();
  }, []);

  const [markCheckAllMutation] = useMutation(
    UPDATE_REVIEW_ASSET_COMMENT_STATUS,
    {
      onCompleted: async () => {
        await refetch();
        toast.success('Success');
      },
      onError: (e) => {
        toast.error('error');
      },
    }
  );

  const handleChange = async () => {
    await markCheckAllMutation({
      variables: {
        commentIds: markCheckArray,
        projectId,
        folderId: parseToInteger(folderId),
        isMarkAll: true,
      },
    });

    toggle();
  };

  const disabledMarkAllComplete = data
    ? every(data.listReviewAssetComments, ['commentCompleted', true])
    : false;
  return (
    <>
      <div>
        <div className="my-3">
          <Row>
            <Col md={6}>
              <h3 className="font-size-14">COMMENTS</h3>
            </Col>
            <Col md={6} className="text-right">
              <Button
                size="sm"
                color="primary"
                onClick={toggle}
                disabled={disabledMarkAllComplete}
                className="font-size-14 font-weight-light px-4 "
              >
                Mark All Complete
              </Button>
            </Col>
          </Row>
        </div>
        <hr />
        <div>
          <ScreenShot
            markCheckArray={markCheckArray}
            loading={loading}
            error={error}
            data={data}
            refetch={refetch}
            folderId={folderId}
            projectId={projectId}
          />
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalBody className="text-center py-5">
          <h5>Are you sure?</h5>
          <p className="mb-0">You wanna mark all complete. </p>
        </ModalBody>
        <ModalFooter className="bg-grey-upload">
          <Button
            color="primary"
            outline
            onClick={toggle}
            className="mr-2 btn-sm px-4 font-size-13"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleChange()}
            className="btn-sm px-4 font-size-13"
          >
            Yes, Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditorComments;
