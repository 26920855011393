import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
//
import { authContext } from 'contexts/AuthContext';
import CrexiBronzeHeader from './common/CrexiBronzeHeader';
import TemplatePreview from './components/TemplatePreview';
import StepFormWrapper from './components/StepFormWrapper';
import { BronzeProvider } from './BronzeContext';

const CrexiBronze = () => {
  const { auth } = useContext(authContext);

  return (
    <BronzeProvider>
      <>
        <Helmet defaultTitle="CrexiBronze" />
        <CrexiBronzeHeader userRole={auth?.data?.role} />
        <Row className="m-0">
          <Col md={5} className="p-0 crexi-bronze-height bg-white">
            <StepFormWrapper userRole={auth?.data?.role} />
          </Col>
          <Col md={7} className="crexi-bronze-height">
            <TemplatePreview />
          </Col>
        </Row>
      </>
    </BronzeProvider>
  );
};

export default CrexiBronze;
