import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  RECORDS_TO_BE_UPDATED,
  RECORDS_TO_BE_UPDATED_SUBSCRIPTION,
} from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';

export const recordsToBeUpdatedContext = React.createContext();

const RecordsToBeUpdatedProvider = ({ children }) => {
  const [recordsToBeUpdated, setCount] = useState(0);

  const { loading, error, data, subscribeToMore } = useQuery(
    RECORDS_TO_BE_UPDATED,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  useEffect(() => {
    if (data && data.recordsToBeUpdated) {
      setCount(data.recordsToBeUpdated);
    }
  }, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: RECORDS_TO_BE_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || isEmpty(prev)) return '';
        if (!subscriptionData.data) return prev;
        const { recordsToBeUpdated } =
          subscriptionData.data.updateBrokerDirectoryCountDetails.data;
        return { recordsToBeUpdated };
      },
    });
    return () => unsubscribe();
  }, []);

  return (
    <recordsToBeUpdatedContext.Provider
      value={{
        error,
        loading,
        recordsToBeUpdated,
      }}
    >
      {children}
    </recordsToBeUpdatedContext.Provider>
  );
};

export default RecordsToBeUpdatedProvider;
