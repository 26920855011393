import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faExternalLinkSquareAlt,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  Row,
  Modal,
  ModalBody,
  Button,
  Tooltip,
} from 'reactstrap';
import { numberWithCommas } from 'utils/helper';
import BrokerCard from 'pages/Search/common/BrokerCard';
import ImageGallery from 'react-image-gallery';
import { useQuery } from '@apollo/client';
import {
  ASSET_GALLERIES_SYNCED,
  GET_ASSET_GALLERY,
} from 'pages/Search/CrexiSearchGraphQL';
import PageLoader from 'common/PageLoader';
import NoData from 'common/NoData';
import { toast } from 'react-toastify';
import closeImage from 'assets/close-icon.svg';

const ProperyCard = ({
  name,
  brokers,
  crexiUrl,
  askingPrice,
  description,
  thumbnailUrl,
  marketingDescription,
  assetCrexiId,
  setGalleryLoading,
  galleryImage,
  setGalleryImage,
}) => {
  const [isGalleryModal, setGalleryModel] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const galleryMessage = () => setTooltipOpen(!tooltipOpen);

  const renderMarketingDescription = () => {
    return <div dangerouslySetInnerHTML={{ __html: marketingDescription }} />;
  };

  const toggleGalleryModal = () => {
    setGalleryModel(!isGalleryModal);
  };

  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    GET_ASSET_GALLERY,
    {
      variables: {
        assetCrexiId,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: ASSET_GALLERIES_SYNCED,
      updateQuery: (prev, { subscriptionData }) => {
        const gallerySyncStatus =
          subscriptionData?.data?.assetGalleriesSynced?.status;
        if (gallerySyncStatus) {
          setGalleryLoading(false);
          toast.success('Asset galleries sync successfully');
          refetch();
        } else if (!gallerySyncStatus) {
          setGalleryLoading(false);
          toast.success('Sync failed! Please try again later');
        }
        return prev;
      },
    });
    return () => subscribe();
  }, []);

  const getArrayData = data?.getAssetGalleryUrl?.assetGalleryS3Url?.map(
    (value) => ({
      original: value?.image_url,
      thumbnail: value?.image_url,
    })
  );

  useEffect(() => {
    setGalleryImage(getArrayData);
  }, [data]);

  if (loading) return <PageLoader />;

  return (
    <Col sm="12" lg="5">
      <Modal
        isOpen={isGalleryModal}
        toggle={toggleGalleryModal}
        className="modal-dialog-centered modal-xl"
      >
        <ModalBody className="position-relative">
          <Button
            color="link"
            className="position-absolute close-btn"
            onClick={toggleGalleryModal}
          >
            <img src={closeImage} alt="Close Icon" />
          </Button>
          {galleryImage?.length > 0 ? (
            <ImageGallery
              items={galleryImage}
              showFullscreenButton={false}
              lazyLoad
            />
          ) : (
            <NoData customMessage="No asset image found" />
          )}
        </ModalBody>
      </Modal>

      <Card className="main-card-property h-100">
        <Button
          className="text-left p-0 border-0"
          onClick={toggleGalleryModal}
          disabled={galleryImage?.length === 0 || galleryImage === undefined}
        >
          <div className="property-caption-parent">
            <CardImg top width="100%" src={thumbnailUrl} alt={name} />

            <div className="property-caption-child">
              <div className="d-flex justify-content-between mt-auto">
                <div className="w-50">
                  <h5 type="button">{name || 'Not Specified'}</h5>
                  <p>{description || 'Not Specified'}</p>
                </div>
                <div className="text-right">
                  {galleryImage?.length > 1 ? (
                    <Button color="link" className="border-light mb-2 mr-2">
                      {error?.message ? (
                        <>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="align-middle text-danger"
                            id="galleryError"
                          />
                          <Tooltip
                            isOpen={tooltipOpen}
                            target="galleryError"
                            toggle={galleryMessage}
                          >
                            Something went wrong
                          </Tooltip>
                        </>
                      ) : (
                        <FontAwesomeIcon
                          icon={faImage}
                          className="align-middle text-white"
                        />
                      )}
                      {galleryImage?.length > 1 ? (
                        <span className="text-white font-size-12 ml-2">
                          + {galleryImage?.length - 1}
                        </span>
                      ) : null}
                      <br />
                      <span className="text-white">Gallery</span>
                    </Button>
                  ) : null}

                  <br />
                </div>
              </div>
            </div>
          </div>
        </Button>
        <CardBody className="p-3">
          <Row className="row broker-card-body">
            {brokers &&
              brokers.map((broker) => {
                return <BrokerCard {...broker} key={broker.id} />;
              })}

            <Col lg="auto" className="mb-2 ">
              <Card className="border-radius-8px mb-2 p-1 starting-bid">
                <CardBody className="p-2">
                  <h6 className="mb-1 font-size-14">ASKING PRICE</h6>
                  <p className="m-0">
                    <strong>
                      {askingPrice
                        ? `$${numberWithCommas(askingPrice)}`
                        : 'Not Specified'}
                    </strong>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <div className="mt-2 description-h1">
                {renderMarketingDescription() || 'Not Specified'}
              </div>
            </Col>
          </Row>
          <CardText className="my-3">
            <a href={crexiUrl} target="_blank" rel="noopener noreferrer">
              {crexiUrl}

              <FontAwesomeIcon
                icon={faExternalLinkSquareAlt}
                color="#565082"
                className="ml-2 align-middle"
              />
            </a>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};
export default ProperyCard;
