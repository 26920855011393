import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Collapse,
  Button,
  Badge,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/client';

import LoadingButton from 'common/LoadingButton';
import PropertyFeatureBlock from 'pages/Search/common/PropertyFeatureBlock';
import { dateTimeFormatter, numberWithCommas } from 'utils/helper';
import BrokerCard from 'pages/Search/common/BrokerCard';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DELETE_PENDING_ASSET,
  PROJECT_CHECKOUT_STATUS,
} from 'pages/Search/CrexiSearchGraphQL';

const PropertyItem = ({
  id,
  name,
  details,
  brokers,
  refetch,
  subtypes,
  locations,
  askingPrice,
  thumbnailUrl,
  pendingOrder,
  companyNames,
  lastUpdatedAt,
  handleOpenDetail,
  status,
  crexiUrl,
  projectPlan = null,
  isPrivate,
}) => {
  const propertyType =
    details && details.propertyType ? details.propertyType : '';
  const companyName = null;
  const [statusMessage, setStatus] = useState('');
  const { plan } = useSelector((state) => state.salesPortal);
  const [deletePendingOrder, { loading }] = useMutation(DELETE_PENDING_ASSET);
  const [
    projectCheckoutStatus,
    { error: projectCheckOutError, data: projectCheckOutData },
  ] = useLazyQuery(PROJECT_CHECKOUT_STATUS);

  useEffect(() => {
    if (!loading && projectCheckOutData?.projectCheckoutStatus) {
      const {
        projectCheckoutStatus: { status, message, projectPlan: planType },
      } = projectCheckOutData;

      if (status) {
        handleOpenDetail(id, {
          crexiUrl,
          name,
          projectPlan: planType || projectPlan,
        });
      } else {
        setStatus(message);
      }
    }
  }, [projectCheckOutData, loading]);

  if (projectCheckOutError) {
    setStatus('Something went wrong!!');
  }

  const getPropertyAddress = () => {
    let propertyAddress = null;
    locations.forEach((row) => {
      const { address, city, county, state, zip } = row;
      propertyAddress = `${address || ''}, ${county || ''}, ${city || ''}, ${
        state && state.name ? state.name : ''
      } , ${zip}`;
    });
    return propertyAddress;
  };
  const [brokerCollapse, setCollapse] = useState(false);

  const toggle = (e) => {
    e.stopPropagation();
    setCollapse(!brokerCollapse);
  };

  const rendersBrokerCards = () => {
    return (
      <>
        {brokers.map((row, index) => {
          if (index < 2) {
            return <BrokerCard {...row} key={row.id} />;
          }
          return null;
        })}
        {brokers.length > 2 ? (
          <Col lg="2" className="mb-2 ">
            <div className="remaining-brokers">
              <Button
                color="secondary"
                className="bg-white border text-dark count-btn"
                onClick={toggle}
              >
                + {brokers.length - 2}
              </Button>
              <Collapse isOpen={brokerCollapse}>
                {brokers.map((row, index) => {
                  if (index > 1) {
                    return (
                      <Row key={row.id}>
                        <BrokerCard {...row} />
                      </Row>
                    );
                  }
                  return null;
                })}
              </Collapse>
            </div>
          </Col>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderCompanyName = () => {
    return companyNames.map((row) => {
      return <span key={row}>{row}</span>;
    });
  };

  const handleDeletePendingOrder = async (e) => {
    e.stopPropagation();
    try {
      await deletePendingOrder({
        variables: { assetId: id, status: 'Inprogress' },
        update: async (cache, data) => {
          toast.success('Asset deleted successfully');
          await refetch();
        },
        error: (e) => {
          toast.error(e.message);
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const renderPendingOptions = () => {
    return (
      <div className="text-right">
        Last Updated: {dateTimeFormatter(lastUpdatedAt)} &nbsp; &nbsp; | &nbsp;
        &nbsp;
        <LoadingButton
          color="link"
          disabled={loading}
          onClick={(e) => handleDeletePendingOrder(e)}
          className="p-0 font-size-12 font-weight-bold view-detail-btn"
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            color="#ed0f08"
            title="Delete Asset"
          />
        </LoadingButton>
      </div>
    );
  };

  const handleAssetOnClick = () => {
    projectCheckoutStatus({
      variables: {
        assetId: id,
        planName: pendingOrder ? projectPlan : plan?.type || 'PLATINUM',
      },
      fetchPolicy: 'network-only',
    });
  };

  return (
    <div>
      <Card
        className="property-card-detail shadow mb-3 px-3 pt-3 pb-2 color-inherit text-decoration-none border-radius-8px prop-list-card"
        tag="a"
        onClick={() => handleAssetOnClick()}
      >
        <div className="border-bottom pb-3 mb-3">
          <div>
            <CardBody className="p-0">
              {pendingOrder ? renderPendingOptions() : ''}
              <Row>
                <Col md="2" className="pr-2">
                  <div className="d-flex">
                    <CardImg
                      className="property-image border-radius-8px"
                      top
                      width="100%"
                      src={thumbnailUrl}
                      alt="Card image cap"
                    />
                    <div className="mb-3 mr_top_ipad d-xl-none ml-3">
                      <div className="d-flex">
                        <CardTitle
                          tag="h5"
                          className="mt-3 mt-lg-0 mr-2 "
                          title={name}
                        >
                          {name}
                        </CardTitle>
                        <div>
                          <Badge className="font-size-13">{status}</Badge>
                        </div>
                        <span className="text-danger">{statusMessage}</span>
                      </div>
                      <CardSubtitle
                        tag="p"
                        className="mb-2 text-muted"
                        title={companyName}
                      >
                        {companyNames && companyNames.length
                          ? renderCompanyName()
                          : ''}
                      </CardSubtitle>
                    </div>
                  </div>
                </Col>
                <Col md="10" className="flex-fill d-flex flex-column">
                  <div className="mb-3 mr_top_ipad d-none d-xl-block">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <CardTitle
                          tag="h5"
                          className="text-truncate mt-3 mt-lg-0 mr-2"
                          title={name}
                        >
                          {name}
                        </CardTitle>

                        <div>
                          <Badge className="font-size-13 align-right">
                            {status}
                          </Badge>
                        </div>
                        {isPrivate && (
                          <div>
                            <Badge
                              className="font-size-13 align-right ml-2"
                              color="danger"
                            >
                              Private
                            </Badge>
                          </div>
                        )}
                      </div>
                      <span className="text-danger">{statusMessage}</span>
                    </div>
                    <CardSubtitle
                      tag="p"
                      className="mb-2 text-muted text-truncate"
                      title={companyName}
                    >
                      {companyNames && companyNames.length ? (
                        <span title={renderCompanyName()?.[0]?.key}>
                          {renderCompanyName()}
                        </span>
                      ) : (
                        ''
                      )}
                    </CardSubtitle>
                  </div>
                  <div className="mt-auto">
                    <Row>
                      <Col sm="12" lg="5" className="my-xl-0 my-2">
                        <PropertyFeatureBlock
                          heading="Address"
                          bottomSpace={false}
                          value={
                            locations && locations.length
                              ? getPropertyAddress()
                              : ''
                          }
                        />
                      </Col>
                      <Col sm="12" lg="3">
                        <PropertyFeatureBlock
                          heading="PROPERTY TYPE"
                          bottomSpace={false}
                          value={propertyType}
                        />
                      </Col>
                      <Col sm="12" lg="3">
                        <PropertyFeatureBlock
                          heading="SUB TYPE"
                          bottomSpace={false}
                          value={subtypes}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </div>
        </div>

        <div>
          <Row>
            <Col lg="9">
              <Row className="align-items-center">{rendersBrokerCards()}</Row>
            </Col>
            <Col lg="3" className="ml-auto mb-2 text-right">
              <div className="d-inline-block">
                <Card className="border-radius-8px start-bid">
                  <CardBody className="p-0">
                    <h6 className="mb-1 font-weight-normal font-size-14">
                      ASKING PRICE
                    </h6>
                    <p className="m-0 font-size-16">
                      <strong>
                        {' '}
                        {askingPrice
                          ? `$${numberWithCommas(askingPrice)}`
                          : 'Not Specified'}
                      </strong>
                    </p>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};
export default PropertyItem;
