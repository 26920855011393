import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingButton from 'common/LoadingButton';

export const DownloadFinalVideo = ({ downloadPath }) => {
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const downloadVideo = (videoURL) => {
    setHasDownloaded(true);
    const { pathname } = new URL(videoURL);
    const data = {
      file: decodeURI(pathname.replace('/', '')),
    };
    const config = {
      responseType: 'blob',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-file-download?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${pathname}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setHasDownloaded(false);
      })
      .catch(() => {
        setHasDownloaded(false);
        toast.error('An error occurred while downloading.');
      });
  };

  return (
    <LoadingButton
      size="sm"
      color="success"
      loading={hasDownloaded}
      disabled={hasDownloaded}
      className="ml-4 px-1 py-0 font-size-12"
      style={{ background: '#64BC26', color: '#fff' }}
      onClick={() => downloadVideo(downloadPath)}
    >
      Download Final Video File
    </LoadingButton>
  );
};
