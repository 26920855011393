import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import crypto from 'crypto';
import Header from 'common/Header';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import VideoPlayerProvider from 'contexts/VideoPlayerContext';
import { Helmet } from 'react-helmet';
import VideoPlayerScreen from './components/VideoPlayerScreen';
import {
  GET_PLAYER_DETAILS,
  REVIEW_ASSET_COMMENT_STATUS,
} from './videoPlayerGraphQL';

const VideoPlayer = ({
  isModal,
  isVidPitchPlayer,
  videoSrc,
  isAssetModel,
  trackVideo,
}) => {
  const { videoId } = useParams();
  const [
    getReviewLinkDetails,
    { subscribeToMore, data, refetch, error, loading },
  ] = useLazyQuery(GET_PLAYER_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!isVidPitchPlayer) {
      const subscribe = subscribeToMore({
        document: REVIEW_ASSET_COMMENT_STATUS,
        updateQuery: (prev, { subscriptionData }) => {
          if (subscriptionData?.data?.reviewAssetCommentStatus) {
            return refetch();
          }
          return prev;
        },
      });

      return () => subscribe();
    }
    return () => null;
  }, []);

  const decryptPassword = async (videoEncodedId) => {
    try {
      const textParts = videoEncodedId.split('.');
      const iv = Buffer.from(textParts.shift(), 'hex');
      const encryptedText = Buffer.from(textParts.join('.'), 'hex');
      const decipher = crypto.createDecipheriv(
        process.env.REACT_APP_CRYPTO_ALGORITHM,
        process.env.REACT_APP_CRYPTO_KEY,
        iv
      );
      const decrypted = Buffer.concat([
        decipher.update(encryptedText),
        decipher.final(),
      ]);
      return decrypted.toString();
    } catch (error) {
      return null;
    }
  };

  const getUrlParams = async () => {
    const decryptedResult = await decryptPassword(videoId);
    const assetId = decryptedResult ? JSON.parse(decryptedResult).id : 0;
    getReviewLinkDetails({ variables: { id: assetId } });
  };

  useEffect(() => {
    if (!isModal && videoId) {
      getUrlParams();
    }
  }, []);

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <>
        <Helmet defaultTitle="VidTech Player" />
        <Header pilotRegistration />
        <NoData customMessage="No such asset exist. Please use correct player URL." />
      </>
    );
  }

  if (isModal) {
    return (
      <VideoPlayerProvider>
        <div className="container-fluid video-player-videoms text-light">
          <div className="video-wrapper">
            <VideoPlayerScreen
              isVidPitchPlayer={isVidPitchPlayer}
              isAssetModel={isAssetModel}
              trackVideo={trackVideo}
              videoSrc={videoSrc}
              isModal={isModal}
              playerData={{}}
            />
          </div>
        </div>
      </VideoPlayerProvider>
    );
  }

  if (data) {
    return (
      <VideoPlayerProvider>
        <Helmet defaultTitle="VidTech Player" />
        <Header pilotRegistration />
        <div className="container-fluid video-player-videoms text-light">
          <div className="video-wrapper">
            <VideoPlayerScreen
              isModal={isModal}
              playerData={data}
              videoSrc={videoSrc}
              refetch={refetch}
            />
          </div>
          <div id="annotationScreenshot" />
        </div>
      </VideoPlayerProvider>
    );
  }
  return null;
};

export default VideoPlayer;
