import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import ErrorBoundary from 'common/ErrorBoundary';
import Header from 'pages/PMPortal/common/Header';
import ProjectTableProvider from './components/ProjectTableProvider';

const FILTER = {
  status: 'ACTIVE',
  limit: 10,
  offset: 0,
  page: 1,
};

const PMsActiveProjects = () => {
  const {
    activeProjectInfo: {
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.pmPortal);

  const [filters, setFilters] = useState(FILTER);

  useEffect(() => {
    setFilters({ ...filters, offset, page });
  }, [page, offset]);

  return (
    <>
      <Header heading="Projects" />
      <div>
        <Row className="align-items-center">
          <Col md="6">
            <h6 className="my-3">Active Projects</h6>
          </Col>
        </Row>

        <div>
          <ErrorBoundary>
            <ProjectTableProvider filters={filters} setFilters={setFilters} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
export default PMsActiveProjects;
