import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { LIST_REVIEWED_PROJECTS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import ProjectList from './components/ProjectList';

const FILTER = {
  limit: 10,
  offset: 0,
};

const ProjectListProvider = ({ slug }) => {
  const [filters, setFilters] = useState(FILTER);
  const [items, setItems] = useState([]);
  let totalCount;
  const { data, loading, error } = useQuery(LIST_REVIEWED_PROJECTS, {
    variables: filters,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && data?.listReviewedProjects?.reviewProjectData) {
      setItems([...items, ...data.listReviewedProjects.reviewProjectData]);
    }
  }, [data, loading]);

  if (error) return <div>{error.message}</div>;

  if (data) {
    totalCount = data.listReviewedProjects.totalCount;
  }
  if (items.length) {
    return (
      <ProjectList
        PROJECT_LIST={items}
        slug={slug}
        filters={filters}
        totalCount={totalCount}
        setFilters={setFilters}
        loading={loading}
      />
    );
  }

  return (
    <li className="mt-4 text-center">
      <span className="text-dark">
        {loading ? 'Loading...' : 'No Projects Available For Review'}
      </span>
    </li>
  );
};

export default ProjectListProvider;
