import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileVideo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const ModifyVideo = ({
  uuid,
  videoId,
  assetCrexiId,
  assetTable,
  pipelineStatus,
}) => {
  const renderActionIcon = () => {
    if (pipelineStatus !== 'inprogress') {
      return (
        <FontAwesomeIcon
          icon={faFileVideo}
          className="font-size-18"
          title="Modify Video"
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="font-size-18"
        title="Video is under rendering."
      />
    );
  };

  return (
    <>
      <div className={assetTable ? 'text-center' : ''}>
        <Link
          size="sm"
          type="button"
          className={
            !assetTable ? 'btn btn-outline-primary px-4 py-2 temp-button' : ''
          }
          to={{
            pathname:
              pipelineStatus !== 'inprogress' ? `/${uuid}/modify-video` : '',
            // state: { videoId, assetCrexiId },
          }}
        >
          {assetTable ? renderActionIcon() : 'MODIFY VIDEO'}
        </Link>
      </div>
    </>
  );
};

export default ModifyVideo;
