import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Filters from './components/Filters';
import FlaggedRecordsFilter from './components/FlaggedRecordsFilter';

const BrokerNavigations = () => {
  return (
    <div className="management-tabs">
      <Nav vertical className="tab-ul row">
        <NavItem>
          <NavLink
            className="nav-link font-weight-600 font-size-14 active text-primary"
            to="/brokers-directory/update-missing-details"
          >
            Brokers Directory
          </NavLink>
          <Nav vertical>
            <NavLink
              className="nav-link"
              isActive={(match) => match}
              to="/brokers-directory/updated-records"
            >
              Updated Records
            </NavLink>
            <NavLink
              className="nav-link missing-details-tab"
              isActive={(match) => match}
              to="/brokers-directory/update-missing-details"
            >
              Update Missing Details
            </NavLink>
            <Filters />
            <NavLink
              className="nav-link flagged-details-tab"
              isActive={(match) => match}
              to="/brokers-directory/flagged-records"
            >
              Flagged Records
            </NavLink>
            <FlaggedRecordsFilter />
            <NavLink
              className="nav-link "
              isActive={(match) => match}
              to="/brokers-directory/HS-out-of-sync"
            >
              HS Non Sync
            </NavLink>
          </Nav>
        </NavItem>
      </Nav>
    </div>
  );
};

export default BrokerNavigations;
