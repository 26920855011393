import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import imageLine from 'assets/image-line.svg';
import excelLogo from 'assets/excelLogo.png';
import { FILE_TYPES, FILE_FORMATS } from 'constants/fileFormatAndTypes';
import { getFileName } from 'utils/helper';
import AssetPreviewModal from 'common/AssetPreviewModal';
import { useMutation } from '@apollo/client';
import { PILOT_ASSET_UPLOADED } from 'pages/Pilot/PilotPortalGraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import eyeVisible from 'assets/eye-line.svg';
import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import AssetActions from '../AssetActions';

const AssetFile = ({
  allSelected,
  fileKey,
  ETag,
  handleSelectFile,
  isPilotPortal,
  refetch,
  fileLength,
  pilotID,
  refetchPilotVideoImages,
  isMarkedCompleted,
  isSalesPortal,
  hideCheckbox,
  portalSlug,
  version,
}) => {
  const [assetModal, setAssetModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState({});

  const toggleDeleteModal = (status, key) => {
    setDeleteModal(status);
    setDeleteValue(key);
  };

  const [pilotAssetUploaded] = useMutation(PILOT_ASSET_UPLOADED, {
    onCompleted: async () => {
      await refetch();
      await refetchPilotVideoImages();
    },
  });

  const toggleAssetModal = () => setAssetModal(!assetModal);

  const { IMAGE, VIDEO, OTHER, XLSX: XLSX_FILE } = FILE_TYPES;
  const {
    JPG,
    GIF,
    BMP,
    PNG,
    M4V,
    AVI,
    MPG,
    MP4,
    MKV,
    WEBM,
    MOV,
    WEBP,
    TIFF,
    TIF,
    JPEG,
    RAW,
    SR,
    CR,
    NEF,
    ORF,
    XLSX,
  } = FILE_FORMATS;

  const [fileDetail, setFileDetail] = useState(null);
  const [status, setStatus] = useState(false);

  const fileCountForAsset = () => {
    if (fileLength) {
      return !(fileLength - 1 === 0);
    }
    return true;
  };

  const downloadJSON = async (url) => {
    try {
      // Fetch the JSON data from the provided URL
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch the JSON file.');
      }

      const jsonData = await response.json();

      // Create a Blob from the JSON data
      const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: 'application/json',
      });

      // Create a temporary link element
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      // Set the download attribute and click the link to trigger the download
      link.href = downloadUrl;
      link.download = 'data.json'; // Filename of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the JSON file:', error);
    }
  };

  const deleteFile = (url) => {
    const data = {
      file: url,
    };
    const config = {
      responseType: 'blob',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-file-delete?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then(async ({ message }) => {
        toast.success(message);
        await pilotAssetUploaded({
          variables: {
            id: pilotID,
            isAssetUploaded: fileCountForAsset(),
          },
        });
      })
      .catch(() => {
        toast.error('Failed to delete the asset.');
      });
  };

  const getExtension = (filename) => {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case JPG:
      case GIF:
      case BMP:
      case PNG:
      case WEBP:
      case TIFF:
      case JPEG:
      case TIF:
      case RAW:
      case NEF:
      case ORF:
      case SR:
      case CR:
        //etc
        return true;
      default:
    }
    return false;
  };

  const isVideo = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case M4V:
      case AVI:
      case MPG:
      case MP4:
      case WEBM:
      case MKV:
      case MOV:
        // etc
        return true;
      default:
    }
    return false;
  };

  const isXLSX = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case XLSX:
        // etc
        return true;
      default:
    }
    return false;
  };

  const displayImage = async () => {
    try {
      let fileType = null;
      if (isImage(fileKey)) {
        fileType = IMAGE;
      } else if (isVideo(fileKey)) {
        fileType = VIDEO;
      } else if (isXLSX(fileKey)) {
        fileType = XLSX_FILE;
      } else {
        fileType = OTHER;
      }
      setFileDetail({
        Key: fileKey,
        url: `${process.env.REACT_APP_S3_BASE_ENDPOINT}${encodeURIComponent(
          fileKey
        )}`,
        fileType,
      });
    } catch (err) {
      toast.error(`Error ${err}`);
    }
  };

  useEffect(() => {
    displayImage();
  }, []);

  useEffect(() => {
    setStatus(allSelected);
  }, [allSelected]);

  const renderAssetFile = (fileName) => {
    if (fileDetail) {
      const { url, fileType, Key } = fileDetail;
      if (fileType === IMAGE)
        return (
          <>
            {!isPilotPortal && (
              <img
                src={url}
                alt={url}
                style={{ height: '120px', objectFit: 'cover' }}
                onClick={toggleAssetModal}
                onKeyDown={toggleAssetModal}
                role="presentation"
              />
            )}

            {isPilotPortal && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn font-size-12 font-weight-bold border-0 outline-none p-0 btn-block text-left  d-flex justify-content-between align-items-center"
                  onClick={toggleAssetModal}
                >
                  <span className="show-elipsis-100 w-75">{fileName}</span>
                  <span className="float-right">
                    <img src={eyeVisible} alt="eye Visible" />
                  </span>
                </button>
                <RBAC
                  allowedRoles={
                    isSalesPortal
                      ? [
                          ROLES.VIDEOMS_SALES_PERSON,
                          ROLES.VIDEOMS_PM,
                          ROLES.VIDEOMS_QA,
                          ROLES.ADMIN,
                          ROLES.VIDEOMS_QC,
                        ]
                      : [
                          ROLES.VIDEOMS_PILOT,
                          ROLES.VIDEOMS_SALES_PERSON,
                          ROLES.ADMIN,
                          ROLES.VIDEOMS_PM,
                          ROLES.VIDEOMS_QC,
                        ]
                  }
                >
                  <>
                    {!isMarkedCompleted && (
                      <button
                        type="button"
                        className="btn font-size-12 border-0"
                        onClick={() => toggleDeleteModal(true, Key)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} color="#565082" />
                      </button>
                    )}
                  </>
                </RBAC>
              </div>
            )}
          </>
        );
      if (fileType === VIDEO) {
        return (
          <>
            {!isPilotPortal && (
              <div className="d-flex justify-content-center video-main-div">
                <ReactPlayer url={url} onClick={() => toggleAssetModal()} />
              </div>
            )}
            {isPilotPortal && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn font-size-12 font-weight-bold border-0 outline-none p-0 btn-block text-left"
                  onClick={toggleAssetModal}
                >
                  <span className="show-elipsis-100 w-75">{fileName}</span>
                  <span className="float-right">
                    <img src={eyeVisible} alt="eye Visible" />
                  </span>
                </button>
                <RBAC
                  allowedRoles={
                    isSalesPortal
                      ? [
                          ROLES.VIDEOMS_SALES_PERSON,
                          ROLES.VIDEOMS_PM,
                          ROLES.VIDEOMS_QA,
                          ROLES.ADMIN,
                          ROLES.VIDEOMS_QC,
                        ]
                      : [
                          ROLES.VIDEOMS_PILOT,
                          ROLES.VIDEOMS_SALES_PERSON,
                          ROLES.ADMIN,
                          ROLES.VIDEOMS_PM,
                          ROLES.VIDEOMS_QC,
                        ]
                  }
                >
                  <>
                    {!isMarkedCompleted && (
                      <button
                        type="button"
                        className="btn font-size-12 border-0"
                        onClick={() => toggleDeleteModal(true, Key)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} color="#565082" />
                      </button>
                    )}
                  </>
                </RBAC>
              </div>
            )}
          </>
        );
      }
      if (fileType === XLSX_FILE) {
        return (
          <div className="text-center mt-1">
            <img
              src={excelLogo}
              alt={url}
              style={{ height: '100px' }}
              role="presentation"
            />
          </div>
        );
      }
      return (
        <>
          {!isPilotPortal && (
            <img
              src={imageLine}
              alt="Other"
              onClick={toggleAssetModal}
              onKeyDown={toggleAssetModal}
              role="presentation"
            />
          )}
          {isPilotPortal && (
            <div className="d-flex">
              {/* <button
                type="button"
                className="btn font-size-12 font-weight-bold border-0 outline-none p-0 btn-block text-left"
                onClick={toggleAssetModal}
              >
                {fileName}
                <span className="float-right">
                  <img src={eyeVisible} alt="eye Visible" />
                </span>
              </button> */}

              <button
                type="button"
                className="btn font-size-12 font-weight-bold border-0 outline-none p-0 btn-block text-left"
                onClick={() => downloadJSON(fileDetail.url)}
              >
                {fileName}
                <span className="float-right" title="Download">
                  <FontAwesomeIcon icon={faDownload} color="#565082" />
                </span>
              </button>
              <RBAC
                allowedRoles={
                  isSalesPortal
                    ? [
                        ROLES.VIDEOMS_SALES_PERSON,
                        ROLES.VIDEOMS_PM,
                        ROLES.VIDEOMS_QA,
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_QC,
                      ]
                    : [
                        ROLES.VIDEOMS_PILOT,
                        ROLES.VIDEOMS_SALES_PERSON,
                        ROLES.VIDEOMS_PM,
                        ROLES.ADMIN,
                        ROLES.VIDEOMS_QC,
                      ]
                }
              >
                <>
                  {!isMarkedCompleted && (
                    <button
                      type="button"
                      className="btn font-size-12 border-0"
                      onClick={() => toggleDeleteModal(true, Key)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} color="#565082" />
                    </button>
                  )}
                </>
              </RBAC>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const selectedFile = (e) => {
    setStatus(e.target.checked);
    handleSelectFile(fileDetail, e.target.checked);
  };

  if (fileDetail) {
    const fileName = getFileName(fileKey);

    return (
      <div
        title={fileName}
        key={ETag}
        className={
          isPilotPortal
            ? 'col-md-12 mb-2 font-weight-bold font-size-12'
            : 'align-items-center mb-3 bg-white border img-div mx-3 position-relative'
        }
      >
        {!hideCheckbox && (
          <>
            {!isPilotPortal && (
              <div className="d-flex px-2 justify-content-between">
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={status}
                    onChange={(e) => selectedFile(e)}
                  />
                </FormGroup>
                <span className="mt-1 d-flex">
                  {fileDetail.fileType === FILE_TYPES.XLSX ? (
                    <span className="project-name-ellipses">{fileName}</span>
                  ) : (
                    'Version -'
                  )}{' '}
                  {version}
                </span>
                {/* <AssetActions
                  fileName={fileName}
                  portalSlug={portalSlug}
                  version={version}
                  fileType={fileDetail.fileType}
                /> */}
              </div>
            )}
          </>
        )}
        <div
          className={
            isPilotPortal
              ? 'video-player bg-grey p-2 cursor-pointer'
              : 'video-player p-2 cursor-pointer text-center'
          }
        >
          {renderAssetFile(fileName)}
          <AssetActions
            fileName={fileName}
            portalSlug={portalSlug}
            version={version}
            fileType={fileDetail.fileType}
          />
        </div>
        <AssetPreviewModal
          assetModal={assetModal}
          toggleAssetModal={toggleAssetModal}
          assetURL={fileDetail.url}
          fileType={fileDetail.fileType}
          fileName={fileName}
        />

        {isDeleteModalOpen && (
          <Modal
            isOpen={isDeleteModalOpen}
            toggle={() => toggleDeleteModal(false)}
            className="modal-dialog-centered"
          >
            <ModalBody className="text-center py-5">
              <h5 className="mt-2">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faTrashAlt}
                  color="red"
                />{' '}
                Are you sure?
              </h5>
              <p className="mb-0">Do you really want to delete this file.</p>
            </ModalBody>
            <ModalFooter className="bg-grey-upload">
              <Button
                outline
                color="primary"
                className="mr-2 btn-sm px-4 font-size-13"
                onClick={() => toggleDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                className="btn-sm px-4 font-size-13"
                onClick={() => deleteFile(deleteValue)}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
  return null;
};
export default AssetFile;
