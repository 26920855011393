import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  CardTitle,
  Col,
  Button,
  Row,
  Modal,
  ModalBody,
} from 'reactstrap';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
//
import RBAC from 'hoc/RBAC';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import AssetPreviewModal from 'common/AssetPreviewModal';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import UploadModal from 'pages/Pilot/common/UploadModal';
import {
  PILOT_ASSET_FOLDERS_LISTING,
  PILOT_ASSET_UPDATED,
  PILOT_CUSTOM_FOLDERS,
  PILOT_MARK_COMPLETED_FOLDER,
} from 'pages/Pilot/PilotPortalGraphQL';
import ProjectAssetProvider from 'components/ProjectAssetProvider';
import FormikInputField from 'components/Fields/FormikInputField';
import { ASSET_LISTING_CUSTOM } from 'pages/Pilot/constant';
import { parseToInteger } from 'utils/helper';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import AddNote from '../AddNote';
import MarkComplete from '../MarkComplete';

const customShotSchema = Yup.object({
  title: Yup.string()
    .required('Title is required')
    .test(
      'remove-white-spaces',
      'White space not allowed as a character',
      (title) => {
        if (title) return title.trim().length > 0;
        return false;
      }
    ),
  description: Yup.string()
    .required('Description is required')
    .test(
      'remove-white-spaces',
      'White space not allowed as a character',
      (description) => {
        if (description) return description.trim().length > 0;
        return false;
      }
    ),
});

let fileNamesWithCount = {};

const CustomShots = ({
  selectProject,
  isProjectAccepted,
  verifyCompletedStatus,
  projectStatus,
  pilotFlagStatusRefetch,
}) => {
  const [assetModal, setAssetModal] = useState(false);
  const [modalData, setModalData] = useState({
    exampleLink: '',
    folderTypes: '',
    imageTitle: '',
  });

  const [isCustomShotModalOpen, setCustomShotModal] = useState(false);
  const [fileNameCount, setFileNameCount] = useState({});

  const toggleCustomShotModal = (status) => {
    setCustomShotModal(status);
  };
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    PILOT_ASSET_FOLDERS_LISTING,
    {
      variables: {
        projectId: parseToInteger(selectProject),
        folderTypes: ASSET_LISTING_CUSTOM,
        isMarkedCompleted: verifyCompletedStatus ? false : null,
        status: projectStatus,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_ASSET_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.pilotAssetUpdated) {
          return refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_MARK_COMPLETED_FOLDER,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.pilotMarkCompletedFolder) {
          return refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  const [pilotCustomFolders] = useMutation(PILOT_CUSTOM_FOLDERS, {
    onCompleted: async ({ pilotCustomFolders }) => {
      await refetch();
      toggleCustomShotModal(false);
      toast.success(pilotCustomFolders.message);
    },
    onError: ({ message }) => {
      toggleCustomShotModal(false);
      toast.error(message);
    },
  });

  const toggleAssetModal = () => setAssetModal(!assetModal);

  // const togglePreviewModal = (exampleLink, folderTypes, imageTitle) => {
  //   // setModalData({ exampleLink, folderTypes, imageTitle });
  //   // toggleAssetModal();
  // };

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const { pilotAssetFoldersListing } = data;

  const renderCount = () => {
    if (!pilotAssetFoldersListing.length) return <>Custom Shots / B-Roll</>;

    return <>{pilotAssetFoldersListing.length} Custom Shots / B-Roll</>;
  };

  const setCheckMarkColor = (isMarkCompleted) => {
    if (isMarkCompleted) {
      return '#3AB42B';
    }

    return '#ccc';
  };

  const setFilesCount = (assetLocation, count) => {
    const temp = { ...fileNamesWithCount, [assetLocation]: count };
    fileNamesWithCount = { ...temp };
    setFileNameCount(assetLocation);
  };

  const handleOnChange = async ({ title, description }) => {
    try {
      await pilotCustomFolders({
        variables: {
          title: title.trim(),
          description: description.trim(),
          folderTypes: 'Custom',
          projectId: parseToInteger(selectProject),
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderList = () => {
    if (!pilotAssetFoldersListing.length)
      return <NoData customMessage="No Data Available" />;

    return pilotAssetFoldersListing.map(
      ({
        uploadingPath,
        title,
        description,
        folderTypes,
        exampleLink,
        projectId,
        id,
        isAssetUploaded,
        isMarkedCompleted,
        project,
        hasNoteAdded,
      }) => {
        return (
          <div key={id}>
            <AWSFolderAssetsProvider assetLocation={uploadingPath}>
              <Card body className="mt-2 border-radius-8px border-0 shadow ">
                <div className="d-flex">
                  <div className="mr-2">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color={setCheckMarkColor(
                        isMarkedCompleted,
                        isAssetUploaded
                      )}
                    />
                  </div>
                  <div className="flex-fill">
                    <CardTitle className="font-size-14 font-weight-500">
                      {title}
                    </CardTitle>
                    <Row>
                      <Col md={4} className="">
                        <div
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                        {/* <div>
                          <Button
                            size="sm"
                            className="btn btn-link font-size-13 outline-none p-0"
                            color="link"
                            onClick={() =>
                              togglePreviewModal(
                                exampleLink,
                                folderTypes,
                                title
                              )
                            }
                          >
                            View Example
                          </Button>
                        </div> */}
                      </Col>
                      <>
                        <Col md={5}>
                          <ProjectAssetProvider
                            handleSelectAll={() => {}}
                            handleSelectFile={() => {}}
                            isPilotPortal
                            pilotID={id}
                            refetchPilotVideoImages={refetch}
                            isMarkedCompleted={isMarkedCompleted}
                            setFilesCount={setFilesCount}
                          />
                        </Col>

                        <Col md={3}>
                          {isProjectAccepted && (
                            <div className="text-right">
                              <RBAC
                                allowedRoles={[
                                  ROLES.VIDEOMS_PILOT,
                                  ROLES.VIDEOMS_PM,
                                  ROLES.VIDEOMS_QC,
                                  ROLES.ADMIN,
                                ]}
                              >
                                <>
                                  {isAssetUploaded && (
                                    <MarkComplete
                                      projectId={projectId}
                                      isProjectAccepted={isProjectAccepted}
                                      folderId={id}
                                      isMarkCompleted={isMarkedCompleted}
                                      refetchAssets={refetch}
                                      pilotFlagStatusRefetch={
                                        pilotFlagStatusRefetch
                                      }
                                    />
                                  )}
                                </>
                              </RBAC>

                              <RBAC
                                allowedRoles={[
                                  ROLES.VIDEOMS_PILOT,
                                  ROLES.VIDEOMS_PM,
                                  ROLES.ADMIN,
                                  ROLES.VIDEOMS_QC,
                                ]}
                              >
                                <AddNote
                                  title={title}
                                  folderId={id}
                                  project={project}
                                  refetchAssets={refetch}
                                />
                              </RBAC>
                            </div>
                          )}

                          <div className="text-right">
                            <RBAC
                              allowedRoles={[
                                ROLES.VIDEOMS_PILOT,
                                ROLES.VIDEOMS_PM,
                                ROLES.ADMIN,
                                ROLES.VIDEOMS_QC,
                              ]}
                            >
                              <>
                                <UploadModal
                                  upload="Upload"
                                  uploadLocation={uploadingPath}
                                  imageryId={projectId}
                                  pilotID={id}
                                  refetchPilot={refetch}
                                  forPilotPortal
                                  isCustomShots
                                  versionSuffix={{ ...fileNamesWithCount }}
                                />
                              </>
                            </RBAC>
                          </div>
                        </Col>
                      </>
                    </Row>
                  </div>
                </div>
              </Card>
            </AWSFolderAssetsProvider>
          </div>
        );
      }
    );
  };

  const renderAddCustomShotFolder = () => {
    return (
      <div className="text-right mt-3 mb-2">
        <Button
          id="reschedule"
          className="btn-sm btn-primary font-size-13 px-3"
          onClick={() => toggleCustomShotModal(true)}
        >
          Add Custom Shots / B-Roll
        </Button>
      </div>
    );
  };

  const createCustomShotFolderPermissions = () => {
    if ([ROLES.VIDEOMS_PM, ROLES.ADMIN, ROLES.VIDEOMS_PILOT, ROLES.VIDEOMS_QC].includes(role)) {
      return renderAddCustomShotFolder();
    }

    if (role === ROLES.VIDEOMS_PILOT && isProjectAccepted) {
      return renderAddCustomShotFolder();
    }

    return null;
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <h4 className="font-size-16  mt-3 mb-2">{renderCount()} </h4>
        </Col>
        <Col md={6}>{createCustomShotFolderPermissions()}</Col>
      </Row>

      <Row>
        <Col sm="12">{renderList()}</Col>
        <AssetPreviewModal
          assetModal={assetModal}
          toggleAssetModal={toggleAssetModal}
          assetURL={modalData.exampleLink}
          fileType={modalData.folderTypes}
          fileName={modalData.imageTitle}
        />
      </Row>
      {isCustomShotModalOpen && (
        <Modal
          isOpen={isCustomShotModalOpen}
          toggle={() => toggleCustomShotModal(false)}
          className="modal-dialog-centered"
        >
          <ModalBody className=" flight-modal">
            <Formik
              initialValues={{ title: '', description: '' }}
              validationSchema={customShotSchema}
              onSubmit={handleOnChange}
            >
              {() => (
                <Form autoComplete="off" className="w-100">
                  <div>
                    <Field
                      id="title"
                      name="title"
                      component={FormikInputField}
                      label="Add Title"
                    />
                    <Field
                      id="description"
                      name="description"
                      component={FormikInputField}
                      label="Add Description"
                      type="textarea"
                    />
                  </div>
                  <div className="ml-2 mt-0">
                    <div className="text-right">
                      <Button
                        outline
                        color="primary"
                        className="mr-2 btn-sm px-4 font-size-13"
                        onClick={() => toggleCustomShotModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        className="btn btn-sm font-size-13 px-4"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default CustomShots;
