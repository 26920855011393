import { useContext } from 'react';
import { allowedUserChecker } from 'utils/helper';
import PropTypes from 'prop-types';
import { authContext } from 'contexts/AuthContext';

const RBAC = ({ allowedRoles, children }) => {
  const { auth } = useContext(authContext);
  const {
    data: { role },
  } = auth;

  const access = allowedUserChecker(role, allowedRoles);
  return access && children;
};

RBAC.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
};

export default RBAC;
