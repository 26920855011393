import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const SortProjectStatus = ({ sortByData, handleSort }) => {
  return (
    <>
      <InputGroup className="d-flex flex-nowrap input-group sort">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Sort by status</InputGroupText>
        </InputGroupAddon>
        <Input
          type="select"
          name="select"
          value={sortByData}
          onChange={(e) => handleSort(e.target.value)}
          className="w-auto"
        >
          <option value="">All</option>
          <option value="NOT_STARTED">Not Started</option>
          <option value="NOT_COMPLETED">Not Completed</option>
          <option value="COMPLETED">Completed</option>
        </Input>
      </InputGroup>
    </>
  );
};
export default SortProjectStatus;
