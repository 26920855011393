import { lazy } from 'react';
import ActiveProjects from 'pages/ManagementPortal/components/Projects/components/ActiveProjects';
import Completed from 'pages/ManagementPortal/components/Projects/components/Completed';
import NeedMyAttention from 'pages/ManagementPortal/components/Projects/components/NeedMyAttention';

import { ROLES } from './role';

const routeProjects = import('pages/ManagementPortal/components/Projects');
const Projects = lazy(() => routeProjects);

export const PORTALS = {
  SALES: {
    name: 'Sales Portal',
    link: '/crexi-search/results',
    slug: 'crexi-search',
  },
  MANAGEMENT: {
    name: 'Management Portal',
    link: '/management-portal/projects/active-projects',
    slug: 'management-portal',
  },
  BROKERS_DIRECTORY: {
    name: "Broker's Directory",
    link: '/brokers-directory/update-missing-details',
    slug: 'brokers-directory',
  },
  PM_PORTAL: {
    name: 'PM Portal',
    link: '/pm-portal/projects/project-list',
    slug: 'pm-portal',
  },
  PILOT_PORTAL: {
    name: 'Pilot Portal',
    link: '/pilot-dashboard/0',
    slug: 'pilot-portal',
  },
  EDITOR_PORTAL: {
    name: 'Editor Portal',
    link: '/editor-portal/projects/project-list',
    slug: 'editor-portal',
  },
  SALES_PERSON_PORTAL: {
    name: 'Sales Person Portal',
    link: '/sales-portal/deals/',
    slug: 'sales-person-portal',
  },
};

export const NAVS = [
  {
    label: 'Projects',
    link: '/projects',
    type: `projects`,
    path: '/management-portal/projects',
    rightTo: [ROLES.ADMIN, ROLES.VIDEOMS_PM, ROLES.USER, ROLES.VIDEOMS_QA, ROLES.VIDEOMS_QC],
    component: Projects,
    subNavs: [
      {
        tabId: 'Active Projects',
        label: 'Active Projects',
        type: `active-projects`,
        link: `/management-portal/projects/active-projects`,
        path: 'management-portal/projects/active-projects',
        component: ActiveProjects,
      },
      {
        tabId: 'Need My Attention',
        label: 'Need My Attention',
        type: `need-my-attention`,
        link: `/management-portal/projects/need-my-attention`,
        path: 'management-portal/projects/need-my-attention',
        component: NeedMyAttention,
      },
      {
        tabId: 'Completed',
        label: 'Completed',
        type: `completed`,
        link: `/management-portal/projects/completed`,
        path: 'management-portal/projects/completed',
        component: Completed,
      },
    ],
  },
];
