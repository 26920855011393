import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { PLAN_TYPE_ICONS } from 'utils/helper';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProjectList = ({ PROJECT_LIST, filters, setFilters, totalCount }) => {
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const fetchMoreData = () => {
    if (PROJECT_LIST.length !== totalCount) {
      setHasMoreItems(true);
      setFilters({ ...filters, offset: filters.offset + filters.limit });
    } else {
      setHasMoreItems(false);
    }
  };

  useEffect(() => {
    if (PROJECT_LIST.length !== totalCount) {
      setHasMoreItems(true);
    }
  }, [PROJECT_LIST]);

  const renderList = () => {
    return PROJECT_LIST?.map((value, ind) => {
      return (
        <NavItem key={`project-list-${value.id}`}>
          <NavLink
            className="nav-link d-flex align-items-center"
            isActive={(match) => match}
            to={{
              pathname: `/editor-portal/projects/${value.id}/folders`,
              state: {
                clearTab: true,
              },
            }}
          >
            <img
              src={PLAN_TYPE_ICONS[value?.planType].icon}
              className="mr-2"
              alt="plan type"
            />
            <span className="d-inline-block text-truncate w-100 align-middle max-w-230px">
              {value?.projectName}
            </span>
          </NavLink>
        </NavItem>
      );
    });
  };
  return (
    <>
      <InfiniteScroll
        height={200}
        next={fetchMoreData}
        hasMore={hasMoreItems}
        dataLength={PROJECT_LIST.length}
        loader={
          <li className="mt-4 text-center">
            <span className="text-dark">Loading...</span>
          </li>
        }
        endMessage={<p className="text-center">No more data to load.</p>}
      >
        {renderList()}
      </InfiniteScroll>
    </>
  );
};

export default ProjectList;
