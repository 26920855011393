import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import PageLoader from 'common/PageLoader';
import { PORTALS } from 'constants/appNavigations';
import NoData from 'common/NoData';
import Header from 'pages/PMPortal/common/Header';
import SubFolders from './components';
import ProjectDetailHeader from '../VideoAssetsFolders/components/ProjectDetailHeader';
import { GET_PROJECT_DETAILS } from '../../ProjectsGraphQL';

const SubFoldersAssets = () => {
  const history = useHistory();
  let { projectId } = useParams();

  const {
    location: { state },
  } = history;

  const { parentName, parent } = useParams();

  projectId = parseInt(projectId, 10);

  const {
    PM_PORTAL: { slug },
  } = PORTALS;

  const { data, loading, error, refetch } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data && data.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        projectAsanaLink,
        slackLink,
        startDate,
        timeElapsed,
        projectEditor,
        projectManager,
        projectPilot,
        isPilotInviteAccepted,
        isEditorInviteAccepted,
        dataSource,
        isCompleted,
        projectReviews,
        planType,
        status
      },
    } = data;

    return (
      <>
        <div className="video-review ">
          <div className="video-review-head">
            <ProjectDetailHeader
              projectId={projectId}
              projectName={projectName}
              projectAsanaLink={projectAsanaLink}
              slackLink={slackLink}
              startDate={startDate}
              timeElapsed={timeElapsed}
              projectEditor={projectEditor}
              projectManager={projectManager}
              projectPilot={projectPilot}
              isPilotInviteAccepted={isPilotInviteAccepted}
              isEditorInviteAccepted={isEditorInviteAccepted}
              dataSource={dataSource}
              isCompleted={isCompleted}
              projectReviews={projectReviews}
              refetchProjectDetails={refetch}
              planType={planType}
              status={status}
            />
          </div>
          <SubFolders
            projectId={projectId}
            projectName={projectName}
            subDirectoryName={state?.subDirectoryName}
            portalSlug={slug}
            children={state?.subFolders}
            directoryName={state?.directoryName}
            parentNameLink={parentName}
            parentLink={parent}
            hasFilesInFolderListing={state?.hasFilesInFolderListing}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};

export default SubFoldersAssets;
