import React from 'react';
import { Button } from 'reactstrap';
import closeIcon from 'assets/close-icon.svg';

const CloseButton = ({ handleDrawerClose }) => {
  return (
    <Button
      color="link"
      className="close-button d-inline-block"
      onClick={handleDrawerClose}
    >
      <img src={closeIcon} alt="closeIcon" />
    </Button>
  );
};
export default CloseButton;
