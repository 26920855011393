import React, { useContext, useEffect } from 'react';
import { find } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars-2';
import StarFillIcon from 'remixicon-react/StarFillIcon';
import { Button, Col, FormGroup, Row } from 'reactstrap';
//
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { VidPitchContext } from 'contexts/VidPitchContext';
import { GET_VIDEO_TEMPLATES } from 'pages/Vidpitch/VidPitchGraphQL';

const TemplateSelection = ({ videoURL }) => {
  const { modifyVideoDetails, updateModifyVideoDetail } =
    useContext(VidPitchContext);

  const [getVideoTemplates, { data, loading, error }] =
    useLazyQuery(GET_VIDEO_TEMPLATES);

  useEffect(() => {
    getVideoTemplates({ fetchPolicy: 'network-only' });
  }, []);

  useEffect(() => {
    if (data && !loading) {
      let selectedVideoTemplate = {};
      const { getVideoTemplates } = data;
      if (!modifyVideoDetails.selectedTemplateId) {
        selectedVideoTemplate = find(getVideoTemplates, {
          isDefault: true,
        });
      } else {
        selectedVideoTemplate = find(getVideoTemplates, {
          templateId: modifyVideoDetails.selectedTemplateId,
        });
      }
      if (selectedVideoTemplate) {
        const { segmentCount, s3Link, templateId } = selectedVideoTemplate;
        updateModifyVideoDetail({
          ...modifyVideoDetails,
          previewURL: videoURL,
          isVideoType: true,
          totalChapter: segmentCount,
          selectedTemplateId: templateId,
        });
      }
    }
  }, [data, loading]);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const renderTemplateColor = (templateName) => {
    if (templateName.includes('gray')) return 'Gray';
    if (templateName.includes('red')) return 'Rust';
    if (templateName.includes('green')) return 'Green';
    if (templateName.includes('blue')) return 'Blue';
    return '';
  };

  const handleChange = ({ s3Link, segmentCount, templateId }) => {
    updateModifyVideoDetail({
      ...modifyVideoDetails,
      previewURL: videoURL,
      isVideoType: true,
      totalChapter: segmentCount,
      selectedTemplateId: templateId,
    });
  };

  if (data?.getVideoTemplates) {
    const { getVideoTemplates } = data;

    const renderTemplateThumbs = () => {
      if (getVideoTemplates.length) {
        return getVideoTemplates.map((item) => {
          const { templateId, segmentUrl, templateName } = item;
          const { selectedTemplateId } = modifyVideoDetails;
          return (
            <Col key={templateId} md={3} className="p-0 pr-2 pb-2">
              <FormGroup check inline className="template-checkbox">
                {/* <Input
                  type="checkbox"
                  onChange={() => handleChange(item)}
                  checked={selectedTemplateId === templateId}
                /> */}
              </FormGroup>
              <Button
                onClick={() => handleChange(item)}
                color="link"
                className="p-0"
              >
                <img
                  src={segmentUrl}
                  className={
                    selectedTemplateId === templateId
                      ? 'template-img v-active-image'
                      : 'template-img'
                  }
                  alt="select template thumbnail"
                />
              </Button>
              <div className="text-center">
                {renderTemplateColor(templateName.toLowerCase())}
              </div>
            </Col>
          );
        });
      }

      return <NoData customMessage="No templates available for selection." />;
    };

    return (
      <>
        <div className="vm-suggession mt-3 p-2">
          {`The video has ${
            modifyVideoDetails?.totalChapter || 0
          } chapters. Go through
          each chapter and make changes. Click the button "Done! Render New
          Video" to see your changes.`}
        </div>
        <hr />
        <div className="temp-settings p-2">
          <h6>
            <b>Template Settings</b>
          </h6>
        </div>
        <div className="p-2 pt-4">
          <h6 className="font-size-18">
            <b>
              <StarFillIcon size={18} className="text-warning mr-1 temp-star" />
              Template designs
            </b>
          </h6>
          <p className="font-size-16 mb-2">
            Your template color will change when you render your video.
          </p>
          <div className="pt-2">
            <Scrollbars
              style={{
                width: '100%',
                height: `calc(100vh - 525px)`,
                margin: '0px',
              }}
            >
              <Row className="p-0 m-0">{renderTemplateThumbs()}</Row>
            </Scrollbars>
          </div>
        </div>
      </>
    );
  }

  return (
    <NoData customMessage="An error occurred, unable to fetch templates." />
  );
};

export default TemplateSelection;
