import { gql } from '@apollo/client';

export const UPLOAD_FILE_IN_FOLDERS = gql`
  mutation addReviewAssetsByEditor(
    $projectId: Int!
    $fileName: String!
    $versionId: String
    $fileSize: String!
    $fileType: String!
    $assetLink: String!
    $parentFolderName: String!
  ) {
    addReviewAssetsByEditor(
      fileName: $fileName
      fileSize: $fileSize
      fileType: $fileType
      assetLink: $assetLink
      versionId: $versionId
      projectId: $projectId
      parentFolderName: $parentFolderName
    )
  }
`;

export const GET_AWS_UPLOAD_ID = gql`
  mutation getAwsUploadId($path: String!, $fileType: String!) {
    getAwsUploadId(path: $path, fileType: $fileType)
  }
`;

export const GET_UPLOAD_SIGNED_URL = gql`
  mutation getUploadSignedUrl(
    $path: String!
    $partNumber: Int!
    $uploadId: String!
  ) {
    getUploadSignedUrl(
      path: $path
      partNumber: $partNumber
      uploadId: $uploadId
    )
  }
`;

export const UPLOAD_FILE_BY_PART = gql`
  mutation uploadFileByPart($path: String!, $parts: JSON!, $uploadId: String!) {
    uploadFileByPart(path: $path, parts: $parts, uploadId: $uploadId)
  }
`;
