import React from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
//
import { UPDATE_BROKER_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import Paginator from 'common/Paginator';
import { addUpdatedBrokerPageInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import BrokersTable from './components/BrokersTable';

const BrokerTableRenderer = ({
  refetch,
  filters,
  pageCount,
  totalCount,
  brokersData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const limit = 10;

  const {
    location: { pathname },
  } = history;

  const [updateBrokerDirectory] = useMutation(UPDATE_BROKER_DIRECTORY, {
    onCompleted: async ({ updateBrokerDirectory: { message } }) => {
      await refetch();
      toast.success(message);
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const saveBrokersData = async (data) => {
    const { email, phone, id } = data;
    const modifiedData = {
      phone,
      email,
      brokerId: id,
    };
    try {
      await updateBrokerDirectory({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const goToTop = async (filters) => {
    const { page, offset, ...rest } = filters;
    await dispatch(
      addUpdatedBrokerPageInfo({
        updatedBrokersInfo: {
          filters: { ...rest },
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  return (
    <div>
      <div className="my-3 update-record-scroll">
        <BrokersTable
          tableData={brokersData}
          saveBrokersData={saveBrokersData}
        />
      </div>
      <div className="mb-4 ">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={brokersData.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </div>
  );
};

export default BrokerTableRenderer;
