import React from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import PasswordChecklist from 'react-password-checklist';
//
import LoadingButton from 'common/LoadingButton';
import { REGISTER_BROKER } from 'pages/Vidpitch/VidPitchGraphQL';
import FormikInputField from 'components/Fields/FormikInputField';

const RegistrationForm = ({ uuid, emailAddress }) => {
  const history = useHistory();

  const registrationSchema = Yup.object({
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    email: Yup.string()
      .trim()
      .email('Enter valid email address')
      .required('Email address is required')
      .nullable(),
    password: Yup.string()
      .required('Please enter password')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/, 'Incorrect format'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const registerInitialValues = {
    email: emailAddress,
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  const [registerBroker] = useMutation(REGISTER_BROKER, {
    onCompleted: () => {
      toast.success('Registered Successfully!!.');
      history.push(`/${uuid}/video-draft-purchase`);
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const registerVidPitchUser = async (values, { setSubmitting }) => {
    const { confirmPassword, ...registrationValues } = values;
    try {
      setSubmitting(true);
      await registerBroker({
        variables: { ...registrationValues, uuid },
      });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={registerInitialValues}
      validationSchema={registrationSchema}
      onSubmit={registerVidPitchUser}
      children={({ isSubmitting, isValid, values }) => {
        return (
          <Form autoComplete="off">
            <FormGroup>
              <Field
                required
                disabled
                id="email"
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
                component={FormikInputField}
              />
            </FormGroup>
            <FormGroup>
              <Field
                required
                type="text"
                name="firstName"
                id="firstName"
                label="First Name"
                placeholder="First Name"
                component={FormikInputField}
              />
            </FormGroup>
            <FormGroup>
              <Field
                required
                type="text"
                id="lastName"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                component={FormikInputField}
              />
            </FormGroup>
            <FormGroup>
              <Field
                required
                id="password"
                label="Password"
                name="password"
                type="password"
                component={FormikInputField}
                placeholder="Enter your password"
              />
            </FormGroup>
            <FormGroup>
              <Field
                required
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                component={FormikInputField}
                placeholder="Confirm your password"
              />
            </FormGroup>
            <PasswordChecklist
              minLength={6}
              className="p-2"
              value={values.password}
              valueAgain={values.confirmPassword}
              rules={['number', 'capital', 'lowercase', 'minLength', 'match']}
            />
            <LoadingButton
              block
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
              className="btn btn-primary btn-block font-weight-500"
            >
              Register
            </LoadingButton>
          </Form>
        );
      }}
    />
  );
};

export default RegistrationForm;
