import React, { useContext, useEffect, useState } from 'react';
import crypto from 'crypto';
import PageLoader from 'common/PageLoader';
import Collapsible from 'react-collapsible';
import { useLazyQuery } from '@apollo/client';
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { videoPlayerContext } from 'contexts/VideoPlayerContext';
import { GET_REVIEW_DRAFT_LIST } from 'common/VideoPlayer/videoPlayerGraphQL';

const VideoVersionListing = ({
  rootId,
  assetFileName,
  assetId,
  videoElement,
}) => {
  const { playerState, setVideoPlayerDetails } = useContext(videoPlayerContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(assetFileName);
  const [selectedAssetId, setSelectedAssetId] = useState(assetId);

  const toggleVersionDropdown = () => {
    if (playerState.isPlaying) {
      videoElement.current.pause();
      setVideoPlayerDetails({
        ...playerState,
        isPlaying: false,
      });
    }
    setDropdownOpen(!dropdownOpen);
  };

  const renderSelectedFileName = (fileName) => {
    setSelectedFileName(fileName);
  };

  const renderSelectedAssetId = (id) => {
    setSelectedAssetId(id);
  };

  const [getReviewDraftList, { data, error, loading }] = useLazyQuery(
    GET_REVIEW_DRAFT_LIST
  );

  const decryptPassword = async (videoEncodedId) => {
    try {
      const textParts = videoEncodedId.split('.');
      const iv = Buffer.from(textParts.shift(), 'hex');
      const encryptedText = Buffer.from(textParts.join('.'), 'hex');
      const decipher = crypto.createDecipheriv(
        process.env.REACT_APP_CRYPTO_ALGORITHM,
        process.env.REACT_APP_CRYPTO_KEY,
        iv
      );
      const decrypted = Buffer.concat([
        decipher.update(encryptedText),
        decipher.final(),
      ]);
      return decrypted.toString();
    } catch (error) {
      return null;
    }
  };

  const getUrlParams = async () => {
    const decryptedResult = await decryptPassword(rootId);
    const assetId = decryptedResult ? JSON.parse(decryptedResult).rootId : 0;
    getReviewDraftList({ variables: { projectId: assetId } });
  };

  useEffect(() => {
    if (rootId) {
      getUrlParams();
    }
  }, []);

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <div className="py-2 font-weight-bold">
        No versioning exists for this project.
      </div>
    );
  }

  const setAssetDetails = ({ fileName, id }) => {
    renderSelectedFileName(fileName);
    renderSelectedAssetId(id);
  };

  const renderVersions = (folderAssets) => {
    return folderAssets.map((item) => {
      return (
        <li
          className={`${selectedAssetId === item.id ? 'active-version' : ''}`}
          key={Math.random()}
        >
          <a href={item.reviewLink} onClick={() => setAssetDetails(item)}>
            {item.version}
          </a>
        </li>
      );
    });
  };

  const renderCollapsibleMenu = () => {
    return data.getReviewDraftList.map((item) => {
      const { name, folder_assets: folderAssets } = item;
      return (
        <Collapsible trigger={name} key={Math.random()}>
          <ul>{renderVersions(folderAssets)}</ul>
        </Collapsible>
      );
    });
  };

  if (data) {
    return (
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggleVersionDropdown}
        size="sm"
        className="video-version"
      >
        <DropdownToggle caret>
          <span className="button-text">
            {selectedFileName}
          </span>
        </DropdownToggle>
        <DropdownMenu>{renderCollapsibleMenu()}</DropdownMenu>
      </ButtonDropdown>
    );
  }
  return <></>;
};

export default VideoVersionListing;
