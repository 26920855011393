import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
//
import { UPDATE_BROKER_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import Paginator from 'common/Paginator';
import { changeFlaggedFilters } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import { recordsToBeUpdatedContext } from 'contexts/RecordsToBeUpdated';
import { highPriorityUpdatesContext } from 'contexts/HighPriorityUpdates';
import BrokersTable from './components/BrokersTable';
import SaveConfirmationModal from './components/SaveConfirmationModal';

const FlaggedRecords = ({ refetch, filters, totalCount, brokersData }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { limit } = filters;

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    modalData: null,
  });

  const {
    location: { pathname },
  } = history;

  const { highPriorityUpdates: highPriorityCount } = useContext(
    highPriorityUpdatesContext
  );

  const { recordsToBeUpdated: recordsToUpdatedCount } = useContext(
    recordsToBeUpdatedContext
  );

  const [updateBrokerDirectory, { loading }] = useMutation(
    UPDATE_BROKER_DIRECTORY,
    {
      onCompleted: async ({ updateBrokerDirectory: { message } }) => {
        await refetch();
        toast.success(message);
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );

  const saveBrokersData = async ({ email, phone, id, brokerUpdatedAt }) => {
    const modifiedData = {
      phone,
      email,
      brokerId: id,
      isFlaggedRecord: true,
    };
    if (brokerUpdatedAt) {
      modifiedData.recordsToUpdatedCount = -1;
    }
    try {
      await updateBrokerDirectory({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
    setConfirmationModal({ status: false, modalData: null });
  };

  const pageCount = Math.ceil(totalCount / limit);

  const goToTop = async (filters) => {
    const { page, offset, ...rest } = filters;
    await dispatch(
      changeFlaggedFilters({
        flaggedPageInfo: {
          filters: { ...rest },
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, offset: newOffset, page: newPage });
  };

  const toggleConfirmationModal = (status, brokerData) =>
    setConfirmationModal({ status, modalData: brokerData });

  return (
    <div>
      <div className="my-3 flagged-records-scroll">
        <BrokersTable
          tableData={brokersData}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      </div>
      <div className="mb-4 ">
        <Paginator
          offset={filters.offset}
          currentPage={filters.page}
          pageCount={pageCount}
          totalCount={totalCount}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          recordLength={brokersData.length}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
      {confirmationModal && confirmationModal.status && (
        <SaveConfirmationModal
          toggleConfirmationModal={toggleConfirmationModal}
          confirmationModal={confirmationModal.status}
          saveBrokersData={saveBrokersData}
          buttonDisable={loading}
          confirmationData={confirmationModal.modalData}
        />
      )}
    </div>
  );
};

export default FlaggedRecords;
