import React from 'react';
import { Field } from 'formik';
import { InputGroup } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import FormikInputField from 'components/Fields/FormikInputField';
import FormikDatePicker from 'components/Fields/FormikDatePicker';

const Step2 = () => {
  return (
    <div className="pilot-detail-calender">
      <div className="bg-white card-header mb-4 mt-n3 mx-n4 text-center">
        <h5 className="font-size-14 mb-1">
          <span className="progress-span">
            <CircularProgressbar
              value={33}
              text="2/3"
              styles={buildStyles({
                pathColor: `rgb(58, 180, 43)`,
                textSize: '28px',
              })}
            />
          </span>
          Insurance Details
        </h5>
      </div>
      <InputGroup className="mt-2" size="medium">
        <Field
          id="faaLicenseNumber"
          name="faaLicenseNumber"
          type="text"
          component={FormikInputField}
          label="FAA License number"
          className="font-size-13"
        />
      </InputGroup>
      <InputGroup className="mt-2" size="medium">
        <Field
          id="faaExpireDate"
          name="faaExpireDate"
          component={FormikDatePicker}
          label="FAA License expiration date"
          className="font-size-13"
        />
      </InputGroup>
      <InputGroup className="mt-2" size="medium">
        <Field
          id="insurancePolicyNumber"
          name="insurancePolicyNumber"
          type="text"
          component={FormikInputField}
          label="Insurance policy number"
          className="font-size-13"
        />
      </InputGroup>
      <InputGroup className="mt-2" size="medium">
        <Field
          id="insuranceExpireDate"
          name="insuranceExpireDate"
          component={FormikDatePicker}
          label="Insurance policy expiration date"
          className="font-size-13"
        />
      </InputGroup>
    </div>
  );
};

export default Step2;
