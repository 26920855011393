import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const EditorInvitedAlreadyModal = ({
  inviteEditorPromptModal,
  toggleEditorInviteModal,
  toggleEditorInvitePromptModal,
}) => {
  const toggleModal = () => {
    toggleEditorInviteModal(true);
    toggleEditorInvitePromptModal(false);
  };
  return (
    <Modal
      isOpen={inviteEditorPromptModal}
      toggle={() => toggleEditorInvitePromptModal(false)}
      className="modal-dialog-centered editor-invite-prompt-modal"
    >
      <ModalHeader>Editor Invite Exist</ModalHeader>
      <ModalBody>
        <span>
          A editor is already invited on this project. <br />
          <b>Do you want to invite new editor ?</b>
        </span>
        <div className="text-right pt-4">
          <Button
            outline
            color="primary"
            className=" btn-sm px-2 font-size-13"
            onClick={() => toggleEditorInvitePromptModal(false)}
          >
            No
          </Button>
          <Button
            type="submit"
            color="primary"
            className="btn-sm px-3 font-size-13 ml-2"
            onClick={() => toggleModal()}
          >
            Yes
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditorInvitedAlreadyModal;
