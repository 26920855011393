import React, { useContext } from 'react';
import { Badge, Table } from 'reactstrap';
import Header from 'pages/PMPortal/common/Header';

import slackIMG from 'assets/slack.png';
import asanaIMG from 'assets/asana.png';
import { authContext } from 'contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { BRONZE, NOT_ASSIGNED, SLACK_LINK } from 'constants/appConstants';
import { ROLES } from 'constants/role';

const ProjectDetailHeader = ({
  projectName,
  projectAsanaLink,
  slackLink,
  startDate,
  projectEditor,
  projectManager,
  projectPilot,
  isPilotInviteAccepted,
  isEditorInviteAccepted,
  isCompleted,
  planType,
  status,
}) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const renderEditor = () => {
    if (isEditorInviteAccepted) {
      return (
        <a
          href={slackLink || SLACK_LINK}
          rel="noreferrer"
          target="_blank"
          className="mb-0"
        >
          <img src={slackIMG} alt={slackIMG} className="mr-2" />{' '}
          {projectEditor || 'Not Assigned'}
        </a>
      );
    }
    return NOT_ASSIGNED;
  };

  const renderPilot = () => {
    if (isPilotInviteAccepted) return projectPilot;
    return NOT_ASSIGNED;
  };

  return (
    <>
      <Header heading={projectName} />
      <Table borderless className="mb-0 table-header-list">
        <thead>
          <tr>
            {planType !== BRONZE && <th>PROJECT IN ASANA</th>}

            {role !== ROLES.VIDEOMS_PM && <th>SLACK PM</th>}

            {role !== ROLES.VIDEOMS_EDITOR && <th>EDITOR</th>}

            {planType !== BRONZE && <th>PILOT</th>}
            <th>START DATE</th>
            <th>PROJECT TYPE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {planType !== BRONZE && (
              <td className="pt-2">
                <a
                  href={projectAsanaLink}
                  rel="noreferrer"
                  target="_blank"
                  onClick={
                    role === ROLES.VIDEOMS_EDITOR
                      ? (e) => e.preventDefault()
                      : ''
                  }
                  className={`${
                    role === ROLES.VIDEOMS_EDITOR ? 'disableStar' : ''
                  }`}
                >
                  <img src={asanaIMG} alt={asanaIMG} className="mr-2" /> View
                  project in Asana
                </a>
                <FontAwesomeIcon
                  icon={faExternalLinkSquareAlt}
                  color="#565082"
                  className="ml-2 align-middle"
                />
              </td>
            )}
            {role !== ROLES.VIDEOMS_PM && (
              <td className="pt-2">
                <a
                  href={slackLink || SLACK_LINK}
                  rel="noreferrer"
                  target="_blank"
                  className="mb-0"
                >
                  <img src={slackIMG} alt={slackIMG} className="mr-2" />{' '}
                  {projectManager || 'Not Assigned'}
                </a>
              </td>
            )}

            {role !== ROLES.VIDEOMS_EDITOR && (
              <td className="pt-2 text-capitalized">{renderEditor()}</td>
            )}
            {planType !== BRONZE && (
              <td className="pt-2 text-capitalized">{renderPilot()}</td>
            )}
            <td className="pt-2"> {startDate}</td>
            <td className="pt-2"> {planType}</td>
            <td className="pt-2">
              {planType !== BRONZE ? (
                <Badge
                  color="light"
                  className="px-3 rounded border border-primary"
                >
                  {!isCompleted && (
                    <span className="pilot-invitation-status">
                      {isPilotInviteAccepted ? 'ACTIVE' : 'NOT STARTED'}
                    </span>
                  )}
                  {isCompleted && (
                    <span className="pilot-invitation-status">COMPLETED</span>
                  )}
                </Badge>
              ) : (
                <Badge
                  color="light"
                  className="px-3 rounded border border-primary"
                >
                  <span className="pilot-invitation-status">{status?.toUpperCase()}</span>
                </Badge>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
export default ProjectDetailHeader;
