import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const DeleteConfirmationModel = ({
  onDeleteCancel,
  showDeleteModel,
  deleteCustomImages,
  fileType,
}) => {
  const FILE_TYPE = { LOGO: 'logo', QR_CODE: 'QR code ' };
  return (
    <div>
      <Modal
        isOpen={showDeleteModel}
        toggle={onDeleteCancel}
        className="unfinished-orders-modal modal-dialog-centered overflow-hidden"
        style={{width: "450px"}}
      >
        <ModalBody className="px-3 py-4">
          <p className='mb-1 font-size-14'>{`Uploading a new ${
            FILE_TYPE[fileType] ?? 'image'
          } will permanently replace the existing one.`}</p>
          <p className='font-size-14'>Are you sure you want to proceed?</p>
          <div className="text-right pt-2">
            <Button
              outline
              color="primary"
              className=" btn-sm px-2 font-size-13"
              onClick={onDeleteCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={deleteCustomImages}
              className="btn-sm px-3 font-size-13 ml-2"
            >
              Proceed
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModel;
