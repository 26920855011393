import { useContext } from 'react';
import PropTypes from 'prop-types';
import { permissionContext } from 'contexts/PermissionContext';

//RBFP = Role Based Folder Permission
const RBFP = ({
  directoryName,
  permissionType,
  children,
  subDirectoryName,
  isParentFolder,
}) => {
  const permissionData = useContext(permissionContext);

  if (!permissionData) return null;
  if (!permissionData.permissionDetail) return null;

  const {
    permissionDetail: { getUserPermission },
  } = permissionData;

  if (getUserPermission && getUserPermission.permissions) {
    const {
      permissions: { directory },
    } = getUserPermission;
    const directoryPermission = directory && directory[directoryName];

    const { permissions, subdirectories } = directoryPermission;

    const access = permissions && permissions[permissionType];

    if (
      !isParentFolder &&
      subDirectoryName &&
      subdirectories[subDirectoryName]
    ) {
      const { permissions: subDirectoryPermissions } =
        subdirectories[subDirectoryName];

      const subdirectoriesAccess =
        subDirectoryPermissions && subDirectoryPermissions[permissionType];
      if (!subdirectoriesAccess) {
        return null;
      }
      return subdirectoriesAccess && children;
    }

    return access && children;
  }
  return null;
};


RBFP.defaultProps = {  
  directoryName: "",
  permissionType: "",
  subDirectoryName: "",
  children: null,
  isParentFolder: false,

}

RBFP.propTypes = {
  directoryName: PropTypes.string,
  permissionType: PropTypes.string,
  subDirectoryName: PropTypes.string,
  children: PropTypes.element,
  isParentFolder: PropTypes.bool,
};

export default RBFP;
