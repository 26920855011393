import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
//
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import FormikInputField from 'components/Fields/FormikInputField';
import { ADD_NOTES } from 'pages/Pilot/PilotPortalGraphQL';

const messagingSchema = Yup.object({
  noteText: Yup.string()
    .required('Required')
    .test(
      'remove-white-spaces',
      'White space not allowed as a character',
      (noteText) => {
        if (noteText) return noteText.trim().length > 0;
        return false;
      }
    ),
});

const NoteMessaging = ({
  folderId,
  projectManagerId,
  projectPilotId,
  refetchNotes,
  refetchAssets,
}) => {
  const {
    auth: {
      data: { role, userId },
    },
  } = useContext(authContext);

  const [addNotes] = useMutation(ADD_NOTES, {
    onCompleted: ({ addNotes }) => {
      refetchNotes();
      refetchAssets();
      toast.success('Note added successfully.');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const getNotesFromId = () => {
    return role === ROLES.VIDEOMS_PILOT ? projectPilotId : projectManagerId;
  };

  const getNotesToId = () => {
    return role === ROLES.VIDEOMS_PILOT ? projectManagerId : projectPilotId;
  };

  const handleSubmit = async ({ noteText }, { resetForm }) => {
    try {
      resetForm({});
      await addNotes({
        variables: {
          notesFrom: parseInt(userId, 10),
          notesTo: getNotesToId(),
          noteText: noteText.trim(),
          notesUserRole: role,
          folderId,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Formik
      initialValues={{ noteText: '' }}
      validationSchema={messagingSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="d-flex w-100">
          <Field
            id="noteText"
            type="text"
            name="noteText"
            placeholder="Type your message here.."
            component={FormikInputField}
            className="form-control form-control-sm mb-0"
          />
          <div className="ml-2 mt-0">
            <div className="text-right">
              <Button
                color="primary"
                type="submit"
                className="btn btn-sm font-size-13 px-4"
              >
                Send
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NoteMessaging;
