import React from 'react';
import TimelineDiagram from './components/TimelineDiagram';

const TIMELINE_DATA = [
  {
    stepName: 'Data Collection',
    stepDuration: '3 hr 00m',
  },
  {
    stepName: 'Auto Process',
    stepTimeStamp: '10/24/2021',
  },
  {
    stepName: 'Gap',
    stepDuration: '4 hr 30m',
  },
  {
    stepName: 'Pilot Scheduled',
    stepDuration: '2 hr 30m',
  },
  {
    stepName: 'Pilot image submission',
    stepDuration: '6 hr 30m',
  },
  {
    stepName: 'Editing Draft 1',
    stepDuration: '6 hr 30m',
  },
  {
    stepName: 'Review Pending',
  },
  {
    stepName: 'Editing Draft 2',
    stepDuration: '6 hr 30m',
  },
];

const Timeline = () => {
  return (
    <>
      <h6 className="mt-4">Timeline will appear here</h6>
      {/*
        <h6>Timeline Status</h6> */}
      {/* <TimelineDiagram timelinData = {TIMELINE_DATA}/> */}
    </>
  );
};

export default Timeline;
