import React, { useContext, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import Mail from 'assets/mail-send-fill.svg';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';
import { useHistory } from 'react-router-dom';

const EmailSent = () => {
  const history = useHistory();
  const { auth } = useContext(authContext);

  const redirectBasedOnRole = (role) => {
    if (role === ROLES.OPERATOR) {
      history.push('/brokers-directory/update-missing-details');
    } else {
      history.push('/crexi-search/results');
    }
  };

  useEffect(() => {
    if (auth.data) {
      redirectBasedOnRole(auth.data.role);
    }
  }, [auth]);

  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <div className="text-center mb-3">
              <img src={Mail} alt="MailIcon" />
            </div>
            <p className="text-center mb-0 font-size-16 font-weight-bold">
              Reset password instructions sent to your email address.
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default EmailSent;
