import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
//
import { ROLES } from 'constants/role';
import { crexiBronzeCombinedActions } from 'pages/CrexiBronze/CrexiBronzeAction';
import { bronzeContext } from 'pages/CrexiBronze/BronzeContext';
import { DELETE_CUSTOM_IMAGE_S3_URL } from 'pages/CrexiBronze/CrexiBronzeGraphQL';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PROJECT_CHECKOUT_STATUS } from 'pages/Search/CrexiSearchGraphQL';
import { BRONZE } from 'constants/appConstants';

const SaveToDraftModal = ({ source, userRole }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: assetId } = useParams();
  const [showDraftModal, setDraftModal] = useState(false);
  const { bronzeData, dispatchBronzeData } = useContext(bronzeContext);

  const { isChapterOneVisited, isVideoTextChange } = useSelector(
    (state) => state?.crexiBronze
  );

  const portalData = useSelector((state) =>
    userRole !== ROLES.VIDEOMS_EDITOR ? state?.salesPortal : state.editorPortal
  );

  const [deleteCustomImageS3Url] = useMutation(DELETE_CUSTOM_IMAGE_S3_URL, {
    onCompleted: () => {
      dispatchBronzeData({
        type: 'RESET_BRONZE_DATA',
      });
    },
    onError: (err) => {
      toast.error(
        'An error occurred, while deleting file. Please try again.',
        err
      );
    },
  });

  const [projectCheckoutStatus, { data: projectCheckOutData }] = useLazyQuery(
    PROJECT_CHECKOUT_STATUS
  );

  const deleteUnsavedImages = async () => {
    if (
      !isChapterOneVisited &&
      !portalData?.searchedBy?.includes('pendingOrder') &&
      bronzeData?.projectUuid &&
      bronzeData?.s3ImageLinkArray?.length
    ) {
      const responseData = await projectCheckoutStatus({
        variables: {
          assetId: parseInt(assetId, 10),
          planName: BRONZE,
        },
        fetchPolicy: 'network-only',
      });

      if (responseData?.data?.projectCheckoutStatus?.projectPlan !== BRONZE) {
        const { s3ImageLinkArray, fileType, projectUuid } = bronzeData;
        deleteCustomImageS3Url({
          variables: {
            s3ImageLinkArray,
            fileType,
            projectUuid,
          },
        });
      }
    }
  };
  const navigateBack = () => {
    dispatch(
      crexiBronzeCombinedActions({
        currentChapter: 1,
        currentPreviewURL: '',
        isChapterOneVisited: false,
        logoAndQrUrl: '',
        isVideoTextChange: false,
      })
    );

    if (!isVideoTextChange && userRole !== ROLES.VIDEOMS_EDITOR) {
      deleteUnsavedImages();
      history.push(portalData?.searchedBy);
    } else {
      history.go(-1);
    }
  };

  const goBackToSearchList = () => {
    if (!isVideoTextChange && isChapterOneVisited) {
      setDraftModal(true);
    } else {
      navigateBack();
    }
  };

  const toggleDraftModal = () => {
    setDraftModal(!showDraftModal);
  };

  const renderModalMessage = () =>
    userRole !== ROLES.VIDEOMS_EDITOR
      ? `The order for ${portalData?.propertyDetail?.name} will get saved to
              pending orders, with chapter details you have saved till now.`
      : `Your work for saved chapters for order ${portalData?.propertyDetail?.name} has been saved.`;

  return (
    <div>
      {source === 'Header' ? (
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => goBackToSearchList()}
          className="font-size-14 mr-2 mt-1 cursor-pointer"
        />
      ) : (
        <Button
          outline
          color="primary"
          className="crexi-bronze-cancel"
          onClick={() => goBackToSearchList()}
        >
          Cancel
        </Button>
      )}
      {showDraftModal && (
        <Modal
          isOpen={showDraftModal}
          toggle={toggleDraftModal}
          className="unfinished-orders-modal modal-dialog-centered overflow-hidden"
        >
          <ModalBody className="px-3 py-4">
            <p className="font-size-14">{renderModalMessage()}</p>
            <div className="text-right pt-2">
              <Button
                outline
                color="primary"
                className=" btn-sm px-2 font-size-13"
                onClick={toggleDraftModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={navigateBack}
                className="btn-sm px-3 font-size-13 ml-2"
              >
                Accept
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default SaveToDraftModal;
