import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_PERMISSION } from 'containers/AppContainer/AppGraphQL';
import { authContext } from './AuthContext';

export const permissionContext = React.createContext();

const PermissionProvider = ({ children }) => {
  const authData = useContext(authContext);
  const email =
    authData && authData.auth && authData.auth.data && authData.auth.data.email;

  const [permissionDetail, setPermissionDetail] = useState(null);

  const { loading, error, data } = useQuery(GET_USER_PERMISSION, {
    variables: {
      email,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setPermissionDetail(data);
    }
  }, [data]);

  return (
    <permissionContext.Provider
      value={{
        permissionDetail,
        loading,
        error,
      }}
    >
      {children}
    </permissionContext.Provider>
  );
};

export default PermissionProvider;
