import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const ProjectsTable = ({
  baseProps: { columns, data },
  defaultSorted,
  rowEvents,
}) => {
  const getRowClasses = (row, rowIndex) =>
    row.isNew ? 'bg-light-success' : '';
  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={data}
      columns={columns}
      bordered={false}
      rowEvents={rowEvents}
      defaultSorted={defaultSorted}
      rowClasses={getRowClasses}
      classes="project-table"
    />
  );
};
export default ProjectsTable;
