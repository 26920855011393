import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { useFormik } from 'formik';
import { validationSchemaLogin } from 'common/ValidationSchemas/SignUpSchema';
import FormikErrorMessage from 'common/FormikErrorMessage';

const RegistrationForm = () => {
  const {
    // values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      role: '',
      password: '',
    },
    validationSchema: validationSchemaLogin,
    onSubmit: (values) => {
      //   Login(values);
    },
  });

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="form-group">
          <label>First name</label>
          <Input
            type="text"
            name="firstName"
            className="form-control form-control-sm"
            placeholder="First name"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage
            errors={errors}
            touched={touched}
            field="firstName"
          />
        </FormGroup>

        <FormGroup className="form-group">
          <label>Last name</label>
          <Input
            type="text"
            name="lastName"
            className="form-control form-control-sm"
            placeholder="Last name"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage
            errors={errors}
            touched={touched}
            field="lastName"
          />
        </FormGroup>

        <FormGroup className="form-group">
          <label>Email address</label>
          <Input
            type="email"
            name="email"
            className="form-control form-control-sm"
            placeholder="Enter email"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage errors={errors} touched={touched} field="email" />
        </FormGroup>
        <FormGroup className="form-group">
          <label>Phone</label>
          <Input
            type="text"
            name="phone"
            className="form-control form-control-sm"
            placeholder="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage errors={errors} touched={touched} field="phone" />
        </FormGroup>
        <FormGroup className="form-group">
          <label>Address</label>
          <Input
            type="text"
            name="address"
            className="form-control form-control-sm"
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage
            errors={errors}
            touched={touched}
            field="address"
          />
        </FormGroup>
        <FormGroup className="form-group">
          <label>Role</label>
          <Input
            type="text"
            name="role"
            className="form-control form-control-sm"
            placeholder="Role"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage errors={errors} touched={touched} field="role" />
        </FormGroup>

        <FormGroup className="form-group">
          <label>Password</label>
          <Input
            type="password"
            name="password"
            className="form-control form-control-sm"
            placeholder="Enter password"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage
            errors={errors}
            touched={touched}
            field="password"
          />
        </FormGroup>

        <FormGroup className="form-group">
          <label>Confirm Password</label>
          <Input
            type="password"
            name="ConfirmPassword"
            className="form-control form-control-sm"
            placeholder="Enter Confirm Password"
            onChange={handleChange}
            onBlur={handleBlur}
            // value={values.userName}
          />
          <FormikErrorMessage
            errors={errors}
            touched={touched}
            field="ConfirmPassword"
          />
        </FormGroup>

        <Button
          color="primary"
          type="submit"
          className="btn btn-primary btn-block"
        >
          Sign Up
        </Button>
        <p className="forgot-password text-right">
          Already registered <Link to="/login">Log In</Link>
        </p>
      </Form>
    </div>
  );
};

export default RegistrationForm;
