export const FILE_FORMATS = {
  JPG: 'jpg',
  GIF: 'gif',
  BMP: 'bmp',
  PNG: 'png',
  M4V: 'm4v',
  AVI: 'avi',
  MPG: 'mpg',
  MP4: 'mp4',
  MKV: 'mkv',
  WEBM: 'webm',
  MOV: 'mov',
  WEBP: 'webp',
  TIFF: 'tiff',
  JPEG: 'jpeg',
  TIF: 'tif',
  RAW: 'raw',
  NEF: 'nef',
  ORF: 'orf',
  CR: 'cr2',
  SR: 'sr2',
  XLSX: 'xlsx',
};

export const FILE_TYPES = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  XLSX: 'XLSX',
  OTHER: 'Other',
};

export const IMAGE_FORMATS = {
  JPG: 'jpg',
  GIF: 'gif',
  BMP: 'bmp',
  PNG: 'png',
  WEBP: 'webp',
  TIFF: 'tiff',
  JPEG: 'jpeg',
  TIF: 'tif',
  RAW: 'raw',
  NEF: 'nef',
  ORF: 'orf',
  CR: 'cr2',
  SR: 'sr2',
};

export const VIDEO_FORMATS = {
  M4V: 'm4v',
  AVI: 'avi',
  MPG: 'mpg',
  MP4: 'mp4',
  MKV: 'mkv',
  WEBM: 'webm',
  MOV: 'mov',
};
