import React from 'react';
import { dateTimeFormatter } from 'utils/helper';
import { NOT_SPECIFIED } from 'constants/appConstants';

const RequirementSidebar = ({ projectId, requirementData }) => {
  const {
    editorProjectDetails: { propertyAddress, projectStartDate, requiredSkills },
  } = requirementData;

  const renderDate = (timeStamp) => {
    if (timeStamp !== NOT_SPECIFIED) return dateTimeFormatter(timeStamp);
    return NOT_SPECIFIED;
  };

  const renderRequirements = () => {
    if (requiredSkills.length) {
      return requiredSkills.map((reqItem, id) => {
        return (
          <li key={reqItem} className="text-muted font-size-13">
            {reqItem}
          </li>
        );
      });
    }

    return <small className="font-size-14">Not Specified</small>;
  };

  return (
    <div>
      <div className="py-3">
        <p className="mb-0 font-size-14 font-weight-500">Property Address</p>
        <small className="font-size-13 text-muted">
          {propertyAddress || NOT_SPECIFIED}
        </small>
      </div>
      <div className="pb-3">
        <p className="mb-0 font-size-14 font-weight-500">Start Date</p>
        <small className="font-size-13 text-muted">
          {renderDate(projectStartDate)}
        </small>
      </div>
      {/* <div>
        <p className="mb-0 font-size-16 font-weight-600">Due Date</p>
        <small className="font-size-14">
          {renderDate(projectCompleteDate)}
        </small>
      </div> */}

      <div>
        <p className="mb-0 font-size-14 font-weight-500">Requirement</p>
        <ul className="list-unstyled">{renderRequirements()}</ul>
      </div>

      {/* <div>
        <small className="mb-0 font-size-14">Fee for the flight project</small>
        <p className="font-size-16 font-weight-600">
          {projectEditorBudget || NOT_SPECIFIED}
        </p>
      </div> */}
      {/* <RBAC allowedRoles={[ROLES.VIDEOMS_EDITOR]}>
        <ProjectAcceptBox projectId={projectId} />
      </RBAC> */}
    </div>
  );
};

export default RequirementSidebar;
