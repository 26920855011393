import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { numberWithCommas } from 'utils/helper';
import { getPlanContext } from 'contexts/GetPlanContext';

const TAX = 0;
const REDIX = 10;

const OrderSummary = () => {
  const { planData } = useContext(getPlanContext);
  const defaultPlan = planData && planData.defaultPlan;
  const { plan } = useSelector((state) => state.salesPortal);

  const price = plan && plan.price ? plan.price : 0;
  const defaultPlanPrice =
    defaultPlan && defaultPlan.price ? defaultPlan.price : 0;
  const planPrice = price || defaultPlanPrice;
  const totalprice = parseInt(planPrice, REDIX) + TAX;

  return (
    <>
      <Row>
        <Col sm="12">
          <h5 className="order-heading">Order Summary</h5>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm="7">Product</Col>
        <Col sm="5">Price</Col>
      </Row>
      <Row>
        <Col sm="7">
          <strong className="font-size-14">{plan.type}</strong>
        </Col>
        <Col sm="5">
          <strong className="font-size-14">
            ${numberWithCommas(planPrice)}
          </strong>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <hr />
        </Col>
      </Row>
      <Row className="order-red">
        <Col sm="7">Order Total</Col>
        <Col sm="5">${numberWithCommas(totalprice)}</Col>
      </Row>
    </>
  );
};

export default OrderSummary;
