import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Helmet } from 'react-helmet';

import RecordsToBeUpdatedProvider from 'contexts/RecordsToBeUpdated';
import HighPriorityUpdatesProvider from 'contexts/HighPriorityUpdates';
import Sidebar from './common/Sidebar';
import Header from './common/Header';
import FlaggedRecords from './components/FlaggedRecords';
import UpdatedRecords from './components/UpdatedRecords';
import UpdateMissingDetails from './components/UpdateMissingDetails';
import HSOutOfSyncData from './components/HSOutOfSyncData';

const BrokersDirectory = ({ component, ...rest }) => {
  const { path } = rest.match;
  return (
    <HighPriorityUpdatesProvider>
      <RecordsToBeUpdatedProvider>
        <Helmet defaultTitle="Brokers Directory - VidTech" />
        <Container className="themed-container" fluid>
          <Row>
            <Sidebar />
            <Col className="content-quad px-4 bg-white">
              <Header />
              <Scrollbars
                autoHide
                className="row"
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{
                  width: `calc(100% + 30px)`,
                  height: `calc(100vh - 60px)`,
                }}
              >
                <Col sm="12">
                  <Switch>
                    <Route
                      exact
                      path={`${path}/updated-records`}
                      component={UpdatedRecords}
                    />
                    <Route
                      path={`${path}/update-missing-details`}
                      component={UpdateMissingDetails}
                    />
                    <Route
                      path={`${path}/flagged-records`}
                      component={FlaggedRecords}
                    />
                    <Route
                      path={`${path}/HS-out-of-sync`}
                      component={HSOutOfSyncData}
                    />
                  </Switch>
                </Col>
                <div style={{ height: '50px' }} />
              </Scrollbars>
            </Col>
          </Row>
        </Container>
      </RecordsToBeUpdatedProvider>
    </HighPriorityUpdatesProvider>
  );
};
export default BrokersDirectory;
