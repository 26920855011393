import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const ReviewLinkModal = ({
  reviewLinkModalStatus,
  toggleReviewLinkModal,
  reviewLink,
}) => {
  const [copied, setCopiedStatus] = useState(false);

  if (copied) {
    toast.success('Review Link Copied!!!');
  }

  return (
    <Modal
      isOpen={reviewLinkModalStatus}
      toggle={() => toggleReviewLinkModal(false)}
      className="modal-dialog-centered editor-invite-prompt-modal"
    >
      <ModalHeader toggle={() => toggleReviewLinkModal(false)}>
        Review Link Generated!!!
      </ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <div className="asset-copy-link">
            <span className="review-link">{reviewLink}</span>
          </div>
          <CopyToClipboard
            text={reviewLink}
            onCopy={() => {
              setCopiedStatus(true);
            }}
          >
            <Button color="link" size="sm" className="ml-2">
              <FontAwesomeIcon icon={faCopy} className="copy-asset-icon" />
            </Button>
          </CopyToClipboard>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewLinkModal;
