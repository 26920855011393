import React from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const FormikRating = ({ isDisabled, fieldName }) => {
  const renderStar = (i, value) => {
    const isFull = i < value;
    const icon = isFull ? faStar : faEmptyStar;
    return <FontAwesomeIcon icon={icon} size="lg" color="#565374" />;
  };

  return (
    <Field name={fieldName} id={fieldName} type="number">
      {({ field: { value }, form: { setFieldValue } }) => (
        <div>
          <span className="star">
            {[...Array(4).keys()].map((i) => (
              <span
                aria-hidden="true"
                key={i}
                className={`star ${isDisabled ? ' disableStar' : ''}`}
                onClick={() => {
                  setFieldValue(fieldName, i + 1);
                }}
              >
                {renderStar(i, value)}
              </span>
            ))}
          </span>
        </div>
      )}
    </Field>
  );
};

export default FormikRating;
