import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import FormikCheckbox from 'components/Fields/FormikCheckbox';
import { changeFlaggedFilters } from 'pages/BrokersDirectory/BrokersDirectoryActions';

const FlaggedRecordsFilter = () => {
  const dispatch = useDispatch();
  const { flaggedPageInfo } = useSelector((state) => state.brokersDirectory);

  const { filters } = flaggedPageInfo;

  const handleChange = (e, name) => {
    const filterObj = {
      ...filters,
    };
    filterObj[name] = e.target.checked;
    dispatch(
      changeFlaggedFilters({
        flaggedPageInfo: {
          filters: filterObj,
          previousPageInfo: {
            previousRoutes: {
              path: '',
            },
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <div className="flagged-record-filters">
      <Formik enableReinitialize initialValues={filters}>
        <Form>
          <div className="customized-checkbox mb-2">
            <Field
              id="emailNotFound"
              name="emailNotFound"
              label="Email Not Found!"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <div className="customized-checkbox mb-2">
            <Field
              id="needsEvaluation"
              name="needsEvaluation"
              label="Needs Evaluation"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <h6 className="additional-filter mt-3 font-size-14">
            Additional Filters
          </h6>
          <div className="customized-checkbox mb-2">
            <Field
              id="withNotesOnly"
              name="withNotesOnly"
              label="With Notes only"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <div className="customized-checkbox mb-2">
            <Field
              id="priority1"
              name="priority1"
              label="Priority 1"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <div className="customized-checkbox mb-2">
            <Field
              id="priority2"
              name="priority2"
              label="Priority 2"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <div className="customized-checkbox mb-2">
            <Field
              id="priority3"
              name="priority3"
              label="Priority 3"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default FlaggedRecordsFilter;
