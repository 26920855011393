import React, { useContext, useState } from 'react';
import RBAC from 'hoc/RBAC';
import { useParams } from 'react-router-dom';
import { Button, ButtonDropdown, DropdownToggle } from 'reactstrap';
//
import { ROLES } from 'constants/role';
import { BRONZE } from 'constants/appConstants';
import { PORTALS } from 'constants/appNavigations';
import { FILE_TYPES } from 'constants/fileFormatAndTypes';
import { awsFolderAssetsContext } from 'contexts/GetAWSFolderAssets';
import AssetActionItems from '../AssetActionItems';
import ReviewLinkModal from '../ReviewLinkModal';

const AssetActions = ({ fileName, portalSlug, version, fileType }) => {
  const { parentName } = useParams();
  const [reviewLink, setReviewLink] = useState('');
  const [assetAction, setAssetAction] = useState(false);
  const [reviewLinkModalStatus, setReviewLinkModalStatus] = useState(false);

  const { projectPlanType, refetchAssets } = useContext(awsFolderAssetsContext);
  const {
    PM_PORTAL, EDITOR_PORTAL
  } = PORTALS;

  const toggleReviewLinkModal = (status, reviewLink) => {
    window.open(reviewLink, "_blank");
    // setReviewLink(reviewLink);
    // setReviewLinkModalStatus(status);
  };

  const toggleAssetAction = (status) => {
    setAssetAction(status);
  };
  const allowedPath = PM_PORTAL.slug === portalSlug || EDITOR_PORTAL.slug === portalSlug
  return (
    <>

      {parentName === 'Video Drafts' &&
        allowedPath &&
        projectPlanType !== BRONZE && (
          // <RBAC allowedRoles={[ROLES.ADMIN, ROLES.VIDEOMS_PM, ROLES.VIDEOMS_QC, ROLES.VIDEOMS_EDITOR]}>
          <AssetActionItems
            refetchAssets={refetchAssets}
            fileName={fileName}
            toggleReviewLinkModal={toggleReviewLinkModal}
            toggleAssetActions={toggleAssetAction}
          />
          /*{ <ButtonDropdown
            isOpen={assetAction}
            toggle={() => toggleAssetAction(!assetAction)}
            className="float-right"
          >
            <DropdownToggle color="link" className="p-0">
              <i className="fa fa-ellipsis-v" />
            </DropdownToggle>
            {assetAction && (
              <AssetActionItems
                fileName={fileName}
                toggleReviewLinkModal={toggleReviewLinkModal}
                toggleAssetActions={toggleAssetAction}
              />
            )}
          </ButtonDropdown> }*/
          // </RBAC >
        )}
      {
        reviewLinkModalStatus && (
          <ReviewLinkModal
            reviewLinkModalStatus={reviewLinkModalStatus}
            toggleReviewLinkModal={toggleReviewLinkModal}
            reviewLink={reviewLink}
          />
        )
      }
    </>
  );
};

export default AssetActions;
