import React from 'react';
import HighPriorityUpdates from './components/HighPriorityUpdates';
import RecordsToBeUpdated from './components/RecordsToBeUpdated';

const BrokerRecordsCounters = () => {
  return (
    <div className="mr-3 broker-header-label">
      <span className="badge high-priority mr-2">
        <HighPriorityUpdates />
      </span>
      <span className="badge records-updated">
        <RecordsToBeUpdated />
      </span>
    </div>
  );
};
export default BrokerRecordsCounters;
