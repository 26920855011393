import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//
import Paginator from 'common/Paginator';
import { pmEditorInvite } from 'pages/PMPortal/PMPortalActions';
import EditorsTable from './components/EditorsTable';

const EditorTableRenderer = ({
  pageCount,
  totalCount,
  editorsData,
  filters,
  dataSource,
  projectId,
  editorSearchFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const limit = 10;

  const {
    location: { pathname },
  } = history;

  const goToTop = async (filters) => {
    const { page, offset, limit, ...rest } = filters;
    await dispatch(
      pmEditorInvite({
        editorInviteListing: {
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              searchText: '',
            },
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  return (
    <div>
      <div className="update-record-scroll">
        <EditorsTable
          tableData={editorsData}
          dataSource={dataSource}
          projectId={projectId}
          editorSearchFilters={editorSearchFilters}
        />
      </div>
      <div className="pb-3">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={editorsData.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </div>
  );
};

export default EditorTableRenderer;
