import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty, isArray, isObject, transform } from 'lodash';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dateTimeFormatter } from 'utils/helper';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import { getCurrentTab } from 'pages/Search/constant';
import { ADD_ASSET_DETAILS } from 'pages/Search/CrexiSearchGraphQL';
import PortalDropdown from 'common/PortalDropdown';
import HeaderUserDropdown from 'common/HeaderUserDropdown';
import { resetSalesFilters } from 'pages/Search/SalesActions';
import SaveDetailWarningModal from '../SaveDetailWarningModal';
import PendingOrderButton from '../PendingOrderButton';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pendingOrder, setPendingOrder] = useState(false);
  const { searchFilters, searchedBy, plan } = useSelector(
    (state) => state.salesPortal
  );

  const {
    location: { pathname },
  } = history;

  const currentTab = getCurrentTab(pathname);
  const { tabId } = currentTab || { tabId: 0, label: '' };
  const [addAssetDetails, { loading }] = useMutation(ADD_ASSET_DETAILS);

  const {
    assetToEdit: { editStatus, assetData },
    handleEditAsset,
    assetDetail,
    isIVCDisabled,
  } = useContext(assetDetailContext);

  const getAssetDetails = assetData || null;

  let isCompleteIVC = true;
  if (getAssetDetails && getAssetDetails.interiorVideoContact && tabId === 5) {
    isCompleteIVC = Object.values(getAssetDetails.interiorVideoContact).every(
      (row) => row
    );
  }

  const propertyName = getAssetDetails ? getAssetDetails.asset.name : '';

  const [modalProp, setModalProp] = useState({
    isVisibleModal: false,
    path: null,
  });

  const { isVisibleModal } = modalProp;

  const toggleUnfinishedModal = () => setModalProp(!isVisibleModal);

  const goToPendingOrders = async () => {
    setPendingOrder(true);
    if (editStatus) {
      setModalProp({
        isVisibleModal: true,
        path: {
          pathname: '/crexi-search/results',
          search: '?pendingOrder=true',
        },
      });
      return;
    }

    await dispatch(resetSalesFilters());
    history.push({
      pathname: '/crexi-search/results',
      search: '?pendingOrder=true',
    });
  };

  const goBackToSearchList = () => {
    setPendingOrder(false);
    if (editStatus) {
      setModalProp({
        isVisibleModal: true,
        path: searchedBy,
      });
      return;
    }
    history.push(searchedBy);
  };

  const handleDiscardChanges = async () => {
    await handleEditAsset(false, null);
    history.push(modalProp.path);
  };

  const removeProperties = (updatedAssetDetails) => {
    const properties = [
      'createdAt',
      'updatedAt',
      'status',
      'userId',
      'assetId',
      'isEditing',
    ];
    const details = cloneDeep(updatedAssetDetails);
    const changes = (details) => {
      const temp = details;
      transform(details, (result, value, key) => {
        if (properties.indexOf(key) >= 0) delete temp[key];
        if (isObject(value) || isArray(value)) changes(value);
        return details;
      });
      return temp;
    };
    return changes(details);
  };

  const handleSaveChanges = async () => {
    let updatedAssetDetails = {};
    updatedAssetDetails = cloneDeep(getAssetDetails);
    updatedAssetDetails.interiorVideoContact = {
      ...assetDetail.interiorVideoContact,
      ...updatedAssetDetails.interiorVideoContact,
    };
    const modifiedData = removeProperties(updatedAssetDetails);
    modifiedData.assetId = updatedAssetDetails.asset.id;
    modifiedData.status = 'Inprogress';
    if (tabId === 6) modifiedData.status = 'Complete';
    if (modifiedData.interiorVideoContact) {
      const { shootTime } = modifiedData.interiorVideoContact;
      if (isObject(shootTime)) {
        modifiedData.interiorVideoContact.shootTime = dateTimeFormatter(
          shootTime,
          'hh:mm:ss'
        );
      }
    }
    if (isEmpty(modifiedData.interiorVideoContact)) {
      modifiedData.interiorVideoContact = null;
    }
    delete modifiedData.asset;
    try {
      await addAssetDetails({
        variables: { projectPlan: plan.type ?? 'PLATINUM', ...modifiedData },
        update: (cache, { data: { addAssetDetails } }) => {
          toast.success('Asset details added successfully');
          history.push(searchedBy);
        },
        error: (e) => {
          toggleUnfinishedModal();
          toast.error(e.message);
        },
      });
    } catch (e) {
      toggleUnfinishedModal();
      toast.error(e.message);
    }
    await handleEditAsset(false, null);
  };

  return (
    <Row className="content-header-row border-bottom py-2 align-items-center mx-n4 px-2">
      <Col sm="auto">
        <Button
          color="link"
          className="back-btn color-inherit px-0"
          onClick={goBackToSearchList}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="back-icom mr-2" />
          Back to Search results
        </Button>
      </Col>
      <Col sm="auto" className="ml-auto pl-0">
        <div className="d-flex">
          <PendingOrderButton goToPendingOrders={goToPendingOrders} />
          <PortalDropdown />
          <HeaderUserDropdown />
        </div>
      </Col>
      {isVisibleModal && (
        <SaveDetailWarningModal
          pendingOrder={pendingOrder}
          isVisibleModal={isVisibleModal}
          propertyName={propertyName}
          toggleUnfinishedModal={toggleUnfinishedModal}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveChanges={handleSaveChanges}
          isCompleteIVC={isCompleteIVC}
          isIVCDisabled={isIVCDisabled}
        />
      )}
    </Row>
  );
};
export default Header;
