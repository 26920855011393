import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

const ProjectList = ({
  slug,
  PROJECT_LIST,
  totalCount,
  setFilters,
  filters,
  loading,
}) => {
  const [expandListFor, setExpandListFor] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const fetchMoreData = () => {
    if (PROJECT_LIST.length !== totalCount) {
      setHasMoreItems(true);
      setFilters({ ...filters, offset: filters.offset + filters.limit });
    } else {
      setHasMoreItems(false);
    }
  };

  useEffect(() => {
    if (PROJECT_LIST.length !== totalCount) {
      setHasMoreItems(true);
    }
  }, [PROJECT_LIST]);

  const handleExpandList = (projectId) => {
    setExpandListFor(expandListFor === projectId ? 0 : projectId);
  };

  const renderFolders = (projectId, commentedFolders) => {
    return commentedFolders.map(({ id, name }) => {
      return (
        <NavItem key={id}>
          <NavLink
            className="nav-link pl-5 font-size-14 font-weight-500 video-review-a"
            isActive={(match) => match}
            to={`/${slug}/projects/video-review/${projectId}/${id}`}
          >
            <span className="d-inline-block text-truncate w-100 align-middle max-w-160px">
              {name}
            </span>
          </NavLink>
        </NavItem>
      );
    });
  };

  const renderList = () => {
    return PROJECT_LIST.map(({ projectId, projectName, commentedFolders }) => {
      return (
        <div
          key={projectId}
          className={`position-relative video-review-sidebar ${
            expandListFor === projectId ? 'active' : ''
          }`}
        >
          <p
            className="video-review-heading-sidebar "
            onClick={() => handleExpandList(projectId)}
            onKeyDown={handleExpandList}
            role="presentation"
            title={projectName}
          >
            <span className="text-truncate d-inline-block max-w-230px align-middle">
              {projectName}
            </span>
          </p>

          {expandListFor === projectId && commentedFolders.length
            ? renderFolders(projectId, commentedFolders)
            : null}
        </div>
      );
    });
  };
  return (
    <>
      <InfiniteScroll
        height={200}
        next={fetchMoreData}
        hasMore={hasMoreItems}
        dataLength={PROJECT_LIST.length}
        loader={
          loading && (
            <li className="mt-4 text-center">
              <span className="text-dark">Loading...</span>
            </li>
          )
        }
        endMessage={<p className="text-center">No more data to load.</p>}
      >
        {renderList()}
      </InfiniteScroll>
    </>
  );
};
export default ProjectList;
