import React, { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { BRONZE } from 'constants/appConstants';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import crexiImage from 'assets/CREXi-logo-black.svg';
import linkImage from 'assets/link.svg';
import { Field, Form, Formik } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import { useMutation } from '@apollo/client';
import { UPDATE_PROJECT_RELATED_DETAILS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import AdditionalNotes from './components/AdditionalNotes';

const crexiValidationSchema = Yup.object({
  crexiLinkAddress: Yup.string()
    .trim()
    .matches(/https:\/\/(www.)?crexi.com/g, 'Enter correct url!')
    .required('Crexi link address is required')
    .nullable(),
});

const b4uValidationSchema = Yup.object({
  b4uLinkAddress: Yup.string()
    .trim()
    .matches(/https:\/\/?b4ufly.aloft.ai/g, 'Enter correct url!')
    .required('B4UFLY link address is required')
    .nullable(),
});

const Timeline = ({
  projectId,
  b4uLink,
  crexiLink,
  googleLink,
  projectAddress,
  refetchDetails,
  projectPilotId,
  planType,
  isPilotInviteAccepted,
}) => {
  const [showInput, setInput] = useState(true);
  const [showAddress, setAddress] = useState(true);
  const [showFly, setFly] = useState(true);

  const toggleInput = () => {
    setInput(!showInput);
  };

  const toggleFly = () => {
    setFly(!showFly);
  };

  const toggleAddress = () => {
    setAddress(!showAddress);
  };

  const [updateProjectLinks] = useMutation(UPDATE_PROJECT_RELATED_DETAILS, {
    onCompleted: ({ updateProjectRelatedLinks }) => {
      const { message } = updateProjectRelatedLinks;
      toast.success(message);
      if (message.includes('crexiLink')) {
        toggleInput();
      }
      if (message.includes('b4uLink')) {
        toggleFly();
      }
      refetchDetails();
    },
    onError: ({ message }) => {
      console.log(message);
    },
  });

  const handleSubmit = async (values) => {
    let fieldName = '';
    let value = '';
    if (values.crexiLinkAddress) {
      fieldName = 'crexiLink';
      value = values.crexiLinkAddress;
    }
    if (values.b4uLinkAddress) {
      fieldName = 'b4uLink';
      value = values.b4uLinkAddress;
    }

    try {
      await updateProjectLinks({
        variables: {
          field: fieldName,
          projectId,
          value,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const crexiLinkRenderer = () => {
    return (
      <div>
        {showInput ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <img src={linkImage} alt="" className="mr-2" />
              {crexiLink ? (
                <a
                  href={crexiLink}
                  target="_blank"
                  rel="noreferrer"
                  className="font-size-14"
                >
                  {crexiLink}
                </a>
              ) : (
                'Not Specified'
              )}
            </div>
            <Button color="link" size="sm" onClick={toggleInput}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </div>
        ) : (
          <Formik
            initialValues={{ crexiLinkAddress: crexiLink }}
            validationSchema={crexiValidationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="w-100 my-2">
                <div className="d-flex">
                  <img src={linkImage} alt="" className="mr-2" />
                  <Field
                    id="crexiLinkAddress"
                    name="crexiLinkAddress"
                    type="text"
                    value={crexiLink}
                    component={FormikInputField}
                  />
                </div>
                <div className="mt-2 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="sm"
                    className="mr-2"
                    onClick={toggleInput}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" size="sm">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  };

  const addressRendrer = () => {
    return (
      <div>
        <h6 className="mb-2">Address</h6>
        {showAddress ? (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p>{projectAddress || 'Not Specified'}</p>
            </div>
            <Button
              size="sm"
              outline
              target="_blank"
              href={googleLink}
              disabled={!googleLink}
            >
              Show On Map
            </Button>
            <div className="mt-2">
              {!googleLink && (
                <span className="text-danger">Google link do not exists. </span>
              )}{' '}
            </div>
          </div>
        ) : (
          <div className="w-100 my-2">
            <div className="d-flex">
              <Input type="text" size="sm" value={projectAddress} />
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <Button
                color="secondary"
                size="sm"
                className="mr-2"
                onClick={toggleAddress}
              >
                Cancel
              </Button>
              <Button color="primary" size="sm" onClick={toggleAddress}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const flyRendrer = () => {
    return (
      <div>
        <h6>B4UFLY</h6>
        {showFly ? (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              {b4uLink ? (
                <a
                  href={b4uLink}
                  target="_blank"
                  rel="noreferrer"
                  className="font-size-14"
                >
                  {b4uLink}
                </a>
              ) : (
                'B4UFLY link not available'
              )}
              <Button color="link" size="sm" onClick={toggleFly}>
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{ b4uLinkAddress: b4uLink }}
            validationSchema={b4uValidationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="w-100 my-2">
                <div className="d-flex">
                  <Field
                    id="b4uLinkAddress"
                    name="b4uLinkAddress"
                    type="text"
                    value={b4uLink}
                    component={FormikInputField}
                  />
                </div>
                <div className="mt-2 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="sm"
                    className="mr-2"
                    onClick={toggleFly}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="sm" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="mt-4">
        <Row>
          <Col md={6}>
            <Card className="border-0 shadow mb-2">
              <CardBody>
                <img src={crexiImage} alt="" />
                <div className="my-2">{crexiLinkRenderer()}</div>
                <hr />
                <div>{addressRendrer()}</div>
                {planType !== BRONZE && (
                  <>
                    <hr />
                    {flyRendrer()}
                  </>
                )}
              </CardBody>
            </Card>
            {planType !== BRONZE && (
              <Card className="border-0 shadow">
                <CardBody>
                  <AdditionalNotes
                    isPilotInviteAccepted={isPilotInviteAccepted}
                    projectPilotId={projectPilotId}
                    projectId={projectId}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Timeline;
