import React, { useState } from 'react';
import NoData from 'common/NoData';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';

const Contacts = ({ contacts }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const toggleSidebarList = () => setSidebarIsOpen(!sidebarIsOpen);

  const showContactList = (contacts) => {
    if (contacts.length > 0) {
      return contacts.map((item, index) => (
        <div key={`${item.contactFirstname}_${index + 1}`}>
          <p className="mb-0">
            <FontAwesomeIcon icon={faCircle} color="#BBB8D4" size="sm" />
            <a
              className="align-middle blue-color-link font-size-12 blue-color-link d-inline-block font-size-12 text-truncate width-90"
              href={item.contactHsLink}
              rel="noreferrer"
              target="_blank"
            >
              <span className="mb-2 font-size-14 ml-2">
                {item.contactFirstname} {item.contactLastname}
              </span>
            </a>
          </p>
          <Row className="font-size-13">
            <Col>
              <span className="pl-4">{item.contactEmail}</span>
            </Col>
            <Col>
              <span className="float-right">{item.contactPhone}</span>
            </Col>
          </Row>
          <hr />
        </div>
      ));
    }
    return <NoData />;
  };

  return (
    <>
      <Button
        color="primary"
        id="collabsible"
        className="nav-link w-100 mb-3"
        onClick={toggleSidebarList}
      >
        {sidebarIsOpen ? (
          <FontAwesomeIcon
            icon={faAngleUp}
            color="#fff"
            className="text-dark mr-2"
            size="lg"
          />
        ) : (
          <FontAwesomeIcon
            icon={faAngleDown}
            color="#fff"
            className="text-dark mr-2"
            size="lg"
          />
        )}
        Contacts ({contacts && contacts.length})
      </Button>

      {sidebarIsOpen ? (
        <Collapse isOpen={sidebarIsOpen}>
          <div className='show-contacts-list'>{showContactList(contacts)}</div>
        </Collapse>
      ) : null}
    </>
  );
};

export default Contacts;
