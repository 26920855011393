import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import MailIcon from 'assets/mail-icon.png';

const ConfirmationDialog = (props) => {
  const { modalConfirmation, handleGotIt } = props;
  return (
    <div>
      <Modal isOpen={modalConfirmation} className="modal-dialog-centered">
        <ModalBody>
          <div className="text-center px-3 py-3">
            <img src={MailIcon} alt="MailIcon" />
            <h4 className="py-3">
              Your order summary has been sent to your email.
            </h4>
            <p className="font-size-16 ">
              Please check your junk folder to assure email receipt.
            </p>
            <div className="text-center">
              <Button
                color="primary"
                onClick={handleGotIt}
                className="px-4 font-weight-500"
              >
                Okay, Got it
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmationDialog;
