import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//
import FormikCheckbox from 'components/Fields/FormikCheckbox';
import { addMissingBrokerPageInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';

const Filters = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    missingBrokersInfo: { filters },
  } = useSelector((state) => state.brokersDirectory);

  const {
    location: { pathname },
  } = history;

  const handleChange = (e, name) => {
    const filterObj = {
      ...filters,
    };

    filterObj[name] = e.target.checked;

    dispatch(
      addMissingBrokerPageInfo({
        missingBrokersInfo: {
          filters: filterObj,
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <div className="broker-directory-filters">
      <Formik enableReinitialize initialValues={filters}>
        <Form>
          <div className="customized-checkbox mb-2">
            <Field
              id="missingEmail"
              name="missingEmail"
              label="Missing Email"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
          <div className="customized-checkbox mb-2">
            <Field
              id="missingPhone"
              name="missingPhone"
              label="Missing Phone"
              type="checkbox"
              onChange={handleChange}
              component={FormikCheckbox}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Filters;
