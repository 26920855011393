import * as Yup from 'yup';

export const validationSchemaLogin = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  emailId: Yup.string()
    .email('Invalid email')
    .required('Email id is required.'),
  password: Yup.string()
    .required('Password is required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
});
