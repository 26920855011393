import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Button, Card, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
//
import { SAVE_DEAL_NOTES } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import { dateTimeFormatter } from 'utils/helper';
import { authContext } from 'contexts/AuthContext';

const dealNoteSchema = Yup.object({
  pmNotes: Yup.string().required('Enter a note to save').nullable(),
});

const DealNotes = ({
  deal: { projectId, pmNotes, customerNotes, quotes },
  refetchDealDetails,
}) => {
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [activeTab, setActiveTab] = useState('PM_NOTES');

  const [saveDealNotes] = useMutation(SAVE_DEAL_NOTES, {
    onCompleted: async () => {
      await refetchDealDetails();
      toast.success('Note added successfully.');
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const renderQuote = () => {
    if (quotes.length) {
      const quoteText = quotes.map((item) => {
        const {
          id,
          quoteHsTitle,
          quoteHsQuoteNumber,
          quoteHsCreatedate,
          quoteHsExpirationDate,
          quoteHsSenderFirstname,
          quoteHsSenderLastname,
        } = item;

        return (
          <ul key={id} className="list-inline mb-0">
            <li>
              <b>Quote Title</b> : {quoteHsTitle}
            </li>
            <li>
              <b>Reference Number</b> : {quoteHsQuoteNumber}
            </li>
            <li>
              <b>Created Date</b> :{dateTimeFormatter(quoteHsCreatedate)}
            </li>
            <li>
              <b>Expiration Date</b> :{dateTimeFormatter(quoteHsExpirationDate)}
            </li>
            <li>
              <b>Sender Name</b> : {quoteHsSenderFirstname}{' '}
              {quoteHsSenderLastname}
            </li>
          </ul>
        );
      });
      return quoteText;
    }

    return 'No Quotes Available';
  };

  const onNoteSave = async ({ pmNotes, customerNotes = '' }) => {
    try {
      await saveDealNotes({
        variables: {
          projectId,
          customerNotes,
          pmNotes,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ pmNotes }}
        validationSchema={dealNoteSchema}
        onSubmit={onNoteSave}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <Row>
              <Col md={12}>
                <h6 className="font-size-14 mt-2 pt-1">PM Notes</h6>
                <Card>
                  <CardBody>
                    <Field
                      id="pmNotes"
                      type="textarea"
                      name="pmNotes"
                      component={FormikInputField}
                      className="form-control form-control-sm mb-0"
                      disabled={
                        role === ROLES.ADMIN || role === ROLES.VIDEOMS_QA
                      }
                    />
                  </CardBody>
                  <RBAC
                    allowedRoles={[
                      ROLES.VIDEOMS_SALES_PERSON,
                      ROLES.VIDEOMS_PM,
                      ROLES.VIDEOMS_QC,
                    ]}
                  >
                    <CardFooter>
                      <div className="text-right">
                        <Button
                          type="submit"
                          color="primary"
                          size="sm"
                          className="font-size-12 px-3"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </CardFooter>
                  </RBAC>
                </Card>
              </Col>
              <Col md={12} className="pt-4">
                <h6 className="font-size-14">Customer Notes</h6>
                <Card className="mb-3">
                  <CardBody>
                    <div className="customer-overflow">
                      {quotes && renderQuote()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DealNotes;
