import localforage from 'localforage';

export const reduxStorage = localforage.createInstance({
  deiver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: 'React Matetial Web Preferences',
});

export const apolloStorage = localforage.createInstance({
  driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: 'React Matetial Web',
});

export default reduxStorage;
