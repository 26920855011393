export const FLAG_TYPES = {
  REQUIRE_EVALUATION: 'Requires Further Evaluation',
  EMAIL_NOT_FOUND: "Broker's email not found!",
};

export const PRIORITIES = {
  PRIORITY1: 'Priority 1',
  PRIORITY2: 'Priority 2',
  PRIORITY3: 'Priority 3',
};
