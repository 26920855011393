import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import VersionOptions from './components/VersionOptions';

const VersionDropdown = ({ versionData, handleSelectVersion }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleVersionDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown className="version-dropdown" isOpen={dropdownOpen} toggle={toggleVersionDropdown}>
      <DropdownToggle outline caret  className="font-size-13 font-weight-500">Version History</DropdownToggle>
      <DropdownMenu>
        <VersionOptions versionData={versionData} handleSelectVersion={handleSelectVersion} />
      </DropdownMenu>
    </Dropdown>
  );
};
export default VersionDropdown;
