import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import AssetFile from './component/AssetFile';
import { FOLDERS_FILES_TOGETHER } from 'constants/appConstants';

const AssetFiles = ({
  allSelected,
  getAwsFiles,
  handleSelectFile,
  isPilotPortal,
  refetch,
  pilotID,
  refetchPilotVideoImages,
  isMarkedCompleted,
  isSalesPortal,
  hideCheckbox,
  portalSlug,
  hasFilesInFolderListing,
  parent,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggleBrokerDropdown = () => setOpen(!dropdownOpen);

  return (
    <>
      {isSalesPortal ? (
        <ButtonDropdown
          inNavbar
          className="bg-white"
          isOpen={dropdownOpen}
          toggle={toggleBrokerDropdown}
        >
          {getAwsFiles.length > 0 ? (
            <DropdownToggle className="bg-white border-0 text-dark count-btn font-size-13 p-0">
              {getAwsFiles.length} Files uploaded
            </DropdownToggle>
          ) : (
            <span className="d-inline-block mx-1 font-size-13">
              0 File Uploaded
            </span>
          )}
          <DropdownMenu className="border-radius-8px border-0 shadow-lg min-w-320">
            {getAwsFiles.map(({ Key, ETag }, index) => {
              return (
                <DropdownItem key={Key} className="py-0 px-1" header>
                  <AssetFile
                    fileLength={getAwsFiles.length}
                    key={Key}
                    fileKey={Key}
                    ETag={ETag}
                    allSelected={allSelected}
                    handleSelectFile={handleSelectFile}
                    isPilotPortal={isPilotPortal}
                    refetch={refetch}
                    pilotID={pilotID}
                    refetchPilotVideoImages={refetchPilotVideoImages}
                    isMarkedCompleted={isMarkedCompleted}
                    isSalesPortal={isSalesPortal}
                    hideCheckbox={hideCheckbox}
                  />
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      ) : (
        <>
          {getAwsFiles.map(({ Key, ETag }, index) => {
            if (
              !hasFilesInFolderListing ||
              (hasFilesInFolderListing &&
                !Key?.includes(FOLDERS_FILES_TOGETHER[parent]))
            ) {
              return (
                <AssetFile
                  fileLength={getAwsFiles.length}
                  key={Key}
                  fileKey={Key}
                  ETag={ETag}
                  allSelected={allSelected}
                  handleSelectFile={handleSelectFile}
                  isPilotPortal={isPilotPortal}
                  refetch={refetch}
                  pilotID={pilotID}
                  refetchPilotVideoImages={refetchPilotVideoImages}
                  isMarkedCompleted={isMarkedCompleted}
                  hideCheckbox={hideCheckbox}
                  portalSlug={portalSlug}
                  version={index + 1}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </>
  );
};

export default AssetFiles;
