import React, { useState } from 'react';
import NoData from 'common/NoData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Collapse, Row } from 'reactstrap';

const Company = ({ company }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const toggleSidebarList = () => setSidebarIsOpen(!sidebarIsOpen);
  const showCompany = () => {
    if (company) {
      const { companyName, companyPhone, companyWebsite } = company;
      return (
        <div>
          <Row className="font-size-13">
            <Col>
              <FontAwesomeIcon icon={faCircle} color="#BBB8D4" size="sm" />
              <span className="mb-2 font-size-14 ml-2">
                {companyName || 'NA'}
              </span>
            </Col>
            <Col>
              <span className="pl-3">{companyWebsite || 'NA'}</span>
            </Col>
            <Col>
              <span className="float-right">{companyPhone || 'NA'}</span>
            </Col>
          </Row>
          <hr />
        </div>
      );
    }
    return <NoData />;
  };
  return (
    <>
      <Button
        color="primary"
        id="collabsible"
        className="nav-link w-100 mb-3"
        onClick={toggleSidebarList}
      >
        {sidebarIsOpen ? (
          <FontAwesomeIcon
            icon={faAngleUp}
            color="#fff"
            className="text-dark mr-2"
            size="lg"
          />
        ) : (
          <FontAwesomeIcon
            icon={faAngleDown}
            color="#fff"
            className="text-dark mr-2"
            size="lg"
          />
        )}
        Company ({company ? 1 : 0})
      </Button>

      {sidebarIsOpen ? (
        <Collapse isOpen={sidebarIsOpen}>{showCompany()}</Collapse>
      ) : null}
    </>
  );
};

export default Company;
