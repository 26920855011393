import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const SortBroker = ({ sortByData, handleSort }) => {
  return (
    <>
      <InputGroup className="sort">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Sort by</InputGroupText>
        </InputGroupAddon>
        <Input
          type="select"
          name="select"
          value={sortByData}
          onChange={(e) => handleSort(e.target.value)}
        >
          <option value="">All</option>
          <option value="total_active_assets_count">
            Brokers with most Listing
          </option>
          <option value="new_brokers">New Brokers</option>
        </Input>
      </InputGroup>
    </>
  );
};
export default SortBroker;
