import React from 'react';
import { Field } from 'formik';
import { Col, FormGroup, Label, Row, Card, Badge } from 'reactstrap';
import { CountryDropdown } from 'react-country-region-selector';
//
import FormikInputField from 'components/Fields/FormikInputField';
import Avatar from 'pages/Pilot/components/PilotRegistration/components/Avatar';
// import { TimezoneSelect } from 'timezone-select-js';

const GeneralDetails = ({ values, isEditProfile, handleBlur, ...rest }) => {
  const { firstName, email } = values;

  const handleCountryChange = (e) => {
    const { setFieldValue } = rest;
    setFieldValue('country', e);
  };

  // const handleTimeZoneChange = (e) => {
  //   const { setFieldValue } = rest;
  //   setFieldValue('timeZone', { label: `GMT${e.offset}`, value: e.value });
  // };

  return (
    <>
      <div className="bg-white card-header mt-n3 mx-n4 text-left pilot-progress">
        <h5 className="font-size-14 mb-1">
          Please complete your profile before moving forward
        </h5>
      </div>
      <div className=" mb-3 mx-n4 px-4 py-3">
        <Row>
          <Col md={4}>
            <p className="font-weight-bold font-size-16">GENERAL DETAILS</p>
            <Card className="text-center py-5 bg-light">
              <Avatar
                id="avatar"
                values={values}
                uploadLocation="editor-profile"
                {...rest}
              />
            </Card>
          </Col>
          <Col md={8}>
            <div className="d-flex mt-5">
              <h6 className="font-size-20 mb-1">{firstName}</h6>
              <p>
                <Badge className="font-size-12 text-dark px-2 ml-4" pill>
                  EDITOR
                </Badge>
              </p>
            </div>

            <p className="font-size-14 mb-0">{email}</p>
            <FormGroup className="mt-4">
              <Row>
                <Col>
                  <Field
                    required
                    id="officePhone"
                    type="text"
                    name="officePhone"
                    component={FormikInputField}
                    label="Phone (Office)"
                    className="form-control form-control-sm"
                  />
                </Col>
                <Col>
                  <Field
                    required
                    id="phone"
                    type="text"
                    name="phone"
                    component={FormikInputField}
                    label="Phone (Cell)"
                    className="form-control form-control-sm"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Field
                id="address.address"
                required
                type="text"
                name="address.address"
                component={FormikInputField}
                label="Address"
                placeholder="Street Address"
                className="form-control form-control-sm"
              />
            </FormGroup>
            <FormGroup>
              <Field
                id="address.city"
                name="address.city"
                type="text"
                component={FormikInputField}
                className="form-control form-control-sm"
                placeholder="City"
              />
            </FormGroup>
            <FormGroup>
              <Field
                id="address.state"
                name="address.state"
                type="text"
                component={FormikInputField}
                className="form-control form-control-sm"
                placeholder="State"
              />
            </FormGroup>
            <FormGroup>
              <Field
                id="address.zip"
                name="address.zip"
                type="text"
                component={FormikInputField}
                className="form-control form-control-sm"
                placeholder="Zip"
              />
            </FormGroup>

            <FormGroup className="mb-2" size="medium">
              <Label>Country</Label>
              <br />
              <CountryDropdown
                name="country"
                value={values.country}
                onChange={(e) => {
                  handleCountryChange(e);
                }}
                classes="font-size-14 country-select"
                onBlur={handleBlur}
                defaultOptionLabel="select country"
              />
            </FormGroup>
            <FormGroup className="mb-2" size="medium">
              <Field
                id="timeZone"
                name="timeZone"
                type="text"
                component={FormikInputField}
                label="Time Zone"
                className="font-size-13"
              />
              {/* <Label>Time Zone</Label>
              <br />
              <TimezoneSelect
                name="timeZone"
                value={
                  values.timeZone ? values.timeZone : values.timeZone.value
                }
                onChange={(e) => {
                  handleTimeZoneChange(e);
                }}
                classes="font-size-14"
                onBlur={handleBlur}
              /> */}
            </FormGroup>
            <FormGroup className="mb-2" size="medium">
              <Field
                id="paymentVendorName"
                name="paymentVendorName"
                type="text"
                component={FormikInputField}
                label="Payment vendor name"
                className="font-size-13"
              />
            </FormGroup>
            <FormGroup>
              <Field
                id="editorVendorId"
                name="editorVendorId"
                type="text"
                component={FormikInputField}
                className="form-control form-control-sm"
                label="Editor ID with vendor"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GeneralDetails;
