import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PLANS } from 'pages/Search/common/PlansCard/PlansGraphQL';
import { Row, Col, Button } from 'reactstrap';
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLoader from 'pages/Search/common/PageLoader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { crexiBronzeCombinedActions } from 'pages/CrexiBronze/CrexiBronzeAction';
import { changePlan } from 'pages/Search/SalesActions';

const TableRenderer = ({
  planId,
  planData,
  slectedPlanType,
  togglePlansModal,
  handleSelectPlan,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { assetId } = useParams();
  const path = location.pathname;
  const handleClick = (row) => {
    if (path.includes('property-detail') && row.type === 'BRONZE') {
      const { id, type, price } = row;
      dispatch(
        changePlan({
          plan: {
            id,
            type,
            price,
          },
        })
      );
      history.push({
        pathname: `/crexi-bronze/${assetId}`,
      });

      dispatch(
        crexiBronzeCombinedActions({
          currentChapter: 1,
          currentPreviewURL: '',
          isChapterOneVisited: false,
          logoAndQrUrl: '',
          isVideoTextChange: false,
        })
      );
    } else {
      handleSelectPlan(row);
      togglePlansModal();
    }
  };

  const renderPlansColumn = () => {
    return planData.map((row, index) => {
      const { id, type, description } = row;
      const planClassName = ` plan-header ${type.toLowerCase()}-plan`;
      const planColClassName = `${type.toLowerCase()}-col-plan`;
      const pricePlanClassName = `${type.toLowerCase()}-price pr-4 align-middle `;
      const keyString = `header-${type}-${id}-${index}`;

      const planDescription = () => {
        return description.map((desc) => (
          <div key={desc} className="mb-3 d-flex">
            <FontAwesomeIcon icon={faCheckCircle} className="desc-arrow" />
            <span className="pl-2">{desc}</span>
          </div>
        ));
      };

      return (
        <Col md="3" key={keyString} className="px-2 mb-3">
          <div
            className={`plans-col ${planColClassName} ${
              planId === id || type === slectedPlanType ? 'selected' : ''
            }`}
          >
            <div className={planClassName}>
              <h5 className="text-center">{type} </h5>
              <div className="plan-describe">
                <div className="font-weight-normal mb-0">
                  {planDescription()}
                </div>
              </div>
            </div>
            <div>
              <div className="text-center plan-footer">
                <strong className={pricePlanClassName}>&#36;{row.price}</strong>

                {planId === id || type === slectedPlanType ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="plan-select-active"
                  />
                ) : (
                  <Button
                    onClick={() => handleClick(row)}
                    color="primary"
                    className="align-middle px-2 plan-select-button"
                  >
                    <span className="pr-2">Select</span>
                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="plans-table p-2">
      <Row>
        <Col sm="12" className="plans-col-container">
          <Row className="gx-3 gy-3">{renderPlansColumn()}</Row>
        </Col>
      </Row>
    </div>
  );
};

const PlansTable = (props) => {
  const { data, loading, error } = useQuery(GET_PLANS);
  if (loading) return <PageLoader />;
  if (error) return <div>error</div>;
  if (data) {
    const { getPlans } = data;
    const { plans, featuresList } = getPlans;
    return (
      <TableRenderer planData={plans} featuresList={featuresList} {...props} />
    );
  }
  return 'Plans not available!';
};

export default PlansTable;
