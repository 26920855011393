export const PUT = 'put';
export const GET = 'get';
export const POST = 'post';
export const VIEW = 'view';
export const DELETE = 'delete';
export const DOWNLOAD = 'download';

export const pest = {
  getUserPermission: {
    permissions: {
      directory: {
        adobeFiles: {
          label: 'Adobe Files',
          permissions: {
            get: false,
            put: false,
            post: false,
            view: false,
            delete: false,
          },
          subdirectories: {
            draft1Resource: {
              label: 'Draft-1 Resource',
              permissions: {
                get: false,
                put: false,
                post: false,
                view: false,
                delete: false,
              },
            },
            draft2Resource: {
              label: 'Draft-2 Resource',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            draft3Resource: {
              label: 'Draft-3 Resource',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            videoFinalResource: {
              label: 'Video Final Resource',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
          },
        },
        videoDrafts: {
          label: 'Video Drafts',
          permissions: {
            get: true,
            put: true,
            post: true,
            view: true,
            delete: true,
          },
          subdirectories: {
            draft1: {
              label: 'Draft-1',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            draft2: {
              label: 'Draft-2',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            draft3: {
              label: 'Draft-3',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            videoFinal: {
              label: 'Video Final',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
          },
        },
        aerialImagery: {
          label: 'Aerial Imagery',
          permissions: {
            get: true,
            put: true,
            post: true,
            view: true,
            delete: true,
          },
          subdirectories: {
            models: {
              label: 'Models',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            overlays: {
              label: 'Overlays',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: false,
                delete: true,
              },
            },
            modifiedImages: {
              label: 'Modified Images',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: false,
                delete: true,
              },
            },
            modifiedVideos: {
              label: 'Modified Videos',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            originalVideos: {
              label: 'Original Videos',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
          },
        },
        projectInputs: {
          label: 'Project Inputs',
          permissions: {
            get: true,
            put: true,
            post: true,
            view: true,
            delete: true,
          },
          subdirectories: {
            logos: {
              label: 'Logos',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            imagery: {
              label: 'Imagery',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            projectData: {
              label: 'Project Data',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
            projectDocuments: {
              label: 'Project Documents',
              permissions: {
                get: true,
                put: true,
                post: true,
                view: true,
                delete: true,
              },
            },
          },
        },
      },
    },
  },
};
