import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
//
import rootReducer from 'reducers';
import { reduxStorage } from 'config/storage';

const rootPersistConfig = {
  key: 'root',
  storage: reduxStorage,
  whitelist: [],
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  composeEnhancer(applyMiddleware(thunk))
);

export const persistingStore = persistStore(store);

export default store;
