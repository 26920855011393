import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PLANS } from 'pages/Search/common/PlansCard/PlansGraphQL';
import { find } from 'lodash';

export const getPlanContext = React.createContext();

const GetPlanProvider = ({ children }) => {
  const [planData, setPlanData] = useState(null);

  const { loading, error, data } = useQuery(GET_PLANS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.getPlans) {
      const plans = data && data.getPlans ? data.getPlans.plans : [];
      const defaultPlan = find(plans, (o) => o.default);
      setPlanData({ ...data.getPlans, defaultPlan });
    }
  }, [data]);

  return (
    <getPlanContext.Provider
      value={{
        error,
        loading,
        planData,
      }}
    >
      {children}
    </getPlanContext.Provider>
  );
};

export default GetPlanProvider;
