import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useParams, useLocation } from 'react-router';
import { useQuery, useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { parseToInteger } from 'utils/helper';
import AWSfolders from 'components/AWSfolders';
import { BRONZE } from 'constants/appConstants';
import Header from 'pages/PMPortal/common/Header';
import { PROJECT_TASK_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import { PORTALS } from 'constants/appNavigations';
import { ASSET_TAB_NAMES } from 'pages/EditorPortal/constant';
import ProjectDetail from 'pages/ManagementPortal/components/Projects/components/ProjectDetail';
import { GET_PROJECT_DETAILS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import ProjectDetailHeader from './components/ProjectDetailHeader';
import ProjectDetailDrawer from '../ProjectInvitation/components/ProjectDetailDrawer';
import { newInviteProject } from 'pages/EditorPortal/EditorPortalActions';

const VideoAssetsFolders = () => {
  let { projectId } = useParams();
  const location = useLocation();
  projectId = parseInt(projectId, 10);

  const { editorActiveTabStatus, isNewProjectInvite } = useSelector(
    (state) => state.editorPortal
  );

  const dispatch = useDispatch();
  const [isDetailOpen, setDetail] = useState(false);

  const toggleDetails = () => {
    if (isNewProjectInvite && isNewProjectInvite === 'ACTIVE') {
      dispatch(newInviteProject({ isNewProjectInvite: 'IN_ACTIVE' }));
    }
    setDetail(!isDetailOpen);
  };

  useEffect(() => {
    if (isNewProjectInvite && isNewProjectInvite === 'ACTIVE') toggleDetails();
  }, [isNewProjectInvite]);

  const {
    EDITOR_PORTAL: { slug },
  } = PORTALS;

  const { data, loading, error, refetch } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: subscriptionData } = useSubscription(PROJECT_TASK_SUBSCRIPTION);

  if (data?.getProjectDetails?.planType === BRONZE) {
    if (subscriptionData?.bronzeVideoStatus?.data) {
      const {
        bronzeVideoStatus: {
          data: { status, projectId: subscribedProjectId },
        },
      } = subscriptionData;
      if (status && parseInt(subscribedProjectId, 10) === projectId) {
        refetch();
      }
    }
  }

  useEffect(() => {
    if (location?.state?.clearTab) {
      dispatch({ type: 'EDITOR_ACTIVE_TAB_STATUS', payload: 0 });
    }
    if (!loading && data?.getProjectDetails) {
      if (data?.getProjectDetails?.planType !== BRONZE) {
        dispatch({ type: 'EDITOR_ACTIVE_TAB_STATUS', payload: 1 });
      }
    }
  }, [loading, data]);

  const onChangeActiveButton = (i) => {
    dispatch({ type: 'EDITOR_ACTIVE_TAB_STATUS', payload: i });
  };

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const renderOverviewTab = (planType) => {
    return planType === BRONZE ? (
      <ProjectDetail pmEditorSelectedPlan={planType} />
    ) : (
      <NoData customMessage="Overview not available." />
    );
  };

  const renderTabButtons = (planType) => {
    return ASSET_TAB_NAMES.map((tabName, i) => {
      return (
        <div key={tabName} className="position-relative">
          <Button
            outline
            onClick={() => onChangeActiveButton(i)}
            disabled={tabName === ASSET_TAB_NAMES[0] && planType !== BRONZE}
            className={`tab-button-editor ${
              i === editorActiveTabStatus ? 'isactive' : ''
            }`}
          >
            {tabName}
          </Button>
        </div>
      );
    });
  };

  if (data?.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        projectAsanaLink,
        slackLink,
        startDate,
        timeElapsed,
        projectEditor,
        projectManager,
        projectPilot,
        isPilotInviteAccepted,
        isEditorInviteAccepted,
        isCompleted,
        planType,
        status,
      },
    } = data;

    return (
      <>
        <div className="video-review ">
          <div className="video-review-head">
            <ProjectDetailHeader
              projectId={projectId}
              projectName={projectName}
              projectAsanaLink={projectAsanaLink}
              slackLink={slackLink}
              startDate={startDate}
              timeElapsed={timeElapsed}
              projectEditor={projectEditor}
              projectManager={projectManager}
              projectPilot={projectPilot}
              isPilotInviteAccepted={isPilotInviteAccepted}
              isEditorInviteAccepted={isEditorInviteAccepted}
              isCompleted={isCompleted}
              planType={planType}
              status={status}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">{renderTabButtons(planType)}</div>
            {planType === BRONZE && (
              <Button
                color="link"
                onClick={() => toggleDetails()}
                className="p-0 font-size-12 font-weight-bold"
              >
                PROJECT/TASK DETAILS
              </Button>
            )}
          </div>
          {editorActiveTabStatus === 0 ? (
            renderOverviewTab(planType)
          ) : (
            <AWSfolders
              projectId={projectId}
              projectName={projectName}
              portalSlug={slug}
            />
          )}
        </div>
        {isDetailOpen && planType === BRONZE && (
          <ProjectDetailDrawer
            projectId={projectId}
            isDetailOpen={isDetailOpen}
            toggleDetails={toggleDetails}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};
export default VideoAssetsFolders;
