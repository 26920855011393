import React, { useContext } from 'react';
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { authContext } from 'contexts/AuthContext';
import { logOut } from 'pages/Login/LoginActions';
import { useHistory } from 'react-router-dom';
import { ROLES } from 'constants/role';
import { ROLES_ALIAS } from 'constants/roleAlias';
import RBAC from 'hoc/RBAC';
import VideoRenderTimer from 'common/VideoRenderTimer';
import { videoRenderTimer } from 'pages/CrexiBronze/CrexiBronzeAction';

const HeaderUserDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth: {
      data: { userName, email, photo, role, isEditorProfileUpdated },
    },
    setAuthData,
  } = useContext(authContext);

  const handleLogout = async () => {
    dispatch(
      videoRenderTimer({
        videoRenderState: {
          showVideoRenderTime: false,
          videoRenderTimeElapsed: '',
        },
      })
    );
    await dispatch(logOut());
    await setAuthData(null);
    history.push('/login');
  };

  const handleEditProfile = () => {
    if (role === ROLES.VIDEOMS_PILOT) {
      history.push('/pilot-portal/edit-profile');
    } else if (role === ROLES.VIDEOMS_EDITOR) {
      history.push('/edit-profile');
    }
  };

  const renderEditProfile = () => {
    if (
      (role === ROLES.VIDEOMS_EDITOR && isEditorProfileUpdated) ||
      role === ROLES.VIDEOMS_PILOT
    ) {
      return (
        <>
          <div className="border-top my-1" />
          <Button color="link" onClick={handleEditProfile}>
            <i className="fa fa-pencil mr-1" /> Edit Profile
          </Button>
        </>
      );
    }
    return '';
  };

  const ShowUserRole = (role) => {
    const userRole = ROLES_ALIAS[role];
    return userRole;
  };

  return (
    <>
      <RBAC allowedRoles={[ROLES.VIDEOMS_EDITOR]}>
        <VideoRenderTimer />
      </RBAC>
      <UncontrolledDropdown>
        <DropdownToggle nav>
          {photo ? (
            <img
              src={photo}
              alt="pilot avatar"
              width="25"
              height="25"
              style={{ borderRadius: '50%' }}
            />
          ) : (
            <span className="user-circle rounded-circle">
              <FontAwesomeIcon icon={faUser} />
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu right className="user-dd shadow">
          <div className="d-flex align-items-center px-2">
            <div>
              <span>
                {photo ? (
                  <img
                    src={photo}
                    alt="pilot avatar"
                    width="30"
                    height="30"
                    style={{ borderRadius: '50%' }}
                  />
                ) : (
                  <span className="user-circle rounded-circle">
                    <FontAwesomeIcon icon={faUser} color="#565082" />
                  </span>
                )}
              </span>
            </div>
            <div className="ml-2">
              <h4 className="mb-0">{userName}</h4>
              <p className=" mb-0 role font-size-13" title={email}>
                {email}
                <br />
                <b className="font-size-12 role">{ShowUserRole(role)}</b>
              </p>
            </div>
          </div>
          <div>
            <RBAC allowedRoles={[ROLES.VIDEOMS_PILOT, ROLES.VIDEOMS_EDITOR]}>
              <>{renderEditProfile()}</>
            </RBAC>
            <div className="border-top my-1" />
            <Button color="link" onClick={handleLogout}>
              <i className="fa fa-power-off mr-1" /> Logout
            </Button>
          </div>
          <div className="px-3 border-top pt-1 text-black-50 font-weight-normal font-size-12">
            App version - {`${process.env.REACT_APP_VERSION}`}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default HeaderUserDropdown;
