import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';

import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import {
  TAB_NAMES,
  STATUS_COLUMN,
  DELETE_ACTION,
  NEW_INVITE_STATUS_COLUMN,
} from 'pages/EditorPortal/constant';
import ProjectAcceptBox from 'pages/EditorPortal/components/Projects/components/ProjectInvitation/components/ProjectAcceptBox';
import DeleteInvitation from 'pages/EditorPortal/components/Projects/components/ProjectInvitation/components/DeleteInvitation';
import ProjectDetailDrawer from 'pages/EditorPortal/components/Projects/components/ProjectInvitation/components/ProjectDetailDrawer';
import { NOT_SPECIFIED } from 'constants/appConstants';

const EditorPortalTable = ({ rowData, listingStatus, refetchProjects }) => {
  const history = useHistory();
  const [isDetailOpen, setDetail] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState(null);

  const toggleDetails = (projectId = null) => {
    setDetail(!isDetailOpen);
    setActiveProjectId(projectId);
  };

  const {
    location: { pathname },
  } = history;

  const TABLE_COLUMNS = [
    { headerName: '', width: 20 },
    { headerName: 'Project', width: 250 },
    { headerName: '', width: 50 },
    { headerName: 'Due Date', width: TAB_NAMES['ACTIVE PROJECT'] ? 200 : 100 },
    listingStatus !== TAB_NAMES['PROJECT INVITATION']
      ? { ...STATUS_COLUMN }
      : { ...NEW_INVITE_STATUS_COLUMN },
  ];

  const getTableHeading = () => {
    return (
      <tr>
        {TABLE_COLUMNS.map((heading, index) => (
          <th
            key={heading?.headerName || index}
            style={{ width: heading?.width }}
          >
            {heading?.headerName}
          </th>
        ))}
      </tr>
    );
  };

  const navigateUser = (projectId) => {
    if (listingStatus !== TAB_NAMES['PROJECT INVITATION']) {
      history.push({
        pathname: `/editor-portal/projects/${projectId}/folders`,
        from: pathname,
        state: {
          clearTab: true,
        },
      });
    }
  };

  const renderTableRows = () => {
    return rowData.map((projectItem) => {
      const {
        id,
        badge,
        status,
        actions,
        message,
        planType,
        projectName,
        projectCompleteDate,
      } = projectItem;
      return (
        <tr
          key={id}
          className="update-record-row"
          onClick={() => navigateUser(id)}
        >
          <td>
            <img
              src={PLAN_TYPE_ICONS[planType].icon}
              alt={PLAN_TYPE_ICONS[planType].altText}
            />
          </td>
          <td className="text-truncate">{projectName || NOT_SPECIFIED}</td>
          <td>
            {listingStatus === TAB_NAMES['PROJECT INVITATION'] && badge && (
              <Badge
                pill
                color="success"
                className="py-1 px-2 border-radius-8px text-light"
              >
                {badge}
              </Badge>
            )}
          </td>
          <td>
            {projectCompleteDate
              ? dateTimeFormatter(projectCompleteDate)
              : NOT_SPECIFIED}
          </td>
          {listingStatus !== TAB_NAMES['PROJECT INVITATION'] && (
            <td>
              <span
                className={
                  status === 'In progress' ? 'active-td' : 'completed-td'
                }
              >
                {status || NOT_SPECIFIED}
              </span>
            </td>
          )}
          {listingStatus === TAB_NAMES['PROJECT INVITATION'] && (
            <td>
              <div className="d-flex">
                {message && <span className="text-danger">{message}</span>}
                {!actions.includes(DELETE_ACTION) && (
                  <Button
                    size="sm"
                    color="link"
                    onClick={() => toggleDetails(id)}
                    className="px-0"
                  >
                    Check details
                  </Button>
                )}
                <RBAC allowedRoles={[ROLES.VIDEOMS_EDITOR]}>
                  <div className="ml-auto float-right">
                    {actions.includes(DELETE_ACTION) ? (
                      <DeleteInvitation
                        projectId={id}
                        refetchProjects={refetchProjects}
                      />
                    ) : (
                      <ProjectAcceptBox
                        projectId={id}
                        isEditorPortal
                        refetchProjects={refetchProjects}
                      />
                    )}
                  </div>
                </RBAC>
              </div>
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <Table className="table broker-table flagged-table">
        <thead>{getTableHeading()}</thead>
        <tbody>{renderTableRows()}</tbody>
        {isDetailOpen && (
          <ProjectDetailDrawer
            isProjectInvitationDrawer
            isDetailOpen={isDetailOpen}
            projectId={activeProjectId}
            toggleDetails={toggleDetails}
            refetchProjects={refetchProjects}
          />
        )}
      </Table>
    </>
  );
};
export default EditorPortalTable;
