import React, { lazy } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCommentAlt,
  faExclamationTriangle,
  faLink,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import ActiveProjects from 'pages/ManagementPortal/components/Projects/components/ActiveProjects';
import Completed from 'pages/ManagementPortal/components/Projects/components/Completed';
import NeedMyAttention from 'pages/ManagementPortal/components/Projects/components/NeedMyAttention';
import activeFill from 'assets/refresh-fill.svg';
import AttentionIcon from 'pages/ManagementPortal/components/Projects/components/ProjectDetail/components/ProjectDetailContent/components/TaskItem/components/AttentionIcon';
import { PLAN_TYPE_ICONS, dateTimeFormatter } from 'utils/helper';
import StatusFormatter from './components/Projects/components/ProjectDetail/components/ProjectDetailContent/components/TaskItem/components/StatusFormatter';
import { DownloadFinalVideo } from './common/DownloadFinalVideo';
import CodeSnippet from 'components/CopySnippet';
import ActivityPopup from './components/Projects/components/ProjectDetail/components/ProjectDetailContent/components/TaskItem/components/ActivityPopup';
import { APP_TOKEN } from 'constants/appConstants';
import { ROLES } from 'constants/role';

const routeProjects = import('pages/ManagementPortal/components/Projects');
const Projects = lazy(() => routeProjects);

export const PROPERTY_DATA_COLLECTION = 'Property Data Collection';
export const COMPLETE_STATUS = 'Complete';
export const SYNC_STATUS_CLOSED = 'CLOSED';

export const PROJECT_TASK_HEADER = {
  PRODUCTION_APPROVALS: 'Production / Approvals',
  EDITOR_ONBOARDING: 'Editor Onboarding',
};

export const PROJECT_STATUS = Object.freeze({
  NOT_STARTED: 'Not Started',
  IN_PROCESS: 'In Process',
  COMPLETE: 'Complete',
  FAA_DELAY: 'Faa Delay',
  WEATHER_DELAY: 'Weather Delay',
  SCHEDULED: 'Scheduled',
  IN_REVIEW: 'In Review',
  READY_FOR_REVIEW: 'Ready For Review',
  EDITS_RECEIVED: 'Edits Received',
  APPROVED: 'Approved',
  FINAL_READY: 'Final Ready',
  MISSED_EDIT_RECEIVED: 'Missed Edit Received',
  RESCHEDULED: 'Rescheduled',
  PROJECT_ON_HOLD: 'Project On Hold',
  AWAITING_DATA_IMAGERY: 'Awaiting Data / Imagery',
  RESHOOT_IMAGES_VIDEO: 'Reshoot Images / Video',
  IN_PRODUCTION: 'In Production',
  AWAITING_CLIENT_FEEDBACK_1ST_EDITS: 'Awaiting Client Feedback, 1st Edits',
  AWAITING_CLIENT_FEEDBACK_2ND_EDITS: 'Awaiting Client Feedback, 2nd Edits',
  AWAITING_CLIENT_FEEDBACK_3RD_EDITS: 'Awaiting Client Feedback, 3rd Edits',
  AWAITING_CLIENT_APPROVAL: 'Awaiting Client Approval',
  PUBLISHED: 'Published',
  APPROVED_BY_CLIENT: 'Approved By Client',
  UNDER_INTERNAL_REVIEW: 'Under Internal Review',
  IN_PROGRESS: 'Inprogress',
  RENDERING: 'Rendering',
  CHANGES_RENDER: 'Changes Rendered',
  UNDER_TEXT_CHANGES: 'Under Text Changes',
});

export const ProjectDetail = (rowContent, { plan }) => {
  let icon = null;
  let altText = null;
  if (plan !== 'Not Specified') {
    const iconObj = PLAN_TYPE_ICONS[plan];
    icon = iconObj?.icon;
    altText = iconObj?.altText;
  }
  return (
    <div className="d-flex">
      {icon && altText ? (
        <img className="mr-2" src={icon} alt={altText} />
      ) : (
        <span className="plan-image-placeholder mr-3" />
      )}
      <strong
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px', fontWeight: '500' }}
        title={rowContent}
      >
        {rowContent}
      </strong>
    </div>
  );
};

export const ProjectDetailCheckIcon = (
  rowContent,
  { lastStatus, asanaDeepLink, isDownloadable }
) => {
  const colorObj = {
    Complete: '#64BC26',
    Inprogress: '#1876d4',
    [PROJECT_STATUS.CHANGES_RENDER]: '#1876d4',
    [PROJECT_STATUS.RENDERING]: '#1876d4',
    [PROJECT_STATUS.UNDER_TEXT_CHANGES]: '#1876d4',
  };

  const videoCode = `<embed type="video/webm" src="${asanaDeepLink}" width="400" height="300">`;

  return (
    <>
      <FontAwesomeIcon
        icon={faCheckCircle}
        size="lg"
        className="mr-3"
        color={colorObj[lastStatus] ?? '#ccc'}
      />
      <span
        className="text-truncate d-inline-block align-middle maxWidth90Per"
        title={rowContent}
      >
        {rowContent}
        {asanaDeepLink && !isDownloadable && (
          <a
            href={asanaDeepLink}
            rel="noreferrer"
            target="_blank"
            className="ml-2"
          >
            <span className="available-review-link">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-1"
                style={{ color: '#f0850e' }}
              />
              {`Video is available for review: `}
              <FontAwesomeIcon icon={faLink} className="mr-1" />
              Video Link
            </span>
          </a>
        )}
        {asanaDeepLink && isDownloadable && (
          <>
            <DownloadFinalVideo downloadPath={asanaDeepLink} />
            <span className="ml-2 text-white bg-secondary px-1 pb-1">
              <CodeSnippet code={videoCode} />
            </span>
          </>
        )}
        {rowContent === PROJECT_TASK_HEADER.EDITOR_ONBOARDING &&
          lastStatus === PROJECT_STATUS.IN_PROGRESS && (
            <Button color="link" className="py-0 font-size-12">
              <span style={{ color: '#f09d2e' }}>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                Editor Invited
              </span>
            </Button>
          )}
      </span>
    </>
  );
};

export const CompletedOn = (rowContent, { task }) => {
  if (task === PROPERTY_DATA_COLLECTION) {
    return <>SALES PORTAL LINK</>;
  }
  return null;
};

export const ProjectDetailCompleteLabel = (rowContent, { lastStatus }) => {
  const classString = `${lastStatus
    .replace(/[^\w]/g, '-')
    .toLowerCase()}-badge`;

  return (
    <span title={lastStatus} className={`badge text-truncate ${classString}`}>
      {lastStatus}
    </span>
  );
};

export const ProjectDetailChatIcon = (
  rowContent,
  { asanaTaskId, alertDetails, planName }
) => {
  return (
    <>
      {alertDetails && alertDetails.length ? (
        <AttentionIcon
          id={asanaTaskId}
          alertDetails={alertDetails}
          planName={planName}
        />
      ) : (
        ''
      )}
    </>
  );
};

export const TruncatedDetail = (rowContent, row) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={rowContent}
      >
        {rowContent}
      </span>
    </>
  );
};

export const TruncatedBrokerName = (rowContent, { broker }) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={broker?.[0]?.brokerName || 'Not Specified'}
      >
        {broker?.[0]?.brokerName || 'Not Specified'}
      </span>
    </>
  );
};

export const ProjectStatus = (rowContent, { trackStatus, hasMessage }) => {
  return (
    <>
      <div
        style={{ maxWidth: '180px', display: 'inline-block' }}
        className="text-truncate"
      >
        {trackStatus === 'NOT STARTED' ? (
          <span title={trackStatus} className="not-started">
            {trackStatus}
          </span>
        ) : (
          <span className="active-td" title={trackStatus}>
            <img
              src={activeFill}
              alt="active td"
              className="align-middle mr-1"
            />
            {trackStatus}
          </span>
        )}
      </div>
      {hasMessage && (
        <span className="chat-span">
          <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#ccc" />
          <span>1</span>
        </span>
      )}
    </>
  );
};

export const OrderedBy = (rowContent, row) => (
  <span className="text-capitalized">{rowContent}</span>
);

export const TABS = [
  {
    tabId: 0,
    label: 'Projects',
    link: '/projects',
    type: `projects`,
    path: '/management-portal/projects',
    component: Projects,
    subNavs: [
      {
        tabId: 1,
        label: 'Active Projects',
        link: `/projects/active-projects`,
        type: `active-projects`,
        path: 'management-portal/projects/active-projects',
        component: ActiveProjects,
      },
      {
        tabId: 1,
        label: 'Need My Attention',
        link: `/projects/need-my-attention`,
        type: `need-my-attention`,
        path: 'management-portal/projects/need-my-attention',
        component: NeedMyAttention,
      },
      {
        tabId: 1,
        label: 'Completed',
        link: `/projects/completed`,
        type: `completed`,
        path: 'management-portal/projects/completed',
        component: Completed,
      },
    ],
  },
];

export const getTab = (pathname) => {
  const params = pathname.split('/');
  return params[params.length - 1];
};

export const getCurrentTab = (pathname) => {
  return TABS.filter((TAB) => TAB.type === getTab(pathname))[0];
};

export const StartDate = (rowContent, row) => (
  <span>{dateTimeFormatter(rowContent)}</span>
);

export const showCredentials = (
  rowContent,
  { lastStatus, isCredentialsVisible }
) => {
  const validStatuses = [
    'draft',
    'under text changes',
    'inprogress',
    'changes rendered',
  ];

  if (
    validStatuses.includes(lastStatus.toLowerCase()) &&
    isCredentialsVisible
  ) {
    return <ActivityPopup />;
  }
  return null;
};

export const PROJECT_DETAIL_COLUMN = [
  {
    dataField: 'task',
    text: 'Task Name',
    formatter: ProjectDetailCheckIcon,
  },
  {
    dataField: 'completedAt',
    text: 'Product complete',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: StatusFormatter,
  },
  {
    dataField: 'isMessage',
    text: 'Product message',
    formatter: ProjectDetailChatIcon,
  },
  {
    dataField: 'status',
    text: 'Show Credentials',
    formatter: showCredentials,
  },
];

export const ACTIVE_PROJECT_COLUMN = [
  {
    dataField: 'projectName',
    text: 'Project',
    sort: true,
    formatter: ProjectDetail,
  },
  {
    dataField: 'projectId',
    text: 'Project ID',
    sort: true,
    formatter: OrderedBy,
  },
  {
    dataField: 'projectTitle',
    text: 'Name',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'broker',
    text: 'Contact',
    sort: true,
    formatter: TruncatedBrokerName,
  },
  {
    dataField: 'company',
    text: 'Company',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'productType',
    text: 'Product Type',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'startDate',
    text: 'Start date',
    sort: true,
    formatter: StartDate,
  },
  {
    dataField: 'trackStatus',
    text: 'Status',
    sort: true,
    formatter: ProjectStatus,
  },
];

export const CREXI_STATUS = {
  NOT_STARTED: 'Not Started',
};

export const convertDateWithTimezone = (date) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = moment(moment.utc(date));
  return moment.tz(newDate, tz).format('MM/DD/YYYY HH:mm:ss A');
};
