import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import PageLoader from 'common/PageLoader';
import NoData from 'common/NoData';
import Header from 'pages/PMPortal/common/Header';
import {
  GET_PROJECT_DETAILS,
  VIDEO_STATUS_UPDATED,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import Timeline from './components/Timeline';
import ProjectDetailHeader from './components/ProjectDetailHeader';

const VideoDevelopmentDetail = () => {
  let { projectId } = useParams();
  projectId = parseInt(projectId, 10);

  const { subscribeToMore, data, loading, error, refetch } = useQuery(
    GET_PROJECT_DETAILS,
    {
      variables: {
        projectId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: VIDEO_STATUS_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.videoStatusUpdated?.projects.length) {
          const { projects } = subscriptionData.data.videoStatusUpdated;
          if (projects.length) {
            const updatedProjectObj = {
              getProjectDetails:
                prev.getProjectDetails.id === projects[0].id
                  ? { ...projects[0] }
                  : prev.getProjectDetails,
            };
            return updatedProjectObj;
          }
          return prev;
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data && data.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        projectAsanaLink,
        slackPm,
        startDate,
        timeElapsed,
        projectEditor,
        projectManager,
        projectPilot,
        hubspotLink,
        deliveryDate,
        timeTillDue,
        trackStatus,
        videoStatus,
        b4uLink,
        crexiLink,
        googleLink,
        assetAddress,
        projectPilotId,
        isPilotInviteAccepted,
        planType,
        createdAt
      },
    } = data;

    return (
      <>
        <div className="video-review w-100 mt-3">
          <div className="video-review-head px-2 py-1 border-bottom">
            <ProjectDetailHeader
              projectId={projectId}
              projectName={projectName}
              projectAsanaLink={projectAsanaLink}
              slackPm={slackPm}
              startDate={startDate}
              timeElapsed={timeElapsed}
              projectEditor={projectEditor}
              projectManager={projectManager}
              hubspotLink={hubspotLink}
              deliveryDate={deliveryDate}
              timeTillDue={timeTillDue}
              trackStatus={trackStatus}
              videoStatus={videoStatus}
              planType={planType}
              createdAt={createdAt}
            />
          </div>
        </div>
        <Timeline
          projectPilotId={projectPilotId}
          isPilotInviteAccepted={isPilotInviteAccepted}
          projectId={projectId}
          b4uLink={b4uLink}
          crexiLink={crexiLink}
          googleLink={googleLink}
          projectAddress={assetAddress}
          refetchDetails={refetch}
          planType={planType}
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};
export default VideoDevelopmentDetail;
