import React from 'react';
import { useQuery } from '@apollo/client';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
//
import FileUploaderVidPitch from 'common/FileUploaderVidPitch';
import { GET_CUSTOM_ASSET_IMAGES } from 'pages/Vidpitch/VidPitchGraphQL';
import CustomImage from '../ChangeImage/components/CustomImage';

const UploadLogoModal = ({
  uuid,
  logoImageURL,
  uploadLogoModal,
  setLogoImageURL,
  toggleUploadLogoModal,
  currentChapter,
}) => {
  const {
    data: customImages,
    loading: customImageLoader,
    error: customImageError,
    refetch: refetchAssets,
  } = useQuery(GET_CUSTOM_ASSET_IMAGES, {
    variables: {
      fileType: 'LOGO',
      offset: 0,
      limit: 100,
      uuid,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Modal
      isOpen={uploadLogoModal}
      toggle={() => toggleUploadLogoModal(false)}
      className="modal-dialog-centered modal-xl"
    >
      <ModalHeader toggle={() => toggleUploadLogoModal(false)}>
        Upload Logo
      </ModalHeader>
      <ModalBody className="text-center">
        <Row>
          <Col md={7} className="border-right">
            <div className="mb-2">
              <b className="d-flex align-left font-size-16">
                Custom Uploaded Logos (
                {customImages?.getCustomAssetImages?.length || 0})
              </b>
              <span className="d-flex align-left font-size-14">
                * Select one of the below logo or upload a new logo.
              </span>
            </div>
            <CustomImage
              type="LOGO"
              error={customImageError}
              loader={customImageLoader}
              chapterAssetURL={logoImageURL}
              setChapterAssetURL={setLogoImageURL}
              customImages={customImages?.getCustomAssetImages}
            />
          </Col>
          <Col md={5}>
            <div className="image-req">
              <FileUploaderVidPitch
                uuid={uuid}
                fileType="LOGO"
                versionSuffix={0}
                refetchAssets={refetchAssets}
                currentChapter={currentChapter}
              />
              <div className="text-left">
                <b className="pl-3 font-size-18">Logo Requirements</b>
                <p className="pl-3 d-block text-danger my-2">
                  <b>Logo side should be 150 X 60.</b>
                </p>
                <p className="pl-3 d-block mb-1">
                  Please upload the highest quality images available, the image
                  will appear the same as it is uploaded. All images must be
                  licensed or approved for commercial marketing.
                </p>
                <p className="pl-3 d-block">
                  VidTech retains the right to remove any inappropriate or
                  unlicensed images.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UploadLogoModal;
