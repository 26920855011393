import React from 'react';
import { useSelector } from 'react-redux';
import ImageLoader from 'react-load-image';
import loadingImage from 'assets/loading.gif';
import { Card, CardBody } from 'reactstrap';
import BronzeChapterImage from 'assets/BronzeChapterImage.png';

const TemplatePreview = () => {
  const { currentPreviewURL, currentChapter } = useSelector(
    (state) => state.crexiBronze
  );

  const renderLoadingPreview = () => {
    return (
      <div className="v-loading-container">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <img
            height="46"
            alt="loading..."
            className="mr-3"
            src={loadingImage}
          />
        </div>
      </div>
    );
  };

  const renderPreviewSegment = () => {
    return (
      <ImageLoader src={currentPreviewURL}>
        <img alt="preview" className="v-loading-image-container w-100 mr-3" />
        <span className="text-danger">Unable to load image.</span>
        {renderLoadingPreview()}
      </ImageLoader>
    );
  };

  const renderEmptyMapPreview = () => {
    return (
      <>
        <img
          alt="preview"
          src={BronzeChapterImage}
          className="v-loading-image-container w-100 mr-3"
        />
        {/* <h3 className="crexi-bronze-empty-map-preview">Map {currentChapter}</h3> */}
      </>
    );
  };

  return (
    <div className="ml-2 mr-2 mt-3">
      <Card
        style={{ boxShadow: '0px 15px 40px #0000001F' }}
        className="border-0"
      >
        <CardBody style={{ position: 'relative' }}>
          {currentPreviewURL ? renderPreviewSegment() : renderEmptyMapPreview()}
        </CardBody>
      </Card>
    </div>
  );
};

export default TemplatePreview;
