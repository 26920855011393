import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { highPriorityUpdatesContext } from 'contexts/HighPriorityUpdates';
import { useHistory } from 'react-router-dom';
import { changeFlaggedFilters } from 'pages/BrokersDirectory/BrokersDirectoryActions';

const HighPriorityUpdates = () => {
  const { highPriorityUpdates, loading, error } = useContext(
    highPriorityUpdatesContext
  );
  const history = useHistory();
  const dispatch = useDispatch();

  if (error) return `Error! ${error.message}`;

  const renderCount = () => {
    if (loading) return <Spinner size="sm" color="primary" />;
    return (
      <strong>
        {' '}
        {highPriorityUpdates && highPriorityUpdates > 0
          ? highPriorityUpdates
          : 0}{' '}
      </strong>
    );
  };

  const handleChange = () => {
    history.push('/brokers-directory/flagged-records');
    dispatch(
      changeFlaggedFilters({
        flaggedPageInfo: {
          filters: {
            emailNotFound: true,
            needsEvaluation: true,
            withNotesOnly: false,
            priority1: true,
            priority2: false,
            priority3: false,
          },
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  return (
    <span>
      <Button
        outline
        className="high-priority-button"
        onClick={() => handleChange()}
      >
        High Priority Updates:
        {renderCount()}
      </Button>{' '}
    </span>
  );
};

export default HighPriorityUpdates;
