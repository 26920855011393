import React from 'react';
import { Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import VideoMSLogo from 'common/VideoMSLogo';
import CrexiBrandLogo from 'common/CrexiBrandLogo';
import PlansCard from 'pages/Search/common/PlansCard';
import AuctionCardWrapper from './components/AuctionCardWrapper';
import Filters from './components/Filters';

const Sidebar = () => {
  const history = useHistory();
  const {
    location: { search },
  } = history;

  return (
    <div className="sidebar-quad px-3">
      <div className="d-flex justify-content-between align-items-center logo-height">
        <VideoMSLogo />
        <CrexiBrandLogo />
      </div>
      <div className="portal-name">
        <span>SALES PORTAL</span>
      </div>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        className={`row ${
          search.includes('pendingOrder') ? 'invisible mt-4' : 'visible mt-4'
        } `}
        style={{ width: `calc(100% + 30px)` }}
      >
        <Col sm="12">
          <div>
            <PlansCard />
          </div>
          <div className="mt-3">
            <AuctionCardWrapper />
          </div>
          <Filters />
        </Col>
      </Scrollbars>
    </div>
  );
};
export default Sidebar;
