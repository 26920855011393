import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import ErrorBoundary from 'common/ErrorBoundary';
import Header from 'pages/ManagementPortal/common/Header';
import { BRONZE, PROJECT_DETAIL_PAGE_HEADINGS } from 'constants/appConstants';
import {
  GET_PROJECT_DETAIL,
  PROJECT_TASK_SUBSCRIPTION,
} from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import ProjectDetailContent from './components/ProjectDetailContent';

const ProjectDetailWrapper = ({ pmEditorSelectedPlan }) => {
  const { projectId, planName } = useParams();
  const parsedProjectId = parseInt(projectId, 10);
  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    GET_PROJECT_DETAIL,
    {
      variables: {
        projectId: parsedProjectId,
        planName: pmEditorSelectedPlan || planName?.toUpperCase(),
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const planType = pmEditorSelectedPlan || planName?.toUpperCase();
    const subscribe = subscribeToMore({
      document: PROJECT_TASK_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (planType === BRONZE) {
          if (subscriptionData?.data?.bronzeVideoStatus?.data) {
            const {
              data: {
                bronzeVideoStatus: {
                  data: { status, projectId: subscribedProjectId },
                },
              },
            } = subscriptionData;
            if (
              status &&
              parseInt(subscribedProjectId, 10) === parsedProjectId
            ) {
              refetch();
            }
          } else {
            toast.error('Video Rendering Failed');
          }
        }
        return prev;
      },
    });
    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;
  if (data) {
    if (data.getTaskDetails) {
      const { getTaskDetails } = data;
      if (!getTaskDetails.id) {
        return (
          <NoData
            customMessage="Your project has been initiated but not yet started. We will send an email when your project starts."
            animation
          />
        );
      }

      return (
        <ProjectDetailContent
          parsedProjectId={parsedProjectId}
          projectData={getTaskDetails}
          refetch={refetch}
          pmEditorSelectedPlan={pmEditorSelectedPlan}
        />
      );
    }

    return (
      <NoData customMessage="Project details for requested project does not exist." />
    );
  }

  return <NoData customMessage="Requested project does not exist." />;
};

const ProjectDetail = ({ pmEditorSelectedPlan = null }) => {
  const location = useLocation();
  const { pathname } = location;
  const { ACTIVE, NEED_MY_ATTENTION, COMPLETED } = PROJECT_DETAIL_PAGE_HEADINGS;
  const getHeading = () => {
    if (pathname.includes(NEED_MY_ATTENTION.slug)) {
      return NEED_MY_ATTENTION.label;
    }
    if (pathname.includes(ACTIVE.slug)) {
      return ACTIVE.label;
    }
    return COMPLETED.label;
  };
  return (
    <>
      {!pmEditorSelectedPlan && (
        <Header heading={getHeading()} showBackButton />
      )}

      <div className="management-border">
        <ErrorBoundary>
          <ProjectDetailWrapper pmEditorSelectedPlan={pmEditorSelectedPlan} />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default ProjectDetail;
