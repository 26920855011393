import React from 'react';
import client from 'config/apollo';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { Badge, Button } from 'reactstrap';
import { GET_ASSESTS_DETAILS } from 'pages/Search/CrexiSearchGraphQL';

const MESSAGE = 'No data entered!';

const Message = ({ distance }) => {
  let message = MESSAGE;
  if ([10, 20, 100].indexOf(distance) >= 0) {
    message = MESSAGE;
  }
  return <div className="mb-2">{message}</div>;
};

const CityCalloutsList = ({
  dataArray,
  fieldName,
  getAssetDetails,
  distance,
}) => {
  const deletedFields = dataArray.filter(
    (data) => data.delete && data.distanceFromAsset === distance
  );
  const fieldArray = dataArray.filter(
    (data) => data.distanceFromAsset === distance
  );
  if (deletedFields.length === fieldArray.length)
    return <Message distance={distance} />;

  const handleRemove = async (object) => {
    const { id } = object;
    const assetDetails = cloneDeep(getAssetDetails);
    if (id) {
      assetDetails[fieldName].filter(
        (row) => row.name === object.name
      )[0].delete = true;
    } else {
      assetDetails[fieldName] = assetDetails[fieldName].filter(
        (row) => row.name !== object.name
      );
    }
    try {
      await client.writeQuery({
        query: GET_ASSESTS_DETAILS,
        variables: {
          assetId: getAssetDetails.asset.id,
        },
        data: {
          getAssetDetails: assetDetails,
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderBadgeElement = () => {
    if (dataArray && dataArray.length) {
      return dataArray.map((row) => {
        if (row.delete) return null;
        if (row.distanceFromAsset === distance) {
          return (
            <Badge
              key={row.id}
              color="light"
              pill
              className="mr-2 filter-badge"
            >
              {row.name}
              <Button close onClick={() => handleRemove(row)} />
            </Badge>
          );
        }
        return null;
      });
    }
    return <Message distance={distance} />;
  };

  return renderBadgeElement();
};

export default CityCalloutsList;
