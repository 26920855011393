import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import PageLoader from 'common/PageLoader';
import Header from 'pages/PMPortal/common/Header';
import VideoReviewHeader from 'components/VideoReviewHeader';
import NoData from 'common/NoData';
import {
  LIST_ASSET_VERSIONS,
  GET_REVIEWED_PROJECT_HEADER_DETAILS,
} from 'containers/AppContainer/AppGraphQL';
import ErrorBoundary from 'common/ErrorBoundary';
import AssetCommentsProvider from './components/AssetCommentsProvider';

const useGetVersionsList = () => {
  const { folderId } = useParams();
  const { data, loading, error } = useQuery(LIST_ASSET_VERSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      folderId: parseInt(folderId, 10),
    },
  });

  return { versionLoading: loading, versionError: error, versionData: data };
};

const VideoReview = () => {
  const [selectedVersion, setVersion] = useState({});

  const { folderId } = useParams();
  const { versionData, versionLoading } = useGetVersionsList(folderId);
  const { data, loading, error } = useQuery(
    GET_REVIEWED_PROJECT_HEADER_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: {
        folderId: parseInt(folderId, 10),
        version: selectedVersion.version,
      },
    }
  );

  const handleSelectVersion = (version) => {
    setVersion(version);
  };

  useEffect(() => {
    if (versionData?.listAssetVersions?.length) {
      handleSelectVersion(versionData.listAssetVersions[0]);
    }
  }, [versionData]);

  if (versionLoading || loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data && data.getReviewedProjectHeaderDetails) {
    const {
      getReviewedProjectHeaderDetails: {
        folderName,
        projectName,
        projectId,
        startDate,
        elaspedTime,
        frameIoLink,
        asanaLink,
        slackLink,
        hubspotLink,
        projectEditor,
        planType,
      },
    } = data;

    return (
      <>
        <Header heading="Projects" />
        <VideoReviewHeader
          forPMPortal
          projectId={projectId}
          startDate={startDate}
          asanaLink={asanaLink}
          folderName={folderName}
          projectName={projectName}
          elaspedTime={elaspedTime}
          frameIoLink={frameIoLink}
          slackLink={slackLink}
          hubspotLink={hubspotLink}
          selectedVersion={selectedVersion}
          handleSelectVersion={handleSelectVersion}
          versionData={versionData ? versionData.listAssetVersions : []}
          planType={planType}
        />
        <ErrorBoundary>
          <AssetCommentsProvider
            version={selectedVersion.version}
            commentTo={projectEditor}
          />
        </ErrorBoundary>
      </>
    );
  }
  return (
    <>
      <Header />
      <NoData customMessage="No comments exist for requested asset." />
    </>
  );
};
export default VideoReview;
