import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { isObject, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

import { useMutation } from '@apollo/client';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import {
  TABS,
  getCurrentTab,
  verifyIVCEmptyFields,
} from 'pages/Search/constant';
import { ADD_ASSET_DETAILS } from 'pages/Search/CrexiSearchGraphQL';
import {
  removeProperties,
  removeBlankCityCallouts,
  dateTimeFormatter,
  IVC_FIELDS,
} from 'utils/helper';

const tempIVCFields = ['id', ...IVC_FIELDS];

const DetailViewNextButton = ({
  fieldKey,
  values,
  getAssetDetails,
  refetch,
  isDisabled,
}) => {
  const history = useHistory();
  const { assetId } = useParams();
  const { eliteAuction: selectedEliteAuction, plan } = useSelector(
    (state) => state.salesPortal
  );

  const { handleEditAsset, assetDetail, setIsIVCDisabled } =
    useContext(assetDetailContext);

  let eliteAuction = '';
  if (selectedEliteAuction) {
    eliteAuction = selectedEliteAuction;
  } else if (getAssetDetails && getAssetDetails.eliteAuction) {
    eliteAuction = getAssetDetails.eliteAuction;
  } else {
    eliteAuction = '';
  }

  const currentTab = getCurrentTab(history.location.pathname);
  const { tabId } = currentTab || { tabId: 0, label: '' };

  const [addAssetDetails, { loading }] = useMutation(ADD_ASSET_DETAILS);

  const assetDetails = cloneDeep(getAssetDetails);

  const goTo = async (newTab) => {
    const { link } = newTab;
    history.push(`/crexi-search/property-detail/${assetId}${link}`);
  };

  const goToNextTab = async () => {
    try {
      const nextTab = TABS[tabId + 1];

      let updatedAssetDetails = {};
      updatedAssetDetails = cloneDeep(assetDetails);
      updatedAssetDetails[fieldKey] = values[fieldKey];
      updatedAssetDetails.interiorVideoContact = {
        ...assetDetail.interiorVideoContact,
        ...updatedAssetDetails.interiorVideoContact,
      };

      let modifiedData = await removeProperties(updatedAssetDetails);

      modifiedData = removeBlankCityCallouts(modifiedData);

      modifiedData.status = 'Inprogress';
      modifiedData.eliteAuction = eliteAuction;

      if (modifiedData.callToAction && !modifiedData.callToAction.text) {
        modifiedData.callToAction = null;
      }

      const hasAllEmptyField = verifyIVCEmptyFields(
        modifiedData.interiorVideoContact,
        tempIVCFields
      );

      if (hasAllEmptyField) {
        modifiedData.interiorVideoContact = null;
      }

      if (modifiedData.interiorVideoContact) {
        const { shootTime, email } = modifiedData.interiorVideoContact;
        if (isObject(shootTime)) {
          modifiedData.interiorVideoContact.shootTime = dateTimeFormatter(
            shootTime,
            'hh:mm:ss'
          );
        } else {
          modifiedData.interiorVideoContact.shootTime = null;
          modifiedData.interiorVideoContact.shootDate = null;
        }

        if (!email) modifiedData.interiorVideoContact.email = null;
      }

      modifiedData.assetId = assetDetails.asset.id;
      delete modifiedData.asset;
      try {
        await addAssetDetails({
          variables: { projectPlan: plan.type ?? 'PLATINUM', ...modifiedData },
          update: async (cache, { data: { addAssetDetails } }) => {
            toast.success('Asset details added successfully');
            await refetch();
            await handleEditAsset(false, null);
            // if (addAssetDetails.interiorVideoContact) {
            //   await setIsIVCDisabled(false);
            // }
            if (nextTab) goTo(nextTab);
          },
          error: (e) => {
            toast.error(e.message);
          },
        });
      } catch (e) {
        toast.error(e.message);
      }
    } catch (e) {
      toast.error(e.message);
    }

    return true;
  };

  return (
    <Button
      color="primary"
      disabled={isDisabled}
      className="ml-2 font-size-14"
      onClick={goToNextTab}
    >
      Next
    </Button>
  );
};

export default DetailViewNextButton;
