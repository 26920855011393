import React from 'react';
import {
  convertDateWithTimezone,
  CREXI_STATUS,
} from 'pages/ManagementPortal/constant';

const ActivityItems = ({ activities }) => {
  const { NOT_STARTED } = CREXI_STATUS;

  return activities.map((col, index) => {
    const { lastUpdatedBy, completedOn, crexiTaskStatus } = col;

    if (index === 0 && crexiTaskStatus === NOT_STARTED) {
      return (
        <tr className="activity-log">
          <td />
          <td>
            <p className="textIndent45 mb-0 d-block text-truncate">
              Task not started yet
            </p>
          </td>
          <td>-</td>
          <td />
          <td />
        </tr>
      );
    }

    return (
      <tr key={`${index + 1}`} className="activity-log">
        <td />
        <td>
          <p className="textIndent45 mb-0 d-block text-truncate">
            {lastUpdatedBy} updated to{' '}
            <strong className="status" title={crexiTaskStatus}>
              {crexiTaskStatus}{' '}
            </strong>
          </p>
        </td>
        <td>
          <span className="date-time">
            {completedOn ? convertDateWithTimezone(completedOn) : ''}
          </span>
        </td>
        <td />
        <td />
      </tr>
    );
  });
};

export default ActivityItems;
