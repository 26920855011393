import React from 'react';
import { Table } from 'reactstrap';
import DealItem from './components/DealItem';

const DealsTable = ({ tableData }) => {
  return (
    <Table className="table record-update deal-table">
      <tbody>
        {tableData &&
          tableData.map((row) => {
            return <DealItem key={Math.random()} dealInfo={row} />;
          })}
      </tbody>
    </Table>
  );
};
export default DealsTable;
