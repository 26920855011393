import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import MarqueeVideoDialog from './components/MarqueeVideoDialog';

const Sidebar = () => {
  const [openVideoDialog, setVideoDialog] = useState(false);
  const [index, setIndex] = useState(0);

  const toggleVideoDialog = (videoIndex) => {
    setVideoDialog(!openVideoDialog);
    setIndex(videoIndex);
  };

  return (
    <Card className="border-0 mt-3 h-100 v-background-color">
      <CardBody>
        <h5>Watch VidTech Samples</h5>

        <Row className="my-3">
          <Col md={6} className="mb-2">
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => toggleVideoDialog(0)}
              className="px-1 p-0"
            >
              Retail
            </Button>
          </Col>

          <Col md={6} className="mb-2">
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => toggleVideoDialog(1)}
              className="px-1 p-0"
            >
              Office
            </Button>
          </Col>

          <Col md={6} className="mb-2">
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => toggleVideoDialog(2)}
              className="px-1 p-0"
            >
              Multifamily
            </Button>
          </Col>

          <Col md={6} className="mb-2">
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => toggleVideoDialog(3)}
              className="px-1 p-0"
            >
              Industrial/Land
            </Button>
          </Col>
        </Row>

        <h6>{`CRE's Most Effective Videos`}</h6>

        <div className="mt-2">
          <p className="font-size-14">
            {`VidTech offer's a full suite of CRE video options, including 3D satellite footage, drone footage, virtual tours, 3D models and more. Learn more about CRE's leading provider of video solutions by booking a demo with an experienced Account Manager.`}
          </p>
        </div>

        <div className="p-4 bg-light">
          <p className="text-center font-size-14">
            To learn about our streamlined process and standard pricing
          </p>
          <div className="text-center">
            <Link
              to={{
                pathname:
                  'https://meetings.hubspot.com/evan83/vidtech-account-manager-round-robin-demo',
              }}
              target="_blank"
            >
              <Button color="primary" className="font-size-14">
                Book a Demo
              </Button>
            </Link>
          </div>
        </div>
      </CardBody>
      <MarqueeVideoDialog
        setVideoDialog={setVideoDialog}
        openVideoDialog={openVideoDialog}
        toggleVideoDialog={toggleVideoDialog}
        index={index}
        setIndex={setIndex}
      />
    </Card>
  );
};

export default Sidebar;
