import React, { useState } from 'react';
import { without } from 'lodash';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import RBAC from 'hoc/RBAC';
import RBFP from 'hoc/RBFP';
import NoData from 'common/NoData';
import { ROLES } from 'constants/role';
import PageLoader from 'common/PageLoader';
import { POST } from 'constants/permissions';
import { BRONZE } from 'constants/appConstants';
import AssetDownload from 'common/AssetDownload';
import Header from 'pages/PMPortal/common/Header';
import { PORTALS } from 'constants/appNavigations';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import ProjectAssetProvider from 'components/ProjectAssetProvider';
import { editorFilesToDownload } from 'pages/EditorPortal/EditorPortalActions';
import UploadModal from './components/UploadModal';
import { GET_PROJECT_DETAILS } from '../../ProjectsGraphQL';
import ProjectDetailHeader from '../VideoAssetsFolders/components/ProjectDetailHeader';

const ProjectAssets = ({ hasFilesInFolderListing = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { projectId } = useParams();
  const { parentName, parent, child } = useParams();
  const [filesCount, setFilesCount] = useState(0);

  const { filesToDownload } = useSelector((state) => state.editorPortal);
  const {
    location: { state },
  } = history;

  const handleSelectFile = (key, status) => {
    if (status) {
      if (!filesToDownload.includes(key)) {
        dispatch(
          editorFilesToDownload({ filesToDownload: [...filesToDownload, key] })
        );
      }
    } else {
      dispatch(
        editorFilesToDownload({
          filesToDownload: without(filesToDownload, key),
        })
      );
    }
  };

  const handleSelectAll = (status, allAssets) => {
    if (status) {
      dispatch(editorFilesToDownload({ filesToDownload: [...allAssets] }));
    } else {
      dispatch(editorFilesToDownload({ filesToDownload: [] }));
    }
  };

  const {
    EDITOR_PORTAL: { slug },
  } = PORTALS;

  projectId = parseInt(projectId, 10);
  const { data, loading, error } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const goBackToList = () => {
    // history.go(-1);
    history.push(`/editor-portal/projects/${projectId}/folders`);
  };
  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data && data.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        startDate,
        projectAsanaLink,
        slackLink,
        timeElapsed,
        projectEditor,
        projectPilot,
        projectManager,
        isPilotInviteAccepted,
        isEditorInviteAccepted,
        isCompleted,
        planType,
        status,
      },
    } = data;

    let uploadLocation;
    if (child) {
      uploadLocation = `${projectName}/${parentName}/${parent}/${child}`;
    } else {
      uploadLocation = `${projectName}/${parentName}/${parent}`;
    }

    const renderParentFolderName = () => {
      if (child) {
        return child;
      }
      return parent;
    };
    return (
      <AWSFolderAssetsProvider
        assetLocation={uploadLocation}
        planType={planType}
      >
        <Header heading={projectName} />
        <div className="screenshots">
          {!hasFilesInFolderListing && (
            <div className="screenshots-head mb-2">
              <div className="video-review-head">
                <ProjectDetailHeader
                  projectId={projectId}
                  projectName={projectName}
                  projectAsanaLink={projectAsanaLink}
                  slackLink={slackLink}
                  startDate={startDate}
                  timeElapsed={timeElapsed}
                  projectEditor={projectEditor}
                  projectManager={projectManager}
                  projectPilot={projectPilot}
                  isPilotInviteAccepted={isPilotInviteAccepted}
                  isEditorInviteAccepted={isEditorInviteAccepted}
                  isCompleted={isCompleted}
                  planType={planType}
                  status={status}
                />
              </div>
            </div>
          )}

          <Row>
            {!hasFilesInFolderListing && (
              <Col md={6}>
                <Button
                  color="link"
                  className="back-btn color-inherit px-0 folder-back-btn"
                  onClick={goBackToList}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
                  {renderParentFolderName()}
                </Button>
              </Col>
            )}

            <Col md={hasFilesInFolderListing ? 12 : 6}>
              <div className="text-right">
                {planType !== BRONZE && (
                  <RBAC allowedRoles={[ROLES.VIDEOMS_EDITOR]}>
                    <RBFP
                      key={state?.subDirectoryName}
                      permissionType={POST}
                      subDirectoryName={state?.subDirectoryName}
                      directoryName={state?.directoryName}
                    >
                      <UploadModal
                        upload="Upload"
                        projectId={projectId}
                        uploadLocation={uploadLocation}
                        versionSuffix={filesCount}
                      />
                    </RBFP>
                  </RBAC>
                )}
                <AssetDownload reducerName="editorPortal" />
              </div>
            </Col>
          </Row>

          <div>
            <Row>
              <Col>
                <ProjectAssetProvider
                  portalSlug={slug}
                  setFilesCount={setFilesCount}
                  handleSelectAll={handleSelectAll}
                  handleSelectFile={handleSelectFile}
                  hasFilesInFolderListing={hasFilesInFolderListing}
                  parent={parent}
                />
              </Col>
            </Row>
          </div>
        </div>
      </AWSFolderAssetsProvider>
    );
  }

  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};
export default ProjectAssets;
