import React, { createContext, useReducer } from 'react';

const bronzeContext = createContext();

const initialState = {
  s3ImageLinkArray: [],
  fileType: 'IMAGE',
  projectUuid: '',
};

const reducerFunc = (bronzeData = initialState, action) => {
  switch (action.type) {
    case 'IMAGE_LINKS':
      return { ...bronzeData, s3ImageLinkArray: action.s3ImageLinkArray };
    case 'FILE_TYPE':
      return { ...bronzeData, fileType: action.fileType };
    case 'PROJECT_UUID':
      return { ...bronzeData, projectUuid: action.projectUuid };
    case 'RESET_BRONZE_DATA':
      return initialState;
    default:
      return bronzeData;
  }
};

const BronzeProvider = ({ children }) => {
  const [bronzeData, dispatchBronzeData] = useReducer(
    reducerFunc,
    initialState
  );

  return (
    <bronzeContext.Provider value={{ bronzeData, dispatchBronzeData }}>
      {children}
    </bronzeContext.Provider>
  );
};

export { BronzeProvider, bronzeContext };
