import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_ASSEST_PLAN } from 'pages/Search/CrexiSearchGraphQL';

const useGetAssestPlan = () => {
  const { assetId } = useParams();
  if (!assetId)
    return { loading: false, data: { getAssetPlan: null }, error: null };
  const { data, loading, error } = useQuery(GET_ASSEST_PLAN, {
    variables: {
      assetId: parseInt(assetId, 10),
    },
    fetchPolicy: 'network-only'
  });
  return { loading, data, error };
};

export default useGetAssestPlan;
