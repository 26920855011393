import React, { useEffect, useRef } from 'react';
import NoData from 'common/NoData';
import { dateTimeFormatter } from 'utils/helper';

const NoteListing = ({ listNotes }) => {
  const notesRef = useRef();
  useEffect(() => {
    if (listNotes.length) {
      notesRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [listNotes]);

  const renderNotes = () => {
    if (!listNotes.length) return <NoData customMessage="No notes available" />;
    return listNotes.map(
      ({ id, notesUserRole, noteText, createdAt, notesUsername }) => {
        return (
          <div key={id} ref={notesRef}>
            <span>
              <b className="text-capitalize">
                {`(${notesUserRole}) ${notesUsername || ''}`} :{' '}
              </b>{' '}
              {dateTimeFormatter(createdAt, 'MM/DD/YYYY hh:mm a')}
            </span>

            <p>{noteText}</p>
          </div>
        );
      }
    );
  };

  return <>{renderNotes()}</>;
};

export default NoteListing;
