import React from 'react';
import { Badge, Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { dateTimeFormatter } from 'utils/helper';

const EditorItem = ({
  editorInfo,
  toggleEditorProfileModal,
  toggleEditProfileModal,
}) => {
  const {
    id,
    availability,
    location,
    name,
    projectCompleted,
    timeZone,
    photo,
    recommended,
  } = editorInfo;

  const renderAvailability = () => {
    if (availability && typeof availability !== 'string') {
      const { projectCompleteDate } = availability;
      return <>{dateTimeFormatter(projectCompleteDate)}</>;
    }

    return availability ? (
      <span className="text-success">{availability}</span>
    ) : (
      <span className="text-success">NA</span>
    );
  };

  const renderRecommendedBadge = () => {
    if (recommended) {
      return (
        <Badge color="danger" pill className="p-2 px-2">
          <span>Recommended</span>
        </Badge>
      );
    }
    return '';
  };

  return (
    <tr key={id}>
      <td className="align-middle" colSpan="2">
        <Row className="align-items-center">
          <Col md={2}>
            {photo !== 'Not specified' ? (
              <img
                src={photo}
                alt="editor-profile"
                width="35"
                height="35"
                style={{ borderRadius: '50%' }}
                className="rounded-circle"
              />
            ) : (
              <FontAwesomeIcon
                size="3x"
                className="mr-2"
                icon={faUserCircle}
                color="#3AB42B"
              />
            )}
          </Col>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <div>
                <div className="text-truncate">{name}</div>
              </div>
              <div className="ml-4">{renderRecommendedBadge()}</div>
            </div>
          </Col>
        </Row>
      </td>
      <td className="align-middle">{`${location}  (${timeZone})`}</td>
      <td className="align-middle">{projectCompleted || 0}</td>
      <td className="align-middle">{renderAvailability()}</td>
      <td className="align-middle">
        <Button
          color="link"
          className="font-size-13 outline-none"
          onClick={() => toggleEditorProfileModal(true, editorInfo)}
        >
          View Profile
        </Button>
      </td>
      <td className="align-middle">
        <Button
          color="link"
          className="font-size-13 outline-none"
          onClick={() => toggleEditProfileModal(true, editorInfo)}
        >
          Edit Profile
        </Button>
      </td>
    </tr>
  );
};

export default EditorItem;
