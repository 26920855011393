import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
//
import { toast } from 'react-toastify';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { dateTimeFormatter } from 'utils/helper';
import {
  GET_ASSET_LISTING,
  LIST_UPDATED_VIDEOS,
} from 'pages/Vidpitch/VidPitchGraphQL';
import VidTechHeader from 'pages/Vidpitch/common/VidtechHeader';
import { authContext } from 'contexts/AuthContext';
import VidTechSearch from './components/VidtechSearch';
import AssetTable from './components/AssetTable';

const AssetTableWrapper = ({
  page,
  offset,
  endDate,
  startDate,
  globalSearchText,
}) => {
  const limit = 10;

  const { loading, data, error, refetch, subscribeToMore } = useQuery(
    GET_ASSET_LISTING,
    {
      variables: {
        limit,
        offset,
        search: globalSearchText || null,
        endDate: endDate && dateTimeFormatter(endDate, 'YYYY/MM/DD'),
        startDate: startDate && dateTimeFormatter(startDate, 'YYYY/MM/DD'),
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: LIST_UPDATED_VIDEOS,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.listUpdatedVideos) {
          refetch();
          // toast.success(
          //   'Video rendering completed!! Please check your modified video.'
          // );
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  if (data?.listVideos) {
    const {
      listVideos: { videoDetails, totalCount },
    } = data;

    if (!videoDetails.length)
      return <NoData customMessage="No video assets found" />;
    const pageCount = Math.ceil(totalCount / limit);

    return (
      <AssetTable
        refetch={refetch}
        filters={{
          page,
          offset,
          endDate,
          startDate,
          globalSearchText,
        }}
        limit={limit}
        pageCount={pageCount}
        totalCount={totalCount}
        videosData={videoDetails}
      />
    );
  }

  return <NoData customMessage="No video assets found" />;
};

const AssetsListing = () => {
  const { auth } = useContext(authContext);

  const {
    assetListInfo: {
      endDate,
      startDate,
      globalSearchText,
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.vidPitch);

  return (
    <div>
      <Helmet defaultTitle="VidTech" />
      <VidTechHeader userRole={auth?.data?.role} />
      <VidTechSearch
        filters={{
          page,
          offset,
          endDate,
          startDate,
          globalSearchText,
        }}
      />
      <AssetTableWrapper
        page={page}
        offset={offset}
        endDate={endDate}
        startDate={startDate}
        globalSearchText={globalSearchText}
      />
    </div>
  );
};

export default AssetsListing;
