import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ActiveProjects from './components/ActiveProjects';
import ProjectDetail from './components/ProjectDetail';
import VideoAssetsFolders from './components/VideoAssetsFolders';
import ProjectAssets from './components/ProjectAssets';
import VideoDevelopmentDetail from './components/VideoDevelopmentDetail';
import VideoReview from './components/VideoReview';
import SubFoldersAssets from './components/SubFoldersAssets';
import ProjectInvitation from './components/ProjectInvitation';

const Projects = ({ component, ...rest }) => {
  const { path } = rest.match;

  return (
    <Row>
      <Col>
        <Switch>
          <Route
            exact
            path={`${path}/project-list`}
            component={ActiveProjects}
          />

          <Route
            exact
            path={`${path}/video-review/:ID/:folderId`}
            component={VideoReview}
          />

          <Route
            path={`${path}/active-projects/project-detail/:projectId`}
            component={ProjectDetail}
          />

          <Route
            path={`${path}/:projectId/folders`}
            exact
            component={VideoAssetsFolders}
          />

          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders/:child`}
            component={ProjectAssets}
          />

          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders`}
            component={SubFoldersAssets}
          />

          <Route
            path={`${path}/:projectId/folders/:parentName/:parent`}
            component={ProjectAssets}
          />

          <Route
            exact
            path={`${path}/project-detail/:projectId`}
            component={VideoDevelopmentDetail}
          />

          <Route
            exact
            path={`${path}/project-invitation/:projectId`}
            component={ProjectInvitation}
          />
        </Switch>
      </Col>
    </Row>
  );
};
export default Projects;
