import React, { useContext, useEffect, useState } from 'react';
import Loader from 'common/Loader';
import { forEach } from 'lodash-es';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { useQuery, useMutation } from '@apollo/client';

import {
  LIST_REVIEW_ASSET_COMMENTS,
  ACCEPT_BROKER_COMMENTS_BY_PROJECT_MANAGER,
  REVIEW_ASSET_COMMENT_STATUS,
  ACCEPTED_BROKER_COMMENT,
  BROKER_COMMENT_ON_REVIEW_ASSET,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import NoData from 'common/NoData';
import { authContext } from 'contexts/AuthContext';
import Screenshot from './components/ScreenShot';

const AssetComments = ({
  listReviewAssetComments,
  refechAssetComments,
  handleAcceptSingleComment,
  projectEditor,
  acceptAllCommentStatus,
}) => {
  return listReviewAssetComments.map(
    ({
      comments,
      commentTo,
      commentId,
      commentFrom,
      commentTime,
      commentSource,
      commentCreatedAt,
      commentCompleted,
      commentScreenShot,
      annotationCords,
    }) => {
      return (
        <Screenshot
          key={commentId}
          comments={comments}
          commentId={commentId}
          commentTo={commentTo}
          commentTime={commentTime}
          commentFrom={commentFrom}
          commentSource={commentSource}
          commentCompleted={commentCompleted}
          parentCommentCreatedAt={commentCreatedAt}
          commentScreenShot={commentScreenShot}
          refechAssetComments={refechAssetComments}
          handleAcceptSingleComment={handleAcceptSingleComment}
          annotationCords={annotationCords}
          projectEditor={projectEditor}
          acceptAllCommentStatus={acceptAllCommentStatus}
        />
      );
    }
  );
};

const AssetCommentsProvider = ({ version, commentTo }) => {
  const { auth } = useContext(authContext);
  const [acceptAllCommentStatus, toggleAcceptAllCommentStatus] =
    useState(false);
  const [acceptAllComments] = useMutation(
    ACCEPT_BROKER_COMMENTS_BY_PROJECT_MANAGER
  );
  const { folderId } = useParams();

  useEffect(() => {
    if (acceptAllCommentStatus) {
      toggleAcceptAllCommentStatus(false);
    }
  }, [acceptAllCommentStatus]);

  const { subscribeToMore, data, loading, error, refetch } = useQuery(
    LIST_REVIEW_ASSET_COMMENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        folderId: parseInt(folderId, 10),
        version: version || '',
      },
    }
  );
  useEffect(() => {
    const subscribe = subscribeToMore({
      document: BROKER_COMMENT_ON_REVIEW_ASSET,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.addedBrokerCommentsOnReviewAsset) {
          return refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: REVIEW_ASSET_COMMENT_STATUS,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.reviewAssetCommentStatus
        ) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribeBrokerComment = subscribeToMore({
      document: ACCEPTED_BROKER_COMMENT,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.acceptedBrokerComment
        ) {
          toggleAcceptAllCommentStatus(true);
          refetch();
        }
        return prev;
      },
    });

    return () => subscribeBrokerComment();
  }, []);

  if (loading) return <Loader />;

  if (error) return <div>{error.message}</div>;

  if (data && data.listReviewAssetComments) {
    const { listReviewAssetComments } = data;
    if (!listReviewAssetComments.length) {
      return <NoData />;
    }

    const handleAcceptAllComments = async () => {
      toggleAcceptAllCommentStatus(true);
      const acceptedComments = [];
      if (auth && auth.data) {
        const { role, token } = auth.data;
        const decryptedToken = await jwtDecode(token);
        const {
          data: { userId },
        } = decryptedToken;

        listReviewAssetComments.forEach((object) => {
          const { commentFrom, commentId, comments } = object;

          comments.forEach((o) => {
            if (!o.commentAccepted) {
              const commentObject = { commentFrom, commentId };
              commentObject.commentText = o.commentText;
              commentObject.isEdited = o.isEditedComment || false;
              commentObject.commentFrom = userId;
              commentObject.commentTo = commentTo;
              acceptedComments.push(commentObject);
            }
          });
        });
      }
      try {
        await acceptAllComments({
          variables: {
            input: acceptedComments,
          },
          update: async (cache, { data }) => {
            toast.success('Accepted all comments!');
            await refetch();
          },
          error: (e) => {
            toast.error(e.message);
          },
        });
      } catch (e) {
        toast.error(e.message);
      }
    };

    const handleAcceptSingleComment = async (editedComment) => {
      try {
        await acceptAllComments({
          variables: {
            input: [editedComment],
          },
          update: async (cache, { data }) => {
            toast.success('Accepted comment!');
            await refetch();
          },
          error: (e) => {
            toast.error(e.message);
          },
        });
      } catch (e) {
        toast.error(e.message);
      }
    };

    let disabledAcceptComments = true;
    forEach(listReviewAssetComments, (review, index) => {
      const { comments } = review;
      forEach(comments, (comment, index) => {
        if (!comment.commentAccepted) {
          disabledAcceptComments = false;
        }
      });
    });

    return (
      <>
        <div>
          <div className="my-3">
            <Row>
              <Col md={6}>
                <h3 className="font-size-14">COMMENTS</h3>
              </Col>
              <Col md={6} className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  className="font-size-14 font-weight-light px-4 "
                  disabled={disabledAcceptComments}
                  onClick={() => {
                    handleAcceptAllComments();
                  }}
                >
                  Accept Comments
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <hr />
        <div>
          <AssetComments
            refechAssetComments={refetch}
            listReviewAssetComments={listReviewAssetComments}
            handleAcceptAllComments={handleAcceptAllComments}
            handleAcceptSingleComment={handleAcceptSingleComment}
            projectEditor={commentTo}
            acceptAllCommentStatus={acceptAllCommentStatus}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        <div className="my-3">
          <Row>
            <Col md={6}>
              <h3 className="font-size-14">COMMENTS</h3>
            </Col>
          </Row>
        </div>
      </div>
      <hr />
      <div>
        <NoData />
      </div>
    </>
  );
};
export default AssetCommentsProvider;
