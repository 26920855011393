import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Col,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormikInputField from 'components/Fields/FormikInputField';

import { FLAG_BROKER_BROKERS_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import { highPriorityUpdatesContext } from 'contexts/HighPriorityUpdates';

const NOTE_MAX_LENGTH = 100;

const FlaggedModal = ({
  flaggedModal: {
    isFlagModalOpen,
    data: { firstName, lastName, company, id, email },
  },
  toggleFlagModal,
  refetch,
}) => {
  const { highPriorityUpdates: highPriorityCount } = useContext(
    highPriorityUpdatesContext
  );

  const flaggedModalFormData = {
    flagType: 'Requires Further Evaluation',
    priority: 'Priority 1',
    brokerId: id,
    note: '',
  };

  const [flagBroker, { loading }] = useMutation(FLAG_BROKER_BROKERS_DIRECTORY, {
    onCompleted: async ({ flagBroker: { message } }) => {
      toggleFlagModal(false);
      await refetch();
      toast.success(message);
    },
    onError: (e) => {
      toggleFlagModal(false);
      toast.error(e.message);
    },
  });

  const saveFlaggedData = async ({ brokerId, flagType, priority, note }) => {
    const modifiedData = {
      priority,
      flagType,
      brokerId,
      note,
    };
    try {
      await flagBroker({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isFlagModalOpen}
        toggle={() => toggleFlagModal(false)}
        className="flagged-modal"
      >
        <ModalHeader className="py-2" toggle={() => toggleFlagModal(false)}>
          Flagged Records
        </ModalHeader>
        <ModalBody>
          <div className="d-flex user">
            <div>
              <img
                src="https://via.placeholder.com/40"
                className="img-fluid rounded-circle"
                alt="Flagged Records user"
              />
            </div>
            <div className="flex-fill ml-2">
              <p className="mb-0">
                {firstName} {lastName}
              </p>
              <span>{company}</span>
            </div>
          </div>

          <Formik
            enableReinitialize
            initialValues={flaggedModalFormData}
            onSubmit={(values) => {
              saveFlaggedData(values);
            }}
            children={({ values }) => {
              return (
                <Form>
                  <div>
                    <h6 className="my-3 flag-type">Flag type</h6>
                    <Row>
                      <Col>
                        <Label>
                          <Field
                            type="radio"
                            name="flagType"
                            value="Requires Further Evaluation"
                            checked
                          />{' '}
                          Requires Further Evaluation
                        </Label>
                      </Col>

                      <Col>
                        <Label>
                          <Field
                            type="radio"
                            name="flagType"
                            value="Broker's email not found!"
                            disabled={email}
                          />{' '}
                          Broker’s email not found!
                        </Label>
                      </Col>
                    </Row>
                  </div>
                  <div className="priority">
                    <h6 className="my-3 ">Set priority</h6>
                    <Row>
                      <Col>
                        <Label>
                          <Field
                            type="radio"
                            name="priority"
                            value="Priority 1"
                            checked
                          />
                          <span className="priority-1 p-1 px-2 ml-2">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFlag}
                              color="#E94853"
                            />
                            Priority 1
                          </span>
                        </Label>
                      </Col>
                      <Col>
                        <Label>
                          <Field
                            type="radio"
                            name="priority"
                            value="Priority 2"
                          />
                          <span className="priority-2 p-1 px-2 ml-2">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFlag}
                              color="#EA8918"
                            />
                            Priority 2
                          </span>
                        </Label>
                      </Col>
                      <Col>
                        <Label>
                          <Field
                            type="radio"
                            name="priority"
                            value="Priority 3"
                          />
                          <span className="priority-3 p-1 px-2 ml-2">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFlag}
                              color="#F4BE09"
                            />
                            Priority 3
                          </span>
                        </Label>
                      </Col>
                    </Row>
                  </div>
                  <div className="add-note mt-2">
                    <Field
                      id="note"
                      name="note"
                      type="textarea"
                      component={FormikInputField}
                      label="Add Note"
                      placeholder="Type here"
                      autoFocus
                      maxLength={NOTE_MAX_LENGTH}
                    />
                    <span className="charecter-counter">
                      {NOTE_MAX_LENGTH - values.note.length} Charcters
                    </span>
                  </div>
                  <ModalFooter className="row py-0 text-right border-0">
                    <Button
                      outline
                      color="primary"
                      className="font-size-13"
                      onClick={() => toggleFlagModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="font-size-13 ml-2"
                      disabled={loading}
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlaggedModal;
