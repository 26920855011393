import React from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ProjectsTable from 'pages/ManagementPortal/common/ProjectsTable';
import Paginator from 'common/Paginator';
import { ACTIVE_PROJECT_COLUMN } from 'pages/ManagementPortal/constant';
import {
  GET_PROJECTS,
  PROJECT_SEARCH,
} from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import PageLoader from 'common/PageLoader';
import { addActiveProjectPageInfo } from 'pages/ManagementPortal/ManagementPortalActions';
import NoData from 'common/NoData';
import { PROJECT_DETAIL_PAGE_HEADINGS } from 'constants/appConstants';
import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';

const ProjectTableProvider = ({
  filters,
  pageCount,
  projects,
  totalCount,
  searchText,
  setFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { ACTIVE } = PROJECT_DETAIL_PAGE_HEADINGS;

  const {
    location: { pathname },
  } = history;

  const { limit } = filters;

  const defaultSorted = [
    {
      dataField: 'projectName1',
      order: 'desc',
    },
  ];

  const rowEvents = {
    onClick: async (e, row, rowIndex) => {
      const { page, offset } = filters;
      window.scrollTo(0, 0);
      const planName = row?.plan?.toLowerCase() ?? '';
      const path = `/management-portal/projects/${ACTIVE.slug}/project-detail/${row.id}/${planName}`;
      await dispatch(
        toggleAttentionDrawer({
          isOpen: false,
          heading: '',
          drawerName: '',
        })
      );
      await dispatch(
        addActiveProjectPageInfo({
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              searchText,
            },
            page,
            offset,
          },
        })
      );
      history.push({ pathname: path, from: pathname });
    },
  };

  const goToTop = async (filters) => {
    const { page, offset } = filters;
    setFilters(filters);
    await dispatch(
      addActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: '',
            searchText,
          },
          page,
          offset,
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page, limit } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const { limit } = filters;
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page, limit } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  return (
    <>
      <div className="management-scroll">
        <ToolkitProvider
          keyField="id"
          data={projects}
          columns={ACTIVE_PROJECT_COLUMN}
        >
          {(props) => (
            <ProjectsTable
              {...props}
              defaultSorted={defaultSorted}
              rowEvents={rowEvents}
            />
          )}
        </ToolkitProvider>
      </div>
      <div className="mb-4 ">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={projects.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </>
  );
};

const ProjectTableWrapper = ({ filters, searchText, setFilters }) => {
  let queryApi = GET_PROJECTS;
  let queryVariables = filters;

  if (searchText?.trim()) {
    queryApi = PROJECT_SEARCH;
    queryVariables = { ...filters, searchText: searchText?.trim() };
  }

  const { data, loading, error } = useQuery(queryApi, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data) {
    const { getProjects, projectSearch } = data;

    let projects = null;
    let totalCount = 0;
    if (getProjects) {
      projects = getProjects.projects;
      totalCount = getProjects.totalCount;
    }
    if (projectSearch) {
      projects = projectSearch.projects;
      totalCount = projectSearch.totalCount;
    }

    if (!projects.length) {
      return (
        <NoData customMessage="You don't have any active projects at this moment" />
      );
    }
    const pageCount = Math.ceil(totalCount / filters.limit);

    return (
      <ProjectTableProvider
        filters={filters}
        searchText={searchText}
        setFilters={setFilters}
        pageCount={pageCount}
        projects={projects}
        totalCount={totalCount}
      />
    );
  }
  return <NoData customMessage="No projects to be shown." />;
};
export default ProjectTableWrapper;
