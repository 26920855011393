import React from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ProjectsTable from 'pages/ManagementPortal/common/ProjectsTable';
import NoData from 'common/NoData';
import Paginator from 'common/Paginator';
import { ACTIVE_PROJECT_COLUMN } from 'pages/ManagementPortal/constant';
import { GET_ATTENTION_PROJECT } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import PageLoader from 'common/PageLoader';
import { addNeedAttentionPageInfo } from 'pages/ManagementPortal/ManagementPortalActions';
import { PROJECT_DETAIL_PAGE_HEADINGS } from 'constants/appConstants';
import { toggleAttentionDrawer } from 'containers/AppContainer/AppActions';

const ProjectTableProvider = ({ filters, setFilters }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { NEED_MY_ATTENTION } = PROJECT_DETAIL_PAGE_HEADINGS;

  const { limit } = filters;

  const {
    location: { pathname },
  } = history;

  const { data, loading, error } = useQuery(GET_ATTENTION_PROJECT, {
    variables: filters,
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data) {
    const { getAttentionProjects } = data;
    if (!getAttentionProjects) {
      return (
        <NoData customMessage="There are no projects needing your attention at this time." />
      );
    }
    const { projects, totalCount } = getAttentionProjects;
    if (!projects.length) {
      return (
        <NoData customMessage="There are no projects needing your attention at this time." />
      );
    }
    const pageCount = Math.ceil(totalCount / limit);

    const defaultSorted = [
      {
        dataField: 'projectName1',
        order: 'desc',
      },
    ];

    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        const { page, offset } = filters;
        window.scrollTo(0, 0);
        const planName = row?.plan?.toLowerCase() ?? '';
        const path = `/management-portal/projects/${NEED_MY_ATTENTION.slug}/project-detail/${row.id}/${planName}`;
        await dispatch(
          toggleAttentionDrawer({
            isOpen: false,
            heading: '',
            drawerName: '',
          })
        );
        await dispatch(
          addNeedAttentionPageInfo({
            previousPageInfo: {
              previousRoutes: {
                path: pathname,
                param: '',
              },
              page,
              offset,
            },
          })
        );
        history.push({ pathname: path, from: pathname });
      },
    };

    const goToTop = async (filters) => {
      const { page, offset } = filters;
      setFilters(filters);
      await dispatch(
        addNeedAttentionPageInfo({
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page,
            offset,
          },
        })
      );
      window.scrollTo(0, 0);
    };

    const handlePageChange = (page) => {
      const newOffset = (page - 1) * limit;
      goToTop({ ...filters, limit, offset: newOffset, page });
    };

    const handleGoToFirst = () => {
      goToTop({ ...filters, limit, offset: 0, page: 1 });
    };
    const handleGoToNext = () => {
      const { page } = filters;
      const newPage = page + 1;
      const newOffset = page * limit;
      goToTop({ ...filters, limit, offset: newOffset, page: newPage });
    };

    const handleGoToLast = () => {
      const newOffset = (pageCount - 1) * limit;
      goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
    };

    const handleGoToPrevious = () => {
      const { page } = filters;
      const newPage = page - 1;
      const pageNumber = newPage - 1;
      const newOffset = pageNumber * limit;
      goToTop({ ...filters, limit, offset: newOffset, page: newPage });
    };

    return (
      <>
        <div className="management-scroll">
          <ToolkitProvider
            keyField="id"
            data={projects}
            columns={ACTIVE_PROJECT_COLUMN}
            search
          >
            {(props) => (
              <ProjectsTable
                {...props}
                defaultSorted={defaultSorted}
                rowEvents={rowEvents}
              />
            )}
          </ToolkitProvider>
        </div>
        <div className="mb-4 ">
          <Paginator
            pageCount={pageCount}
            totalCount={totalCount}
            offset={filters.offset}
            currentPage={filters.page}
            recordLength={projects.length}
            handleGoToNext={handleGoToNext}
            onPageChange={handlePageChange}
            handleGoToLast={handleGoToLast}
            handleGoToFirst={handleGoToFirst}
            handleGoToPrevious={handleGoToPrevious}
          />
        </div>
      </>
    );
  }
  return <NoData customMessage="There are no projects at this time." />;
};
export default ProjectTableProvider;
