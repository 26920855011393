import React, { useEffect, useState } from 'react';
import NoData from 'common/NoData';
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Input,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { PILOT_ASSET_FOLDERS_LISTING } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import { useQuery } from '@apollo/client';
import { parseToInteger } from 'utils/helper';
import UploadModal from 'pages/SalesPersonPortal/common/UploadModal';
import ProjectAssetProvider from 'components/ProjectAssetProvider';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import FolderImg from 'assets/folder-fill.svg';
import { ROLES } from 'constants/role';
import RBAC from 'hoc/RBAC';

let fileNamesWithCount = {};

const Attachments = ({ projectId }) => {
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [uploadLocation, setUploadLocation] = useState('');
  const [fileNameCount, setFileNameCount] = useState({});

  const toggleUploadModal = (status) => {
    setUploadModal(status);
  };

  const setFilesCount = (assetLocation, count) => {
    setFileNameCount(assetLocation);
    fileNamesWithCount = { ...fileNamesWithCount, [assetLocation]: count };
  };

  const {
    loading,
    error,
    data,
    refetch: refetchSales,
  } = useQuery(PILOT_ASSET_FOLDERS_LISTING, {
    variables: {
      projectId: parseToInteger(projectId),
      folderTypes: 'Sales',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.pilotAssetFoldersListing.length)
      setUploadLocation(data.pilotAssetFoldersListing[0].uploadingPath);
  }, [data]);

  return (
    <>
      <div className="d-flex py-2">
        <h6 className="font-size-14 mt-2 pt-1 pb-2">
          Attachments ({data ? data.pilotAssetFoldersListing.length : 0})
        </h6>
        <RBAC
          allowedRoles={[
            ROLES.VIDEOMS_SALES_PERSON,
            ROLES.VIDEOMS_PM,
            ROLES.VIDEOMS_QA,
            ROLES.ADMIN,
            ROLES.VIDEOMS_QC,
          ]}
        >
          <div className="text-right">
            <Button
              type="button"
              color="primary"
              outline
              size="sm"
              className="font-size-12 px-3 mx-3 mt-2"
              onClick={() => toggleUploadModal(true)}
            >
              Upload file
            </Button>
          </div>
        </RBAC>
      </div>
      {/* <Card className="border-0">
        <CardBody className="px-0 pb-0 attachment-overflow pt-0 pb-2"> */}
          {data &&
            data.pilotAssetFoldersListing.map(
              ({ title, uploadingPath, id }) => {
                return (
                  <Card className="pr-3 mb-2 border-0" key={title}>
                    <AWSFolderAssetsProvider assetLocation={uploadingPath}>
                      <Row>
                        <Col>
                          <h6 className="font-size-13 m-0 p-2 show-elipsis-100 ">
                            <img
                              src={FolderImg}
                              alt="FolderImg"
                              className="mr-2"
                            />
                            {title}
                          </h6>
                        </Col>
                        <Col md={4} className="sales-upload">
                          <ProjectAssetProvider
                            handleSelectAll={() => {}}
                            handleSelectFile={() => {}}
                            isPilotPortal
                            pilotID={id}
                            refetchPilotVideoImages={refetchSales}
                            isMarkedCompleted={false}
                            isSalesPortal
                            setFilesCount={setFilesCount}
                          />
                        </Col>
                      </Row>
                    </AWSFolderAssetsProvider>
                  </Card>
                );
              }
            )}
        {/* </CardBody>
      </Card> */}
      {isUploadModalOpen && (
        <Modal
          isOpen={isUploadModalOpen}
          toggle={() => toggleUploadModal(false)}
          className="modal-dialog-centered"
        >
          <ModalBody className="">
            <h6>Attachment</h6>
            <p>Customer-Provided Docs</p>
            <InputGroupAddon addonType="prepend">
              <Input
                type="select"
                name="select"
                value={uploadLocation}
                onChange={(e) => setUploadLocation(e.target.value)}
              >
                {data &&
                  data.pilotAssetFoldersListing.map(
                    ({ title, uploadingPath }) => {
                      return (
                        <option value={uploadingPath} key={title}>
                          {title}
                        </option>
                      );
                    }
                  )}
              </Input>
            </InputGroupAddon>
            <AWSFolderAssetsProvider assetLocation={uploadLocation}>
              <UploadModal
                upload="Upload"
                imageryId={parseToInteger(projectId)}
                uploadLocation={uploadLocation}
                forSalesPerson
                versionSuffix={fileNamesWithCount}
              />
            </AWSFolderAssetsProvider>
          </ModalBody>
          <ModalFooter className="bg-grey-upload">
            <Button
              outline
              color="primary"
              className="mr-2 btn-sm px-4 font-size-13"
              onClick={() => toggleUploadModal(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Attachments;
