import React, { useEffect, useState } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import Header from 'pages/ManagementPortal/common/Header';
import ErrorBoundary from 'common/ErrorBoundary';
import { addActiveProjectPageInfo } from 'pages/ManagementPortal/ManagementPortalActions';
import ProjectTableProvider from './components/ProjectTableProvider';

const FILTER = {
  trackStatus: 'ACTIVE',
  limit: 10,
  offset: 0,
  page: 1,
};

const ActiveProjects = () => {
  const dispatch = useDispatch();
  const {
    activeProjectInfo: {
      previousPageInfo: {
        page,
        offset,
        previousRoutes: { searchText },
      },
    },
  } = useSelector((state) => state.managementPortal);

  const [filters, setFilters] = useState(FILTER);

  useEffect(() => {
    setFilters({ ...filters, offset, page });
  }, [page, offset]);

  const handleSearchProject = (e) => {
    dispatch(
      addActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: '',
            searchText: e.target.value,
          },
          page: 1,
          offset: 0,
        },
      })
    );
  };

  return (
    <>
      <Header heading="Projects" />
      <div>
        <Row className="align-items-center">
          <Col md="6">
            <h6 className="my-3">Active Projects</h6>
          </Col>
          <Col md="4" className="ml-auto">
            <div className="my-3">
              <Input
                type="search"
                name="searchText"
                id="searchText"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleSearchProject(e)}
              />
            </div>
          </Col>
        </Row>

        <div>
          <ErrorBoundary>
            <ProjectTableProvider
              filters={filters}
              searchText={searchText}
              setFilters={setFilters}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
export default ActiveProjects;
