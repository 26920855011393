import React from 'react';
import * as Yup from 'yup';
import { ROLES } from 'constants/role';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { createNumberMask } from 'text-mask-addons';
import { useMutation, useQuery } from '@apollo/client';
import FormikInputField from 'components/Fields/FormikInputField';
import {
  GET_PROJECT_QUOTES,
  USER_INVITE,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import { defaultMaskOptions, HUBSPOT } from 'pages/PMPortal/constant';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import FormikDatePicker from 'components/Fields/FormikDatePicker';
import PageLoader from 'pages/Search/common/PageLoader';
import { dateTimeFormatter } from 'utils/helper';

const ExternalEditorInviteModal = ({
  inviteExternalEditorModal,
  toggleExternalEditorInviteModal,
  projectId,
  dataSource,
  editorName,
  editorEmail,
  skills,
}) => {
  const history = useHistory();
  const validationSchemaEditorInvite = Yup.object().shape({
    firstName: Yup.string().required('Name is required').trim(),
    projectStartDate: Yup.string()
      .required('Project start date is required')
      .nullable(),
    projectCompleteDate: Yup.string()
      .required('Project complete date is required')
      .nullable(),
    email: Yup.string()
      .trim()
      .email('Invalid email address')
      .required('Email address is required.'),
  });

  let notes = '';

  const [inviteUser] = useMutation(USER_INVITE, {
    onCompleted: async ({ userInvite: { message } }) => {
      toggleExternalEditorInviteModal(false);
      history.replace(`/pm-portal/projects/${projectId}/folders`);
      toast.success(message);
    },
    onError: (e) => {
      toggleExternalEditorInviteModal(false);
      toast.error(e.message);
    },
  });

  const fetchHSQuotes = (projectQuotes) => {
    if (projectQuotes.length) {
      const quotesText = projectQuotes.map((quoteItem) => {
        const {
          quoteHsTitle,
          quoteHsQuoteNumber,
          quoteHsCreatedate,
          quoteHsExpirationDate,
          quoteHsSenderFirstname,
          quoteHsSenderLastname,
        } = quoteItem;

        return {
          quoteTitle: quoteHsTitle,
          referenceNumber: quoteHsQuoteNumber,
          createdDate: dateTimeFormatter(quoteHsCreatedate),
          expirationDate: dateTimeFormatter(quoteHsExpirationDate),
          senderName: quoteHsSenderFirstname + quoteHsSenderLastname,
        };
      });
      return quotesText;
    }

    return {};
  };

  const fetchCREXIQuotes = (projectQuotes) => {
    if (projectQuotes.length) {
      const quotesText = projectQuotes.map((quoteItem) => {
        return {
          productType: quoteItem,
        };
      });
      return quotesText;
    }
    return {};
  };

  const { data, loading, error } = useQuery(GET_PROJECT_QUOTES, {
    variables: {
      projectId,
      dataSource,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;
  if (error) return <span>{error.message}</span>;

  if (data) {
    const { getProjectQuotes } = data;

    if (getProjectQuotes.length) {
      if (dataSource === HUBSPOT) {
        notes = fetchHSQuotes(getProjectQuotes);
      } else {
        notes = fetchCREXIQuotes(getProjectQuotes);
      }
    }

    const renderQuote = () => {
      if (getProjectQuotes.length && dataSource === HUBSPOT) {
        const quoteText = getProjectQuotes.map((item) => {
          const {
            id,
            quoteHsTitle,
            quoteHsQuoteNumber,
            quoteHsCreatedate,
            quoteHsExpirationDate,
            quoteHsSenderFirstname,
            quoteHsSenderLastname,
          } = item;

          return (
            <ul key={id} className="list-inline pb-3">
              <li>
                <b>Quote Title</b> : {quoteHsTitle}
              </li>
              <li>
                <b>Reference Number</b> : {quoteHsQuoteNumber}
              </li>
              <li>
                <b>Created Date</b> :{dateTimeFormatter(quoteHsCreatedate)}
              </li>
              <li>
                <b>Expiration Date</b> :
                {dateTimeFormatter(quoteHsExpirationDate)}
              </li>
              <li>
                <b>Sender Name</b> : {quoteHsSenderFirstname}{' '}
                {quoteHsSenderLastname}
              </li>
            </ul>
          );
        });
        return quoteText;
      }

      if (getProjectQuotes.length && dataSource === 'CREXI') {
        const quoteText = getProjectQuotes.map((item) => {
          return (
            <ul key={item} className="list-inline pb-3">
              <li>
                <b>Product Type</b> : {item}
              </li>
            </ul>
          );
        });
        return quoteText;
      }

      return 'No Quotes Available';
    };

    const saveInviteData = async (values) => {
      const {
        firstName,
        email,
        projectNotes,
        skills,
        projectCompleteDate,
        projectStartDate,
        ...inviteDetails
      } = values;
      try {
        await inviteUser({
          variables: {
            role: ROLES.VIDEOMS_EDITOR,
            firstName: firstName.trim(),
            email: email.trim().toLowerCase(),
            projectNotes: JSON.parse(projectNotes),
            requiredSkills: skills,
            projectStartDate: dateTimeFormatter(projectStartDate, 'MM-DD-YYYY'),
            projectCompleteDate: dateTimeFormatter(
              projectCompleteDate,
              'MM-DD-YYYY'
            ),
            ...inviteDetails,
          },
        });
      } catch (e) {
        toast.error(e.message);
      }
    };

    const renderSkills = () => {
      return skills.map((skill) => {
        return (
          <Badge
            key={skill}
            color="light"
            pill
            className="px-2 ml-1 font-size-12 text-muted border my-1"
          >
            {skill}
          </Badge>
        );
      });
    };

    const ExternalEditorInitialValues = {
      firstName: editorName,
      projectId,
      email: editorEmail,
      projectStartDate: '',
      projectCompleteDate: '',
      projectEditorBudget: '',
      projectNotes: JSON.stringify(notes),
      skills,
    };

    const currencyMask = createNumberMask({
      ...defaultMaskOptions,
    });

    return (
      <Modal
        isOpen={inviteExternalEditorModal}
        toggle={() => toggleExternalEditorInviteModal(false)}
        className="modal-dialog-centered"
      >
        <ModalHeader>Invite Editor to Videoms</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={ExternalEditorInitialValues}
            validationSchema={validationSchemaEditorInvite}
            onSubmit={(values) => {
              saveInviteData(values);
            }}
            children={({ values, handleChange, handleBlur }) => {
              return (
                <Form autoComplete="off">
                  <Field
                    component={FormikInputField}
                    label="Name"
                    required
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter Editor Name"
                    className="form-control form-control-sm f-12"
                    disabled={editorName}
                  />
                  <Field
                    type="email"
                    required
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    className="form-control form-control-sm f-12"
                    component={FormikInputField}
                    label="Email Address"
                    disabled={editorEmail}
                  />
                  <Row className="form-row">
                    <Col md={6}>
                      <div className="date-pick">
                        <Field
                          id="projectStartDate"
                          name="projectStartDate"
                          component={FormikDatePicker}
                          placeholder="Start Date"
                          className="font-size-13 form-control w-100"
                          label="Project Start Date"
                          maxDate={values.projectCompleteDate}
                          minDate={new Date()}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="date-pick">
                        <Field
                          id="projectCompleteDate"
                          name="projectCompleteDate"
                          component={FormikDatePicker}
                          placeholder="Completion Date"
                          className="font-size-13 form-control"
                          label="Project Completion Date"
                          minDate={values.projectStartDate || new Date()}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Label>Project Payment</Label>
                  <Field name="projectEditorBudget">
                    {({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={currencyMask}
                        className="form-control form-control-sm f-12 flex-grow-1 mb-2"
                        id="projectEditorBudget"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </Field>
                  <div className="mt-3 mb-2">
                    <Label className="d-block">Required Skills</Label>
                    {renderSkills()}
                  </div>
                  <Label className="d-block">Project Notes</Label>
                  <Card className="mb-2">
                    <CardBody>
                      <div className="customer-overflow">{renderQuote()}</div>
                    </CardBody>
                  </Card>
                  {/* <Field
                      type="textarea"
                      name="projectNotes"
                      id="projectNotes"
                      className="form-control form-control-sm f-12"
                      component={FormikInputField}
                      label="Project Notes"
                    /> */}
                  <div className="text-right mt-3">
                    <Button
                      outline
                      color="primary"
                      className="mr-2 btn-sm px-4 font-size-13"
                      onClick={() => toggleExternalEditorInviteModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-sm px-4 font-size-13"
                    >
                      Send Invite
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    );
  }

  return null;
};
export default ExternalEditorInviteModal;
