import React from 'react';
import { Col } from 'reactstrap';

import VideoMSLogo from 'common/VideoMSLogo';
import PortalDropdown from 'common/PortalDropdown';
import { Scrollbars } from 'react-custom-scrollbars-2';
import BrokerNavigations from './components/BrokerNavigations';

const Sidebar = () => {
  const brokerDirectory = "BROKER'S DIRECTORY";
  return (
    <div className="sidebar-quad px-3">
      <div className="d-flex justify-content-between align-items-center logo-height">
        <VideoMSLogo />
      </div>
      <div className="portal-name">
        <span>{brokerDirectory}</span>
      </div>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ width: `calc(100% + 30px)` }}
        className="row"
      >
        <Col sm="12">
          <div>
            <BrokerNavigations />
          </div>
          <div style={{ height: '30px' }} />
        </Col>
      </Scrollbars>
    </div>
  );
};
export default Sidebar;
