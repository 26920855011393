import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoPlayer from 'common/VideoPlayer';

const SHOWCASE_VIDEOS = ['Retail', 'Office', 'Multifamily', 'Industrial/Land'];

const videoArray = [
  'arcadia_restaurant_property+(1080p).mp4',
  '1000_broadway_building+(1080p).mp4',
  'domain_town_center+(1080p).mp4',
  'the_yard_by_treeline+(1080p).mp4',
];

const MarqueeVideoDialog = ({
  openVideoDialog,
  toggleVideoDialog,
  setIndex,
  index,
}) => {
  const [getVideo, setVideo] = useState(videoArray[index]);

  useEffect(() => {
    setVideo(videoArray[index]);
  }, [index]);

  const onChangeNext = () => {
    setVideo(videoArray[index + 1]);
    setIndex(index + 1);
  };

  const onChangePrevious = () => {
    setVideo(videoArray[index - 1]);
    setIndex(index - 1);
  };
  return (
    <Modal
      toggle={toggleVideoDialog}
      isOpen={openVideoDialog}
      centered
      size="xl"
    >
      <ModalHeader toggle={toggleVideoDialog}>
        Watch VidTech {SHOWCASE_VIDEOS[index]} Sample
      </ModalHeader>
      <ModalBody>
        <Row className="h-100">
          <Col md={1} className=" d-flex align-items-center">
            <Button
              color="link"
              onClick={onChangePrevious}
              disabled={index === 0}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{ fontSize: '30px' }}
                className="m-3"
              />
            </Button>
          </Col>
          <Col>
            <VideoPlayer
              isModal
              videoSrc={`${process.env.REACT_APP_VIDPITCH_S3_BASE_ENDPOINT}vitpitch-video/${getVideo}`}
              isVidPitchPlayer
            />
          </Col>
          <Col
            md={1}
            className=" d-flex align-items-center justify-content-end"
          >
            <Button
              color="link"
              onClick={onChangeNext}
              disabled={index === videoArray.length - 1}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                style={{ fontSize: '30px' }}
                className="m-3"
              />
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
export default MarqueeVideoDialog;
