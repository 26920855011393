import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { gql, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const ACCESS_PM_ON_USER_BEHALF = gql`
  query accessPMOnUserBehalf($projectId: Int!) {
    accessPMOnUserBehalf(projectId: $projectId)
  }
`;

const ActivityPopup = () => {
  const { projectId } = useParams();
  const { auth } = useContext(authContext);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [getAccessPmOnUserBehalf, { loading, data, error }] = useLazyQuery(
    ACCESS_PM_ON_USER_BEHALF,
    { variables: { projectId: parseInt(projectId, 10) } }
  );

  const { email, password } = data?.accessPMOnUserBehalf?.user || {};
  const { msg } = data?.accessPMOnUserBehalf || {};

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
    if (!popoverOpen) getAccessPmOnUserBehalf();
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      {auth?.data?.role === ROLES.VIDEOMS_PM ? (
        <>
          <div className="text-center">
            <Button
              id="credentials-popup"
              type="button"
              onClick={togglePopover}
              className="p-0 border-0"
              color="link"
            >
              <FontAwesomeIcon
                title="Show Credentials"
                icon={faEye}
                className="mr-3 mt-2"
                size="sm"
                color="#58537e"
              />
            </Button>
          </div>
          <Popover
            placement="left"
            target="credentials-popup"
            isOpen={popoverOpen}
            toggle={togglePopover}
          >
            <PopoverHeader>
              <div className="d-flex justify-content-between">
                <span>Credentials</span>
                <span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="sm"
                    color="#58537e"
                    onClick={togglePopover}
                  />
                </span>
              </div>
            </PopoverHeader>
            <PopoverBody>
              {loading ? (
                'Loading...'
              ) : (
                <>
                  {msg ? (
                    <p className="mb-1 text-danger">{msg}</p>
                  ) : (
                    <>
                      <p className="mb-1">
                        <b>Email:</b> {email}
                      </p>
                      <p className="mb-1">
                        <b>Password:</b> {password}
                      </p>
                    </>
                  )}
                </>
              )}
            </PopoverBody>
          </Popover>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActivityPopup;
