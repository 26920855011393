import { crexiBronzeInitialState } from 'constants/reduxInitialStates/crexiBronzeState';
import * as actions from './CrexiBronzeActionTypes';

export const CrexiBronzeReducer = (state = crexiBronzeInitialState, action) => {
  switch (action.type) {
    case actions.CREXI_BRONZE_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: action.payload.currentChapter,
      };

    case actions.CREXI_BRONZE_CURRENT_PREVIEW_URL:
      return {
        ...state,
        currentPreviewURL: action.payload.currentPreviewURL,
      };

    case actions.CREXI_BRONZE_FORM_DATA:
      return {
        ...state,
        formData: { ...action.payload.formData },
      };

    case actions.CREXI_BRONZE_LOGO_QR_URL:
      return {
        ...state,
        logoAndQrUrl: action.payload.logoAndQrUrl,
      };

    case actions.CREXI_BRONZE_CHAPTER_VISITED_STATUS:
      return {
        ...state,
        isChapterOneVisited: action.payload.isChapterOneVisited,
      };
    case actions.CREXI_BRONZE_VIDEO_TEXT_CHANGE:
      return { ...state, isVideoTextChange: action.payload.isVideoTextChange };
    case actions.VIDEO_RENDER_TIMER:
      return {
        ...state,
        videoRenderState: action.payload.videoRenderState,
      };
    default:
      return state;
  }
};
