import { useSubscription } from '@apollo/client';
import { NOTIFICATION_ACTION_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';

function useTaskActionSubscription() {
  const { data, loading, error } = useSubscription(
    NOTIFICATION_ACTION_SUBSCRIPTION,
    {
      shouldResubscribe: true,
    }
  );
  if (loading) return null;
  if (error) return error;
  if (data) {
    const {
      notificationAction: { data: taskActionTaken },
    } = data;

    return taskActionTaken;
  }
  return null;
}

export default useTaskActionSubscription;
