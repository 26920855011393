import React from 'react';
import CurrencyFormat from 'react-currency-format';

const CurrencyFormatter = ({ price, currency = '$' }) => {
  return (
    <CurrencyFormat
      value={price}
      displayType="text"
      thousandSeparator
      prefix={currency}
    />
  );
};

export default CurrencyFormatter;
