import React, { lazy } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCommentAlt } from '@fortawesome/free-solid-svg-icons';

import ActiveProjects from 'pages/ManagementPortal/components/Projects/components/ActiveProjects';
import Completed from 'pages/ManagementPortal/components/Projects/components/Completed';
import NeedMyAttention from 'pages/ManagementPortal/components/Projects/components/NeedMyAttention';
import activeFill from 'assets/refresh-fill.svg';
import { PLAN_TYPE_ICONS } from 'utils/helper';
import AttentionIcon from 'pages/ManagementPortal/components/Projects/components/ProjectDetail/components/ProjectDetailContent/components/TaskItem/components/AttentionIcon';
import Countdown from 'react-countdown';

const routeProjects = import('pages/ManagementPortal/components/Projects');
const Projects = lazy(() => routeProjects);

export const ProjectDetail = (rowContent, { plan }) => {
  let icon = null;
  let altText = null;
  if (plan !== 'Not Specified') {
    const iconObj = PLAN_TYPE_ICONS[plan];
    icon = iconObj.icon;
    altText = iconObj.altText;
  }
  return (
    <div className="d-flex">
      {icon && altText ? (
        <img className="mr-2" src={icon} alt={altText} />
      ) : (
        <span className="plan-image-placeholder mr-3" />
      )}
      <strong
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px', fontWeight: '500' }}
        title={rowContent}
      >
        {rowContent}
      </strong>
    </div>
  );
};

export const ProjectDetailCheckIcon = (rowContent, { status }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faCheckCircle}
        size="lg"
        className="mr-3"
        color={status === 'COMPLETED' ? '#64BC26' : '#ccc'}
      />
      <span
        className="text-truncate d-inline-block align-middle maxWidth90Per"
        title={rowContent}
      >
        {rowContent}
      </span>
    </>
  );
};

export const ProjectDetailCompleteLabel = (rowContent, { lastStatus }) => {
  const classString = `${lastStatus
    .replace(/[^\w]/g, '-')
    .toLowerCase()}-badge`;

  return (
    <span title={lastStatus} className={`badge text-truncate ${classString}`}>
      {lastStatus}
    </span>
  );
};

export const ProjectDetailChatIcon = (rowContent, { id, alertDetails }) => {
  return (
    <>
      {alertDetails.length ? (
        <AttentionIcon id={id} alertDetails={alertDetails} />
      ) : (
        ''
      )}
    </>
  );
};

export const TruncatedDetail = (rowContent, row) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={rowContent}
      >
        {rowContent}
      </span>
    </>
  );
};

export const TruncatedBrokerName = (rowContent, { broker }) => {
  return (
    <>
      <span
        className="text-truncate d-inline-block align-middle"
        style={{ maxWidth: '120px' }}
        title={rowContent}
      >
        {broker[0].brokerName}
      </span>
    </>
  );
};

export const ProjectStatus = (rowContent, { trackStatus, hasMessage }) => {
  return (
    <>
      <div style={{ width: '120px', display: 'inline-block' }}>
        {trackStatus === 'NOT STARTED' ? (
          <span className="not-started">{trackStatus}</span>
        ) : (
          <span className="active-td">
            <img
              src={activeFill}
              alt="active td"
              className="align-middle mr-1"
            />
            {trackStatus}
          </span>
        )}
      </div>
      {hasMessage && (
        <span className="chat-span">
          <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#ccc" />
          <span>1</span>
        </span>
      )}
    </>
  );
};

export const OrderedBy = (rowContent, row) => (
  <span className="text-capitalized">{rowContent}</span>
);

export const TABS = [
  {
    tabId: 0,
    label: 'Projects',
    link: '/projects',
    type: `projects`,
    path: '/management-portal/projects',
    component: Projects,
    subNavs: [
      {
        tabId: 1,
        label: 'Active Projects',
        link: `/projects/active-projects`,
        type: `active-projects`,
        path: 'management-portal/projects/active-projects',
        component: ActiveProjects,
      },
      {
        tabId: 1,
        label: 'Need My Attention',
        link: `/projects/need-my-attention`,
        type: `need-my-attention`,
        path: 'management-portal/projects/need-my-attention',
        component: NeedMyAttention,
      },
      {
        tabId: 1,
        label: 'Completed',
        link: `/projects/completed`,
        type: `completed`,
        path: 'management-portal/projects/completed',
        component: Completed,
      },
    ],
  },
];

export const getTab = (pathname) => {
  const params = pathname.split('/');
  return params[params.length - 1];
};

export const getCurrentTab = (pathname) => {
  return TABS.filter((TAB) => TAB.type === getTab(pathname))[0];
};

export const PROJECT_DETAIL_COLUMN = [
  {
    dataField: 'task',
    text: 'Task Name',
    formatter: ProjectDetailCheckIcon,
  },
  {
    dataField: 'completedAt',
    text: 'Product complete',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: ProjectDetailCompleteLabel,
  },
  {
    dataField: 'isMessage',
    text: 'Product message',
    formatter: ProjectDetailChatIcon,
  },
];

export const ACTIVE_PROJECT_COLUMN = [
  {
    dataField: 'projectName',
    text: 'Project',
    sort: true,
    formatter: ProjectDetail,
  },
  {
    dataField: 'broker',
    text: 'Broker',
    sort: true,
    formatter: TruncatedBrokerName,
  },
  {
    dataField: 'company',
    text: 'Company',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'eliteAuction',
    text: 'Elite / Auction',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'startDate',
    text: 'Start date',
    sort: true,
    formatter: TruncatedDetail,
  },
  {
    dataField: 'trackStatus',
    text: 'Status',
    sort: true,
    formatter: ProjectStatus,
  },
  {
    dataField: 'orderCreatedBy',
    text: 'Ordered By',
    sort: true,
    formatter: OrderedBy,
  },
];

export const CREXI_STATUS = {
  NOT_STARTED: 'Not Started',
};

export const convertDateWithTimezone = (date) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = moment(moment.utc(date));
  return moment.tz(newDate, tz).format('MM/DD/YYYY HH:mm:ss A');
};

export const PROJECT_LIST = [
  {
    id: 1,
    projectName: 'Mansion Height 99',
  },
  {
    id: 2,
    projectName: 'Treasure Apollo',
  },
  {
    id: 3,
    projectName: '72 Height Hills',
  },
];

export const HUBSPOT = 'HUBSPOT';

export const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const NO_PILOT_INVITED_MESSAGE =
  'No pilot has been invited yet or the pilot has not accepted the invite.';

const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span className="text-danger">Over!!</span>;
  }

  return (
    <span>
      <strong>
        {`${hours < 10 ? 0 : ''}${hours} : ${
          minutes < 10 ? 0 : ''
        }${minutes} : ${seconds < 10 ? 0 : ''}${seconds}`}
      </strong>
    </span>
  );
};

export const renderCountDownTimer = (createdAt) => {
  const currentEpoch = Date.now();
  if (createdAt) {
    const createdUTCDate = new Date(createdAt);
    const dueDateEpoch = createdUTCDate.setTime(
      createdUTCDate.getTime() + 24 * 60 * 60 * 1000 // Add 24 hours i.e 1 day to created time
    );
    if (dueDateEpoch > currentEpoch) {
      return (
        <Countdown
          date={currentEpoch + (dueDateEpoch - currentEpoch)}
          renderer={countDownRenderer}
        />
      );
    }
  }
  return <span className="text-danger">{createdAt ? `Over!!` : ''}</span>;
};
