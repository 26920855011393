import React, { useState } from 'react';
import { Badge, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { UPDATE_REVIEW_ASSET_COMMENT_STATUS } from 'pages/EditorPortal/components/Projects/ProjectsGraphQL';

import { toast } from 'react-toastify';
import NoData from 'common/NoData';
import AnnotationModal from 'common/AnnotationModal';
import { COMMENT_SOURCE } from 'constants/appConstants';
import { every } from 'lodash-es';
import { dateTimeFormatter, parseToInteger } from 'utils/helper';

const Screenshot = ({
  markCheckArray,
  loading,
  error,
  data,
  refetch,
  projectId,
  folderId,
}) => {
  const [assetModalData, setAssetModalData] = useState({
    assetURL: null,
    comments: null,
    assetModal: false,
    annotationCords: null,
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const toggleAssetModal = (commentScreenShot, annotationCords, comments) =>
    setAssetModalData({
      comments,
      annotationCords,
      assetURL: commentScreenShot,
      assetModal: !assetModalData.assetModal,
    });

  const { VIDEO_PLAYER, PORTAL } = COMMENT_SOURCE;
  const [markCheckMutation] = useMutation(UPDATE_REVIEW_ASSET_COMMENT_STATUS, {
    onCompleted: async () => {
      await refetch();
      toast.success('Success');
      setIsDisabled(false);
    },
    onError: (e) => {
      toast.error('error');
      setIsDisabled(false);
    },
  });

  const handleOnChange = async (checkedValue) => {
    setIsDisabled(true);
    await markCheckMutation({
      variables: {
        commentIds: [checkedValue],
        isMarkAll: false,
        projectId,
        folderId: parseToInteger(folderId),
      },
    });
  };

  const renderFrameIOComments = (commentText, commentCreatedAt) => {
    return (
      <div className="comment-card">
        <Row>
          <Col md={6}>
            <p className="font-size-14 font-italic">{commentText}</p>
          </Col>
          <Col md={6}>
            <div className="text-right">
              <h6 className="mb-0 text-muted font-size-14 editor-comment-timestamp">
                {dateTimeFormatter(commentCreatedAt, 'MM/DD/YYYY h:mm:ss a')}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPortalComments = (commentText, commentCreatedAt) => {
    return (
      <div className="edited-comment-card">
        <Row>
          <Col md={6}>
            <p className="font-size-14 font-italic">{commentText}</p>
          </Col>
          <Col md={6}>
            <div className="text-right">
              <h6 className="mb-0 font-size-12 font-weight-normal">
                {dateTimeFormatter(commentCreatedAt, 'MM/DD/YYYY h:mm:ss a')}
              </h6>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  if (data && data.listReviewAssetComments) {
    const { listReviewAssetComments } = data;
    if (!listReviewAssetComments.length) return <NoData />;

    return (
      <>
        <div className="screenshots-row">
          {listReviewAssetComments.map(
            ({
              commentId,
              commentScreenShot,
              commentCompleted,
              commentCreatedAt,
              commentTime,
              comments,
              annotationCords,
            }) => {
              const skipComment = !every(comments, ['commentAccepted', true]);
              if (skipComment) {
                return null;
              }
              markCheckArray.push(commentId);
              return (
                <div key={commentId}>
                  <Row>
                    <Col md="2">
                      <div>
                        <img
                          src={commentScreenShot}
                          alt="SCREENSHOTS"
                          className="border-radius-8px"
                          height="100px"
                          width="135px"
                          onClick={() =>
                            toggleAssetModal(
                              commentScreenShot,
                              annotationCords,
                              comments
                            )
                          }
                          onKeyDown={() =>
                            toggleAssetModal(
                              commentScreenShot,
                              annotationCords,
                              comments
                            )
                          }
                          role="presentation"
                        />
                      </div>
                    </Col>
                    <Col md="10">
                      <div className="row">
                        <div className="col-md-8">
                          {commentCompleted ? (
                            <Badge
                              color="success"
                              className="badge badge-success border-radius-8px font-size-12 font-weight-bold mb-2 px-3 py-1 text-white"
                            >
                              {commentTime}
                            </Badge>
                          ) : (
                            <Badge
                              color="light"
                              className="badge badge-light border-radius-8px font-size-12 font-weight-bold mb-2 px-3 py-1 text-danger"
                            >
                              {commentTime}
                            </Badge>
                          )}

                          {comments &&
                            comments.map(
                              ({
                                id,
                                commentSource,
                                commentText,
                                commentCreatedAt,
                              }) => {
                                if (
                                  comments.length > 1 &&
                                  commentSource === VIDEO_PLAYER
                                ) {
                                  return null;
                                }

                                if (
                                  comments.length > 1 &&
                                  commentSource === PORTAL
                                ) {
                                  return (
                                    <div key={id} className="comment-row">
                                      {renderPortalComments(
                                        commentText,
                                        commentCreatedAt
                                      )}
                                    </div>
                                  );
                                }

                                return (
                                  <div key={id} className="comment-row">
                                    {renderFrameIOComments(
                                      commentText,
                                      commentCreatedAt
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="col-md-4 text-right">
                          <div className="d-flex">
                            <FormGroup
                              check
                              className="mark-complete-checkbox mr-3"
                            >
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id="topping"
                                  name="topping"
                                  value={commentId}
                                  onChange={() => handleOnChange(commentId)}
                                  disabled={isDisabled || commentCompleted}
                                  checked={commentCompleted}
                                />{' '}
                                <span
                                  className={
                                    commentCompleted ? 'line-through' : ''
                                  }
                                >
                                  Mark Complete
                                </span>
                              </Label>
                            </FormGroup>
                            {/* <h6 className="mb-0 text-muted font-size-14">
                            {dateTimeFormatter(
                                commentCreatedAt,
                                'MM/DD/YYYY h:mm:ss a'
                              )}
                            </h6> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                </div>
              );
            }
          )}
        </div>
        {assetModalData.assetModal && (
          <AnnotationModal
            toggleAssetModal={toggleAssetModal}
            {...assetModalData}
          />
        )}
      </>
    );
  }

  return <NoData />;
};
export default Screenshot;
