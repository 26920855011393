export const pmPortalInitialState = {
  activeProjectInfo: {
    previousPageInfo: {
      previousRoutes: {
        path: '',
        searchText: '',
      },
      page: 1,
      offset: 0,
    },
  },
  folderAssetsPageInfo: {
    previousPageInfo: {
      previousRoutes: {
        path: '',
        searchText: '',
      },
    },
    folderAssets: {
      page: 1,
      offset: 0,
    },
  },
  filesToDownload: [],
  editorInviteListing: {
    previousPageInfo: {
      previousRoutes: {
        path: '',
        searchText: '',
      },
      page: 1,
      offset: 0,
    },
  },
  activeTabStatus: 0,
};
