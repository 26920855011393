import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';

import { assetDetailContext } from 'contexts/AssetDetailContext';
import PageLoader from 'pages/Search/common/PageLoader';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import CallToActionForm from './components/CallToActionForm';

const CallToAction = () => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);
  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (getAssetDetails) {
    const callToAction = getAssetDetails.callToAction || {};
    return (
      <Formik
        initialValues={{ callToAction }}
        enableReinitialize
        children={(props) => (
          <>
            <PropertyDetailHeader
              fieldKey="callToAction"
              values={props.values}
              getAssetDetails={getAssetDetails}
              isValid
              refetch={refetchAssetDetail}
            />
            <div className="themed-container property-detail-page">
              <div className="search-result-header ">
                <CallToActionForm
                  {...props}
                  fieldKey="callToAction"
                  getAssetDetails={getAssetDetails}
                  refetch={refetchAssetDetail}
                />
              </div>
            </div>
          </>
        )}
      />
    );
  }
  return null;
};

export default CallToAction;
