import * as actions from './CrexiBronzeActionTypes';

export const crexiBronzeCurrentChapter = (payload) => ({
  type: actions.CREXI_BRONZE_CURRENT_CHAPTER,
  payload,
});

export const crexiBronzeCurrentPreviewURL = (payload) => ({
  type: actions.CREXI_BRONZE_CURRENT_PREVIEW_URL,
  payload,
});

export const crexiBronzeFormData = (payload) => ({
  type: actions.CREXI_BRONZE_FORM_DATA,
  payload,
});

export const crexiBronzeLogoAndQrUrl = (payload) => ({
  type: actions.CREXI_BRONZE_LOGO_QR_URL,
  payload,
});

export const crexiBronzeChapterStatus = (payload) => ({
  type: actions.CREXI_BRONZE_CHAPTER_VISITED_STATUS,
  payload,
});

export const crexiBronzeVideoTextChange = (payload) => ({
  type: actions.CREXI_BRONZE_VIDEO_TEXT_CHANGE,
  payload,
});

export const videoRenderTimer = (payload) => ({
  type: actions.VIDEO_RENDER_TIMER,
  payload,
});

export const crexiBronzeCombinedActions = ({
  currentPreviewURL = null,
  currentChapter = null,
  isChapterOneVisited = undefined,
  logoAndQrUrl = null,
  isVideoTextChange = null,
}) => {
  return (dispatch) => {
    // if (currentPreviewURL) {
    dispatch(crexiBronzeCurrentPreviewURL({ currentPreviewURL }));
    // }

    if (currentChapter) {
      dispatch(crexiBronzeCurrentChapter({ currentChapter }));
    }

    if (isChapterOneVisited !== undefined) {
      dispatch(crexiBronzeChapterStatus({ isChapterOneVisited }));
    }
    if (logoAndQrUrl) {
      dispatch(crexiBronzeLogoAndQrUrl({ logoAndQrUrl }));
    }
    if (isVideoTextChange === false) {
      dispatch(crexiBronzeVideoTextChange({ isVideoTextChange }));
    }
  };
};
