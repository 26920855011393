import React from 'react';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from 'formik';
import { FormGroup, Label, Input } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

const FormikDatePicker = (props) => {
  const {
    id,
    form,
    field,
    label,
    required,
    placeholder,
    inputprops,
    selectedField,
    onChange,
  } = props;
  const { touched, errors, setFieldValue, setFieldTouched } = form;
  let formatedDate = '';
  if (field.value) {
    formatedDate = new Date(field.value);
  }

  const handleChange = (date) => {
    setFieldValue(field.name, date, true);
    if (onChange) {
      onChange(date);
    }
  };
  const onBlur = () => {
    setFieldTouched(field.name, true, true);
  };

  const lableStyle = {};
  if (selectedField) {
    lableStyle.color = 'blue';
    lableStyle.fontWeight = 'bold';
  }

  return (
    <FormGroup className="flex-grow-1">
      <Label for={field.name} style={lableStyle} className="d-block">
        {label}
        {required && <span className="text-danger"> *</span>}
      </Label>
      <DatePicker
        peekNextMonth
        placeholderText={placeholder}
        yearDropdownItemNumber={15}
        id={id}
        invalid={Boolean(touched[field.name] && errors[field.name])}
        selected={formatedDate}
        onChange={handleChange}
        name={field.name}
        dropdownMode="select"
        onClickOutside={onBlur}
        customInput={<Input />}
        {...inputprops}
        {...props}
      />
      <ErrorMessage
        name={field.name}
        render={(msg) => <div className="text-danger font-size-12">{msg}</div>}
      />
    </FormGroup>
  );
};
export default FormikDatePicker;
