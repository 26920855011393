import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Formik } from 'formik';

import CityAndCalloutForm from 'pages/Search/common/CityAndCalloutForm';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import PageLoader from 'pages/Search/common/PageLoader';

const CitiesWithIn200Miles = () => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;

  if (getAssetDetails) {
    const { nearbyCities } = getAssetDetails;

    return (
      <Formik
        initialValues={{
          nearbyCities,
        }}
        enableReinitialize
        children={(props) => (
          <>
            <PropertyDetailHeader
              fieldKey="nearbyCities"
              values={props.values}
              getAssetDetails={getAssetDetails}
              refetch={refetchAssetDetail}
              isValid
            />
            <div className="themed-container property-detail-page">
              <div className="search-result-header ">
                <Card className="shadow border-radius-8px">
                  <CardBody className="px-3">
                    <CityAndCalloutForm
                      {...props}
                      distanceFromAsset={200}
                      fieldKey="nearbyCities"
                      fieldName="nearbyCities"
                      refetch={refetchAssetDetail}
                      getAssetDetails={getAssetDetails}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </>
        )}
      />
    );
  }
  return null;
};
export default CitiesWithIn200Miles;
