import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Loader from 'common/Loader';
import { NO_PILOT_INVITED_MESSAGE } from 'pages/PMPortal/constant';
import Scrollbars from 'react-custom-scrollbars-2';
import NoData from 'common/NoData';
import {
  LIST_NOTES,
  ADDITIONAL_NOTES_ADDED,
  PILOT_EDITOR_INVITED_ACCEPTED,
} from 'pages/Pilot/PilotPortalGraphQL';
import AddNotes from './components/AddNotes';
import ListNotes from './components/ListNotes';

const AdditionalNotes = ({
  projectPilotId,
  projectId,
  isPilotInviteAccepted,
}) => {
  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    LIST_NOTES,
    {
      variables: {
        input: {
          projectId,
          orderBy: 'ASC',
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    const subscribe = subscribeToMore({
      document: ADDITIONAL_NOTES_ADDED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.addedAdditionalNotes.listNotes) {
          const {
            addedAdditionalNotes: { listNotes },
          } = subscriptionData.data;
          if (listNotes?.length > 0) {
            const { pilot_task: pilotTask } = listNotes[0];
            if (pilotTask.projectId === projectId) {
              return {
                listNotes: [...prev.listNotes, ...listNotes],
              };
            }
          }
          return prev;
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_EDITOR_INVITED_ACCEPTED,

      updateQuery: (prev, { subscriptionData }) => {
        console.log('subscriptionData=>', subscriptionData);
        if (subscriptionData?.data) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <Loader />;
  if (error) return <NoData customMessage={error.message} />;

  const { listNotes } = data;

  return (
    <>
      <h6 className="mb-3">Notes</h6>
      {projectPilotId && isPilotInviteAccepted ? (
        <>
          <div>
            <Scrollbars
              autoHide
              className="row"
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{
                width: '100%',
                height: '150px',
                margin: '0px',
              }}
            >
              <ListNotes listNotes={listNotes} />
            </Scrollbars>
          </div>
          <div className="d-flex mt-2">
            <AddNotes
              refetch={refetch}
              projectPilotId={projectPilotId}
              projectId={projectId}
            />
          </div>
        </>
      ) : (
        <h6 className="mb-3">{NO_PILOT_INVITED_MESSAGE}</h6>
      )}
    </>
  );
};

export default AdditionalNotes;
