export const salesInitialState = {
  searchFilters: {
    firstName: '',
    lastName: '',
    companyName: '',
    limit: 10,
    offset: 0,
    page: 1,
  },
  plan: {},
  eliteAuction: '',
  searchedBy: '',
};
