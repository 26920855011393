import React from 'react';
import { getIn } from 'formik';

const FormikErrorMessage = (props) => {
  const { errors, touched, field } = props;
  const error = getIn(errors, field);
  const touch = getIn(touched, field);

  if (touch && error) {
    return (
      <div>
        <span className="errorMessage text-danger">{error}​​​​​</span>
      </div>
    );
  }
  return '';
};

export default FormikErrorMessage;
