import React from 'react';
import { useQuery } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars-2';
import { Button, Col, Row, Spinner } from 'reactstrap';
//
import NoData from 'common/NoData';
import { GET_ASSET_IMAGES } from 'pages/Vidpitch/VidPitchGraphQL';

const AssetImages = ({
  uuid,
  assetCrexiId,
  chapterImageURL,
  setChapterImageURL,
}) => {
  const { loading, error, data } = useQuery(GET_ASSET_IMAGES, {
    variables: {
      input: {
        uuid,
        assetCrexiId,
      },
    },
  });

  if (loading) return <Spinner color="secondary" />;
  if (error) return <div>{error.message}</div>;

  const handleChange = (imageURL) => {
    setChapterImageURL(imageURL);
  };

  if (data?.getAssetImages) {
    const { getAssetImages } = data;
    const assetImageCount = getAssetImages ? getAssetImages.length : 0;
    return (
      <div>
        <div className="mb-2 mt-2">
          <b className="d-flex align-left font-size-16">
            Available Images ({assetImageCount})
          </b>
        </div>
        {assetImageCount ? (
          <div className="pt-2">
            <Scrollbars
              style={{
                width: '100%',
                height: `calc(100vh - 525px)`,
                margin: '0px',
              }}
            >
              <Row className="p-1 m-1">
                {getAssetImages.map((item) => (
                  <Col key={item} md={3} className="p-0 pr-2 pb-2">
                    {/* <FormGroup check inline className="template-checkbox">
                      <Input
                        type="checkbox"
                        onChange={() => handleChange(item)}
                        checked={chapterImageURL === item}
                      />
                    </FormGroup> */}
                    <Button
                      onClick={() => handleChange(item)}
                      color="link"
                      className="p-0"
                    >
                      <img
                        src={item}
                        alt="asset images"
                        className={
                          chapterImageURL === item
                            ? 'template-img-2 v-active-image'
                            : 'template-img-2'
                        }
                      />
                    </Button>
                  </Col>
                ))}
              </Row>
            </Scrollbars>
          </div>
        ) : (
          <span>No asset images available</span>
        )}
      </div>
    );
  }

  return <NoData customMessage="No asset images available" />;
};

export default AssetImages;
