import * as actions from './AppActionTypes';

export const togglePortal = (payload) => ({
  type: actions.TOGGLE_PORTAL,
  payload,
});

export const addRoutePageNum = (payload) => ({
  type: actions.ADD_ROUTE_PAGE_NUM,
  payload,
});

export const toggleAttentionDrawer = (payload) => ({
  type: actions.TOGGLE_ATTENTION_DRAWER,
  payload,
});
