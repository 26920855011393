export const ROLES = {
  ADMIN: 'videoms-admin',
  USER: 'crexi',
  BROKER: 'broker',
  OPERATOR: 'videoms-operator',
  VIDEOMS_QA: 'videoms-test',
  VIDEOMS_EDITOR: 'videoms-editor',
  VIDEOMS_PM: 'videoms-project-manager',
  VIDEOMS_PILOT: 'videoms-pilot',
  VIDEOMS_SALES_PERSON: 'videoms-sales-person',
  VIDPITCH_ADMIN: 'videoms-admin',
  VIDEOMS_QC: 'videoms-quality-controller',
};
