import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { parseToInteger, dateTimeFormatter } from 'utils/helper';
import { Field, Form, Formik } from 'formik';
import { Button, Modal, ModalBody } from 'reactstrap';
import { addMonths, setHours, setMinutes } from 'date-fns';
//
import { PILOT_FLIGHT_RESCHEDULE } from 'pages/Pilot/PilotPortalGraphQL';
import FormikDatePicker from 'components/Fields/FormikDatePicker';

const rescheduleSchema = Yup.object({
  rescheduleDate: Yup.string()
    .required('Reschedule date is required')
    .nullable(),
  rescheduleTime: Yup.string()
    .required('Reschedule time is required')
    .nullable(),
});

const FlightReschedule = ({ projectId, approval }) => {
  const [isRescheduleModalOpen, setRescheduleModal] = useState(false);

  const toggleRescheduleModal = (status) => {
    setRescheduleModal(status);
  };

  const [pilotFlightReschedule] = useMutation(PILOT_FLIGHT_RESCHEDULE, {
    onCompleted: async ({ pilotFlightRescheduled }) => {
      toggleRescheduleModal(false);
      toast.success(pilotFlightRescheduled.message);
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const handleOnChange = async (
    { rescheduleDate, rescheduleTime },
    { resetForm }
  ) => {
    const shootDate = dateTimeFormatter(rescheduleDate, 'YYYY-MM-DD');
    const shootTime = moment.utc(rescheduleTime).format('hh:mm:ss');

    try {
      resetForm({});
      await pilotFlightReschedule({
        variables: {
          projectId: parseToInteger(projectId),
          dueDate: `${shootDate}T${shootTime}Z`,
          isScheduled: false,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <Button
        id="reschedule"
        className="btn-sm btn-primary font-size-13 px-3"
        onClick={() => toggleRescheduleModal(true)}
      >
        Flight Reschedule
      </Button>
      {isRescheduleModalOpen && (
        <Modal
          isOpen={isRescheduleModalOpen}
          toggle={() => toggleRescheduleModal(false)}
          className="modal-dialog-centered"
        >
          <ModalBody className=" flight-modal">
            <Formik
              initialValues={{ rescheduleDate: '', rescheduleTime: '' }}
              validationSchema={rescheduleSchema}
              onSubmit={handleOnChange}
            >
              {() => (
                <Form autoComplete="off" className="w-100">
                  <div>
                    <Field
                      id="rescheduleDate"
                      name="rescheduleDate"
                      component={FormikDatePicker}
                      timeCaption="Date"
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 1)}
                      showDisabledMonthNavigation
                      label="Shoot Date"
                    />
                    <Field
                      id="rescheduleTime"
                      name="rescheduleTime"
                      component={FormikDatePicker}
                      timeCaption="Time"
                      label="Shoot Time"
                      minTime={setHours(setMinutes(new Date(), 0), 9)}
                      maxTime={setHours(setMinutes(new Date(), 30), 16)}
                      timeIntervals={60}
                      dateFormat="h:mm aa"
                      showTimeSelect
                      showTimeSelectOnly
                    />
                  </div>
                  <div className="ml-2 mt-0">
                    <div className="text-right">
                      <Button
                        color="primary"
                        type="submit"
                        className="btn btn-sm font-size-13 px-4"
                      >
                        Submit
                      </Button>
                      <Button
                        outline
                        color="primary"
                        className="ml-2 btn-sm px-4 font-size-13"
                        onClick={() => toggleRescheduleModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default FlightReschedule;
