import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PageLoader from 'common/PageLoader';
import NoData from 'common/NoData';
import RBFP from 'hoc/RBFP';

import { permissionContext } from 'contexts/PermissionContext';
import { GET_AWS_FOLDERS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import { VIEW } from 'constants/permissions';
import FolderGroup from './components/FolderGroup';

const AWSfolders = ({ projectId, projectName, portalSlug }) => {
  const permissionData = useContext(permissionContext);

  const { data, loading, error } = useQuery(GET_AWS_FOLDERS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;

  if (!permissionData?.permissionDetail?.getUserPermission?.permissions) {
    return <NoData customMessage="Required permissions are not available!" />;
  }

  if (data) {
    const { getAwsFolders } = data;

    if (!getAwsFolders.length) return <NoData />;

    return getAwsFolders.map(({ parent, children }) => {
      return (
        <div className="video-review-folder" key={parent}>
          {children &&
            children.map(
              ({ parent: folderParent, name: folderName, ...rest }) => {
                return (
                  <RBFP
                    isParentFolder
                    key={folderParent}
                    permissionType={VIEW}
                    directoryName={folderName}
                  >
                    <FolderGroup
                      parent={folderParent}
                      projectId={projectId}
                      projectName={projectName}
                      directoryName={folderName}
                      portalSlug={portalSlug}
                      {...rest}
                    />
                  </RBFP>
                );
              }
            )}
        </div>
      );
    });
  }
  return null;
};

export default AWSfolders;
