import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap';
import { authContext } from 'contexts/AuthContext';
import { logOut } from 'pages/Login/LoginActions';
import noAccessImg from 'assets/no-access.png';
import { Helmet } from 'react-helmet';

const Error401 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setAuthData } = useContext(authContext);

  const handleClick = async () => {
    localStorage.clear();
    await dispatch(logOut());
    await setAuthData(null);
    history.push('/login');
  };

  return (
    <Container fluid>
      <Helmet  defaultTitle='Error - VidTech' />
      <Row className="no-access-401">
        <Col sm="12" className="d-flex justify-content-center align-items-center">
          <Card className="pt-2 border-0">
            <CardBody className="text-center">
              <img src={noAccessImg} alt="no access" height="180" />
              <h1>No Access</h1>
              <p className="font-size-20">
                You don’t have permission to access this page.
              </p>
              <hr />
              <Button color="link" onClick={handleClick}>
                Go to Login
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Error401;
