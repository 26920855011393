import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Button, Row, Col, PopoverBody, Popover } from 'reactstrap';

import closeIcon from 'assets/close-icon.svg';
import { ATTENTION_POPOVER, BRONZE } from 'constants/appConstants';
import { attentionContext } from 'contexts/AttentionContext';
import { convertDateWithTimezone } from 'pages/ManagementPortal/constant';
import { UPDATE_NOTIFICATION_STATUS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';

const AttentionIcon = () => {
  const { attentionData, setAttentionData, togglePopoverButton } =
    useContext(attentionContext);

  const [updateNotificationStatus, { loading }] = useMutation(
    UPDATE_NOTIFICATION_STATUS,
    {
      onCompleted: async (data) => {
        setAttentionData(ATTENTION_POPOVER);
        toast.success('Action successfully done!');
      },
      onError: (e) => {
        setAttentionData(ATTENTION_POPOVER);
        toast.error(e.message);
      },
    }
  );

  const toggleAttentionPopover = async (e) => {
    e.stopPropagation();
    setAttentionData(ATTENTION_POPOVER);
  };

  const handleActionYes = async ({ id, crexiTaskStatus }) => {
    try {
      await updateNotificationStatus({
        variables: {
          id,
          status: crexiTaskStatus,
          actionValue: true,
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
    togglePopoverButton(true);
  };

  const handleActionDefer = async ({ id, crexiTaskStatus }) => {
    try {
      await updateNotificationStatus({
        variables: {
          id,
          status: crexiTaskStatus,
          actionValue: false,
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
    togglePopoverButton(true);
  };

  const { status, popoverID, alertDetails, planName } = attentionData;
  const isOpen = popoverID && alertDetails;
  const target = !popoverID ? null : popoverID;
  if (status) {
    return (
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target={target}
        trigger="focus"
        className="attention-popover"
      >
        <PopoverBody>
          <Button
            color="link"
            className="close-button d-inline-block"
            onClick={(e) => toggleAttentionPopover(e)}
          >
            <img src={closeIcon} alt="closeIcon" width="13px" />
          </Button>
          {alertDetails.map((row, index) => {
            const {
              attentionText,
              crexiTaskStatus,
              createdAt,
              linkToAsset,
              asanaTaskName,
              projectName,
            } = row;
            const keyName = `${attentionText}-${index + 1}`;
            return (
              <div className="attention-item mt-2" key={keyName}>
                <Row>
                  <Col sm="6">
                    <span
                      title={projectName}
                      className="asset-name d-inline-block text-truncate"
                    >
                      {projectName}
                    </span>
                  </Col>
                  <Col sm="6">
                    <span className="text-right d-block time">
                      {createdAt ? convertDateWithTimezone(createdAt) : ''}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm="12">
                    <span className="action-name">{crexiTaskStatus}</span>
                    <p className="mt-1">{asanaTaskName}</p>
                    <a
                      className="link-in-notification  d-block  text-truncate"
                      title={linkToAsset}
                      href={linkToAsset}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {linkToAsset}
                    </a>
                  </Col>
                  {planName !== BRONZE && (
                    <Col sm="12">
                      <Button
                        className="btn mt-3 mr-2 defer-btn float"
                        type="button"
                        onClick={() => handleActionDefer(row)}
                      >
                        Remind me to review later
                      </Button>
                      <Button
                        className="btn mt-3 yes-btn"
                        type="button"
                        onClick={() => handleActionYes(row)}
                      >
                        Review is complete
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
        </PopoverBody>
      </Popover>
    );
  }
  return null;
};
export default AttentionIcon;
