import React, { useState } from 'react';

export const videoPlayerContext = React.createContext();

const VideoPlayerProvider = ({ children }) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    showAnnotationBoard: false,
    previewVideoTime: 0,
    volume: 0.2,
    videoPreviewImages: [],
    annotationScreenShot: '',
    imageFileWithAnnotation: '',
    commentPosition: [],
    imageBase64: '',
    isVideoLoaded: false,
    videoLoading: true,
    commentLoading: true,
    showAddCommentModal: true,
    userDetails: null,
    formatorType: 'standard',
    aspectRatio: 'fill',
    isBronze: false,
  });

  const setVideoPlayerDetails = (data) => {
    setPlayerState(data);
  };

  return (
    <videoPlayerContext.Provider value={{ playerState, setVideoPlayerDetails }}>
      {children}
    </videoPlayerContext.Provider>
  );
};

export default VideoPlayerProvider;
