import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
//
import { VidPitchContext } from 'contexts/VidPitchContext';
import { RENDER_EDITED_VIDEO_TEMPLATE } from 'pages/Vidpitch/VidPitchGraphQL';

const RenderVideoPromptModal = ({
  videoId,
  updateActiveStep,
  confirmationModal,
  toggleConfirmationModal,
}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const { modifyVideoDetails } = useContext(VidPitchContext);

  const [renderEditedVideoTemplate] = useMutation(
    RENDER_EDITED_VIDEO_TEMPLATE,
    {
      onCompleted: (data) => {
        const {
          renderEditedVideoTemplate: { uuid },
        } = data;
        setLoader(false);
        toggleConfirmationModal(false);
        history.push(`/${uuid}/video-draft`);
        toast.success(
          'Edited data is processing for video rendering. Video will be rendered in 2 - 4 mins.'
        );
      },
      onError: () => {
        setLoader(false);
        toggleConfirmationModal(false);
        toast.error(
          'An error occurred, while processing video rendering. Please try again.'
        );
      },
    }
  );

  const handleFormSubmit = async () => {
    try {
      setLoader(true);
      await renderEditedVideoTemplate({
        variables: {
          videoId,
          templateId: modifyVideoDetails.selectedTemplateId,
          formData: {},
          isEdited: false,
        },
      });
    } catch (err) {
      toast.error(
        'An error occurred, while processing video rendering. Please try again.'
      );
    }
  };

  return (
    <Modal isOpen={confirmationModal} className="modal-dialog-centered">
      <ModalHeader toggle={() => toggleConfirmationModal(false)}>
        Ready to see your new video?
      </ModalHeader>
      <ModalBody>
        Are you ready to render your new video with selected template or want to
        edit text and images ahead.
      </ModalBody>
      <ModalFooter>
        <Button
          className="font-size-13"
          disabled={loader}
          onClick={updateActiveStep}
        >
          Edit Text & Images
        </Button>
        <Button
          type="submit"
          disabled={loader}
          onClick={handleFormSubmit}
          className="font-size-13 ml-2"
        >
          Render My Video!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RenderVideoPromptModal;
