import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row } from 'reactstrap';
//
import NoData from 'common/NoData';
import PageLoader from 'pages/Search/common/PageLoader';
import Header from 'pages/PMPortal/common/Header';
import EditorTableRenderer from './components/EditorTableRenderer';
import {
  EDITOR_LISTING,
  EDITOR_UPDATE_SUBSCRIPTION,
} from '../../ProjectsGraphQL';
import EditorInviteModal from './components/EditorInviteModal';
import ExternalEditorInviteModal from './components/ExternalEditorInviteModal';

const useEditorUpdateSubscription = () => {
  const { data, loading, error } = useSubscription(EDITOR_UPDATE_SUBSCRIPTION);
  if (loading) return null;
  if (error) return error;
  if (data) {
    const { editorUpdate } = data;
    return editorUpdate;
  }
  return null;
};

const GetEditorsInfo = ({
  editorSearchFilters,
  page,
  offset,
  setEditorsCount,
  dataSource,
  projectId,
}) => {
  const limit = 10;

  const editorUpdate = useEditorUpdateSubscription();

  const { loading, data, error, refetch } = useQuery(EDITOR_LISTING, {
    variables: {
      limit,
      offset,
      ...editorSearchFilters,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (editorUpdate?.isEditorUpdate) {
      refetch();
    }
  }, [editorUpdate?.isEditorUpdate]);

  if (loading) return <PageLoader />;
  if (error) return <span>{error.message}</span>;

  if (data) {
    const {
      editorListing: { editors, totalCount },
    } = data;

    if (!editors.length) return <NoData />;

    setEditorsCount(totalCount);
    const pageCount = Math.ceil(totalCount / limit);

    return (
      <EditorTableRenderer
        filters={{ page, offset }}
        limit={limit}
        pageCount={pageCount}
        totalCount={totalCount}
        editorsData={editors}
        dataSource={dataSource}
        projectId={projectId}
        editorSearchFilters={editorSearchFilters}
      />
    );
  }

  return null;
};

const EditorInvite = () => {
  const history = useHistory();

  const {
    location: {
      state: {
        dataSource,
        projectId,
        projectName,
        searchFilters,
        editorFilter,
      },
    },
  } = history;

  const [editorsCount, setEditorsCount] = useState(0);
  const [inviteEditorModal, setInviteEditorModal] = useState(false);
  const [inviteExternalEditorModal, setInviteExternalEditorModal] =
    useState(false);

  useEffect(() => {
    if (history.action === 'POP') {
      history.go(-1);
    }

    return () => {
      history.replace(`/pm-portal/projects/${projectId}/folders`);
    };
  }, []);

  const toggleEditorInviteModal = (status) => {
    setInviteEditorModal(status);
  };

  const toggleExternalEditorInviteModal = (status) => {
    setInviteExternalEditorModal(status);
  };

  const {
    editorInviteListing: {
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.pmPortal);

  const renderSkills = () => {
    const { skills } = searchFilters;
    return skills.join(', ');
  };

  return (
    <div className="broker-top-space">
      <Header heading={projectName} />
      <div>
        <div className="py-2 bg-grey-upload mx-n3 px-3">
          <Row>
            <Col md={6}>
              <Button
                color="link"
                className="back-btn color-inherit px-0 folder-back-btn"
                onClick={() => {
                  history.replace(`/pm-portal/projects/${projectId}/folders`);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
                Invite Editor
              </Button>
            </Col>

            <Col md={6}>
              <div className="text-right">
                <Button
                  color="primary"
                  size="sm"
                  className="px-3 bg-white ml-3 text-primary"
                  onClick={() => toggleEditorInviteModal(true)}
                >
                  Filters
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-2">
          <Row>
            <Col md={8}>
              <h6 className="text-primary mt-3">
                {editorsCount} Results based on filters
              </h6>
              <p className="mt-3">{renderSkills()}</p>
            </Col>
            <Col md={4} className="mt-3 text-right">
              {' '}
              <Button
                color="primary"
                outline
                size="sm"
                className="font-size-14"
                onClick={() => {
                  toggleExternalEditorInviteModal(true);
                }}
              >
                Invite External Editor
              </Button>
            </Col>
          </Row>
        </div>
        <GetEditorsInfo
          editorSearchFilters={searchFilters}
          page={page}
          offset={offset}
          setEditorsCount={setEditorsCount}
          dataSource={dataSource}
          projectId={projectId}
        />
        {inviteEditorModal && (
          <EditorInviteModal
            inviteEditorModal={inviteEditorModal}
            toggleEditorInviteModal={toggleEditorInviteModal}
            projectId={projectId}
            projectName={projectName}
            dataSource={dataSource}
            searchFilter={editorFilter}
          />
        )}
        {inviteExternalEditorModal && (
          <ExternalEditorInviteModal
            inviteExternalEditorModal={inviteExternalEditorModal}
            toggleExternalEditorInviteModal={toggleExternalEditorInviteModal}
            projectId={projectId}
            dataSource={dataSource}
            skills={searchFilters.skills}
          />
        )}
      </div>
    </div>
  );
};

export default EditorInvite;
