import React, { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ReactPicker from 'react-datepicker';
import { Button, FormGroup, Input } from 'reactstrap';
//
import { ROLES } from 'constants/role';
import { dateTimeFormatter } from 'utils/helper';
import { authContext } from 'contexts/AuthContext';
import { assetListInfo } from 'pages/Vidpitch/VidPitchActions';

let dateObj = {};

const VidTechSearch = ({ filters }) => {
  let searchFilterTimeOut;
  const { globalSearchText } = filters;

  const {
    auth: {
      data: { role, token, userId },
    },
  } = useContext(authContext);
  const dispatch = useDispatch();

  const [filterEndDate, setEndDate] = useState(filters.endDate);
  const [filterStartDate, setStartDate] = useState(filters.startDate);
  const [warningMessage, setWarningMessage] = useState('');
  const [searchText, setSearchText] = useState(filters.globalSearchText);

  const handleSearch = useCallback((e) => {
    const query = e.target.value;
    setSearchText(query);
    clearTimeout(searchFilterTimeOut);

    searchFilterTimeOut = setTimeout(() => {
      dispatch(
        assetListInfo({
          assetListInfo: {
            startDate: dateObj.startDate,
            endDate: dateObj.endDate,
            globalSearchText: query.trim(),
            previousPageInfo: { page: 1, offset: 0 },
          },
        })
      );
    }, [200]);
  }, []);

  const handleDateFilter = (date, key) => {
    if (key === 'startDate') setStartDate(date);
    if (key === 'endDate') setEndDate(date);
    dateObj = { ...dateObj, [key]: date };

    if (dateObj.startDate && dateObj.endDate) {
      setWarningMessage('');
      dateObj = { ...dateObj, [key]: date };
      const { startDate, endDate } = dateObj;

      dispatch(
        assetListInfo({
          assetListInfo: {
            endDate,
            startDate,
            globalSearchText: globalSearchText && globalSearchText.trim(),
            previousPageInfo: { page: 1, offset: 0 },
          },
        })
      );
    } else {
      return dateObj.startDate
        ? setWarningMessage('Enter End Date To Complete Search.')
        : setWarningMessage('Enter Start Date To Complete Search.');
    }
    return null;
  };

  const resetFilters = () => {
    setEndDate('');
    setStartDate('');
    setSearchText('');
    setWarningMessage('');
    dateObj = { startDate: '', endDate: '' };
    dispatch(
      assetListInfo({
        assetListInfo: {
          startDate: '',
          endDate: '',
          globalSearchText: '',
          previousPageInfo: { page: 1, offset: 0 },
        },
      })
    );
  };

  const exportFile = () => {
    const { endDate, startDate } = filters;
    const data = {
      role,
      user_id: userId,
      role_check: true,
      access_token: token,
      search: globalSearchText,
      end_date: endDate && dateTimeFormatter(endDate, 'YYYY-MM-DD'),
      start_date: startDate && dateTimeFormatter(startDate, 'YYYY-MM-DD'),
    };

    axios({
      url: `${process.env.REACT_APP_BASE_ENDPOINT}api/export-video-data`,
      method: 'POST',
      data,
      responseType: 'blob',
    })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'vidpitch_records.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        toast.error('Something went wrong while exporting file.');
      });
  };

  return (
    <div className="d-flex justify-content-between align-items-center mx-2">
      <div className="d-flex  w-75">
        <FormGroup className="mt-4 vidtech-input w-25">
          <Input
            type="text"
            name="search"
            value={searchText}
            placeholder="Search"
            onChange={(e) => {
              handleSearch(e);
            }}
            className="vidtech-input"
          />
        </FormGroup>
        <div className="d-flex align-items-center ml-5 mt-2">
          <b>SEARCH BY DATE RANGE</b>
          {warningMessage && (
            <span className="text-danger v-calender-error">{`* ${warningMessage}`}</span>
          )}
          <ReactPicker
            selectsStart
            maxDate={filterEndDate}
            selected={filterStartDate}
            placeholderText="Choose Start Date"
            className="ml-4 mr-1 vidtech-datepicker"
            onChange={(date) => handleDateFilter(date, 'startDate')}
          />
          -
          <ReactPicker
            selectsEnd
            selected={filterEndDate}
            minDate={filterStartDate}
            placeholderText="Choose End Date"
            className="ml-1 vidtech-datepicker"
            onChange={(date) => handleDateFilter(date, 'endDate')}
          />
          <Button className="ml-4 temp-button" size="sm" onClick={resetFilters}>
            Reset Search
          </Button>
        </div>
      </div>

      {role === ROLES.VIDPITCH_ADMIN && (
        <Button
          color="primary"
          onClick={exportFile}
          className="vidtech-input px-4 mt-2"
        >
          Export
        </Button>
      )}
    </div>
  );
};

export default VidTechSearch;
