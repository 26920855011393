import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Header from './common/Header';
//
import DealDetails from './components/DealDetails';
import Deals from './components/Deals';

const SalesPersonPortal = ({ component, ...rest }) => {
  const { path } = rest.match;

  return (
    <>
      <Header salesHeader portalName="Sales person Portal" />
      <Helmet defaultTitle="Sales Person Portal - VidTech" />
      <Switch>
        <Route exact path={`${path}/deals`} component={Deals} />
        <Route
          exact
          path={`${path}/deals/:projectId`}
          component={DealDetails}
        />
      </Switch>
    </>
  );
};
export default SalesPersonPortal;
