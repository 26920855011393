import * as Yup from 'yup';

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const verifyField = (customValue) => {
  if (customValue) {
    return true;
  }
  return false;
};

const ValidationSchema = Yup.object().shape({
  interiorVideoContact: Yup.object().shape(
    {
      name: Yup.string().trim(),
      email: Yup.string().email('Email is not valid').nullable(),
      phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      shootDate: Yup.string()
        .when(['shootTime'], {
          is: (shootTime) => verifyField(shootTime),
          then: Yup.string().trim().required('Date is required, if shoot time is provided').nullable(),
          otherwise: Yup.string().nullable(),
        }),
      shootTime: Yup.string()
        .when(['shootDate'], {
          is: (shootDate) => verifyField(shootDate),
          then: Yup.string().trim().required('Time is required, if shoot date is provided').nullable(),
          otherwise: Yup.string().nullable(),
        }),
    },
    [['shootTime', 'shootDate']]
  ),
});

export default ValidationSchema;
