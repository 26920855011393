import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { blobToDownloadLink } from 'utils/helper';
import Folders from './components/Folders';

const FolderGroup = ({
  parent,
  projectId,
  directoryName,
  projectName,
  ...rest
}) => {
  const downloadZIP = () => {
    const data = {
      folder: `${projectName}/${parent}/`,
    };
    const config = {
      responseType: 'blob',
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-zip-download?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then((res) => {
        blobToDownloadLink(res, `${projectName} - ${parent}.zip`);
      })
      .catch((err) => {
        toast.error('Failed to download the zip');
      });
  };

  const renderZIPButton = () => {
    return (
      <Button
        color="link"
        className="btn-sm px-0 download-zipBtn"
        onClick={() => downloadZIP()}
      >
        <i className="fa fa-download pr-2" />
        Download {parent} ZIP
      </Button>
    );
  };

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col md={6}>
          <h2 className="font-size-16">{parent}</h2>
        </Col>
        <Col md={6}>
          <div className="text-right">{renderZIPButton()}</div>
        </Col>
      </Row>
      <Row>
        <Folders
          parentName={parent}
          directoryName={directoryName}
          projectId={projectId}
          {...rest}
        />
      </Row>
      <hr className="mt-0 dotted-2px" />
    </>
  );
};
export default FolderGroup;
