import React from 'react';
import { NavbarBrand } from 'reactstrap';
import Logo from 'assets/videoms-logo.png';
import { VIDTECH_LINK } from 'constants/appConstants';

const VideoMSLogo = ({ portalName }) => (
  <NavbarBrand
    href={VIDTECH_LINK}
    target="_blank"
    tag="a"
    // className="d-inline-flex flex-column p-0 mt-n2"
  >
    <img src={Logo} alt="Logo" width="80" />
    {portalName && (
      <p className="font-size-12 text-right text-secondary font-weight-normal">
        {portalName}
      </p>
    )}
  </NavbarBrand>
);
export default VideoMSLogo;
