import React from 'react';
import { camelCase, upperCase, upperFirst } from 'lodash';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

import { PLAN_TYPE_ICONS } from 'utils/helper';
import filterIcon from 'assets/icons/filter-icon.svg';

const FilterProjectPlanType = ({ sortByData, handleFilter }) => {
  const renderPlanTypeOptions = () =>
    Object.keys(PLAN_TYPE_ICONS).map((planName) => (
      <option key={planName} value={upperCase(planName)}>
        {upperFirst(camelCase(planName))}
      </option>
    ));

  return (
    <InputGroup style={{ width: '250px' }} className="mr-2">
      <InputGroupText className="p-2 rounded-left">
        <img src={filterIcon} alt="filter icon" width={20} />
      </InputGroupText>
      <Input
        type="select"
        name="select"
        className="w-auto"
        value={sortByData}
        onChange={(e) => handleFilter(e.target.value)}
      >
        <option value="ALL">All</option>
        {renderPlanTypeOptions()}
      </Input>
    </InputGroup>
  );
};

export default FilterProjectPlanType;
