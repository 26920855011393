import React, { useEffect } from 'react';
import { isArray } from 'lodash';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Paginator from 'common/Paginator';
import PageLoader from 'common/PageLoader';
import NoData from 'common/NoData';
import { PLAN_TYPE_ICONS, convertDateWithTimezone } from 'utils/helper';
import {
  GET_ACTIVE_PROJECT_LIST,
  VIDEO_STATUS_UPDATED,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import { pmActiveProjectPageInfo } from 'pages/PMPortal/PMPortalActions';
import { Badge, Card, CardBody } from 'reactstrap';
import moviePlatinum from 'assets/movie.svg';
import { renderCountDownTimer } from 'pages/PMPortal/constant';
import { BRONZE } from 'constants/appConstants';

const ProjectTableProvider = ({
  filters,
  pageCount,
  projects,
  totalCount,
  searchText,
  setFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    location: { pathname },
  } = history;

  const { limit } = filters;
  const handleCardClick = async (id) => {
    const { page, offset } = filters;
    window.scrollTo(0, 0);
    const path = `/pm-portal/projects/project-detail/${id}`;
    await dispatch(
      pmActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: pathname,
            searchText,
          },
          page,
          offset,
        },
      })
    );
    history.push({ pathname: path, from: pathname });
  };

  const goToTop = async (filters) => {
    const { page, offset } = filters;
    setFilters(filters);
    await dispatch(
      pmActiveProjectPageInfo({
        previousPageInfo: {
          previousRoutes: {
            path: '',
            searchText,
          },
          page,
          offset,
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page, limit } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const { limit } = filters;
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page, limit } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, limit, offset: newOffset, page: newPage });
  };

  const renderSubType = (value) => {
    if (
      value.assetSubtype?.length > 1 &&
      value.assetSubtype !== 'Not Specified'
    ) {
      return value.assetSubtype.join(',');
    }
    return value.assetSubtype;
  };

  return (
    <>
      <div className="management-scroll">
        {projects &&
          projects.map((value) => {
            return (
              <Card
                key={value?.id}
                className="mb-2 cursor-pointer border-radius-8px color-inherit cursor-pointer mb-2 mb-3 pb-2 property-card-detail pt-3 px-3 shadow text-decoration-none"
                onClick={() => handleCardClick(value?.id)}
              >
                <CardBody className="d-flex p-0">
                  <div>
                    <img
                      className="mr-2"
                      src={
                        value?.planType
                          ? PLAN_TYPE_ICONS[value.planType].icon
                          : moviePlatinum
                      }
                      alt="planType"
                    />
                  </div>
                  <div>
                    <h5 className="mb-1 font-size-16">{value?.projectName}</h5>
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3">
                        {value?.assetAddress || 'N/A'}
                      </li>
                      <li className="list-inline-item mr-3">
                        <span className="blue-dot mr-3" />
                        Asset Type:
                        {value?.assetType &&
                          isArray(value.assetType) &&
                          value.assetType.map((asset) => {
                            return (
                              <strong key={asset} className="ml-1">
                                {asset}
                              </strong>
                            );
                          })}
                      </li>
                      <li className="list-inline-item">
                        <span className="blue-dot mr-3" />
                        Asset Subtype:
                        <strong> {renderSubType(value)}</strong>
                      </li>
                    </ul>
                  </div>
                </CardBody>
                <hr className="mb-2" />
                <CardBody className="p-0">
                  <ul className="list-inline mb-0 ml-4 pl-2">
                    <li className="list-inline-item mr-3">
                      Video Status:{' '}
                      <Badge className="active-td border border-radius-8px">
                        {value?.videoStatus || 'N/A'}
                      </Badge>
                    </li>
                    <li className="list-inline-item mr-3 ml-md-5">
                      Start Date: <strong>{value?.startDate || 'N/A'}</strong>
                    </li>
                    <li className="list-inline-item mr-3">
                      Delivery Date:{' '}
                      <strong>
                        {value?.deliveryDate
                          ? convertDateWithTimezone(value.deliveryDate)
                          : 'N/A'}
                      </strong>
                    </li>
                    <li className="list-inline-item">
                      Time till due:{' '}
                      {value?.planType === BRONZE
                        ? renderCountDownTimer(value?.createdAt)
                        : 'N/A'}
                    </li>
                  </ul>
                </CardBody>
              </Card>
            );
          })}
      </div>
      <div className="mb-4 ">
        <Paginator
          pageCount={pageCount}
          totalCount={totalCount}
          offset={filters.offset}
          currentPage={filters.page}
          recordLength={projects.length}
          handleGoToNext={handleGoToNext}
          onPageChange={handlePageChange}
          handleGoToLast={handleGoToLast}
          handleGoToFirst={handleGoToFirst}
          handleGoToPrevious={handleGoToPrevious}
        />
      </div>
    </>
  );
};

const ProjectTableWrapper = ({ filters, searchText, setFilters }) => {
  const queryApi = GET_ACTIVE_PROJECT_LIST;
  const queryVariables = filters;

  const { subscribeToMore, data, loading, error } = useQuery(queryApi, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: VIDEO_STATUS_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.videoStatusUpdated?.projects.length) {
          const { projects: prevProjects } = prev.getActiveProjectList;
          const { videoStatusUpdated } = subscriptionData.data;
          const prevProjectCopy = [...prevProjects];
          const indexOfObj = prevProjectCopy.findIndex(
            (item) => item.id === videoStatusUpdated?.projects[0]?.id
          );
          if (indexOfObj !== -1) {
            prevProjectCopy[indexOfObj] = {
              ...prevProjectCopy[indexOfObj],
              videoStatus:
                videoStatusUpdated?.projects[0]?.videoStatus ||
                prevProjectCopy[indexOfObj].videoStatus,
            };
          }
          return {
            getActiveProjectList: {
              ...prev.getActiveProjectList,
              projects: [...prevProjectCopy],
            },
          };
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;

  if (data) {
    const { getActiveProjectList } = data;

    let projects = null;
    let totalCount = 0;
    if (getActiveProjectList) {
      projects = getActiveProjectList.projects;
      totalCount = getActiveProjectList.totalCount;
    }

    if (!projects.length) {
      return (
        <NoData customMessage="You don't have any active projects at this moment" />
      );
    }
    const pageCount = Math.ceil(totalCount / filters.limit);

    return (
      <ProjectTableProvider
        filters={filters}
        searchText={searchText}
        setFilters={setFilters}
        pageCount={pageCount}
        projects={projects}
        totalCount={totalCount}
      />
    );
  }
  return null;
};
export default ProjectTableWrapper;
