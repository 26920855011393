import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { Stage } from 'react-konva';
//
import { dateTimeFormatter } from 'utils/helper';
import { COMMENT_SOURCE, ANNOTATION_TOOLS } from 'constants/appConstants';
import LineShape from './components/LineShape';
import RectShape from './components/RectShape';
import CustomShapes from './components/CustomShapes';
import ArrowShape from './components/ArrowShape';

const AnnotationModal = (props) => {
  const { assetModal, toggleAssetModal, assetURL, comments, annotationCords } =
    props;
  const { VIDEO_PLAYER, PORTAL } = COMMENT_SOURCE;
  const { PEN, LINE, RECT, ARROW } = ANNOTATION_TOOLS;
  const renderFrameIOComments = (commentText) => {
    return (
      <div className="comment-card">
        <Row>
          <Col md={6}>
            <p className="font-size-14 font-italic">{commentText}</p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPortalComments = (commentText, commentCreatedAt) => {
    return (
      <div className="edited-comment-card">
        <Row>
          <Col md={12}>
            <div className="text-right">
              <h6 className="mb-0 text-muted font-size-12 font-weight-normal">
                {dateTimeFormatter(commentCreatedAt, 'MM/DD/YYYY h:mm:ss a')}
              </h6>
            </div>
          </Col>
          <Col md={12}>
            <p className="font-size-14 font-italic">{commentText}</p>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <>
      <Modal
        isOpen={assetModal}
        toggle={toggleAssetModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalBody className=" ">
          <div>
            <div className="position-relative annotation-image-preview">
              <img
                src={assetURL}
                alt="SCREENSHOTS"
                width="640px"
                height="360px"
              />

              {annotationCords &&
                annotationCords.map((value, index) => {
                  return (
                    <div
                      className="annotation-abs"
                      key={`${value.tool}-${index + 1}`}
                    >
                      <Stage width={640} height={360}>
                        {value.tool === RECT && <RectShape {...value} />}
                        {value.tool === LINE && <LineShape {...value} />}
                        {value.tool === PEN && <CustomShapes {...value} />}

                        {/* {value.tool === ARROW && (
                          <ArrowShape {...value} />
                        )} */}
                      </Stage>
                    </div>
                  );
                })}
            </div>

            <div className="mt-3">
              {comments.map(
                ({ id, commentSource, commentText, commentCreatedAt }) => {
                  if (comments.length > 1 && commentSource === VIDEO_PLAYER) {
                    return null;
                  }

                  if (comments.length > 1 && commentSource === PORTAL) {
                    return (
                      <div key={id} className="comment-row">
                        {renderPortalComments(commentText, commentCreatedAt)}
                      </div>
                    );
                  }

                  return (
                    <div key={id} className="comment-row">
                      {renderFrameIOComments(commentText)}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AnnotationModal;
