import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import Header from 'pages/PMPortal/common/Header';
import { Row, Col, Button } from 'reactstrap';
import PageLoader from 'common/PageLoader';
import ProjectAssetProvider from 'components/ProjectAssetProvider';
import { PORTALS } from 'constants/appNavigations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import AssetDownload from 'common/AssetDownload';
import { without } from 'lodash';

import NoData from 'common/NoData';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import { pmFilesToDownload } from 'pages/PMPortal/PMPortalActions';
import { filesAndFoldersTogether } from 'utils/helper';
import UploadModal from './components/UploadModal';
import { GET_PROJECT_DETAILS } from '../../ProjectsGraphQL';
import InviteModal from '../VideoAssetsFolders/components/InviteModal';
import EditorInviteModal from '../EditorInvite/components/EditorInviteModal';
import ProjectDetailHeader from '../VideoAssetsFolders/components/ProjectDetailHeader';

const ProjectAssets = ({ hasFilesInFolderListing = false }) => {
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [inviteEditorModal, setInviteEditorModal] = useState(false);

  const toggleEditorInviteModal = (status) => {
    setInviteEditorModal(status);
  };
  const toggleSendEmailModal = (status) => {
    setSendEmailModal(status);
  };

  const dispatch = useDispatch();
  const { filesToDownload } = useSelector((state) => state.pmPortal);

  const handleSelectFile = (key, status) => {
    if (status) {
      if (!filesToDownload.includes(key)) {
        dispatch(
          pmFilesToDownload({ filesToDownload: [...filesToDownload, key] })
        );
      }
    } else {
      dispatch(
        pmFilesToDownload({ filesToDownload: without(filesToDownload, key) })
      );
    }
  };

  const handleSelectAll = (status, allAssets) => {
    if (status) {
      dispatch(pmFilesToDownload({ filesToDownload: [...allAssets] }));
    } else {
      dispatch(pmFilesToDownload({ filesToDownload: [] }));
    }
  };

  let { projectId } = useParams();
  const { parentName, parent, child } = useParams();
  projectId = parseInt(projectId, 10);
  const history = useHistory();

  const goBackToList = () => {
    if (child) {
      history.push({
        pathname: `/pm-portal/projects/${projectId}/folders/${parentName}/${parent}/subFolders`,
        state: {
          directoryName: parentName,
          subDirectoryName: parent,
          hasFilesInFolderListing: filesAndFoldersTogether(parent),
        },
      });
    } else {
      history.push({
        pathname: `/pm-portal/projects/${projectId}/folders`,
        state: {
          directoryName: parentName,
          subDirectoryName: parent,
        },
      });
    }
  };

  const {
    PM_PORTAL: { slug },
  } = PORTALS;

  const { data, loading, error, refetch } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });
  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (data?.getProjectDetails) {
    const {
      getProjectDetails: {
        projectName,
        startDate,
        slackLink,
        timeElapsed,
        projectEditor,
        projectManager,
        projectPilot,
        projectAsanaLink,
        isPilotInviteAccepted,
        isEditorInviteAccepted,
        dataSource,
        isCompleted,
        projectReviews,
        planType,
        status,
      },
    } = data;

    let uploadLocation;
    if (child) {
      uploadLocation = `${projectName}/${parentName}/${parent}/${child}`;
    } else {
      uploadLocation = `${projectName}/${parentName}/${parent}`;
    }

    const renderParentFolderName = () => {
      if (child) {
        return child;
      }
      return parent;
    };

    return (
      <>
        <AWSFolderAssetsProvider
          assetLocation={uploadLocation}
          planType={planType}
        >
          <Header heading={projectName} />
          <div className="screenshots">
            {!hasFilesInFolderListing && (
              <div className="screenshots-head mb-2">
                <div className="video-review-head">
                  <ProjectDetailHeader
                    projectId={projectId}
                    projectName={projectName}
                    projectAsanaLink={projectAsanaLink}
                    slackLink={slackLink}
                    startDate={startDate}
                    timeElapsed={timeElapsed}
                    projectEditor={projectEditor}
                    projectPilot={projectPilot}
                    projectManager={projectManager}
                    isPilotInviteAccepted={isPilotInviteAccepted}
                    isEditorInviteAccepted={isEditorInviteAccepted}
                    dataSource={dataSource}
                    isCompleted={isCompleted}
                    projectReviews={projectReviews}
                    refetchProjectDetails={refetch}
                    planType={planType}
                    status={status}
                  />
                </div>
              </div>
            )}

            <Row>
              {!hasFilesInFolderListing && (
                <Col md={6}>
                  <Button
                    color="link"
                    className="back-btn color-inherit px-0 folder-back-btn"
                    onClick={goBackToList}
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="back-icon"
                    />
                    {renderParentFolderName()}
                  </Button>
                </Col>
              )}
              <Col
                md={hasFilesInFolderListing ? 12 : 6}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="text-right">
                  <AssetDownload reducerName="pmPortal" />
                </div>
              </Col>
            </Row>
            <ProjectAssetProvider
              portalSlug={slug}
              assetLocation={uploadLocation}
              handleSelectAll={handleSelectAll}
              handleSelectFile={handleSelectFile}
              hasFilesInFolderListing={hasFilesInFolderListing}
              parent={parent}
            />
          </div>
        </AWSFolderAssetsProvider>
        {sendEmailModal && (
          <InviteModal
            sendEmailModal={sendEmailModal}
            toggleSendEmailModal={toggleSendEmailModal}
            projectId={projectId}
          />
        )}
        {inviteEditorModal && (
          <EditorInviteModal
            inviteEditorModal={inviteEditorModal}
            toggleEditorInviteModal={toggleEditorInviteModal}
            projectId={projectId}
            projectName={projectName}
            dataSource={dataSource}
            searchFilter={null}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Header />
      <NoData customMessage="Requested project does not exist." />
    </>
  );
};
export default ProjectAssets;
