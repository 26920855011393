import React, { useState } from 'react';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadAvatar from 'pages/Pilot/common/UploadAvatar';

const Avatar = ({ id, values, uploadLocation, setFieldValue }) => {
  const [image, setImage] = useState({
    preview: values.photo || '',
  });

  const setAvatar = (avatarImgSignedURL) => {
    setImage({
      preview: avatarImgSignedURL,
    });
    setFieldValue('photo', avatarImgSignedURL);
  };

  return (
    <div>
      <label htmlFor={id} className="cursor-pointer">
        {image.preview ? (
          <img
            src={image.preview}
            alt={uploadLocation}
            width="65"
            height="65"
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <FontAwesomeIcon
            size="5x"
            className="mr-2"
            icon={faUserCircle}
            color="#3AB42B"
          />
        )}
      </label>
      <br />

      <UploadAvatar
        upload="Upload"
        uploadLocation={uploadLocation}
        setAvatar={setAvatar}
        forPilotPortal
        forPilotRegistration
      />
      <br />
    </div>
  );
};

export default Avatar;
