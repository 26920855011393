import * as actions from './PMPortalActionTypes';

export const pmActiveProjectPageInfo = (payload) => ({
  type: actions.PM_ACTIVE_PROJECT_PAGE_INFO,
  payload,
});

export const pmFolderAssetsPageInfo = (payload) => ({
  type: actions.PM_FOLDER_ASSETS_PAGE_INFO,
  payload,
});
export const resetPmPortalReducer = (payload) => ({
  type: actions.RESET_PM_PORTAL_REDUCER,
  payload,
});
export const pmFilesToDownload = (payload) => ({
  type: actions.PM_FILES_TO_DOWNLOAD,
  payload,
});

export const pmEditorInvite = (payload) => ({
  type: actions.PM_EDITOR_INVITE,
  payload,
});
export const activeTabPMPortalReducer = (payload) => ({
  type: actions.ACTIVE_TAB_STATUS,
  payload,
});
