import React from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
//
import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import { convertDateWithTimezone } from 'utils/helper';
import PageLoader from 'common/PageLoader';
import { GET_FLIGHT_DETAILS } from 'pages/Pilot/PilotPortalGraphQL';
import NoData from 'common/NoData';
import FlightReschedule from './FlightReschedule';

const FlightDetails = ({ projectId, isProjectAccepted }) => {
  const { loading, error, data } = useQuery(GET_FLIGHT_DETAILS, {
    variables: {
      projectId: parseInt(projectId, 10),
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  const renderDate = (approval, timeStamp) => {
    if (approval) {
      return convertDateWithTimezone(timeStamp);
    }
    return timeStamp || 'Flight Date Not Specified';
  };

  const renderApprovalStatus = (approval) => {
    if (approval) {
      return <span className="text-success">Yes</span>;
    }
    return <span className="text-danger">No</span>;
  };

  if (data && data.getFlightDetails) {
    const {
      getFlightDetails: {
        flightDetails: { b4uLink, approval, timeStamp },
      },
    } = data;
    return (
      <Card className="mt-4 border-radius-8px border-0 shadow overflow-hidden">
        <CardBody>
          <CardTitle tag="h5" className="font-size-16 ">
            FLIGHT DATE
          </CardTitle>
          <CardText>
            <span className="d-flex mb-3">
              B4UFLY :
              {b4uLink ? (
                <a
                  href={b4uLink}
                  target="_blank"
                  rel="noreferrer"
                  className="w-50 show-elipsis-100 ml-2"
                >
                  {b4uLink}
                </a>
              ) : (
                <span className="text-danger ml-2">
                  B4UFLY link not available
                </span>
              )}
            </span>
            <span>{renderDate(approval, timeStamp)}</span>
            <span className="mt-3 d-block">
              AirMap/flight approval : {renderApprovalStatus(approval)}
            </span>
          </CardText>
          {isProjectAccepted && (
            <RBAC allowedRoles={[ROLES.VIDEOMS_PILOT]}>
              <FlightReschedule projectId={projectId} approval={approval} />
            </RBAC>
          )}
        </CardBody>
      </Card>
    );
  }

  return <NoData />;
};

export default FlightDetails;
