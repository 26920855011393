import React, { useState } from 'react';
import { Nav } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { PORTALS } from 'constants/appNavigations';
import ProjectReviewNav from 'components/ProjectReviewNav';
import ProjectAssetsNav from './components/ProjectAssetsNav';

const PMNavigation = () => {
  const {
    PM_PORTAL: { slug },
  } = PORTALS;

  const [isActive, setActive] = useState(0);

  const onClickActive = (active) => {
    setActive(active);
  };

  return (
    <>
      <div className="frame-io-tabs mt-4 portal-sidebar-list">
        <Nav
          vertical
          className="tab-ul row home-navlink"
          onClick={() => onClickActive(0)}
        >
          <NavLink
            className={`nav-link ${isActive === 0 ? 'active-state' : ''}`}
            to="/pm-portal/projects/project-list"
            style={{
              background: '#e8e8e8',
              borderTop: '1px solid #dedede',
              paddingLeft: '30px',
            }}
          >
            Home
          </NavLink>
        </Nav>
      </div>
      <ProjectAssetsNav onClickActive={onClickActive} isActive={isActive} />
      <ProjectReviewNav
        slug={slug}
        onClickActive={onClickActive}
        isActive={isActive}
      />
    </>
  );
};

export default PMNavigation;
