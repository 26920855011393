import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spinner } from 'reactstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { toast } from 'react-toastify';
//
import PageLoader from 'common/PageLoader';
import { dateTimeFormatter } from 'utils/helper';
import { HS_OUT_OF_SYNC } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import { HSoutOfSyncFilterInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import NoData from 'common/NoData';
import BrokerTableRenderer from './components/BrokerTableRenderer';

const HSOutOfSyncData = ({ filters, limit, offset, date }) => {
  const { loading, data, error, refetch } = useQuery(HS_OUT_OF_SYNC, {
    variables: {
      input: {
        limit,
        offset,
        filter: {
          startDate: date.start,
          endDate: date.end,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (error) return <span>{error.message}</span>;

  if (data && data.getHsOutOfSyncData) {
    const {
      getHsOutOfSyncData: { data: brokersData, totalCount },
    } = data;

    if (!brokersData.length) return <NoData />;

    const pageCount = Math.ceil(totalCount / limit);

    return (
      <BrokerTableRenderer
        refetch={refetch}
        filters={filters}
        pageCount={pageCount}
        totalCount={totalCount}
        brokersData={brokersData}
      />
    );
  }
  return <NoData />;
};

const HSOutOfSyncDataWrapper = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState({ start: null, end: null });
  const [load, setLoad] = useState(false);

  const dispatch = useDispatch();
  const {
    HSoutOfSyncData: {
      filters,
      previousPageInfo: { page, offset },
    },
  } = useSelector((state) => state.brokersDirectory);

  const handleDate = async () => {
    setDate({
      start: dateTimeFormatter(startDate),
      end: dateTimeFormatter(endDate),
    });

    await dispatch(
      HSoutOfSyncFilterInfo({
        HSoutOfSyncData: {
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const downloadData = () => {
    setLoad(true);
    if ((date.start && date.end) == null) {
      axios({
        url: `${process.env.REACT_APP_BASE_ENDPOINT}api/export-file?access_code=agWrdk457fKBl8RV9rtTeLuCzRfa3j4w`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `HS_Non_Sync_Data.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setLoad(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .then(() => {
          setLoad(false);
        });
    } else {
      axios({
        url: `${process.env.REACT_APP_BASE_ENDPOINT}api/export-file?access_code=agWrdk457fKBl8RV9rtTeLuCzRfa3j4w&start_date=${date.start}&end_date=${date.end}`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `HS_Non_Sync_Data(${dateTimeFormatter(
              date.start
            )}_${dateTimeFormatter(date.end)}).xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          setLoad(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .then(() => {
          setLoad(false);
        });
    }
  };

  const clearDate = async () => {
    setDate({
      start: null,
      end: null,
    });
    setStartDate(null);
    setEndDate(null);
    await dispatch(
      HSoutOfSyncFilterInfo({
        HSoutOfSyncData: {
          previousPageInfo: {
            page: 1,
            offset: 0,
          },
        },
      })
    );
  };

  const limit = 10;

  return (
    <>
      <div className="broker-top-space">
        <div className="mt-3 date-filter-bd text-right">
          <span>Filter by date : </span>

          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/dd/yyyy"
            className="form-control form-control-sm"
          />
          <span className="d-inline-block mx-1"> - </span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="MM/dd/yyyy"
            className="form-control form-control-sm"
          />
          <Button
            type="button"
            color="primary"
            className="px-3 font-size-14 font-weight-500 m-1 py-1"
            onClick={handleDate}
            disabled={(startDate && endDate) == null}
          >
            Filter
          </Button>
          <Button
            type="button"
            outline
            className="px-3 font-size-14 font-weight-500 m-1 py-1"
            color="primary"
            onClick={clearDate}
          >
            Reset Filter
          </Button>

          {load ? (
            <span className="spinner-button">
              <Spinner size="sm" color="primary" />
            </span>
          ) : (
            <Button
              type="button"
              outline
              className="px-3 font-size-14 font-weight-500 m-1 py-1"
              color="primary"
              onClick={downloadData}
              disabled={load}
            >
              Download
            </Button>
          )}
        </div>
        <HSOutOfSyncData
          filters={{
            ...filters,
            page,
            offset,
            limit,
          }}
          offset={offset}
          limit={limit}
          date={date}
        />
      </div>
    </>
  );
};

export default HSOutOfSyncDataWrapper;
