import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Button, Row, Col } from 'reactstrap';

import { UPDATE_NOTIFICATION_STATUS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import { convertDateWithTimezone } from 'pages/ManagementPortal/constant';

const NotificationItem = ({
  id,
  createdAt,
  projectName,
  linkToAsset,
  asanaTaskName,
  crexiTaskStatus,
  refetch,
  filters,
  setItems,
}) => {
  const [updateNotificationStatus, { loading }] = useMutation(
    UPDATE_NOTIFICATION_STATUS,
    {
      onCompleted: async (data) => {
        setItems([]);
        await refetch({
          limit: filters.offset + filters.limit,
          offset: 0,
        });
        toast.success('Action successfully done!');
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );

  const handleActionYes = async () => {
    try {
      await updateNotificationStatus({
        variables: {
          id,
          status: crexiTaskStatus,
          actionValue: true,
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleActionDefer = async () => {
    try {
      await updateNotificationStatus({
        variables: {
          id,
          status: crexiTaskStatus,
          actionValue: false,
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div className="notification-item ml-3 py-3 px-3">
      <Row>
        <Col sm="6">
          <span
            title={projectName}
            className="asset-name text-truncate d-inline-block"
          >
            {projectName || 'NA'}
          </span>
        </Col>
        <Col sm="6">
          <span className="text-right d-block time">
            {createdAt ? convertDateWithTimezone(createdAt) : ''}
          </span>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <span className="action-name">{crexiTaskStatus || 'NA'}</span>
          <p className="mt-1 mb-0 text-truncate x-block">
            {asanaTaskName || 'NA'}
          </p>

          <a
            className="link-in-notification  d-block  text-truncate"
            title={linkToAsset}
            href={linkToAsset}
            target="_blank"
            rel="noreferrer"
          >
            {linkToAsset || ''}
          </a>
        </Col>
        <Col sm="12" className="text-right">
          <Button
            className="btn mr-2 defer-btn"
            type="button"
            onClick={() => handleActionDefer()}
            disabled={loading}
          >
            Remind me to review later
          </Button>
          <Button
            className="btn yes-btn"
            type="button"
            onClick={() => handleActionYes()}
            disabled={loading}
          >
            Review is complete
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default NotificationItem;
