import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Files from './components/Files';
import Folders from './components/Folders';

const ProjectFolderModal = ({
  isProjectFolderModalOpen,
  toggleProjectFolderModal,
  projectId,
  toggle,
  setToggle,
}) => {
  const [uploadPath, setUploadPath] = useState(false);
  const [folderName, setFolderName] = useState(false);

  return (
    <Modal
      isOpen={isProjectFolderModalOpen}
      toggle={() => toggleProjectFolderModal(false)}
      className="project-Folders-spp modal-xl mr-0 mt-0 mb-0 project-details-spp"
      backdrop="static"
    >
      <ModalBody className="px-4">
        {toggle ? (
          <Folders
            toggleProjectFolderModal={toggleProjectFolderModal}
            projectId={projectId}
            setToggle={setToggle}
            setUploadPath={setUploadPath}
            setFolderName={setFolderName}
          />
        ) : (
          <Files
            setToggle={setToggle}
            uploadPath={uploadPath}
            toggleProjectFolderModal={toggleProjectFolderModal}
            folderName={folderName}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProjectFolderModal;
