import React, { useState } from 'react';

export const VidPitchContext = React.createContext();

const VidPitchProvider = ({ children }) => {
  const [modifyVideoDetails, setModifyVideoDetails] = useState({
    selectedTemplateId: null,
    totalChapter: 0,
    currentChapter: 1,
    previewURL: '',
  });

  const updateModifyVideoDetail = (value) => {
    setModifyVideoDetails(value);
  };

  return (
    <VidPitchContext.Provider
      value={{ modifyVideoDetails, updateModifyVideoDetail }}
    >
      {children}
    </VidPitchContext.Provider>
  );
};

export default VidPitchProvider;
