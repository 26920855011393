import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { CountryDropdown } from 'react-country-region-selector';
//
import { VIDPITCH_VIDEO_PRICE } from 'pages/Vidpitch/constant';
import FormikInputField from 'components/Fields/FormikInputField';
import PaymentSuccess from '../PaymentSuccess';

const videoCheckOutSchema = Yup.object({
  billingAddress: Yup.object({
    name: Yup.string().trim().required('Name is required'),
    address: Yup.object({
      line1: Yup.string().trim().required('street address is required'),
      city: Yup.string().trim().required('city is required'),
      postal_code: Yup.string()
        .trim()
        .matches(/^[0-9]{5}$/, 'Must be 5 digits')
        .required('zip is required'),
    }),
  }),
});

const VideoCheckOut = ({ uuid, email }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentForm, setPaymentForm] = useState(false);
  const [isCompleteMarked, setCompleteMarked] = useState(false);
  const [showAfterPaymentMessage, setAfterPaymentMessage] = useState(false);

  const initialState = {
    email,
    billingAddress: {
      name: '',
      address: {
        line1: '',
        country: '',
        city: '',
        postal_code: '',
      },
    },
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      setPaymentForm(true);
      setAfterPaymentMessage(false);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setCompleteMarked(true);
          setMessage('Order Successfully Placed!!');
          break;
        case 'processing':
          setCompleteMarked(true);
          setMessage(
            'Your payment is processing. Once confirmed will send you receipt on mail.'
          );
          break;
        case 'requires_payment_method':
          setCompleteMarked(false);
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setCompleteMarked(false);
          setMessage('Something went wrong. Please try again.');
          break;
      }
    });

    setPaymentForm(false);
    setAfterPaymentMessage(true);
  }, [stripe]);

  const checkoutVideoDraft = async (values) => {
    const { billingAddress } = values;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);
    setMessage(
      "Please don't refresh or close the page, your transaction is under processing."
    );

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${uuid}/video-draft-purchase`,
        payment_method_data: {
          billing_details: billingAddress,
        },
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const handleCountryChange = (e, setFieldValue, addressType) => {
    setFieldValue(`${addressType}.address.country`, e);
  };

  return (
    <>
      {showPaymentForm && (
        <div>
          <h6>Information</h6>
          <Formik
            initialValues={initialState}
            onSubmit={checkoutVideoDraft}
            validationSchema={videoCheckOutSchema}
            children={({ setFieldValue, values }) => {
              return (
                <Form>
                  <FormGroup>
                    <Field
                      disabled
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Email"
                      value={values.email}
                      component={FormikInputField}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Billing Address</Label>
                    <Field
                      id="billingAddress.name"
                      name="billingAddress.name"
                      component={FormikInputField}
                      placeholder="Name"
                    />
                    <Field
                      id="billingAddress.address.line1"
                      name="billingAddress.address.line1"
                      component={FormikInputField}
                      placeholder="Address"
                    />
                    <Row>
                      <Col className="pr-0">
                        <CountryDropdown
                          name="billingAddress.address.country"
                          value={values.billingAddress.address.country}
                          onChange={(e) => {
                            handleCountryChange(
                              e,
                              setFieldValue,
                              'billingAddress'
                            );
                          }}
                          classes="form-control"
                          defaultOptionLabel="Country"
                        />
                      </Col>
                      <Col className="px-0">
                        <Field
                          id="billingAddress.address.city"
                          name="billingAddress.address.city"
                          component={FormikInputField}
                          placeholder="City"
                        />
                      </Col>
                      <Col className="pl-0">
                        <Field
                          id="billingAddress.address.postal_code"
                          name="billingAddress.address.postal_code"
                          component={FormikInputField}
                          placeholder="ZIP"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <div>
                    <h6>Payment Details</h6>
                    <label htmlFor="card">Card Information</label>
                    <PaymentElement id="card" />
                  </div>
                  <div className="pt-2">
                    {/* Show any error or success messages */}
                    {message && (
                      <div className="text-danger" id="payment-message">
                        {message}
                      </div>
                    )}
                  </div>
                  <div className="pt-2">
                    <Button
                      id="submit"
                      type="submit"
                      color="success"
                      className="btn-block mb-5"
                      disabled={isLoading || !stripe || !elements}
                    >
                      <span id="button-text">
                        {isLoading ? (
                          <div className="spinner" id="spinner">
                            <Spinner />
                          </div>
                        ) : (
                          `Pay ${VIDPITCH_VIDEO_PRICE}`
                        )}
                      </span>
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      )}
      {showAfterPaymentMessage && (
        <PaymentSuccess
          uuid={uuid}
          email={email}
          message={message}
          isCompleteMarked={isCompleteMarked}
        />
      )}
    </>
  );
};

export default VideoCheckOut;
