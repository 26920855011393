import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import NoData from 'common/NoData';
import Header from './components/Header';

const VidpitchVideoDownload = () => {
  const { uuid } = useParams();
  const [getVideoFile, setVideoFile] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const downloadVideo = () => {
    setDisableButton(true);
    fetch(getVideoFile, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/mp4',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Vidpitch-video.mp4`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDisableButton(false);
      });
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}api/download-video?link_id=${uuid}`,
      {
        method: 'GET',
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const { s3Link } = data;
        setVideoFile(s3Link);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  return (
    <div>
      <Helmet defaultTitle="VidTech" />
      <Header />
      <div className="bg-grey-upload vit-video-download">
        <Container fluid className="pt-4">
          {getVideoFile ? (
            <div>
              <video controls className="shadow">
                <source src={getVideoFile} type="video/mp4" />
                <track kind="captions" />
              </video>
              <div className="d-flex justify-content-end">
                <Button onClick={downloadVideo} disabled={disableButton}>
                  {disableButton ? 'Downloading in Progress' : 'Download Video'}
                </Button>
              </div>
            </div>
          ) : (
            <NoData customMessage="Requested asset video does not exist." />
          )}
        </Container>
      </div>
    </div>
  );
};

export default VidpitchVideoDownload;
