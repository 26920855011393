import React from 'react';
import { Layer, Line } from 'react-konva';

const LineShape = ({ color, x1, x2, y1, y2 }) => {
  return (
    <Layer>
      <Line
        points={[
          (640 * (x1 * 100)) / 100,
          (360 * (y1 * 100)) / 100,
          (640 * (x2 * 100)) / 100,
          (360 * (y2 * 100)) / 100,
        ]}
        stroke={color}
        strokeWidth={3}
      />
    </Layer>
  );
};
export default LineShape;
