import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const CheckoutWarningModal = ({
  isVisibleModal,
  closeCheckoutWarningModal,
  propertyName,
}) => {
  return (
    <>
      <Modal
        isOpen={isVisibleModal}
        toggle={closeCheckoutWarningModal}
        className="unfinished-orders-modal modal-dialog-centered overflow-hidden"
      >
        <ModalBody className="px-3 py-4">
          <p className="text-center mb-4">
            Please select ELITE or AUCTION option from sidebar for
            <br /> &quot;<strong>{propertyName}</strong>&quot;, to checkout this
            order.
          </p>
          <div className="text-center ">
            <Button
              color="secondary"
              className="previous-btn font-size-14 py-2 px-3"
              onClick={closeCheckoutWarningModal}
            >
              OK
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default CheckoutWarningModal;
