import React from 'react';
import { Table } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams } from 'react-router-dom';
//
import { PROJECT_DETAIL_COLUMN } from 'pages/ManagementPortal/constant';
import ActivityItems from './components/ActivityItems';

const TaskItem = ({ sectionName, sectionTasks }) => {
  const { projectId } = useParams();
  const location = useLocation();

  const idFromLocation = location.hash.split('#')[1];
  const idFromParam = projectId.split('#')[1];

  const renderSectionTasks = () => {
    let expandedTableId = [];
    if (!sectionTasks.length)
      return <div className="no-activity-message">No task found!</div>;
    return sectionTasks.map((row, index) => {
      const { task, activities } = row;
      const lastObject = activities[activities.length - 1];
      const taskID = task.split(' ').join('_');

      const taskData = [
        {
          ...row,
          ...lastObject,
        },
      ];
      if ((idFromParam || idFromLocation) === taskID) {
        expandedTableId = task;
      }

      return (
        <div key={`${task}_${index + 1}`} className="expand-table-container">
          <CustomTable
            keyField="task"
            data={taskData}
            columns={PROJECT_DETAIL_COLUMN}
            classes="mb-0  project-detail-inner-table "
            id={taskID}
            expandedTableId={expandedTableId}
          />
        </div>
      );
    });
  };
  return (
    <>
      <h6 className="mt-4 mb-2 font-size-14 font-weight-bold">{sectionName}</h6>
      <div className="collapse-section">{renderSectionTasks()}</div>
    </>
  );
};

const CustomTable = ({ expandedTableId, ...rest }) => {
  const expandRow = {
    parentClassName: (isExpanded) => {
      return 'parent-expand-bar';
    },
    renderer: ({ activities }) => {
      return (
        <div className="">
          <Table className="project-detail-inner-table activity-table">
            <tbody>
              <ActivityItems activities={activities} />
            </tbody>
          </Table>
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon icon={faCaretDown} />;
      }
      return <FontAwesomeIcon icon={faCaretRight} />;
    },
    expanded: [expandedTableId],
  };

  return <BootstrapTable {...rest} expandRow={expandRow} />;
};

export default TaskItem;
