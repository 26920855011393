import React from 'react';
import { useQuery } from '@apollo/client';
import PageLoader from 'common/PageLoader';
import { DEAL_COUNT } from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';

const DealCount = () => {
  const { loading, error, data } = useQuery(DEAL_COUNT, {});

  if (loading) return <PageLoader />;
  if (error) return <span>{error.message}</span>;

  if (data) {
    const { dealCount } = data;
    return (
      <span className="font-size-13 font-weight-normal">
        ({dealCount} Records)
      </span>
    );
  }

  return null;
};

export default DealCount;
