import React from 'react';
import { Row, Col, Form, FormGroup, Input } from 'reactstrap';

import HeaderUserDropdown from 'common/HeaderUserDropdown';
import PortalDropdown from 'common/PortalDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import BrokerRecordsCounters from './components/BrokerRecordsCounters';

const Header = () => {
  const history = useHistory();

  return (
    <>
      <Row className="content-header-row border-bottom py-2 align-items-center mx-n4">
        <Col sm="auto">
          {history.location.pathname === '/brokers-directory/advance-search' ? (
            <div className="advance-search">
              <Form>
                <FormGroup className="position-relative m-0">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="advance-search-icon"
                  />
                  <Input
                    type="search"
                    name="search"
                    id="advanceSearch"
                    className="pl-5"
                  />
                </FormGroup>
              </Form>
            </div>
          ) : (
            <h6 className="px-3 mb-0">Broker&#39;s Directory</h6>
          )}
        </Col>

        <Col sm="auto" className="ml-auto position-relative">
          <div className="d-flex align-items-center">
            <div className="d-lg-block d-none">
              <BrokerRecordsCounters />
            </div>
            <PortalDropdown />
            <HeaderUserDropdown />
          </div>
        </Col>
        <Col sm="12" className="d-lg-none d-block mt-2 text-center">
          <BrokerRecordsCounters />
        </Col>
      </Row>
    </>
  );
};
export default Header;
