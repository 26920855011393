import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Formik, Form } from 'formik';

import FormikInputField from 'components/Fields/FormikInputField';

const initialValues = {
  mapview: 'Manual view',
};
const MapView = () => {
  const {
    searchFilters: { firstName, lastName, companyName },
  } = useSelector((state) => state.salesPortal);

  const brokerName =
    !firstName && !lastName ? '' : `${firstName} ${lastName} (Broker)`;

  return (
    <div className="map-view px-2 py-3 ">
      <h6 className="font-weight-bold pl-2 pr-2">
        {companyName || <span>&nbsp;</span>}
      </h6>
      <p className="font-size-14 font-weight-500 mb-2 pl-2 pr-2">
        {brokerName}
      </p>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        }}
      >
        {(props) => (
          <Form>
            <Field
              id="mapview"
              name="mapview"
              component={FormikInputField}
              disabled
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default MapView;
