export const vidPitchInitialState = {
  assetListInfo: {
    startDate: '',
    endDate: '',
    globalSearchText: '',
    previousPageInfo: {
      previousRoutes: {
        path: '',
      },
      page: 1,
      offset: 0,
    },
  },
};
