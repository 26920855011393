import React from 'react';
import VideoMSLogo from 'common/VideoMSLogo';
import CrexiBrandLogo from 'common/CrexiBrandLogo';
import ManagementNavigation from './components/ManagementNavigation';

const Sidebar = () => {
  return (
    <div className="sidebar-quad px-3">
      <div className="d-flex justify-content-between align-items-center logo-height">
        <VideoMSLogo />
        <CrexiBrandLogo />
      </div>
      <div className='portal-name'>
        <span>MANAGEMENT PORTAL</span>
      </div>
      <div>
        <ManagementNavigation />
      </div>
    </div>
  );
};
export default Sidebar;
