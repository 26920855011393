import React from 'react';
import { Table } from 'reactstrap';

import BrokerItem from './components/BrokerItem';

const BrokersTable = ({
  tableData,
  saveBrokersData,
  toggleConfirmationModal,
}) => {
  const renderBrokerItem = () => {
    return tableData.map((row) => {
      return (
        <BrokerItem
          key={row.id}
          brokerInfo={row}
          saveBrokersData={saveBrokersData}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      );
    });
  };

  return (
    <>
      <Table className="table broker-table flagged-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company</th>
            <th>Phone No.</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>{renderBrokerItem()}</tbody>
      </Table>
    </>
  );
};
export default BrokersTable;
