import { gql } from '@apollo/client';

export const GET_PLAYER_DETAILS = gql`
  query getReviewLinkDetails($id: Int!) {
    getReviewLinkDetails(id: $id)
  }
`;

export const GET_S3_URL = gql`
  query getAwsS3PresignedUrl(
    $location: String!
    $fileName: String!
    $contentType: String!
  ) {
    getAwsS3PresignedUrl(
      location: $location
      fileName: $fileName
      contentType: $contentType
    )
  }
`;

export const ADD_VIDEO_PLAYER_COMMENT = gql`
  mutation addBrokerCommentsOnReviewAsset(
    $commentId: String!
    $commentTime: String
    $commentScreenShot: String!
    $commentText: String!
    $commentCreatedAt: String
    $commentUser: String
    $commentUserId: String
    $commentUpdatedAt: String
    $commentCompletedAt: String
    $assetId: Int!
    $commentSource: String!
    $annotationCords: JSON
    $parentId: Int
    $toEditor: Boolean
  ) {
    addBrokerCommentsOnReviewAsset(
      commentId: $commentId
      commentTime: $commentTime
      commentScreenShot: $commentScreenShot
      commentText: $commentText
      commentCreatedAt: $commentCreatedAt
      commentUser: $commentUser
      commentUserId: $commentUserId
      commentUpdatedAt: $commentUpdatedAt
      commentCompletedAt: $commentCompletedAt
      assetId: $assetId
      commentSource: $commentSource
      annotationCords: $annotationCords
      parentId: $parentId
      toEditor: $toEditor
    )
  }
`;

export const APPROVE_REVIEW_ASSET_COMMENT_USER = gql`
  query approveReviewAssetCommentUser($email: String!, $assetId: Int!) {
    approveReviewAssetCommentUser(email: $email, assetId: $assetId)
  }
`;

export const GET_REVIEW_DRAFT_LIST = gql`
  query getReviewDraftList($projectId: Int!) {
    getReviewDraftList(projectId: $projectId)
  }
`;

export const UPDATE_REVIEW_ASSET_STATUS = gql`
  mutation updateReviewAssetStatus(
    $parentId: Int!
    $assetId: Int!
    $folderName: String!
  ) {
    updateReviewAssetStatus(
      parentId: $parentId
      assetId: $assetId
      folderName: $folderName
    )
  }
`;

export const REVIEW_ASSET_COMMENT_STATUS = gql`
  subscription reviewAssetCommentStatus {
    reviewAssetCommentStatus
  }
`;

export const VIDEO_PLAYER_REVIEW_COMPLETE = gql`
  mutation videoPlayerReviewComplete(
    $projectId: Int!
    $isMappedComments: Boolean
    $folderAssetId: Int!
  ) {
    videoPlayerReviewComplete(
      projectId: $projectId
      isMappedComments: $isMappedComments
      folderAssetId: $folderAssetId
    )
  }
`;

export const VIDEO_PLAYER_REVIEW_COMPLETE_SUBSCRIPTION = gql`
  subscription videoPlayerReviewComplete {
    videoPlayerReviewComplete
  }
`;

export const VIDEO_PLAYER_CHAPTER_TEXT_CHANGES_BRONZE = gql`
  mutation chapterTextChanges($projectId: Int!) {
    chapterTextChanges(projectId: $projectId)
  }
`;

export const ADD_REVIEW_LINK_APPROVAL = gql`
  mutation addReviewLinkApproval(
    $projectId: Int!
    $assetId: Int!
    $reviewStatus: String!
    $folderName: String!
    $isNewTemplate: Boolean!
    $role: String
  ) {
    addReviewLinkApproval(
      projectId: $projectId
      assetId: $assetId
      reviewStatus: $reviewStatus
      folderName: $folderName
      isNewTemplate: $isNewTemplate
      role: $role
    )
  }
`;
