import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { findKey } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import FileImage from 'assets/image-line.svg';
import { authContext } from 'contexts/AuthContext';
import {
  BRONZE_FILE_TYPES,
  CUSTOM_IMAGE_UPLOAD_ENUM,
} from 'pages/CrexiBronze/constant';
import { GET_CUSTOM_ASSET_IMAGES } from 'pages/Vidpitch/VidPitchGraphQL';
import DeleteConfirmationModel from 'pages/CrexiBronze/common/DeleteConfirmationModel';
import { DELETE_CUSTOM_IMAGE_S3_URL } from 'pages/CrexiBronze/CrexiBronzeGraphQL';
import { crexiBronzeLogoAndQrUrl } from 'pages/CrexiBronze/CrexiBronzeAction';
import {
  getExtensionFromFileName,
  updateFileNameWithVersion,
} from 'utils/helper';
import UploadFileRows from './components/UploadFileRows';
import {
  GET_CUSTOM_IMAGE_PRE_SIGNED_URL,
  GET_S3_IMAGE_PRE_SIGNED_URL,
  UPDATE_CUSTOM_IMAGE_S3_URL,
} from './uploadsGraphQL';

const FileUploaderVidPitch = ({
  uuid,
  fileType,
  refetchAssets,
  versionSuffix,
  isBronze = false,
  updateBronzeUrl,
  logoAndQrUrl = '',
  currentChapter,
  refetchBronzeData = () => {},
  setPropertyBoundaryImg = () => {},
  isBoundaryImage,
}) => {
  const { auth } = useContext(authContext);
  const dispatch = useDispatch();

  const img = new Image();
  let fileNameWithVersions = {};

  const promisesArray = [];
  const preSignURLList = [];

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteModel, setDeleteModal] = useState(false);

  const fileInput = React.useRef();
  const filesToUpload = (fileInput.current && fileInput.current.files) || [];
  const { LOGO, IMAGE, QR_CODE, PROPERTY_BOUNDARY } = BRONZE_FILE_TYPES;
  const [
    getCustomAssetImages,
    { data: customImages, refetch: refetchCustomImages },
  ] = useLazyQuery(GET_CUSTOM_ASSET_IMAGES);

  const [getCustomImagePreSignedUrl] = useMutation(
    GET_CUSTOM_IMAGE_PRE_SIGNED_URL,
    {
      onError: (err) => {
        fileInput.current.value = '';
        setProgress(0);
        setLoading(false);
        toast.error('File upload failed. Please try again.');
        console.log('update custom image', err);
      },
    }
  );

  const [getS3ImagePreSignedUrl, { error, data }] = useLazyQuery(
    GET_S3_IMAGE_PRE_SIGNED_URL
  );

  // handle error here
  // if (error) {
  //   fileInput.current.value = '';
  //   setProgress(0);
  //   setLoading(false);
  //   toast.error('File upload failed. Please try again.');
  // }

  const [updateCustomImageS3Url] = useMutation(UPDATE_CUSTOM_IMAGE_S3_URL, {
    onCompleted: () => {
      fileInput.current.value = '';
      refetchAssets();
      if (setPropertyBoundaryImg) setPropertyBoundaryImg([{}]);
      refetchCustomImages();
      setProgress(100);
      setLoading(false);
      toast.success('File uploaded successfully!!');
    },
    onError: (err) => {
      fileInput.current.value = '';
      setProgress(0);
      setLoading(false);
      toast.error('An error occurred, while file uploading. Please try again.');
    },
  });

  const onDeleteCancel = () => {
    setDeleteModal(!showDeleteModel);
    fileInput.current.value = '';
  };
  const updateCustomImage = async () => {
    try {
      const input = preSignURLList.map((item) => {
        return {
          s3ImageLink: item.split('?X-')[0],
          fileType,
          chapter: `Chapter_${currentChapter}`,
        };
      });
      await updateCustomImageS3Url({
        variables: {
          input,
          uuid,
          ...(isBronze ? { packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE } : {}),
          ...(isBronze ? { userUuid: auth?.data?.userUuid ?? '' } : {}),
        },
      });

      if (
        isBronze &&
        input?.length &&
        (fileType === LOGO || fileType === QR_CODE)
      ) {
        updateBronzeUrl(Object.assign({}, ...input));
      }
    } catch (err) {
      console.log('update custom', err);
    }
  };

  const fetchPreSignURL = async (preSignURL, blob) => {
    preSignURLList.push(preSignURL);
    try {
      const uploadResp = await axios.put(preSignURL, blob, {
        headers: { 'Content-Type': 'Image' },
      });
      promisesArray.push({ uploadResp });
      await Promise.all(promisesArray);
      if (promisesArray.length === Object.keys(fileNameWithVersions).length) {
        await updateCustomImage();
      }
    } catch (er) {
      console.log('er', er);
    }
  };

  const uploadToPreSignURL = async (preSignUrlCollection) => {
    if (
      preSignUrlCollection?.getCustomImagePreSignedUrl ||
      preSignUrlCollection?.getS3ImagePreSignedUrl
    ) {
      const urlRes =
        preSignUrlCollection?.getS3ImagePreSignedUrl ??
        preSignUrlCollection?.getCustomImagePreSignedUrl;

      urlRes?.forEach(async (item) => {
        const [updatedFileName] = Object.keys(item);
        const resultKey = findKey(fileNameWithVersions, {
          fileName: updatedFileName,
        });
        const { fileBlob } = fileNameWithVersions[resultKey];
        await fetchPreSignURL(item[updatedFileName], fileBlob);
      });
    }
  };

  const uploadFile = async () => {
    try {
      const payload = Object.values(fileNameWithVersions).map((item) => {
        return {
          fileName: item.fileName,
          fileType,
          uuid,
          ...(currentChapter ? { chapter: `Chapter_${currentChapter}` } : {}),
        };
      });
      if (payload.length) {
        if (fileType === PROPERTY_BOUNDARY) {
          const { data } = await getS3ImagePreSignedUrl({
            variables: {
              customImageInput: payload,
              ...(isBronze
                ? { packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE }
                : {}),
            },
          });
          if (data) {
            await uploadToPreSignURL(data);
          }
        } else {
          const { data } = await getCustomImagePreSignedUrl({
            variables: {
              customImageInput: payload,
              ...(isBronze
                ? { packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE }
                : {}),
            },
          });
          if (data) {
            await uploadToPreSignURL(data);
          }
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleUpload = (file, index) => {
    let suffix = 0;
    if (index !== 0) {
      suffix = versionSuffix + 1 + index;
    } else {
      suffix = versionSuffix + 1;
    }
    fileNameWithVersions = {
      ...fileNameWithVersions,
      [file.name]: {
        fileName: updateFileNameWithVersion(suffix, file.name),
        fileBlob: file,
      },
    };

    setLoading(true);
    setProgress(0);

    if (fileType === LOGO) uploadFile();
  };

  const imageLoadEvent = (file, index) => {
    img.onload = () => {
      if (img.width === 150 && img.height === 60) {
        handleUpload(file, index);
      } else {
        setLoading(false);
        setProgress(0);
        fileInput.current.value = '';
        toast.error(
          'Uploaded logo image not matches with required dimensions, Please use 150 X 60 logo dimension'
        );
      }
    };
  };

  const imageOnLoad = async (file, index) => {
    return new Promise(() => imageLoadEvent(file, index));
  };

  const verifyDimension = async (file, index) => {
    img.src = window.URL.createObjectURL(file);
    await imageOnLoad(file, index);
  };

  const verifyFormat = (file) => {
    const ext = getExtensionFromFileName(file.name);
    return ['jpg', 'png', 'jpeg'].includes(ext);
  };

  const processFileUploading = () => {
    const filesList = fileInput.current.files;
    if (
      ((fileType === LOGO || fileType === QR_CODE) && filesList.length === 1) ||
      fileType === IMAGE ||
      fileType === PROPERTY_BOUNDARY
    ) {
      Object.values(filesList).forEach(async (fileItem, index) => {
        const isAllowedType = verifyFormat(fileItem);
        if (isAllowedType) {
          if (fileType === LOGO) {
            await verifyDimension(fileItem, index);
          }

          handleUpload(fileItem, index);
        } else {
          toast.error(
            `${getExtensionFromFileName(
              fileItem.name
            )} file format not allowed.`
          );
        }
      });

      if (fileType !== LOGO) uploadFile();
    } else {
      fileInput.current.value = '';
      toast.error('Multiple file uploading is not allowed');
    }
  };

  const [deleteCustomImageS3Url] = useMutation(DELETE_CUSTOM_IMAGE_S3_URL, {
    onCompleted: () => {
      toast.success('File Deleted successfully!!');
      setDeleteModal(false);
      refetchBronzeData();
      refetchCustomImages();
      processFileUploading();
    },
    onError: (err) => {
      toast.error('An error occurred, while deleting file. Please try again.');
      fileInput.current.value = '';
      setLoading(false);
      setProgress(0);
      setDeleteModal(false);
    },
  });

  useEffect(() => {
    if (isBronze && uuid && (fileType === LOGO || fileType === QR_CODE)) {
      getCustomAssetImages({
        variables: {
          fileType,
          offset: 0,
          limit: 100,
          uuid,
          packageType: CUSTOM_IMAGE_UPLOAD_ENUM.BRONZE,
        },
      });
    }
  }, [uuid, fileType]);

  useEffect(() => {
    if (isBronze && uuid && (fileType === LOGO || fileType === QR_CODE)) {
      let url = '';
      if (customImages?.getCustomAssetImages?.length) {
        url = customImages.getCustomAssetImages[0]?.s3ImageLink;
      }
      dispatch(
        crexiBronzeLogoAndQrUrl({
          logoAndQrUrl: url,
        })
      );
    }
  }, [customImages]);

  const handleChange = (event) => {
    // const renderProgressModal = () => {
    //   setLoading(true);
    //   setProgress(0);
    //   event.preventDefault();
    // };

    if (!fileInput.current.files || fileInput.current.files.length === 0) {
      toast.error('Choose at least one file!');
      event.preventDefault();
    } else if (
      isBronze &&
      customImages?.getCustomAssetImages?.length &&
      (fileType === LOGO || fileType === QR_CODE)
    ) {
      setProgress(0);
      setDeleteModal(true);
    } else {
      processFileUploading();
    }
  };
  const deleteCustomImages = () => {
    const s3ImageLinkArray =
      customImages?.getCustomAssetImages?.map((item) => item.s3ImageLink) ?? [];

    deleteCustomImageS3Url({
      variables: {
        s3ImageLinkArray,
        fileType,
        projectUuid: uuid,
        // userUuid: auth?.data?.userUuid ?? '',
      },
    });
  };

  return (
    <>
      <div className={`container ${isBronze ? 'p-0' : ''}`}>
        <form className="upload-steps">
          <div
            className={`upload-section text-center position-relative ${
              isBronze &&
              (fileType === IMAGE
                ? 'w-100 mb-0'
                : `${!isBoundaryImage ? 'w-75' : ''} mb-0`)
            }`}
          >
            <div className="input-file">
              <div className={`input-group ${isBronze ? '' : 'mb-3'}`}>
                <input
                  type="file"
                  className="form-control cursor-pointer"
                  style={{ height: isBronze ? '50px' : '115px' }}
                  multiple
                  ref={fileInput}
                  disabled={loading}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            {!isBronze ? (
              <div className="upload-section-abs">
                <img src={FileImage} alt="File" />
                <h6 className="mt-3">
                  Drop files here or <span>Browse</span>
                </h6>
              </div>
            ) : (
              <span className="position-absolute upload-text-bz">
                {logoAndQrUrl ? (
                  <>
                    <span className="text-success">Uploaded </span>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-success"
                    />
                  </>
                ) : (
                  'Upload'
                )}
              </span>
            )}
          </div>
          {isBronze ? (
            <span className="font-size-12 text-muted">
              Supporting files png, jpeg
            </span>
          ) : null}
        </form>
        <div>
          <UploadFileRows
            progress={progress}
            filesToUpload={Object.values(filesToUpload) || []}
            verifyFormat={verifyFormat}
          />

          <DeleteConfirmationModel
            onDeleteCancel={onDeleteCancel}
            showDeleteModel={showDeleteModel}
            deleteCustomImages={deleteCustomImages}
            fileType={fileType}
          />
        </div>
      </div>
    </>
  );
};

export default FileUploaderVidPitch;
