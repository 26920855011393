import React, { useContext, useState } from 'react';
import { Badge, Button, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';
import slackIMG from 'assets/slack.png';
import asanaIMG from 'assets/asana.png';
import feedbackImg from 'assets/feedback-line.png';

import Header from 'pages/PMPortal/common/Header';
import { BRONZE, NOT_SPECIFIED, SLACK_LINK } from 'constants/appConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import InviteModal from '../InviteModal';
import EditorInviteModal from '../../../EditorInvite/components/EditorInviteModal';
import ProjectFeedback from '../../../Feedback';
import EditorInvitedAlreadyModal from '../../../EditorInvite/components/EditorInvitedAlreadyModal';
import PilotAlreadyInvitedModal from '../PilotAlreadyInvitedModal';

const ProjectDetailHeader = ({
  projectId,
  projectName,
  projectAsanaLink,
  slackLink,
  startDate,
  projectEditor,
  projectPilot,
  projectManager,
  isPilotInviteAccepted,
  isEditorInviteAccepted,
  dataSource,
  isCompleted,
  projectReviews,
  refetchProjectDetails,
  planType,
  status,
}) => {
  const { feedbackStatus } = useParams();
  const {
    auth: {
      data: { role },
    },
  } = useContext(authContext);

  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [inviteEditorModal, setInviteEditorModal] = useState(false);
  const [inviteEditorPromptModal, setInviteEditorPromptModal] = useState(false);
  const [invitePilotPromptModal, setInvitePilotPromptModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const toggleEditorInviteModal = (status) => {
    setInviteEditorModal(status);
  };
  const toggleEditorInvitePromptModal = (status) => {
    setInviteEditorPromptModal(status);
  };
  const togglePilotInvitePromptModal = (status) => {
    setInvitePilotPromptModal(status);
  };
  const toggleSendEmailModal = (status) => {
    setSendEmailModal(status);
  };
  const toggleFeedbackModal = (status) => {
    setFeedbackModal(status);
  };

  const toggleEditorInvite = () => {
    if (projectEditor !== NOT_SPECIFIED) {
      toggleEditorInvitePromptModal(true);
    } else if (projectEditor === NOT_SPECIFIED) {
      toggleEditorInviteModal(true);
    }
  };

  const togglePilotInvite = () => {
    if (projectPilot !== NOT_SPECIFIED) {
      togglePilotInvitePromptModal(true);
    } else if (projectPilot === NOT_SPECIFIED) {
      toggleSendEmailModal(true);
    }
  };

  const showFeedbackButton = () => {
    if (isCompleted && !projectReviews) {
      return (
        <Button color="link" onClick={() => toggleFeedbackModal(true)}>
          <img src={feedbackImg} alt="feedback img" />
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Header heading={projectName} />
      <Table borderless className="mb-0 table-header-list">
        <thead>
          <tr>
            {planType !== 'BRONZE' && <th>PROJECT IN ASANA</th>}
            {role !== ROLES.VIDEOMS_PM && <th>SLACK PM</th>}
            <th>EDITOR</th>
            {planType !== 'BRONZE' && <th>PILOT</th>}
            <th>START DATE</th>
            <th>PROJECT TYPE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {planType !== BRONZE && (
              <td className="pt-2">
                <a href={projectAsanaLink} rel="noreferrer" target="_blank">
                  <img src={asanaIMG} alt={asanaIMG} className="mr-2" />
                  View project in Asana
                  <FontAwesomeIcon
                    icon={faExternalLinkSquareAlt}
                    color="#565082"
                    className="ml-2 align-middle"
                  />
                </a>
              </td>
            )}

            {role !== ROLES.VIDEOMS_PM && (
              <td className="pt-2">
                <a
                  href={slackLink || SLACK_LINK}
                  rel="noreferrer"
                  target="_blank"
                  className="mb-0"
                >
                  <img src={slackIMG} alt={slackIMG} className="mr-2" />
                  {projectManager}
                </a>
              </td>
            )}

            <td className="pt-2">
              {!isEditorInviteAccepted && planType !== BRONZE ? (
                <Button
                  color="primary"
                  size="sm"
                  className="px-3 py-1 border-radius-8px"
                  onClick={toggleEditorInvite}
                >
                  Invite
                </Button>
              ) : (
                !isEditorInviteAccepted && 'Editor Invited'
              )}
              {isEditorInviteAccepted && (
                <a
                  href={slackLink || SLACK_LINK}
                  rel="noreferrer"
                  target="_blank"
                  className="mb-0"
                >
                  <img src={slackIMG} alt={slackIMG} className="mr-2" />
                  {projectEditor}
                </a>
              )}
            </td>
            {planType !== BRONZE && (
              <td className="pt-2">
                {!isPilotInviteAccepted && (
                  <Button
                    color="primary"
                    size="sm"
                    className="px-3 py-1 border-radius-8px"
                    disabled={isPilotInviteAccepted}
                    onClick={togglePilotInvite}
                  >
                    Invite
                  </Button>
                )}
                {isPilotInviteAccepted && projectPilot}
              </td>
            )}
            <td className="pt-2">{startDate}</td>
            <td className="pt-2">{planType}</td>
            <td className="pt-2">
              <Badge
                color="light"
                className="px-3 rounded border border-primary"
              >
                {planType !== BRONZE ? (
                  <>
                    {!isCompleted && (
                      <span className="pilot-invitation-status">
                        {isPilotInviteAccepted ? 'ACTIVE' : 'NOT STARTED'}
                      </span>
                    )}
                    {isCompleted && (
                      <span className="pilot-invitation-status">COMPLETED</span>
                    )}
                  </>
                ) : (
                  <span className="pilot-invitation-status">{status}</span>
                )}
              </Badge>
              {showFeedbackButton()}
            </td>
          </tr>
        </tbody>
      </Table>
      {sendEmailModal && (
        <InviteModal
          sendEmailModal={sendEmailModal}
          toggleSendEmailModal={toggleSendEmailModal}
          projectId={projectId}
          refetchProjectDetails={refetchProjectDetails}
        />
      )}
      {inviteEditorPromptModal && (
        <EditorInvitedAlreadyModal
          inviteEditorPromptModal={inviteEditorPromptModal}
          toggleEditorInviteModal={toggleEditorInviteModal}
          toggleEditorInvitePromptModal={toggleEditorInvitePromptModal}
        />
      )}
      {invitePilotPromptModal && (
        <PilotAlreadyInvitedModal
          invitePilotPromptModal={invitePilotPromptModal}
          togglePilotInviteModal={toggleSendEmailModal}
          togglePilotInvitePromptModal={togglePilotInvitePromptModal}
        />
      )}
      {inviteEditorModal && (
        <EditorInviteModal
          inviteEditorModal={inviteEditorModal}
          toggleEditorInviteModal={toggleEditorInviteModal}
          projectId={projectId}
          projectName={projectName}
          dataSource={dataSource}
          searchFilter={null}
        />
      )}

      {(feedbackStatus === 'feedback' && isCompleted && !projectReviews) ||
      feedbackModal ? (
        <ProjectFeedback
          feedbackModal
          toggleFeedbackModal={toggleFeedbackModal}
          projectId={projectId}
        />
      ) : null}
    </>
  );
};
export default ProjectDetailHeader;
