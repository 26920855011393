import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
//
import { VidPitchContext } from 'contexts/VidPitchContext';
import { authContext } from 'contexts/AuthContext';
import Step1 from '../TemplateSelection';
import Step2 from '../EditTextImages';
import RenderVideoPromptModal from './components/RenderVideoPromptModal';

const steps = ['SELECT TEMPLATE', 'EDIT TEXT & IMAGES'];

const renderStepContent = (step, properties) => {
  switch (step) {
    case 0:
      return <Step1 {...properties} />;
    case 1:
      return <Step2 {...properties} />;
    default:
      return <div>Not Found</div>;
  }
};

const MultiStepWrapper = ({ uuid, videoId, videoURL, assetCrexiId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [confirmationModal, toggleConfirmationModal] = useState(false);

  const { auth } = useContext(authContext);
  const { modifyVideoDetails, updateModifyVideoDetail } =
    useContext(VidPitchContext);

  const isLastStep = activeStep === steps.length - 1;

  const submitForm = async () => {
    return true;
  };

  const updateActiveStep = () => {
    setActiveStep(activeStep + 1);
    updateModifyVideoDetail({
      ...modifyVideoDetails,
      previewURL: '',
    });
    toggleConfirmationModal(false);
  };

  const handleSubmit = async () => {
    if (isLastStep) {
      submitForm();
    } else {
      toggleConfirmationModal(true);
    }
  };

  const handleBack = () => {
    const { selectedTemplateId } = modifyVideoDetails;
    updateModifyVideoDetail({
      selectedTemplateId,
      totalChapter: 0,
      currentChapter: 1,
      previewURL: '',
    });
    setActiveStep(activeStep - 1);
  };

  const renderSteps = () => {
    return steps.map((item, index) => {
      return (
        <div
          key={item}
          className={
            index === activeStep
              ? 'vidpitch-stepper-step active'
              : 'vidpitch-stepper-step'
          }
        >
          <span>{index + 1}</span>
          {item}
        </div>
      );
    });
  };

  return (
    <div className="p-4">
      <div className="d-flex">{renderSteps()}</div>
      {renderStepContent(activeStep, {
        videoId,
        videoURL,
        assetCrexiId,
      })}
      {activeStep === 0 && <hr />}
      <div className="mt-2">
        <div>
          {activeStep !== 0 && (
            <Button
              color="primary"
              outline
              onClick={handleBack}
              className="temp-button"
              style={
                modifyVideoDetails.previewURL
                  ? { position: 'relative', bottom: '40px' }
                  : { position: 'relative' }
              }
            >
              Back To Select Template
            </Button>
          )}

          {activeStep === 0 && (
            <div className="d-flex align-items-center justify-content-between">
              <Link
                to={
                  auth?.data?.role ? '/assets-listing' : `/${uuid}/video-draft`
                }
                className="temp-button btn btn-outline-primary"
              >
                Back To Home
              </Link>
              <Button
                color="primary"
                onClick={handleSubmit}
                type="submit"
                className="temp-button"
              >
                <>Save & Continue To Next Step</>
              </Button>
            </div>
          )}
        </div>
      </div>
      <RenderVideoPromptModal
        videoId={videoId}
        updateActiveStep={updateActiveStep}
        confirmationModal={confirmationModal}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </div>
  );
};

export default MultiStepWrapper;
