import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjects($status: String!, $limit: Int!, $offset: Int!) {
    getProjects(status: $status, limit: $limit, offset: $offset)
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query getTaskDetails($projectId: Int!) {
    getTaskDetails(projectId: $projectId)
  }
`;

export const GET_ATTENTION_PROJECT = gql`
  query getAttentionProjects($limit: Int!, $offset: Int!) {
    getAttentionProjects(limit: $limit, offset: $offset)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications($limit: Int, $offset: Int) {
    getNotifications(limit: $limit, offset: $offset)
  }
`;
export const PROJECT_SEARCH = gql`
  query projectSearch(
    $searchText: String!
    $status: String!
    $limit: Int!
    $offset: Int!
  ) {
    projectSearch(
      searchText: $searchText
      status: $status
      limit: $limit
      offset: $offset
    )
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus(
    $actionValue: Boolean!
    $status: String!
    $id: Int!
  ) {
    updateNotificationStatus(
      actionValue: $actionValue
      status: $status
      id: $id
    )
  }
`;

export const TASK_NOTIFICATION_SUBSCRIPTION = gql`
  subscription taskNotification {
    taskNotification
  }
`;

export const TASK_UPDATE_SUBSCRIPTION = gql`
  subscription taskUpdated {
    taskUpdated
  }
`;

export const NOTIFICATION_ACTION_SUBSCRIPTION = gql`
  subscription notificationAction {
    notificationAction
  }
`;

export const BROKER_COMMENT_ON_REVIEW_ASSET = gql`
  subscription addedBrokerCommentsOnReviewAsset {
    addedBrokerCommentsOnReviewAsset
  }
`;
export const VIDEO_STATUS_UPDATED = gql`
  subscription videoStatusUpdated {
    videoStatusUpdated
  }
`;

// ========================= PM portal =============
export const GET_ACTIVE_PROJECT_LIST = gql`
  query getActiveProjectList(
    $status: String!
    $limit: Int!
    $offset: Int!
    $isEditorPortal: Boolean
    $filter: JSON
  ) {
    getActiveProjectList(
      status: $status
      limit: $limit
      offset: $offset
      isEditorPortal: $isEditorPortal
      filter: $filter
    )
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query getProjectDetails($projectId: Int!) {
    getProjectDetails(projectId: $projectId)
  }
`;

export const GET_AWS_FOLDERS = gql`
  query getAwsFolders($projectId: Int!) {
    getAwsFolders(projectId: $projectId)
  }
`;

export const GET_FOLDER_ASSETS = gql`
  query getAwsFiles($filePath: String!) {
    getAwsFiles(filePath: $filePath)
  }
`;

export const SEARCH_PROJECT_BY_NAME = gql`
  query searchProjectByName(
    $searchString: String!
    $limit: Int
    $offset: Int
    $isEditorPortal: Boolean
  ) {
    searchProjectByName(
      searchString: $searchString
      limit: $limit
      offset: $offset
      isEditorPortal: $isEditorPortal
    )
  }
`;

export const LIST_REVIEWED_PROJECTS = gql`
  query listReviewedProjects($limit: Int, $offset: Int) {
    listReviewedProjects(limit: $limit, offset: $offset)
  }
`;

export const LIST_REVIEW_ASSET_COMMENTS = gql`
  query listReviewAssetComments($folderId: Int!, $version: String) {
    listReviewAssetComments(folderId: $folderId, version: $version)
  }
`;

export const ACCEPT_BROKER_COMMENTS_BY_PROJECT_MANAGER = gql`
  mutation acceptBrokerCommentsByProjectManager(
    $input: [CommentAcceptInput!]!
  ) {
    acceptBrokerCommentsByProjectManager(input: $input)
  }
`;

export const PILOT_INVITE = gql`
  mutation pilotInvite(
    $email: String!
    $projectId: Int!
    $companyName: String
    $firstName: String
    $googleLink: String
  ) {
    pilotInvite(
      email: $email
      projectId: $projectId
      companyName: $companyName
      firstName: $firstName
      googleLink: $googleLink
    )
  }
`;

export const GET_ASSET_REVIEW_LINK = gql`
  query getAssetReviewLink(
    $projectId: Int!
    $folderName: String!
    $fileName: String!
  ) {
    getAssetReviewLink(
      projectId: $projectId
      folderName: $folderName
      fileName: $fileName
    )
  }
`;

export const REVIEW_ASSET_COMMENT_STATUS = gql`
  subscription reviewAssetCommentStatus {
    reviewAssetCommentStatus
  }
`;

export const ACCEPTED_BROKER_COMMENT = gql`
  subscription acceptedBrokerComment {
    acceptedBrokerComment
  }
`;

//------------------------Editor Invite-------------------------------

export const EDITOR_LISTING = gql`
  query editorListing(
    $skills: [String!]!
    $availability: EditorAvailabilityInput!
    $flag: String!
    $limit: Int
    $offset: Int
  ) {
    editorListing(
      skills: $skills
      availability: $availability
      flag: $flag
      limit: $limit
      offset: $offset
    )
  }
`;

export const ADD_REVIEW_LINK_APPROVAL = gql`
  mutation addReviewLinkApproval(
    $projectId: Int!
    $assetId: Int!
    $reviewStatus: String!
    $folderName: String!
    $isNewTemplate: Boolean!
  ) {
    addReviewLinkApproval(
      projectId: $projectId
      assetId: $assetId
      reviewStatus: $reviewStatus
      folderName: $folderName
      isNewTemplate: $isNewTemplate
    )
  }
`;

export const GET_PROJECT_QUOTES = gql`
  query getProjectQuotes($projectId: Int!, $dataSource: String!) {
    getProjectQuotes(projectId: $projectId, dataSource: $dataSource)
  }
`;

export const USER_INVITE = gql`
  mutation userInvite(
    $email: String!
    $role: String!
    $projectId: Int!
    $companyName: String
    $firstName: String
    $googleLink: String
    $projectStartDate: Date
    $projectCompleteDate: Date
    $projectEditorBudget: String
    $projectNotes: JSON
    $requiredSkills: [String]
  ) {
    userInvite(
      email: $email
      role: $role
      projectId: $projectId
      companyName: $companyName
      firstName: $firstName
      googleLink: $googleLink
      projectStartDate: $projectStartDate
      projectCompleteDate: $projectCompleteDate
      projectEditorBudget: $projectEditorBudget
      projectNotes: $projectNotes
      requiredSkills: $requiredSkills
    )
  }
`;

export const LIST_EDITOR_PROJECTS = gql`
  query listEditorProjects($editorId: Int!) {
    listEditorProjects(editorId: $editorId)
  }
`;

export const PM_TO_EDITOR_FEEDBACK = gql`
  mutation pmToEditorFeedback(
    $timeBadge: Boolean
    $projectReviews: JSON
    $commentsOnEditor: String
    $projectId: Int!
  ) {
    pmToEditorFeedback(
      timeBadge: $timeBadge
      projectReviews: $projectReviews
      commentsOnEditor: $commentsOnEditor
      projectId: $projectId
    )
  }
`;

export const UPDATE_PROJECT_RELATED_DETAILS = gql`
  mutation updateProjectRelatedLinks(
    $field: String!
    $value: String!
    $projectId: Int!
  ) {
    updateProjectRelatedLinks(
      field: $field
      value: $value
      projectId: $projectId
    )
  }
`;

export const GET_UPDATED_AWS_FOLDERS = gql`
  subscription getUpdatedAwsFolders {
    getUpdatedAwsFolders
  }
`;

export const PILOT_EDITOR_INVITED_ACCEPTED = gql`
  subscription pilotOrEditorInviteAccepted {
    pilotOrEditorInviteAccepted
  }
`;

export const PILOT_ASSET_UPDATED = gql`
  subscription pilotAssetUpdated {
    pilotAssetUpdated
  }
`;

export const UPLOADED_ASSETS_BY_EDITOR = gql`
  subscription uploadedAssetsByEditor {
    uploadedAssetsByEditor
  }
`;

export const GET_UPDATED_CUSTOM_FOLDERS = gql`
  subscription getUpdatedCustomFolders {
    getUpdatedCustomFolders
  }
`;

export const EDITOR_UPDATE_SUBSCRIPTION = gql`
  subscription editorUpdate {
    editorUpdate
  }
`;
