import * as actions from './EditorPortalActionTypes';

export const editorActiveProjectPageInfo = (payload) => ({
  type: actions.EDITOR_ACTIVE_PROJECT_PAGE_INFO,
  payload,
});

export const editorFolderAssetsPageInfo = (payload) => ({
  type: actions.EDITOR_FOLDER_ASSETS_PAGE_INFO,
  payload,
});

export const editorFilesToDownload = (payload) => ({
  type: actions.EDITOR_FILES_TO_DOWNLOAD,
  payload,
});

export const resetEditorPortalReducer = (payload) => ({
  type: actions.RESET_EDITOR_PORTAL_REDUCER,
  payload,
});

export const editorProjectDetails = (payload) => ({
  type: actions.EDITOR_PROJECT_DETAILS,
  payload,
});

export const activeTabEditorPortalReducer = (payload) => ({
  type: actions.EDITOR_ACTIVE_TAB_STATUS,
  payload,
});

export const newInviteProject = (payload) => ({
  type: actions.NEW_PROJECT_INVITE,
  payload,
});
