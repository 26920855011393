import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, ModalBody, Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';
//
import medal from 'assets/medal.png';
import PageLoader from 'pages/Search/common/PageLoader';
import { LIST_EDITOR_PROJECTS } from 'pages/PMPortal/components/Projects/ProjectsGraphQL';
import EditorProjects from './EditorProjects';
import ExternalEditorInviteModal from '../ExternalEditorInviteModal';

const EditorProfileDetails = ({
  isEditorProfileModalOpen,
  toggleEditorProfileModal,
  editorHeaderInfo,
  dataSource,
  projectId,
  editorSearchFilters,
}) => {
  const {
    id,
    name,
    email,
    location,
    photo,
    availability,
    avgStars,
    totalReviews,
    skills,
  } = editorHeaderInfo;

  const [inviteExternalEditorModal, setInviteExternalEditorModal] =
    useState(false);

  const toggleExternalEditorInviteModal = (status) => {
    setInviteExternalEditorModal(status);
  };

  const { loading, error, data } = useQuery(LIST_EDITOR_PROJECTS, {
    variables: {
      editorId: id,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;
  if (error) return <>{error.message}</>;

  const renderAvailability = () => {
    if (typeof availability === 'string') {
      return <span>{availability}</span>;
    }

    return '';
  };

  const renderStars = () => {
    const starRatings = Math.floor(avgStars);
    return [...Array(4)].map((item, index) => {
      return (
        <span
          key={Math.random()}
          className={`${starRatings > index ? 'text-warning' : 'grey-star'}`}
        >
          &#9733;
        </span>
      );
    });
  };

  const renderStarCount = () => {
    const starRatings = Math.floor(avgStars);
    return (
      <span className="text-muted pl-2 font-weight-500 font-size-12">
        {starRatings} star off {totalReviews} reviews
      </span>
    );
  };

  const renderEditorSkills = () => {
    const newSkills = Object.keys(skills).filter((key) => skills[key] === 1);

    if (newSkills.length) {
      return newSkills.map((skill) => {
        return (
          <Badge
            key={skill}
            color="light"
            pill
            className="p-2 ml-1 font-size-12 text-muted border"
          >
            {skill}
          </Badge>
        );
      });
    }
    return 'N/A';
  };

  return (
    <>
      <Modal
        isOpen={isEditorProfileModalOpen}
        toggle={() => toggleEditorProfileModal(false)}
        className="project-details-spp modal-xl mr-0 mt-0 mb-0"
      >
        <ModalBody className="p-0">
          <div className="d-flex justify-content-between align-items-center px-4 py-2">
            <span className="font-size-16">{name}</span>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className="cursor-pointer"
              onClick={() => {
                toggleEditorProfileModal(false);
              }}
            />
          </div>
          <hr className="m-0" />
          <div className="bg-light">
            <div className="d-flex p-4">
              <div>
                {photo !== 'Not specified' ? (
                  <img
                    src={photo}
                    className="rounded-circle"
                    width="100"
                    height="100"
                    style={{ borderRadius: '50%' }}
                    alt="editor-profile"
                  />
                ) : (
                  <FontAwesomeIcon
                    size="8x"
                    className="mr-2"
                    icon={faUserCircle}
                    color="#3AB42B"
                  />
                )}
              </div>

              <div className="font-weight-bold flex-fill pl-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <p className="font-size-18 text-capitalize font-weight-500 m-0">
                      {name}
                    </p>
                    <span>
                      <Badge pill className="invite-badge">
                        EDITOR
                      </Badge>
                    </span>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => toggleExternalEditorInviteModal(true)}
                    >
                      Invite to Project
                    </Button>
                  </div>
                </div>
                <div className="font-size-14 text-muted font-weight-500">
                  {location}
                </div>
                <div className="mt-2">
                  {data?.listEditorProjects?.timeBadge && (
                    <Badge pill className="border font-size-12 badge-white">
                      <span>
                        {' '}
                        <img src={medal} alt="" />
                      </span>
                      <span> On Time Badge</span>
                    </Badge>
                  )}
                  <Badge
                    pill
                    className="p-2 px-4 badge-success font-size-12 invite-available"
                  >
                    {renderAvailability()}
                  </Badge>
                </div>

                <div className=" font-size-16 mt-2">
                  {renderStars()}
                  {renderStarCount()}
                </div>
                <div className="pt-4 font-weight-500">
                  <span className="font-size-14 mr-2">Skills:</span>
                  {renderEditorSkills()}
                </div>
              </div>
            </div>
          </div>
          <EditorProjects projectsData={data?.listEditorProjects} />
        </ModalBody>
      </Modal>
      {inviteExternalEditorModal && (
        <ExternalEditorInviteModal
          inviteExternalEditorModal={inviteExternalEditorModal}
          toggleExternalEditorInviteModal={toggleExternalEditorInviteModal}
          projectId={projectId}
          dataSource={dataSource}
          editorName={name}
          editorEmail={email}
          skills={editorSearchFilters.skills}
        />
      )}
    </>
  );
};

export default EditorProfileDetails;
