import { gql } from '@apollo/client';

export const REGISTER_BROKER = gql`
  mutation registerBroker(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $uuid: String!
  ) {
    registerBroker(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      uuid: $uuid
    )
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  query createPaymentIntent($uuid: String!, $brokerEmail: String!) {
    createPaymentIntent(uuid: $uuid, brokerEmail: $brokerEmail)
  }
`;

export const GET_VIDEO_DATA = gql`
  query getVideoData($input: VideoDataInput!) {
    getVideoData(input: $input)
  }
`;

export const UPDATE_VIDEO_WATCH_TIME = gql`
  mutation updateVideoWatchTime($input: VideoWatchTimeInput!) {
    updateVideoWatchTime(input: $input)
  }
`;

export const GET_VIDEO_TEMPLATES = gql`
  query getVideoTemplates {
    getVideoTemplates
  }
`;

export const GET_VIDEO_SEGMENT = gql`
  query getVideoSegment(
    $templateId: String!
    $segmentNumber: Int!
    $videoId: Int!
  ) {
    getVideoSegment(
      templateId: $templateId
      segmentNumber: $segmentNumber
      videoId: $videoId
    )
  }
`;

export const GET_ASSET_IMAGES = gql`
  query getAssetImages($input: AssetImagesInput!) {
    getAssetImages(input: $input)
  }
`;
export const RENDER_EDITED_VIDEO_TEMPLATE = gql`
  mutation renderEditedVideoTemplate(
    $videoId: Int!
    $templateId: String!
    $formData: JSON!
    $isEdited: Boolean!
  ) {
    renderEditedVideoTemplate(
      videoId: $videoId
      templateId: $templateId
      formData: $formData
      isEdited: $isEdited
    )
  }
`;

export const GET_CUSTOM_ASSET_IMAGES = gql`
  query getCustomAssetImages(
    $limit: Int!
    $offset: Int!
    $fileType: String!
    $uuid: String!
    $packageType: packageType
    $chapter: String
  ) {
    getCustomAssetImages(
      limit: $limit
      offset: $offset
      fileType: $fileType
      uuid: $uuid
      packageType: $packageType
      chapter: $chapter
    )
  }
`;

export const GET_ASSET_LISTING = gql`
  query listVideos(
    $startDate: String
    $endDate: String
    $search: String
    $limit: Int!
    $offset: Int!
  ) {
    listVideos(
      limit: $limit
      offset: $offset
      search: $search
      endDate: $endDate
      startDate: $startDate
    )
  }
`;

export const RENDERED_VIDEO_SUBSCRIPTION = gql`
  subscription renderedVideo {
    renderedVideo
  }
`;

export const LIST_UPDATED_VIDEOS = gql`
  subscription listUpdatedVideos {
    listUpdatedVideos
  }
`;

export const GET_PURCHASED_VIDEO_STATUS = gql`
  query getPurchasedVideoStatus($uuid: String!) {
    getPurchasedVideoStatus(uuid: $uuid)
  }
`;

export const GET_ALL_CREDENTIALS_BY_PROJECT = gql`
  query getAllCredentialsByProject($projectId: Int!) {
    getAllCredentialsByProject(projectId: $projectId)
  }
`;
