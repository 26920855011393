import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const RedirectConfirmationModal = ({
  handleOnConfirm,
  showConfirmationModal,
  setShowConfirmationModal,
}) => {
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={showConfirmationModal}
        toggle={closeConfirmationModal}
        centered
        style={{ width: '330px' }}
      >
        <ModalBody>
          <p className="font-size-14">
            Do you want to redirect to management portal?
          </p>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              color="secondary"
              onClick={closeConfirmationModal}
              size="sm"
              className="mr-2"
            >
              No
            </Button>
            <a
              href="/management-portal/projects/active-projects"
              target="_parent"
            >
              <Button
                color="primary"
                type="link"
                size="sm"
                onClick={closeConfirmationModal}
              >
                Yes
              </Button>
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RedirectConfirmationModal;
