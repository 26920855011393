import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonDropdown,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap';
import slackIMG from 'assets/slack.png';
import ProjectFolderModal from 'pages/SalesPersonPortal/common/ProjectFolderModal';
import hubspotIcon from 'assets/hubspot-icon.png';
import folderIcon from 'assets/folder-fill.svg';
import asanaIMG from 'assets/asana.png';
import HSLink from 'assets/HSLink.png';
import { SLACK_LINK } from 'constants/appConstants';

const DealDetailsHead = ({
  deal: {
    dealStage,
    projectEditor,
    projectManager,
    hsLink,
    slackLink,
    hsQuoteLinks,
    publishedVideoLink,
    projectPilot,
    projectId,
    projectAsanaLink,
  },
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [isProjectFolderModalOpen, setProjectFolderModal] = useState(false);
  const [toggle, setToggle] = useState(true);

  const toggleProjectFolderModal = (status) => {
    setProjectFolderModal(status);
  };

  const toggleBrokerDropdown = () => setOpen(!dropdownOpen);

  const renderQuoteCount = () => {
    return (
      <div className="">
        <ButtonDropdown
          className="bg-white"
          isOpen={dropdownOpen}
          toggle={toggleBrokerDropdown}
        >
          <DropdownToggle className="bg-white border-0 text-dark count-btn font-size-14 py-1 px-2 border-radius-8px mt-n1">
            + {hsQuoteLinks.length - 1} more links
          </DropdownToggle>
          <DropdownMenu className="border-radius-8px border-0 shadow-lg">
            {hsQuoteLinks.map((row, index) => {
              if (index > 0) {
                return (
                  <DropdownItem
                    key={`${row}_${index + 1}`}
                    className="py-0 px-1"
                  >
                    <div className="d-flex align-items-center flex-fill p-2">
                      <div className="ml-2 ">
                        <a
                          className="align-middle blue-color-link font-size-12 blue-color-link d-inline-block font-size-12 text-truncate width-90"
                          href={row}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {row}
                        </a>
                      </div>
                    </div>
                  </DropdownItem>
                );
              }
              return null;
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  };

  const renderQuotes = () => {
    const quoteLinks = hsQuoteLinks.map((item, index) => {
      if (index === 0) {
        return (
          <div className="list-inline-item m-0" key={`${item}_${index + 1}`}>
            <div>
              <a
                href={item}
                rel="noreferrer"
                target="_blank"
                className="show-elipsis-100"
                title={item}
              >
                <img src={HSLink} alt="HS Link" height={20} />
              </a>
            </div>
          </div>
        );
      }
      return null;
    });
    return quoteLinks;
  };

  const renderQuoteLinks = () => {
    if (hsQuoteLinks.length) {
      return (
        <div>
          <div>
            <ul className="list-inline mb-0 mr-0">{renderQuotes()}</ul>
          </div>
          {hsQuoteLinks.length > 1 ? renderQuoteCount() : ''}
        </div>
      );
    }
    return (
      <>
        <div className="flex-fill w-75">
          <img src={HSLink} alt="HS Link" height={20} />
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <span>
            <Badge
              color="light"
              pill
              className="font-size-14 font-weight-normal"
            >
              {dealStage === 'closedwon' && 'Closed / won'}
            </Badge>
          </span>
          <Link
            to={publishedVideoLink || {}}
            type="button"
            className="btn-sm px-2 btn btn-outline-primary font-size-12 p-0 px-2"
            size="sm"
          >
            Published Video
          </Link>
        </div>
        <div className="pt-4">
          <h6 className="font-size-14">PM</h6>
          <div className="d-flex">
            <a
              href={slackLink || SLACK_LINK}
              rel="noreferrer"
              target="_blank"
              className="mb-0 ml-1"
            >
              <img src={slackIMG} alt={slackIMG} className="mr-2" />
            </a>
            <span className="show-elipsis-100">{projectManager || 'N/A'}</span>
          </div>
        </div>
        {/* <div className="pt-4">
          <h6 className="font-size-14">PILOT</h6>
          <div className="d-flex">
            <a
              href={slackLink || SLACK_LINK}
              rel="noreferrer"
              target="_blank"
              className="mb-0 ml-1"
            >
              <img src={slackIMG} alt={slackIMG} className="mr-2" />
            </a>
            <span className="show-elipsis-100">{projectPilot || 'N/A'}</span>
          </div>
        </div> */}
        <div className="pt-4">
          <h6 className="font-size-14">EDITOR</h6>
          <div className="d-flex">
            <a
              href={slackLink || SLACK_LINK}
              rel="noreferrer"
              target="_blank"
              className="mb-0 ml-1"
            >
              <img src={slackIMG} alt={slackIMG} className="mr-2" />
            </a>
            <span className="show-elipsis-100"> {projectEditor || 'N/A'}</span>
          </div>
        </div>

        <hr className="mb-3" />
        <div className="font-size-14 mr-1 d-flex">
          <span>
            {hsLink ? (
              <a
                href={hsLink}
                rel="noreferrer"
                target="_blank"
                className="show-elipsis-100"
                title={hsLink}
              >
                <img src={hubspotIcon} alt={hubspotIcon} className="mr-3" />
                {/* {hsLink} */}
              </a>
            ) : (
              <img src={hubspotIcon} alt={hubspotIcon} className="mr-3" />
            )}
          </span>
          <span>
            {projectAsanaLink ? (
              <a
                href={projectAsanaLink}
                rel="noreferrer"
                target="_blank"
                className="show-elipsis-100"
                title={projectAsanaLink}
              >
                <img src={asanaIMG} alt={asanaIMG} className="mr-3" />{' '}
                {/* {projectAsanaLink} */}
              </a>
            ) : (
              <img src={asanaIMG} alt={asanaIMG} className="mr-3" />
            )}
          </span>
          <span>{renderQuoteLinks()}</span>
        </div>
        {/* <div className="font-size-14">
          {projectAsanaLink ? (
            <a
              href={projectAsanaLink}
              rel="noreferrer"
              target="_blank"
              className="show-elipsis-100"
            >
              <img src={asanaIMG} alt={asanaIMG} className="mr-1" />{' '}
              {projectAsanaLink}
            </a>
          ) : (
            <img src={asanaIMG} alt={asanaIMG} className="mr-1" />
          )}
        </div> */}
        {/* <hr />
        <div className="font-size-14">{renderQuoteLinks()}</div> */}
        <hr className="mt-2" />
        <Button
          type="button"
          onClick={() => toggleProjectFolderModal(true)}
          outline
          color="link"
          className="btn btn-sm font-size-14 px-0"
        >
          <img src={folderIcon} alt={folderIcon} className="mr-2" />
          Project Asset Folder
        </Button>
        <hr />
      </div>
      {isProjectFolderModalOpen && (
        <ProjectFolderModal
          isProjectFolderModalOpen={isProjectFolderModalOpen}
          toggleProjectFolderModal={toggleProjectFolderModal}
          projectId={projectId}
          toggle={toggle}
          setToggle={setToggle}
        />
      )}
    </>
  );
};
export default DealDetailsHead;
