import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
//
import VidPitchProvider from 'contexts/VidPitchContext';
import VidTechHeader from 'pages/Vidpitch/common/VidtechHeader';
import { GET_PURCHASED_VIDEO_STATUS } from 'pages/Vidpitch/VidPitchGraphQL';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { authContext } from 'contexts/AuthContext';
import Preview from '../Preview';
import MultiStepWrapper from './components/MultiStepWrapper';

const MultiStepModifyVideo = () => {
  const { uuid } = useParams();
  const { auth } = useContext(authContext);
  const [videoURL, setVideoURL] = useState();
  const [videoId, setVideoId] = useState(-1);
  const [assetCrexiId, setAssetCrexiId] = useState(-1);
  const [isVideoPurchased, setVideoPurchasedStatus] = useState(false);

  const { loading, data, error } = useQuery(GET_PURCHASED_VIDEO_STATUS, {
    variables: {
      uuid,
    },
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && !loading) {
      if (data.getPurchasedVideoStatus) {
        const { id, isVideoPurchased, assetCrexiId, s3Link } =
          data.getPurchasedVideoStatus;
        setVideoId(id);
        setVideoURL(s3Link);
        setAssetCrexiId(assetCrexiId);
        setVideoPurchasedStatus(isVideoPurchased);
      }
    }
  }, [data, loading]);

  const renderBackToHome = () => {
    return (
      <Link
        to={auth?.data?.role ? '/assets-listing' : `/${uuid}/video-draft`}
        className="mt-2 ml-2 temp-button btn btn-outline-primary"
      >
        Back To Home
      </Link>
    );
  };

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <>
        <NoData customMessage={error.message} />
        {renderBackToHome()}
      </>
    );
  }

  return (
    <>
      <Helmet defaultTitle="VidTech" />
      <VidPitchProvider>
        <VidTechHeader userRole={auth?.data?.role} />
        <Row className="m-0">
          {!isVideoPurchased ? (
            <>
              <Col md={5} className="p-0 vidpitch-height bg-white border">
                <MultiStepWrapper
                  uuid={uuid}
                  videoId={videoId}
                  videoURL={videoURL}
                  assetCrexiId={assetCrexiId}
                />
              </Col>
              <Col md={7} className="vidpitch-height v-background-color mt-1">
                <Preview />
              </Col>
            </>
          ) : (
            <Col className="p-0 vidpitch-height bg-white border">
              <NoData customMessage="Video cannot be modified, as this video is already purchased." />
              {renderBackToHome()}
            </Col>
          )}
        </Row>
      </VidPitchProvider>
    </>
  );
};

export default MultiStepModifyVideo;
