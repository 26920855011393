import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import DrawerRight from 'components/DrawerRight';
import { extractSubDomain } from 'utils/helper';
import AppVidPitchView from './AppVidPitchView';
import AppVideomsView from './AppVideomsView';

const AppContainer = () => {
  const subDomain = extractSubDomain();

  return (
    <BrowserRouter>
      <React.Suspense fallback="">
        <Switch>
          {subDomain === process.env.REACT_APP_VIDPITCH_KEYWORD && (
            <Route component={AppVidPitchView} />
          )}
          {subDomain !== process.env.REACT_APP_VIDPITCH_KEYWORD && (
            <Route component={AppVideomsView} />
          )}
        </Switch>
        <DrawerRight />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppContainer;
