import { FLAG_TYPES, PRIORITIES } from 'pages/BrokersDirectory/constant';

export const rowStyle2 = (row) => {
  const style = {};
  if (
    row.company === undefined ||
    row.email === null ||
    row.phone.length === 0
  ) {
    style.backgroundColor = '#f8e7e9';
  } else {
    style.backgroundColor = '#f9f9fb';
  }
  return style;
};

const handleOnSelect = (row) => {
  const { firstName, lastName, company } = row;
  window.open(
    `https://www.google.com/search?q=${firstName}+${lastName}+${company}`,
    '_blank'
  );
};

export const searchOnGoogleOnSelectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  hideSelectColumn: true,
  onSelect: handleOnSelect,
};

export const getRowClass = (priority, flagType) => {
  const { REQUIRE_EVALUATION } = FLAG_TYPES;
  const { PRIORITY1, PRIORITY2, PRIORITY3 } = PRIORITIES;
  let classString = '';
  if (priority === PRIORITY1) {
    classString = 'tr-priority-1';
  }
  if (priority === PRIORITY2) {
    classString = 'tr-priority-2';
  }
  if (priority === PRIORITY3) {
    classString = 'tr-priority-3';
  }
  if (flagType === REQUIRE_EVALUATION) classString += ' text-danger';
  return classString;
};
