import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSync } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Collapse, Input, Nav } from 'reactstrap';
import AWSFolderAssetsProvider from 'contexts/GetAWSFolderAssets';
import { toast } from 'react-toastify';
import activeFill from 'assets/refresh-fill.svg';
//
import NoData from 'common/NoData';
import {
  DEAL_DETAILS,
  SYNC_DEAL_ASSOCIATION,
  SYNC_DEAL_ASSOCIATION_SUBSCRIPTION,
} from 'pages/SalesPersonPortal/SalesPersonPortalGraphQL';
import PageLoader from 'pages/Search/common/PageLoader';
import { parseToInteger } from 'utils/helper';
import AboutThisDeal from './components/AboutThisDeal';
import Company from './components/Company';
import Contacts from './components/Contacts';
import DealDetailsHead from './components/DealDetailsHead';
import DealNotes from './components/DealNotes';
import Packages from './components/Packages';
import Payment from './components/Payment';
import ProjectDetailModal from './components/ProjectDetailModal';
import Attachments from './components/Attachments';
import QuoteURL from './components/QuoteURL';

const DealDetails = ({ component, ...rest }) => {
  const { params } = rest.match;
  const { projectId } = params;
  const history = useHistory();
  const [isProjectDetailModalOpen, setProjectDetailModal] = useState(false);
  const [isDealSyncing, setDealSyncing] = useState(false);

  const toggleProjectDetailModal = (status) => {
    setProjectDetailModal(status);
  };

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    DEAL_DETAILS,
    {
      variables: {
        projectId: parseToInteger(projectId),
      },
      fetchPolicy: 'network-only',
    }
  );

  const subscribeToDealData = () => {
    subscribeToMore({
      document: SYNC_DEAL_ASSOCIATION_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.syncDealAssociation &&
          subscriptionData.data.syncDealAssociation.data &&
          subscriptionData.data.syncDealAssociation.data.dealData
        ) {
          refetch();
          setDealSyncing(false);
          toast.success('Deal Association Synced.');
        }
      },
    });
  };

  useEffect(() => {
    subscribeToDealData();
  }, []);

  const [syncDealAssociations] = useMutation(SYNC_DEAL_ASSOCIATION, {
    onCompleted: async () => {
      setDealSyncing(true);
    },
    onError: ({ message }) => {
      toast.error(message);
      setDealSyncing(false);
    },
  });

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;
  if (!data) {
    return <NoData />;
  }

  const { deal } = data;

  const goBackToList = () => {
    history.push('/sales-portal/deals');
  };

  const handleDealSync = () => {
    syncDealAssociations({
      variables: {
        dealId: deal.dealId,
      },
    });
  };

  return (
    <>
      <AWSFolderAssetsProvider>
        {/* assetLocation={uploadLocation} */}
        <div className="bg-grey-upload ">
          <div className="width-90 m-auto py-2">
            <Row className="align-items-center">
              <Col md={8}>
                <Button
                  color="link"
                  onClick={goBackToList}
                  size="sm"
                  className="pt-0"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
                  <h6
                    className="max-width-500px text-truncate d-inline-block deal-name-btn mb-0"
                    title={deal.dealName}
                  >
                    {deal.dealName}
                  </h6>
                </Button>

                <Button
                  type="button"
                  color="link"
                  size="sm"
                  className="px-4 btn ml-4"
                  onClick={() => toggleProjectDetailModal(true)}
                >
                  View Project Details
                </Button>
                <Button
                  disabled={isDealSyncing}
                  type="button"
                  color="primary"
                  outline
                  className="btn-sm btn ml-2 sync-button"
                  onClick={() => handleDealSync()}
                >
                  Deal Sync
                  <FontAwesomeIcon
                    icon={faSync}
                    className={isDealSyncing ? 'rotate' : ''}
                  />
                </Button>
              </Col>
              <Col md={4}>
                <div className="text-right">
                  Status:
                  <span className="active-td border border-radius-8px badge badge-secondary d-inline-block ml-1">
                    <img
                      src={activeFill}
                      alt="active td"
                      className="align-middle mr-1"
                    />

                    {deal.status}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="width-90 m-auto">
          <Row>
            <Col md="3" className="pt-3 border-right">
              <div>
                <DealDetailsHead deal={deal} />
                <Payment deal={deal} refetchDealDetails={refetch} />
                <QuoteURL dealId={deal.dealHsObjectId} />
              </div>
            </Col>
            <Col md="6" className="pt-3">
              <div className="bg-about-this-deal mb-3 bg-white">
                <AboutThisDeal deal={deal} />
              </div>
              <Contacts contacts={deal.contacts} />
              <Company company={deal.company} />
              <Attachments projectId={projectId} />
              <DealNotes deal={deal} />
            </Col>
            <Col md="3" className="pt-3 bg-white">
              <Packages deal={deal} />
            </Col>
          </Row>
        </div>
        {isProjectDetailModalOpen && (
          <ProjectDetailModal
            name={deal.dealName}
            isProjectDetailModalOpen={isProjectDetailModalOpen}
            toggleProjectDetailModal={toggleProjectDetailModal}
          />
        )}
      </AWSFolderAssetsProvider>
    </>
  );
};
export default DealDetails;
