import React, { useEffect, useRef } from 'react';
import NoData from 'common/NoData';
import { dateTimeFormatter } from 'utils/helper';
import { Badge } from 'reactstrap';
import { FOLDER_TYPES } from 'pages/Pilot/constant';

const ListNotes = ({ listNotes }) => {
  const notesRef = useRef();

  useEffect(() => {
    if (listNotes.length) {
      notesRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [listNotes]);

  const renderNotes = () => {
    if (!listNotes.length)
      return (
        <div className="pr-2">
          <NoData customMessage="No notes available" />
        </div>
      );
    return listNotes.map(
      ({
        id,
        notesUserRole,
        noteText,
        createdAt,
        notesUsername,
        pilot_task: { title, folderTypes },
      }) => {
        return (
          <div key={id} ref={notesRef} className="mt-3">
            <span>
              <b className="text-capitalize">
                {`(${notesUserRole}) ${notesUsername || ''}`} ,{' '}
              </b>{' '}
              {dateTimeFormatter(createdAt, 'MM/DD/YYYY hh:mm a')}
            </span>
            <div>
              {folderTypes !== FOLDER_TYPES.DEFAULT && (
                <Badge
                  color={
                    folderTypes === FOLDER_TYPES.CUSTOM ? `success` : 'warning'
                  }
                  className="border-radius-8px font-weight-500 px-2 py-1 mb-2"
                >
                  {`${title}`}
                </Badge>
              )}
            </div>
            <p>{noteText}</p>
          </div>
        );
      }
    );
  };

  return <>{renderNotes()}</>;
};

export default ListNotes;
