import React from 'react';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
//
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import Header from 'pages/PMPortal/common/Header';
// import ProjectRequirement from './components/ProjectRequirement';
// import RequirementSidebar from './components/RequirementSidebar';
import { EDITOR_PROJECT_DETAILS } from '../../ProjectsGraphQL';

const ProjectInvitation = () => {
  const { projectId } = useParams();

  const renderErrorPage = (message) => {
    return (
      <>
        <Header heading="Invitation" />
        <NoData customMessage={message} />
      </>
    );
  };

  const { loading, data, error } = useQuery(EDITOR_PROJECT_DETAILS, {
    variables: {
      projectId: parseInt(projectId, 10),
    },
  });

  if (loading) return <PageLoader />;
  if (error) return renderErrorPage(error.message);

  if (data && data.editorProjectDetails) {
    const {
      editorProjectDetails: { projectName },
    } = data;
    return (
      <>
        <Header heading={projectName} showBackButton/>
        <Row className="py-4">
          <Col xs="3" className="bg-light">
            {/* <RequirementSidebar projectId={projectId} requirementData={data} /> */}
          </Col>
          {/* <Col xs="9" className="p-0">
            <div className="py-2 px-4">
              <p className="font-size-16 font-weight-600 mb-0">
                Project Examples
              </p>
            </div>
            <hr className="mt-0" />
            <div className="px-4">
              <ProjectRequirement requirements={data} />
            </div>
          </Col> */}
        </Row>
      </>
    );
  }

  return renderErrorPage('Currently, no project requirement to show.');
};

export default ProjectInvitation;
