import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, Row, Col, Badge } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
//
import { authContext } from 'contexts/AuthContext';
import {
  UPDATE_PROFILE,
  GET_USER_DETAILS,
} from 'pages/Pilot/PilotPortalGraphQL';
import PageLoader from 'common/PageLoader';
import Header from 'common/Header';
import { ROLES } from 'constants/role';
import { camelCase } from 'lodash';
import { EDITOR_REGISTRATION_VALUES } from 'pages/EditorPortal/constant';
import Step1 from './components/GeneralDetails';
import Step2 from './components/SkillsTwo';
import Step3 from './components/ProfileComplete';
import { profileValidationSchema } from './profileValidationSchema';

const steps = ['Step 1', 'Step 2'];

function renderStepContent(step, properties, isEditProfile) {
  switch (step) {
    case 0:
      return <Step1 {...properties} isEditProfile={isEditProfile} />;
    case 1:
      return <Step2 {...properties} />;
    default:
      return <div>Not Found</div>;
  }
}

const EditorProfile = ({ isModal, editorID }) => {
  let registrationInitialValues = {};
  let isEditProfile = false;
  const {
    auth: { data: authData },
    setAuthPhoto,
  } = useContext(authContext);
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  if (pathname.includes('edit-profile')) {
    isEditProfile = true;
  }

  const [activeStep, setActiveStep] = useState(0);
  const { email, firstName } = authData;

  registrationInitialValues = {
    firstName,
    email,
    ...EDITOR_REGISTRATION_VALUES,
  };

  const [updateEditorProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: async ({ updateProfile }) => {
      setActiveStep(activeStep + 1);
      toast.success(updateProfile.message);
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  let userId = null;

  if (!isModal) {
    userId = editorID;
  } else if (isEditProfile) {
    userId = authData?.userId;
  }

  const { loading, data } = useQuery(GET_USER_DETAILS, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (data && data.getUserDetails) {
    const {
      createdAt,
      emailVerified,
      fullName,
      has_password_reset: hasPwdReset,
      id,
      isProfileUpdated,
      reset_token: resetToken,
      rolePermissionId,
      token,
      updatedAt,
      userSlackId,
      role,
      password,
      camera,
      companyName,
      editorSkills,
      coverageArea,
      dronePlatform,
      faaExpireDate,
      faaLicenseNumber,
      insuranceExpireDate,
      insurancePolicyNumber,
      isEditorProfileUpdated,
      offerInteriorDronePhotos,
      offerMatterPortOrOtherTours,
      offerProfessionalInteriorDronePhotos,
      offerThermalDrone,
      userUuid,
      ...rest
    } = data.getUserDetails;

    const tempEditorSkills = { ...EDITOR_REGISTRATION_VALUES.editorSkills };
    const tempEditorSkillsRatings = {
      ...EDITOR_REGISTRATION_VALUES.editorSkillsRatings,
    };

    Object.values(editorSkills).forEach((skillItem) => {
      const key = camelCase(skillItem.skill);
      if (key in tempEditorSkills) {
        tempEditorSkills[key] = [skillItem.skill];
        tempEditorSkillsRatings[key] = skillItem.ratings;
      }
    });

    registrationInitialValues = {
      editorSkills,
      ...rest,
    };
  }

  const isLastStep = activeStep === steps.length - 1;

  const submitForm = async (values, actions) => {
    const { email, editorSkills, editorSkillsRatings, ...signUpDetails } =
      values;
    // const editorSkill = formatEditorSkills(editorSkillsRatings);
    setAuthPhoto(signUpDetails.photo);
    try {
      await updateEditorProfile({
        variables: {
          role: ROLES.VIDEOMS_EDITOR,
          email,
          input: {
            isEditorProfileUpdated: true,
            editorSkills,
            ...signUpDetails,
          },
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const renderBackToProjects = () => {
    if (isEditProfile && activeStep < 2)
      return (
        <div className="container-fluid pl-3 py-3">
          <Link
            className="d-inline-block font-size-14 font-weight-500 pb-1"
            to="/editor-portal/projects/project-list"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
            Back to Projects
          </Link>
        </div>
      );

    return '';
  };

  const tabNavigate = (isValid, step) => {
    if (isValid) {
      setActiveStep(step);
    }
  };

  return (
    <>
      {isModal && <Header />}
      {renderBackToProjects()}
      <hr className="mt-0" />
      <Formik
        initialValues={registrationInitialValues}
        validateOnMount
        validationSchema={profileValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <div className="m-auto container mb-4">
              {activeStep === steps.length ? (
                <Row>
                  <Col md={4}>
                    <Card className="bg-light px-3 max-w-400px">
                      <div
                        className="pt-4 pb-2"
                        role="button"
                        tabIndex={0}
                        onClick={() => setActiveStep(0)}
                        onKeyDown={() => {
                          setActiveStep(0);
                        }}
                      >
                        <span>
                          <Badge
                            color="success"
                            pill
                            className="py-2 px-2 border-radius-8px"
                          >
                            1
                          </Badge>
                        </span>
                        <span className="font-size-14 pl-2 ">
                          General Details
                        </span>
                      </div>
                      <div
                        className="my-3"
                        role="button"
                        tabIndex={0}
                        onClick={() => setActiveStep(1)}
                        onKeyDown={() => {
                          setActiveStep(1);
                        }}
                      >
                        <span>
                          <Badge
                            color="success"
                            pill
                            className="py-2 px-2 border-radius-8px"
                          >
                            2
                          </Badge>
                        </span>
                        <span className="font-size-14 pl-2">Skills</span>
                      </div>
                    </Card>
                  </Col>
                  <Col md={8}>
                    <Card className="border-radius-8px shadow border-0 w-100">
                      <CardBody className="border-0 px-4">
                        <Step3
                          isEditProfile={isEditProfile}
                          isModal={isModal}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={4}>
                    <Card className="bg-light max-w-400px">
                      <div
                        className={
                          activeStep === 0
                            ? 'px-4 py-3 activeStep'
                            : 'px-4 py-3'
                        }
                        role="button"
                        tabIndex={0}
                        onClick={() => tabNavigate(props.isValid, 0)}
                        onKeyDown={() => {
                          setActiveStep(0);
                        }}
                      >
                        <span>
                          <Badge
                            color={`${activeStep > 0 ? 'success' : 'primary'}`}
                            pill
                            className="py-2 px-2 border-radius-8px"
                          >
                            1
                          </Badge>
                        </span>
                        <span className="font-size-14 pl-2">
                          General Details
                        </span>
                      </div>
                      <div
                        className={
                          activeStep === 1
                            ? 'px-4 py-3 activeStep'
                            : 'px-4 py-3'
                        }
                        role="button"
                        tabIndex={0}
                        onClick={() => tabNavigate(props.isValid, 1)}
                        onKeyDown={() => {
                          setActiveStep(1);
                        }}
                      >
                        <span>
                          <Badge
                            color="primary"
                            pill
                            className="py-2 px-2 border-radius-8px"
                          >
                            2
                          </Badge>
                        </span>
                        <span className="font-size-14 pl-2">Skills</span>
                      </div>
                    </Card>
                  </Col>
                  <Col md={8} className="mb-4">
                    <Card className="border-radius-8px shadow border-0">
                      <CardBody className="border-0 px-4">
                        {renderStepContent(activeStep, props, isEditProfile)}
                        <div className="mt-3">
                          <div className="text-right ">
                            <Button
                              color="primary"
                              type="submit"
                              className="btn btn-sm font-size-13 px-4"
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditorProfile;
