import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardBody,
  Card,
  FormGroup,
  Label,
  Badge,
  InputGroup,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import medal from 'assets/medal.png';
import FormikRating from 'components/Fields/FormikRating';
import FormikInputField from 'components/Fields/FormikInputField';
import { toast } from 'react-toastify';
import { dateTimeFormatter } from 'utils/helper';
import {
  GET_PROJECT_DETAILS,
  PM_TO_EDITOR_FEEDBACK,
} from '../../ProjectsGraphQL';

const ProjectFeedback = ({ feedbackModal, toggleFeedbackModal, projectId }) => {
  const { data, loading, error } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const history = useHistory();
  const { feedbackStatus } = useParams();

  const [pmToEditorFeedback] = useMutation(PM_TO_EDITOR_FEEDBACK, {
    onCompleted: async ({ pmToEditorFeedback }) => {
      toast.success(pmToEditorFeedback.message);
      toggleFeedbackModal(false);
      if (feedbackStatus === 'feedback') {
        history.push(`/pm-portal/projects/${projectId}/folders`);
      }
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const handleSubmit = async (values, actions) => {
    const {
      easeOfCollabrationReview,
      commentsOnEditor,
      projectFinishOnTimeReview,
      timeBadge,
      projectQualityReview,
    } = values;

    try {
      await pmToEditorFeedback({
        variables: {
          projectId,
          timeBadge,
          projectReviews: {
            projectQualityReview,
            projectFinishOnTimeReview,
            easeOfCollabrationReview,
          },
          commentsOnEditor: commentsOnEditor.trim(),
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
    actions.setSubmitting(false);
  };

  if (data) {
    const {
      getProjectDetails: {
        projectName,
        projectManager,
        projectStartDate,
        projectCompleteDate,
        projectEditor,
        projectEditorCountry,
        projectEditorPhoto,
      },
    } = data;

    if (error) return <div>{error.message}</div>;

    return (
      <>
        <Modal
          isOpen={feedbackModal}
          toggle={() => toggleFeedbackModal(false)}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader>Feedback</ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                commentsOnEditor: '',
                projectReviews: '',
                timeBadge: false,
              }}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);
              }}
              children={({ handleSubmit, values }) => {
                return (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Row>
                      <Col md={4}>
                        <Card>
                          <CardBody className="bg-about-this-deal px-5">
                            <div className="text-center mt-4">
                              {projectEditorPhoto ? (
                                <img
                                  src={projectEditorPhoto}
                                  alt=""
                                  width="100"
                                  height="100"
                                  className="rounded-circle"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  size="8x"
                                  className="mr-2"
                                  icon={faUserCircle}
                                  color="#3AB42B"
                                />
                              )}
                            </div>
                            <p className="text-center font-weight-bold font-size-18 mt-2 mb-0">
                              {projectEditor || 'N/A'}
                            </p>
                            <p className="text-muted text-center mb-2">
                              {projectEditorCountry || 'N/A'}
                            </p>
                            <div className="text-center mb-4">
                              <Badge className="invite-badge" pill>
                                EDITOR
                              </Badge>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={8}>
                        <div>
                          <p className="m-0">Project</p>
                          <p className="font-size-16 font-weight-bold mb-2">
                            {projectName || 'N/A'}
                          </p>
                          <p>
                            <span>PM:</span>
                            <span className="font-weight-bold ml-2">
                              {projectManager || 'N/A'}
                            </span>
                          </p>
                        </div>
                        <div>
                          <hr />
                          <Row>
                            <Col>
                              <span className="font-weight-bold">
                                Date Assigned
                              </span>
                              <span className="ml-2">
                                {dateTimeFormatter(projectStartDate) || 'N/A'}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">
                                Delivery Date
                              </span>
                              <span className="ml-2">
                                {dateTimeFormatter(projectCompleteDate) ||
                                  'N/A'}
                              </span>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center">
                          <FormGroup check className="p-0">
                            <Field
                              type="checkbox"
                              id="timeBadge"
                              name="timeBadge"
                              className="pt-1 align-middle mt-1"
                            />
                            <Label check className="pl-1 align-middle">
                              <img src={medal} alt="" />
                              <span className="font-weight-bold">
                                Award Time Badge
                              </span>
                              <span className="px-2">
                                (Delivered project on Time)
                              </span>
                            </Label>
                          </FormGroup>
                        </div>
                        <hr />
                        <div>
                          <Row className="mb-3">
                            <Col className="font-weight-bold">
                              Project Quality
                            </Col>
                            <Col>
                              <FormikRating fieldName="projectQualityReview" />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="font-weight-bold">
                              Project Finished on Time
                            </Col>
                            <Col>
                              <FormikRating fieldName="projectFinishOnTimeReview" />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col className="font-weight-bold">
                              Ease of Collaboration
                            </Col>
                            <Col>
                              <FormikRating fieldName="easeOfCollabrationReview" />
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    </Row>
                    <div>
                      <Label>Comments</Label>
                      <InputGroup>
                        <Field
                          id="commentsOnEditor"
                          name="commentsOnEditor"
                          type="textarea"
                          component={FormikInputField}
                        />
                      </InputGroup>
                    </div>
                    <div className="text-right mt-2">
                      {!feedbackStatus && (
                        <Button
                          outline
                          color="primary"
                          className=" btn-sm px-2 font-size-13"
                          onClick={() => toggleFeedbackModal(false)}
                        >
                          Cancel
                        </Button>
                      )}

                      <Button
                        type="submit"
                        color="primary"
                        className="btn-sm px-3 font-size-13 ml-2"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }

  return null;
};

export default ProjectFeedback;
