import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { useQueryParams } from 'pages/Search/hooks';
import FormikInputField from 'components/Fields/FormikInputField';
import { changeSalesFilters } from 'pages/Search/SalesActions';

const Filters = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [params] = useQueryParams();

  const [filters, setFilters] = useState({
    firstName: params.get('firstName') || '',
    lastName: params.get('lastName') || '',
    companyName: params.get('companyName') || '',
  });

  useEffect(() => {
    const filterObject = {
      firstName: params.get('firstName') || '',
      lastName: params.get('lastName') || '',
      companyName: params.get('companyName') || '',
    };
    setFilters(filterObject);
    dispatch(
      changeSalesFilters({
        searchFilters: {
          ...filterObject,
        },
      })
    );
  }, [history.location]);

  const handleResetForm = (setFieldValue) => {
    setFieldValue('firstName', '');
    setFieldValue('lastName', '');
    setFieldValue('companyName', '');
    dispatch(
      changeSalesFilters({
        filters: {
          firstName: '',
          lastName: '',
          companyName: '',
          limit: 10,
          offset: 0,
          page: 1,
        },
      })
    );
    history.push('/crexi-search/results');
  };

  return (
    <Card className="border-radius-8px border-0 grey-card mb-3 my-3">
      <CardBody className="px-3 py-3">
        <CardTitle tag="h6" className="font-weight-bold">
          Filters
        </CardTitle>
        <Formik
          enableReinitialize
          initialValues={filters}
          onSubmit={async (values, actions) => {
            let string = '';

            Object.keys(values).map((key) => {
              if (values[key]) {
                string += `${key}=${values[key]}&`;
              }
              return key;
            });
            string = params.get('auctionData')
              ? `${string}auctionData=${params.get('auctionData')}&`
              : string;

            await dispatch(
              changeSalesFilters({
                searchFilters: { ...values, limit: 10, offset: 0, page: 1 },
              })
            );
            history.push({
              pathname: history.location.pathname,
              search: string.slice(0, -1),
              state: history.location.state,
            });

            setTimeout(() => {
              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Field
                id="firstName"
                name="firstName"
                label="First Name"
                component={FormikInputField}
              />
              <Field
                id="lastName"
                name="lastName"
                label="Last Name"
                component={FormikInputField}
              />
              <Field
                id="companyName"
                name="companyName"
                label="Company Name"
                component={FormikInputField}
              />
              <Button
                className="float-right px-3 font-size-14 font-weight-500 m-1"
                color="primary"
                type="submit"
              >
                Search
              </Button>
              <Button
                outline
                className="float-right px-3 font-size-14 font-weight-500 m-1"
                color="primary"
                onClick={() => handleResetForm(setFieldValue)}
              >
                Reset
              </Button>
              <div className="clearfix">&nbsp;</div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default Filters;
