import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import ErrorBoundary from 'common/ErrorBoundary';
import Header from 'pages/ManagementPortal/common/Header';
import { PROJECT_DETAIL_PAGE_HEADINGS } from 'constants/appConstants';
import { GET_PROJECT_DETAIL } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import ProjectDetailContent from './components/ProjectDetailContent';

const ProjectDetailWrapper = () => {
  const { projectId } = useParams();
  const parsedProjectId = parseInt(projectId, 10);
  const { data, loading, error, refetch } = useQuery(GET_PROJECT_DETAIL, {
    variables: { projectId: parsedProjectId },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;

  if (error) return <div>{error.message}</div>;
  if (data) {
    const { getTaskDetails } = data;
    if (!getTaskDetails.id) {
      return (
        <NoData customMessage="Your project has been initiated but not yet started. We will send an email when your project starts." />
      );
    }
    return (
      <ProjectDetailContent
        parsedProjectId={parsedProjectId}
        projectData={getTaskDetails}
        refetch={refetch}
      />
    );
  }
  return null;
};

const ProjectDetail = () => {
  const location = useLocation();
  const { pathname } = location;
  const { ACTIVE, NEED_MY_ATTENTION, COMPLETED } = PROJECT_DETAIL_PAGE_HEADINGS;
  const getHeading = () => {
    if (pathname.includes(NEED_MY_ATTENTION.slug)) {
      return NEED_MY_ATTENTION.label;
    }
    if (pathname.includes(ACTIVE.slug)) {
      return ACTIVE.label;
    }
    return COMPLETED.label;
  };
  return (
    <>
      <Header heading={getHeading()} showBackButton />
      <div className="management-border">
        <ErrorBoundary>
          <ProjectDetailWrapper />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default ProjectDetail;
