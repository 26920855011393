import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_FOLDER_ASSETS,
  PILOT_ASSET_UPDATED,
  UPLOADED_ASSETS_BY_EDITOR,
} from 'pages/PMPortal/components/Projects/ProjectsGraphQL';

export const awsFolderAssetsContext = React.createContext();

const AWSFolderAssetsProvider = ({ children, assetLocation, planType }) => {
  const [awsfiles, setFiles] = useState([]);

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    GET_FOLDER_ASSETS,
    {
      variables: {
        filePath: assetLocation,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (data && data.getAwsFiles) {
      setFiles(data.getAwsFiles);
    }
  }, [data]);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: PILOT_ASSET_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.pilotAssetUpdated) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: UPLOADED_ASSETS_BY_EDITOR,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.uploadedAssetsByEditor) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  return (
    <awsFolderAssetsContext.Provider
      value={{
        error,
        loading,
        awsfiles,
        assetLocation,
        refetchAssets: refetch,
        projectPlanType: planType || null 
      }}
    >
      {children}
    </awsFolderAssetsContext.Provider>
  );
};

export default AWSFolderAssetsProvider;
