import React from 'react';
import { Input, InputGroupAddon } from 'reactstrap';
import DatePicker from 'react-datepicker';

const SearchDeal = ({
  searchParams,
  searchString,
  handleSearchParams,
  handleSearchString,
}) => {
  return (
    <div className="input-group updated-record-search">
      <InputGroupAddon addonType="prepend">
        <Input
          type="select"
          name="select"
          value={searchParams}
          onChange={(e) => handleSearchParams(e.target.value)}
        >
          <option value="">All</option>
          <option value="dealName">Deal Name</option>
          <option value="status">Status</option>
          <option value="startDate">Start Date</option>
        </Input>
      </InputGroupAddon>
      {searchParams !== 'startDate' && (
        <Input
          type="search"
          name="searchText"
          id="searchText"
          placeholder="Search"
          value={searchString}
          onChange={(e) => handleSearchString(e.target.value)}
        />
      )}
      {searchParams === 'startDate' && (
        <DatePicker
          selected={searchString}
          onChange={(date) => handleSearchString(date)}
          dateFormat="MM/dd/yyyy"
          className="form-control"
        />
      )}
    </div>
  );
};
export default SearchDeal;
