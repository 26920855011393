import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Header from 'pages/ManagementPortal/common/Header';
import ErrorBoundary from 'common/ErrorBoundary';
import ProjectTableProvider from './components/ProjectTableProvider';

const FILTER = {
  trackStatus: 'COMPLETED',
  limit: 10,
  offset: 0,
  page: 1,
};

const Completed = () => {
  const { completedProjectInfo } = useSelector(
    (state) => state.managementPortal
  );
  const {
    previousPageInfo: { page, offset },
  } = completedProjectInfo;

  const [filters, setFilters] = useState(FILTER);

  useEffect(() => {
    setFilters({ ...filters, offset, page });
  }, [page, offset]);

  return (
    <>
      <Header heading="Projects" />
      <div>
        <Row className="align-items-center">
          <Col md="6">
            <h6 className="my-3">Completed</h6>
          </Col>
        </Row>
        <div>
          <ErrorBoundary>
            <ProjectTableProvider filters={filters} setFilters={setFilters} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
export default Completed;
