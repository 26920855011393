import React from 'react';
import { useSubscription } from '@apollo/client';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { PROJECT_TASK_SUBSCRIPTION } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { VIDEO_RENDER_COUNT_DOWN } from 'pages/CrexiBronze/constant';
import { videoRenderTimer } from 'pages/CrexiBronze/CrexiBronzeAction';

const VideoRenderTimer = () => {
  const { data } = useSubscription(PROJECT_TASK_SUBSCRIPTION);

  const dispatch = useDispatch();
  const { videoRenderState } = useSelector((state) => state.crexiBronze);

  const countDownRenderer = ({ minutes, seconds }) => {
    if (!minutes && !seconds) {
      toast.info('Video rendering taking longer than expected');
      dispatch(
        videoRenderTimer({
          videoRenderState: {
            showVideoRenderTime: false,
            videoRenderTimeElapsed: '',
          },
        })
      );
    }
    return (
      <span>
        {`${minutes < 10 ? 0 : ''}${minutes} : ${
          seconds < 10 ? 0 : ''
        }${seconds}`}
      </span>
    );
  };
  const renderCountDownRenderer = () => {
    const modifiedTimeStamp = new Date(
      Date.parse(videoRenderState?.videoRenderTimeElapsed)
    );
    modifiedTimeStamp.setMinutes(
      modifiedTimeStamp.getMinutes() + VIDEO_RENDER_COUNT_DOWN
    );

    return <Countdown date={modifiedTimeStamp} renderer={countDownRenderer} />;
  };

  if (
    data?.bronzeVideoStatus?.data?.error &&
    data?.bronzeVideoStatus?.data?.status === 'failed' &&
    videoRenderState?.showVideoRenderTime
  ) {
    dispatch(
      videoRenderTimer({
        videoRenderState: {
          showVideoRenderTime: false,
          videoRenderTimeElapsed: '',
        },
      })
    );
  }

  if (
    data?.bronzeVideoStatus?.data?.completed &&
    videoRenderState?.showVideoRenderTime
  ) {
    toast.success('Video rendered successfully');
    dispatch(
      videoRenderTimer({
        videoRenderState: {
          showVideoRenderTime: false,
          videoRenderTimeElapsed: '',
        },
      })
    );
  }
  if (
    videoRenderState?.showVideoRenderTime &&
    videoRenderState?.videoRenderTimeElapsed
  ) {
    return (
      <div>
        <Card className="border-0 shadow-sm">
          <CardBody className="p-2 text-center d-flex text-primary">
            <b>Time Elapsed To Render Bronze Video -</b>
            &nbsp;
            <b className="font-size-14">{renderCountDownRenderer()}</b>
          </CardBody>
        </Card>
      </div>
    );
  }

  return null;
};

export default VideoRenderTimer;
