import React from 'react';
import { Table } from 'reactstrap';
import { dateTimeFormatter } from 'utils/helper';

const AboutThisDeal = ({
  deal: { dealHsObjectId, urgency, startDate, projectTargetCompletionDate },
}) => {
  return (
    <Table className="mb-0">
      <tbody>
        <tr>
          <th className="border-0 align-middle font-size-14">
            About <br/><span className='font-size-12'>this deal</span>
          </th>
          <td className="border-0">
            <label className="font-size-12 mb-0">Deal ID</label>
            <p className="mb-0 font-size-14 font-weight-500">
              {dealHsObjectId || 'NA'}
            </p>
          </td>
          <td className="border-0">
            <label className="font-size-12 mb-0">Urgency</label>
            <p className="mb-0 font-size-14 font-weight-500">
              {urgency || 'NA'}
            </p>
          </td>
          <td className="border-0">
            <label className="font-size-12 mb-0">Start Date</label>
            <p className="mb-0 font-size-14 font-weight-500">
              {dateTimeFormatter(startDate) || 'NA'}
            </p>
          </td>
          <td className="border-0">
            <label className="font-size-12 mb-0">
              Project Target Completion Date
            </label>
            <p className="mb-0 font-size-14 font-weight-500">
              {projectTargetCompletionDate || 'NA'}
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AboutThisDeal;
