import React, { useState } from 'react';
import { Field } from 'formik';
import { Button, InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'common/LoadingButton';
import { Link } from 'react-router-dom';
import eyeVisible from 'assets/eye-line.svg';
import eyeInvisible from 'assets/eye-off-line.svg';

const LoginForm = ({
  handleSubmit,
  isValid,
  isSubmitting,
  credentialStatus: { invalidValidEmail, invalidValidPassword },
}) => {
  const [isEyeVisible, setIsEyeVisible] = useState(false);

  const renderInvalidUserMessage = () => {
    if (invalidValidEmail)
      return (
        <div className="custom-error-msg">
          Unknown user ID. Please contact videoms at <br />
          <strong> 805-413-1032</strong> to change your user ID.
        </div>
      );
    return null;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputGroup className="mt-3" size="medium">
          <Field
            id="email"
            name="email"
            type="email"
            component={FormikInputField}
            label="Email"
            placeholder="Email"
          />
        </InputGroup>
        {renderInvalidUserMessage()}
        <InputGroup size="medium">
          <Field
            id="password"
            name="password"
            type={isEyeVisible ? 'text' : 'password'}
            component={FormikInputField}
            label="Password"
            placeholder="Password"
          />
          <Button
            type="button"
            className="eye-button"
            onClick={() => setIsEyeVisible(!isEyeVisible)}
          >
            {isEyeVisible ? (
              <img src={eyeVisible} alt="eye Visible" />
            ) : (
              <img src={eyeInvisible} alt="eye Invisible" />
            )}
          </Button>
        </InputGroup>
        {invalidValidPassword ? (
          <div className="custom-error-msg">Invalid Credentials.</div>
        ) : (
          ''
        )}

        <LoadingButton
          className="btn btn-primary btn-block font-weight-500"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          color="primary"
          block
          type="submit"
        >
          Login
        </LoadingButton>
        <div className="mt-4 text-center">
          <Link to="/forgot-password">Forgot password?</Link>
        </div>
      </form>
    </div>
  );
};
export default LoginForm;
