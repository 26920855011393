import Login from 'pages/Login';
import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const LoginModal = ({ isLogin, handleLoginModal }) => {
  return (
    <Modal
      isOpen={isLogin}
      toggle={handleLoginModal}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <Login isReviewed />
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
