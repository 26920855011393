import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Collapse, Nav } from 'reactstrap';
import ProjectListProvider from './components/ProjectListProvider';

const ProjectReviewNav = ({ slug, onClickActive, isActive }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const toggleSidebarList = () => setSidebarIsOpen(!sidebarIsOpen);
  return (
    <div className="frame-io-tabs portal-sidebar-list">
      <Nav vertical className="tab-ul row" onClick={() => onClickActive(2)}>
        <Button
          id="project-asset-toggler"
          className={`nav-link ${isActive === 2 ? 'active-state' : ''}`}
          onClick={toggleSidebarList}
        >
          Video Review
          {sidebarIsOpen ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              color="#000"
              className="float-right"
              size="lg"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              color="#000"
              className="float-right mt-1"
              size="lg"
            />
          )}
        </Button>
        {sidebarIsOpen ? (
          <Collapse isOpen={sidebarIsOpen}>
            <div className="project-asset-list">
              <Nav vertical>
                <ProjectListProvider slug={slug} />
              </Nav>
            </div>
          </Collapse>
        ) : null}
      </Nav>
    </div>
  );
};

export default ProjectReviewNav;
