import React from 'react';
import { Field, useFormikContext } from 'formik';
import { InputGroup, Label, Row, Col } from 'reactstrap';
import { SKILLS } from 'constants/appConstants';

const SkillCheckbox = ({ id, name, label, handleChange, checked }) => (
  <div className="border-bottom">
    <Row className="m-0">
      <Col className="pr-4 py-3">
        <InputGroup
          className="d-flex justify-content-between align-items-center"
          size="medium"
        >
          <Label className="mb-0" htmlFor={id}>
            {label}
          </Label>
          <Field
            className="bg-purple-dark"
            id={id}
            name={name}
            value={id}
            label={label}
            type="checkbox"
            onChange={handleChange}
            checked={checked}
          />
        </InputGroup>
      </Col>
    </Row>
  </div>
);

const SkillsTwo = ({ values }) => {
  const { setFieldValue } = useFormikContext();
  const { editorSkills = {}, editorSkillsRatings = {} } = values; // Default to empty objects if undefined

  const handleChange = (e) => {
    const { checked, id } = e.target;
    if (checked) {
      setFieldValue(`editorSkills.${id}`, 1);
      setFieldValue(`editorSkillsRatings.${id}`, editorSkillsRatings[id] || 1);
    } else {
      setFieldValue(`editorSkills.${id}`, 0);
      setFieldValue(`editorSkillsRatings.${id}`, 0);
    }
  };

  return (
    <div className="pilot-detail-calender">
      <div className="bg-white card-header mt-n3 mx-n4 text-left pilot-progress">
        <h5 className="font-size-14 mb-1">
          Please complete your profile before moving forward
        </h5>
      </div>
      <div className="mt-0 mx-n4">
        <div className="bg-purple-dark text-white px-3 py-3 d-flex justify-content-between">
          <b>SKILL NAME</b>
          <b>STATUS</b>
        </div>
        {SKILLS.map((skill) => (
          <SkillCheckbox
            key={skill.id}
            id={skill.id}
            name={`editorSkills.${skill.id}`}
            label={skill.label}
            handleChange={handleChange}
            checked={!!editorSkills[skill.id]} // Use !! to ensure a boolean value
          />
        ))}
      </div>
    </div>
  );
};

export default SkillsTwo;
