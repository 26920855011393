import React, { useContext, useReducer, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
//
import { UPDATE_BROKER_DIRECTORY } from 'pages/BrokersDirectory/BrokerDirectoryGraphQL';
import { rowStyle2 } from 'pages/BrokersDirectory/helper';
import Paginator from 'common/Paginator';
import { addMissingBrokerPageInfo } from 'pages/BrokersDirectory/BrokersDirectoryActions';
import FlaggedModal from 'pages/BrokersDirectory/common/FlaggedModal';
import { recordsToBeUpdatedContext } from 'contexts/RecordsToBeUpdated';
import BrokersTable from './components/BrokersTable';

const INITIAL_FLAG_DATA = {
  firstName: '',
  lastName: '',
  company: '',
};

const flatReducer = (state, action) => {
  switch (action.type) {
    case 'setFlagData':
      return { ...action.payload.brokerData };
    default:
      throw new Error();
  }
};

const BrokerTableRenderer = ({
  refetch,
  offset,
  limit,
  page,
  filters,
  pageCount,
  totalCount,
  brokersData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isFlagModalOpen, setFlagModal] = useState(false);
  const [flagModalData, dispatchToReducer] = useReducer(
    flatReducer,
    INITIAL_FLAG_DATA
  );
  const {
    location: { pathname },
  } = history;

  const { recordsToBeUpdated: recordsToUpdatedCount } = useContext(
    recordsToBeUpdatedContext
  );

  const [updateBrokerDirectory, { loading }] = useMutation(
    UPDATE_BROKER_DIRECTORY,
    {
      onCompleted: async ({ updateBrokerDirectory: { message } }) => {
        await refetch();
        toast.success(message);
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );

  const saveBrokersData = async ({ email, phone, id }) => {
    const modifiedData = {
      phone,
      email,
      brokerId: id,
    };

    try {
      await updateBrokerDirectory({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const goToTop = async (paginationObject) => {
    const { page, offset, ...rest } = paginationObject;
    await dispatch(
      addMissingBrokerPageInfo({
        missingBrokersInfo: {
          filters: { ...filters, ...rest },
          previousPageInfo: {
            previousRoutes: {
              path: pathname,
              param: '',
            },
            page,
            offset,
          },
        },
      })
    );
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * limit;
    goToTop({ limit, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ limit, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ limit, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const newOffset = (pageCount - 1) * limit;
    goToTop({ limit, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ limit, offset: newOffset, page: newPage });
  };

  const toggleFlagModal = (status, brokerData) => {
    setFlagModal(status);
    if (status && brokerData) {
      const { firstName, lastName, company, id, email } = brokerData;
      dispatchToReducer({
        type: 'setFlagData',
        payload: {
          brokerData: {
            id,
            email,
            company,
            lastName,
            firstName,
          },
        },
      });
    }
  };

  return (
    <>
      <div>
        <div className="my-3 updated-missing-scroll">
          <BrokersTable
            rowStyle2={rowStyle2}
            tableData={brokersData}
            toggleFlagModal={toggleFlagModal}
            saveBrokersData={saveBrokersData}
            loading={loading}
          />
        </div>
        <div className="mb-4 ">
          <Paginator
            pageCount={pageCount}
            totalCount={totalCount}
            offset={offset}
            currentPage={page}
            recordLength={brokersData.length}
            handleGoToNext={handleGoToNext}
            onPageChange={handlePageChange}
            handleGoToLast={handleGoToLast}
            handleGoToFirst={handleGoToFirst}
            handleGoToPrevious={handleGoToPrevious}
          />
        </div>
      </div>
      {isFlagModalOpen && (
        <FlaggedModal
          flaggedModal={{
            isFlagModalOpen,
            data: flagModalData,
          }}
          toggleFlagModal={toggleFlagModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default BrokerTableRenderer;
