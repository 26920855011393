import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import AuctionCard from 'pages/Search/common/AuctionCard';
import { AUCTION, BRONZE } from 'constants/appConstants';

const AuctionCardWrapper = () => {
  const { eliteAuction, plan } = useSelector((state) => state.salesPortal);

  return (
    <Card className="border-radius-8px border-0 grey-card card-block auction-selector mb-3">
      <CardBody className="px-3 py-3">
        <AuctionCard eliteAuctionStatus={plan?.type===BRONZE ? AUCTION : eliteAuction} />
      </CardBody>
    </Card>
  );
};
export default AuctionCardWrapper;
